import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import Tabs from 'antd/es/tabs';
import PlusOutlined from '@ant-design/icons/PlusOutlined';

import { useIsExportOrImport } from 'app-wrapper/hooks';
import { hasAccess, hasReadAccess } from 'app-wrapper/utils';
import { RouteNames } from 'app-wrapper/constants';
import { SubPageTabs } from 'app-wrapper/view/guideline';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import {
  StyledButton,
} from 'shipment-operations/view/pages/ShipmentTransportation/components/ShipmentTransportationTabs/ShipmentTransportationTabs.styled';
import {
  TabsContainer,
} from 'shipment-operations/view/components/DrayageTransportationTabs/DrayageTrasportationTabs.styled';
import EditOutlined from '@ant-design/icons/EditOutlined';
import { Button } from 'app-wrapper/view/components';

interface IDrayageTransportationTabsComponentProps {
  openAddCharge?: () => void
  addChargesImportPermissions?: PermissionAttributePolicy
  addChargesExportPermissions?: PermissionAttributePolicy
  trackerAndChargesVisibility?: PermissionAttributePolicy
  requestChangesVisibility?: PermissionAttributePolicy
  openChangeRequestDrawer: () => void
}

const DrayageTransportationTabsComponent: FC<IDrayageTransportationTabsComponentProps> = ({
  openAddCharge,
  addChargesImportPermissions,
  addChargesExportPermissions,
  trackerAndChargesVisibility,
  openChangeRequestDrawer,
  requestChangesVisibility,
}) => {
  const { t } = useTranslation();
  const { shipmentId = '0' } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { isExport } = useIsExportOrImport();

  const onChangeTab = useCallback((tab) => {
    navigate(tab, { replace: true });
  }, []);

  const isAbleToWriteExportCharges = hasAccess(addChargesExportPermissions, PermissionAttributePolicy.WRITE);
  const isAbleToWriteImportCharges = hasAccess(addChargesImportPermissions, PermissionAttributePolicy.WRITE);
  const isAbleToViewChargesAndTracker = hasReadAccess(trackerAndChargesVisibility);

  const extraContent = useMemo(() => {
    const routeConditions = {
      [RouteNames.SHIPMENT_TRANSPORTATION_EXPORT_CHARGES(shipmentId)]: isAbleToWriteExportCharges,
      [RouteNames.SHIPMENT_TRANSPORTATION_IMPORT_CHARGES(shipmentId)]: isAbleToWriteImportCharges,
      [RouteNames.SHIPMENT_TRANSPORTATION_EXPORT_ACCESSORIALS(shipmentId)]: isAbleToWriteExportCharges,
      [RouteNames.SHIPMENT_TRANSPORTATION_IMPORT_ACCESSORIALS(shipmentId)]: isAbleToWriteImportCharges,
    };

    if (routeConditions[pathname]) {
      return (
        <StyledButton onClick={openAddCharge} icon={<PlusOutlined />}>
          {t('addCharge')}
        </StyledButton>
      );
    }

    return undefined;
  }, [pathname, shipmentId, isAbleToWriteExportCharges, isAbleToWriteImportCharges]);

  const handleOpenChangeRequestDrawer = useCallback(() => {
    openChangeRequestDrawer();
  }, []);

  return (
    <TabsContainer>
      <SubPageTabs
        activeKey={pathname}
        onChange={onChangeTab}
        tabBarExtraContent={extraContent}
      >
        <Tabs.TabPane
          tab={t('Overview')}
          key={isExport ? RouteNames.SHIPMENT_TRANSPORTATION_EXPORT_OVERVIEW(shipmentId) : RouteNames.SHIPMENT_TRANSPORTATION_IMPORT_OVERVIEW(shipmentId)}
        />

        {isAbleToViewChargesAndTracker ? (
          <>
            <Tabs.TabPane
              tab={t('Tracker')}
              key={isExport ? RouteNames.SHIPMENT_TRANSPORTATION_EXPORT_TRACKER(shipmentId) : RouteNames.SHIPMENT_TRANSPORTATION_IMPORT_TRACKER(shipmentId)}
            />
            <Tabs.TabPane
              tab={t('Charges')}
              key={isExport ? RouteNames.SHIPMENT_TRANSPORTATION_EXPORT_CHARGES(shipmentId) : RouteNames.SHIPMENT_TRANSPORTATION_IMPORT_CHARGES(shipmentId)}
            />
            <Tabs.TabPane
              tab={t('accessorials')}
              key={isExport ? RouteNames.SHIPMENT_TRANSPORTATION_EXPORT_ACCESSORIALS(shipmentId) : RouteNames.SHIPMENT_TRANSPORTATION_IMPORT_ACCESSORIALS(shipmentId)}
            />
            <Tabs.TabPane
              tab={t('freeTime')}
              key={isExport ? RouteNames.SHIPMENT_TRANSPORTATION_EXPORT_FREE_TIME(shipmentId) : RouteNames.SHIPMENT_TRANSPORTATION_IMPORT_FREE_TIME(shipmentId)}
            />
          </>
        ) : null}
      </SubPageTabs>
      {
        hasAccess(requestChangesVisibility, PermissionAttributePolicy.WRITE)
          ? (
            <div>
              <Button
                icon={<EditOutlined />}
                size="middle"
                onClick={handleOpenChangeRequestDrawer}
              >
                {t('Request Changes')}
              </Button>
            </div>
          )
          : null
      }
    </TabsContainer>
  );
};

export { DrayageTransportationTabsComponent };
