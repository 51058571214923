import React, {
  FC, useCallback, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';
import Typography from 'antd/es/typography';
import Divider from 'antd/es/divider';

import { VerticalFormItemSvg } from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';

import { ECarrierSCAC } from 'monetary/constants';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

import { CarrierLogo } from 'app-wrapper/view/guideline';
import {
  CarrierCardContainer,
  CarrierCardTop,
  CarrierCardBottom,
  CarrierCardAvatarAndNameContainer,
  CarrierCardAvatar,
  CarrierCardLabeledText,
  CarrierCardLabel,
  CarrierCardReferenceTag,
  CarrierCardTextArea,
  CarrierCardBottomWrap,
  CarrierCardBottomExpandButton,
} from './styled';

export interface ICarrierCardComponentProps {
  isLoading: boolean
  name?: string
  reference?: string
  carrierSCAC?: string
  termsAndConditions?: string
  comments?: string
  tocAvailability?: PermissionAttributePolicy
  commentsAvailability?: PermissionAttributePolicy
}

const defaultLengthTermText = 500;
const defaultLengthCommentText = 300;

export const CarrierCardComponent: FC<ICarrierCardComponentProps> = ({
  isLoading,
  name,
  reference,
  carrierSCAC,
  termsAndConditions,
  comments,
  tocAvailability,
  commentsAvailability,
}) => {
  const { t } = useTranslation();

  const isAbleToViewTOC = useMemo(() => tocAvailability === PermissionAttributePolicy.READ, [tocAvailability]);
  const isAbleToViewComments = useMemo(() => commentsAvailability === PermissionAttributePolicy.READ, [commentsAvailability]);

  const [termButton, setTermButton] = useState(false);
  const [commentsButton, setCommentsButton] = useState(false);

  const termButtonOnClick = useCallback(
    () => {
      setTermButton((prev) => !prev);
    },
    [],
  );
  const commentsButtonOnClick = useCallback(
    () => {
      setCommentsButton((prev) => !prev);
    },
    [],
  );

  if (isLoading) {
    return (
      <CarrierCardContainer>
        <Typography.Title level={5}>
          {t('Carrier')}
        </Typography.Title>
        <Skeleton />
      </CarrierCardContainer>
    );
  }

  const isLargeTermText = termsAndConditions && termsAndConditions?.length > defaultLengthTermText;
  const isLargeCommentText = comments && comments?.length > defaultLengthCommentText;

  return (
    <CarrierCardContainer>
      <CarrierCardTop>
        <CarrierCardAvatarAndNameContainer>
          <CarrierCardAvatar>
            <CarrierLogo carrierSCAC={carrierSCAC as ECarrierSCAC} small />
          </CarrierCardAvatar>

          <CarrierCardLabeledText>
            <CarrierCardLabel>{t('Carrier')}</CarrierCardLabel>
            <br />
            <Typography.Title level={5}>{name}</Typography.Title>
          </CarrierCardLabeledText>
        </CarrierCardAvatarAndNameContainer>
        {!!reference && (
          <CarrierCardLabeledText>
            <CarrierCardLabel>{t('Booking Number')}</CarrierCardLabel>
            <br />
            <CarrierCardReferenceTag>{reference}</CarrierCardReferenceTag>
          </CarrierCardLabeledText>
        )}
      </CarrierCardTop>
      {
        (!!termsAndConditions && !!comments) && (
          <>
            <Divider style={{ margin: '8px' }} />
            <CarrierCardBottom>
              {!!termsAndConditions && isAbleToViewTOC && (
                <CarrierCardLabeledText>
                  <CarrierCardLabel>{t('Carrier Terms and Conditions')}</CarrierCardLabel>
                  <br />
                  <CarrierCardTextArea>{termButton ? termsAndConditions : `${termsAndConditions.slice(0, defaultLengthTermText)}${isLargeTermText ? '...' : ''}`}</CarrierCardTextArea>
                </CarrierCardLabeledText>
              )}
              {(isLargeTermText) && (
                <CarrierCardBottomWrap>
                  <CarrierCardBottomExpandButton onClick={termButtonOnClick}>
                    <VerticalFormItemSvg
                      rotate={termButton ? 180 : 0}
                      style={{
                        color: themesColors.neutralBranding7,
                      }}
                    />
                    {termButton
                      ? t('View less')
                      : `${t('View more')}`}
                  </CarrierCardBottomExpandButton>
                </CarrierCardBottomWrap>
              )}

              {!!comments && isAbleToViewComments && (
                <CarrierCardLabeledText>
                  <CarrierCardLabel>{t('Carrier Comments')}</CarrierCardLabel>
                  <br />
                  <CarrierCardTextArea>{commentsButton ? comments : `${comments.slice(0, defaultLengthCommentText)}${isLargeCommentText ? '...' : ''}`}</CarrierCardTextArea>
                </CarrierCardLabeledText>
              )}
              {(isLargeCommentText) && (
                <CarrierCardBottomWrap>
                  <CarrierCardBottomExpandButton onClick={commentsButtonOnClick}>
                    <VerticalFormItemSvg
                      rotate={commentsButton ? 180 : 0}
                      style={{
                        color: themesColors.neutralBranding7,
                      }}
                    />
                    {commentsButton
                      ? t('View less')
                      : `${t('View more')}`}
                  </CarrierCardBottomExpandButton>
                </CarrierCardBottomWrap>
              )}
            </CarrierCardBottom>
          </>
        )
      }
    </CarrierCardContainer>
  );
};
