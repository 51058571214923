import { css } from 'styled-components';

export const inputDefaultStyle = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  min-height: 32px;
  height: 32px;

  &.ant-input:focus, &.ant-input-affix-wrapper-focused {
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme?.themesColors?.primaryBranding6} !important;
  }

  &.ant-input-affix-wrapper > input {
    font-size: 12px;
    font-weight: 400;
    height: 20px !important;
    min-height: 20px !important;
  }

  .ant-input::placeholder {
    color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
    opacity: 1;
  }
`;
