import React, {
  FC,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import Typography from 'antd/es/typography';

import { DateDtm } from 'app-wrapper/models/dtm';
import {
  Row,
  Col,
  RadioGroup,
} from 'app-wrapper/view/components';
import { RadioButton } from 'app-wrapper/view/components/ant/Radio';
import { FreightFromLocationStateDatePortDTM, FreightFromLocationStateDTM } from 'monetary/models/dtm';
import { IDefaultFieldErrors } from 'monetary/models/errors';
import {
  AddressBlockOriginContainer,
} from 'monetary/view/components/FreightQuoteFormRequest/AddressBlock/AddressBlockOrigin.container';
import {
  RowAddressBoth,
  AddressBlockRadioGroup,
  AddressBlockDatePicker,
} from 'monetary/view/components/FreightQuoteFormRequest/AddressBlock/AddressBlock.styled';

import {
  OverlayWrap,
  OverlayBottom,
  Overlay,
} from './OriginOverlay.styled';

interface IOriginOverlayComponentProps {
  onChangeOriginTypeToPort: (isPort: boolean) => void
  origin?: FreightFromLocationStateDTM
  onChangePortDateOrigin: (props: FreightFromLocationStateDatePortDTM) => void
  originErrors: {
    isPort?: IDefaultFieldErrors
    location?: IDefaultFieldErrors
    datePort?: {
      error?: IDefaultFieldErrors
    }
    dateDoor?: {
      error?: IDefaultFieldErrors
    }
  }
  destinationErrors: {
    isPort?: IDefaultFieldErrors
    location?: IDefaultFieldErrors
    datePort?: {
      error?: IDefaultFieldErrors
    }
    dateDoor?: {
      error?: IDefaultFieldErrors
    }
  }
  onFocusPortDateOrigin: () => void
  onBlurPortDateOrigin: () => void
  isSubmitVisited?: boolean
}

export const OriginOverlayComponent: FC<IOriginOverlayComponentProps> = ({
  onChangeOriginTypeToPort,
  origin,
  onChangePortDateOrigin,
  originErrors,
  destinationErrors,
  onBlurPortDateOrigin,
  onFocusPortDateOrigin,
  isSubmitVisited,
}) => {
  const { t } = useTranslation();

  const disabledDate = useCallback((current: moment.Moment): boolean => {
    if (origin?.isPort && origin?.datePort?.earliestDate) {
      const minDateSecond = moment(origin?.datePort?.earliestDate);
      const maxDate = moment(origin?.datePort?.earliestDate).add(34, 'days');

      return current && (current < minDateSecond.subtract(1, 'day').endOf('day') || (current > maxDate.endOf('day')));
    }

    if (!origin?.isPort) {
      const maxDays = DateDtm.addWorkingsDaysDisabled(moment(), 2);
      return current && (current < maxDays.endOf('day'));
    }

    return current && (current < moment().add(3, 'days').endOf('day'));
  }, [origin?.datePort?.earliestDate, origin?.isPort]);

  const originOnCalendarChange = useCallback((dates, dateStrings) => {
    if (origin?.isPort) {
      onChangePortDateOrigin(FreightFromLocationStateDatePortDTM.fromPlain({
        earliestDate: dateStrings || '',
        latestDate: dateStrings || '',
      }));
    } else {
      onChangePortDateOrigin(FreightFromLocationStateDatePortDTM.fromPlain({
        earliestDate: dateStrings || '',
      }));
    }
  }, [onChangePortDateOrigin, origin?.isPort]);

  const originTypeToPortOnChangeHandler = useCallback((e) => onChangeOriginTypeToPort(e.target.value === '1'), [onChangeOriginTypeToPort]);

  const isErrorDateOrigin = !!originErrors.datePort?.error?.message
    && ((originErrors.datePort?.error?.isVisited
        && destinationErrors.datePort?.error?.isVisited)
      || isSubmitVisited);

  return (
    <OverlayWrap>
      <Overlay>
        <RowAddressBoth className="FreightQuoteFormRequest__Row__col_both_col">
          <Col span={24} className="FreightQuoteFormRequest__Row__col_left">
            <Row justify="space-between" className="FreightQuoteFormRequest__Row__col_both_col__title">
              <div>
                <Typography.Title level={5}>{t('Origin')}</Typography.Title>
              </div>

              <AddressBlockRadioGroup>
                <RadioGroup
                  size="small"
                  onChange={originTypeToPortOnChangeHandler}
                  value={`${origin?.isPort ? '1' : '0'}`}
                >
                  <RadioButton value="0">{t('Door')}</RadioButton>
                  <RadioButton value="1">{t('Port')}</RadioButton>
                </RadioGroup>

              </AddressBlockRadioGroup>
            </Row>

            <Row className="FreightQuoteFormRequest__Row__col FreightQuoteFormRequest__Row__col_first">
              <Col span={24}>
                <AddressBlockOriginContainer />
              </Col>
            </Row>

            <Row className="contentFullWidth FreightQuoteFormRequest__Row__col FreightQuoteFormRequest__Row__col_second">
              <Col span={24}>
                <AddressBlockDatePicker
                  className="AddressBlockDatePickerOrigin"
                  isError={isErrorDateOrigin}
                  value={origin?.datePort?.earliestDate ? moment(origin?.datePort?.earliestDate) : null}
                  placeholder={t('Cargo ready date *')}
                  disabledDate={disabledDate}
                  onChange={originOnCalendarChange}
                  onFocus={onFocusPortDateOrigin}
                  onBlur={onBlurPortDateOrigin}
                />
              </Col>
            </Row>
          </Col>
        </RowAddressBoth>
      </Overlay>

      <OverlayBottom />
    </OverlayWrap>
  );
};
