import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { useParams } from 'react-router';

import { addPrefix } from 'app-wrapper/utils';
import { DocumentType, PermissionAttributePolicy } from 'shipment-operations/constants';
import { ShipmentDeclarationFileDTM } from 'shipment-operations/models/dtm';

import { Button, Upload } from './SDHUploadDocument.styled';

interface ISDHUploadDocumentComponentProps {
  file?: UploadFile;
  containerId: number;
  authToken: string;
  setIsLoading: (isLoading: boolean) => void;
  previewDocument: (documentId: number | null, documentName: string) => void;
  addHazmatDocument: (shipmentId: number, containerId: number, file: ShipmentDeclarationFileDTM) => void;
  deleteHazmatDocument: (shipmentId: number, containerId: number, file?: ShipmentDeclarationFileDTM) => void;
  setContainerFile: (containerId: number, file?: ShipmentDeclarationFileDTM) => void;
  imoDeclarationPermission?: PermissionAttributePolicy;
}

export const SDHUploadDocumentComponent: FC<ISDHUploadDocumentComponentProps> = ({
  file,
  containerId,
  authToken,
  deleteHazmatDocument,
  addHazmatDocument,
  previewDocument,
  setIsLoading,
  setContainerFile,
  imoDeclarationPermission,
}) => {
  const { shipmentId } = useParams<'shipmentId'>();
  const { t } = useTranslation();

  const postUrl = useMemo(() => addPrefix(`/shipment-service/api/v1/shipments/${shipmentId}/documents?type=${DocumentType.IMO}`), [shipmentId]);

  const handleChange = useCallback(({ file: antFile }: UploadChangeParam) => {
    if (antFile.status === 'uploading' && file) {
      deleteHazmatDocument(Number(shipmentId), containerId, ShipmentDeclarationFileDTM.fromPlain({
        ...antFile,
      }));
    }

    if (antFile?.status === 'done') {
      addHazmatDocument(Number(shipmentId), containerId, ShipmentDeclarationFileDTM.fromPlain({
        ...antFile,
        fileId: antFile.response.id,
      }));
      return;
    }

    if (antFile?.response && antFile?.status === 'error') {
      setContainerFile(containerId, ShipmentDeclarationFileDTM.fromPlain({
        ...antFile,
        response: antFile.response.message,
      }));
    }
  }, [addHazmatDocument, setIsLoading, setContainerFile, file, deleteHazmatDocument, containerId, shipmentId]);

  const handleRemove = useCallback(() => {
    deleteHazmatDocument(Number(shipmentId), containerId);
  }, [deleteHazmatDocument, shipmentId, containerId]);

  const handlePreview = useCallback((antFile) => {
    previewDocument(
      antFile?.fileId ? +antFile?.fileId : null,
      antFile.name,
    );
  }, [previewDocument]);

  const isDisabled = useMemo(() => imoDeclarationPermission !== PermissionAttributePolicy.WRITE, [imoDeclarationPermission]);

  return (
    <Upload
      disabled={isDisabled}
      multiple={false}
      maxCount={1}
      fileList={file ? [file] : undefined}
      headers={{ Authorization: authToken }}
      action={postUrl}
      onRemove={handleRemove}
      onChange={handleChange}
      onPreview={handlePreview}
    >
      <Button
        disabled={isDisabled}
        icon={<UploadOutlined />}
        type="dashed"
      >
        {t('Upload document')}
      </Button>
    </Upload>
  );
};
