import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import themesColors from 'app-wrapper/view/themes/themesColors';
import { FreightFromLocationStateDTM, FreightQuotaContentSchedulesDTM } from 'monetary/models/dtm';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import {
  ScheduleOption,
  SubPageContent,
  RowSpaceBetween,
  FlexRow,
  GapVerticalContainerStyled,
} from 'app-wrapper/view/guideline';
import { BodyTextMediumSpan, BodyTextRegularSpan } from 'app-wrapper/view/guideline/Font';
import { DateDtm } from 'app-wrapper/models/dtm';

import { RoutingDetails } from './components';
import {
  Content,
} from './SchedulesStep.styled';

interface ISchedulesStepComponentProps {
  isPending: boolean;
  origin?: FreightFromLocationStateDTM;
  schedules: FreightQuotaContentSchedulesDTM[];
  setSchedule: (scheduleId: number) => void;
  scheduleId: number;
}

export const SchedulesStepComponent: FC<ISchedulesStepComponentProps> = ({
  schedules,
  origin,
  setSchedule,
  scheduleId,
  isPending,
}) => {
  const { t } = useTranslation();

  const cargoReadyDate = useMemo(() => (origin?.datePort?.earliestDate ? DateDtm.fromPlain(origin?.datePort?.earliestDate).getFormatDMMMHHmmWithOffset() : ''), [origin?.datePort?.earliestDate]);

  if (isPending) {
    return (
      <GapVerticalContainerStyled
        style={{
          padding: 16,
        }}
      >
        <SkeletonSection height="46px" />

        {schedules.map((schedule) => (
          <SkeletonSection key={schedule.id} height="88px" />
        ))}
      </GapVerticalContainerStyled>
    );
  }

  return (
    <SubPageContent>
      <RowSpaceBetween>
        <BodyTextMediumSpan>
          {t('Select Schedule')}
        </BodyTextMediumSpan>

        <FlexRow>
          <BodyTextRegularSpan
            style={{
              color: themesColors.secondaryDot45,
              marginRight: 4,
            }}
          >
            {t('Cargo Ready Date')}
          </BodyTextRegularSpan>

          <BodyTextRegularSpan>
            {cargoReadyDate}
          </BodyTextRegularSpan>
        </FlexRow>
      </RowSpaceBetween>

      <Content>
        {schedules.map((schedule) => (
          <ScheduleOption
            key={schedule.id}
            isChosen={scheduleId === schedule.id}
            chooseSchedule={setSchedule}
            schedule={schedule}
            expandContent={schedule.id ? <RoutingDetails scheduleId={schedule.id} /> : null}
          />
        ))}
      </Content>
    </SubPageContent>
  );
};
