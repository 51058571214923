import { RouteNames } from 'app-wrapper/constants';
import { BaseUseCase } from 'app-wrapper/usecases/Base.useCase';
import { UserAuthDataDTM } from 'authentication/models/dtm';
import { R } from 'authentication/repository';
import { R as MonetaryR } from 'monetary/repository';
import { OrganizationDTM, OrganizationMemberDTM } from 'user-management/models/dtm';
import { R as UserManagementR } from 'user-management/repository';

export class AuthUseCase extends BaseUseCase {
  setLastUserId(userId: string) {
    window.localStorage.setItem('lastUserId', userId);
  }

  getLastUserId() {
    return window.localStorage.getItem('lastUserId');
  }

  public async checkAuth() {
    let userResponse: UserAuthDataDTM | undefined;
    let organization: OrganizationDTM | undefined;
    let organizationMember: OrganizationMemberDTM | undefined;

    this.dispatch(R.actions.auth.setIsLoading(true));

    try {
      await R.services.auth.setLoggedIn();

      userResponse = await R.services.auth.onCheckUser();
    } catch (e) {
      this.dispatch(R.actions.auth.logOut());

      // here is all unauthorized screens, if you will add the new one - do it hare too
      if (
        this.controller.location.pathname !== RouteNames.SIGN_IN()
        && this.controller.location.pathname !== RouteNames['SIGN-UP']()
        && this.controller.location.pathname !== RouteNames.SIGN_UP()
        && this.controller.location.pathname !== RouteNames.SIGN_UP_EMAIL_CONFIRMATION()
        && this.controller.location.pathname !== RouteNames.SIGN_UP_SUCCESS()
        && this.controller.location.pathname !== RouteNames.FORGOT_PASSWORD_EMAIL_SEND()
        && this.controller.location.pathname !== RouteNames.FORGOT_PASSWORD_NEW_PASSWORD()
        && this.controller.location.pathname !== RouteNames.FORGOT_PASSWORD()
      ) {
        this.controller.navigate(RouteNames.SIGN_IN());
      }

      return;
    }

    if (!userResponse) {
      return;
    }

    this.dispatch(R.actions.auth.setUser(userResponse));
    new AuthUseCase(this.controller).setLastUserId(userResponse.userId);

    try {
      organization = await UserManagementR.services.organization.getCurrentOrganization();
    } catch (e) {
      console.error(e);

      return;
    }

    if (!organization) {
      return;
    }

    this.dispatch(UserManagementR.actions.userOrganizationData.setUserOrganization(organization));

    try {
      organizationMember = await UserManagementR.services.organization.getOrganizationMember(organization.id, userResponse.email);
    } catch (e) {
      console.error(e);

      return;
    }
    if (!organizationMember) {
      return;
    }

    const userManagementFunctionalityAvailability = UserManagementR.services.moduleFunctionalityPermissions.getFunctionalityPermissions(organizationMember.role, organization.role, organization.status);
    const monetaryAvailability = MonetaryR.services.moduleFunctionalityPermissions.getFunctionalityPermissions(organization.role);
    if (!userManagementFunctionalityAvailability || !monetaryAvailability) {
      return;
    }

    this.dispatch(MonetaryR.actions.moduleFunctionalityPermissions.setPermissions(monetaryAvailability));
    this.dispatch(UserManagementR.actions.moduleFunctionalityPermissions.setPermissions(userManagementFunctionalityAvailability));

    this.dispatch(R.actions.auth.setIsLoading(false));
  }
}
