import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';

import {
  CardsComponent,
} from './Cards.component';

export interface IRFQContentProps {
  NoneContentRecommended?: React.ReactNode
  isAllRequestPage?: boolean
  isFooter?: boolean
  hideBook?: boolean
}

export const CardsContainer = memo(({
  NoneContentRecommended,
  isAllRequestPage,
  isFooter,
  hideBook,
}: IRFQContentProps) => {
  const getFreightQuoteCurrent = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const requestFiltersStatus = useSelector(R.selectors.freightQuote.getFreightQuoteFiltersRequestStatus);
  const isHaveFreightQuoteCurrentFiltersParams = useSelector(R.selectors.freightQuote.getIsHaveFreightQuoteCurrentFiltersParams);
  const quotas = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentQuotas);
  const { quotasOption } = quotas || {};

  return (
    <CardsComponent
      NoneContentRecommended={NoneContentRecommended}
      isAllRequestPage={isAllRequestPage}
      isFooter={isFooter}
      requestFiltersStatus={requestFiltersStatus}
      quotaRequestServiceByIdData={quotas?.allQuotas}
      quotaRequestServiceById={quotas}
      fullCompleteStatus={getFreightQuoteCurrent?.fullCompleteStatus}
      hideBook={hideBook}
      hasNextPage={!quotasOption?.isEnd}
      onRunRFQContentComponent={UC.FreightQuote.onRunRFQContentComponent}
      onScrollNextInfinityPage={UC.FreightQuote.onScrollNextInfinityPage}
      isHaveFiltersParams={Boolean(isHaveFreightQuoteCurrentFiltersParams)}
    />
  );
});
