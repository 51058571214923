import React, { memo } from 'react';

import { CardsContainer } from 'monetary/view/pages/QuotaRequests/components/Quotas/components/Cards';
import { FiltersContainer } from 'monetary/view/pages/QuotaRequests/components/Quotas/components/Filters';
import { GapHorizontalContainerStyled } from 'app-wrapper/view/guideline';

export interface IRFQContentProps {
  NoneContentRecommended?: React.ReactNode
  isAllRequestPage?: boolean
  isFooter?: boolean
  hideBook?: boolean
}

export const ContentComponent = memo(({
  NoneContentRecommended,
  isAllRequestPage,
  isFooter,
  hideBook,
}: IRFQContentProps) => (
  <GapHorizontalContainerStyled
    width="100%"
    gap="16px"
  >
    <FiltersContainer />
    <CardsContainer
      NoneContentRecommended={NoneContentRecommended}
      isAllRequestPage={isAllRequestPage}
      isFooter={isFooter}
      hideBook={hideBook}
    />
  </GapHorizontalContainerStyled>
));
