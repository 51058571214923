import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomModal } from 'shipment-operations/view/components';
import { GapVerticalContainerStyled } from 'app-wrapper/view/guideline';

import {
  BookingDetails, Schedule, Location, Header, SideBar,
} from './components';
import { Wrapper, MainContent } from './ManualConfirmation.styled';

interface IManualConfirmationComponentProps {
  onClose: () => void
}

const ManualConfirmationComponent: FC<IManualConfirmationComponentProps> = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <CustomModal
      onClose={onClose}
      title={t('Manual Confirmation')}
      contentStyles={{
        left: 'unset',
        width: '100vw',
      }}
      childrenWrapperStyles={{
        position: 'relative',
        padding: '0px 0px',
      }}
    >
      <Wrapper>
        <SideBar />
        <MainContent>
          <Header />
          <GapVerticalContainerStyled>
            <BookingDetails />
            <Schedule />
            <Location />
          </GapVerticalContainerStyled>
        </MainContent>
      </Wrapper>
    </CustomModal>
  );
};

export { ManualConfirmationComponent };
