import React, { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { SubPageContent, DocumentsSection } from 'app-wrapper/view/guideline';

import { EditModal } from './EditModal';
import { GreenCheck, StyledEdit } from '../CommonStyled';
import { StyledSection } from '../styled';
import {
  Wrapper, InputsWrapper, InputWrapper, InputValue, UploadSection,
} from './BookingDetails.styled';

interface IBookingDetailsComponentProps {
  withIntra: boolean
  bookingNumber?: string
  bookingNumberForEdit?: string
  toggleEdit: (editModalHost: string) => void
  editHost: string
  onChangeBookingNumber: (value: string) => void
  onSaveBookingNumber: (shipmentId: string) => void
  pending: boolean
}

const BOOKING_NUMBER = 'BOOKING_NUMBER';
const INTRA = 'INTRA';

const BookingDetailsComponent: FC<IBookingDetailsComponentProps> = ({
  withIntra, bookingNumber, toggleEdit, editHost, onChangeBookingNumber, bookingNumberForEdit,
  onSaveBookingNumber, pending,
}) => {
  const { shipmentId } = useParams<'shipmentId'>();
  const { t } = useTranslation();
  const openEditBookingNumber = useCallback(() => toggleEdit(BOOKING_NUMBER), [editHost]);
  const openEditIntra = useCallback(() => toggleEdit(INTRA), [editHost]);
  const onCloseModal = useCallback(() => toggleEdit(''), [editHost]);
  const handleOk = useCallback(() => {
    if (shipmentId) {
      onSaveBookingNumber(shipmentId);
    }
  }, [shipmentId]);

  return (
    <SubPageContent>
      {editHost === BOOKING_NUMBER && (
        <EditModal
          isPending={pending}
          onClose={onCloseModal}
          onOk={handleOk}
          value={bookingNumberForEdit}
          onChange={onChangeBookingNumber}
          title={t('Ocean booking details')}
          label={t('Booking number')}
        />
      )}
      {editHost === INTRA && (
        <EditModal
          isPending={false}
          onClose={() => {}}
          onOk={() => {}}
          value=""
          onChange={() => {}}
          title="Ocean booking details"
          label="Booking number"
        />
      )}
      <GreenCheck title={t('Booking Details')} isValid={!!bookingNumber} />
      <Wrapper>
        <InputsWrapper>
          <StyledSection>
            <InputWrapper>{t('Booking number')}</InputWrapper>
            <div>
              <InputValue>{bookingNumber}</InputValue>
              <StyledEdit onClick={openEditBookingNumber} />
            </div>
          </StyledSection>
          {withIntra && (
            <StyledSection>
              <InputWrapper>{t('Inttra ref number')}</InputWrapper>
              <div>
                <InputValue />
                <StyledEdit onClick={openEditIntra} />
              </div>
            </StyledSection>
          )}
        </InputsWrapper>
        <div style={{ width: '50%' }}>
          <UploadSection>
            <DocumentsSection
              sectionName={t('Upload booking confirmation')}
              columns={[]}
              files={[]}
              addFile={() => {
              }}
            />
          </UploadSection>
        </div>
      </Wrapper>
    </SubPageContent>
  );
};

export { BookingDetailsComponent };
