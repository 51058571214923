import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { RcFile, UploadProps } from 'antd/es/upload/interface';
import AntdUploadExports from 'antd/es/upload';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import UploadOutlined from '@ant-design/icons/UploadOutlined';

import { showWarning } from 'app-wrapper/utils/showWarning';
import { addPrefix } from 'app-wrapper/utils';
import { ContainerDocumentDTM } from 'shipment-operations/models/dtm';

import { Upload, Button } from './DocumentUpload.styled';

const MAX_FILE_SIZE = 1024 * 1024 * 10; // 10MB
const { LIST_IGNORE } = AntdUploadExports;

interface DocumentUploadComponentProps {
  authToken: string
  organizationId: number
  containerDocument: ContainerDocumentDTM | null
  setContainerDocument: (document: ContainerDocumentDTM | null) => void
  removeDocument: (organizationId: string) => void;
  downloadDocument: (organizationId: string) => void
  documentType: string
}

// TODO Refactoring request by @AliaksandrKatovich FRTUN-2166
export const DocumentUploadComponent: FC<DocumentUploadComponentProps> = ({
  authToken,
  containerDocument,
  organizationId,
  setContainerDocument,
  removeDocument,
  downloadDocument,
  documentType,
}) => {
  const { t } = useTranslation();
  const { customerId = '' } = useParams();

  const setDocument = useCallback((value: ContainerDocumentDTM[]) => {
    setContainerDocument(value[0] || null);
  }, [setContainerDocument]);

  const id = useMemo(() => customerId || organizationId, [customerId, organizationId]);

  const downloadContainerDocument = useCallback(() => downloadDocument(customerId), [downloadDocument, customerId]);

  const handleBeforeUpload = useCallback((file: RcFile) => {
    const isAcceptableSize = file.size < MAX_FILE_SIZE;
    if (!isAcceptableSize) {
      showWarning({ message: t('File is too large'), duration: 4 });
    }
    return isAcceptableSize || LIST_IGNORE;
  }, []);

  const handleChange: UploadProps['onChange'] = useCallback(({ file }) => {
    if (file.status === 'removed') {
      setDocument([]);
      return;
    }

    if (file.response) {
      file.url = '/';
    }

    if (file.response && file.status === 'error') {
      file.error = new Error(file.response?.message);
      file.response = file.response.message ? file.response.message : {};
    }

    setDocument([ContainerDocumentDTM.fromPlain({
      name: file.name,
      url: file.url,
      uid: file.uid,
      response: file.response,
      status: file.status,
    })]);
  }, [setDocument]);

  const handleRemove: UploadProps['onRemove'] = useCallback(async () => {
    await removeDocument(customerId);
    setDocument([]);
  }, [removeDocument, customerId]);

  const handlePreview: UploadProps['onPreview'] = useCallback((file) => {
    if (!file.status) {
      return;
    }

    downloadContainerDocument();
  }, [downloadContainerDocument]);

  const postUrl = addPrefix(`/organization-service/api/v1/organizations/${id}/documents?type=${documentType}`);

  return (
    <Upload
      maxCount={1}
      multiple={false}
      showUploadList={{ showRemoveIcon: true, removeIcon: <DeleteOutlined /> }}
      beforeUpload={handleBeforeUpload}
      action={postUrl}
      headers={{ Authorization: authToken }}
      fileList={containerDocument ? [containerDocument] : []}
      onPreview={handlePreview}
      onChange={handleChange}
      onRemove={handleRemove}
    >
      <Button
        error={0}
        icon={<UploadOutlined />}
        type="dashed"
      >
        {t('Upload document')}
      </Button>
    </Upload>
  );
};
