import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { EmailInputComponent } from './EmailInput.component';

const EmailInputContainer: FC = () => {
  const email = useSelector(R.selectors.shippingParties.getEmail);
  const emailError = useSelector(R.selectors.shippingParties.getEmailError);
  const role = useSelector(R.selectors.shippingParties.getSelectedFormType);
  const permissions = useSelector(R.selectors.shippingParties.getShippingPartyPermissionsByRole(role));

  return (
    <EmailInputComponent
      email={email}
      setEmail={UC.shippingParties.setEmail}
      emailError={emailError}
      disabled={!permissions.isAbleToEditContacts}
    />
  );
};

const EmailInputContainerCached = React.memo(EmailInputContainer);

export {
  EmailInputContainerCached as EmailInput,
};
