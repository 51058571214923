import React from 'react';

export const ExportIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="#202C3C"
      d="M14.721 12.38h-.96a.14.14 0 0 0-.138.137v1.104H2.378V2.375h11.247v1.103c0 .075.062.138.137.138h.961c.075 0 .138-.061.138-.138v-1.79a.547.547 0 0 0-.549-.547H1.692a.547.547 0 0 0-.547.548v12.618c0 .303.244.548.548.548H14.31a.547.547 0 0 0 .548-.548v-1.79a.14.14 0 0 0-.138-.137m.332-4.495-2.533-2a.143.143 0 0 0-.233.113v1.357H6.68a.143.143 0 0 0-.143.143v1c0 .078.065.143.143.143h5.607v1.357c0 .12.14.187.232.112l2.534-2a.143.143 0 0 0 0-.225"
    />
  </svg>
);
