import React, { FC } from 'react';
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import { useTranslation } from 'react-i18next';

import { BodyTextMediumSpan } from 'app-wrapper/view/fonts';
import { Wrapper, Name, MarginTopContainer } from './SideBar.styled';

interface ISideBarComponentProps {
  validBookingDetails?: string
}

const SideBarComponent: FC<ISideBarComponentProps> = ({ validBookingDetails }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <BodyTextMediumSpan>
        {t('Completion status')}
      </BodyTextMediumSpan>
      <MarginTopContainer>
        <CheckCircleOutlined style={{ color: validBookingDetails ? 'green' : '#D1D7E2', fontSize: '12px' }} />
        <Name>{t('Ocean booking details')}</Name>
      </MarginTopContainer>
      <MarginTopContainer>
        <CheckCircleOutlined style={{ color: 'green', fontSize: '12px' }} />
        <Name>{t('Schedule')}</Name>
      </MarginTopContainer>
      <MarginTopContainer>
        <CheckCircleOutlined style={{ color: 'green', fontSize: '12px' }} />
        <Name>{t('Location')}</Name>
      </MarginTopContainer>
    </Wrapper>
  );
};

export { SideBarComponent };
