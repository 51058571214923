import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { RATES_STATUS } from 'app-wrapper/constants';

import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';

import { RFRByIdSecondStepComponent } from './RFRByIdSecondStep.component';

const RFRByIdSecondStepContainer: FC = () => {
  const { isCollapse } = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const getFreightQuoteCurrent = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const quotas = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentQuotas);

  return (
    <RFRByIdSecondStepComponent
      isCollapse={isCollapse}
      isLoad={getFreightQuoteCurrent?.isLoadingByIdRFR}
      isHaveQuotaId={!!getFreightQuoteCurrent?.RFRById?.quotaRequestId}
      isLoadCompletedRequest={getFreightQuoteCurrent?.quotaRFRRequestOptions?.isLoading}
      isLoadCompletedRequestComplete={getFreightQuoteCurrent?.RFRById?.status === RATES_STATUS.COMPLETED}
      isLoadCompletedRequestDone={getFreightQuoteCurrent?.RFRById?.status !== RATES_STATUS.PENDING}
      isLoadCompletedRequestReject={getFreightQuoteCurrent?.RFRById?.status === RATES_STATUS.CANCELLED}
      completeRequest={getFreightQuoteCurrent?.quotaRFRRequestOptions?.isCompletedRequest}
      isCompletedDone={!quotas?.allQuotas?.length}
      onCanceledRequest={UC.FreightRFRQuote.onClickCanceledRatesRequest}
      onCompletedRequest={UC.FreightRFRQuote.onClickCompleteRatesRequest}
      onCheckRatesRequest={UC.FreightRFRQuote.onClickCheckRatesRatesRequest}
    />
  );
};

const RFRByIdSecondStepContainerCached = memo(RFRByIdSecondStepContainer);

export { RFRByIdSecondStepContainerCached as RFRByIdSecondStep };
