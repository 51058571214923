import styled, { css } from 'styled-components';

import { divFlex, responsive } from 'app-wrapper/view/themes/mixin';
import { Input, Select } from 'app-wrapper/view/components';
import Button from 'antd/es/button';

const titleCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
`;

const secondTitleCss = css`
font-size: 12px;
font-weight: 400;
color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
`;

const inputCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  min-height: 32px;
`;

const blockNameCss = css`
font-size: 14px;
font-weight: 500;
color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
`;

const itemRowCss = css`
margin-top: 16px;
`;

const inputHalfCss = css`
width: calc(50% - 2px);
`;

const inputTopMarginCss = css`
  margin-top: 4px;
`;

export const CBDividerMargins = {
  margin: '0',
  marginTop: '16px',
};

export const ContactsAddressBlock = styled.div.attrs({
  'data-class': 'ContactsAddressBlock',
})`
  ${divFlex}
  ${itemRowCss}
  flex-direction: column;
  width: 100%;
`;

export const ContactsAddressBlockNameWrapper = styled.div.attrs({
  'data-class': 'ContactsAddressBlockNameWrapper',
})`
  ${divFlex}
  ${blockNameCss}
  width: 100%;
`;

export const CBContentBodyFormPrimary = styled.div.attrs({
  'data-class': 'CBContentBodyFormPrimary',
})`
  ${divFlex}
  width: 58px;
  height: 22px;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.themesColors.polarGreen1};
  color: ${({ theme }) => theme.themesColors.polarGreen7};
  font-size: 12px;
  font-weight: 400;
  border: 1px solid ${({ theme }) => theme.themesColors.polarGreen3};
  margin-left: 8px;
  border-radius: 3px;
`;

export const ContactsAddressBlockName = styled.div.attrs({
  'data-class': 'ContactsAddressBlockName',
})`
  ${divFlex}
  ${blockNameCss}
  width: 50%;
`;

export const ContactsAddressBlockDeleteButton = styled(Button).attrs({
  'data-class': 'ContactsAddressBlockDeleteButton',
})`
  ${divFlex}
  ${titleCss}

  height: 22px;
  align-items: center;
  justify-content: center;
  width: 82px;
  right: 0;
  margin-left: auto;
`;

// First

export const ContactsAddressBlockFirst = styled.div.attrs({
  'data-class': 'ContactsAddressBlockFirst',
})`
  ${divFlex}
  ${itemRowCss}
  flex-direction: column;
  width: 100%;
`;

export const ContactsAddressBlockFirstTitle = styled.div.attrs({
  'data-class': 'ContactsAddressBlockFirstTitle',
})`
  ${divFlex}
  ${titleCss}
  width: 100%;
`;

export const ContactsAddressBlockFirstInput = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
  'data-class'?: string,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': props['data-class'] || 'ContactsAddressBlockFirstInput',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}
  width: 100%;

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

// Second

export const ContactsAddressBlockSecond = styled.div.attrs({
  'data-class': 'ContactsAddressBlockSecond',
})`
  ${divFlex}
  ${itemRowCss}
  margin-top: 13px;
  flex-direction: column;
  width: 100%;
`;

export const ContactsAddressBlockSecondTitleDiv = styled.div.attrs({
  'data-class': 'ContactsAddressBlockSecondTitleDiv',
})`
  ${divFlex}
  ${titleCss}
  width: 100%;
`;

export const ContactsAddressBlockSecondTitle = styled.div.attrs({
  'data-class': 'ContactsAddressBlockSecondTitle',
})`
  ${divFlex}
  ${titleCss}
`;

export const ContactsAddressBlockSecondTitleSecond = styled.div.attrs({
  'data-class': 'ContactsAddressBlockSecondTitleSecond',
})`
  ${divFlex}
  ${titleCss}
  ${secondTitleCss}
  margin-left: 3px;
`;

export const ContactsAddressBlockSecondInputDiv = styled.div.attrs({
  'data-class': 'ContactsAddressBlockSecondInputDiv',
})`
  width: 100%;
  margin-top: 4px;
  ${divFlex}
`;

export const ContactsAddressBlockSecondCountryWrapper = styled.div.attrs({
  'data-class': 'ContactsAddressBlockSecondCountryWrapper',
})`
  ${divFlex}
  ${inputHalfCss}
  flex-direction: column;
  margin-left: 4px;
`;

export const ContactsAddressBlockSecondCountry = styled(Select).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'ContactsAddressBlockSecondCountry',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}
  width: 100%;


  .ant-select-item-option-content {
    font-size: 12px;
    color: ${({ theme }) => theme.themesColors.neutralBranding10};
  }

  border-width: 0px;
  .ant-select-item-option-content {
    font-family: "Roboto";
  }

  .ant-select-selector {
    min-width: ${({ theme }) => theme?.inputConst?.defaultHeight};
    ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
  }
  ${responsive.forFullMedia`
    .ant-select-selector {
      min-width: ${({ theme }: { theme: { inputConst: { hdHeight: string } } }) => theme?.inputConst?.hdHeight};
    }
  `}
  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

export const ContactsAddressBlockSecondStateWrapper = styled.div.attrs({
  'data-class': 'ContactsAddressBlockSecondStateWrapper',
})`
  ${divFlex}
  ${inputHalfCss}
  flex-direction: column;

  margin-left: 4px;
`;

export const ContactsAddressBlockSecondState = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'ContactsAddressBlockSecondState',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}
  width: 100%;

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

// Third

export const ContactsAddressBlockThird = styled.div.attrs({
  'data-class': 'ContactsAddressBlockThird',
})`
  ${divFlex}
  ${itemRowCss}
  margin-top: 13px;
  flex-direction: column;
  width: 100%;
`;

export const ContactsAddressBlockThirdTitleDiv = styled.div.attrs({
  'data-class': 'ContactsAddressBlockThirdTitleDiv',
})`
  ${divFlex}
  ${titleCss}
  width: 100%;
`;

export const ContactsAddressBlockThirdTitle = styled.div.attrs({
  'data-class': 'ContactsAddressBlockThirdTitle',
})`
  ${divFlex}
  ${titleCss}
`;

export const ContactsAddressBlockThirdInputDiv = styled.div.attrs({
  'data-class': 'ContactsAddressBlockThirdInputDiv',
})`
  width: 100%;
  margin-top: 4px;
  ${divFlex}
`;

export const ContactsAddressBlockThirdCityWrapper = styled.div.attrs({
  'data-class': 'ContactsAddressBlockThirdCityWrapper',
})`
  ${divFlex}
  ${inputHalfCss}
  flex-direction: column;
  margin-left: 4px;
`;

export const ContactsAddressBlockThirdCity = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'ContactsAddressBlockThirdCity',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}
  width: 100%;

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

export const ContactsAddressBlockThirdPostalCodeWrapper = styled.div.attrs({
  'data-class': 'ContactsAddressBlockThirdPostalCodeWrapper',
})`
  ${divFlex}
  ${inputHalfCss}
  flex-direction: column;
  margin-left: 4px;
`;

export const ContactsAddressBlockThirdPostalCode = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'ContactsAddressBlockThirdPostalCode',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}
  width: 100%;

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

// Third

export const ContactsAddressBlockFourth = styled.div.attrs({
  'data-class': 'ContactsAddressBlockFourth',
})`
  ${divFlex}
  ${itemRowCss}
  flex-direction: column;
  width: 100%;

  button {
    width: 134px;
    font-size: 14px;
    ${titleCss}
  }
  svg {
    color: ${({ theme }) => theme?.themesColors?.neutralBranding7};
  }
`;
