import styled, { css } from 'styled-components';

import { divFlex, responsive } from 'app-wrapper/view/themes/mixin';
import { Input, Select } from 'app-wrapper/view/components';

const inputCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  min-height: 32px;
`;

const inputTopMarginCss = css`
  margin-top: 4px;
`;

export const CAIAddress = styled.div.attrs({
  'data-class': 'CAIAddress',
})`
  ${divFlex}
  flex-direction: column;
  justify-content: center;
`;

export const CAISelect = styled(Select).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
  'data-class'?: string,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': props['data-class'] || 'CAISelect',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}
  width: 100%;


  .ant-select-item-option-content {
    font-size: 12px;
    color: ${({ theme }) => theme.themesColors.neutralBranding10};
  }

  border-width: 0px;
  .ant-select-item-option-content {
    font-family: "Roboto";
  }

  .ant-select-selector {
    min-width: ${({ theme }) => theme?.inputConst?.defaultHeight};
    ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
  }
  ${responsive.forFullMedia`
    .ant-select-selector {
      min-width: ${({ theme }: { theme: { inputConst: { hdHeight: string } } }) => theme?.inputConst?.hdHeight};
    }
  `}
  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

export const CAITitle = styled.div.attrs({
  'data-class': 'CAITitle',
})`
  ${divFlex}
  justify-content: start;
  align-items: center;

  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};

  svg {
    color: ${({ theme }) => theme.themesColors.secondaryDot45};
    margin-left: 4px;
  }
`;

export const CAISubTitle = styled.div.attrs({
  'data-class': 'CAISubTitle',
})`
  ${divFlex}
  justify-content: start;
  margin-left: 4px;

  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const CAISpaceInput = styled.div.attrs({
  'data-class': 'CAISpaceInput',
})`
  ${divFlex}
  width: 100%;

  margin-bottom: 16px;
`;

export const CAISpaceRow = styled.div.attrs({
  'data-class': 'CAISpaceRow',
})`
  ${divFlex}
  width: 100%;

  > div:not(:first-child) {
    margin-left: 8px;
  }
`;

export const CAISpaceColumn = styled.div.attrs((props: {
  widthProp: string,
}) => ({
  widthProp: props.widthProp,
  'data-class': 'CAISpaceColumn',
}))`
  ${divFlex}
  flex-direction: column;

  ${({ widthProp }) => widthProp && `width: ${widthProp}`};
`;

export const CAIHeader = styled.div.attrs({
  'data-class': 'CAIHeader',
})`
  ${divFlex}
  justify-content: start;

  margin-bottom: 16px;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;

export const CAIInput = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
  'data-class'?: string,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': props['data-class'] || 'CAIInput',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}
  width: 100%;

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;
