import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { PhoneInputComponent } from './PhoneInput.component';

const PhoneInputContainer: FC = () => {
  const phone = useSelector(R.selectors.shippingParties.getPhone);
  const requiredError = useSelector(R.selectors.shippingParties.getIsRequiredErrorVisible);
  const errorMessage = useSelector(R.selectors.shippingParties.getPhoneErrorMessage);
  const role = useSelector(R.selectors.shippingParties.getSelectedFormType);
  const permissions = useSelector(R.selectors.shippingParties.getShippingPartyPermissionsByRole(role));

  return (
    <PhoneInputComponent
      phone={phone}
      setPhone={UC.shippingParties.setPhone}
      error={requiredError}
      errorMessage={errorMessage}
      disabled={!permissions.isAbleToEditContacts}
    />
  );
};

const PhoneInputContainerCached = React.memo(PhoneInputContainer);

export {
  PhoneInputContainerCached as PhoneInput,
};
