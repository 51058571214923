import React, { useState } from 'react';

import { Commodity } from 'monetary/view/components/FreightQuoteFormRequest/Commodity';
import { Containers } from 'monetary/view/components/FreightQuoteFormRequest/Containers';

import {
  Overlay,
  OverlayBottom,
  OverlayWrap,
} from './CargoContainerOverlay.styled';

export const CargoContainerOverlayComponent = () => {
  const [formLocalState, setFormLocalState] = useState<{ isCollapsed?: boolean }>({
    isCollapsed: true,
  });

  return (
    <OverlayWrap>
      <Overlay>
        <Commodity />

        <Containers
          forceCollapse={false}
          formLocalState={formLocalState}
          setFormLocalState={setFormLocalState}
        />
      </Overlay>

      <OverlayBottom />
    </OverlayWrap>
  );
};
