import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import {
  SpinnerWrap,
  Spinner,
  Title,
  GapVerticalContainerStyled,
  Modal,
} from 'app-wrapper/view/guideline';
import { SmallTextFootnoteDescriptionSpan } from 'app-wrapper/view/guideline/Font/Font.styled';
import { Input } from 'app-wrapper/view/components';

const MAX_LENGTH = 100;

interface CancelShipmentModalComponentProps {
  isPending: boolean;
  onClose: () => void;
  onOk: (shipmentId: string) => void;
  mblNumber: string;
  setMBLNumber: (mblNumber: string) => void;
  title: string;
  label: string;
}

export const EditMBLNumberModalComponent: FC<CancelShipmentModalComponentProps> = ({
  isPending,
  onOk,
  onClose,
  mblNumber,
  setMBLNumber,
  title,
  label,
}) => {
  const { shipmentId = '' } = useParams();
  const { t } = useTranslation();

  const handleOk = useCallback(() => {
    onOk(shipmentId);
  }, [onOk, shipmentId]);

  const handleMBLNumber = useCallback((e) => {
    setMBLNumber(e.target.value);
  }, [setMBLNumber]);

  return (
    <Modal
      visible
      onCancel={onClose}
      onOk={handleOk}
      okText={t('Save')}
      cancelText={t('Close')}
      okButtonProps={{
        disabled: isPending || !mblNumber.length,
      }}
      cancelButtonProps={{
        disabled: isPending,
      }}
    >
      {isPending ? (
        <SpinnerWrap
          style={{
            minHeight: '140px',
          }}
        >
          <Spinner size="large" />
        </SpinnerWrap>
      ) : (
        <>
          <GapVerticalContainerStyled>
            <Title>
              {title}
            </Title>

            <SmallTextFootnoteDescriptionSpan>
              {label} *
            </SmallTextFootnoteDescriptionSpan>

            <Input
              data-class="modal-edit-reference-input"
              value={mblNumber}
              onChange={handleMBLNumber}
              maxLength={MAX_LENGTH}
            />
          </GapVerticalContainerStyled>
        </>
      )}
    </Modal>
  );
};
