import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { FullNameInputComponent } from './FullNameInput.component';

const FullNameInputContainer: FC = () => {
  const fullName = useSelector(R.selectors.shippingParties.getFullName);
  const requiredError = useSelector(R.selectors.shippingParties.getIsRequiredErrorVisible);
  const role = useSelector(R.selectors.shippingParties.getSelectedFormType);
  const permissions = useSelector(R.selectors.shippingParties.getShippingPartyPermissionsByRole(role));

  return (
    <FullNameInputComponent
      fullName={fullName}
      setFullName={UC.shippingParties.setFullName}
      error={requiredError}
      disabled={!permissions.isAbleToEditContacts}
    />
  );
};

const FullNameInputContainerCached = React.memo(FullNameInputContainer);

export {
  FullNameInputContainerCached as FullNameInput,
};
