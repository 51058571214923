import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { store } from 'app-wrapper/store';

import { ShipmentDrawerContent } from 'shipment-operations/constants';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { TransportationLocationDataComponent } from './TransportationLocationData.component';

const getReleaseDateLabelBySelectedContent = (contentType?: ShipmentDrawerContent) => {
  if (contentType === ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_LOCATION_DESTINATION_CONTAINER_TERMINAL_OPERATOR) {
    return 'Earliest CTO Full Drop Date';
  }
  if (contentType === ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_LOCATION_ORIGIN_CONTAINER_TERMINAL_OPERATOR) {
    return 'Earliest CTO Full Drop Date';
  }

  if (contentType === ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_LOCATION_DESTINATION_CONTAINER_YARD) {
    return 'Earliest CY Empty Release';
  }
  if (contentType === ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_LOCATION_ORIGIN_CONTAINER_YARD) {
    return 'Earliest CY Empty Release';
  }

  return 'Not found label';
};
const HIDDEN_DATE_TYPES = [
  ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_LOCATION_DESTINATION_CONTAINER_YARD,
  ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_LOCATION_DESTINATION_CONTAINER_TERMINAL_OPERATOR,
];
const TIME_FORMAT = 'DD MMM, HH:mm';

const TransportationLocationDataContainer: FC = () => {
  const countriesList = useSelector(R.selectors.shipmentTransportationLocationData.getCountriesList);
  const data = useSelector(R.selectors.shipmentTransportationLocationData.getFullData);
  const wasDataChanged = useSelector(R.selectors.shipmentTransportationLocationData.wasFormDataChanged);
  const terminalNameError = useSelector(R.selectors.shipmentTransportationLocationData.getTerminalNameError);
  const passCodeError = useSelector(R.selectors.shipmentTransportationLocationData.getPassCodeError);
  const address1Error = useSelector(R.selectors.shipmentTransportationLocationData.getAddress1Error);
  const address2Error = useSelector(R.selectors.shipmentTransportationLocationData.getAddress2Error);
  const address3Error = useSelector(R.selectors.shipmentTransportationLocationData.getAddress3Error);
  const address4Error = useSelector(R.selectors.shipmentTransportationLocationData.getAddress4Error);
  const cityError = useSelector(R.selectors.shipmentTransportationLocationData.getCityError);
  const stateError = useSelector(R.selectors.shipmentTransportationLocationData.getStateError);
  const postcodeError = useSelector(R.selectors.shipmentTransportationLocationData.getPostcodeError);
  const countryError = useSelector(R.selectors.shipmentTransportationLocationData.getCountryError);
  const isLoading = useSelector(R.selectors.shipmentTransportationLocationData.getLoading);
  const contentType = useSelector(R.selectors.shipmentDrawer.getContentType);
  const shipment = useSelector(R.selectors.shipment.getShipment);
  const freightuneCutoffDate = R.selectors.transportationOverview.getSavedFreightuneCutoffDates(store.getState());
  const portCutoff = freightuneCutoffDate?.portCarrierDate;
  const releaseDateCYEmpty = useSelector(R.selectors.shipmentTransportationLocationData.getReleaseDateCYEmpty);
  return data ? (
    <TransportationLocationDataComponent
      isLoading={isLoading}
      countriesList={countriesList}
      data={data}
      shipmentOriginWaypoint={shipment?.origin}
      portCutoff={portCutoff}
      wasAnyFormFieldChanged={wasDataChanged}
      hideDate={contentType && HIDDEN_DATE_TYPES.includes(contentType)}
      releaseDateLabel={getReleaseDateLabelBySelectedContent(contentType)}
      terminalNameError={terminalNameError}
      timeFormat={TIME_FORMAT}
      passCodeError={passCodeError}
      address1Error={address1Error}
      address2Error={address2Error}
      address3Error={address3Error}
      address4Error={address4Error}
      cityError={cityError}
      stateError={stateError}
      postcodeError={postcodeError}
      countryError={countryError}
      releaseDateCYEmpty={releaseDateCYEmpty}
      terminalNameChange={UC.shipmentTransportationLocation.terminalNameChange}
      passCodeChange={UC.shipmentTransportationLocation.passCodeChange}
      address1Change={UC.shipmentTransportationLocation.address1Change}
      address2Change={UC.shipmentTransportationLocation.address2Change}
      address3Change={UC.shipmentTransportationLocation.address3Change}
      address4Change={UC.shipmentTransportationLocation.address4Change}
      cityChange={UC.shipmentTransportationLocation.cityChange}
      stateChange={UC.shipmentTransportationLocation.stateChange}
      postcodeChange={UC.shipmentTransportationLocation.postcodeChange}
      countryChange={UC.shipmentTransportationLocation.countryChange}
      releaseDateChange={UC.shipmentTransportationLocation.releaseDateChange}
      terminalNameBlur={UC.shipmentTransportationLocation.terminalNameBlur}
      passCodeBlur={UC.shipmentTransportationLocation.passCodeBlur}
      address1Blur={UC.shipmentTransportationLocation.address1Blur}
      address2Blur={UC.shipmentTransportationLocation.address2Blur}
      address3Blur={UC.shipmentTransportationLocation.address3Blur}
      address4Blur={UC.shipmentTransportationLocation.address4Blur}
      cityBlur={UC.shipmentTransportationLocation.cityBlur}
      stateBlur={UC.shipmentTransportationLocation.stateBlur}
      postcodeBlur={UC.shipmentTransportationLocation.postcodeBlur}
      countryBlur={UC.shipmentTransportationLocation.countryBlur}
      releaseDateBlur={UC.shipmentTransportationLocation.releaseDateBlur}
      onOk={UC.shipmentTransportationLocation.updateData}
      onCancel={UC.shipmentTransportationLocation.closeDrawer}
    />
  ) : null;
};

export { TransportationLocationDataContainer as TransportationLocationData };
