import React, {
  FC, memo, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Option } from 'app-wrapper/view/components';

import { AccountDepartmentDTM, IContactBookStateErrorsFieldDTM, OrganizationMemberDTM } from 'user-management/models/dtm';
import { TooltipError } from 'user-management/view/components';

import {
  ADprtmEMCContactPersonBlock,
  ADprtmEMCContactPersonBlockFirst,
  ADprtmEMCContactPersonBlockFirstInput,
  ADprtmEMCContactPersonBlockFirstTitle,
  ADprtmEMCContactPersonBlockSecond,
  ADprtmEMCContactPersonBlockSecondPhone,
  ADprtmEMCContactPersonBlockSecondPhoneWrapper,
  ADprtmEMCContactPersonBlockSecondInputDiv,
  ADprtmEMCContactPersonBlockSecondTitle,
  CBDividerMargins,
  ADprtmEMCContactPersonBlockFirstInputDiv,
  ADprtmEMCContactPersonBlockFirstInputWrapperRight,
  ContainersSelect,
} from './AccountDepartmentEditMemberComponent.styled';

interface IAccountDepartmentEditMemberComponentCompProps {
  department?: AccountDepartmentDTM
  memberList?: OrganizationMemberDTM[]
  errorsEmail?: IContactBookStateErrorsFieldDTM
  errorsPhone?: IContactBookStateErrorsFieldDTM
  onChangeEmail: (email: string) => void
  onBlurEmail: () => void
  onFocusEmail: () => void
  onChangePhone: (phone: string) => void
  onBlurPhone: () => void
  onFocusPhone: () => void
  onChangeMembers: (member?: string[]) => void
}

const AccountDepartmentEditMemberComponentComp: FC<IAccountDepartmentEditMemberComponentCompProps> = (props) => {
  const {
    department,
    memberList,
    errorsEmail,
    errorsPhone,
    onChangeEmail,
    onBlurEmail,
    onFocusEmail,
    onChangePhone,
    onBlurPhone,
    onFocusPhone,
    onChangeMembers,
  } = props;
  const { t } = useTranslation();

  const onChangeHandlerEmail = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeEmail(event.target.value);
    },
    [onChangeEmail],
  );

  const hasErrorEmail = useMemo(() => !!(errorsEmail
    && errorsEmail?.message
    && (errorsEmail?.isBlur || errorsEmail?.isVisited)), [errorsEmail]);

  const onChangeHandlerPhone = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangePhone(event.target.value);
    },
    [onChangePhone],
  );

  const hasErrorPhone = useMemo(() => !!(errorsPhone
    && errorsPhone?.message
    && (errorsPhone?.isBlur || errorsPhone?.isVisited)), [errorsPhone]);

  const isVisibleEmail = useMemo(() => {
    const msg = errorsEmail?.message !== t('basicerrors?.REQUIRED_MESSAGE');
    return !!(hasErrorEmail && errorsEmail?.isTooltipVisible && msg);
  }, [errorsEmail, hasErrorEmail]);

  const isVisiblePhone = useMemo(() => {
    const msg = errorsPhone?.message !== t('basicerrors?.REQUIRED_MESSAGE');

    return !!(hasErrorPhone && errorsPhone?.isTooltipVisible && msg);
  }, [errorsPhone, hasErrorPhone]);

  const membersType = useMemo(() => {
    const value = department?.members?.map((item) => (item?.getFullName() || null))?.filter((item) => item);
    return value;
  }, [department]);

  const membersOptionsType = useMemo(() => memberList?.filter((item) => (item.getFullName()))?.map((item) => ({
    value: item.getFullName(),
    label: item.getFullName() || '',
  })) || [], [memberList]);

  return (
    <ADprtmEMCContactPersonBlock>

      <ADprtmEMCContactPersonBlockFirst>
        <ADprtmEMCContactPersonBlockFirstInputDiv>
          <ADprtmEMCContactPersonBlockFirstInputWrapperRight>
            <ADprtmEMCContactPersonBlockFirstTitle>
              {`${t('Email')}`}
            </ADprtmEMCContactPersonBlockFirstTitle>
            <TooltipError
              message={errorsEmail?.message || ''}
              visible={isVisibleEmail}
            >
              <ADprtmEMCContactPersonBlockFirstInput
                value={department?.email || ''}
                hasError={hasErrorEmail}
                onChange={onChangeHandlerEmail}
                onBlur={onBlurEmail}
                onFocus={onFocusEmail}
              />
            </TooltipError>
          </ADprtmEMCContactPersonBlockFirstInputWrapperRight>
        </ADprtmEMCContactPersonBlockFirstInputDiv>
      </ADprtmEMCContactPersonBlockFirst>

      <ADprtmEMCContactPersonBlockSecond>
        <ADprtmEMCContactPersonBlockSecondInputDiv>
          <ADprtmEMCContactPersonBlockSecondPhoneWrapper>
            <ADprtmEMCContactPersonBlockSecondTitle>
              {`${t('Phone')}`}
            </ADprtmEMCContactPersonBlockSecondTitle>
            <TooltipError
              message={errorsPhone?.message || ''}
              visible={isVisiblePhone}
            >
              <ADprtmEMCContactPersonBlockSecondPhone
                value={department?.phone || ''}
                hasError={hasErrorPhone}
                onChange={onChangeHandlerPhone}
                onBlur={onBlurPhone}
                onFocus={onFocusPhone}
              />
            </TooltipError>
          </ADprtmEMCContactPersonBlockSecondPhoneWrapper>
        </ADprtmEMCContactPersonBlockSecondInputDiv>
      </ADprtmEMCContactPersonBlockSecond>

      <ADprtmEMCContactPersonBlockFirst>
        <ADprtmEMCContactPersonBlockFirstInputDiv>
          <ADprtmEMCContactPersonBlockFirstInputWrapperRight>
            <ADprtmEMCContactPersonBlockFirstTitle>
              {`${t('Members')}`}
            </ADprtmEMCContactPersonBlockFirstTitle>
            <ContainersSelect
              mode="multiple"
              value={membersType}
              key={department?.customId}
              data-class="membersTypeComponent"
              placeholder={t('')}
              onChange={onChangeMembers}
            >
              {membersOptionsType?.map((item, index) => (
                <Option key={`membersTypeComponent_${index + 1}`} value={item.value || ''}>{item.label}</Option>
              ))}
            </ContainersSelect>
          </ADprtmEMCContactPersonBlockFirstInputWrapperRight>
        </ADprtmEMCContactPersonBlockFirstInputDiv>
      </ADprtmEMCContactPersonBlockFirst>

      <Divider dashed style={CBDividerMargins} />

    </ADprtmEMCContactPersonBlock>
  );
};

const AccountDepartmentEditMemberComponentCompCached = memo(AccountDepartmentEditMemberComponentComp);

export { AccountDepartmentEditMemberComponentCompCached as AccountDepartmentEditMemberComponentComp };
