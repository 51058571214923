import styled from 'styled-components';

import { ServicesDivider } from 'app-wrapper/view/components';
import { ContentSection } from 'app-wrapper/view/guideline';
import { SmallTextFootnoteDescriptionSpan } from 'app-wrapper/view/fonts';

export const EditHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;

export const StyledDivider = styled(ServicesDivider)`
  border: 0.5px dashed ${({ theme }) => theme.themesColors.neutralBranding4};
`;

export const ObjectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledSection = styled(ContentSection)`
  flex-direction: column;
`;

export const SmallDivider = styled(StyledDivider)`
  margin: 6px 0;
`;

export const StyledKey = styled(SmallTextFootnoteDescriptionSpan)`
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;
