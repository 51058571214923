import i18n from 'app-wrapper/i18n/i18n';

import { ShipmentStatusEnum, ShipmentBookingStatusEnum } from './ShipmentStatus.enum';

export const ShipmentStatusNames: Record<ShipmentStatusEnum, string> = {
  SI_SUBMITTED: i18n.t('SI_SUBMITTED'),
  SI_CONFIRMED: i18n.t('SI_CONFIRMED'),
  CANCELLED: i18n.t('Cancelled'),
  DRAFT: i18n.t('Draft'),
  AWAITING_APPROVAL: i18n.t('Waiting Approval'),
  PENDING_CONFIRMATION: i18n.t('Pending Confirmation'),
  CONFIRMED: i18n.t('Confirmed'),
  IN_TRANSIT: i18n.t('In Transit'),
  DELIVERED: i18n.t('Delivered'),
  COMPLETED: i18n.t('Completed'),
};

export const ShipmentStatusColors: Record<ShipmentStatusEnum, string> = {
  SI_SUBMITTED: '',
  SI_CONFIRMED: '',
  CANCELLED: '#FF4D4F',
  DRAFT: '#6C79DE',
  AWAITING_APPROVAL: '#6C79DE',
  PENDING_CONFIRMATION: '#6C79DE',
  CONFIRMED: '#75CB3F',
  IN_TRANSIT: '#75CB3F',
  DELIVERED: '#75CB3F',
  COMPLETED: '#D1D7E2',
};

export const ShipmentBookingStatusNames: Record<ShipmentBookingStatusEnum, string> = {
  BOOKING_PREPARATION: i18n.t('Draft'),
  BOOKING_SUBMITTED: i18n.t('Booking Submitted'),
  BOOKING_CONFIRMED: i18n.t('Booking Confirmed'),
  BOOKING_DECLINED: i18n.t('Booking Declined'),
  BOOKING_AMENDMENT_SUBMITTED: i18n.t('Amendment Submitted'),
  BOOKING_AMENDMENT_CONFIRMED: i18n.t('Amendment Confirmed'),
  BOOKING_AMENDMENT_DECLINED: i18n.t('Amendment Declined'),
  BOOKING_CANCEL: i18n.t('Booking Cancelled'),
};

export const ShipmentBookingStatusColors: Record<ShipmentBookingStatusEnum, string> = {
  BOOKING_PREPARATION: '#6C79DE',
  BOOKING_SUBMITTED: '#6C79DE',
  BOOKING_CONFIRMED: '#75CB3F',
  BOOKING_DECLINED: '#FF4D4F',
  BOOKING_AMENDMENT_SUBMITTED: '#F1AE00',
  BOOKING_AMENDMENT_CONFIRMED: '#75CB3F',
  BOOKING_AMENDMENT_DECLINED: '#FF4D4F',
  BOOKING_CANCEL: '#FF4D4F',
};
