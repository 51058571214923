import i18n from 'app-wrapper/i18n/i18n';
import { BaseController, controller } from 'proto/BaseController';
import { R } from 'shipment-operations/repository';
import { apiWorker } from 'app-wrapper/repository/utilsServices';
import {
  ShipmentBillingInvoiceDtm, UpdatedCreditNoteItemDTM, CreatedCreditNoteDTM, LinkedCreditNoteDTM, ShippingPartyDTM,
} from 'shipment-operations/models/dtm';
import { OVER_BALANCE, DEFAULT_ERROR, EShippingPartyTypes } from 'shipment-operations/constants';
import uniqBy from 'lodash/fp/uniqBy';
import { PL_COUNTRY_CODE, RouteNames } from 'app-wrapper/constants';
import { R as AuthR } from 'authentication/repository';
import { R as userManagementR } from 'user-management/repository';

@controller
export class ShipmentBillingInvoiceController extends BaseController {
  loadData = async (shipmentId?: string, invoiceId?: string, invoiceType?: string) => {
    this.dispatch(R.actions.shipmentBillingInvoice.setIsLoadingCompanies(true));
    this.dispatch(R.actions.shipmentBillingInvoice.setIsLoading(true));

    if (!shipmentId || !invoiceId || !invoiceType) {
      this.dispatch(R.actions.shipmentBillingInvoice.setError(true));

      this.dispatch(R.actions.shipmentBillingInvoice.setIsLoadingCompanies(false));
      this.dispatch(R.actions.shipmentBillingInvoice.setIsLoading(false));
      return;
    }

    const userData = AuthR.selectors.auth.getUser(this.store.getState());

    let shipmentBillingPermissions;

    try {
      shipmentBillingPermissions = await R.services.moduleFunctionalityPermissions.getFunctionalityPermissions(String(shipmentId), null, userData.isAdmin);
    } catch (e) {
      this.navigate(RouteNames.SHIPMENTS());
      this.dispatch(R.actions.shipmentBillingInvoice.setIsLoadingCompanies(true));
      this.dispatch(R.actions.shipmentBillingInvoice.setIsLoading(true));

      console.error(e);

      return;
    }

    if (!shipmentBillingPermissions) {
      this.navigate(RouteNames.SHIPMENTS());
      this.dispatch(R.actions.shipmentBillingInvoice.setIsLoadingCompanies(true));
      this.dispatch(R.actions.shipmentBillingInvoice.setIsLoading(true));

      return;
    }

    this.dispatch(R.actions.shipmentBillingInvoice.setPermissions(shipmentBillingPermissions));

    let response: ShipmentBillingInvoiceDtm | null;

    try {
      response = await R.services.shipmentBillingInvoice.getShipmentInvoice(shipmentId, invoiceId, invoiceType);
    } catch (e) {
      this.dispatch(R.actions.shipmentBillingInvoice.setError(true));
      return;
    }
    this.dispatch(R.actions.shipmentBillingInvoice.setInvoice(response));

    const linkedPayments = response.transactions?.map((item) => item.source.id);

    let payments;

    if (linkedPayments?.length) {
      try {
        payments = await Promise.all(linkedPayments.map((item) => {
          if (response && item) {
            return R.services.makePayment.onGetPayment(invoiceType, item);
          }
          return null;
        }));
      } catch (e) {
        this.dispatch(R.actions.shipmentBillingInvoice.setLinkedPaymentsError(true));
        this.dispatch(R.actions.shipmentBillingInvoice.setIsLoading(false));
        console.error(e);
      }

      this.dispatch(R.actions.shipmentBillingInvoice.setLinkedPayments(payments));
    }

    let shippingPartyList: ShippingPartyDTM[] | null;

    try {
      shippingPartyList = await R.services.shippingParties.getList(shipmentId);
    } catch (e) {
      this.dispatch(R.actions.shipmentBillingInvoice.setLoadCompaniesError(true));
      this.dispatch(R.actions.shipmentBillingInvoice.setIsLoading(false));
      return;
    }

    const uniqCompanies = uniqBy((item) => item.id, shippingPartyList);
    if (!uniqCompanies) {
      this.dispatch(R.actions.shipmentBillingInvoice.setIsLoading(false));
      return;
    }

    this.dispatch(R.actions.shipmentBillingInvoice.onLoadCompaniesSuccess(uniqCompanies));

    let fetchedCreditNotes: LinkedCreditNoteDTM[] | null;

    this.dispatch(R.actions.shipmentBillingInvoice.setIsLoadingLinkedCreditNotes(true));

    try {
      fetchedCreditNotes = await R.services.shipmentBillingInvoice.getLinkedCreditNotes(shipmentId, invoiceId, invoiceType);
    } catch (e) {
      this.dispatch(R.actions.shipmentBillingInvoice.loadLinkedCreditNotesFailed());
      this.dispatch(R.actions.shipmentBillingInvoice.setIsLoading(false));
      return;
    }

    const shipment = await R.services.shipment.getShipmentShortById(+shipmentId);

    if (shipment) {
      const customerRelatedAdmin = await userManagementR.services.organization.getRelatedAdminPublicInfo(shipment.customerOrgId);

      this.dispatch(R.actions.shipmentBillingInvoice.setRelatedAdmin(customerRelatedAdmin));

      const accountHolderPublicInfo = await userManagementR.services.organization.getCustomerRelatedPartnerPublicInfo(shipment.accountHolderOrgId);
      const customerShippingParty = shippingPartyList.find(({ role }) => role === EShippingPartyTypes.CUSTOMER);

      if (accountHolderPublicInfo?.address && customerShippingParty?.company && response?.billTo) {
        const { address } = accountHolderPublicInfo;
        const { billTo } = response;

        this.dispatch(R.actions.shipmentBillingInvoice.setShouldShowPLBankInfo(address?.country === PL_COUNTRY_CODE && billTo?.id === customerShippingParty.company.id));
      }
    }

    this.dispatch(R.actions.shipmentBillingInvoice.setIsLoading(false));
    this.dispatch(R.actions.shipmentBillingInvoice.loadLinkedCreditNotesSuccess(fetchedCreditNotes));
  }

  setSelectedItem = (id: number) => this.dispatch(R.actions.shipmentBillingInvoice.setSelectedItem(id));

  setGroupSelectedItems = (elems: number[]) => this.dispatch(R.actions.shipmentBillingInvoice.setSubTableItems(elems));

  clearSelectedItems = () => this.dispatch(R.actions.shipmentBillingInvoice.clearSelectedItems());

  clearUpdatedItems = () => this.dispatch(R.actions.shipmentBillingInvoice.clearUpdatedItems());

  setNotes = (values: string) => this.dispatch(R.actions.shipmentBillingInvoice.setNotes(values));

  setListUpdatedItems = () => {
    const selectedItems = R.selectors.shipmentBillingInvoice.getSelectedItems(this.store.getState());
    const charges = R.selectors.shipmentBillingInvoice.getCharges(this.store.getState());
    const fullListUpdatedItems = charges?.filter((item) => selectedItems.includes(item.id));
    const preparedItems = fullListUpdatedItems?.map((item) => ({
      invoiceItem: {
        id: item.id,
      },
      costPerUnit: item.costPerUnit,
      numberOfUnits: 1,
      amount: item.invoiced,
    }));
    this.dispatch(R.actions.shipmentBillingInvoice.setListUpdatedItems(preparedItems));
  }

  setUpdatedItem = (item: UpdatedCreditNoteItemDTM) => this.dispatch(R.actions.shipmentBillingInvoice.setUpdatedItem(item));

  createCreditNote = async (shipmentId?: string, invoiceId?: string, type?: string) => {
    if (!shipmentId || !invoiceId || !type) {
      return;
    }

    const balance = R.selectors.shipmentBillingInvoice.getInvoiceBalance(this.store.getState());
    const updatedSum = R.selectors.shipmentBillingInvoice.getSumOfUpdatedItems(this.store.getState());

    if ((balance || 0) < updatedSum) {
      this.dispatch(R.actions.shipmentBillingInvoice.onCreateCreditNoteError(OVER_BALANCE));
      return;
    }

    this.dispatch(R.actions.shipmentBillingInvoice.setCreditNoteLoading(true));

    const { updatedItems, notes } = this.store.getState().shipmentBillingInvoice.creditNote;
    const reason = 'WAIVER';

    let creditNote: CreatedCreditNoteDTM | null;

    try {
      creditNote = await R.services.shipmentBillingInvoice.createCreditNote(shipmentId, invoiceId, updatedItems, notes, reason, type);
    } catch (e) {
      this.dispatch(R.actions.shipmentBillingInvoice.onCreateCreditNoteError(DEFAULT_ERROR));

      return;
    }
    this.callSuccessNotification(`${i18n.t('createCreditNoteSuccessStart')} ${creditNote?.number} ${i18n.t('createCreditNoteSuccess')}`);

    this.dispatch(R.actions.shipmentBillingInvoice.onCreateCreditNoteSuccess(creditNote));
  }

  resetError = () => this.dispatch(R.actions.shipmentBillingInvoice.setCreditNoteError(false));

  resetSuccess = () => this.dispatch(R.actions.shipmentBillingInvoice.setCreditNoteSuccess(false));

  resetCreditNote = () => {
    apiWorker.abortAllRequests();
    this.dispatch(R.actions.shipmentBillingInvoice.clearCreditNote());
  };

  openCreateCreditNote = () => {
    this.dispatch(R.actions.shipmentBillingInvoice.setIsOpenCreditNote(true));
    this.dispatch(R.actions.shipmentBillingInvoice.setCreditNoteSuccess(false));
  }

  openPayment = () => {
    this.dispatch(R.actions.shipmentBillingInvoice.setIsOpenPayment(true));
  }

  closePayment = () => {
    this.dispatch(R.actions.shipmentBillingInvoice.setIsOpenPayment(false));
    this.dispatch(R.actions.makePayment.clear());
  }

  closeCreateCreditNote = () => {
    this.dispatch(R.actions.shipmentBillingInvoice.setIsOpenCreditNote(false));
    this.dispatch(R.actions.shipmentBillingInvoice.clearSelectedItems());
  }
}
