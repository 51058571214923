import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CustomerServiceOutlined from '@ant-design/icons/CustomerServiceOutlined';
import DollarCircleOutlined from '@ant-design/icons/DollarCircleOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import EyeOutlined from '@ant-design/icons/EyeOutlined';

import { QUOTAS_STATUS } from 'app-wrapper/constants';
import themesColors from 'app-wrapper/view/themes/themesColors';

import { RFQLoaderContainer, SupportDialogBlockComponent } from 'monetary/view/components';

import {
  RFQResponseTextStatus,
  Wrapper,
} from './NoneContent.styled';

interface INoneContentProps {
  isLoading?: boolean;
  isLoadingQuotas?: boolean;
}

export const NoneContent: FC<INoneContentProps> = (props) => {
  const {
    isLoading,
    isLoadingQuotas,
  } = props;
  const { t } = useTranslation();

  const contentNotFailed = useMemo(() => (
    <>
      {isLoading ? (
        <RFQLoaderContainer
          nameLogo="Searching Quotes"
        />
      )
        : (
          <>
            <SupportDialogBlockComponent
              icon={(<DollarCircleOutlined />)}
              iconColor={themesColors.primaryBranding6}
              headerText={t('Full Cycle Sea & Road Freight transport')}
              footerText={t('Your all-in logistics partner for pricing, booking, tracking, and customs for over 10,000 destinations.')}
              marginBottom="8px"
            />
            <SupportDialogBlockComponent
              icon={(<CustomerServiceOutlined />)}
              iconColor={themesColors.primaryBranding6}
              headerText={t('Dedicated Logistics Expert Teams')}
              footerText={t('Experience premier customer support for all your orders.')}
              marginBottom="8px"
            />
            <SupportDialogBlockComponent
              icon={(<ClockCircleOutlined />)}
              iconColor={themesColors.primaryBranding6}
              headerText={t('Efficient, Tech-Driven Operations')}
              footerText={t('Benefit from advanced technology and workflow for fast and reliable process.')}
              marginBottom="8px"
            />
            <SupportDialogBlockComponent
              icon={(<EyeOutlined />)}
              iconColor={themesColors.primaryBranding6}
              headerText={t('Proactive Visibility')}
              footerText={t('Stay informed with real-time tracking and proactive updates on your shipments.')}
            />
          </>
        )}
    </>
  ), [isLoading, t]);

  return (
    <Wrapper>
      {isLoadingQuotas
        ? <RFQResponseTextStatus>{QUOTAS_STATUS.failed}</RFQResponseTextStatus>
        : contentNotFailed}
    </Wrapper>
  );
};
