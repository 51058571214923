import React, { FC, useCallback } from 'react';

import { Input, Tooltip } from 'app-wrapper/view/components';

interface IShippingPartiesEmailInputComponentProps {
  email?: string;
  setEmail: (email: string) => void;
  emailError?: string;
  disabled: boolean
}

export const EmailInputComponent: FC<IShippingPartiesEmailInputComponentProps> = ({
  email,
  setEmail,
  emailError,
  disabled,
}) => {
  const handleChange = useCallback((e) => {
    setEmail(e.target.value);
  }, [setEmail]);

  return (
    <Tooltip
      visible={!!emailError?.length}
      title={emailError}
      mode="danger"
    >
      <Input
        error={!!emailError?.length}
        value={email}
        onChange={handleChange}
        disabled={disabled}
      />
    </Tooltip>
  );
};
