import React, { FC, ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';
import Button from 'antd/es/button';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import MinusOutlined from '@ant-design/icons/MinusOutlined';

import { hasAccess } from 'app-wrapper/utils';
import { ValidationErrorType } from 'app-wrapper/types';
import {
  AlertBlock,
  Input,
  Select,
  Option,
  TwoInputsAndSmallButtonView,
} from 'app-wrapper/view/components';
import {
  CargoReferenceType,
  CargoReferenceTypeNames,
  PermissionAttributePolicy,
} from 'shipment-operations/constants';
import { CargoReferenceDTM, CargoReferenceErrorsDTM } from 'shipment-operations/models/dtm';

import { Container } from './CargoReferencesForm.styled';

interface ICargoReferencesFormComponentProps {
  references: CargoReferenceDTM[],
  wasUpdateAttempted: boolean
  touchedFields: { [key: string]: boolean }
  errors?: CargoReferenceErrorsDTM[]
  addReference: () => void
  setReferenceType: (value: CargoReferenceType, index: number) => void
  setReferenceValue: (value: string, index: number) => void
  touchReferenceType: (index: number) => void
  touchReferenceValue: (index: number) => void
  removeReference: (index: number) => void
  cargosAvailability?: PermissionAttributePolicy
}

export const CargoReferencesFormComponent: FC<ICargoReferencesFormComponentProps> = ({
  references,
  wasUpdateAttempted,
  touchedFields,
  errors = [],
  setReferenceType,
  setReferenceValue,
  touchReferenceType,
  touchReferenceValue,
  addReference,
  removeReference,
  cargosAvailability,
}) => {
  const { t } = useTranslation();

  const anyAlert = errors.find((ref) => {
    if (!ref) {
      return false;
    }
    const { type: referenceTypeError } = ref;
    if (!referenceTypeError) {
      return false;
    }

    return referenceTypeError.type === ValidationErrorType.ALERT;
  })?.type;

  const createReferenceTypeHandler = (i: number) => (value: CargoReferenceType) => {
    setReferenceType(value, i);
  };

  const createReferenceValueHandler = (i: number) => (event: ChangeEvent<HTMLInputElement>) => {
    setReferenceValue(event.target.value, i);
  };

  const createReferenceTypeBlurHandler = (i: number) => () => {
    touchReferenceType(i);
  };

  const createReferenceValueBlurHandler = (i: number) => () => {
    touchReferenceValue(i);
  };

  const isDisabled = useMemo(() => !hasAccess(cargosAvailability, PermissionAttributePolicy.WRITE), [cargosAvailability]);

  return (
    <>
      <Container>
        <TwoInputsAndSmallButtonView size="middle">
          <Typography.Text>{t('Reference Type')}</Typography.Text>
          <Typography.Text>{t('Reference Number')}</Typography.Text>
        </TwoInputsAndSmallButtonView>
        {references.map((ref, i) => (
          <TwoInputsAndSmallButtonView key={ref.id} size="middle">
            <Select
              disabled={isDisabled}
              allowClear={!!ref.type}
              error={!!errors?.[i]?.type && (touchedFields[`references.${i}.type`] || wasUpdateAttempted)}
              value={ref.type}
              onChange={createReferenceTypeHandler(i)}
              onBlur={createReferenceTypeBlurHandler(i)}
            >
              {Object.keys(CargoReferenceTypeNames).map((typeName) => (
                <Option key={typeName} value={typeName}>
                  {t(CargoReferenceTypeNames[typeName as CargoReferenceType])}
                </Option>
              ))}
            </Select>
            <Input
              disabled={isDisabled}
              value={ref.value}
              onChange={createReferenceValueHandler(i)}
              error={!!errors?.[i]?.value && (touchedFields[`references.${i}.value`] || wasUpdateAttempted)}
              onBlur={createReferenceValueBlurHandler(i)}
            />
            <Button
              disabled={isDisabled}
              type="dashed"
              onClick={i === 0 ? addReference : () => removeReference(i)}
            >
              {i === 0 ? <PlusOutlined /> : <MinusOutlined />}
            </Button>
          </TwoInputsAndSmallButtonView>
        ))}
      </Container>
      {anyAlert ? <AlertBlock type="warning" message={t(anyAlert.message)} /> : null}
    </>
  );
};
