import {
  IsString, IsDefined, IsOptional, IsNumber,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export class BaseAddressDTM<IDTM> extends BaseDTM<IDTM> {
  @IsOptional()
  @IsNumber()
  id: number | null; // can be problem

  @IsDefined()
  @IsString()
  country: string;

  @IsOptional()
  @IsString()
  state?: string;

  @IsDefined()
  @IsString()
  city: string;

  @IsDefined()
  @IsString()
  address1: string;

  @IsOptional()
  @IsString()
  address2?: string;

  @IsDefined()
  @IsString()
  postalCode: string;

  @IsOptional()
  @IsString()
  closestPort?: string;

  cityAndState() {
    return [this.city, this.state].filter((s) => s).join(', ');
  }

  cityStatePostalCodeCountry() {
    return [
      this.city,
      [this.state, this.getPostalCode()].filter((s) => s).join(' '),
      this.country,
    ].filter((s) => s).join(', ');
  }

  getPostalCode() {
    return this.postalCode === 'N/A' ? '' : this.postalCode;
  }

  getAddressLine = () => {
    let addressLine = this.cityAndState();
    addressLine += `, ${this.country}, ${this.address1}, ${this.postalCode}`;

    return addressLine;
  }
}

export interface IAddressDTM {
  id: number | null
  country: string
  state?: string
  city: string
  address1: string
  address2?: string
  postalCode?: string
  closestPort?: string
}

export class AddressDTM extends BaseAddressDTM<IAddressDTM> {}
