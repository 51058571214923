import styled, { css } from 'styled-components';
import Button from 'antd/es/button';
import CopyOutlined from '@ant-design/icons/CopyOutlined';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { Input, Select } from 'app-wrapper/view/components';

const titleCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
`;

const secondTitleCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
`;

const inputCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  height: 32px;
`;

const blockNameCss = css`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
`;

const itemRowCss = css`
  margin-top: 10px;
`;

const inputTopMarginCss = css`
  margin-top: 5px;
`;

export const CBDividerMargins = {
  margin: '0',
  marginTop: '16px',
};

export const ContainersSelect = styled(Select).attrs((props: {
  isError: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  'data-class': props?.['data-class'] || 'ContainersSelect',
}))`
  .ant-select-selector {
    ${({ isError, theme }) => isError && `border-color: ${theme.themesColors.characterBrandingDanger} !important;`};
    ${({ isError }) => isError && 'z-index: 2;'};
  }
`;

export const ADprtmEMCContactPersonBlock = styled.div.attrs({
  'data-class': 'ADprtmEMCContactPersonBlock',
})`
  ${divFlex}
  ${itemRowCss}
  flex-direction: column;
  width: 100%;
`;

export const ADprtmEMCCopyIcon = styled(CopyOutlined).attrs({
  'data-class': 'ADprtmEMCCopyIcon',
})`
  color: #73819B;
  font-size: 12px;
  margin-left: 3px;
  cursor: copy !important;
`;

export const CBContentBodyFormPrimary = styled.div.attrs({
  'data-class': 'CBContentBodyFormPrimary',
})`
  ${divFlex}
  width: 58px;
  height: 22px;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.themesColors.polarGreen1};
  color: ${({ theme }) => theme.themesColors.polarGreen7};
  font-size: 12px;
  font-weight: 400;
  border: 1px solid ${({ theme }) => theme.themesColors.polarGreen3};
  margin-left: 8px;
  border-radius: 3px;
`;

export const ADprtmEMCContactPersonBlockNameWrapper = styled.div.attrs({
  'data-class': 'ADprtmEMCContactPersonBlockNameWrapper',
})`
  ${divFlex}
  ${blockNameCss}
  width: 100%;
`;

export const ADprtmEMCContactPersonBlockName = styled.div.attrs({
  'data-class': 'ADprtmEMCContactPersonBlockName',
})`
  ${divFlex}
  ${blockNameCss}
  width: 100%;
`;

export const ADprtmEMCContactPersonBlockDeleteButton = styled(Button).attrs({
  'data-class': 'ADprtmEMCContactPersonBlockDeleteButton',
})`
  ${divFlex}
  ${titleCss}

  height: 22px;
  align-items: center;
  justify-content: center;
  width: 82px;
  right: 0;
  margin-left: auto;
`;

// First

export const ADprtmEMCContactPersonBlockFirst = styled.div.attrs({
  'data-class': 'ADprtmEMCContactPersonBlockFirst',
})`
  ${divFlex}
  ${itemRowCss}
  flex-direction: column;
  width: 100%;
`;

export const ADprtmEMCContactPersonBlockFirstInputDiv = styled.div.attrs({
  'data-class': 'ADprtmEMCContactPersonBlockFirstInputDiv',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;
`;

export const ADprtmEMCContactPersonBlockFirstInputWrapper = styled.div.attrs({
  'data-class': 'ADprtmEMCContactPersonBlockFirstInputWrapper',
})`
  ${divFlex}
  flex-direction: column;
`;

export const ADprtmEMCContactPersonBlockFirstInputWrapperRight = styled.div.attrs({
  'data-class': 'ADprtmEMCContactPersonBlockFirstInputWrapperRight',
})`
  ${divFlex}

  margin-top: 10px;
  flex-direction: column;
`;

export const ADprtmEMCContactPersonBlockFirstTitle = styled.div.attrs({
  'data-class': 'ADprtmEMCContactPersonBlockFirstTitle',
})`
  ${divFlex}
  ${titleCss}
  width: 100%;
`;

export const ADprtmEMCContactPersonBlockFirstInput = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'ADprtmEMCContactPersonBlockFirstInput',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}

  width: 100%;

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

// Second

export const ADprtmEMCContactPersonBlockSecond = styled.div.attrs({
  'data-class': 'ADprtmEMCContactPersonBlockSecond',
})`
  ${divFlex}
  ${itemRowCss}
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
`;

export const ADprtmEMCContactPersonBlockSecondTitleDiv = styled.div.attrs({
  'data-class': 'ADprtmEMCContactPersonBlockSecondTitleDiv',
})`
  ${divFlex}
  ${titleCss}
  width: 100%;
`;

export const ADprtmEMCContactPersonBlockSecondTitle = styled.div.attrs({
  'data-class': 'ADprtmEMCContactPersonBlockSecondTitle',
})`
  ${divFlex}
  ${titleCss}
`;

export const ADprtmEMCContactPersonBlockSecondTitleSecond = styled.div.attrs({
  'data-class': 'ADprtmEMCContactPersonBlockSecondTitleSecond',
})`
  ${divFlex}
  ${titleCss}
  ${secondTitleCss}
  margin-left: 3px;
`;

export const ADprtmEMCContactPersonBlockSecondInputDiv = styled.div.attrs({
  'data-class': 'ADprtmEMCContactPersonBlockSecondInputDiv',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;
  margin-top: 4px;
`;

export const ADprtmEMCContactPersonBlockSecondPhoneWrapper = styled.div.attrs({
  'data-class': 'ADprtmEMCContactPersonBlockSecondPhoneWrapper',
})`
  ${divFlex}
  flex-direction: column;
`;

export const ADprtmEMCContactPersonBlockSecondPhone = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'ADprtmEMCContactPersonBlockSecondPhone',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}

  width: 100%;

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

export const ADprtmEMCContactPersonBlockSecondAPhoneWrapper = styled.div.attrs({
  'data-class': 'ADprtmEMCContactPersonBlockSecondAPhoneWrapper',
})`
  ${divFlex}

  margin-top: 10px;
  flex-direction: column;
`;

export const ADprtmEMCContactPersonBlockSecondAPhone = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'ADprtmEMCContactPersonBlockSecondAPhone',
}))`
  ${divFlex}
  ${inputCss}
  width: 100%;
  margin-top: 4px;

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

// Third

export const ADprtmEMCContactPersonBlockFourth = styled.div.attrs({
  'data-class': 'ADprtmEMCContactPersonBlockFourth',
})`
  ${divFlex}
  ${itemRowCss}
  flex-direction: column;
  width: 100%;

  button {
    width: 179px;
    ${titleCss}
    font-size: 14px;
  }
  svg {
    color: ${({ theme }) => theme?.themesColors?.neutralBranding7};
  }
`;
