import React, {
  FC,
} from 'react';
import { useSelector } from 'react-redux';

import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';

import { RFQContentComponent } from './RFQContent.component';

export interface IRFQContentProps {
  NoneContentRecommended?: React.ReactNode
  isAllRequestPage?: boolean
  isFooter?: boolean
  hideBook?: boolean
  hideFavorite?: boolean
}

export const RFQContent: FC<IRFQContentProps> = ({
  NoneContentRecommended,
  isAllRequestPage,
  isFooter,
  hideBook,
  hideFavorite,
}) => {
  const getFreightQuoteCurrent = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const freightQuoteFilters = useSelector(R.selectors.freightQuote.getFreightQuoteFilters);
  const getFreightQuoteCurrentFiltersPrice = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentFiltersPrice);
  const isHaveFreightQuoteCurrentFiltersParams = useSelector(R.selectors.freightQuote.getIsHaveFreightQuoteCurrentFiltersParams);
  const quotas = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentQuotas);
  const { quotasOption } = quotas || {};

  const filterMinPrice = getFreightQuoteCurrentFiltersPrice?.filterMin || '';
  const filterMaxPrice = getFreightQuoteCurrentFiltersPrice?.filterMax || '';
  const limitMinPrice = getFreightQuoteCurrentFiltersPrice?.limitMin || 0;
  const limitMaxPrice = getFreightQuoteCurrentFiltersPrice?.limitMax || 0;
  const disabledResetPrice = getFreightQuoteCurrentFiltersPrice?.disableReset || false;
  const isUpdatePrice = getFreightQuoteCurrentFiltersPrice?.isUpdate || false;

  const filterMinTransitTime = freightQuoteFilters?.valuesFilters?.filterTransitTime?.filterMin || '';
  const filterMaxTransitTime = freightQuoteFilters?.valuesFilters?.filterTransitTime?.filterMax || '';
  const limitMinTransitTime = freightQuoteFilters?.valuesFilters?.filterTransitTime?.limitMin || 0;
  const limitMaxTransitTime = freightQuoteFilters?.valuesFilters?.filterTransitTime?.limitMax || 0;
  const disabledResetTransitTime = freightQuoteFilters?.valuesFilters?.filterTransitTime?.disableReset || false;
  const isUpdateTransitTime = freightQuoteFilters?.valuesFilters?.filterTransitTime?.isUpdate || false;

  const groupCarrier = freightQuoteFilters?.valuesFilters?.filterCarrier?.group || [];
  const disabledResetCarrier = freightQuoteFilters?.valuesFilters?.filterCarrier?.disableReset || false;
  const isUpdateCarrier = freightQuoteFilters?.valuesFilters?.filterCarrier?.isUpdate || false;

  const isVisibleCheckSchedule = false;

  return (
    <RFQContentComponent
      NoneContentRecommended={NoneContentRecommended}
      isAllRequestPage={isAllRequestPage}
      isVisibleCheckSchedule={isVisibleCheckSchedule}
      isVisibleIsIncludeRelatedPortRequest={freightQuoteFilters?.isIncludeRelatedPortRequest}
      isFooter={isFooter}
      isRequestFilters={freightQuoteFilters?.isRequestFilters}
      requestFiltersStatus={freightQuoteFilters?.requestFiltersStatus}
      filters={{
        hideFilters: freightQuoteFilters?.hideFilters,
        isHaveFiltersParams: !!isHaveFreightQuoteCurrentFiltersParams,
        isRequestFilters: freightQuoteFilters?.isRequestFilters,
        filterIsFavorite: freightQuoteFilters?.filterIsFavorite,
        filterIsIncludeRelatedPortRequest: freightQuoteFilters?.filterIsIncludeRelatedPortRequest,
        visibleFilterIsFavorite: freightQuoteFilters?.visibleFilterIsFavorite,
        onChangeFilterIsFavorite: UC.FreightQuote.onChangeFilterIsFavorite(Number(quotasOption?.id)),
        onChangeFilterIsIncludeRelatedPortRequest: UC.FreightQuote.onChangeFilterIsIncludeRelatedPortRequest(Number(getFreightQuoteCurrent?.quotaId || quotasOption?.id)),
        filterPrice: {
          onApply: UC.FreightQuote.onApplyFilterPrice(Number(quotasOption?.id)),
          onReset: UC.FreightQuote.resetFilterPrice(Number(quotasOption?.id)),
          onChangeFilterMin: UC.FreightQuote.setFilterPriceMin,
          onChangeFilterMax: UC.FreightQuote.setFilterPriceMax,
          filterMin: filterMinPrice,
          filterMax: filterMaxPrice,
          limitMin: limitMinPrice,
          limitMax: limitMaxPrice,
          disabledReset: disabledResetPrice,
          isUpdate: isUpdatePrice,
        },
        filterTransitTime: {
          onApply: UC.FreightQuote.onApplyFilterTransitTime(Number(quotasOption?.id)),
          onReset: UC.FreightQuote.resetFilterTransitTime(Number(quotasOption?.id)),
          onChangeFilterMin: UC.FreightQuote.setFilterTransitTimeMin,
          onChangeFilterMax: UC.FreightQuote.setFilterTransitTimeMax,
          filterMin: filterMinTransitTime,
          filterMax: filterMaxTransitTime,
          limitMin: limitMinTransitTime,
          limitMax: limitMaxTransitTime,
          disabledReset: disabledResetTransitTime,
          isUpdate: isUpdateTransitTime,
        },
        carrier: {
          onApply: UC.FreightQuote.onApplyFilterCarrier(Number(quotasOption?.id)),
          onReset: UC.FreightQuote.resetFilterByDefaultCarrier(Number(quotasOption?.id)),
          onChangeGroup: UC.FreightQuote.onChangeGroup,
          group: groupCarrier,
          disabledReset: disabledResetCarrier,
          isUpdate: isUpdateCarrier,
        },
      }}
      quotaRequestServiceByIdData={quotas?.allQuotas}
      quotaRequestServiceById={quotas}
      fullCompleteStatus={getFreightQuoteCurrent?.fullCompleteStatus}
      hideBook={hideBook}
      hideFavorite={hideFavorite}
      hasNextPage={!quotasOption?.isEnd}
      onRunRFQContentComponent={UC.FreightQuote.onRunRFQContentComponent}
      onScrollNextInfinityPage={UC.FreightQuote.onScrollNextInfinityPage}
    />
  );
};
