import React from 'react';
import { useTranslation } from 'react-i18next';

import { SubPageHeaderTitle, GapHorizontalContainerStyled } from 'app-wrapper/view/guideline';
import { Button } from 'app-wrapper/view/components';
import { SubTitle, Wrapper } from './Header.styled';

const HeaderComponent = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <div>
        <SubPageHeaderTitle>{t('Booking summary')}</SubPageHeaderTitle>
        <SubTitle>{t('Please make sure the information is correct before sending')}</SubTitle>
      </div>
      <GapHorizontalContainerStyled>
        <Button danger type="default">{t('Decline')}</Button>
        <Button type="default">{t('Check later')}</Button>
        <Button type="primary">{t('Approve booking')}</Button>
      </GapHorizontalContainerStyled>
    </Wrapper>
  );
};

export { HeaderComponent };
