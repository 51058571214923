import styled, { css } from 'styled-components';

import { Input } from 'app-wrapper/view/components';
import { divFlex } from 'app-wrapper/view/themes/mixin';

const inputCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  min-height: 32px;
`;

const inputTopMarginCss = css`
  margin-top: 4px;
`;

export const SelectItemInput = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'SelectItemInput',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}
  width: 100%;

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;
