import { CSSProperties } from 'react';
import styled from 'styled-components';

export const GapVerticalContainerStyled = styled.div.attrs((props: {
  width?: string, noGap?: boolean, gap?: string, justifyContent?: CSSProperties['justifyContent']
}) => ({
  'data-class': 'GapVerticalContainerStyled',
  justifyContent: props.justifyContent,
  width: props.width,
  noGap: props.noGap,
  gap: props.gap,
}))`
  display: flex;
  flex-direction: column;
  gap: ${({ noGap, gap }) => (noGap ? '0' : false) || (gap || '8px')};
  ${({ width }) => (width ? `width: ${width};` : '')}
  justify-content: ${({ justifyContent }) => (justifyContent || 'flex-end')};
`;
