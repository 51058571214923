import React from 'react';
import { observer } from 'mobx-react-lite';

import { ConfirmationBookingDetailsController } from 'shipment-operations/controllers/ConfirmationBookingDetails';
import { useCurrentController } from 'app-wrapper/view/routes/components';
import { useMobxStore } from 'app-wrapper/mobxStores';
import { BookingDetailsComponent } from './BookingDetails.component';

const BookingDetailsContainer = observer(() => {
  const { confirmationBookingDetailsStore } = useMobxStore();
  const controller = useCurrentController<ConfirmationBookingDetailsController>();

  return (
    <BookingDetailsComponent
      withIntra={false}
      bookingNumber={confirmationBookingDetailsStore.getOriginalBookingNumber}
      bookingNumberForEdit={confirmationBookingDetailsStore.getBookingNumber}
      toggleEdit={controller.toggleEditModal}
      editHost={confirmationBookingDetailsStore.getEditModalHost}
      onChangeBookingNumber={controller.onChangeBookingNumber}
      onSaveBookingNumber={controller.onSavedBookingNumber}
      pending={confirmationBookingDetailsStore.isPending}
    />
  );
});

export { BookingDetailsContainer as BookingDetails };
