import styled, { css } from 'styled-components';

const textColorFirst = css`
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
`;

export const divFlex = css`
  display: flex;
  position: relative;
  ${textColorFirst}
`;

export const RFQResponseTextStatus = styled.div`
  ${divFlex}
  align-items: center;
  justify-content: center;
`;

export const LoaderContainer = styled.div`
  ${divFlex}
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
