import React, {
  FC,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';
import MinusOutlined from '@ant-design/icons/MinusOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';

import {
  Input, Select, Option, Button, AlertBlock,
} from 'app-wrapper/view/components';
import { ValidationErrorType } from 'app-wrapper/types';
import {
  DocumentsDistributionErrorsDTM,
  DocumentsDistributionDTM,
  IShippingPartiesMapDTM,
  ShippingPartyOverviewDTM,
} from 'shipment-operations/models/dtm';
import {
  EShippingPartyTypes,
  SHIPPING_PARTIES_PAGE_NAMES,
  PermissionAttributePolicy,
  BillOfLadingType,
} from 'shipment-operations/constants';
import { CompanyName } from 'shipment-operations/view/pages/Shipment/components/ShipmentDrawer/components/EditBillOfLading/components/BillOfLadingCopiesDistribution/BillOfLadingCopiesDistribution.styled';

import {
  BillOfLadingOriginalsDistributionContainer,
  BillOfLadingOriginalsDistributionEntry,
  BillOfLadingAmountLabel,
} from './BillOfLadingOriginalsDistribution.styled';
import { BillOfLadingFreightedSwitch } from '../BillOfLaddingFreightedSwitch';

interface IBillOfLadingOriginalsDistributionComponentProps {
  shippingPartiesMap: IShippingPartiesMapDTM
  shippingPartiesRecord: Record<EShippingPartyTypes, ShippingPartyOverviewDTM>
  billOfLadingType?: BillOfLadingType
  entries: DocumentsDistributionDTM[]
  errors?: DocumentsDistributionErrorsDTM[]
  touchedFields: { [key: string]: boolean }
  wasUpdateAttempted?: boolean
  touchField: (field: string) => void
  setShippingParty: (party: EShippingPartyTypes | undefined, i: number) => void
  setAmount: (amount: string, i: number) => void
  setFreighted: (freighted: boolean, index: number) => void
  addEntry: () => void
  removeEntry: (i: number) => void
  billOfLadingPermission?: PermissionAttributePolicy
  isOnlyHouseConsigneeAvailable: boolean;
}

export const BillOfLadingOriginalsDistributionComponent: FC<IBillOfLadingOriginalsDistributionComponentProps> = ({
  shippingPartiesMap,
  shippingPartiesRecord,
  billOfLadingType,
  entries,
  errors = [],
  touchedFields,
  wasUpdateAttempted,
  touchField,
  setShippingParty,
  setFreighted,
  setAmount,
  addEntry,
  removeEntry,
  billOfLadingPermission,
  isOnlyHouseConsigneeAvailable,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isOnlyHouseConsigneeAvailable) {
      const houseConsignee = Object.values(shippingPartiesMap).find((party) => party === EShippingPartyTypes.HOUSE_CONSIGNEE);

      if (houseConsignee) {
        entries.forEach((entry, index) => {
          if (index > 0) {
            removeEntry(1);
          }
        });

        setShippingParty(houseConsignee, 0);
      } else {
        setShippingParty(undefined, 0);
      }
    }
  }, [isOnlyHouseConsigneeAvailable]);

  useEffect(() => {
    if (billOfLadingType && !entries.length) {
      addEntry();
    }
  }, [billOfLadingType]);

  const createShippingPartySelectHandler = (i: number) => (party: EShippingPartyTypes) => (
    setShippingParty(party, i)
  );

  const createShippingAmountInputHandler = (i: number) => (event: React.ChangeEvent<HTMLInputElement>) => (
    setAmount(event.target.value, i)
  );

  const createRemoveEntry = (i: number) => () => {
    removeEntry(i);
  };

  const createTouchPartyHandler = (i: number) => () => {
    touchField(`originals.${i}.party`);
  };

  const createTouchAmountHandler = (i: number) => () => {
    touchField(`originals.${i}.amount`);
  };

  const createFreightedHandler = useCallback((i: number) => (freighted: boolean) => {
    setFreighted(freighted, i);
  }, [setFreighted]);

  const alertError = errors.find((error) => !!error?.amount && error.amount.type === ValidationErrorType.ALERT)?.amount?.message;

  const isDisabled = useMemo(() => billOfLadingPermission !== PermissionAttributePolicy.WRITE, [billOfLadingPermission]);

  return billOfLadingType ? (
    <BillOfLadingOriginalsDistributionContainer>
      <BillOfLadingOriginalsDistributionEntry>
        <Typography.Text>{t('Send Original BL to')}</Typography.Text>
        <BillOfLadingAmountLabel>
          <Typography.Text>
            {t('Originals')}
          </Typography.Text>
          <Typography.Text>
            {t('Max 3')}
          </Typography.Text>
        </BillOfLadingAmountLabel>
        <Typography.Text>{t('Freighted')}</Typography.Text>
      </BillOfLadingOriginalsDistributionEntry>
      {entries.map(({
        id, party, amount, freighted,
      }, i) => (
        <BillOfLadingOriginalsDistributionEntry key={`${id}__originals`}>
          <Select
            placeholder={t('Select shipping party')}
            value={party}
            error={!!errors?.[i]?.party && (touchedFields[`originals.${i}.party`] || wasUpdateAttempted)}
            allowClear={!!party}
            onChange={createShippingPartySelectHandler(i)}
            onBlur={createTouchPartyHandler(i)}
            disabled={isDisabled || isOnlyHouseConsigneeAvailable}
            dropdownStyle={{
              minWidth: '308px',
            }}
            optionLabelProp="label"
            style={{
              maxWidth: '358px',
            }}
          >
            {Object.values(shippingPartiesMap).map((type) => {
              const shippingParty = shippingPartiesRecord[type as EShippingPartyTypes];

              return (
                <Option
                  key={type}
                  value={type}
                  label={shippingParty?.companyName}
                >
                  {shippingParty?.companyName}
                  &nbsp;
                  <CompanyName>
                    {SHIPPING_PARTIES_PAGE_NAMES[type as EShippingPartyTypes]}
                  </CompanyName>
                </Option>
              );
            })}
          </Select>
          <Input
            value={amount}
            disabled={isDisabled || !party}
            error={!!errors?.[i]?.amount && (touchedFields[`originals.${i}.amount`] || wasUpdateAttempted)}
            onChange={createShippingAmountInputHandler(i)}
            onBlur={createTouchAmountHandler(i)}
          />
          <BillOfLadingFreightedSwitch
            disabled={isDisabled}
            checked={freighted}
            onChange={createFreightedHandler(i)}
          />
          <Button
            type="dashed"
            onClick={i === 0 ? addEntry : createRemoveEntry(i)}
            disabled={isDisabled || isOnlyHouseConsigneeAvailable}
          >
            {i === 0 ? <PlusOutlined /> : <MinusOutlined />}
          </Button>
        </BillOfLadingOriginalsDistributionEntry>
      ))}
      {alertError && <AlertBlock type="warning" message={t(alertError)} />}
    </BillOfLadingOriginalsDistributionContainer>
  ) : null;
};
