import { ControllerStore, IControllerState } from 'proto/BaseMobxStore';
import { action, computed } from 'mobx';

interface IManualConfirmationControllerState {
  isBookingDetails: boolean
  shipmentId?: string,
}

const initialState: IControllerState<IManualConfirmationControllerState> = {
  isBookingDetails: false,
  isLoading: false,
};

export class ManualConfirmationStore extends ControllerStore<IControllerState<IManualConfirmationControllerState>> {
  @action
  setIsBookingDetails(isBookingDetails: boolean) {
    this.state.isBookingDetails = isBookingDetails;
  }

  @action
  setShipmentId(shipmentId: string) {
    this.state.shipmentId = shipmentId;
  }

  @computed
  get getShipmentId() {
    return this.state.shipmentId;
  }
}

export const createManualConfirmationStore = (_initialState?: IControllerState<IManualConfirmationControllerState>) => new ManualConfirmationStore(_initialState || initialState);
