import styled from 'styled-components';

export const InnerWrapper = styled.div<{ withProgress?: boolean, withExtra?: boolean }>`
  width: 33%;
  height: 100%;
  border-radius: 3px;
  border: 1px solid var(--neutral-branding-4, #EAEEF8);
  background: white;
  padding: 12px 16px 8px 16px;
  display: flex;
  flex-direction: ${({ withProgress, withExtra }) => ((withProgress || withExtra) ? 'column' : 'row')};
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Budget = styled.div`
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px dashed #EAEEF8;
`;

export const SubTitle = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #73819B;
  margin-right: 3px;
`;

export const MainValue = styled.span`
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  color: #202C3C;
`;

export const Percent = styled.span`
  color: #73819B;
`;

export const TooltipInner = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CheckShadowMain = styled.div`
  height: 28px;
  width: 28px;
  background: #F6FFED;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const CheckShadow = styled.div`
  height: 18px;
  width: 18px;
  background: #75CB3F;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const IconShadow = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #FFFBE6;
`;

export const StyledDiff = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #202C3C;
`;

export const StyledBar = styled.div`
  width: 100%;
  height: 4px;
  border-radius: 100px;
  background: var(--neutral-3, #F5F5F5);
  margin-top: 5px;
`;

export const StyledProgress = styled.div<{ width?: number }>`
  height: 100%;
  width: ${({ width }) => `${width}%`};
  background-color: #75CB3F;
  border-radius: 100px;
`;

export const ExtraWrapper = styled.div`
  padding-top: 3px;
  width: 100%;
  border-top: 1px dashed #EAEEF8;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ExtraItem = styled.div`
  width: 32%;
  display: flex;
  flex-direction: column;
`;

export const ExtraTitle = styled.span`
  font-size: 12px;
  color: #73819B;
`;

export const ExtraValue = styled.span`
  font-size: 14px;
  color: #202C3C;
`;

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const InnerBudget = styled.div`
  margin-bottom: 3px;
`;

export const FlexWrapper = styled.div`
  display: flex;
`;
