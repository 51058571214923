import styled from 'styled-components';
import AntRadio from 'antd/es/radio';

import { ShipIcon, WareHouseSvg } from 'app-wrapper/view/icons';
import { Divider as BaseDivider, Button } from 'app-wrapper/view/components';
import {
  FlexCenter,
  FlexRow,
  Tag as BaseTag,
  SubPageContent,
  GapHorizontalContainerStyled, FlexCol,
} from 'app-wrapper/view/guideline';
import {
  BodyTextMediumSpan,
  SmallTextFootnoteDescriptionSpan,
  BodyTextRegular,
} from 'app-wrapper/view/guideline/Font';

export const Wrap = styled.div.attrs((props: {
  isChosen: boolean,
}) => ({
  isChosen: props.isChosen,
}))`
  width: 100%;
  position: relative;
  display: flex;
  padding: 16px;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  box-shadow: ${({ isChosen }) => (isChosen ? '5px 0px 0px 0px rgba(108, 121, 222, 1) inset' : 'none')};
  border-radius: 4px;
  min-height: 88px;
  cursor: pointer;

  :not(:first-of-type) {
    margin-top: 8px;
  }
`;

export const ContentWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Row = styled(FlexRow)`
  height: 56px;
  gap: 16px;
`;

export const Radio = styled(AntRadio)`
  margin-right: 0px;
`;

export const IconWrap = styled(FlexCenter)`
  min-width: 32px;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.themesColors.primaryBranding1};
`;

export const StorageIconWrap = styled.div`
  margin-top: 1px;

  svg > * {
    fill: ${({ theme }) => theme.themesColors.primaryBranding7};
  }
`;

export const TimeText = styled(BodyTextMediumSpan)`
  min-width: 115px;
`;

export const StorageIcon = styled(WareHouseSvg)`
  width: 16px;
  height: 16px;
`;

export const CarrierShipIcon = styled(ShipIcon)`
  width: 16px;

  * {
    fill: ${({ theme }) => theme.themesColors.primaryBranding7}; 
  }
`;

export const Divider = styled(BaseDivider)`
  width: 100%;
  max-width: 170px;
  min-width: 170px;
`;

export const CutoffButton = styled(Button)`
  cursor: initial;

  &:hover, &:active, &:focus {
    border-color: ${({ theme }) => theme.themesColors.neutralBranding5} !important;
    color: ${({ theme }) => theme.themesColors.secondaryDot45} !important;
  }
`;

export const CutoffWarningButton = styled(Button)`
  cursor: initial;
  border-color: ${({ theme }) => theme.themesColors.yellowBranding2};
  color: ${({ theme }) => theme.themesColors.neutralBranding10};

  svg {
    color: ${({ theme }) => theme.themesColors.characterBrandingWarning};
  }

  &:hover, &:not([disabled]):active, &:focus {
    border-color: ${({ theme }) => theme.themesColors.yellowBranding2};
    color: ${({ theme }) => theme.themesColors.neutralBranding10};

    svg {
      color: ${({ theme }) => theme.themesColors.characterBrandingWarning};
    }
  }
`;

export const Tag = styled(BaseTag)`
  margin: 0;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
`;

export const ExpandedData = styled.div`
  margin-top: 24px;
  padding-left: 32px;
  width: 100%;
  gap: 8px;
`;

export const CutoffsWrap = styled(SubPageContent)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Cutoffs = styled(GapHorizontalContainerStyled)`
  width: fit-content;
  gap: 32px;
`;

export const CutoffItem = styled(GapHorizontalContainerStyled).attrs((props: {
  isWarning: boolean,
}) => ({
  isWarning: props.isWarning,
}))`
  width: fit-content;
  align-items: center;

  svg > * {
    fill: ${({ theme, isWarning }) => (isWarning ? theme.themesColors.characterBrandingWarning : theme.themesColors.secondaryDot45)};
  }
`;

export const TooltipContent = styled(FlexCol)`
  ${BodyTextRegular}
  color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const CutoffText = styled(SmallTextFootnoteDescriptionSpan)`
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const CutoffTextDate = styled(CutoffText)`
  margin-left: 4px;
  color: initial;
`;

export const ExpandContent = styled(SubPageContent)`
  margin-top: 8px;
`;
