import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { HsCodeInputComponent, IHsCodeInputComponentProps } from 'shipment-operations/view/components';

interface HSCodeInputComponentProps extends Omit<IHsCodeInputComponentProps, 'setHsCode' | 'touchField'> {}

export const HSCodeInputComponent: FC<HSCodeInputComponentProps> = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <HsCodeInputComponent
      {...props}
      placeholder={t('Enter HS Code or Commodity Name')}
      setHsCode={() => null}
      touchField={() => null}
      isTemperatureControlled
    />
  );
};
