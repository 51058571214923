import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { ShipmentBookingStatusEnum } from 'shipment-operations/constants';
import { BookingDetailsDTM, ManualConfirmationDocumentDTM } from 'shipment-operations/models/dtm';

export class BookingDetailsService {
  private base = '/shipment-service/api/v1/shipments';

  public getBookingDetails = async (shipmentId: string): Promise<BookingDetailsDTM | null> => {
    let bookingDetails: BookingDetailsDTM | null = null;

    const response = await apiWorker.requestGetBySchema(`${this.base}/${shipmentId}/booking-details` as '/api/v1/shipments/{shipmentId}/booking-details');

    if (response.status === 200) {
      const { data } = response;

      bookingDetails = BookingDetailsDTM.fromPlain({
        ...data,
        status: data.status as ShipmentBookingStatusEnum,
        oceanBookingId: data.oceanBookingId || '',
        carrierReferenceNumber: data.carrierReferenceNumber || '',
        mblReferenceNumber: data.mblReferenceNumber || '',
        requestTheSameWithShipment: data.requestTheSameWithShipment as boolean,
        manualConfirmationDocument: data.manualConfirmationDocument ? ManualConfirmationDocumentDTM.fromPlain({
          ...data.manualConfirmationDocument,
        }) : null,
      });
    }

    return bookingDetails;
  };

  public editBookingDetails = async (shipmentId: string, bookingDetails: BookingDetailsDTM) => {
    const body = {
      status: bookingDetails.status as ShipmentBookingStatusEnum,
      oceanBookingId: bookingDetails.oceanBookingId || null,
      carrierReferenceNumber: bookingDetails.carrierReferenceNumber || null,
      mblReferenceNumber: bookingDetails.mblReferenceNumber || null,
      requestTheSameWithShipment: bookingDetails.requestTheSameWithShipment,
      manualConfirmationDocument: bookingDetails.manualConfirmationDocument || null,
    };

    await apiWorker.requestPut(`${this.base}/${shipmentId}/booking-details`, body);
  };
}
