import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;

export const MainContent = styled.div`
  padding: 32px 90px;
  width: calc(100% - 240px);
`;
