import styled from 'styled-components';
import AntInput from 'antd/es/input';

import { inputDefaultStyle } from 'app-wrapper/view/themes/components';

export const InputComponentStyle = styled(AntInput).attrs((props: {
  $isPrefix: boolean
  'data-class'?: string
}) => ({
  $isPrefix: props.$isPrefix,
  'data-class': props?.['data-class'] || 'InputComponentStyle',
}))`
  ${inputDefaultStyle}

    ${({ $isPrefix }) => $isPrefix && `
      input {
        min-height: 22px !important;
      }
    `};
  
  .ant-input-prefix {
    margin-right: 8px;
    
    svg {
      width: 16px;
    }
  }
`;
