import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import {
  CountryDTM,
  OrganizationDTM,
  BusinessRegistrationNumberDtm,
  OrganizationFieldsErrorsDTM,
  OrganizationMemberDTM,
  OrganizationPrimaryContactDTM,
  OrganizationMembershipDtm,
  OrganizationTradeReferenceDTM,
  TOrganizationFieldsErrorsFieldDTM,
  TOrganizationMemberDTM,
  OrganizationFieldsErrorsFieldDTM,
} from 'user-management/models/dtm';
import { WebsiteUrl } from 'user-management/models/dtm/WebsiteUrl';
import { TNewOrganizationDataState } from 'user-management/models/states';
import { EBusinessRegistrationNumberType, EOrganizationType } from 'user-management/constants';

import { CountriesJson } from './countries';

const countries = Object.entries(CountriesJson).map(([code, name]) => (CountryDTM.fromPlain({
  code,
  name,
})));

export const initialState: TNewOrganizationDataState = {
  isLoading: false,
  organization: undefined,
  isTradeReferenceCollapsed: false,
  wasSubmittedOnce: false,
  organizationUpdate: OrganizationDTM.fromPlain({
    id: 0,
    status: undefined,
    address: {
      id: null,
      address1: '',
      city: '',
      closestPort: '',
      country: '',
      postalCode: '',
    },
    note: '',
    documents: [],
    businessRegistrationNumber: BusinessRegistrationNumberDtm.fromPlain({ type: EBusinessRegistrationNumberType.EIN, value: '' }),
    memberships: [OrganizationMembershipDtm.createEmpty()],
    name: '',
    phone: '',
  }),
  organizationUserData: undefined,
  countries,
  tradeReference: OrganizationTradeReferenceDTM.createEmpty(),
};

export const NewOrganizationDataStore = createSlice({
  name: 'NewOrganizationData',
  initialState,
  reducers: {
    setOrganizationDataLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
    setUserOrganization: (state, { payload }: PayloadAction<OrganizationDTM>) => ({
      ...state,
      organization: payload,
    }),
    resetUserOrganization: (state) => ({
      ...state,
      organization: initialState.organization,
    }),
    setUserOrganizations: (state, { payload }: PayloadAction<OrganizationDTM[]>) => ({
      ...state,
      organizations: payload,
    }),
    setUserOrganizationUpdate: (state, { payload }: PayloadAction<OrganizationDTM>) => ({
      ...state,
      organizationUpdate: payload,
    }),
    resetUserOrganizationUpdate: (state) => ({
      ...state,
      organizationUpdate: {
        ...initialState.organizationUpdate,
      },
    }),
    setWasSubmittedOnce: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      wasSubmittedOnce: payload,
    }),
    setIsTradeReferenceCollapsed: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isTradeReferenceCollapsed: payload,
    }),
    setTradeReference: (state, { payload }: PayloadAction<OrganizationTradeReferenceDTM>) => ({
      ...state,
      tradeReference: payload,
    }),
    setUserOrganizationUpdateName: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        name: payload,
      },
    }),
    setUserOrganizationUpdateType: (state, { payload }: PayloadAction<EOrganizationType>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        type: payload,
      },
    }),
    setUserOrganizationUpdateIndustry: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        industry: payload,
      },
    }),
    setUserOrganizationUpdateAddress1: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        address: {
          ...state.organizationUpdate.address,
          address1: payload,
        },
      },
    }),
    setUserOrganizationUpdateAddress2: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        address: {
          ...state.organizationUpdate.address,
          address2: payload,
        },
      },
    }),
    setUserOrganizationUpdateAddressCity: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        address: {
          ...state.organizationUpdate.address,
          city: payload,
        },
      },
    }),
    setUserOrganizationUpdateAddressState: (state, { payload }: PayloadAction<string | undefined>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        address: {
          ...state.organizationUpdate.address,
          state: payload,
        },
      },
    }),
    setUserOrganizationUpdateAddressPostalCode: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        address: {
          ...state.organizationUpdate.address,
          postalCode: payload,
        },
      },
    }),
    setUserOrganizationUpdateAddressCountry: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        address: {
          ...state.organizationUpdate.address,
          country: payload,
        },
      },
    }),
    setUserOrganizationUpdatePhone: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        phone: payload,
      },
    }),
    setUserOrganizationUpdatePhone2: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        phone2: payload,
      },
    }),
    setUserOrganizationUpdateEmail: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        email: payload,
      },
    }),
    setUserOrganizationUpdateWebUrl: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        webUrl: WebsiteUrl.fromPlain({ webUrl: payload }),
      },
    }),
    setUserOrganizationUpdateBusinessRegistrationNumber: (state, { payload }: PayloadAction<BusinessRegistrationNumberDtm>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        businessRegistrationNumber: payload,
      },
    }),
    setUserOrganizationUpdateMemberships: (state, { payload }: PayloadAction<OrganizationMembershipDtm>) => {
      let done = false;
      const newMembershipsList = state.organizationUpdate.memberships ? state.organizationUpdate.memberships.map((item) => {
        if (item.id === payload.id) {
          done = true;

          return payload;
        }

        return item;
      }) : [];

      if (!done) {
        newMembershipsList.push(payload);
      }

      return ({
        ...state,
        organizationUpdate: {
          ...state.organizationUpdate,
          memberships: newMembershipsList,
        },
      });
    },
    removeUserOrganizationUpdateMemberships: (state, { payload }: PayloadAction<string>) => {
      const newMembershipsList = state.organizationUpdate.memberships ? state.organizationUpdate.memberships.filter((item) => {
        if (item.id === payload) {
          return undefined;
        }

        return item;
      }) : [];

      return ({
        ...state,
        organizationUpdate: {
          ...state.organizationUpdate,
          memberships: newMembershipsList,
        },
      });
    },
    setUserOrganizationUpdateTaxId: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        taxId: payload,
      },
    }),
    setUserOrganizationUpdatePrimaryFirstName: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        primaryContact: OrganizationPrimaryContactDTM.fromPlain({
          email: '',
          phone: '',
          lastName: '',
          ...state.organizationUpdate.primaryContact,
          firstName: payload,
        }),
      },
    }),
    setUserOrganizationUpdatePrimaryLastName: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        primaryContact: OrganizationPrimaryContactDTM.fromPlain({
          email: '',
          phone: '',
          firstName: '',
          ...state.organizationUpdate.primaryContact,
          lastName: payload,
        }),
      },
    }),
    setUserOrganizationUpdatePrimaryEmail: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        primaryContact: OrganizationPrimaryContactDTM.fromPlain({
          lastName: '',
          phone: '',
          firstName: '',
          ...state.organizationUpdate.primaryContact,
          email: payload,
        }),
      },
    }),
    setUserOrganizationUpdatePrimaryPhone: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        primaryContact: OrganizationPrimaryContactDTM.fromPlain({
          lastName: '',
          email: '',
          firstName: '',
          ...state.organizationUpdate.primaryContact,
          phone: payload,
        }),
      },
    }),
    setUserOrganizationUpdatePrimaryPhone2: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        primaryContact: OrganizationPrimaryContactDTM.fromPlain({
          lastName: '',
          phone: payload,
          email: '',
          firstName: '',
          ...state.organizationUpdate.primaryContact,
          // phone2: payload,
        }),
      },
    }),
    setUserOrganizationUpdateMembersContacts: (state, { payload }: PayloadAction<TOrganizationMemberDTM[]>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        membersContacts: payload.map((item) => OrganizationMemberDTM.fromPlain(item)),
      },
    }),
    emptyUserOrganizationUpdateMembersContactsAll: (state) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        membersContacts: [],
        membersContactsValue: undefined,
        membersContactsValueOld: undefined,
      },
    }),
    setUserOrganizationUpdateMembersContactsValue: (state, { payload }: PayloadAction<TOrganizationMemberDTM>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        membersContactsValue: OrganizationMemberDTM.fromPlain(payload),
      },
    }),
    setUserOrganizationUpdateMembersContactsValueOld: (state, { payload }: PayloadAction<TOrganizationMemberDTM>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        membersContactsValueOld: OrganizationMemberDTM.fromPlain(payload),
      },
    }),
    resetUserOrganizationErrors: (state) => ({
      ...state,
      organizationFieldsErrors: undefined,
    }),
    setUserOrganizationErrorsName: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        name: {
          ...state.organizationFieldsErrors?.name,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsType: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        type: {
          ...state.organizationFieldsErrors?.type,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsIndustry: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        industry: {
          ...state.organizationFieldsErrors?.industry,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsAddress1: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        address1: {
          ...state.organizationFieldsErrors?.address1,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsAddress2: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        address2: {
          ...state.organizationFieldsErrors?.address2,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsAddressCity: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        addressCity: {
          ...state.organizationFieldsErrors?.addressCity,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsAddressState: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        addressState: {
          ...state.organizationFieldsErrors?.addressState,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsAddressPostalCode: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        addressPostalCode: {
          ...state.organizationFieldsErrors?.addressPostalCode,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsAddressCountry: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        addressCountry: {
          ...state.organizationFieldsErrors?.addressCountry,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsPhone: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        phone: {
          ...state.organizationFieldsErrors?.phone,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsPhone2: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        phone2: {
          ...state.organizationFieldsErrors?.phone2,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsEmail: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        email: {
          ...state.organizationFieldsErrors?.email,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsWebUrl: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        webUrl: {
          ...state.organizationFieldsErrors?.webUrl,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsBusinessRegistrationNumber: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        businessRegistrationNumber: {
          ...state.organizationFieldsErrors?.businessRegistrationNumber,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsMembership: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => {
      const oldMembership = state.organizationFieldsErrors?.memberships?.find((item) => item.id === payload.id);
      const membershipsErrors = state.organizationFieldsErrors?.memberships
        ? [...state.organizationFieldsErrors.memberships].filter((item) => item.id !== payload.id) : [];

      if (oldMembership) {
        membershipsErrors.push(OrganizationFieldsErrorsFieldDTM.fromPlain({
          ...oldMembership,
          ...payload,
        }));
      } else {
        membershipsErrors.push(OrganizationFieldsErrorsFieldDTM.fromPlain(payload));
      }

      return ({
        ...state,
        organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
          ...state.organizationFieldsErrors,
          memberships: membershipsErrors,
        }),
      });
    },
    setUserOrganizationErrorsAllMembershipsVisited: (state, { payload }: PayloadAction<boolean>) => {
      const newMemberships = state.organizationFieldsErrors?.memberships?.map((item) => OrganizationFieldsErrorsFieldDTM.fromPlain({
        ...item,
        isVisited: payload,
      }));

      return ({
        ...state,
        organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
          ...state.organizationFieldsErrors,
          memberships: newMemberships,
        }),
      });
    },
    setUserOrganizationErrorsTaxId: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        taxId: {
          ...state.organizationFieldsErrors?.taxId,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsPrimaryName: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        primaryName: {
          ...state.organizationFieldsErrors?.primaryName,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsPrimaryEmail: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        primaryEmail: {
          ...state.organizationFieldsErrors?.primaryEmail,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsPrimaryPhone: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        primaryPhone: {
          ...state.organizationFieldsErrors?.primaryPhone,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsPrimaryPhone2: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        primaryPhone2: {
          ...state.organizationFieldsErrors?.primaryPhone2,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsMembersContactsValue: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        MembersContactsValue: {
          ...state.organizationFieldsErrors?.MembersContactsValue,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsMembersContactsValue: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        MembersContactsValue: {
          ...state.organizationFieldsErrors?.MembersContactsValue,
          ...payload,
        },
      }),
    }),
    setCurrentOrganizationUserData: (state, { payload }: PayloadAction<OrganizationMemberDTM>) => ({
      ...state,
      organizationUserData: payload,
    }),
  },
});

export const newOrganizationDataReducer = NewOrganizationDataStore.reducer;
export const newOrganizationDataActions = NewOrganizationDataStore.actions;
