import React, { FC, useCallback } from 'react';
import AntdSelect, { SelectProps } from 'antd/es/select';

import i18n from 'app-wrapper/i18n/i18n';
import { VerticalFormItemSvg } from 'app-wrapper/view/icons';

import omit from 'lodash/fp/omit';
import { Select, SelectWrapper } from './Select.styled';
import { CopyOutlined } from '../ant/CopyOutlined';

const getPopupContainer: SelectProps<any>['getPopupContainer'] = (triggerNode) => (
  triggerNode.parentElement || document.body
);

interface ISelectProps extends SelectProps<any> {
  error?: boolean
  cssWidth?: string
  cssMarginLeft?: string
  prefixIcon?: React.ReactNode
  dataClass?: string
  isCopy?: boolean
  copyText?: string
  onCopy?: () => ''
}

export const SelectComponent: FC<ISelectProps> = ({
  placeholder = i18n.t('None'),
  cssWidth,
  cssMarginLeft,
  error,
  prefixIcon,
  isCopy,
  copyText,
  onCopy,
  showSearch,
  onSearch,
  getPopupContainer: getPopupContainerCustom,
  className,
  children,
  size,
  ...other
}) => {
  const onCopyHandler = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event?.preventDefault();
    event?.stopPropagation();

    navigator.clipboard.writeText(copyText || '');

    if (onCopy) {
      onCopy();
    }
  }, [copyText]);

  return (
    <SelectWrapper
      cssWidth={cssWidth}
      cssMarginLeft={cssMarginLeft}
      hasPrefixIcon={!!prefixIcon}
      hasCopy={!!isCopy}
    >
      {prefixIcon && <div className="prefix-icon-wrapper">{prefixIcon}</div>}
      <Select
        size={size || 'middle'}
        className={error ? `${className} ant-select-error` : className}
        suffixIcon={<VerticalFormItemSvg />}
        placeholder={placeholder}
        getPopupContainer={getPopupContainerCustom || getPopupContainer}
        showSearch={showSearch}
        onSearch={showSearch ? onSearch : undefined}
        {...omit(['isLeft', 'isRight', 'isError', 'hasError', 'largeInput'], other)}
      >
        {children}
      </Select>
      {isCopy && (
        <CopyOutlined onClick={onCopyHandler} />
      )}
    </SelectWrapper>
  );
};

export { SelectComponent as Select };
export const { Option, OptGroup } = AntdSelect;
