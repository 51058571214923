import { Type } from 'class-transformer';
import {
  IsDefined,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';

import i18n from 'app-wrapper/i18n/i18n';
import {
  IAddressDTM,
  AddressDTM,
} from 'app-wrapper/models/dtm';
import {
  EOrganizationType,
  EOrganizationIndustry,
} from 'user-management/constants';
import {
  TOrganizationPrimaryContactDTM,
  OrganizationPrimaryContactDTM,
} from 'user-management/models/dtm';

import { BaseDTM } from 'proto/BaseDTM';

export interface IAdminPublicInfoDTM {
  id: number;
  name: string;
  contactEmail?: string;
  contactPhone?: string;
  contactPhone2?: string;
  type: EOrganizationType;
  industry?: EOrganizationIndustry;
  registrationNumber?: string;
  registrationType?: string;
  address?: IAddressDTM;
  primaryContact?: TOrganizationPrimaryContactDTM;
}

export class AdminPublicInfoDTM extends BaseDTM<IAdminPublicInfoDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsString()
  name: string;

  @IsOptional()
  @IsString()
  contactEmail?: string;

  @IsOptional()
  @IsString()
  contactPhone?: string;

  @IsOptional()
  @IsString()
  contactPhone2?: string;

  @IsOptional()
  @IsString()
  registrationNumber?: string;

  @IsOptional()
  @IsString()
  registrationType?: string;

  @IsDefined()
  @IsEnum(EOrganizationType)
  type: EOrganizationType;

  @IsOptional()
  @IsEnum(EOrganizationIndustry)
  industry?: EOrganizationIndustry;

  @IsOptional()
  @ValidateNested()
  @Type(() => AddressDTM)
  address?: AddressDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationPrimaryContactDTM)
  primaryContact?: OrganizationPrimaryContactDTM;

  public getIsAdminFromUS = () => {
    if (!this.address?.country) {
      return false;
    }

    return this.address.country === 'US';
  }

  public getOnBehalfAdminName = (isOriginOrDestinationUS?: boolean) => {
    if (this.getIsAdminFromUS() || !isOriginOrDestinationUS) {
      return this.name;
    }

    return `${this.name} ${i18n.t('as agent of Maxton Shipping Inc DBA Skypace')}`;
  }
}
