import styled from 'styled-components';

import { BodyTextMedium } from 'app-wrapper/view/guideline/Font';
import { SubPageContent } from 'app-wrapper/view/guideline';

export const OverlayWrap = styled(SubPageContent)`
  min-width: 420px;
  box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05);

  input::placeholder {
    color: ${({ theme }) => theme.themesColors.secondaryDot45};
  }

  .FreightQuoteFormRequest__Row__col_both_col__title h5 {
    ${BodyTextMedium}
  }
`;

export const OverlayBottom = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  height: 250px;
  width: 100%;
`;

export const Overlay = styled.div`
  width: 100%;
`;
