import {
  IsNumber, IsString, IsDefined, IsOptional, ValidateNested, IsBoolean, IsArray, IsObject,
} from 'class-validator';
import { Type } from 'class-transformer';

import { IDateDTM, DateDtm } from 'app-wrapper/models/dtm';
import { BaseDTM } from 'proto/BaseDTM';
import { ELocationType } from 'app-wrapper/types/LocationType';

interface ITransportDTM {
  number?: string
  name?: string
  type?: string
}

class TransportDTM extends BaseDTM<ITransportDTM> {
  @IsOptional()
  @IsString()
  number?: string;

  @IsOptional()
  @IsString()
  name?: string;

  @IsOptional()
  @IsString()
  type?: string;
}

interface IStateDTM {
  code?: string
  name?: string
}

export class StateDTM extends BaseDTM<IStateDTM> {
  @IsOptional()
  @IsString()
  code?: string;

  @IsOptional()
  @IsString()
  name?: string;
}

interface ICoordinatesDTN {
  lat?: number
  lng?: number
}

class CoordinatesDTN implements ICoordinatesDTN {
  @IsNumber()
  @IsOptional()
  lat?: number;

  @IsNumber()
  @IsOptional()
  lng?: number;
}

interface ICountryDTM {
  code?: string
  name?: string
}

class CountryDTM extends BaseDTM<ICountryDTM> {
  @IsString()
  @IsOptional()
  code?: string;

  @IsString()
  @IsOptional()
  name?: string;
}

interface ILocationDTM {
  country: ICountryDTM,
  state: IStateDTM,
  code?: string
  name?: string
  city?: string
  coordinates: ICoordinatesDTN,
  timeZoneId?: string
  type: string
  placeId?: string
  address?: string
}

class LocationDTM extends BaseDTM<ILocationDTM> {
  @ValidateNested()
  @IsDefined()
  @Type(() => CountryDTM)
  country: CountryDTM

  @ValidateNested()
  @IsDefined()
  @Type(() => StateDTM)
  state: StateDTM

  @IsString()
  @IsOptional()
  code?: string;

  @IsString()
  @IsOptional()
  name?: string;

  @IsString()
  @IsOptional()
  city?: string;

  @IsString()
  @IsOptional()
  timeZoneId?: string;

  @ValidateNested()
  @IsDefined()
  @Type(() => CoordinatesDTN)
  coordinates: CoordinatesDTN

  @IsString()
  @IsDefined()
  type: string;

  @IsString()
  @IsOptional()
  placeId?: string;

  @IsString()
  @IsOptional()
  address?: string;

  public getLocationName = () => (this.type === ELocationType.DOOR ? (`${this.address}, ${this.name || this.city || ''}`) : (this.name || this.city || ''));
}

export interface IShipmentEventDTM {
  id?: string
  planId?: number,
  location: ILocationDTM,
  transport: ITransportDTM,
  estimated?: IDateDTM
  actual?: IDateDTM
  predicted?: IDateDTM
  code?: 'EE' | 'I' | 'AE' | 'AL' | 'AM' | 'VD' | 'RL' | 'AR' | 'VA' | 'D' | 'UV' | 'UR' | 'U' | 'RE' | 'OA' | 'RD' | 'TD' | 'AP' | 'PC' | 'DC' | 'AS' | 'DS' | 'RA'
  passed?: boolean
  voyageCode: string | null
}

export class ShipmentEventDTM extends BaseDTM<IShipmentEventDTM> {
  @IsOptional()
  @IsString()
  id?: string;

  @IsOptional()
  @IsDefined()
  planId?: number;

  @ValidateNested()
  @IsDefined()
  @Type(() => LocationDTM)
  location: LocationDTM

  @ValidateNested()
  @IsDefined()
  @Type(() => TransportDTM)
  transport: TransportDTM

  @IsOptional()
  @Type(() => DateDtm)
  estimated?: DateDtm;

  @IsOptional()
  @Type(() => DateDtm)
  actual?: DateDtm;

  @IsOptional()
  @Type(() => DateDtm)
  predicted?: DateDtm;

  @IsOptional()
  @IsString()
  code?: 'EE' | 'I' | 'AE' | 'AL' | 'AM' | 'VD' | 'RL' | 'AR' | 'VA' | 'D' | 'UV' | 'UR' | 'U' | 'RE' | 'OA' | 'RD' | 'TD' | 'AP' | 'PC' | 'DC' | 'AS' | 'DS' | 'RA'

  @IsOptional()
  @IsDefined()
  passed?: boolean;

  @IsOptional()
  @IsString()
  voyageCode: string | null
}

interface IContainerDTM {
  id?: number
  type: string
  number?: string
  events: IShipmentEventDTM[]
}

export class ShipmentTrackerContainerDTM extends BaseDTM<IContainerDTM> {
  @IsNumber()
  @IsOptional()
  id?: number;

  @IsString()
  @IsDefined()
  type: string;

  @IsString()
  @IsOptional()
  number?: string;

  @ValidateNested()
  @IsDefined()
  @Type(() => ShipmentEventDTM)
  events: ShipmentEventDTM[]
}

export interface IShipmentTrackerDTM {
  planId: number
  container: IContainerDTM
}

export class ShipmentTrackerDTM extends BaseDTM<IShipmentTrackerDTM> {
  @IsNumber()
  @IsDefined()
  planId: number;

  @ValidateNested()
  @IsDefined()
  @Type(() => ShipmentTrackerContainerDTM)
  container: ShipmentTrackerContainerDTM

  public getIsVesselDeparted = () => {
    const vesselDepartureEvent = this.container.events.find((event) => event.code === 'VD');

    return Boolean(vesselDepartureEvent && !!vesselDepartureEvent.actual);
  }
}

export interface IStepsDTM {
  id?: string
  status: string
  title?: string
  locationName: string
  stateCode?: string
  countryCode?: string
  vessel?: string
  voyage?: string
  isVesselVoyage?: boolean
  estimatedDate?: IDateDTM
  estimatedDateType?: string
  actualDate?: IDateDTM
  actualDateType?: string
  originalActualDateType?: string
  withWarning?: boolean
  predictedDate?: IDateDTM
}

export class StepsDTM extends BaseDTM<IStepsDTM> {
  @IsOptional()
  @IsString()
  id?: string;

  @IsDefined()
  @IsString()
  status: string;

  @IsOptional()
  @IsString()
  title?: string;

  @IsDefined()
  @IsString()
  locationName: string;

  @IsOptional()
  @IsString()
  stateCode?: string;

  @IsOptional()
  @IsString()
  countryCode?: string;

  @IsOptional()
  @IsString()
  vessel?: string;

  @IsOptional()
  @IsString()
  voyage?: string;

  @IsOptional()
  @IsBoolean()
  isVesselVoyage?: boolean;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  estimatedDate?: DateDtm;

  @IsOptional()
  @IsString()
  estimatedDateType?: string

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  actualDate?: DateDtm;

  @IsOptional()
  @IsString()
  actualDateType?: string

  @IsOptional()
  @IsString()
  originalActualDateType?: string;

  @IsOptional()
  @IsBoolean()
  withWarning?: boolean;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  predictedDate?: DateDtm;
}

export interface ITrackerContainersTableDTM {
  key: number
  type: string
  number?: string
  previousEvent?: string
  previousEventDrayage?: string
  date?: IDateDTM
  dateType?: string
  nextEvent?: string
  estimatedDate?: IDateDTM
  estimatedDateType?: string
  actualDate?: IDateDTM
  actualDateType?: string
  events?: IStepsDTM[]
  editable?: boolean
  eventId?: string
  isLinked?: boolean
  id?: number
  planId?: number
  predictedDate?: IDateDTM
}

export class TrackerContainersTableDTM extends BaseDTM<ITrackerContainersTableDTM> {
  @IsDefined()
  @IsNumber()
  key: number;

  @IsString()
  @IsDefined()
  type: string;

  @IsString()
  @IsOptional()
  number?: string;

  @IsString()
  @IsOptional()
  previousEvent?: string;

  @IsString()
  @IsOptional()
  previousEventDrayage?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  date?: DateDtm;

  @IsString()
  @IsOptional()
  dateType?: string;

  @IsString()
  @IsOptional()
  nextEvent?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  estimatedDate: DateDtm;

  @IsString()
  @IsOptional()
  estimatedDateType?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  actualDate: DateDtm;

  @IsString()
  @IsOptional()
  actualDateType?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => StepsDTM)
  events?: StepsDTM[]

  @IsOptional()
  @IsBoolean()
  editable?: boolean;

  @IsOptional()
  @IsString()
  eventId?: string

  @IsOptional()
  @IsBoolean()
  isLinked?: boolean;

  @IsOptional()
  @IsNumber()
  id?: number;

  @IsOptional()
  @IsNumber()
  planId?: number;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  predictedDate?: DateDtm;
}

export interface IPreparedRoutesDTM {
  codes: string[]
  route: {
    [key: string]: (IShipmentEventDTM | undefined)[]
  }
}

export class PreparedRoutesDTM extends BaseDTM<IPreparedRoutesDTM> {
  @IsDefined()
  @IsArray()
  codes: string[]

  @IsObject()
  route: {
    [key: string]: (ShipmentEventDTM | undefined)[]
  }
}

export interface ITrackerScheduleDTM {
  terminalCutOff?: string
  vgmCutOff?: string
  cyAvailable?: string
  ctoAvailable?: string
  documentCutOff?: string
  arrivalTime?: string
}

// TODO: move this model to DateDtm
export class TrackerScheduleDTM extends BaseDTM<ITrackerScheduleDTM> {
  @IsString()
  @IsOptional()
  cyAvailable?: string;

  @IsString()
  @IsOptional()
  ctoAvailable?: string;

  @IsString()
  @IsOptional()
  terminalCutOff?: string;

  @IsString()
  @IsOptional()
  vgmCutOff?: string;

  @IsString()
  @IsOptional()
  documentCutOff?: string;

  @IsString()
  @IsOptional()
  arrivalTime?: DateDtm
}

export interface IResponseScheduleDTM {
  schedule: {
    arrivalTime?: string
    terminalCutOff?: string
    carrierTerminalCutOff?: string
    vgmCutOff?: string
    carrierVgmCutOff?: string
    carrierCyAvailable?: string
    carrierCtoAvailable?: string
    documentCutOff?: string
    carrierDocumentCutOff?: string
    cyAvailable?: string
    ctoAvailable?: string
  }
}

export class ResponseScheduleDTM extends BaseDTM<IResponseScheduleDTM> {
  @ValidateNested()
  @IsDefined()
  @Type(() => TrackerScheduleDTM)
  schedule: TrackerScheduleDTM
}

export interface IUpdatedDateDTM {
  id: string,
  type: string
  time?: IDateDTM
}

export class UpdatedDateDTM extends BaseDTM<IUpdatedDateDTM> {
  @IsString()
  @IsDefined()
  id: string

  @IsString()
  @IsDefined()
  type: string

  @IsOptional()
  @Type(() => DateDtm)
  time: DateDtm;
}

export interface IPlaceDTM {
  country?: string
  countryCode?: string
  code?: string
}

export class PlaceDTM extends BaseDTM<IPlaceDTM> {
  @IsOptional()
  @IsString()
  country?: string

  @IsOptional()
  @IsString()
  countryCode?: string

  @IsOptional()
  @IsString()
  code?: string
}

export interface IUpdatedDatesRequestDTM {
  planId?: number
  code?: string
  type: string
  time: string | null
  place: IPlaceDTM,
}

export class UpdatedDatesRequestDTM extends BaseDTM<IUpdatedDatesRequestDTM> {
  @IsNumber()
  @IsOptional()
  planId?: number;

  @IsString()
  @IsOptional()
  code?: string;

  @IsString()
  @IsDefined()
  type: string;

  @IsString()
  @IsDefined()
  time: string | null;

  @ValidateNested()
  @IsDefined()
  @Type(() => PlaceDTM)
  place: PlaceDTM
}

export interface IShipmentTrackerTableDTM {
  key: number
  type: number
  container: string
  previousEvent: string
  date: string
  nextEvent: string
  estimatedDate: IDateDTM
  actualDate: IDateDTM
}

export class ShipmentTrackerTableDTM extends BaseDTM<IShipmentTrackerTableDTM> {
  @IsNumber()
  @IsDefined()
  key: number;

  @IsNumber()
  @IsDefined()
  type: number;

  @IsString()
  @IsDefined()
  container: string;

  @IsString()
  @IsDefined()
  previousEvent: string;

  @IsString()
  @IsDefined()
  date: string;

  @IsString()
  @IsDefined()
  nextEvent: string;

  @IsDefined()
  @Type(() => DateDtm)
  estimatedDate: DateDtm;

  @IsDefined()
  @Type(() => DateDtm)
  actualDate: DateDtm;
}

export interface IDispatchedContainerStepDTM {
  id: number
  title: string
  city: string
  country: string
  date?: IDateDTM
}

export class DispatchedContainerStepDTM extends BaseDTM<IDispatchedContainerStepDTM> {
  @IsNumber()
  @IsDefined()
  id: number;

  @IsString()
  @IsDefined()
  title: string;

  @IsString()
  @IsDefined()
  city: string;

  @IsString()
  @IsDefined()
  country: string;

  @ValidateNested()
  @IsOptional()
  @Type(() => DateDtm)
  date?: DateDtm;
}

export interface IDispatchedContainerDTM {
  number: string
  type: string
  events?: IDispatchedContainerStepDTM[]
  key: string
  date?: IDateDTM
  previousEvent?: string
}

export class DispatchedContainerDTM extends BaseDTM<IDispatchedContainerDTM> {
  @IsString()
  @IsDefined()
  number: string;

  @IsString()
  @IsDefined()
  type: string;

  @IsArray()
  @IsOptional()
  @Type(() => DispatchedContainerStepDTM)
  events?: DispatchedContainerStepDTM[];

  @IsString()
  @IsDefined()
  key: string;

  @ValidateNested()
  @IsOptional()
  @Type(() => DateDtm)
  date?: DateDtm;

  @IsString()
  @IsOptional()
  previousEvent?: string;
}
