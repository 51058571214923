export const colors = {
  black: '#000',
  black85: '#000000d9',
  black45: '#00000073',
  gray15: '#F9F9FB80',
  white: '#FFF',
  gray33: '#E1E5ED',
  gray35: '#F4F5F8',
  gray40: '#F9F9FB',
  gray42: '#DEE1E9',
  gray43: '#D1D7E2',
  gray44: '#E4EBF7',
  gray45: '#99A3B5',
  gray50: '#828282',
  gray61: '#73819B',
  gray66: '#4E5D70',
  gray70: '#314258',
  gray80: '#202C3C',
  gray90: '#15202E',
  gray100: '#0F1824',
  purple10: '#F0F2FC',
  purple15: '#EAEEF8',
  purple16: '#D3D6EE',
  purple20: '#D3D7F5',
  purple30: '#C4C9F2',
  purple40: '#A7AFEB',
  purple45: '#A5AEE8',
  purple50: '#828DE3',
  purple60: '#6C79DE',
  purple70: '#4A55AD',
  green10: '#F6FFED',
  green30: '#B7EB8F',
  green60: '#75CB3F',
  green65: '#52C41A',
  green70: '#389E0D',
  green71: '#F7F8F9',
  dustRed: '#FFCCC7',
  yellow10: '#FEF9E6',
  yellow22: '#FBECB3',
  yellow20: '#D9D9D9',
  yellow30: '#FAE28D',
  yellow40: '#F7D24D',
  yellow70: '#DA9D00',
  yellow80: '#B07400',
  yellow90: '#B07400',
  red10: '#FFF1F0',
  red30: '#FFA39E',
  red60: '#ff4d4f',
  red61: '#FF4D4F80',
  red65: '#F5222D',
  red80: '#ff0000',
  yellow55: '#EDDEB6',
  yellow60: '#F1AE00',
  boxShadow: '#6C79DE',
  buttonPrimaryShadow: '#F4F4F4',
  buttonPrimaryColor: '#697585',
};

const themesColors = {
  neutralBranding1: colors.white,
  neutralBranding2: colors.gray40,
  neutralBranding3: colors.gray35,
  neutralBranding4: colors.purple15,
  neutralBranding5: colors.gray42,
  neutralBranding6: colors.gray43,
  neutralBranding65: colors.gray44,
  neutralBranding7: colors.gray45,
  neutralBranding75: colors.gray50,
  neutralBranding8: colors.gray66,
  neutralBranding9: colors.gray70,
  neutralBranding10: colors.gray80,
  neutralBranding11: colors.gray90,
  neutralBranding12: colors.gray100,
  neutralBranding13: colors.black,
  neutralBranding1Dot5: colors.gray15,
  neutralBranding6Dot5: colors.gray33,
  neutral5: colors.yellow20,
  middleGraySecondary: colors.gray45,
  primaryBranding1: colors.purple10,
  primaryBranding2: colors.purple20,
  primaryBranding3: colors.purple30,
  primaryBranding4: colors.purple40,
  primaryBranding5: colors.purple50,
  primaryBranding6: colors.purple60,
  primaryBranding7: colors.purple70,
  primaryBranding9: colors.gray70,
  primaryBranding6Dot5: colors.purple16,
  redBranding1: colors.red10,
  redBranding3: colors.red30,
  redBranding6: colors.red65,
  polarGreen1: colors.green10,
  polarGreen3: colors.green30,
  polarGreen7: colors.green70,
  yellowBranding1: colors.yellow10,
  yellowBranding2: colors.yellow22,
  yellowBranding3: colors.yellow30,
  yellowBranding4: colors.yellow40,
  yellowBranding6: colors.yellow80,
  yellowBranding7: colors.yellow70,
  yellowBranding8: colors.yellow90,
  secondaryDot45: colors.gray61,
  characterSecondaryDot45: colors.black45,
  darkGrayMiddle: colors.gray61,
  middleGrayDisable: colors.gray43,
  characterBrandingTitle85: colors.gray80,
  characterBrandingDisabled025: colors.gray43,
  characterBrandingSuccess: colors.green60,
  characterBrandingSuccess65: colors.green65,
  characterBrandingDanger: colors.red60,
  characterBrandingDanger50: colors.red61,
  characterBrandingWarning: colors.yellow60,
  characterBrandingWarningDot5: colors.yellow55,
  purpleSecondary: colors.purple45,
  lightGrayBackground: colors.gray40,
  lightGrayBorder: colors.gray35,
  characterTitleDot85: colors.black85,
  boxShadow: colors.boxShadow,
  dustRed: colors.dustRed,
  emptyStateIconColor: '#A3B5D6',
};

export default themesColors;
