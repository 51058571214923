import React, { memo, useMemo } from 'react';

import { QUOTAS_STATUS, REQUEST_STATUS } from 'app-wrapper/constants';

import { R } from 'monetary/repository';

import { ContentSectionContainer } from 'app-wrapper/view/guideline';
import { useSelector } from 'react-redux';
import { Preview } from './components/Preview';
import {
  ContentComponent,
} from './components/Content';

interface IRFQResponse {
  NoneContentRecommended?: React.ReactNode
  isAllRequestPage?: boolean
  isFooter?: boolean
  isLoadingCompleteStep4?: boolean;
  hideBook?: boolean;
}

export const Quotas = memo((props: IRFQResponse) => {
  const {
    NoneContentRecommended,
    isAllRequestPage,
    isFooter,
    isLoadingCompleteStep4,
    hideBook,
  } = props;
  const getFreightQuoteCurrent = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const filters = useSelector(R.selectors.freightQuote.getFreightQuoteFilters);
  const quotas = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentQuotas);

  const isQuotaNotPending = useMemo(() => getFreightQuoteCurrent.quotasCheckStatus?.status !== 'pending'
    && quotas?.quotasOption?.fullCompleteStatus !== QUOTAS_STATUS.pending
    && getFreightQuoteCurrent?.status !== 'pending', [quotas]);

  const isVisibleFilters = (
    filters?.isRequestFilters
    && (filters?.valuesFilters?.filterPrice?.isUpdate
      || filters?.valuesFilters?.filterTransitTime?.isUpdate
      || filters?.isIncludeRelatedPortRequest
      || filters?.valuesFilters?.filterCarrier?.isUpdate)
  );

  const isVisibleRFQContent = (
    isVisibleFilters
    || (quotas?.data
      && isQuotaNotPending)
  )
    || (quotas?.allQuotas?.length
      || quotas?.quotasOption?.fullCompleteStatus === QUOTAS_STATUS.failed);

  const isLoading = (getFreightQuoteCurrent.status === REQUEST_STATUS.pending && getFreightQuoteCurrent?.quotasCheckStatus?.status !== QUOTAS_STATUS.complete);
  const isLoadingQuotas = getFreightQuoteCurrent.status === QUOTAS_STATUS.failed && quotas?.quotasOption?.fullCompleteStatus === QUOTAS_STATUS.complete;

  return (
    <ContentSectionContainer height="100%">
      {isVisibleRFQContent
        ? (
          <ContentComponent
            isAllRequestPage={isAllRequestPage}
            isFooter={isFooter}
            NoneContentRecommended={NoneContentRecommended}
            hideBook={hideBook}
          />
        )
        : NoneContentRecommended || (
          <Preview
            isLoading={isLoading && !isLoadingCompleteStep4}
            isLoadingQuotas={isLoadingQuotas}
          />
        )}
    </ContentSectionContainer>
  );
});
