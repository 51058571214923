import styled from 'styled-components';
import AntSwitch from 'antd/es/switch';

export const SwitchWrapper = styled.div`
  width: 52px;
  min-height: 32px;
  border: 1px solid #D1D7E2;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SwitchWrapperDisabled = styled(SwitchWrapper)`
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
`;

export const Switch = styled(AntSwitch)`
  max-width: 28px;
  min-width: 28px;
  height: 16px;

  .ant-switch-handle {
    width: 12px;
    height: 12px;
  }

  &.ant-switch-checked {
    background-color: #73819B;
  }

  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 12px - 2px);
  }
`;
