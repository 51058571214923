import styled from 'styled-components';

import { divFlex, responsive } from 'app-wrapper/view/themes/mixin';
import {
  RadioGroupCss,
  TabsCss,
  AlertBlockCss,
  ShadowHeaderTop,
  ButtonCss,
  CustomScrollbar,
} from 'app-wrapper/view/styles/mixins';

export const RFRFormRequestDivider = styled.div.attrs({
  'data-class': 'RFRFormRequestDivider',
})`
  ${divFlex}

  width: 100%;
  border-top: 1px solid ${({ theme }) => theme?.themesColors?.neutralBranding1};
  margin-top: 16px;
`;

export const RFRFormRequestRequest = styled.form.attrs({
  'data-class': 'RFRFormRequestRequest',
})`
  width: 100%;
  height: 100%;
  padding: 0 0 20px 0;
  background: rgba(255, 255, 255, 1);
  position: relative;
  transition: all 0.2s;
  z-index: 5;

  &#RFRFormRequestRequest .ant-input,
  &#RFRFormRequestRequest .ant-select-selector,
  &#RFRFormRequestRequest .ant-picker,
  &#RFRFormRequestRequest .ant-input-affix-wrapper {
    border-color: ${({ theme }) => theme.themesColors.neutralBranding6};
  }

  .ant-tooltip-inner {
    background-color: ${({ theme }) => theme.themesColors.neutralBranding9};
    font-size: 12px;
    font-weight: 400;
  }

  .ant-collapse-header[aria-expanded="true"] {
    color: #6c79de !important;

    > div {
      color: #202c3c !important;
    }

    .VerticalFormItemSvg {
      color: #6c79de !important;
    }
  }

  .ant-select-item,
  .ant-select-item-empty {
    font-size: 12px;
  }

  .ant-select-item-empty {
    font-size: 12px;

    span {
      color: #73819b;
    }

    color: #73819b;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background-color: #f0f2fc;
  }
  .ant-picker-cell-range-hover::before,
  .ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after,
  .ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after,
  .ant-picker-cell-range-hover-end::before {
    background: #D3D7F5 !important;
  }

  ${responsive.forFullDesktop`
    max-width: 738px;
  `}

  .ant-tabs-nav-wrap {
    max-width: calc(100% - 2px);
  }

  .ant-tabs-nav-operations {
    display: none;
  }

  .ant-collapse-item {
    border-color: #f4f5f8;
  }

  .ant-select-selector, input {
    font-size: 12px;
  }

  .ant-input,
  .ant-select-selection-search-input,
  .DatePicker {
    min-height: 32px;
  }

  .text_placeholder,
  .ant-select-selection-placeholder {
    color: #73819b !important;
    font-size: 12px;
    input::placeholder {
      color: #73819b !important;
      opacity: 1;
    }
  }

  input::placeholder,
  input:placeholder-shown,
  .DatePicker input::-webkit-input-placeholder,
  .DatePicker input:placeholder-shown {
    color: #73819b !important;
    font-size: 12px;
    opacity: 1;
  }
  input::-moz-placeholder,
  input::-moz-placeholder,
  .DatePicker input::-moz-placeholder {
    color: #73819b !important;
    opacity: 1;
    font-size: 12px;
  }

  .RFRFormRequest__Row {
    margin-bottom: 19px;

    &__col {
      &_both_col {
        height: 219px;

        ${responsive.forFullMedia`
            min-height: 111px;
            height: 95px;
        `}

        h5 {
          font-size: 14px;
          font-weight: 500;
        }

        &__title {
          padding-bottom: 4px;

          &_owned {
            margin-top: 6px;
            margin-bottom: 32px;

            button.ant-switch {
              margin-left: 0;
            }
          }
        }
        .DatePicker {
          width: 100%;
        }
        .ant-select,
        .DatePicker {
          &:focus-within {
            z-index: 4;
          }
        }
      }

      &_right,
      &_left {
        &.ant-col-12 {
          max-width: 48.4%;
        }
      }

      &_right {
        margin-left: auto;

        &__title {
          padding-bottom: 4px;
        }

        .DatePicker {
          width: 100%;
        }
      }

      &_both {
        &__title {
          box-shadow: inset 0px -1px 0px #f4f5f8;
          padding-bottom: 9px;
          padding-top: 8px;
          margin-bottom: 0px;
          margin-top: 17px;
        }
      }

      &_first {
        .ant-col {
          .ant-select-selector {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        .ant-select {
          width: 100%;
        }

        input {
          border-bottom: none;
        }
      }

      &_second {
        position: absolute;
        top: 63px;
        width: 100%;

        &.contentFullWidth {
          top: 71px;
        }

        &.RFRFormRequest__Row {
          margin-bottom: 1px;
        }

        .ant-col {
          .ant-picker {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }

        input {
          border-bottom: none;
        }
      }

      &_error {
        border-color: #ff4d4f;
        box-shadow: none;

        .ant-input {
          &:hover {
            border-color: #ff4d4f;
            box-shadow: none;
          }
        }

        .ant-input:focus,
        .ant-input-focused {
          border-color: #ff4d4f;
        }

        &:hover {
          border-color: #ff4d4f;
          box-shadow: none;
        }

        input,
        .DatePicker {
          position: relative;
          z-index: 3;
          border-color: #ff4d4f;
          box-shadow: none;
        }

        .ant-select-selector {
          position: relative;
          z-index: 3;
          border-color: #ff4d4f !important;
        }

        .ant-select-arrow {
          svg {
            position: relative;
            z-index: 4;
          }
        }
      }

      &__servicesPanel,
      &__logisticsPanel {
        .ant-collapse {
          background-color: white;
          border-left: none;
          border-right: none;
          border-top: none;

          .ant-collapse-content-active {
            border: none;
          }

          .ant-collapse-header {
            padding: 16px 16px 16px 0;
          }
        }
      }
    }

    &__Commodity {
      &__temperature {
        span.TypeSwitchController__text-left {
          color: #73819b;
        }
      }
    }

    &__Commodity,
    &__Containers {
      .ant-col:not(:first-child) {
        margin-left: auto;
      }

      .ant-select {
        width: 100%;
      }

      &__parent_content {
        &_item {
          margin-top: 8px;
        }
        &_item_first {
          margin-top: 0;
        }
      }

      &__content {
        &__col {
          &_add {
            position: relative;

            ${responsive.forFullMedia`
              min-width: 40px;
            `}

            &_btn {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0 !important;
              min-height: 32px;
              background: none;
              color: #314258;
              position: absolute;
              bottom: 0;
              border: 1px dashed #d1d7e2;
              cursor: pointer;

              &:focus {
                box-shadow: none;
              }

              &:disabled {
                background-color: white;
                border: 1px dashed #d1d7e2;
              }
            }
          }
        }

        &_title {
          margin-top: 0px;

          ${responsive.forFullMedia`
              margin-top: 8px;
            `}

          margin-bottom: 8px;
          font-size: 12px;

          &_icon {
            cursor: pointer;
            margin-left: 5px;

            svg {
              color: #73819B;
              width: 11px;
              height: 11px;
            }
          }
        }
      }
    }

    &__Containers {
      margin-top: 0px;

      .RFRFormRequest__Row__col_both__title {
        margin-top: 15px;
        padding-bottom: 9px;
        margin-bottom: 0;
      }
    }

    &__Tabs {
      ${TabsCss}

      .ant-tabs-tab-active {
        span {
          color: #202c3c;
        }

        svg {
          color: #6c79de;
        }
      }

      &__item {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          color: #202c3c;
        }

        svg {
          margin-right: 13px;
        }
      }
    }

    &__RadioController {
      ${RadioGroupCss}

      margin-bottom: 15px;

      .ant-radio-button-wrapper-checked {
        border-color: #6c79de !important;
        span {
          color: #202c3c;
        }
      }

      label.ant-radio-button-wrapper-disabled {
        background: none;
      }
    }

    &__msg {
      margin-top: 8px;
      ${AlertBlockCss}
    }

    &__Element {
      &__Type {
        &_required {
          color: #73819b;
          margin-left: 3px;
        }
      }
    }
  }

  .Switch {
    &__positive {
      background-color: #73819b;
    }
  }

  .RFRFormRequest__footer {
    display: flex;
    position: absolute;
    bottom: 0;
    z-index: 1051;
    width: calc(100% + 24px);
    overflow-y: hidden;
    background-color: white;
    padding-left: 24px;
    padding-right: 32px;
    padding-top: 14px;
    padding-bottom: 10px;
    margin-left: -24px;
    max-height: 64px;

    ${ShadowHeaderTop}

    &__totals {
      display: flex;
      align-items: center;
      span {
        color: #99a3b5 !important;
      }

      &__items {
        display: flex;
        width: 100%;
        &_item {
          display: flex;
          align-items: center;

          span {
            display: flex;
            align-items: center;
            color: #73819b !important;
          }

          svg {
            margin-right: 9px;
            color: #d1d7e2;
          }
        }
      }
    }

    &__submit {
      margin-left: auto;
      display: flex;
      justify-content: end;
      align-items: center;

      &_btn {
        ${ButtonCss}

        background-color: #314258;
        height: 40px;
        font-size: 16px;
        font-weight: 400;
        text-transform: capitalize;
        width: 112px;
        margin-left: auto;
        letter-spacing: 0.25px;
      }
    }
  }

  &.RFRFormRequest__isNotCollapsed {
  .RFRFormRequest__footer {
      &__totals {
        &__items {
          &_item {
            svg {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
`;

export const RFRFormRequestRequestGetQuotas = styled.div.attrs({
  'data-class': 'RFRFormRequestRequestGetQuotas',
})`
  z-index: 4;
  position: relative;
  padding: 2px 8px 0px 0px;
  background: rgba(255, 255, 255, 1);
  height: calc(100%);
  margin-right: 8px;

  ${CustomScrollbar}

  .RFRFormRequest__Row {
    margin-bottom: 0px;
  }
`;
