import styled from 'styled-components';

import {
  RangePicker, Select, Row,
  DatePicker,
} from 'app-wrapper/view/components';
import { divFlex } from 'app-wrapper/view/themes/mixin';
import Checkbox from 'antd/es/checkbox';

export const AddressBlockSelect = styled(Select).attrs((props: {
  isError: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  'data-class': props?.['data-class'] || 'AddressBlockSelect',
}))`
  .ant-select-selector {
    ${({ isError, theme }) => isError && `border-color: ${theme.themesColors.characterBrandingDanger} !important;`};
    ${({ isError }) => isError && 'z-index: 2;'};
  }

  input {
    color: ${({ theme }) => theme.themesColors.neutralBranding10}
  }
`;

export const AddressBlockRangePicker = styled(RangePicker).attrs((props: {
  isError: boolean
}) => ({
  isError: props.isError,
  'data-class': 'AddressBlockRangePicker',
}))`
  width: 100%;
  min-height: 32px;

  ${({ isError, theme }) => isError && `border-color: ${theme.themesColors.characterBrandingDanger} !important;`};
  ${({ isError }) => isError && 'z-index: 2;'};

  input {
    font-size: 12px !important;
  }
`;

export const AddressBlockDatePicker = styled(DatePicker).attrs((props: {
  isError: boolean
}) => ({
  isError: props.isError,
  'data-class': 'AddressBlockDatePicker',
}))`
  width: 100%;
  min-height: 32px;

  ${({ isError, theme }) => isError && `border-color: ${theme.themesColors.characterBrandingDanger} !important;`};
  ${({ isError }) => isError && 'z-index: 2;'};

  input {
    font-size: 12px !important;
  }
`;

export const AddressBlockIncludeRelatedPort = styled.div.attrs((props: {
  isError: boolean
}) => ({
  isError: props.isError,
  'data-class': 'AddressBlockIncludeRelatedPort',
}))`
  ${divFlex}

  width: 100%;
  margin-top: 18px;

  .ant-checkbox-wrapper .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.themesColors.neutralBranding6};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-checked::after {
    border-color: ${({ theme }) => theme.themesColors.secondaryDot45};
  }

  .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.themesColors.secondaryDot45};
    background-color: ${({ theme }) => theme.themesColors.secondaryDot45};
  }
`;

export const AddressBlockIncludeRelatedPortText = styled.div.attrs((props: {
  isError: boolean
}) => ({
  isError: props.isError,
  'data-class': 'AddressBlockIncludeRelatedPortText',
}))`
  ${divFlex}

  font-size: 12px;
`;

export const AddressBlockIncludeRelatedPortCheckbox = styled(Checkbox).attrs((props: {
  isError: boolean
}) => ({
  isError: props.isError,
  'data-class': 'AddressBlockIncludeRelatedPortCheckbox',
}))`
`;

export const RowAddress = styled(Row)`
  display: flex;
  position: relative;
  width: 100%;
  margin-top: 12px;
`;

export const RowAddressNotCollapsed = styled(RowAddress)`
  .ant-picker-panel-container .ant-picker-panels {
    display: flex;
    flex-direction: column;
  }
  .ant-picker-dropdown {
    top: 32px !important;
  }
`;

export const RowAddressBoth = styled(Row)`
  display: flex;
  position: relative;
  width: 100%;
`;

export const RowAddressMsg = styled(Row)`
  width: 100%;
`;

export const AddressBlockRadioGroup = styled.div.attrs((props: {
  isError: boolean
}) => ({
  isError: props.isError,
  'data-class': 'AddressBlockRadioGroup',
}))`
  ${divFlex}
`;

export const AddressAvailableWrap = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'AddressAvailableWrap',
}))`
  ${divFlex}

  justify-content: center;
  align-items: center;
`;

export const AddressAvailable = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'AddressAvailable',
}))`
  ${divFlex}

  height: 22px;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;

  border-radius: 3px;
  border: 1px solid ${({ theme }) => (theme?.themesColors?.neutralBranding3)};
`;

export const AddressAvailableIcon = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'AddressAvailableIcon',
}))`
  ${divFlex}
  align-items: center;

  margin-right: 4px;
  svg {
    color: ${({ theme }) => (theme?.themesColors?.secondaryDot45)};
  }
`;

export const AddressAvailableText = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'AddressAvailableText',
}))`
  ${divFlex}

  ${({ theme }) => theme?.fonts?.SmallTextFootnoteDescription};
  color: ${({ theme }) => (theme?.themesColors?.secondaryDot45)};
`;

export const AddressItemWrap = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'AddressItemWrap',
}))`
  ${divFlex}

  align-items: center;
`;

export const AddressItemIcon = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'AddressItemIcon',
}))`
  ${divFlex}
  align-items: center;

  margin-right: 6px;
  svg {
    color: ${({ theme }) => (theme?.themesColors?.secondaryDot45)};
  }
`;
