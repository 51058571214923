import React, { FC, ReactNode } from 'react';

import { Dropdown, IDropdownProps } from 'app-wrapper/view/components';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { BodyTextRegularSpan } from 'app-wrapper/view/guideline/Font';

import {
  Wrap,
  TextWrap,
  Placeholder,
} from './InputWithDropdown.styled';

const DISABLED_STYLES = {
  background: themesColors.neutralBranding3,
  cursor: 'not-allowed',
  pointerEvents: 'none' as const,
};

interface IInputWidthDropdownProps {
  icon: ReactNode;
  overlay: ReactNode;
  value?: string;
  placeholder: string;
  dropdownPlacement?: IDropdownProps['placement'];
  disabled?: boolean;
}

export const InputWithDropdown: FC<IInputWidthDropdownProps> = ({
  icon,
  overlay,
  value,
  placeholder,
  dropdownPlacement,
  disabled,
}) => (
  <Dropdown
    placement={dropdownPlacement}
    overlay={(
      <>
        {overlay}
      </>
    )}
  >
    <Wrap
      style={{
        ...(disabled ? DISABLED_STYLES : {}),
      }}
    >
      {icon}

      <TextWrap>
        {value ? (
          <BodyTextRegularSpan>
            {value}
          </BodyTextRegularSpan>
        ) : (
          <Placeholder>
            {placeholder}
          </Placeholder>
        )}
      </TextWrap>
    </Wrap>
  </Dropdown>
);
