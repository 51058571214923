import styled from 'styled-components';

import themesColors from 'app-wrapper/view/themes/themesColors';
import { Button } from 'app-wrapper/view/components';
import {
  SubPageContent,
  GapVerticalContainerStyled,
  FlexRow,
} from 'app-wrapper/view/guideline';
import { ShipIcon, WareHouseSvg } from 'app-wrapper/view/icons';

export const SearchBar = styled(SubPageContent)`
  width: 100%;
  height: 108px;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
  padding: 24px;
`;

export const Row = styled(FlexRow)`
  width: 100%;
`;

export const Item = styled(GapVerticalContainerStyled)`
  width: 25%;
  gap: 4px;
`;

export const StorageIconWrap = styled.div`
  margin-top: 1px;

  svg > * {
    fill: ${({ theme }) => theme.themesColors.neutralBranding10};
  }
`;

export const StorageIcon = styled(WareHouseSvg)`
  width: 16px;
  height: 16px;
`;

export const CarrierShipIcon = styled(ShipIcon)`
  width: 16px;
`;

export const SearchButton = styled(Button)`
  margin-bottom: 1px;
  width: 40px;
  height: 40px;
  background-color: ${themesColors.neutralBranding8};
`;
