import React, {
  memo,
  useCallback, useEffect, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { QUOTAS_STATUS } from 'app-wrapper/constants';
import {
  AppFiltersCheckboxGroupDTM, IAppFiltersCheckboxGroupDTM,
} from 'app-wrapper/models/dtm';
import { TypeSwitch } from 'app-wrapper/view/components';

import {
  IFreightQuotaContentDTM, IFreightQuotasStateDTM,
} from 'monetary/models/dtm';
import { getCarrierSCACNamesRFQ } from 'monetary/constants';

import { TermsAndConditionsDrawerContainer } from 'app-wrapper/view/pages/Overview/components/StepsManager/components';
import {
  RFQContentWrapper,
  RFQContentWrapperHeader,
  RFQContentWrapperHeaderFilters,
  RFQContentWrapperHeaderFiltersItem,
  RFQContentWrapperHeaderFiltersCheckboxItem,
  RFQContentWrapperHeaderFound,
  RFQContentWrapperHeaderSchedule,
  RFQContentWrapperHeaderScheduleButton,
} from 'monetary/view/pages/QuotaRequests/components/Quotas/components/Cards/Cards.styled';
import {
  FiltersContainer,
} from './Filters.styled';

interface IFilterMinMax {
  filterMin: string
  filterMax: string
  limitMin: number
  limitMax: number
  disabledReset: boolean
  isUpdate: boolean
  onChangeFilterMin: (filterMin: string) => void
  onChangeFilterMax: (filterMax: string) => void
  onApply: () => void
  onReset: () => void
}

interface IFilterCheckbox {
  group: IAppFiltersCheckboxGroupDTM[]
  disabledReset: boolean
  isUpdate: boolean
  onChangeGroup: (id: number, value: AppFiltersCheckboxGroupDTM) => void
  onApply: () => void
  onReset: () => void
}

export interface IRFQContentComponentProps {
  isRequestFilters?: boolean
  isVisibleCheckSchedule?: boolean
  isVisibleIsIncludeRelatedPortRequest?: boolean
  requestFiltersStatus?: string
  filters?: {
    isRequestFilters?: boolean
    isHaveFiltersParams?: boolean
    hideFilters?: boolean
    filterIsFavorite?: boolean
    filterIsIncludeRelatedPortRequest?: boolean
    visibleFilterIsFavorite?: boolean
    onChangeFilterIsFavorite: (value: boolean) => void
    onChangeFilterIsIncludeRelatedPortRequest: (value: boolean) => void
    filterPrice?: IFilterMinMax
    filterTransitTime?: IFilterMinMax
    carrier?: IFilterCheckbox
  }
  // new
  quotaRequestServiceByIdData?: IFreightQuotaContentDTM[]
  quotaRequestServiceById?: IFreightQuotasStateDTM
  fullCompleteStatus?: string
  onRunRFQContentComponent: () => void
}

export const FiltersComponent = memo((props: IRFQContentComponentProps) => {
  const {
    filters,
    quotaRequestServiceByIdData,
    quotaRequestServiceById,
    fullCompleteStatus,
    isRequestFilters,
    isVisibleCheckSchedule,
    isVisibleIsIncludeRelatedPortRequest,
    requestFiltersStatus,
    onRunRFQContentComponent,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    onRunRFQContentComponent();
  }, []);

  const onApplyPriceHandler = useCallback(() => {
    filters?.filterPrice?.onApply();
  }, [filters?.filterPrice?.onApply]);

  const isVisibleHeader = !!quotaRequestServiceByIdData?.length
    && quotaRequestServiceById?.quotasOption?.fullCompleteStatus !== QUOTAS_STATUS.failed;
  const isVisibleTotalElements = isVisibleHeader && !!quotaRequestServiceById?.totalElements && !filters?.visibleFilterIsFavorite;

  const isVisibleFilters = (isRequestFilters && ((
    (filters?.filterPrice?.isUpdate
      || filters?.filterTransitTime?.isUpdate
      || filters?.carrier?.isUpdate)
  )
    || filters?.isHaveFiltersParams
    || (isVisibleHeader && !!quotaRequestServiceById?.totalElements)));

  const isVisibleIsIncludeRelatedPortRequestFlag = (isVisibleIsIncludeRelatedPortRequest && !!quotaRequestServiceByIdData?.length)
    || (
      filters?.isRequestFilters
      && isVisibleIsIncludeRelatedPortRequest
      && !quotaRequestServiceByIdData?.length
    );

  const filterPriceComponent = useMemo(() => (
    <>
      {!filters?.hideFilters && filters?.filterPrice && (
        <RFQContentWrapperHeaderFiltersItem
          name={t('price')}
          onApply={onApplyPriceHandler}
          onReset={filters.filterPrice.onReset}
          valueMin={filters.filterPrice.filterMin}
          valueMax={filters.filterPrice.filterMax}
          disabledReset={filters.filterPrice.disabledReset}
          placeholderMin={`${t('min')} $ ${filters.filterPrice.limitMin || ''}`}
          placeholderMax={`${t('max')} $ ${filters.filterPrice.limitMax || ''}`}
          onChangeMin={filters.filterPrice.onChangeFilterMin}
          onChangeMax={filters.filterPrice.onChangeFilterMax}
        />
      )}
    </>
  ), [filters, onApplyPriceHandler]);

  const onChangeFilterIsFavoriteHandler = useCallback(
    (value) => {
      if (filters?.onChangeFilterIsFavorite) {
        filters.onChangeFilterIsFavorite(value);
      }
    },
    [filters],
  );

  const onChangeFilterIsIncludeRelatedPortRequestHandler = useCallback(
    (value) => {
      if (filters?.onChangeFilterIsIncludeRelatedPortRequest) {
        filters.onChangeFilterIsIncludeRelatedPortRequest(!value);
      }
    },
    [filters],
  );

  const filtersGroup = useMemo<IAppFiltersCheckboxGroupDTM[]>(() => (filters?.carrier?.group?.map((item) => ({
    ...item,
    name: getCarrierSCACNamesRFQ(item.value),
  })) || []), [filters?.carrier?.group]);

  return (
    <FiltersContainer>
      <TermsAndConditionsDrawerContainer />
      <RFQContentWrapper>
        <RFQContentWrapperHeader>
          <RFQContentWrapperHeaderFound>
            {isVisibleTotalElements && `${t('ResultsFound')}: ${quotaRequestServiceById?.totalElements || ''}`}
            {!filters?.hideFilters && filters?.visibleFilterIsFavorite && (
              <TypeSwitch
                onChange={onChangeFilterIsFavoriteHandler}
                data-class="RFQContentWrapperHeaderFiltersShowOnlyFavorites"
                rightText={t('ShowOnlyFavorites')}
                checkedChildren="On"
                unCheckedChildren="Off"
                value={!!filters?.filterIsFavorite}
                disabledbg="true"
                disabled={fullCompleteStatus === QUOTAS_STATUS.pending}
              />
            )}
            {isVisibleIsIncludeRelatedPortRequestFlag && (
              <TypeSwitch
                onChange={onChangeFilterIsIncludeRelatedPortRequestHandler}
                disabled={requestFiltersStatus === QUOTAS_STATUS.pending}
                data-class="RFQContentWrapperHeaderFiltersIsVisibleIsIncludeRelatedPortRequest"
                rightText={t('Hide Related Ports')}
                checkedChildren="On"
                unCheckedChildren="Off"
                value={!filters?.filterIsIncludeRelatedPortRequest}
                disabledbg="true"
              />
            )}
          </RFQContentWrapperHeaderFound>
          {isVisibleFilters && (
            <RFQContentWrapperHeaderFilters>
              {filterPriceComponent}
              {!filters?.hideFilters && filters?.filterTransitTime && (
                <RFQContentWrapperHeaderFiltersItem
                  name={t('transitTime')}
                  onApply={filters.filterTransitTime.onApply}
                  onReset={filters.filterTransitTime.onReset}
                  valueMin={filters.filterTransitTime.filterMin}
                  valueMax={filters.filterTransitTime.filterMax}
                  disabledReset={filters.filterTransitTime.disabledReset}
                  placeholderMin={`${t('min')} ${filters.filterTransitTime.limitMin || ''} ${t('Day(s)')}`}
                  placeholderMax={`${t('max')} ${filters.filterTransitTime.limitMax || ''} ${t('Day(s)')}`}
                  onChangeMin={filters.filterTransitTime.onChangeFilterMin}
                  onChangeMax={filters.filterTransitTime.onChangeFilterMax}
                />
              )}
              {!filters?.hideFilters && filters?.carrier && (
                <RFQContentWrapperHeaderFiltersCheckboxItem
                  name={t('carrier')}
                  onApply={filters.carrier.onApply}
                  onReset={filters.carrier.onReset}
                  group={filtersGroup || []}
                  disabledReset={filters.carrier.disabledReset}
                  onChangeGroup={filters.carrier.onChangeGroup}
                />
              )}
            </RFQContentWrapperHeaderFilters>
          )}
          {isVisibleCheckSchedule && (
            <RFQContentWrapperHeaderSchedule>
              <RFQContentWrapperHeaderScheduleButton type="default">
                {t('Check Schedules')}
              </RFQContentWrapperHeaderScheduleButton>
            </RFQContentWrapperHeaderSchedule>
          )}
        </RFQContentWrapperHeader>
      </RFQContentWrapper>
    </FiltersContainer>
  );
});
