import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

import { ShipmentRouteDTM } from 'shipment-operations/models/dtm';

const localState = (state: RootState) => state.shipmentTransportationLocationData;

const getLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getCountriesList = createSelector(
  localState,
  (state) => state.countriesList,
);

const getFullData = createSelector(
  localState,
  (state) => {
    if (!state.type) {
      return undefined;
    }

    const data = ShipmentRouteDTM.fromPlain({
      id: state.id ?? '',
      type: state.type,
      terminalName: state.terminalName ?? '',
      passCode: state.passCode ?? '',
      address1: state.address1 ?? '',
      address2: state.address2 ?? '',
      address3: state.address3 ?? '',
      address4: state.address4 ?? '',
      city: state.city ?? '',
      state: state.state ?? '',
      postcode: state.postcode ?? '',
      country: state.country?.code ?? '',
      releaseDate: state.releaseDate,
    });

    return data;
  },
);

const getInitialData = createSelector(
  localState,
  (state) => {
    if (!state.initialData || !state.type) {
      return ShipmentRouteDTM.fromPlain({
        id: '',
        type: state.type,
        terminalName: '',
        passCode: '',
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        city: '',
        state: '',
        postcode: '',
        country: '',
      });
    }

    return state.initialData;
  },
);

const wasFormDataChanged = createSelector(
  (rootState: RootState) => rootState,
  (state) => {
    const data = getFullData(state);
    const initialData = getInitialData(state);
    let wasChanged = false;

    if (data?.terminalName !== initialData?.terminalName) {
      wasChanged = true;
    }

    if (data?.passCode !== initialData?.passCode) {
      wasChanged = true;
    }

    if (data?.address1 !== initialData?.address1) {
      wasChanged = true;
    }

    if (data?.address2 !== initialData?.address2) {
      wasChanged = true;
    }

    if (data?.address3 !== initialData?.address1) {
      wasChanged = true;
    }

    if (data?.address4 !== initialData?.address2) {
      wasChanged = true;
    }

    if (data?.city !== initialData?.city) {
      wasChanged = true;
    }

    if (data?.state !== initialData?.state) {
      wasChanged = true;
    }

    if (data?.country !== initialData?.country) {
      wasChanged = true;
    }

    if (data?.postcode !== initialData?.postcode) {
      wasChanged = true;
    }

    if (data?.releaseDate?.getDateAsMomentWithOffset().format() !== initialData?.releaseDate?.getDateAsMomentWithOffset().format()) {
      wasChanged = true;
    }

    return wasChanged;
  },
);

const getTerminalName = createSelector(
  localState,
  (state) => state.terminalName,
);

const getTerminalNameError = createSelector(
  localState,
  (state) => state.terminalNameError,
);

const getReleaseDate = createSelector(
  localState,
  (state) => state.releaseDate,
);

const getReleaseDateError = createSelector(
  localState,
  (state) => state.releaseDateError,
);

const getPassCodeError = createSelector(
  localState,
  (state) => state.passCodeError,
);

const getPassCode = createSelector(
  localState,
  (state) => state.passCode,
);

const getAddress1Error = createSelector(
  localState,
  (state) => state.address1Error,
);

const getAddress1 = createSelector(
  localState,
  (state) => state.address1,
);

const getAddress2Error = createSelector(
  localState,
  (state) => state.address2Error,
);

const getAddress2 = createSelector(
  localState,
  (state) => state.address2,
);

const getAddress3Error = createSelector(
  localState,
  (state) => state.address3Error,
);

const getAddress3 = createSelector(
  localState,
  (state) => state.address3,
);

const getAddress4Error = createSelector(
  localState,
  (state) => state.address4Error,
);

const getAddress4 = createSelector(
  localState,
  (state) => state.address4,
);

const getCityError = createSelector(
  localState,
  (state) => state.cityError,
);

const getCity = createSelector(
  localState,
  (state) => state.city,
);

const getStateError = createSelector(
  localState,
  (state) => state.stateError,
);

const getState = createSelector(
  localState,
  (state) => state.state,
);

const getPostcodeError = createSelector(
  localState,
  (state) => state.postcodeError,
);
const getPostcode = createSelector(
  localState,
  (state) => state.postcode,
);

const getCountryError = createSelector(
  localState,
  (state) => state.countryError,
);

const getCountry = createSelector(
  localState,
  (state) => state.country,
);

const getReleaseDateCYEmpty = createSelector(
  localState,
  (state) => state.releaseDateCYEmpty,
);

export const shipmentTransportationLocationDataSelectors = {
  getAddress1,
  getAddress1Error,
  getAddress2,
  getAddress2Error,
  getAddress3,
  getAddress3Error,
  getAddress4,
  getAddress4Error,
  getCity,
  getCityError,
  getCountriesList,
  getCountry,
  getCountryError,
  getFullData,
  getInitialData,
  getLoading,
  getPassCode,
  getPassCodeError,
  getPostcode,
  getPostcodeError,
  getReleaseDate,
  getReleaseDateError,
  getState,
  getStateError,
  getTerminalName,
  getTerminalNameError,
  wasFormDataChanged,
  getReleaseDateCYEmpty,
};
