import React, { FC } from 'react';
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import {
  TitleTextSpan, BodyTextMediumSpan, BodyTextRegularSpan,
} from 'app-wrapper/view/fonts';
import { Button } from 'app-wrapper/view/components';

import themesColors from 'app-wrapper/view/themes/themesColors';
import {
  EditHeader, StyledDivider, ObjectWrapper, StyledSection, StyledKey,
} from './styled';

interface IGreenCheckProps {
  title: string;
  isValid: boolean
}

export const GreenCheck: FC<IGreenCheckProps> = ({ title, isValid }) => (
  <div>
    <CheckCircleOutlined style={{ color: isValid ? 'green' : '#D1D7E2', fontSize: '16px', marginRight: '7px' }} />
    <TitleTextSpan>{title}</TitleTextSpan>
    <StyledDivider />
  </div>
);

interface IStyledEditProps {
  onClick: () => void;
}

export const StyledEdit: FC<IStyledEditProps> = ({ onClick }) => (
  <EditOutlined
    style={{
      marginLeft: '7px', fontSize: '10px', cursor: 'pointer', color: themesColors.darkGrayMiddle,
    }}
    onClick={onClick}
  />
);

interface IEditSectionProps {
  onClick: () => void;
  title: string;
}

export const EditSection: FC<IEditSectionProps> = ({ onClick, title, children }) => (
  <StyledSection>
    <EditHeader>
      <BodyTextMediumSpan>{title}</BodyTextMediumSpan>
      <Button
        type="dashed"
        size="small"
        icon={<EditOutlined />}
        onClick={onClick}
      >
        Edit
      </Button>
    </EditHeader>
    {children}
  </StyledSection>
);

interface IStyledObjectProps {
  customKey: string;
  customValue: string;
}

export const StyledObject: FC<IStyledObjectProps> = ({ customKey, customValue }) => (
  <ObjectWrapper>
    <StyledKey>{customKey}</StyledKey>
    <BodyTextRegularSpan>{customValue}</BodyTextRegularSpan>
  </ObjectWrapper>
);
