import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { REQUEST_STATUS } from 'app-wrapper/constants';

import { UC } from 'monetary/controllers';
import { R } from 'monetary/repository';
import { R as userManagementR } from 'user-management/repository';
import { FreightQuotaContentDTM, IFreightQuotaContentDTM } from 'monetary/models/dtm';
import { EPermissionAttributePolicy } from 'monetary/constants';

import { RFQItemComponent } from './RFQItem.component';

export interface IRFQItemProps {
  data?: IFreightQuotaContentDTM
  isAllRequestPage?: boolean
  idIndex: number
  hideBook?: boolean
  hideFavorite?: boolean
  onScroll: (top: number) => void
}

const RFQItem: FC<IRFQItemProps> = ({
  onScroll, idIndex, isAllRequestPage, hideBook, hideFavorite,
}) => {
  const getFreightQuoteCurrent = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const allQuotasByIndex: FreightQuotaContentDTM = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentAllQuotasAppliedByIndex(idIndex));
  const allQuotasByIndexFileNamePrint = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentAllQuotasByIndexFileNamePrint(idIndex));
  const allQuotasNotAppliedByIndex: FreightQuotaContentDTM = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentAllQuotasNotAppliedByIndex(idIndex));
  const detailRouting = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentAllQuotasDetailsRoutingByIndex(idIndex));
  const isOrganizationInDraft = useSelector(userManagementR.selectors.userOrganizationData.getIsOrganizationInDraft);
  const isOrganizationInWaitingForApproval = useSelector(userManagementR.selectors.userOrganizationData.getIsOrganizationInWaitingForApproval);
  const mustOrganizationAcceptTermsAndCondition = useSelector(userManagementR.selectors.userOrganizationData.getMustOrganizationAcceptTermsAndCondition);

  const detailBreakdown = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentAllQuotasDetailsBreakdownByIndex(idIndex));

  const detailAccessorials = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentAllQuotasDetailsAccessorialsByIndex(idIndex));

  const getFreightQuoteCurrentAllQuotasTotalCost = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentAllQuotasTotalCost(idIndex));

  const getFreightQuoteCurrentAllQuotasIsPaymentTermsOrigin = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentAllQuotasIsPaymentTermsOrigin(idIndex));
  const getFreightQuoteCurrentAllQuotasIsPaymentTermsDestination = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentAllQuotasIsPaymentTermsDestination(idIndex));
  const getRFQNewQuotaPermissions = useSelector(R.selectors.moduleFunctionalityPermissions.getRFQNewQuotaPermissions);
  const quotas = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentQuotas);

  const indexSchedule = quotas?.indexSchedules?.[idIndex]?.idIndex || 0;

  const isLoadingPrint = getFreightQuoteCurrent.form?.services?.status === REQUEST_STATUS.pending;

  const isShowContractName = getRFQNewQuotaPermissions?.quotaItemContractName === EPermissionAttributePolicy.WRITE;

  return (
    <RFQItemComponent
      data={{
        ...allQuotasByIndex,
        schedules: [
          ...allQuotasByIndex?.schedules?.map((item) => ({
            ...item,
            transportations: [
              ...item.transportations || [],
            ],
          })) || [],
        ],
      }}
      isShowContractName={isShowContractName}
      fileNamePrint={allQuotasByIndexFileNamePrint}
      idIndex={idIndex}
      isAllRequestPage={isAllRequestPage}
      dataNotApplied={allQuotasNotAppliedByIndex}
      onScroll={onScroll}
      onPrint={UC.FreightQuote.onSetPrintedId}
      onClearCustomerInfo={UC.FreightQuote.onClearCustomerInfo}
      onBook={UC.FreightQuote.onBook}
      isLoadingPrint={isLoadingPrint}
      printedId={getFreightQuoteCurrent.form?.services?.printedQuotaId}
      shipmentId={getFreightQuoteCurrent.booking?.shipmentId}
      bookingStatus={getFreightQuoteCurrent.booking?.status}
      indexSchedule={indexSchedule}
      freightData={detailRouting.freight}
      originData={detailRouting.origin}
      destinationData={detailRouting.destination}
      breakdownFreight={detailBreakdown.resultFreight}
      breakdownOrigin={detailBreakdown.resultOrigin}
      breakdownDestination={detailBreakdown.resultDestination}
      accessorialsOrigin={detailAccessorials.accessorialsOrigin}
      accessorialsFreight={detailAccessorials.accessorialsFreight}
      accessorialsDestination={detailAccessorials.accessorialsDestination}
      totalCost={getFreightQuoteCurrentAllQuotasTotalCost}
      paymentTermsOrigin={getFreightQuoteCurrentAllQuotasIsPaymentTermsOrigin}
      paymentTermsDestination={getFreightQuoteCurrentAllQuotasIsPaymentTermsDestination}
      isFavorite={!!allQuotasByIndex?.bookmark?.id}
      isFavoriteLoading={quotas?.isFavoriteLoading}
      onClickToggleIsFavorite={UC.FreightQuote.onClickToggleIsFavorite(`${allQuotasByIndex.id}`, `${allQuotasByIndex.bookmark?.id || ''}`)}
      isOrganizationInDraft={isOrganizationInDraft}
      hideBook={hideBook}
      hideFavorite={hideFavorite}
      isOrganizationInWaitingForApproval={isOrganizationInWaitingForApproval}
      mustOrganizationAcceptTermsAndCondition={mustOrganizationAcceptTermsAndCondition}
    />
  );
};

const RFQItemCached = memo(RFQItem);

export { RFQItemCached as RFQItem };
