import React, { FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { EShippingPartyTypes, RequiredShippingParties, ShippingPartyNames } from 'shipment-operations/constants';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { UC as appWrapperUC } from 'app-wrapper/controllers';
import { ShippingPartiesFormTitleComponent } from './ShippingPartiesFormTitle.component';

const ShippingPartiesFormTitleContainer: FC = memo(() => {
  const selected = useSelector(R.selectors.shippingParties.getSelectedFormType);
  const isLoading = useSelector(R.selectors.shippingParties.getFormDataLoadingState);
  const isInDraft = useSelector(R.selectors.shippingParties.isSelectedFormChanged);
  const currentFormState = useSelector(R.selectors.shippingParties.getSelectedCurrentShippingParty);
  const selectedPartyRole = useSelector(R.selectors.shippingParties.getSelectedFormType);
  const permissions = useSelector(R.selectors.shippingParties.getShippingPartyPermissionsByRole(selectedPartyRole));

  const openChangeRequest = useCallback(() => {
    if (selected === EShippingPartyTypes.CARGO_SUPPLIER) {
      appWrapperUC.drawer.openChangeRequestCargoSupplierDrawer();
    }

    if (selected === EShippingPartyTypes.CARGO_RECEIVER) {
      appWrapperUC.drawer.openChangeRequestCargoReceiverDrawer();
    }

    if (selected === EShippingPartyTypes.HOUSE_SHIPPER) {
      appWrapperUC.drawer.openChangeRequestShipperDrawer();
    }

    if (selected === EShippingPartyTypes.HOUSE_CONSIGNEE) {
      appWrapperUC.drawer.openChangeRequestConsigneeDrawer();
    }

    if (selected === EShippingPartyTypes.HOUSE_NOTIFY_PARTY) {
      appWrapperUC.drawer.openChangeRequestNotifyDrawer();
    }
  }, []);

  return (
    <ShippingPartiesFormTitleComponent
      type={selected}
      title={ShippingPartyNames[selected]}
      isRequired={RequiredShippingParties.includes(selected)}
      isInDraft={isInDraft}
      isLoading={isLoading}
      isAbleToEditCompany={permissions.isAbleToEditCompany}
      isAbleToRequestChanges={permissions.isAbleToRequestChanges}
      shippingPartiesCurrentData={currentFormState}
      onShippingPartyRemove={UC.shippingParties.removeShippingParty}
      openChangeRequest={openChangeRequest}
    />
  );
});

export { ShippingPartiesFormTitleContainer as ShippingPartiesFormTitle };
