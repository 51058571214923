import { SmallTextFootnoteDescription } from 'app-wrapper/view/fonts';
import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const InfoBlock = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'InfoBlock',
}))`
  ${divFlex}
  width: 100%;
  height: 90px;
  
  padding: 12px 16px 16px 16px;

  align-items: center;
  justify-content: center;
  
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border: 1px solid ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border-radius: 4px;
`;

export const InfoBlockContainer = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'InfoBlockContainer',
}))`
  ${divFlex}
  width: 100%;
  height: 100%;
  gap: 16px;
`;

export const InfoBlockIconContainer = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'InfoBlockIconContainer',
}))`
  ${divFlex}
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  border: 1px solid ${({ theme }) => theme?.themesColors?.primaryBranding1};
  background-color: ${({ theme }) => theme?.themesColors?.primaryBranding1};
  border-radius: 4px;
  
  margin-top: 5px;
`;

export const InfoBlockIcon = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'InfoBlockIcon',
}))`
  ${divFlex}

  svg {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme?.themesColors?.primaryBranding6}};
  }
`;

export const InfoBlockBody = styled.span.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'InfoBlockBody',
}))`
  ${SmallTextFootnoteDescription}
  color: ${({ theme }) => theme?.themesColors?.secondaryDot45}};
`;
