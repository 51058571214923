import React, { useCallback, useMemo } from 'react';
import get from 'lodash/fp/get';
import { useTranslation } from 'react-i18next';

import {
  NameOfDocument,
  StyledStatus,
  StyledTotal,
  StyledType,
} from 'shipment-operations/view/pages/ShipmentBilling/ShipmentBilling.styled';
import { DataTypeBillingDTM } from 'shipment-operations/models/dtm';
import { RouteNames } from 'app-wrapper/constants';
import { InvoiceStatusesEnum as statuses, EInvoiceType, PAYABLES } from 'shipment-operations/constants';
import { ShareInvoice } from 'shipment-operations/view/components';

const useBilling = (shipmentId?: string, documentType?: string) => {
  const { t } = useTranslation();

  const status = useMemo(() => ({
    [statuses.PAID]: <StyledStatus color="#75CB3F">{t('billingStatuses.paid')}</StyledStatus>,
    [statuses.UNPAID]: <StyledStatus color="#6C79DE">{t('billingStatuses.unpaid')}</StyledStatus>,
    [statuses.PARTIALLY_PAID]: <StyledStatus color="#F1AE00">{t('billingStatuses.partiallyPaid')}</StyledStatus>,
    [statuses.APPLIED]: <StyledStatus color="#75CB3F">{t('billingStatuses.applied')}</StyledStatus>,
    [statuses.VOID]: <StyledStatus color="#82909D">{t('billingStatuses.void')}</StyledStatus>,
  }), [t]);

  const renderCell = useCallback(
    (item: number) => (item >= 0 ? `$ ${item.toFixed(2)}` : `- $ ${parseFloat(item.toString().slice(1)).toFixed(2)}`),
    [],
  );

  const columns = useMemo(() => [
    {
      title: '',
      dataIndex: 'download',
      key: 'download',
      render: (item: string, record: DataTypeBillingDTM | {}) => {
        const invoice = record as DataTypeBillingDTM;

        return invoice.type.toUpperCase() === EInvoiceType.INVOICE ? (
          <ShareInvoice shipmentId={String(shipmentId)} invoiceId={invoice.id} isAPInvoiceType={documentType === PAYABLES} />
        ) : null;
      },
      width: '5%',
    },
    {
      title: 'ID',
      dataIndex: 'number',
      key: 'number',
      render: (item: string, record: DataTypeBillingDTM | {}) => {
        const id = get(['id'], record);
        const type = get(['type'], record);
        return (
          <NameOfDocument
            to={type === 'invoice'
              ? RouteNames.SHIPMENT_BILLING_INVOICE(shipmentId, id, documentType)
              : RouteNames.SHIPMENT_CREDIT_NOTE(shipmentId, id, documentType)}
          >
            {item}
          </NameOfDocument>
        );
      },
      width: '15%',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: '15%',
      render: (item: string) => <StyledType>{item}</StyledType>,
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      width: '15%',
    },
    {
      title: 'Due date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      width: '15%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (item: statuses) => status[item],
      width: '8%',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: renderCell,
      width: '13.5%',
      align: 'right' as 'right',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      render: renderCell,
      width: '13.5%',
      align: 'right' as 'right',
    },
  ], [shipmentId, documentType]);

  const columnsFooter = useMemo(() => [
    {
      width: '5%',
      key: 1,
      render: () => <StyledTotal>{t('total')}</StyledTotal>,
    },
    { width: '15%', key: 2 },
    { width: '15%', key: 3 },
    { width: '15%', key: 4 },
    { width: '15%', key: 5 },
    { width: '8%', key: 6 },
    {
      dataIndex: 'totalSum',
      key: 'totalSum',
      width: '13.5%',
      render: (item: number) => <StyledTotal>$ {item.toFixed(2)}</StyledTotal>,
      align: 'right' as 'right',
    },
    {
      dataIndex: 'totalBalance',
      key: 'totalBalance',
      width: '13.5%',
      render: (item: number) => <StyledTotal>$ {item.toFixed(2)}</StyledTotal>,
      align: 'right' as 'right',
    },
  ], []);

  return {
    columns,
    columnsFooter,
  };
};

export { useBilling };
