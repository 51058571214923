import { BaseController, controller } from 'proto/BaseController';
import { R } from 'shipment-operations/repository';
import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { ParsedInvoiceDTM } from 'shipment-operations/models/dtm';
import { PAYABLES, PermissionAttributePolicy, RECEIVABLES } from 'shipment-operations/constants';
import { hasAccess } from 'app-wrapper/utils';
import { RouteNames } from 'app-wrapper/constants';

@controller
export class ShipmentBillingController extends BaseController {
  onStart(shipmentId?: string) {
    const permissions = this.store.getState().shipment.permissions.shipmentCharges;
    if (!hasAccess(permissions.shipmentChargesAvailability, PermissionAttributePolicy.WRITE)) {
      this.navigate(RouteNames.SHIPMENT_BILLING_PAYABLES(shipmentId));
      return;
    }

    this.dispatch(R.actions.shipmentBilling.setDefaultActiveKey(RECEIVABLES));

    this.loadData(shipmentId, RECEIVABLES);
  }

  onStartPayables(shipmentId?: string) {
    this.dispatch(R.actions.shipmentBilling.setDefaultActiveKey(PAYABLES));

    this.loadData(shipmentId, PAYABLES);
  }

  loadData = async (shipmentId?: string, type?: string) => {
    if (!shipmentId || !type) {
      return;
    }
    let response: ParsedInvoiceDTM[] | null;

    this.dispatch(R.actions.shipmentBilling.setIsLoading(true));
    try {
      response = await R.services.shipmentBilling.getShipmentEntities(shipmentId, type);
    } catch (e) {
      this.dispatch(R.actions.shipmentBilling.setError(true));
      return;
    }
    this.dispatch(R.actions.shipmentBilling.setShipmentBilling(response));
  }

  resetData = () => {
    apiWorker.abortAllRequests();
    this.dispatch(R.actions.shipmentBilling.clear());
  }
}
