import { Type } from 'class-transformer';
import {
  IsDefined, IsOptional, IsString, ValidateNested,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { EPlaceTypes } from 'monetary/constants';

import { FreightSelectFieldCoordinatesDTM, FreightSelectFieldCountryDTM, IFreightSelectFieldCoordinatesDTM } from '../Freight';

export interface IGetRFQDoorAutocompleteBeProps {
  value: string
  sessionToken: string
  country?: string
  types?: EPlaceTypes[]
}

export interface IGetRFQDoorAutocompleteBeResponse {
  id: string
  description: string
  types?: EPlaceTypes[]
}

export interface ILocationsServiceDTM {
  code: string;
  country?: FreightSelectFieldCountryDTM;
  subdivisionCode?: string;
  subdivisionName?: string;
  subdivisionType?: string;
  locationCode?: string;
  locationName?: string;
  coordinates?: IFreightSelectFieldCoordinatesDTM;
  iataCode?: string;
  timeZoneId?: string;
}

export class LocationsServiceDTM extends BaseDTM<ILocationsServiceDTM> {
  @IsDefined()
  @IsString()
  code: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightSelectFieldCountryDTM)
  country?: FreightSelectFieldCountryDTM;

  @IsOptional()
  @IsString()
  subdivisionCode: string;

  @IsOptional()
  @IsString()
  subdivisionName: string;

  @IsOptional()
  @IsString()
  subdivisionType: string;

  @IsOptional()
  @IsString()
  locationCode: string;

  @IsOptional()
  @IsString()
  locationName: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightSelectFieldCoordinatesDTM)
  coordinates?: FreightSelectFieldCoordinatesDTM;

  @IsOptional()
  @IsString()
  iataCode: string;

  @IsOptional()
  @IsString()
  timeZoneId: string;
}
