import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { BadInttraRequestError } from 'shipment-operations/models/errors';
import { IOverviewState } from 'shipment-operations/models/states';
import {
  CargoDTM,
  ContainerSumWithTypeDTM,
  OverviewReferencesDTM,
  OverviewShippingPartiesDTM,
  ShippingPartyOverviewDTM,
  RollWarningInfoDTM,
  ShipmentStatsCustomer,
} from 'shipment-operations/models/dtm';
import { EShipmentCancellationType } from 'shipment-operations/constants';

export const initialState: IOverviewState = {
  wasManualBookingConfirmationSuccessful: false,
  isCustomerBookingDrawerOpened: false,
  isWaitingAcceptFromCustomer: false,
  isTermsAndConditionsConfirmed: false,
  isCancelShipmentModalOpened: false,
  shipmentCancellationType: undefined,
  shipmentCancellationMessage: '',
  isCancelShipmentPending: false,
  isNRAConfirmed: false,
  isLoading: true,
  references: {},
  shippingParties: {
    shipper: ShippingPartyOverviewDTM.createEmpty(),
    consignee: ShippingPartyOverviewDTM.createEmpty(),
    notifyParty: ShippingPartyOverviewDTM.createEmpty(),
    accountHolder: ShippingPartyOverviewDTM.createEmpty(),
    forwarderAgent: ShippingPartyOverviewDTM.createEmpty(),
    deliveryAgent: ShippingPartyOverviewDTM.createEmpty(),
    oceanCarrier: ShippingPartyOverviewDTM.createEmpty(),
  },
  cargos: [],
  containersSumWithType: [],
  charges: {
    isLoading: false,
    isError: false,
    data: [],
  },
  stats: {
    isLoading: false,
    isError: false,
    data: ShipmentStatsCustomer.createEmpty(),
  },
  rollWarningInfo: RollWarningInfoDTM.createEmpty(),
  shipmentId: '',
  manualReferenceNumber: '',
  isManualReferenceNumberDrawerPending: false,
  isModalPending: false,
  mblNumberToEdit: '',
  carrierReferenceNumberToEdit: '',
};

export const overviewStore = createSlice({
  name: 'overview',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setBookingRequestSuccessful: (state, action: PayloadAction<boolean>) => {
      state.wasBookingRequestSuccessful = action.payload;
    },
    setShipmentInstructionRequestSuccessful: (state, action: PayloadAction<boolean>) => {
      state.wasShipmentInstructionRequestSuccessful = action.payload;
    },
    setIsCustomerBookingDrawerOpened: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isCustomerBookingDrawerOpened: payload,
    }),
    setIsWaitingAcceptFromCustomer: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isWaitingAcceptFromCustomer: payload,
    }),
    setIsCancelShipmentModalOpened: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isCancelShipmentModalOpened: payload,
    }),
    setIsCancelShipmentPending: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isCancelShipmentPending: payload,
    }),
    clearCancelShipmentModalData: (state) => ({
      ...state,
      shipmentCancellationType: undefined,
      shipmentCancellationMessage: '',
      isCancelShipmentPending: false,
    }),
    setIsTermsAndConditionsConfirmed: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isTermsAndConditionsConfirmed: payload,
    }),
    setShipmentCancellationType: (state, { payload }: PayloadAction<EShipmentCancellationType | undefined>) => ({
      ...state,
      shipmentCancellationType: payload,
    }),
    setIsManualReferenceNumberDrawerPending: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isManualReferenceNumberDrawerPending: payload,
    }),
    setShipmentCancellationMessage: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      shipmentCancellationMessage: payload,
    }),
    setMBLNumberToEdit: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      mblNumberToEdit: payload,
    }),
    setCarrierReferenceNumberToEdit: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      carrierReferenceNumberToEdit: payload,
    }),
    setIsModalPending: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isModalPending: payload,
    }),
    setShipmentReference: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      shipmentReference: payload,
    }),
    setManualReferenceNumber: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      manualReferenceNumber: payload,
    }),
    setShipmentReferenceIsError: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      shipmentReferenceIsError: payload,
    }),
    setModalShipmentReference: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      modalShipmentReference: payload,
    }),
    setIsNRAConfirmed: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isNRAConfirmed: payload,
    }),
    setRollWarningInfo: (state, { payload }: PayloadAction<RollWarningInfoDTM>) => ({
      ...state,
      rollWarningInfo: payload,
    }),
    setWasManualBookingConfirmationSuccessful: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      wasManualBookingConfirmationSuccessful: payload,
    }),
    setServerError: (state, action: PayloadAction<BadInttraRequestError | undefined>) => {
      state.serverError = action.payload;
    },
    setCargos: (state, { payload }: PayloadAction<CargoDTM[]>) => {
      state.cargos = payload;
    },
    setContainersSumWithType: (state, { payload }: PayloadAction<ContainerSumWithTypeDTM[]>) => {
      state.containersSumWithType = payload;
    },
    setReferences: (state, action: PayloadAction<OverviewReferencesDTM>) => {
      const {
        mbl, contract, inttra, booking,
      } = action.payload;

      state.references.mbl = mbl;
      state.references.contract = contract;
      state.references.inttra = inttra;
      state.references.booking = booking;
    },
    setShippingParties: (state, action: PayloadAction<OverviewShippingPartiesDTM>) => {
      const {
        shipper,
        consignee,
        notifyParty,
        accountHolder,
        forwarderAgent,
        deliveryAgent,
        oceanCarrier,
      } = action.payload;

      state.shippingParties.shipper = shipper;
      state.shippingParties.consignee = consignee;
      state.shippingParties.notifyParty = notifyParty;
      state.shippingParties.accountHolder = accountHolder;
      state.shippingParties.forwarderAgent = forwarderAgent;
      state.shippingParties.deliveryAgent = deliveryAgent;
      state.shippingParties.oceanCarrier = oceanCarrier;
    },
    setChargesLoading: (state, { payload }) => {
      state.charges.isLoading = payload;
    },
    setCharges: (state, { payload }) => {
      state.charges.data = payload;
      state.charges.isLoading = false;
    },
    setChargesError: (state, { payload }) => {
      state.charges.isError = payload;
      state.charges.isLoading = false;
    },
    setStatsLoading: (state, { payload }) => {
      state.stats.isLoading = payload;
    },
    setStats: (state, { payload }) => {
      state.stats.data = payload;
      state.stats.isLoading = false;
    },
    setStatsError: (state, { payload }) => {
      state.stats.isError = payload;
      state.stats.isLoading = false;
    },
    setShipmentId: (state, { payload }: PayloadAction<string>) => {
      state.shipmentId = payload;
    },
    reset: () => ({
      ...initialState,
    }),
    resetAlert: (state) => ({
      ...state,
      wasManualBookingConfirmationSuccessful: false,
      wasBookingRequestSuccessful: false,
      wasShipmentInstructionRequestSuccessful: false,
      serverError: undefined,
    }),
  },
});

export const overviewActions = overviewStore.actions;
export const overviewReducer = overviewStore.reducer;
