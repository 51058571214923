export enum PackageType {
  AEROSOL = 'AEROSOL',
  AMMO_PACK = 'AMMO_PACK',
  AMPOULE = 'AMPOULE',
  AMPOULE_NON_PROTECTED = 'AMPOULE_NON_PROTECTED',
  AMPOULE_PROTECTED = 'AMPOULE_PROTECTED',
  ATOMIZER = 'ATOMIZER',
  ATTACHMENT = 'ATTACHMENT',
  BAG = 'BAG',
  BALE_COMPRESSED = 'BALE_COMPRESSED',
  BALE_NON_COMPRESSED = 'BALE_NON_COMPRESSED',
  BALL = 'BALL',
  BALLOON_NON_PROTECTED = 'BALLOON_NON_PROTECTED',
  BALLOON_PROTECTED = 'BALLOON_PROTECTED',
  BANDING = 'BANDING',
  BAR = 'BAR',
  BARGE = 'BARGE',
  BARREL = 'BARREL',
  BARS_IN_BUNDLE_BUNCH_TRUSS = 'BARS_IN_BUNDLE_BUNCH_TRUSS',
  BASIN = 'BASIN',
  BASKET = 'BASKET',
  BEAM = 'BEAM',
  BEER_CRATE = 'BEER_CRATE',
  BELT = 'BELT',
  BELTING = 'BELTING',
  BIN = 'BIN',
  BING_CHEST = 'BING_CHEST',
  BLOCK = 'BLOCK',
  BOARD = 'BOARD',
  BOARD_IN_BUNDLE_BUNCH_TRUSS = 'BOARD_IN_BUNDLE_BUNCH_TRUSS',
  BOBBIN = 'BOBBIN',
  BOLT = 'BOLT',
  BOTTLE = 'BOTTLE',
  BOTTLE_CRATE = 'BOTTLE_CRATE',
  BOTTLE_NON_PROTECTED_BULBOUS = 'BOTTLE_NON_PROTECTED_BULBOUS',
  BOTTLE_NON_PROTECTED_CYLINDRICAL = 'BOTTLE_NON_PROTECTED_CYLINDRICAL',
  BOTTLE_PROTECTED_BULBOUS = 'BOTTLE_PROTECTED_BULBOUS',
  BOTTLE_PROTECTED_CYLINDRICAL = 'BOTTLE_PROTECTED_CYLINDRICAL',
  BOX = 'BOX',
  BOX_WOODEN_NATURAL_WOOD_SIFTPROOF_WALL = 'BOX_WOODEN_NATURAL_WOOD_SIFTPROOF_WALL',
  BRACING = 'BRACING',
  BUCKET = 'BUCKET',
  BULK = 'BULK',
  BULK_GAS_1031_MBAR_15C = 'BULK_GAS_1031_MBAR_15C',
  BULK_LIQUEFIED_GAS_ABNORMAL_TEMPERATURE_PRESSURE = 'BULK_LIQUEFIED_GAS_ABNORMAL_TEMPERATURE_PRESSURE',
  BULK_LIQUID = 'BULK_LIQUID',
  BULK_SCRAP_METAL = 'BULK_SCRAP_METAL',
  BULK_SOLID_FINE_PARTICLES_POWDERS = 'BULK_SOLID_FINE_PARTICLES_POWDERS',
  BULK_SOLID_GRANULAR_PARTICLES_GRAINS = 'BULK_SOLID_GRANULAR_PARTICLES_GRAINS',
  BULK_SOLID_LARGE_PARTICLES_NODULES = 'BULK_SOLID_LARGE_PARTICLES_NODULES',
  BUNCH = 'BUNCH',
  BUNDLE = 'BUNDLE',
  BUTT = 'BUTT',
  CABINET = 'CABINET',
  CAGE = 'CAGE',
  CAN = 'CAN',
  CANISTER = 'CANISTER',
  CANVAS = 'CANVAS',
  CAN_CASE = 'CAN_CASE',
  CAN_CYLINDRICAL = 'CAN_CYLINDRICAL',
  CAN_RECTANGULAR = 'CAN_RECTANGULAR',
  CAPSULE = 'CAPSULE',
  CARBOY = 'CARBOY',
  CARBOY_NON_PROTECTED = 'CARBOY_NON_PROTECTED',
  CARBOY_PROTECTED = 'CARBOY_PROTECTED',
  CARD = 'CARD',
  CARRIER = 'CARRIER',
  CARTON = 'CARTON',
  CARTRIDGE = 'CARTRIDGE',
  CART_FLATBED = 'CART_FLATBED',
  CAR_LOAD_RAIL = 'CAR_LOAD_RAIL',
  CASE = 'CASE',
  CASK = 'CASK',
  CHEESES = 'CHEESES',
  CHEST = 'CHEST',
  CHURN = 'CHURN',
  CLAMSHELL = 'CLAMSHELL',
  COFFER = 'COFFER',
  COFFIN = 'COFFIN',
  COIL = 'COIL',
  CONE = 'CONE',
  CONTAINER_GALLON = 'CONTAINER_GALLON',
  CONTAINER_INTERMEDIATE = 'CONTAINER_INTERMEDIATE',
  CONTAINER_METAL = 'CONTAINER_METAL',
  CONTAINER_MIXED_TYPES = 'CONTAINER_MIXED_TYPES',
  CONTAINER_NOS = 'CONTAINER_NOS',
  CONTAINER_OUTER = 'CONTAINER_OUTER',
  CORE = 'CORE',
  CORNER_REINFORCEMENT = 'CORNER_REINFORCEMENT',
  COVER = 'COVER',
  CRADLE = 'CRADLE',
  CRATE = 'CRATE',
  CRATE_METAL = 'CRATE_METAL',
  CREEL = 'CREEL',
  CUBE = 'CUBE',
  CUP = 'CUP',
  CYLINDER = 'CYLINDER',
  CYLINDER_SPIN = 'CYLINDER_SPIN',
  DEMIJOHN_NON_PROTECTED = 'DEMIJOHN_NON_PROTECTED',
  DEMIJOHN_PROTECTED = 'DEMIJOHN_PROTECTED',
  DISPENSER = 'DISPENSER',
  DRUM = 'DRUM',
  DRUM_ALUMINIUM_NON_REMOVABLE_HEAD = 'DRUM_ALUMINIUM_NON_REMOVABLE_HEAD',
  DRUM_PLASTIC_REMOVABLE_HEAD = 'DRUM_PLASTIC_REMOVABLE_HEAD',
  EDGE_PROTECTION = 'EDGE_PROTECTION',
  ENGINE_CONTAINER = 'ENGINE_CONTAINER',
  ENVELOPE = 'ENVELOPE',
  ENVELOPE_STEEL = 'ENVELOPE_STEEL',
  FILM_PACK = 'FILM_PACK',
  FIRKIN = 'FIRKIN',
  FLASK = 'FLASK',
  FLEXIBAG = 'FLEXIBAG',
  FLEXITANK = 'FLEXITANK',
  FLO_BIN = 'FLO_BIN',
  FOODTAINER = 'FOODTAINER',
  FOOTLOCKER = 'FOOTLOCKER',
  FRAME = 'FRAME',
  FRAMED_CRATE = 'FRAMED_CRATE',
  FRUIT_CRATE = 'FRUIT_CRATE',
  GARMENT_ON_HANGER = 'GARMENT_ON_HANGER',
  GAS_BOTTLE = 'GAS_BOTTLE',
  GIRDER = 'GIRDER',
  GIRDERS_IN_BUNDLE_BUNCH_TRUSS = 'GIRDERS_IN_BUNDLE_BUNCH_TRUSS',
  HAMPER = 'HAMPER',
  HANGER = 'HANGER',
  HEADS_OF_BEEF = 'HEADS_OF_BEEF',
  HOGSHEAD = 'HOGSHEAD',
  HOUSEHOLD_GOOD_CONTAINER_WOOD = 'HOUSEHOLD_GOOD_CONTAINER_WOOD',
  INGOT = 'INGOT',
  INGOTS_IN_BUNDLE_BUNCH_TRUSS = 'INGOTS_IN_BUNDLE_BUNCH_TRUSS',
  JAR = 'JAR',
  JERRI_CAN_CYLINDRICAL = 'JERRI_CAN_CYLINDRICAL',
  JERRI_CAN_RECTANGULAR = 'JERRI_CAN_RECTANGULAR',
  JUG = 'JUG',
  JUTE_BAG = 'JUTE_BAG',
  KEG = 'KEG',
  KIT = 'KIT',
  LIFTS = 'LIFTS',
  LIFTVAN = 'LIFTVAN',
  LINERS = 'LINERS',
  LIP_TOP = 'LIP_TOP',
  LOG = 'LOG',
  LOGS_IN_BUNDLE_BUNCH_TRUSS = 'LOGS_IN_BUNDLE_BUNCH_TRUSS',
  LOOSE = 'LOOSE',
  LOT = 'LOT',
  LUG = 'LUG',
  LUGGAGE = 'LUGGAGE',
  MAT = 'MAT',
  MATCH_BOX = 'MATCH_BOX',
  MILITARY_AIRCRAFT_CONTAINER = 'MILITARY_AIRCRAFT_CONTAINER',
  MILITARY_SEALIFT_COMMAND_VAN = 'MILITARY_SEALIFT_COMMAND_VAN',
  MILITARY_VAN = 'MILITARY_VAN',
  MILK_CRATE = 'MILK_CRATE',
  MULTIPLY_BAG = 'MULTIPLY_BAG',
  MULTIWALL_SACK = 'MULTIWALL_SACK',
  MULTIWALL_SECURED_WAREHOUSE_PALLET = 'MULTIWALL_SECURED_WAREHOUSE_PALLET',
  MULTI_ROLL_PACK = 'MULTI_ROLL_PACK',
  MUTUALLY_DEFINED = 'MUTUALLY_DEFINED',
  NAVY_CARGO_TRANSPORT_CONTAINER = 'NAVY_CARGO_TRANSPORT_CONTAINER',
  NEST = 'NEST',
  NET = 'NET',
  NOIL = 'NOIL',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  PACKED_NOS = 'PACKED_NOS',
  PACKET = 'PACKET',
  PACK_CARDBOARD_WITH_BOTTLE_GRIP_HOLES = 'PACK_CARDBOARD_WITH_BOTTLE_GRIP_HOLES',
  PAIL = 'PAIL',
  PALLET = 'PALLET',
  PAN = 'PAN',
  PARCEL = 'PARCEL',
  PARTITIONING = 'PARTITIONING',
  PEN = 'PEN',
  PIECE = 'PIECE',
  PIECES = 'PIECES',
  PIPE = 'PIPE',
  PIPELINE = 'PIPELINE',
  PIPES_IN_BUNDLE_BUNCH_TRUSS = 'PIPES_IN_BUNDLE_BUNCH_TRUSS',
  PIPE_RACK = 'PIPE_RACK',
  PITCHER = 'PITCHER',
  PLANK = 'PLANK',
  PLANKS_PIPES_IN_BUNDLE_BUNCH_TRUSS = 'PLANKS_PIPES_IN_BUNDLE_BUNCH_TRUSS',
  PLATE = 'PLATE',
  PLATES_IN_BUNDLE_BUNCH_TRUSS = 'PLATES_IN_BUNDLE_BUNCH_TRUSS',
  PLATFORM = 'PLATFORM',
  PLATFORM_UNSPECIFIED_WEIGHT_DIMENSIONS = 'PLATFORM_UNSPECIFIED_WEIGHT_DIMENSIONS',
  POT = 'POT',
  POUCH = 'POUCH',
  PRIMARY_LIFT_CONTAINER = 'PRIMARY_LIFT_CONTAINER',
  PRIVATE_VEHICLE = 'PRIVATE_VEHICLE',
  PUNNET = 'PUNNET',
  QUARTER_OF_BEEF = 'QUARTER_OF_BEEF',
  RACK = 'RACK',
  RACK_CLOTHING_HANGER = 'RACK_CLOTHING_HANGER',
  RACK_DOUBLE_LENGTH = 'RACK_DOUBLE_LENGTH',
  RACK_HALF_STANDARD = 'RACK_HALF_STANDARD',
  RACK_KNOCK_DOWN = 'RACK_KNOCK_DOWN',
  RAIL_SEMICONDUCTOR = 'RAIL_SEMICONDUCTOR',
  RECEPTACLE_FIBRE = 'RECEPTACLE_FIBRE',
  RECEPTACLE_GLASS = 'RECEPTACLE_GLASS',
  RECEPTACLE_METAL = 'RECEPTACLE_METAL',
  RECEPTACLE_PAPER = 'RECEPTACLE_PAPER',
  RECEPTACLE_PLASTIC = 'RECEPTACLE_PLASTIC',
  RECEPTACLE_PLASTIC_WRAPPED = 'RECEPTACLE_PLASTIC_WRAPPED',
  RECEPTACLE_WOODEN = 'RECEPTACLE_WOODEN',
  REDNET = 'REDNET',
  REEL = 'REEL',
  REEL_FORWARD = 'REEL_FORWARD',
  REEL_REVERSE = 'REEL_REVERSE',
  REINFORCEMENT = 'REINFORCEMENT',
  RING = 'RING',
  ROD = 'ROD',
  RODS_IN_BUNDLE_BUNCH_TRUSS = 'RODS_IN_BUNDLE_BUNCH_TRUSS',
  ROLL = 'ROLL',
  SACHET = 'SACHET',
  SACK = 'SACK',
  SEA_CHEST = 'SEA_CHEST',
  SEA_VAN = 'SEA_VAN',
  SEPARATOR_DIVIDER = 'SEPARATOR_DIVIDER',
  SHALLOW_CRATE = 'SHALLOW_CRATE',
  SHEET = 'SHEET',
  SHEETMETAL = 'SHEETMETAL',
  SHEETS_IN_BUNDLE_BUNCH_TRUSS = 'SHEETS_IN_BUNDLE_BUNCH_TRUSS',
  SHEET_PLASTIC_WRAPPING = 'SHEET_PLASTIC_WRAPPING',
  SHOOK = 'SHOOK',
  SHRINKWRAPPED = 'SHRINKWRAPPED',
  SIDE_OF_BEEF = 'SIDE_OF_BEEF',
  SKELETON_CASE = 'SKELETON_CASE',
  SKID = 'SKID',
  SKID_DOUBLE_LENGTH = 'SKID_DOUBLE_LENGTH',
  SKID_ELEVATING_OF_LIFT_TRUCK = 'SKID_ELEVATING_OF_LIFT_TRUCK',
  SLAB = 'SLAB',
  SLEEVE = 'SLEEVE',
  SLIPSHEET = 'SLIPSHEET',
  SPINDLE = 'SPINDLE',
  SPOOL = 'SPOOL',
  STRETCH_WRAPPED = 'STRETCH_WRAPPED',
  SUITCASE = 'SUITCASE',
  TABLET = 'TABLET',
  TANK = 'TANK',
  TANK_CAR = 'TANK_CAR',
  TANK_CONTAINER_GENERIC = 'TANK_CONTAINER_GENERIC',
  TANK_CYLINDRICAL = 'TANK_CYLINDRICAL',
  TANK_RECTANGULAR = 'TANK_RECTANGULAR',
  TANK_TRUCK = 'TANK_TRUCK',
  TEA_CHEST = 'TEA_CHEST',
  TIERCE = 'TIERCE',
  TIN = 'TIN',
  TOTE_BIN = 'TOTE_BIN',
  TOTE_BIN_DOUBLE_LENGTH = 'TOTE_BIN_DOUBLE_LENGTH',
  TOTE_BIN_HALF_STANDARD = 'TOTE_BIN_HALF_STANDARD',
  TOTE_BIN_KNOCK_DOWN = 'TOTE_BIN_KNOCK_DOWN',
  TOTE_CAN = 'TOTE_CAN',
  TRAILER_CONTAINER_LOAD_RAIL = 'TRAILER_CONTAINER_LOAD_RAIL',
  TRAY = 'TRAY',
  TRUCK = 'TRUCK',
  TRUNK = 'TRUNK',
  TRUSS = 'TRUSS',
  TUB = 'TUB',
  TUBE = 'TUBE',
  TUBES_IN_BUNDLE_BUNCH_TRUSS = 'TUBES_IN_BUNDLE_BUNCH_TRUSS',
  TUBE_COLLAPSIBLE = 'TUBE_COLLAPSIBLE',
  TUBE_WITH_NOZZLE = 'TUBE_WITH_NOZZLE',
  TUB_WITH_LID = 'TUB_WITH_LID',
  TUN = 'TUN',
  TYRE = 'TYRE',
  UNIT = 'UNIT',
  UNPACKD_MULTIPLE_UNITS = 'UNPACKD_MULTIPLE_UNITS',
  UNPACKED = 'UNPACKED',
  VACUUM_PACKED = 'VACUUM_PACKED',
  VAT = 'VAT',
  VEHICLE_OPERATING_CONDITION = 'VEHICLE_OPERATING_CONDITION',
  VIAL = 'VIAL',
  WICKER_BOTTLE = 'WICKER_BOTTLE'
}

export const PackageTypeNames = {
  [PackageType.AEROSOL]: 'Aerosol',
  [PackageType.AMMO_PACK]: 'Ammo Pack',
  [PackageType.AMPOULE]: 'Ampoule',
  [PackageType.AMPOULE_NON_PROTECTED]: 'Ampoule, non-protected',
  [PackageType.AMPOULE_PROTECTED]: 'Ampoule, protected',
  [PackageType.ATOMIZER]: 'Atomizer',
  [PackageType.ATTACHMENT]: 'Attachment',
  [PackageType.BAG]: 'Bag',
  [PackageType.BALE_COMPRESSED]: 'Bale, compressed',
  [PackageType.BALE_NON_COMPRESSED]: 'Bale, non-compressed',
  [PackageType.BALL]: 'Ball',
  [PackageType.BALLOON_NON_PROTECTED]: 'Balloon, non-protected',
  [PackageType.BALLOON_PROTECTED]: 'Balloon, protected',
  [PackageType.BANDING]: 'Banding',
  [PackageType.BAR]: 'Bar',
  [PackageType.BARGE]: 'Barge',
  [PackageType.BARREL]: 'Barrel',
  [PackageType.BARS_IN_BUNDLE_BUNCH_TRUSS]: 'Bars, in bundle/bunch/truss',
  [PackageType.BASIN]: 'Basin',
  [PackageType.BASKET]: 'Basket',
  [PackageType.BEAM]: 'Beam',
  [PackageType.BEER_CRATE]: 'Beer crate',
  [PackageType.BELT]: 'Belt',
  [PackageType.BELTING]: 'Belting',
  [PackageType.BIN]: 'Bin',
  [PackageType.BING_CHEST]: 'Bing Chest',
  [PackageType.BLOCK]: 'Block',
  [PackageType.BOARD]: 'Board',
  [PackageType.BOARD_IN_BUNDLE_BUNCH_TRUSS]: 'Board, inbundle/bunch/truss',
  [PackageType.BOBBIN]: 'Bobbin',
  [PackageType.BOLT]: 'Bolt',
  [PackageType.BOTTLE]: 'Bottle',
  [PackageType.BOTTLE_CRATE]: 'Bottle crate, bottle rack',
  [PackageType.BOTTLE_NON_PROTECTED_BULBOUS]: 'Bottle, non-protected,bulbous',
  [PackageType.BOTTLE_NON_PROTECTED_CYLINDRICAL]: 'Bottle, non-protected,cylindrical',
  [PackageType.BOTTLE_PROTECTED_BULBOUS]: 'Bottle, protected bulbous',
  [PackageType.BOTTLE_PROTECTED_CYLINDRICAL]: 'Bottle, protected cylindrical',
  [PackageType.BOX]: 'Box',
  [PackageType.BOX_WOODEN_NATURAL_WOOD_SIFTPROOF_WALL]: 'Box, wooden, natural wood, siftproof wall',
  [PackageType.BRACING]: 'Bracing',
  [PackageType.BUCKET]: 'Bucket',
  [PackageType.BULK]: 'Bulk',
  [PackageType.BULK_GAS_1031_MBAR_15C]: 'Bulk, gas (at 1031 mbar and 15°C)',
  [PackageType.BULK_LIQUEFIED_GAS_ABNORMAL_TEMPERATURE_PRESSURE]: 'Bulk, liquefied gas (at abnormal temperature/pressure)',
  [PackageType.BULK_LIQUID]: 'Bulk, liquid',
  [PackageType.BULK_SCRAP_METAL]: 'Bulk, scrap metal',
  [PackageType.BULK_SOLID_FINE_PARTICLES_POWDERS]: 'Bulk, solid, fine particles(“powders”)',
  [PackageType.BULK_SOLID_GRANULAR_PARTICLES_GRAINS]: 'Bulk, solid, granular particles(“grains”)',
  [PackageType.BULK_SOLID_LARGE_PARTICLES_NODULES]: 'Bulk, solid, large particles(“nodules”)',
  [PackageType.BUNCH]: 'Bunch',
  [PackageType.BUNDLE]: 'Bundle',
  [PackageType.BUTT]: 'Butt',
  [PackageType.CABINET]: 'Cabinet',
  [PackageType.CAGE]: 'Cage',
  [PackageType.CAN]: 'Can',
  [PackageType.CANISTER]: 'Canister',
  [PackageType.CANVAS]: 'Canvas',
  [PackageType.CAN_CASE]: 'Can Case',
  [PackageType.CAN_CYLINDRICAL]: 'Can, cylindrical',
  [PackageType.CAN_RECTANGULAR]: 'Can, rectangular',
  [PackageType.CAPSULE]: 'Capsule',
  [PackageType.CARBOY]: 'Carboy',
  [PackageType.CARBOY_NON_PROTECTED]: 'Carboy, non-protected',
  [PackageType.CARBOY_PROTECTED]: 'Carboy, protected',
  [PackageType.CARD]: 'Card',
  [PackageType.CARRIER]: 'Carrier',
  [PackageType.CARTON]: 'Carton',
  [PackageType.CARTRIDGE]: 'Cartridge',
  [PackageType.CART_FLATBED]: 'Cart, flatbed',
  [PackageType.CAR_LOAD_RAIL]: 'Car Load, Rail',
  [PackageType.CASE]: 'Case',
  [PackageType.CASK]: 'Cask',
  [PackageType.CHEESES]: 'Cheeses',
  [PackageType.CHEST]: 'Chest',
  [PackageType.CHURN]: 'Churn',
  [PackageType.CLAMSHELL]: 'Clamshell',
  [PackageType.COFFER]: 'Coffer',
  [PackageType.COFFIN]: 'Coffin',
  [PackageType.COIL]: 'Coil',
  [PackageType.CONE]: 'Cone',
  [PackageType.CONTAINER_GALLON]: 'Container, gallon',
  [PackageType.CONTAINER_INTERMEDIATE]: 'Container, Intermediate',
  [PackageType.CONTAINER_METAL]: 'Container, metal',
  [PackageType.CONTAINER_MIXED_TYPES]: 'Container, Mixed Types',
  [PackageType.CONTAINER_NOS]: 'Container, NOS',
  [PackageType.CONTAINER_OUTER]: 'Container, outer',
  [PackageType.CORE]: 'Core',
  [PackageType.CORNER_REINFORCEMENT]: 'Corner Reinforcement',
  [PackageType.COVER]: 'Cover',
  [PackageType.CRADLE]: 'Cradle',
  [PackageType.CRATE]: 'Crate',
  [PackageType.CRATE_METAL]: 'Crate, metal',
  [PackageType.CREEL]: 'Creel',
  [PackageType.CUBE]: 'Cube',
  [PackageType.CUP]: 'Cup',
  [PackageType.CYLINDER]: 'Cylinder',
  [PackageType.CYLINDER_SPIN]: 'Cylinder, Spin',
  [PackageType.DEMIJOHN_NON_PROTECTED]: 'Demijohn, non-protected',
  [PackageType.DEMIJOHN_PROTECTED]: 'Demijohn, protected',
  [PackageType.DISPENSER]: 'Dispenser',
  [PackageType.DRUM]: 'Drum',
  [PackageType.DRUM_ALUMINIUM_NON_REMOVABLE_HEAD]: 'Drum, aluminium, non-removable head',
  [PackageType.DRUM_PLASTIC_REMOVABLE_HEAD]: 'Drum, plastic, removable head',
  [PackageType.EDGE_PROTECTION]: 'Edge Protection',
  [PackageType.ENGINE_CONTAINER]: 'Engine Container',
  [PackageType.ENVELOPE]: 'Envelope',
  [PackageType.ENVELOPE_STEEL]: 'Envelope, steel',
  [PackageType.FILM_PACK]: 'Film pack',
  [PackageType.FIRKIN]: 'Firkin',
  [PackageType.FLASK]: 'Flask',
  [PackageType.FLEXIBAG]: 'Flexibag',
  [PackageType.FLEXITANK]: 'Flexitank',
  [PackageType.FLO_BIN]: 'Flo-Bin',
  [PackageType.FOODTAINER]: 'Foodtainer',
  [PackageType.FOOTLOCKER]: 'Footlocker',
  [PackageType.FRAME]: 'Frame',
  [PackageType.FRAMED_CRATE]: 'Framed crate',
  [PackageType.FRUIT_CRATE]: 'Fruit crate',
  [PackageType.GARMENT_ON_HANGER]: 'Garment On Hanger',
  [PackageType.GAS_BOTTLE]: 'Gas bottle',
  [PackageType.GIRDER]: 'Girder',
  [PackageType.GIRDERS_IN_BUNDLE_BUNCH_TRUSS]: 'Girders, inbundle/bunch/truss',
  [PackageType.HAMPER]: 'Hamper',
  [PackageType.HANGER]: 'Hanger',
  [PackageType.HEADS_OF_BEEF]: 'Heads of Beef',
  [PackageType.HOGSHEAD]: 'Hogshead',
  [PackageType.HOUSEHOLD_GOOD_CONTAINER_WOOD]: 'Household Good Container, Wood',
  [PackageType.INGOT]: 'Ingot',
  [PackageType.INGOTS_IN_BUNDLE_BUNCH_TRUSS]: 'Ingots, inbundle/bunch/truss',
  [PackageType.JAR]: 'Jar',
  [PackageType.JERRI_CAN_CYLINDRICAL]: 'Jerri can, cylindrical',
  [PackageType.JERRI_CAN_RECTANGULAR]: 'Jerri can, rectangular',
  [PackageType.JUG]: 'Jug',
  [PackageType.JUTE_BAG]: 'Jute bag',
  [PackageType.KEG]: 'Keg',
  [PackageType.KIT]: 'Kit',
  [PackageType.LIFTS]: 'Lifts',
  [PackageType.LIFTVAN]: 'Liftvan',
  [PackageType.LINERS]: 'Liners',
  [PackageType.LIP_TOP]: 'Lip/Top',
  [PackageType.LOG]: 'Log',
  [PackageType.LOGS_IN_BUNDLE_BUNCH_TRUSS]: 'Logs, in bundle/bunch/truss',
  [PackageType.LOOSE]: 'Loose',
  [PackageType.LOT]: 'Lot',
  [PackageType.LUG]: 'Lug',
  [PackageType.LUGGAGE]: 'Luggage',
  [PackageType.MAT]: 'Mat',
  [PackageType.MATCH_BOX]: 'Match box',
  [PackageType.MILITARY_AIRCRAFT_CONTAINER]: 'Military Aircraft Container',
  [PackageType.MILITARY_SEALIFT_COMMAND_VAN]: 'Military Sealift Command Van',
  [PackageType.MILITARY_VAN]: 'Military Van',
  [PackageType.MILK_CRATE]: 'Milk crate',
  [PackageType.MULTIPLY_BAG]: 'Multiply bag',
  [PackageType.MULTIWALL_SACK]: 'Multiwall sack',
  [PackageType.MULTIWALL_SECURED_WAREHOUSE_PALLET]: 'Multiwall Secured Warehouse Pallet',
  [PackageType.MULTI_ROLL_PACK]: 'Multi-Roll Pack',
  [PackageType.MUTUALLY_DEFINED]: 'Mutually defined',
  [PackageType.NAVY_CARGO_TRANSPORT_CONTAINER]: 'Navy Cargo Transport Container',
  [PackageType.NEST]: 'Nest',
  [PackageType.NET]: 'Net',
  [PackageType.NOIL]: 'Noil',
  [PackageType.NOT_AVAILABLE]: 'Not available',
  [PackageType.PACKED_NOS]: 'Packed, NOS',
  [PackageType.PACKET]: 'Packet',
  [PackageType.PACK_CARDBOARD_WITH_BOTTLE_GRIP_HOLES]: 'Pack, cardboard, with bottle grip holes',
  [PackageType.PAIL]: 'Pail',
  [PackageType.PALLET]: 'Pallet',
  [PackageType.PAN]: 'Pan',
  [PackageType.PARCEL]: 'Parcel',
  [PackageType.PARTITIONING]: 'Partitioning',
  [PackageType.PEN]: 'Pen',
  [PackageType.PIECE]: 'Piece',
  [PackageType.PIECES]: 'Pieces',
  [PackageType.PIPE]: 'Pipe',
  [PackageType.PIPELINE]: 'Pipeline',
  [PackageType.PIPES_IN_BUNDLE_BUNCH_TRUSS]: 'Pipes, in bundle/bunch/truss',
  [PackageType.PIPE_RACK]: 'Pipe Rack',
  [PackageType.PITCHER]: 'Pitcher',
  [PackageType.PLANK]: 'Plank',
  [PackageType.PLANKS_PIPES_IN_BUNDLE_BUNCH_TRUSS]: 'Planks, pipes, in bundle/bunch/truss',
  [PackageType.PLATE]: 'Plate',
  [PackageType.PLATES_IN_BUNDLE_BUNCH_TRUSS]: 'Plates, inbundle/bunch/truss',
  [PackageType.PLATFORM]: 'Platform',
  [PackageType.PLATFORM_UNSPECIFIED_WEIGHT_DIMENSIONS]: 'Platform, unspecified weight/dimensions',
  [PackageType.POT]: 'Pot',
  [PackageType.POUCH]: 'Pouch',
  [PackageType.PRIMARY_LIFT_CONTAINER]: 'Primary Lift Container',
  [PackageType.PRIVATE_VEHICLE]: 'Private Vehicle',
  [PackageType.PUNNET]: 'Punnet',
  [PackageType.QUARTER_OF_BEEF]: 'Quarter of Beef',
  [PackageType.RACK]: 'Rack',
  [PackageType.RACK_CLOTHING_HANGER]: 'Rack, clothing hanger',
  [PackageType.RACK_DOUBLE_LENGTH]: 'Rack, Double Length',
  [PackageType.RACK_HALF_STANDARD]: 'Rack, Half-Standard',
  [PackageType.RACK_KNOCK_DOWN]: 'Rack, Knock Down',
  [PackageType.RAIL_SEMICONDUCTOR]: 'Rail, Semiconductor',
  [PackageType.RECEPTACLE_FIBRE]: 'Receptacle, fibre',
  [PackageType.RECEPTACLE_GLASS]: 'Receptacle, glass',
  [PackageType.RECEPTACLE_METAL]: 'Receptacle, metal',
  [PackageType.RECEPTACLE_PAPER]: 'Receptacle, paper',
  [PackageType.RECEPTACLE_PLASTIC]: 'Receptacle, plastic',
  [PackageType.RECEPTACLE_PLASTIC_WRAPPED]: 'Receptacle, plastic wrapped',
  [PackageType.RECEPTACLE_WOODEN]: 'Receptacle, wooden',
  [PackageType.REDNET]: 'Rednet',
  [PackageType.REEL]: 'Reel',
  [PackageType.REEL_FORWARD]: 'Reel, Forward',
  [PackageType.REEL_REVERSE]: 'Reel, Reverse',
  [PackageType.REINFORCEMENT]: 'Reinforcement',
  [PackageType.RING]: 'Ring',
  [PackageType.ROD]: 'Rod',
  [PackageType.RODS_IN_BUNDLE_BUNCH_TRUSS]: 'Rods, inbundle/bunch/truss',
  [PackageType.ROLL]: 'Roll',
  [PackageType.SACHET]: 'Sachet',
  [PackageType.SACK]: 'Sack',
  [PackageType.SEA_CHEST]: 'Sea-chest',
  [PackageType.SEA_VAN]: 'Sea Van',
  [PackageType.SEPARATOR_DIVIDER]: 'Separator / Divider',
  [PackageType.SHALLOW_CRATE]: 'Shallowcrate',
  [PackageType.SHEET]: 'Sheet',
  [PackageType.SHEETMETAL]: 'Sheetmetal',
  [PackageType.SHEETS_IN_BUNDLE_BUNCH_TRUSS]: 'Sheets, inbundle/bunch/truss',
  [PackageType.SHEET_PLASTIC_WRAPPING]: 'Sheet, plastic wrapping',
  [PackageType.SHOOK]: 'Shook',
  [PackageType.SHRINKWRAPPED]: 'Shrinkwrapped',
  [PackageType.SIDE_OF_BEEF]: 'Side of Beef',
  [PackageType.SKELETON_CASE]: 'Skeletoncase',
  [PackageType.SKID]: 'Skid',
  [PackageType.SKID_DOUBLE_LENGTH]: 'Skid, Double Length',
  [PackageType.SKID_ELEVATING_OF_LIFT_TRUCK]: 'Skid, Elevating of Lift Truck',
  [PackageType.SLAB]: 'Slab',
  [PackageType.SLEEVE]: 'Sleeve',
  [PackageType.SLIPSHEET]: 'Slipsheet',
  [PackageType.SPINDLE]: 'Spindle',
  [PackageType.SPOOL]: 'Spool',
  [PackageType.STRETCH_WRAPPED]: 'Stretch Wrapped',
  [PackageType.SUITCASE]: 'Suitcase',
  [PackageType.TABLET]: 'Tablet',
  [PackageType.TANK]: 'Tank',
  [PackageType.TANK_CAR]: 'Tank Car',
  [PackageType.TANK_CONTAINER_GENERIC]: 'Tank container, generic',
  [PackageType.TANK_CYLINDRICAL]: 'Tank, cylindrical',
  [PackageType.TANK_RECTANGULAR]: 'Tank, rectangular',
  [PackageType.TANK_TRUCK]: 'Tank Truck',
  [PackageType.TEA_CHEST]: 'Tea-chest',
  [PackageType.TIERCE]: 'Tierce',
  [PackageType.TIN]: 'Tin',
  [PackageType.TOTE_BIN]: 'Tote Bin',
  [PackageType.TOTE_BIN_DOUBLE_LENGTH]: 'Tote Bin, Double Length',
  [PackageType.TOTE_BIN_HALF_STANDARD]: 'Tote Bin, Half-Standard',
  [PackageType.TOTE_BIN_KNOCK_DOWN]: 'Tote Bin, Knock Down',
  [PackageType.TOTE_CAN]: 'Tote Can',
  [PackageType.TRAILER_CONTAINER_LOAD_RAIL]: 'Trailer / Container Load (Rail)',
  [PackageType.TRAY]: 'Tray',
  [PackageType.TRUCK]: 'Truck',
  [PackageType.TRUNK]: 'Trunk',
  [PackageType.TRUSS]: 'Truss',
  [PackageType.TUB]: 'Tub',
  [PackageType.TUBE]: 'Tube',
  [PackageType.TUBES_IN_BUNDLE_BUNCH_TRUSS]: 'Tubes,in bundle/bunch/truss',
  [PackageType.TUBE_COLLAPSIBLE]: 'Tube, collapsible',
  [PackageType.TUBE_WITH_NOZZLE]: 'Tube, with nozzle',
  [PackageType.TUB_WITH_LID]: 'Tub, with lid',
  [PackageType.TUN]: 'Tun',
  [PackageType.TYRE]: 'Tyre',
  [PackageType.UNIT]: 'Unit',
  [PackageType.UNPACKD_MULTIPLE_UNITS]: 'Unpackd multiple units',
  [PackageType.UNPACKED]: 'Unpacked or unpackaged',
  [PackageType.VACUUM_PACKED]: 'Vacuum-packed',
  [PackageType.VAT]: 'Vat',
  [PackageType.VEHICLE_OPERATING_CONDITION]: 'Vehicle, Operating Condition',
  [PackageType.VIAL]: 'Vial',
  [PackageType.WICKER_BOTTLE]: 'Wicker bottle',
};

export const PackageTypesCodes = {
  [PackageType.AEROSOL]: 'AE',
  [PackageType.AMMO_PACK]: 'AMM',
  [PackageType.AMPOULE]: 'AMP',
  [PackageType.AMPOULE_NON_PROTECTED]: 'AM',
  [PackageType.AMPOULE_PROTECTED]: 'AP',
  [PackageType.ATOMIZER]: 'AT',
  [PackageType.ATTACHMENT]: 'ATH',
  [PackageType.BAG]: 'BG',
  [PackageType.BALE_COMPRESSED]: 'BL',
  [PackageType.BALE_NON_COMPRESSED]: 'BN',
  [PackageType.BALL]: 'AL',
  [PackageType.BALLOON_NON_PROTECTED]: 'BF',
  [PackageType.BALLOON_PROTECTED]: 'BP',
  [PackageType.BANDING]: 'BDG',
  [PackageType.BAR]: 'BR',
  [PackageType.BARGE]: 'BRG',
  [PackageType.BARREL]: 'BA',
  [PackageType.BARS_IN_BUNDLE_BUNCH_TRUSS]: 'BZ',
  [PackageType.BASIN]: 'BM',
  [PackageType.BASKET]: 'BK',
  [PackageType.BEAM]: 'BEM',
  [PackageType.BEER_CRATE]: 'CB',
  [PackageType.BELT]: 'B4',
  [PackageType.BELTING]: 'BLT',
  [PackageType.BIN]: 'BI',
  [PackageType.BING_CHEST]: 'BIC',
  [PackageType.BLOCK]: 'OK',
  [PackageType.BOARD]: 'BD',
  [PackageType.BOARD_IN_BUNDLE_BUNCH_TRUSS]: 'BY',
  [PackageType.BOBBIN]: 'BB',
  [PackageType.BOLT]: 'BT',
  [PackageType.BOTTLE]: 'BOT',
  [PackageType.BOTTLE_CRATE]: 'BC',
  [PackageType.BOTTLE_NON_PROTECTED_BULBOUS]: 'BS',
  [PackageType.BOTTLE_NON_PROTECTED_CYLINDRICAL]: 'BO',
  [PackageType.BOTTLE_PROTECTED_BULBOUS]: 'BV',
  [PackageType.BOTTLE_PROTECTED_CYLINDRICAL]: 'BQ',
  [PackageType.BOX]: 'BX',
  [PackageType.BOX_WOODEN_NATURAL_WOOD_SIFTPROOF_WALL]: 'QQ',
  [PackageType.BRACING]: 'BRC',
  [PackageType.BUCKET]: 'BJ',
  [PackageType.BULK]: 'BLK',
  [PackageType.BULK_GAS_1031_MBAR_15C]: 'VG',
  [PackageType.BULK_LIQUEFIED_GAS_ABNORMAL_TEMPERATURE_PRESSURE]: 'VQ',
  [PackageType.BULK_LIQUID]: 'VL',
  [PackageType.BULK_SCRAP_METAL]: 'VS',
  [PackageType.BULK_SOLID_FINE_PARTICLES_POWDERS]: 'VY',
  [PackageType.BULK_SOLID_GRANULAR_PARTICLES_GRAINS]: 'VR',
  [PackageType.BULK_SOLID_LARGE_PARTICLES_NODULES]: 'VO',
  [PackageType.BUNCH]: 'BH',
  [PackageType.BUNDLE]: 'BE',
  [PackageType.BUTT]: 'BU',
  [PackageType.CABINET]: 'CAB',
  [PackageType.CAGE]: 'CG',
  [PackageType.CAN]: 'CAN',
  [PackageType.CANISTER]: 'CI',
  [PackageType.CANVAS]: 'CZ',
  [PackageType.CAN_CASE]: 'CCS',
  [PackageType.CAN_CYLINDRICAL]: 'CX',
  [PackageType.CAN_RECTANGULAR]: 'CA',
  [PackageType.CAPSULE]: 'AV',
  [PackageType.CARBOY]: 'CBY',
  [PackageType.CARBOY_NON_PROTECTED]: 'CO',
  [PackageType.CARBOY_PROTECTED]: 'CP',
  [PackageType.CARD]: 'CM',
  [PackageType.CARRIER]: 'CAR',
  [PackageType.CARTON]: 'CT',
  [PackageType.CARTRIDGE]: 'CQ',
  [PackageType.CART_FLATBED]: 'FW',
  [PackageType.CAR_LOAD_RAIL]: 'CLD',
  [PackageType.CASE]: 'CS',
  [PackageType.CASK]: 'CK',
  [PackageType.CHEESES]: 'CHE',
  [PackageType.CHEST]: 'CH',
  [PackageType.CHURN]: 'CC',
  [PackageType.CLAMSHELL]: 'AI',
  [PackageType.COFFER]: 'CF',
  [PackageType.COFFIN]: 'CJ',
  [PackageType.COIL]: 'CL',
  [PackageType.CONE]: 'AJ',
  [PackageType.CONTAINER_GALLON]: 'GL',
  [PackageType.CONTAINER_INTERMEDIATE]: 'INT',
  [PackageType.CONTAINER_METAL]: 'ME',
  [PackageType.CONTAINER_MIXED_TYPES]: 'MIX',
  [PackageType.CONTAINER_NOS]: 'CN',
  [PackageType.CONTAINER_OUTER]: 'OU',
  [PackageType.CORE]: 'COR',
  [PackageType.CORNER_REINFORCEMENT]: 'CRF',
  [PackageType.COVER]: 'CV',
  [PackageType.CRADLE]: 'CRD',
  [PackageType.CRATE]: 'CR',
  [PackageType.CRATE_METAL]: 'MA',
  [PackageType.CREEL]: 'CE',
  [PackageType.CUBE]: 'CUB',
  [PackageType.CUP]: 'CU',
  [PackageType.CYLINDER]: 'CY',
  [PackageType.CYLINDER_SPIN]: 'SPI',
  [PackageType.DEMIJOHN_NON_PROTECTED]: 'DJ',
  [PackageType.DEMIJOHN_PROTECTED]: 'DP',
  [PackageType.DISPENSER]: 'DN',
  [PackageType.DRUM]: 'DR',
  [PackageType.DRUM_ALUMINIUM_NON_REMOVABLE_HEAD]: 'QC',
  [PackageType.DRUM_PLASTIC_REMOVABLE_HEAD]: 'QG',
  [PackageType.EDGE_PROTECTION]: 'EPR',
  [PackageType.ENGINE_CONTAINER]: 'CNE',
  [PackageType.ENVELOPE]: 'EN',
  [PackageType.ENVELOPE_STEEL]: 'SV',
  [PackageType.FILM_PACK]: 'FP',
  [PackageType.FIRKIN]: 'FI',
  [PackageType.FLASK]: 'FL',
  [PackageType.FLEXIBAG]: 'FB',
  [PackageType.FLEXITANK]: 'FE',
  [PackageType.FLO_BIN]: 'FLO',
  [PackageType.FOODTAINER]: 'FT',
  [PackageType.FOOTLOCKER]: 'FO',
  [PackageType.FRAME]: 'FR',
  [PackageType.FRAMED_CRATE]: 'FD',
  [PackageType.FRUIT_CRATE]: 'FC',
  [PackageType.GARMENT_ON_HANGER]: 'GOH',
  [PackageType.GAS_BOTTLE]: 'GB',
  [PackageType.GIRDER]: 'GI',
  [PackageType.GIRDERS_IN_BUNDLE_BUNCH_TRUSS]: 'GZ',
  [PackageType.HAMPER]: 'HR',
  [PackageType.HANGER]: 'HN',
  [PackageType.HEADS_OF_BEEF]: 'HED',
  [PackageType.HOGSHEAD]: 'HG',
  [PackageType.HOUSEHOLD_GOOD_CONTAINER_WOOD]: 'CNA',
  [PackageType.INGOT]: 'IN',
  [PackageType.INGOTS_IN_BUNDLE_BUNCH_TRUSS]: 'IZ',
  [PackageType.JAR]: 'JR',
  [PackageType.JERRI_CAN_CYLINDRICAL]: 'JY',
  [PackageType.JERRI_CAN_RECTANGULAR]: 'JC',
  [PackageType.JUG]: 'JG',
  [PackageType.JUTE_BAG]: 'JT',
  [PackageType.KEG]: 'KG',
  [PackageType.KIT]: 'KI',
  [PackageType.LIFTS]: 'LIF',
  [PackageType.LIFTVAN]: 'LV',
  [PackageType.LINERS]: 'LNR',
  [PackageType.LIP_TOP]: 'LID',
  [PackageType.LOG]: 'LG',
  [PackageType.LOGS_IN_BUNDLE_BUNCH_TRUSS]: 'LZ',
  [PackageType.LOOSE]: 'LSE',
  [PackageType.LOT]: 'LT',
  [PackageType.LUG]: 'LU',
  [PackageType.LUGGAGE]: 'LE',
  [PackageType.MAT]: 'MT',
  [PackageType.MATCH_BOX]: 'MX',
  [PackageType.MILITARY_AIRCRAFT_CONTAINER]: 'CNB',
  [PackageType.MILITARY_SEALIFT_COMMAND_VAN]: 'MSV',
  [PackageType.MILITARY_VAN]: 'MLV',
  [PackageType.MILK_CRATE]: 'MC',
  [PackageType.MULTIPLY_BAG]: 'MB',
  [PackageType.MULTIWALL_SACK]: 'MS',
  [PackageType.MULTIWALL_SECURED_WAREHOUSE_PALLET]: 'CNF',
  [PackageType.MULTI_ROLL_PACK]: 'MRP',
  [PackageType.MUTUALLY_DEFINED]: 'ZZ',
  [PackageType.NAVY_CARGO_TRANSPORT_CONTAINER]: 'CNC',
  [PackageType.NEST]: 'NS',
  [PackageType.NET]: 'NT',
  [PackageType.NOIL]: 'NOL',
  [PackageType.NOT_AVAILABLE]: 'NA',
  [PackageType.PACKED_NOS]: 'PCK',
  [PackageType.PACKET]: 'PA',
  [PackageType.PACK_CARDBOARD_WITH_BOTTLE_GRIP_HOLES]: 'IK',
  [PackageType.PAIL]: 'PL',
  [PackageType.PALLET]: 'PK',
  [PackageType.PAN]: 'P2',
  [PackageType.PARCEL]: 'PC',
  [PackageType.PARTITIONING]: 'PRT',
  [PackageType.PEN]: 'PF',
  [PackageType.PIECE]: 'PP',
  [PackageType.PIECES]: 'PCS',
  [PackageType.PIPE]: 'PI',
  [PackageType.PIPELINE]: 'PLN',
  [PackageType.PIPES_IN_BUNDLE_BUNCH_TRUSS]: 'PV',
  [PackageType.PIPE_RACK]: 'PRK',
  [PackageType.PITCHER]: 'PH',
  [PackageType.PLANK]: 'PN',
  [PackageType.PLANKS_PIPES_IN_BUNDLE_BUNCH_TRUSS]: 'PZ',
  [PackageType.PLATE]: 'PG',
  [PackageType.PLATES_IN_BUNDLE_BUNCH_TRUSS]: 'PY',
  [PackageType.PLATFORM]: 'PLF',
  [PackageType.PLATFORM_UNSPECIFIED_WEIGHT_DIMENSIONS]: 'OF',
  [PackageType.POT]: 'PT',
  [PackageType.POUCH]: 'PO',
  [PackageType.PRIMARY_LIFT_CONTAINER]: 'PLC',
  [PackageType.PRIVATE_VEHICLE]: 'POV',
  [PackageType.PUNNET]: 'PJ',
  [PackageType.QUARTER_OF_BEEF]: 'QTR',
  [PackageType.RACK]: 'RK',
  [PackageType.RACK_CLOTHING_HANGER]: 'RJ',
  [PackageType.RACK_DOUBLE_LENGTH]: 'DRK',
  [PackageType.RACK_HALF_STANDARD]: 'HRK',
  [PackageType.RACK_KNOCK_DOWN]: 'KRK',
  [PackageType.RAIL_SEMICONDUCTOR]: 'RAL',
  [PackageType.RECEPTACLE_FIBRE]: 'AB',
  [PackageType.RECEPTACLE_GLASS]: 'GR',
  [PackageType.RECEPTACLE_METAL]: 'MR',
  [PackageType.RECEPTACLE_PAPER]: 'AC',
  [PackageType.RECEPTACLE_PLASTIC]: 'PR',
  [PackageType.RECEPTACLE_PLASTIC_WRAPPED]: 'MW',
  [PackageType.RECEPTACLE_WOODEN]: 'AD',
  [PackageType.REDNET]: 'RT',
  [PackageType.REEL]: 'RL',
  [PackageType.REEL_FORWARD]: 'FWR',
  [PackageType.REEL_REVERSE]: 'RVR',
  [PackageType.REINFORCEMENT]: 'RFT',
  [PackageType.RING]: 'RG',
  [PackageType.ROD]: 'RD',
  [PackageType.RODS_IN_BUNDLE_BUNCH_TRUSS]: 'RZ',
  [PackageType.ROLL]: 'RO',
  [PackageType.SACHET]: 'SH',
  [PackageType.SACK]: 'SA',
  [PackageType.SEA_CHEST]: 'SE',
  [PackageType.SEA_VAN]: 'SVN',
  [PackageType.SEPARATOR_DIVIDER]: 'SPR',
  [PackageType.SHALLOW_CRATE]: 'SC',
  [PackageType.SHEET]: 'ST',
  [PackageType.SHEETMETAL]: 'SM',
  [PackageType.SHEETS_IN_BUNDLE_BUNCH_TRUSS]: 'SZ',
  [PackageType.SHEET_PLASTIC_WRAPPING]: 'SP',
  [PackageType.SHOOK]: 'SHK',
  [PackageType.SHRINKWRAPPED]: 'SW',
  [PackageType.SIDE_OF_BEEF]: 'SID',
  [PackageType.SKELETON_CASE]: 'SK',
  [PackageType.SKID]: 'SI',
  [PackageType.SKID_DOUBLE_LENGTH]: 'DSK',
  [PackageType.SKID_ELEVATING_OF_LIFT_TRUCK]: 'SKE',
  [PackageType.SLAB]: 'SB',
  [PackageType.SLEEVE]: 'SY',
  [PackageType.SLIPSHEET]: 'SL',
  [PackageType.SPINDLE]: 'SD',
  [PackageType.SPOOL]: 'SO',
  [PackageType.STRETCH_WRAPPED]: 'STW',
  [PackageType.SUITCASE]: 'SU',
  [PackageType.TABLET]: 'T1',
  [PackageType.TANK]: 'TNK',
  [PackageType.TANK_CAR]: 'TKR',
  [PackageType.TANK_CONTAINER_GENERIC]: 'TG',
  [PackageType.TANK_CYLINDRICAL]: 'TY',
  [PackageType.TANK_RECTANGULAR]: 'TK',
  [PackageType.TANK_TRUCK]: 'TKT',
  [PackageType.TEA_CHEST]: 'TC',
  [PackageType.TIERCE]: 'TI',
  [PackageType.TIN]: 'TN',
  [PackageType.TOTE_BIN]: 'TBN',
  [PackageType.TOTE_BIN_DOUBLE_LENGTH]: 'DTB',
  [PackageType.TOTE_BIN_HALF_STANDARD]: 'HTB',
  [PackageType.TOTE_BIN_KNOCK_DOWN]: 'KTB',
  [PackageType.TOTE_CAN]: 'TTC',
  [PackageType.TRAILER_CONTAINER_LOAD_RAIL]: 'TLD',
  [PackageType.TRAY]: 'DV',
  [PackageType.TRUCK]: 'TRU',
  [PackageType.TRUNK]: 'TR',
  [PackageType.TRUSS]: 'TS',
  [PackageType.TUB]: 'TB',
  [PackageType.TUBE]: 'TU',
  [PackageType.TUBES_IN_BUNDLE_BUNCH_TRUSS]: 'TZ',
  [PackageType.TUBE_COLLAPSIBLE]: 'TD',
  [PackageType.TUBE_WITH_NOZZLE]: 'TV',
  [PackageType.TUB_WITH_LID]: 'TL',
  [PackageType.TUN]: 'TO',
  [PackageType.TYRE]: 'TE',
  [PackageType.UNIT]: 'UN',
  [PackageType.UNPACKD_MULTIPLE_UNITS]: 'NG',
  [PackageType.UNPACKED]: 'NE',
  [PackageType.VACUUM_PACKED]: 'VP',
  [PackageType.VAT]: 'VA',
  [PackageType.VEHICLE_OPERATING_CONDITION]: 'VOC',
  [PackageType.VIAL]: 'VI',
  [PackageType.WICKER_BOTTLE]: 'WB',
};

type ReversePackageTypesCodesType = {
  [code in (typeof PackageTypesCodes)[keyof typeof PackageTypesCodes]]: keyof typeof PackageTypesCodes;
};

export const ReversePackageTypesCodes = Object.keys(PackageTypesCodes)
  .reduce((result, key) => {
    result[PackageTypesCodes[key as PackageType]] = key as keyof typeof PackageTypesCodes;
    return result;
  }, {} as ReversePackageTypesCodesType);
