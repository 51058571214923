import React, {
  useCallback, useRef, useState, FC,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import {
  Button, CollapseButton, EditEllipsisComponent, Menu, ServicesDivider,
} from 'app-wrapper/view/components';
import { normalizationCost } from 'app-wrapper/utils';
import { REQUEST_STATUS } from 'app-wrapper/constants';

import { BookingComponent } from 'shipment-operations/view/pages';

import {
  FreightQuotaContentDTM,
} from 'monetary/models/dtm';
import { getCarrierSCACNamesRFQ } from 'monetary/constants';

import { DraftOrganizationPopover } from 'monetary/view/components/RFQResponse/RFQItem/DraftOrganizationPopover';
import { PrintRFQ } from 'monetary/view/components/RFQResponse/RFQItem/PrintRFQ';
import {
  CardContainerStyled, CardDecoration,
  CardHeaderContainerIcon,
} from 'monetary/view/pages/QuotaRequests/components/Quotas/components/Card/Card.styled';
import { GapHorizontalContainerStyled, GapVerticalContainerStyled } from 'app-wrapper/view/guideline';
import {
  Dropdown,
  MenuItem,
} from 'user-management/view/pages/OrganizationDocuments/components/DocumentSectionPrint/components/ActionMenu/ActionMenu.styled';

export interface IRFQItemComponentProps {
  data?: FreightQuotaContentDTM
  onPrint?: (id: number) => void
  shipmentId?: number
  indexSchedule: number
  onClearCustomerInfo?: () => void
  onBook: (quotaId: number, quotaScheduleId: number) => void
  bookingStatus?: string
  fileNamePrint?: string
  totalCost?: string
  isShowContractName?: boolean
  isOrganizationInDraft?: boolean
  isOrganizationInWaitingForApproval?: boolean
  hideBook?: boolean
  mustOrganizationAcceptTermsAndCondition?: boolean
}

const oldTitle = document.title;

export const CardComponent: FC<IRFQItemComponentProps> = (props) => {
  const {
    data,
    onPrint,
    shipmentId,
    onClearCustomerInfo,
    onBook,
    indexSchedule,
    bookingStatus,
    fileNamePrint,
    totalCost,
    isShowContractName,
    hideBook,
    isOrganizationInDraft,
    isOrganizationInWaitingForApproval,
    mustOrganizationAcceptTermsAndCondition,
  } = props;

  const { t } = useTranslation();
  const [isDetail, setIsDetail] = useState(false);

  const detailOnHandler = useCallback(
    () => {
      setIsDetail((prev) => !prev);
    },
    [],
  );

  const bookOnHandler = useCallback(
    () => {
      onBook(data?.id || 0, Number(data?.schedules?.[indexSchedule]?.id) || 0);
    },
    [data, shipmentId, indexSchedule, onBook],
  );

  // const isCutoffGold = useMemo<boolean>(() => {
  //   const cutoffDoc = moment(data?.schedules?.[indexSchedule]?.documentCutOff);
  //   const dateNow = moment(Date());
  //
  //   return cutoffDoc.diff(dateNow, 'day') <= 5;
  // }, [indexSchedule, data]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => componentRef.current,
    documentTitle: fileNamePrint,
    onAfterPrint: () => {
      if (onClearCustomerInfo) {
        onClearCustomerInfo();
      }

      // support chrome
      document.title = oldTitle;
    },
  });

  const onClickPrint = useCallback(async () => {
    if (onPrint) {
      await onPrint(data?.id || 0);
      // support chrome
      document.title = fileNamePrint || oldTitle;
      handlePrint();
    }
  }, [fileNamePrint, data?.id]);

  const disableBook = bookingStatus === REQUEST_STATUS.pending || data?.validPeriod?.getIsExpired();

  const nameSCAC: string = data?.contracts?.[0]?.scac || '';
  const nameTitle: string = getCarrierSCACNamesRFQ(nameSCAC);

  const pathPoints = [
    data?.getOriginLocationToOneString(),
    data?.isFirstNotSamePOLLeg() && (<span>via {data?.getFirstNotSamePOLLeg()}</span>),
    data?.isFirstPOLLeg() && (<span>via {data?.getFirstPOLLeg()}</span>),
    data?.isSecondPOLLeg() && (<span>via {data?.getSecondPOLLeg()}</span>),
    data?.isSecondNotSamePOLLeg() && (<span>via {data?.getSecondNotSamePOLLeg()}</span>),
    data?.getDestinationLocationToOneString(),
  ];

  return (
    <CardContainerStyled>
      <CardDecoration />
      <BookingComponent />
      <GapVerticalContainerStyled>
        <GapHorizontalContainerStyled
          width="100%"
          height="46px"
          gap="16px"
          alignContent="center"
        >
          <CardHeaderContainerIcon />
          <GapVerticalContainerStyled width="100%">
            <span>
              {pathPoints.filter((p) => !!p).join(' - ')}
            </span>
            <span>{nameTitle} / {isShowContractName ? data?.contracts?.[0].number || '' : ''}</span>
          </GapVerticalContainerStyled>

        </GapHorizontalContainerStyled>
        <ServicesDivider noMargin />

        <GapHorizontalContainerStyled
          height="46px"
          justifyContent="space-between"
          alignItems="center"
        >
          <GapHorizontalContainerStyled
            width="auto"
            gap="16px"
          >
            <div>$ {normalizationCost(totalCost || 0, { toFixed: 2, thousandthSeparatorComma: true })}</div>
            <div>{`${data?.validPeriod?.getIsExpired() ? t('Expired') : t('Expires on')}`}: {`${data?.validPeriod?.getFormatDateDMMMTo() || ''}`}</div>
          </GapHorizontalContainerStyled>
          <GapHorizontalContainerStyled
            width="auto"
            gap="16px"
          >
            <div style={{ display: 'none' }}>
              <PrintRFQ
                ref={componentRef}
              />
            </div>
            <Dropdown
              placement="bottomRight"
              overlay={(
                <Menu>
                  <MenuItem
                    disabled={disableBook}
                    onClick={onClickPrint}
                  >
                    {t('Print')}
                  </MenuItem>
                </Menu>
              )}
            >
              <Button
                type="default"
                icon={<EditEllipsisComponent />}
              />
            </Dropdown>
            {!hideBook && (
              <>
                {isOrganizationInDraft || isOrganizationInWaitingForApproval || mustOrganizationAcceptTermsAndCondition ? (
                  <DraftOrganizationPopover>
                    <Button
                      style={{ opacity: 0.2, cursor: 'initial' }}
                    >
                      {t('Book')}
                    </Button>
                  </DraftOrganizationPopover>
                ) : (
                  <Button
                    disabled={disableBook}
                    onClick={bookOnHandler}
                  >
                    {t('Book')}
                  </Button>
                )}
              </>
            )}
            <CollapseButton show={isDetail} onClick={detailOnHandler} />
          </GapHorizontalContainerStyled>
        </GapHorizontalContainerStyled>
      </GapVerticalContainerStyled>
    </CardContainerStyled>
  );
};
