import { BaseAddressDTM } from 'app-wrapper/models/dtm/BaseAddress.dtm';
import {
  IsBoolean,
  IsOptional,
  ValidateNested,
} from 'class-validator';
import { Type } from 'class-transformer';
import { CompanyAddressDTM } from 'shipment-operations/models/dtm/Company.dtm';

import { ContactDTM, IContactDTM } from './Contact.dtm';

export interface IAddressDTM {
  id: number | null
  country: string
  state?: string
  city: string
  address1: string
  address2?: string
  postalCode: string
  closestPort?: string
  contacts?: IContactDTM[]
  primary?: boolean
}

export class AddressDTM extends BaseAddressDTM<IAddressDTM> {
  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => ContactDTM)
  contacts?: ContactDTM[];

  @IsOptional()
  @IsBoolean()
  primary?: boolean;

  static areAddressesEqual = (address: AddressDTM, companyAddress: CompanyAddressDTM): boolean => address.address1 === companyAddress.address1
    && (address.address2 || '') === (companyAddress.address2 || '')
    && (address.country || '') === (companyAddress.country || '')
    && (address.city || '') === (companyAddress.city || '')
    && (address.postalCode || '') === (companyAddress.postalCode || '')
    && (address.state || '') === (companyAddress.state || '');
}
