import React, {
  FC,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Dropdown from 'antd/es/dropdown';
import Menu from 'antd/es/menu';
import DownOutlined from '@ant-design/icons/DownOutlined';
import UpOutlined from '@ant-design/icons/UpOutlined';
import Popover from 'antd/es/popover';

import { hasReadAccess } from 'app-wrapper/utils';
import { Button } from 'app-wrapper/view/components';
import { PermissionAttributePolicy, ShipmentBookingStatusEnum } from 'shipment-operations/constants';
import { ShipmentPreviewDTM } from 'shipment-operations/models/dtm';
import { SubPageHeaderContainer, SubPageHeaderTitle } from 'app-wrapper/view/guideline';

import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import { PopoverContainer } from './ShipmentOverviewTitle.styled';

interface IShipmentOverviewTitleComponentProps {
  isLoading: boolean
  // bookShipment: (shipmentId: string) => void
  cancelShipment: () => void
  // manualBookShipment: () => void
  rollShipment: () => void
  resubmitShipment: (shipmentId: string) => void
  isRollShipmentAvailable: boolean
  isWaitingAcceptFromCustomer: boolean;
  shipment?: ShipmentPreviewDTM
  shipmentRollAvailability?: PermissionAttributePolicy
  bookingAmendAvailability?: PermissionAttributePolicy
  cancelShipmentAvailability?: PermissionAttributePolicy
  manualConfirmationAvailability?: PermissionAttributePolicy
  resubmitShipmentAvailability?: PermissionAttributePolicy
  updateScheduleAvailability?: PermissionAttributePolicy
  disable: boolean
  openChangeRequest: () => void
  isImport: boolean
}

export const ShipmentOverviewTitleComponent: FC<IShipmentOverviewTitleComponentProps> = ({
  // bookShipment,
  isLoading,
  cancelShipment,
  rollShipment,
  resubmitShipment,
  // manualBookShipment,
  isRollShipmentAvailable,
  shipment,
  shipmentRollAvailability,
  bookingAmendAvailability,
  cancelShipmentAvailability,
  manualConfirmationAvailability,
  resubmitShipmentAvailability,
  isWaitingAcceptFromCustomer,
  disable,
  openChangeRequest,
  updateScheduleAvailability,
  isImport,
}) => {
  const { t } = useTranslation();
  const [menuVisible, setMenuVisible] = useState(false);
  const { shipmentId = '0' } = useParams();

  // const handleBookShipment = useCallback(() => {
  //   bookShipment(shipmentId);
  // }, []);

  const handleCancelShipment = useCallback(() => {
    cancelShipment();
  }, [cancelShipment]);

  const handleResubmit = useCallback(() => {
    resubmitShipment(shipmentId);
  }, [shipmentId, resubmitShipment]);

  const bookingStatus = useMemo(() => shipment?.bookingStatus || '', [shipment]);

  const isAbleToResubmit = useMemo(() => resubmitShipmentAvailability === PermissionAttributePolicy.WRITE && bookingStatus === ShipmentBookingStatusEnum.BOOKING_SUBMITTED, [bookingStatus, resubmitShipmentAvailability]);
  const isAbleToRoll = useMemo(() => shipmentRollAvailability === PermissionAttributePolicy.WRITE, [shipmentRollAvailability]);
  const isAbleToAmend = useMemo(() => bookingAmendAvailability === PermissionAttributePolicy.WRITE, [bookingAmendAvailability]);
  const isAbleToCancel = useMemo(() => cancelShipmentAvailability === PermissionAttributePolicy.WRITE, [cancelShipmentAvailability]);
  const isAbleToManualConfirm = useMemo(() => manualConfirmationAvailability === PermissionAttributePolicy.WRITE, [manualConfirmationAvailability]);
  const hasSomeActions = useMemo(() => isAbleToResubmit || isAbleToRoll || isAbleToAmend || isAbleToCancel || isAbleToManualConfirm, [isAbleToResubmit, isAbleToRoll, isAbleToAmend, isAbleToCancel, isAbleToManualConfirm]);

  if (isLoading) {
    return <SkeletonSection height="34px" />;
  }

  return (
    <SubPageHeaderContainer>
      <SubPageHeaderTitle>
        {t('Shipment Overview')}
      </SubPageHeaderTitle>

      {!isWaitingAcceptFromCustomer && hasSomeActions ? (
        <Dropdown
          arrow
          trigger={['click']}
          overlay={(
            <Menu>
              {/* {isAbleToAmend ? ( */}
              {/*  <Menu.Item */}
              {/*    disabled={bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED} */}
              {/*    key="book" */}
              {/*    onClick={handleBookShipment} */}
              {/*  > */}
              {/*    {bookingStatus !== ShipmentBookingStatusEnum.BOOKING_PREPARATION ? t('Amend booking') : t('Book shipment')} */}
              {/*  </Menu.Item> */}
              {/* ) : null} */}

              {isAbleToResubmit ? (
                <Menu.Item
                  key="resubmit-booking"
                  onClick={handleResubmit}
                >
                  {t('Resubmit Booking')}
                </Menu.Item>
              ) : null}

              {/* {isAbleToManualConfirm && (bookingStatus === ShipmentBookingStatusEnum.BOOKING_SUBMITTED || bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED) ? ( */}
              {/*  <Menu.Item */}
              {/*    key="manual-booking" */}
              {/*    onClick={manualBookShipment} */}
              {/*  > */}
              {/*    {t('Confirm booking')} */}
              {/*  </Menu.Item> */}
              {/* ) : null} */}

              {isAbleToRoll && isRollShipmentAvailable ? (
                <Menu.Item
                  data-testid="shipment-roll-action-testid"
                  key="roll"
                  onClick={rollShipment}
                  disabled={disable}
                >
                  {disable ? (
                    <Popover
                      trigger="hover"
                      content={<PopoverContainer>{t('changesForbidden')}</PopoverContainer>}
                      placement="left"
                      overlayInnerStyle={{
                        backgroundColor: 'rgba(32, 44, 60, 1)',
                      }}
                    >
                      {t('Roll shipment')}
                    </Popover>
                  ) : t('Roll shipment')}
                </Menu.Item>
              ) : null}

              {(isImport || shipment?.getIsOriginDoor()) && hasReadAccess(updateScheduleAvailability) && (
                <Menu.Item
                  data-testid="update-schedule-info"
                  key="updateSchedule"
                  onClick={openChangeRequest}
                >
                  {t('Update Schedule Information')}
                </Menu.Item>
              )}

              {isAbleToCancel && (shipment && shipment.bookingStatus !== ShipmentBookingStatusEnum.BOOKING_CANCEL) ? (
                <Menu.Item
                  data-class="shipment-cancel-testid"
                  key="cancel"
                  style={{ color: 'red' }}
                  onClick={handleCancelShipment}
                >
                  {t('Cancel shipment')}
                </Menu.Item>
              ) : null}
            </Menu>
          )}
          onVisibleChange={setMenuVisible}
        >
          <Button
            data-class="shipment-actions-testid"
            icon={menuVisible ? <UpOutlined /> : <DownOutlined />}
          >
            {t('Shipment Actions')}
          </Button>
        </Dropdown>
      ) : null}
    </SubPageHeaderContainer>
  );
};
