import React from 'react';

import { PageComponent } from 'app-wrapper/view/routes/components';
import { ManualConfirmationController } from 'shipment-operations/controllers/ManualConfirmation';
import { createManualConfirmationStore } from 'shipment-operations/repository/mobxStores';

import { ManualConfirmation } from './ManualConfirmation.container';

export const ManualConfirmationPage = ({ id }: { id: string }) => (
  <PageComponent
    ControllerClass={ManualConfirmationController}
    StoreFactory={() => {
      const store = createManualConfirmationStore();
      store.setShipmentId(id);
      return store;
    }}
  >
    <ManualConfirmation />
  </PageComponent>
);
