import React, { useCallback, FC } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import notification from 'antd/es/notification';
import { useParams } from 'react-router';
import { RcFile, UploadProps } from 'antd/es/upload/interface';
import AntdUploadExports from 'antd/es/upload';

import { ChargeDocumentDTM } from 'shipment-operations/models/dtm';

import { useAuthToken } from 'app-wrapper/hooks';
import { addPrefix, showWarning } from 'app-wrapper/utils';

import { Upload, Button } from './Uploader.styled';

const MAX_FILE_SIZE = 1024 * 1024 * 10;
const { LIST_IGNORE } = AntdUploadExports;

interface IUploaderComponentProps {
  documents?: any[];
  onDownload: (shipmentId: string, id: number, name: string) => void;
  addDocument: (doc: ChargeDocumentDTM) => void
  removeDocument: (id: number) => void
  setIsDocumentUploading: (isUploading: boolean) => void;
}

const UploaderComponent: FC<IUploaderComponentProps> = ({
  documents,
  onDownload,
  addDocument,
  removeDocument,
  setIsDocumentUploading,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams();
  const authToken = useAuthToken();

  const postUrl = addPrefix(`/billing-service/api/v1/shipments/${shipmentId}/documents`);

  const handleBeforeUpload = useCallback((file: RcFile) => {
    const isAcceptableSize = file.size < MAX_FILE_SIZE;
    if (!isAcceptableSize) {
      showWarning({ message: t('File is too large'), duration: 4 });
    }
    return isAcceptableSize || LIST_IGNORE;
  }, []);

  const handlePreview: UploadProps['onPreview'] = useCallback((file) => {
    if (!shipmentId) {
      return;
    }
    onDownload(shipmentId, file.id, file.name);
  }, []);

  const handleChange: UploadProps['onChange'] = (res) => {
    if (res.file && res.file.status === 'uploading') {
      setIsDocumentUploading(true);
    } else {
      setIsDocumentUploading(false);
    }

    if (res.file.response && res.file.status === 'error') {
      notification.error({
        message: t('uploadDocError'),
        placement: 'bottomRight',
        duration: 0,
      });
    }
    if (res.file.status === 'done') {
      addDocument(ChargeDocumentDTM.fromPlain({
        id: res.file.response.id,
        name: res.file.response.name,
      }));
    }
  };

  const handleRemove = useCallback((file) => {
    const id = file.response ? file.response.id : file.id;
    removeDocument(id);
  }, []);

  return (
    <Upload
      showUploadList={{ showRemoveIcon: true, removeIcon: <DeleteOutlined /> }}
      action={postUrl}
      headers={{ Authorization: authToken }}
      beforeUpload={handleBeforeUpload}
      onPreview={handlePreview}
      onChange={handleChange}
      onRemove={handleRemove}
      defaultFileList={documents || []}
    >
      <Button
        icon={<UploadOutlined />}
        type="dashed"
      >
        {t('Upload document')}
      </Button>
    </Upload>
  );
};

export { UploaderComponent };
