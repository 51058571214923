import React, { FC, useMemo } from 'react';

import { QUOTAS_STATUS, REQUEST_STATUS } from 'app-wrapper/constants';

import { R } from 'monetary/repository';

import { useSelector } from 'react-redux';
import { RFQContent } from './RFQContent';
import { RFQResponseDiv } from './RFQResponse.styled';

import { NoneContent } from './NoneContent';

interface IRFQResponse {
  NoneContentRecommended?: React.ReactNode
  isAllRequestPage?: boolean
  isFooter?: boolean
  hideBook?: boolean;
  hideFavorite?: boolean;
}

export const RFQResponse: FC<IRFQResponse> = (props) => {
  const {
    NoneContentRecommended,
    isAllRequestPage,
    isFooter,
    hideBook,
    hideFavorite,
  } = props;
  const filters = useSelector(R.selectors.freightQuote.getFreightQuoteFilters);
  const getFreightQuoteCurrent = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const quotaLoadingSteps = useSelector(R.selectors.freightQuote.getFreightQuoteQuotaLoadingSteps);
  const quotas = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentQuotas);

  const isQuotaNotPending = useMemo(() => getFreightQuoteCurrent.quotasCheckStatus?.status !== 'pending'
    && quotas?.quotasOption?.fullCompleteStatus !== QUOTAS_STATUS.pending
    && getFreightQuoteCurrent?.status !== 'pending', [quotas]);

  const isVisibleFilters = (
    filters?.isRequestFilters
    && (filters?.valuesFilters?.filterPrice?.isUpdate
      || filters?.valuesFilters?.filterTransitTime?.isUpdate
      || filters?.isIncludeRelatedPortRequest
      || filters?.valuesFilters?.filterCarrier?.isUpdate)
  );

  const isVisibleRFQContent = (
    isVisibleFilters
    || (quotas?.data
      && isQuotaNotPending)
  )
    || (quotas?.allQuotas?.length
      || quotas?.quotasOption?.fullCompleteStatus === QUOTAS_STATUS.failed);

  const isLoading = (getFreightQuoteCurrent.status === REQUEST_STATUS.pending && getFreightQuoteCurrent?.quotasCheckStatus?.status !== QUOTAS_STATUS.complete);
  const isLoadingQuotas = getFreightQuoteCurrent.status === QUOTAS_STATUS.failed && quotas?.quotasOption?.fullCompleteStatus === QUOTAS_STATUS.complete;

  return (
    <RFQResponseDiv className="RFQResponse">
      {isVisibleRFQContent
        ? (
          <RFQContent
            isAllRequestPage={isAllRequestPage}
            isFooter={isFooter}
            NoneContentRecommended={NoneContentRecommended}
            hideBook={hideBook}
            hideFavorite={hideFavorite}
          />
        )
        : NoneContentRecommended || (
          <NoneContent
            isLoading={isLoading && !quotaLoadingSteps.isLoadingCompleteStep4}
            isLoadingQuotas={isLoadingQuotas}
          />
        )}
    </RFQResponseDiv>
  );
};
