import React, { FC } from 'react';
import {
  InfoBlock, InfoBlockBody, InfoBlockContainer,
  InfoBlockIcon, InfoBlockIconContainer,
} from 'monetary/view/pages/QuotaRequests/components/Quotas/components/Preview/Info/Info.styled';
import { FlexCol, GapHorizontalContainerStyled } from 'app-wrapper/view/guideline';
import {
  BodyTextMediumSpan,
} from 'app-wrapper/view/fonts';

interface ISupportDialogBlockProps {
  icon?: React.ReactNode;
  headerText?: string;
  footerText?: string;
}

export const InfoComponent: FC<ISupportDialogBlockProps> = (props) => {
  const {
    icon,
    headerText,
    footerText,
  } = props;

  return (
    <GapHorizontalContainerStyled>
      <InfoBlock>
        <InfoBlockContainer>
          <div>{/* I don't know why it doesn't work without div */}
            <InfoBlockIconContainer>
              <InfoBlockIcon>
                {icon}
              </InfoBlockIcon>
            </InfoBlockIconContainer>
          </div>
          <FlexCol>
            <BodyTextMediumSpan>{headerText}</BodyTextMediumSpan>
            <InfoBlockBody>{footerText}</InfoBlockBody>
          </FlexCol>
        </InfoBlockContainer>
      </InfoBlock>
    </GapHorizontalContainerStyled>
  );
};
