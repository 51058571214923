import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './HazmatsSvg.styled';

export const HazmatsSvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="HazmatsSvg"
      width={width || '13'}
      height={height || '12'}
      viewBox="0 0 13 12"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.15644 6.48622C6.78839 6.48622 7.32311 5.9515 7.32311 5.31955C7.32311 4.6633 6.78839 4.15289 6.15644 4.15289C5.50019 4.15289 4.98978 4.68761 4.98978 5.31955C4.98978 5.92719 5.50019 6.48622 6.15644 6.48622ZM5.1113 3.66678L3.38561 0.920247C3.23978 0.677192 2.97241 0.555664 2.72936 0.555664C2.55922 0.555664 2.38908 0.604275 2.24325 0.725803C1.1495 1.64941 0.396025 2.96191 0.177275 4.44455C0.104358 4.90636 0.468942 5.31955 0.955053 5.31955H4.18769C4.18769 4.639 4.57658 4.03136 5.1113 3.66678ZM3.26408 4.15289H1.41686C1.6113 3.3265 2.0488 2.57303 2.65644 1.94108L3.62866 3.49664C3.48283 3.69108 3.3613 3.93414 3.26408 4.15289ZM10.0453 0.725803C9.8995 0.604275 9.72936 0.555664 9.55922 0.555664C9.31616 0.555664 9.0488 0.677192 8.90297 0.920247L7.17727 3.66678C7.7363 4.03136 8.10089 4.639 8.10089 5.31955H11.3578C11.8196 5.31955 12.1842 4.90636 12.1113 4.44455C11.8926 2.96191 11.1391 1.64941 10.0453 0.725803ZM9.0245 4.15289C8.92727 3.93414 8.80575 3.71539 8.65991 3.49664L9.63214 1.94108C10.2398 2.54872 10.6773 3.3265 10.8717 4.15289H9.0245ZM7.17727 6.97233C6.88561 7.16678 6.52103 7.264 6.15644 7.264C5.76755 7.264 5.40297 7.16678 5.1113 6.97233L3.38561 9.71886C3.14255 10.1321 3.28839 10.6668 3.72589 10.8369C4.45505 11.1772 5.28144 11.3473 6.15644 11.3473C7.00714 11.3473 7.83352 11.1772 8.587 10.8369C9.00019 10.6668 9.14602 10.1321 8.90297 9.71886L7.17727 6.97233ZM6.15644 10.1807C5.62172 10.1807 5.1113 10.1077 4.62519 9.93761L5.59741 8.38205C5.79186 8.43066 5.962 8.43066 6.15644 8.43066C6.32658 8.43066 6.49672 8.43066 6.69116 8.38205L7.66339 9.93761C7.17727 10.1077 6.66686 10.1807 6.15644 10.1807Z" fill="currentColor" />
    </Icon>
  );
};
