import React, { memo } from 'react';

import { useSelector } from 'react-redux';
import { R } from 'monetary/repository';
import { RFQLoaderComponent } from 'monetary/view/components/RFQLoader/RFQLoader.component';

interface IRFQLoaderComponentProps {
  nameLogo?: string;
}

export const RFQLoaderContainer = memo((props: IRFQLoaderComponentProps) => {
  const {
    nameLogo,
  } = props;

  const getFreightQuoteCurrent = useSelector(R.selectors.freightQuote.getFreightQuoteQuotaLoadingSteps);

  return (
    <RFQLoaderComponent
      nameLogo={nameLogo}
      isLoadingCompleteStep1={getFreightQuoteCurrent.isLoadingCompleteStep1}
      isLoadingCompleteStep2={getFreightQuoteCurrent.isLoadingCompleteStep2}
      isLoadingCompleteStep3={getFreightQuoteCurrent.isLoadingCompleteStep3}
      isLoadingCompleteStep4={getFreightQuoteCurrent.isLoadingCompleteStep4}
    />
  );
});
