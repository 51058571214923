import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';

import { BookingDetailsOverlayComponent } from './BookingDetailsOverlay.component';

export const BookingDetailsOverlay = React.memo(() => {
  const {
    incotermsTrade,
    isSubmitVisited,
  } = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const errors = useSelector(R.selectors.freightQuote.getFreightQuoteErrors);
  const permissions = useSelector(R.selectors.moduleFunctionalityPermissions.getRFQNewQuotaPermissions);

  return (
    <BookingDetailsOverlayComponent
      incotermsTrade={incotermsTrade || ''}
      isSubmitVisited={isSubmitVisited}
      incotermsTradeError={errors?.incotermsTrade}
      shouldShowClientInformation={!permissions?.rfqFormClientInformation}
      onChangeFreightIncotermsTrade={UC.FreightQuote.onChangeFreightIncotermsTrade}
    />
  );
});
