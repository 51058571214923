import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';

import { RFRByIdPageComponent } from './RFRById.component';

const RFRByIdPageContainer = memo(() => {
  const getFreightQuoteCurrent = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const quotas = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentQuotas);

  const quotaName = `${getFreightQuoteCurrent?.form?.bookmark?.name || getFreightQuoteCurrent.quotaId || quotas?.quotasOption?.id || ''}`;

  return (
    <RFRByIdPageComponent
      allQuotesState={quotas}
      RFRStatus={getFreightQuoteCurrent.RFRById?.status}
      quotaName={quotaName}
      quotaStatus={getFreightQuoteCurrent?.status}
      isFavorite={getFreightQuoteCurrent?.filtersRequestAllQuotas?.isFavorite}
      isLoad={getFreightQuoteCurrent?.isLoadingByIdRFR}
      onRunRFRByIdQuotasBumpersPage={UC.FreightRFRQuote.onRunRFRByIdPage}
      abortAllRequests={UC.FreightQuote.abortAllRequests}
    />
  );
});

export { RFRByIdPageContainer as RFRByIdPage };
