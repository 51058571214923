import styled from 'styled-components';

import { BodyTextRegularSpan } from 'app-wrapper/view/guideline/Font';

export const SubTitle = styled(BodyTextRegularSpan)`
  color: ${({ theme }) => theme?.themesColors?.darkGrayMiddle};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
