import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC as appUC } from 'app-wrapper/controllers';
import { UC } from 'shipment-operations/controllers';
import { EditMBLNumberModalComponent } from 'shipment-operations/view/drawers/EditMBLNumberModal/EditMBLNumberModal.component';

const EditCarrierReferenceModalContainer = React.memo(() => {
  const { t } = useTranslation();
  const isPending = useSelector(R.selectors.overview.getIsModalPending);
  const carrierReference = useSelector(R.selectors.overview.getCarrierReferenceNumberToEdit);

  return (
    <EditMBLNumberModalComponent
      isPending={isPending}
      onClose={appUC.drawer.closeDrawer}
      onOk={UC.overview.saveCarrierReferenceNumber}
      mblNumber={carrierReference}
      setMBLNumber={UC.overview.setCarrierReferenceNumberToEdit}
      title={t('Edit Booking Number')}
      label={t('Booking Number')}
    />
  );
});

export { EditCarrierReferenceModalContainer as EditCarrierReferenceModal };
