import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RcFile, UploadProps } from 'antd/es/upload/interface';
import AntdUploadExports from 'antd/es/upload';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import UploadOutlined from '@ant-design/icons/UploadOutlined';

import { showWarning } from 'app-wrapper/utils/showWarning';
import { addPrefix } from 'app-wrapper/utils';

import { MSDSDocumentDTM, ContainerDocumentDTM } from 'shipment-operations/models/dtm';

import { Upload, Button } from './SOCDocumentUpload.styled';

const MAX_FILE_SIZE = 1024 * 1024 * 10; // 10MB
const { LIST_IGNORE } = AntdUploadExports;

interface ISOCDocumentUploadComponentProps {
  error: boolean
  authToken: string
  shipmentId: string;
  containerId: string;
  containerDocument: ContainerDocumentDTM[]
  setContainerDocument: (document: ContainerDocumentDTM | null, containerId: string) => void
  touchContainerField: (field: string, containerId: string) => void
  downloadDocument: (containerId: string) => void
}

// TODO Refactoring request by @AliaksandrKatovich FRTUN-2166
export const SOCDocumentUploadComponent: FC<ISOCDocumentUploadComponentProps> = ({
  error,
  authToken,
  containerId,
  containerDocument,
  setContainerDocument,
  touchContainerField,
  downloadDocument,
  shipmentId,
}) => {
  const { t } = useTranslation();

  const setDocument = useCallback((value: ContainerDocumentDTM[]) => {
    setContainerDocument(value[0] || null, containerId);
  }, [setContainerDocument, containerId]);

  const touchField = useCallback((field: string) => {
    touchContainerField(field, containerId);
  }, [touchContainerField, containerId]);

  const downloadContainerDocument = useCallback(() => downloadDocument(containerId), [downloadDocument, containerId]);

  const handleBeforeUpload = useCallback((file: RcFile) => {
    const isAcceptableSize = file.size < MAX_FILE_SIZE;
    if (!isAcceptableSize) {
      showWarning({ message: t('File is too large'), duration: 4 });
    }
    return isAcceptableSize || LIST_IGNORE;
  }, []);

  const handleChange: UploadProps['onChange'] = useCallback(({ file }) => {
    if (file.status === 'removed') {
      setDocument([]);
      return;
    }

    if (file.response) {
      file.url = '/';
    }

    if (file.response && file.status === 'error') {
      file.error = new Error(file.response?.message);
      file.response = file.response.message ? file.response.message : {};
    }

    setDocument([MSDSDocumentDTM.fromPlain({
      name: file.name,
      url: file.url,
      uid: file.uid,
      response: file.response,
      status: file.status,
    })]);
  }, [setDocument]);

  const handleRemove: UploadProps['onRemove'] = useCallback(() => {
    setDocument([]);
  }, [setDocument]);

  const handlePreview: UploadProps['onPreview'] = useCallback((file) => {
    if (!file.status) {
      return;
    }

    downloadContainerDocument();
  }, [downloadContainerDocument]);

  const handleBlur = useCallback(() => {
    touchField('msdsDocument');
  }, []);

  const postUrl = addPrefix(`/shipment-service/api/v1/shipments/${shipmentId}/documents?type=CSW`);
  const isErrorOutlineDisplayed = error && !containerDocument.length;

  return (
    <Upload
      maxCount={1}
      multiple={false}
      showUploadList={{ showRemoveIcon: true, removeIcon: <DeleteOutlined /> }}
      beforeUpload={handleBeforeUpload}
      action={postUrl}
      headers={{ Authorization: authToken }}
      fileList={containerDocument}
      onPreview={handlePreview}
      onChange={handleChange}
      onRemove={handleRemove}
    >
      <Button
        error={+isErrorOutlineDisplayed}
        icon={<UploadOutlined />}
        type="dashed"
        onBlur={handleBlur}
      >
        {t('Upload document')}
      </Button>
    </Upload>
  );
};
