import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { StepsHeaderComponent } from './StepsHeader.component';

const StepsHeaderContainer = () => {
  const step = useSelector(R.selectors.bookingWizard.getCurrentStep);
  const isCustomerOrg = useSelector(R.selectors.bookingWizard.getIsCurrentOrganizationCustomer);

  return (
    <StepsHeaderComponent
      step={step}
      isCustomerOrg={isCustomerOrg}
    />
  );
};

export { StepsHeaderContainer as StepsHeader };
