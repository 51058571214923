import styled from 'styled-components';

import { Modal } from 'shipment-operations/view/pages/ShipmentOverview/components/CancelShipmentModal/CancelShipmentModal.styled';
import themesColors from 'app-wrapper/view/themes/themesColors';

export const StyledModal = styled(Modal)`
  button:last-of-type {
    border-color: ${themesColors.neutralBranding8} !important;
    background: ${themesColors.neutralBranding8} !important;
  }

  button:last-of-type:disabled {
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
  }
`;
