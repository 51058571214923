import React, { FC, useMemo } from 'react';
import { NavLink, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DollarIcon from '@ant-design/icons/DollarCircleOutlined';
import SettingIcon from '@ant-design/icons/SettingOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import GlobalOutlined from '@ant-design/icons/GlobalOutlined';
import AimOutlined from '@ant-design/icons/AimOutlined';
import Menu from 'antd/es/menu';
import CompassOutlined from '@ant-design/icons/CompassOutlined';

import { hasReadAccess } from 'app-wrapper/utils';
import { SkeletonSection } from 'app-wrapper/view/components';
import { ShipmentAllStatsDTM, ShipmentPreviewDTM } from 'shipment-operations/models/dtm';
import { RouteNames } from 'app-wrapper/constants';
import { ContainerOutlinedSvg, RoadShipSvg } from 'app-wrapper/view/icons';
import { PermissionAttributePolicy, ShipmentStatusEnum } from 'shipment-operations/constants';

import {
  StyledItemSunWarningDot,
  IconWrapper,
  StyledItem,
  StyledMenu,
  StyledSubMenu,
  Skeletons,
} from './ShipmentMenu.styled';

const getHigherLevelUrls = (url: string, result: string[] = [url]): string[] => {
  const lastDirectoryStart = url.lastIndexOf('/');
  if (lastDirectoryStart === -1) {
    return result;
  }
  const higherLevelUrl = url.substring(0, lastDirectoryStart);

  if (!higherLevelUrl.length) {
    return result;
  }

  return getHigherLevelUrls(higherLevelUrl, [...result, higherLevelUrl]);
};

const SHIPMENT_DETAILS = 'shipment-details';
const SHIPMENT_ACTION_CENTER = 'shipment-action-center';
const SHIPMENT_OPERATIONS = 'shipment-operation';
const SHIPMENT_ACCOUNTING = 'shipment-accounting';
const SHIPMENT_TRANSPORTATION = 'shipment-transportation';

const { Item } = Menu;

interface IShipmentMenuComponentProps {
  shipmentStats?: ShipmentAllStatsDTM;
  shipment?: ShipmentPreviewDTM;
  isLoading: boolean;
  transportationAvailability?: PermissionAttributePolicy;
}

export const ShipmentMenuComponent: FC<IShipmentMenuComponentProps> = ({
  shipmentStats,
  shipment,
  isLoading,
  transportationAvailability,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams<'shipmentId'>();
  const { pathname } = useLocation();

  const selectedKeys = useMemo(() => getHigherLevelUrls(pathname), [pathname]);

  const shipmentDetailsPathnames = useMemo(() => [
    RouteNames.SHIPMENT_SHIPPING_PARTIES(shipmentId),
    RouteNames.SHIPMENT_CARGO(shipmentId),
    RouteNames.SHIPMENT_CONTAINERS(shipmentId),
    RouteNames.SHIPMENT_DOCUMENTS(shipmentId),
  ], [shipmentId]);

  const shipmentOperationsPathnames = useMemo(() => [
    RouteNames.SHIPMENT_TRANSPORTATION(shipmentId),
    RouteNames.SHIPMENT_EXPORT_CLEARANCE(shipmentId),
    RouteNames.SHIPMENT_BILL_OF_LADING(shipmentId),
    RouteNames.SHIPMENT_ADDITIONAL_SERVICES(shipmentId),
  ], [shipmentId]);

  const shipmentAccountingPathnames = useMemo(() => [
    RouteNames.SHIPMENT_CHARGES(shipmentId),
    RouteNames.SHIPMENT_BILLING_RECEIVABLES(shipmentId),
    RouteNames.SHIPMENT_DISPUTES(shipmentId),
  ], [shipmentId]);

  const shipmentTransportationPathnames = useMemo(() => [
    RouteNames.SHIPMENT_TRANSPORTATION_OCEAN_BOOKING(shipmentId),
    RouteNames.SHIPMENT_TRANSPORTATION_OCEAN_BOOKING_OVERVIEW(shipmentId),
    RouteNames.SHIPMENT_TRANSPORTATION_OCEAN_BOOKING_ACCESSORIALS(shipmentId),
    RouteNames.SHIPMENT_TRANSPORTATION_OCEAN_BOOKING_CHARGES(shipmentId),
    RouteNames.SHIPMENT_TRANSPORTATION_OCEAN_BOOKING_FREE_TIME(shipmentId),
    RouteNames.SHIPMENT_TRANSPORTATION_IMPORT(shipmentId),
    RouteNames.SHIPMENT_TRANSPORTATION_EXPORT(shipmentId),
  ], [shipmentId]);

  const defaultOpenedKeys = useMemo(() => {
    if (shipmentTransportationPathnames.some((detailsPathname) => pathname.includes(detailsPathname))) {
      return [SHIPMENT_TRANSPORTATION];
    }
    if (shipmentDetailsPathnames.some((detailsPathname) => pathname.includes(detailsPathname))) {
      return [SHIPMENT_DETAILS];
    }
    if (shipmentOperationsPathnames.some((operationsPathname) => pathname.includes(operationsPathname))) {
      return [SHIPMENT_OPERATIONS];
    }
    if (shipmentAccountingPathnames.some((accountingPathname) => pathname.includes(accountingPathname))) {
      return [SHIPMENT_ACCOUNTING];
    }

    return [];
  }, [shipmentDetailsPathnames, shipmentOperationsPathnames, shipmentAccountingPathnames]);

  const isAbleToViewTransportation = hasReadAccess(transportationAvailability);

  if (isLoading) {
    return (
      <Skeletons>
        <SkeletonSection height="40px" />
        <SkeletonSection height="40px" />
        <SkeletonSection height="40px" />
        <SkeletonSection height="40px" />
        <SkeletonSection height="40px" />
        <SkeletonSection height="40px" />
        <SkeletonSection height="40px" />
        <SkeletonSection height="40px" />
      </Skeletons>
    );
  }

  return (
    <StyledMenu
      selectedKeys={selectedKeys}
      className="ShipmentMenu"
      mode="inline"
      defaultOpenKeys={defaultOpenedKeys}
    >
      <StyledItem
        key={RouteNames.SHIPMENT_OVERVIEW(shipmentId)}
        icon={<IconWrapper><RoadShipSvg /></IconWrapper>}
      >
        <NavLink to={RouteNames.SHIPMENT_OVERVIEW(shipmentId)}>{t('Overview')}</NavLink>
      </StyledItem>

      <StyledSubMenu
        className="RouteNames_SHIPMENT_SHIPPING_PARTIES_Action_Center"
        key={SHIPMENT_ACTION_CENTER}
        icon={<IconWrapper colorSvg="inherit"><CompassOutlined /></IconWrapper>}
        title={(
          <>
            <span>{t('Action Center')}</span>
            <StyledItemSunWarningDot isVisible={!!(shipmentStats && ShipmentAllStatsDTM.fromPlain({ ...shipmentStats })?.getShipmentAllHighNotifications() > 0)} />
          </>
        )}
      >
        <Item key={RouteNames.SHIPMENT_CENTER_TASKS(shipmentId)}>
          <NavLink id="RouteNames_SHIPMENT_SHIPPING_ACTION_CENTER_0" to={RouteNames.SHIPMENT_CENTER_TASKS(shipmentId)}>{t('Tasks')}<StyledItemSunWarningDot isSub isVisible={!!shipmentStats?.taskHighStats?.expiring} /></NavLink>
        </Item>
        <Item key={RouteNames.SHIPMENT_CENTER_REQUEST(shipmentId)}>
          <NavLink id="RouteNames_SHIPMENT_SHIPPING_ACTION_CENTER_3" to={RouteNames.SHIPMENT_CENTER_REQUEST(shipmentId)}>{t('Requests')}<StyledItemSunWarningDot isSub isVisible={!!shipmentStats?.requestsHighStats?.expiring} /></NavLink>
        </Item>
        <Item key={RouteNames.SHIPMENT_CENTER_ALERTS(shipmentId)}>
          <NavLink id="RouteNames_SHIPMENT_SHIPPING_ACTION_CENTER_1" to={RouteNames.SHIPMENT_CENTER_ALERTS(shipmentId)}>{t('Alerts')}<StyledItemSunWarningDot isSub isVisible={!!shipmentStats?.alertsHighStats?.expiring} /></NavLink>
        </Item>
        <Item key={RouteNames.SHIPMENT_CENTER_NOTIFICATION(shipmentId)}>
          <NavLink id="RouteNames_SHIPMENT_SHIPPING_ACTION_CENTER_2" to={RouteNames.SHIPMENT_CENTER_NOTIFICATION(shipmentId)}>{t('Notifications')}<StyledItemSunWarningDot isSub isVisible={!!shipmentStats?.notificationHighStats?.unreadCritical} /></NavLink>
        </Item>
      </StyledSubMenu>

      <StyledSubMenu
        className="RouteNames_SHIPMENT_SHIPPING_PARTIES_Details"
        key={SHIPMENT_DETAILS}
        icon={<IconWrapper><ContainerOutlinedSvg /></IconWrapper>}
        title={t('Details')}
      >
        <Item key={RouteNames.SHIPMENT_SHIPPING_PARTIES(shipmentId)}>
          <NavLink id="RouteNames_SHIPMENT_SHIPPING_PARTIES_0" to={RouteNames.SHIPMENT_SHIPPING_PARTIES(shipmentId)}>{t('Shipping Parties')}</NavLink>
        </Item>
        <Item key={RouteNames.SHIPMENT_CARGO(shipmentId)}>
          <NavLink to={RouteNames.SHIPMENT_CARGO(shipmentId)}>{t('Cargo')}</NavLink>
        </Item>
        <Item key={RouteNames.SHIPMENT_CONTAINERS(shipmentId)}>
          <NavLink to={RouteNames.SHIPMENT_CONTAINERS(shipmentId)}>{t('Containers')}</NavLink>
        </Item>
        <Item key={RouteNames.SHIPMENT_DOCUMENTS(shipmentId)}>
          <NavLink to={RouteNames.SHIPMENT_DOCUMENTS(shipmentId)}>{t('Documents')}</NavLink>
        </Item>
        <Item key={RouteNames.SHIPMENT_LOCATIONS(shipmentId)}>
          <NavLink to={RouteNames.SHIPMENT_LOCATIONS(shipmentId)}>{t('Locations')}</NavLink>
        </Item>
      </StyledSubMenu>

      <StyledItem
        key={RouteNames.SHIPMENT_TRACKER(shipmentId)}
        icon={(
          <IconWrapper>
            <AimOutlined />
          </IconWrapper>
        )}
      >
        <NavLink to={RouteNames.SHIPMENT_TRACKER(shipmentId)}>
          {t('Tracker')}
        </NavLink>
      </StyledItem>

      {shipment?.isDrayageShipment() ? (
        <>
          <StyledSubMenu
            key={SHIPMENT_TRANSPORTATION}
            icon={<GlobalOutlined />}
            title={t('Transportation')}
          >
            {isAbleToViewTransportation
              ? (
                <Item key={RouteNames.SHIPMENT_TRANSPORTATION_OCEAN_BOOKING(shipmentId)}>
                  <NavLink to={RouteNames.SHIPMENT_TRANSPORTATION_OCEAN_BOOKING(shipmentId)}>{t('Ocean Booking')}</NavLink>
                </Item>
              )
              : null}
            {shipment?.getIsOriginDoor() && shipment?.status !== ShipmentStatusEnum.AWAITING_APPROVAL ? (
              <Item key={RouteNames.SHIPMENT_TRANSPORTATION_EXPORT(shipmentId)}>
                <NavLink to={RouteNames.SHIPMENT_TRANSPORTATION_EXPORT(shipmentId)}>{t('Export Inland Pickup')}</NavLink>
              </Item>
            ) : null}

            {shipment?.getIsDestinationDoor() && shipment?.status !== ShipmentStatusEnum.AWAITING_APPROVAL ? (
              <Item key={RouteNames.SHIPMENT_TRANSPORTATION_IMPORT(shipmentId)}>
                <NavLink to={RouteNames.SHIPMENT_TRANSPORTATION_IMPORT(shipmentId)}>{t('Import Inland Delivery')}</NavLink>
              </Item>
            ) : null}
          </StyledSubMenu>
        </>
      )
        : (
          <>
            {isAbleToViewTransportation ? (
              <StyledSubMenu
                key={SHIPMENT_TRANSPORTATION}
                icon={<GlobalOutlined />}
                title={t('Transportation')}
              >
                <Item key={RouteNames.SHIPMENT_TRANSPORTATION_OCEAN_BOOKING(shipmentId)}>
                  <NavLink to={RouteNames.SHIPMENT_TRANSPORTATION_OCEAN_BOOKING(shipmentId)}>{t('Ocean Booking')}</NavLink>
                </Item>
              </StyledSubMenu>
            )
              : null}
          </>
        )}
      <StyledSubMenu
        key={SHIPMENT_OPERATIONS}
        icon={<SettingIcon />}
        title={t('Operations')}
      >
        <Item key={RouteNames.SHIPMENT_BILL_OF_LADING(shipmentId)}>
          <NavLink to={RouteNames.SHIPMENT_BILL_OF_LADING(shipmentId)}>{t('Bills of Lading')}</NavLink>
        </Item>

        <Item key={RouteNames.SHIPMENT_ADDITIONAL_SERVICES(shipmentId)}>
          <NavLink to={RouteNames.SHIPMENT_ADDITIONAL_SERVICES(shipmentId)}>{t('Services')}</NavLink>
        </Item>
      </StyledSubMenu>
      <StyledSubMenu
        key={SHIPMENT_ACCOUNTING}
        icon={<DollarIcon />}
        title={t('Accounting')}
      >
        <Item key={RouteNames.SHIPMENT_CHARGES(shipmentId)}>
          <NavLink to={RouteNames.SHIPMENT_CHARGES(shipmentId)}>{t('Cost Tracking')}</NavLink>
        </Item>
        <Item key={RouteNames.SHIPMENT_BILLING_RECEIVABLES(shipmentId)}>
          <NavLink to={RouteNames.SHIPMENT_BILLING_RECEIVABLES(shipmentId)}>{t('Billing')}</NavLink>
        </Item>
        {/* Page not implemented yet <Item key={RouteNames.SHIPMENT_DISPUTES(shipmentId)}> */}
        {/*  <NavLink to={RouteNames.SHIPMENT_DISPUTES(shipmentId)}>{t('Disputes')}</NavLink> */}
        {/* </Item> */}
      </StyledSubMenu>

      <StyledItem
        key={RouteNames.SHIPMENT_PEOPLE(shipmentId)}
        icon={(
          <IconWrapper>
            <TeamOutlined />
          </IconWrapper>
        )}
      >
        <NavLink to={RouteNames.SHIPMENT_PEOPLE(shipmentId)}>
          {t('People')}
        </NavLink>
      </StyledItem>
      {/* Page not implemented yet <StyledItem */}
      {/*  key={RouteNames.SHIPMENT_ACTIVITY_LOG(shipmentId)} */}
      {/*  icon={<IconWrapper><ClockCircleSvg /></IconWrapper>} */}
      {/* > */}
      {/*  <NavLink to={RouteNames.SHIPMENT_ACTIVITY_LOG(shipmentId)}>{t('Activity Log')}</NavLink> */}
      {/* </StyledItem> */}
    </StyledMenu>
  );
};
