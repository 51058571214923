import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { DateDtm } from 'app-wrapper/models/dtm';
import { instanceToPlain } from 'app-wrapper/utils';

import {
  CutoffsDTM, CarrierOverviewDTM, ReferencesOverviewDTM,
  ShippingPartyOverviewDTM, TransportationOverviewDTM, ICutoffsDTM,
} from 'shipment-operations/models/dtm';
import { ITransportationOverviewState } from 'shipment-operations/models/states';

export const initialState: ITransportationOverviewState = {
  isLoading: true,
  isCutoffEditModalOpen: false,
  oceanCarrierName: '',
  carrierSCAC: '',

  transportationOverview: TransportationOverviewDTM.createEmpty(),
};

export const transportationOverviewStore = createSlice({
  name: 'transportationOverview',
  initialState,
  reducers: {
    openCutoffEditModal: (state) => {
      state.isCutoffEditModalOpen = true;
    },
    closeCutoffEditModal: (state) => {
      state.isCutoffEditModalOpen = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setCarrierSCAC: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      carrierSCAC: payload,
    }),
    setCutoff: (state, action: PayloadAction<{ item: keyof ICutoffsDTM, date: DateDtm }>) => {
      const { item, date } = action.payload;
      state.transportationOverview[item] = date;
    },
    setCutoffs: (state, { payload }: PayloadAction<CutoffsDTM>) => ({
      ...state,
      transportationOverview: TransportationOverviewDTM.fromPlain({
        ...instanceToPlain(state.transportationOverview),
        documentationCarrierDate: payload.documentationCarrierDate,
        portCarrierDate: payload.portCarrierDate,
        vgmCarrierDate: payload.vgmCarrierDate,
        hazmatCarrierDate: payload.hazmatCarrierDate,
      }),
    }),
    setOceanCarrierName: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      oceanCarrierName: payload,
    }),
    discardCutoffsChanges: (state) => ({
      ...state,
      transportationOverview: TransportationOverviewDTM.fromPlain({
        ...instanceToPlain(state.transportationOverview),
        documentationCarrierDate: state.carrierCutoffsSavedData?.documentationCarrierDate,
        portCarrierDate: state.carrierCutoffsSavedData?.portCarrierDate,
        vgmCarrierDate: state.carrierCutoffsSavedData?.vgmCarrierDate,
        hazmatCarrierDate: state.carrierCutoffsSavedData?.hazmatCarrierDate,
        freightuneCutoffs: {
          documentationCarrierDate: state.freightuneCutoffsSavedData?.documentationCarrierDate,
          portCarrierDate: state.freightuneCutoffsSavedData?.portCarrierDate,
          vgmCarrierDate: state.freightuneCutoffsSavedData?.vgmCarrierDate,
          hazmatCarrierDate: state.freightuneCutoffsSavedData?.hazmatCarrierDate,
        },
      }),
    }),
    setSavedCarrierCutoffs: (state, action: PayloadAction<CutoffsDTM>) => {
      state.carrierCutoffsSavedData = action.payload;
    },
    setFreightuneCutoffs: (state, action: PayloadAction<CutoffsDTM>) => {
      state.transportationOverview.freightuneCutoffs = action.payload;
    },
    setSavedFreightuneCutoffs: (state, action: PayloadAction<CutoffsDTM>) => {
      state.freightuneCutoffsSavedData = action.payload;
    },
    setDocumentationCarrierCutoff: (state, { payload }: PayloadAction<DateDtm | undefined | null>) => {
      if (!payload) {
        return {
          ...state,
          transportationOverview: TransportationOverviewDTM.fromPlain({
            ...instanceToPlain(state.transportationOverview),
            documentationCarrierDate: undefined,
          }),
        };
      }
      return {
        ...state,
        transportationOverview: TransportationOverviewDTM.fromPlain({
          ...instanceToPlain(state.transportationOverview),
          documentationCarrierDate: payload,
        }),
      };
    },
    setDocumentationFreightuneCutoff: (state, { payload }: PayloadAction<DateDtm | undefined | null>) => {
      if (!payload) {
        return {
          ...state,
          transportationOverview: TransportationOverviewDTM.fromPlain({
            ...instanceToPlain(state.transportationOverview),
            freightuneCutoffs: {
              ...instanceToPlain(state.transportationOverview.freightuneCutoffs),
              documentationCarrierDate: undefined,
            },
          }),
        };
      }
      return {
        ...state,
        transportationOverview: TransportationOverviewDTM.fromPlain({
          ...instanceToPlain(state.transportationOverview),
          freightuneCutoffs: {
            ...instanceToPlain(state.transportationOverview.freightuneCutoffs),
            documentationCarrierDate: payload,
          },
        }),
      };
    },
    setPortCarrierCutoff: (state, { payload }: PayloadAction<DateDtm | undefined | null>) => {
      if (!payload) {
        return {
          ...state,
          transportationOverview: TransportationOverviewDTM.fromPlain({
            ...instanceToPlain(state.transportationOverview),
            portCarrierDate: undefined,
          }),
        };
      }
      return {
        ...state,
        transportationOverview: TransportationOverviewDTM.fromPlain({
          ...instanceToPlain(state.transportationOverview),
          portCarrierDate: payload,
        }),
      };
    },
    setPortFreightuneCutoff: (state, { payload }: PayloadAction<DateDtm | undefined | null>) => {
      if (!payload) {
        return {
          ...state,
          transportationOverview: TransportationOverviewDTM.fromPlain({
            ...instanceToPlain(state.transportationOverview),
            freightuneCutoffs: {
              ...instanceToPlain(state.transportationOverview.freightuneCutoffs),
              portCarrierDate: undefined,
            },
          }),
        };
      }
      return {
        ...state,
        transportationOverview: TransportationOverviewDTM.fromPlain({
          ...instanceToPlain(state.transportationOverview),
          freightuneCutoffs: {
            ...instanceToPlain(state.transportationOverview.freightuneCutoffs),
            portCarrierDate: payload,
          },
        }),
      };
    },
    setVgmCarrierCutoff: (state, { payload }: PayloadAction<DateDtm | undefined | null>) => {
      if (!payload) {
        return {
          ...state,
          transportationOverview: TransportationOverviewDTM.fromPlain({
            ...instanceToPlain(state.transportationOverview),
            vgmCarrierDate: undefined,
          }),
        };
      }
      return {
        ...state,
        transportationOverview: TransportationOverviewDTM.fromPlain({
          ...instanceToPlain(state.transportationOverview),
          vgmCarrierDate: payload,
        }),
      };
    },
    setVgmFreightuneCutoff: (state, { payload }: PayloadAction<DateDtm | undefined | null>) => {
      if (!payload) {
        return {
          ...state,
          transportationOverview: TransportationOverviewDTM.fromPlain({
            ...instanceToPlain(state.transportationOverview),
            freightuneCutoffs: {
              ...instanceToPlain(state.transportationOverview.freightuneCutoffs),
              vgmCarrierDate: undefined,
            },
          }),
        };
      }
      return {
        ...state,
        transportationOverview: TransportationOverviewDTM.fromPlain({
          ...instanceToPlain(state.transportationOverview),
          freightuneCutoffs: {
            ...instanceToPlain(state.transportationOverview.freightuneCutoffs),
            vgmCarrierDate: payload,
          },
        }),
      };
    },
    setCarrierCutoffs: (state, action: PayloadAction<CutoffsDTM>) => {
      state.transportationOverview.documentationCarrierDate = action.payload.documentationCarrierDate;
      state.transportationOverview.portCarrierDate = action.payload.portCarrierDate;
      state.transportationOverview.vgmCarrierDate = action.payload.vgmCarrierDate;
      state.transportationOverview.hazmatCarrierDate = action.payload.hazmatCarrierDate;
    },
    setHazmatCarrierCutoff: (state, { payload }: PayloadAction<DateDtm | undefined | null>) => {
      if (!payload) {
        return {
          ...state,
          transportationOverview: TransportationOverviewDTM.fromPlain({
            ...instanceToPlain(state.transportationOverview),
            hazmatCarrierDate: undefined,
          }),
        };
      }
      return {
        ...state,
        transportationOverview: TransportationOverviewDTM.fromPlain({
          ...instanceToPlain(state.transportationOverview),
          hazmatCarrierDate: payload,
        }),
      };
    },
    setHazmatFreightuneCutoff: (state, { payload }: PayloadAction<DateDtm | undefined | null>) => {
      if (!payload) {
        return {
          ...state,
          transportationOverview: TransportationOverviewDTM.fromPlain({
            ...instanceToPlain(state.transportationOverview),
            freightuneCutoffs: {
              ...instanceToPlain(state.transportationOverview.freightuneCutoffs),
              hazmatCarrierDate: undefined,
            },
          }),
        };
      }
      return {
        ...state,
        transportationOverview: TransportationOverviewDTM.fromPlain({
          ...instanceToPlain(state.transportationOverview),
          freightuneCutoffs: {
            ...instanceToPlain(state.transportationOverview.freightuneCutoffs),
            hazmatCarrierDate: payload,
          },
        }),
      };
    },
    setBookingOverviewData: (state, action: PayloadAction<CarrierOverviewDTM & ReferencesOverviewDTM>) => {
      state.transportationOverview = {
        ...state.transportationOverview,
        carrierName: action.payload.carrierName,
        carrierReference: action.payload.carrierReference,
        termsAndConditions: action.payload.termsAndConditions,
        comments: action.payload.comments,
        contactName: action.payload.contactName,
        phoneList: action.payload.phoneList,
        emailList: action.payload.emailList,
        mblNumber: action.payload.mblNumber,
        inttraReferenceNumber: action.payload.inttraReferenceNumber,
        contractNumber: action.payload.contractNumber,
        agentsReference: action.payload.agentsReference,
      };
    },
    setShippingParties: (state, action: PayloadAction<Record<'shipper' | 'consignee' | 'notifyParty', ShippingPartyOverviewDTM>>) => {
      state.transportationOverview = {
        ...state.transportationOverview,
        shipper: action.payload.shipper,
        consignee: action.payload.consignee,
        notifyParty: action.payload.notifyParty,
      };
    },
    reset: () => ({ ...initialState }),
  },
});

export const transportationOverviewActions = transportationOverviewStore.actions;
export const transportationOverviewReducer = transportationOverviewStore.reducer;
