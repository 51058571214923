import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RadioGroup } from 'app-wrapper/view/components';
import { RadioButton } from 'app-wrapper/view/components/ant/Radio';
import { EFreightIncotermsTrade } from 'monetary/constants';
import { IDefaultFieldErrors } from 'monetary/models/errors';
import { RisksAndPayments } from 'monetary/view/components/FreightQuoteFormRequest/RisksAndPayments';
import { ClientInformation } from 'monetary/view/components/FreightQuoteFormRequest/ClientInformation';
import { AdditionalServices } from 'monetary/view/components/FreightQuoteFormRequest/AdditionalServices';

import {
  Overlay,
  OverlayBottom,
  OverlayWrap,
} from './BookingDetailsOverlay.styled';

interface IBookingDetailsOverlayProps {
  incotermsTrade: string;
  isSubmitVisited?: boolean;
  incotermsTradeError?: IDefaultFieldErrors;
  onChangeFreightIncotermsTrade: (value: string) => void;
  shouldShowClientInformation?: boolean;
}

export const BookingDetailsOverlayComponent: FC<IBookingDetailsOverlayProps> = ({
  onChangeFreightIncotermsTrade,
  isSubmitVisited,
  incotermsTradeError,
  incotermsTrade,
  shouldShowClientInformation,
}) => {
  const { t } = useTranslation();

  const [formLocalState] = useState<{ isCollapsed?: boolean }>({
    isCollapsed: true,
  });

  const getHasErrorField = useCallback((field?: IDefaultFieldErrors) => !!((field?.isVisited && field?.message) || (field?.message && isSubmitVisited)), [isSubmitVisited]);

  const onChangeFreightIncotermsTradeHandler = useCallback(
    (e) => {
      onChangeFreightIncotermsTrade(e.target.value);
    },
    [],
  );

  return (
    <OverlayWrap>
      <Overlay>
        <RadioGroup
          size="middle"
          onChange={onChangeFreightIncotermsTradeHandler}
          hasError={getHasErrorField(incotermsTradeError)}
          value={incotermsTrade}
        >
          <RadioButton
            key="RFQTabsSelect_IMPORT"
            value={EFreightIncotermsTrade.IMPORT}
          >
            {t('Import')}
          </RadioButton>

          <RadioButton key="RFQTabsSelect_EXPORT" value={EFreightIncotermsTrade.EXPORT}>
            {t('Export')}
          </RadioButton>
        </RadioGroup>

        <RisksAndPayments forceCollapse={false} />

        {!shouldShowClientInformation ? (
          <ClientInformation
            formLocalState={formLocalState}
            forceCollapse={false}
          />
        ) : null}

        <AdditionalServices
          formLocalState={formLocalState}
          forceCollapse={false}
        />
      </Overlay>

      <OverlayBottom />
    </OverlayWrap>
  );
};
