import React, { FC } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';

import { Box } from 'app-wrapper/view/components/Box';
import { FormStatus } from 'app-wrapper/view/components/FormStatus';
import { getShipmentRolesByName } from 'app-wrapper/data/shipmentsParties/shipmentsPartySlice';
import { useAppSelector } from 'app-wrapper/hooks';
import Address from 'app-wrapper/types/Address';

import { IShippingPartyForm, IShippingPartyForms } from 'shipment-operations/view/pages/Booking/ShippingParties/types';

import {
  ShippingPartyListContainer,
  ShippingPartyListDescription,
  ShippingPartyListTitle,
  ShippingPartyListTitleWrapper,
} from './styles';

interface IShippingPartyListProps {
  selectedShipmentParty: number,
  setSelectedShipmentParty: (index: number) => void,
}

const buildContactAddress = (address?: Address) => (
  address && address.id ? `${address?.postalCode} ${address?.city}, ${address?.address1}` : '' // Todo use getPostalCode
);

export const ShippingPartyList: FC<IShippingPartyListProps> = ({
  selectedShipmentParty,
  setSelectedShipmentParty,
}) => {
  const formMethods = useFormContext<IShippingPartyForms>();

  const shipmentParties = useWatch({
    control: formMethods.control,
    name: 'shipmentParties',
  });

  const getFormStatus = (i: number) => {
    const { errors, touchedFields } = formMethods.formState;
    const incomplete = errors?.shipmentParties?.[i];
    const touched = touchedFields?.shipmentParties?.[i];

    if (incomplete && touched && i !== selectedShipmentParty) {
      return 'error';
    }
    if (incomplete) {
      return 'incomplete';
    }
    return 'complete';
  };

  const roleByName = useAppSelector(getShipmentRolesByName);

  const renderShippingParties = () => (
    <ShippingPartyListContainer>
      {shipmentParties.map((item: IShippingPartyForm, index: number) => (
        <Box
          key={item.role || index}
          hasError={getFormStatus(index) === 'error'}
          selected={selectedShipmentParty === index}
          onClick={() => setSelectedShipmentParty(index)}
        >
          <ShippingPartyListTitleWrapper
            $hasDescription={Boolean(item.address?.id) || Boolean(item.company?.id)}
          >
            <ShippingPartyListTitle>
              {roleByName[item.role]}
            </ShippingPartyListTitle>
            <FormStatus type={getFormStatus(index)} />
          </ShippingPartyListTitleWrapper>
          <ShippingPartyListDescription>
            {item.company?.name} {buildContactAddress(item.address)}
          </ShippingPartyListDescription>
        </Box>
      ))}
    </ShippingPartyListContainer>
  );

  return (
    <ShippingPartyListContainer>
      {renderShippingParties()}
    </ShippingPartyListContainer>
  );
};
