import React, {
  FC,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import FileDoneOutlined from '@ant-design/icons/FileDoneOutlined';
import WarningFilled from '@ant-design/icons/WarningFilled';

import themesColors from 'app-wrapper/view/themes/themesColors';
import {
  Divider as BaseDivider,
  CollapseBodyOnly,
  CollapseButton,
  Tooltip,
} from 'app-wrapper/view/components';
import {
  GapHorizontalContainerStyled,
  GapVerticalContainerStyled,
  YellowTag,
} from 'app-wrapper/view/guideline';
import { WaypointTypePortIcon, HazmatsSvg } from 'app-wrapper/view/icons';
import { BodyTextMediumSpan } from 'app-wrapper/view/guideline/Font';
import { FreightQuotaContentSchedulesDTM } from 'monetary/models/dtm';
import { DateDtm } from 'app-wrapper/models/dtm';

import {
  Wrap,
  ContentWrap,
  Row,
  Radio,
  IconWrap,
  StorageIcon,
  StorageIconWrap,
  CarrierShipIcon,
  Divider,
  Tag,
  TimeText,
  ExpandedData,
  CutoffsWrap,
  Cutoffs,
  CutoffText,
  CutoffTextDate,
  CutoffItem,
  ExpandContent,
  CutoffButton,
  CutoffWarningButton,
  TooltipContent,
} from './ScheduleOption.styled';

interface IScheduleOptionProps {
  isChosen: boolean;
  chooseSchedule: (scheduleId: number) => void;
  schedule: FreightQuotaContentSchedulesDTM;
  expandContent?: ReactNode;
}

export const ScheduleOption: FC<IScheduleOptionProps> = ({
  isChosen,
  chooseSchedule,
  schedule,
  expandContent,
}) => {
  const { t } = useTranslation();
  const {
    departureTime,
    arrivalTime,
    documentCutOff,
    hazmatCutOff,
    terminalCutOff,
  } = schedule;

  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = useCallback(() => setIsCollapsed((prev) => !prev), [setIsCollapsed]);

  const handleScheduleChoose = useCallback(() => {
    chooseSchedule(schedule.id as number);
  }, [chooseSchedule, schedule]);

  const documentCutOffDate = useMemo(() => (documentCutOff ? DateDtm.fromPlain(documentCutOff).getFormatDMMMHHmmWithOffset() : undefined), [documentCutOff]);
  const hazmatCutOffDate = useMemo(() => (hazmatCutOff ? DateDtm.fromPlain(hazmatCutOff).getFormatDMMMHHmmWithOffset() : undefined), [hazmatCutOff]);
  const terminalCutOffDate = useMemo(() => (terminalCutOff ? DateDtm.fromPlain(terminalCutOff).getFormatDMMMHHmmWithOffset() : undefined), [terminalCutOff]);
  const departureDate = useMemo(() => (departureTime ? DateDtm.fromPlain(departureTime) : undefined), [departureTime]);
  const arrivalDate = useMemo(() => (arrivalTime ? DateDtm.fromPlain(arrivalTime) : undefined), [arrivalTime]);

  const isCutoffWarningVisible = useMemo<boolean>(() => {
    const cutoffDoc = moment(documentCutOff);
    const dateNow = moment(Date());

    return cutoffDoc.diff(dateNow, 'day') <= 5;
  }, [documentCutOff]);

  const duration = useMemo(() => {
    if (!arrivalDate || !departureDate) {
      return 0;
    }

    return Math.floor(DateDtm.getDifBetweenDates(departureDate, arrivalDate).asDays());
  }, [arrivalDate, departureDate]);

  return (
    <Wrap
      isChosen={isChosen}
      onClick={handleScheduleChoose}
    >
      <ContentWrap>
        <Row>
          <Radio checked={isChosen} />

          <IconWrap>
            <CarrierShipIcon />
          </IconWrap>

          <TimeText>
            {t('ETD')}
            {' '}
            {departureDate?.getFormatDMMMHHmmWithOffset()}
          </TimeText>

          <Divider type="horizontal" />

          <Tag
            style={{
              minWidth: '80px',
            }}
            icon={<ClockCircleOutlined />}
          >
            {duration}
            {' '}
            {t('Days')}
          </Tag>

          <Divider type="horizontal" />

          <TimeText>
            {t('ETA')}
            {' '}
            {arrivalDate?.getFormatDMMMHHmmWithOffset()}
          </TimeText>

          <IconWrap>
            <StorageIconWrap>
              <StorageIcon />
            </StorageIconWrap>
          </IconWrap>

          <BaseDivider
            style={{
              height: '100%',
            }}
            type="vertical"
            dashed
          />

          <Tooltip
            placement="top"
            title={(
              <TooltipContent>
                <span>
                  {t('Port')}
                  {' '}
                  {terminalCutOffDate}
                </span>

                <span>
                  {t('Document')}
                  {' '}
                  {documentCutOffDate}
                </span>

                {hazmatCutOffDate ? (
                  <span>
                    {t('Dangerous Goods')}
                    {' '}
                    {hazmatCutOffDate}
                  </span>
                ) : null}
              </TooltipContent>
            )}
          >
            {isCutoffWarningVisible ? (
              <CutoffWarningButton
                icon={<WarningFilled />}
                type="default"
              >
                {t('Cutoffs')}
              </CutoffWarningButton>
            ) : (
              <CutoffButton
                icon={<InfoCircleOutlined />}
                type="default"
              >
                {t('Cutoffs')}
              </CutoffButton>
            )}
          </Tooltip>

          <CollapseButton onClick={toggleCollapse} show={isCollapsed} />
        </Row>

        <CollapseBodyOnly isCollapsed={isCollapsed}>
          <ExpandedData>
            <GapVerticalContainerStyled>
              <CutoffsWrap>
                <GapHorizontalContainerStyled width="fit-content">
                  <BodyTextMediumSpan>
                    {t('Cutoffs')}
                  </BodyTextMediumSpan>

                  {isCutoffWarningVisible ? (
                    <YellowTag>
                      {t('Soon')}
                    </YellowTag>
                  ) : null}
                </GapHorizontalContainerStyled>

                <Cutoffs>
                  <CutoffItem>
                    <WaypointTypePortIcon color={themesColors.secondaryDot45} />

                    <CutoffText>
                      {t('Port')}

                      <CutoffTextDate>
                        {terminalCutOffDate}
                      </CutoffTextDate>
                    </CutoffText>
                  </CutoffItem>

                  <CutoffItem isWarning={isCutoffWarningVisible}>
                    {isCutoffWarningVisible ? <WarningFilled /> : <FileDoneOutlined />}

                    <CutoffText
                      style={{
                        ...(isCutoffWarningVisible ? {
                          color: themesColors.characterBrandingWarning,
                        } : {}),
                      }}
                    >
                      {t('Documents')}

                      <CutoffTextDate
                        style={{
                          ...(isCutoffWarningVisible ? {
                            color: themesColors.characterBrandingWarning,
                          } : {}),
                        }}
                      >
                        {documentCutOffDate}
                      </CutoffTextDate>
                    </CutoffText>
                  </CutoffItem>

                  {hazmatCutOffDate ? (
                    <CutoffItem>
                      <HazmatsSvg fill={themesColors.secondaryDot45} />

                      <CutoffText>
                        {t('Dangerous Goods')}

                        <CutoffTextDate>
                          {hazmatCutOffDate}
                        </CutoffTextDate>
                      </CutoffText>
                    </CutoffItem>
                  ) : null}
                </Cutoffs>
              </CutoffsWrap>
            </GapVerticalContainerStyled>

            <ExpandContent>
              {expandContent}
            </ExpandContent>
          </ExpandedData>
        </CollapseBodyOnly>
      </ContentWrap>
    </Wrap>
  );
};
