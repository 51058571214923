import { CSSProperties } from 'react';
import styled from 'styled-components';

export const GapHorizontalContainerStyled = styled.div.attrs((props: {
  gap?: string, width?: string; height?: string; disabled?: boolean, alignContent?: CSSProperties['alignContent'], justifyContent?: CSSProperties['justifyContent'], alignItems?: CSSProperties['alignItems']
}) => ({
  'data-class': 'GapHorizontalContainerStyled',
  gap: props.gap,
  width: props.width,
  height: props.height,
  disabled: props.disabled,
  alignContent: props.alignContent,
  justifyContent: props.justifyContent,
  alignItems: props.alignItems,
}))`
  display: flex;
  flex-direction: row;
  gap: ${({ gap }) => (gap || '8px')};
  ${({ width }) => (width ? `width: ${width};` : 'width: 100%;')}
  ${({ height }) => (height ? `height: ${height};` : '')}
  ${({ alignContent }) => (alignContent ? `align-content: ${alignContent};` : '')}
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems};` : '')}
  justify-content: ${({ justifyContent }) => (justifyContent || 'flex-start')};
`;
