import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { RoutingDetailsComponent } from './RoutingDetails.component';

export const RoutingDetails = memo(({ scheduleId }: { scheduleId: number }) => {
  const { origin, freight, destination } = useSelector(R.selectors.bookingWizard.getScheduleSchemaById(scheduleId));

  return (
    <RoutingDetailsComponent
      origin={origin}
      freight={freight}
      destination={destination}
    />
  );
});
