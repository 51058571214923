import React, { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import {
  Title,
  GapVerticalContainerStyled,
  Modal,
} from 'app-wrapper/view/guideline';

import { SmallTextFootnoteDescriptionSpan } from 'app-wrapper/view/guideline/Font';
import { Input } from 'app-wrapper/view/components';

const MAX_LENGTH = 100;

interface IEditModalProps {
  isPending: boolean;
  onClose: () => void;
  onOk: (shipmentId: string) => void;
  value?: string;
  onChange: (mblNumber: string) => void;
  title: string;
  label: string;
}

export const EditModal: FC<IEditModalProps> = observer(({
  title, label, value, onClose, onOk, onChange, isPending,
}) => {
  const { t } = useTranslation();
  const { shipmentId = '' } = useParams();

  const handleOk = useCallback(() => {
    onOk(shipmentId);
  }, [onOk, shipmentId]);

  const handleOnChange = useCallback((e) => {
    onChange(e.target.value);
  }, [onChange]);

  return (
    <Modal
      visible
      onCancel={onClose}
      onOk={handleOk}
      okText={t('Save')}
      cancelText={t('Close')}
      okButtonProps={{
        disabled: isPending || (!!value && !value.length),
        loading: isPending,
      }}
      cancelButtonProps={{
        disabled: isPending,
      }}
    >
      <GapVerticalContainerStyled>
        <Title>
          {title}
        </Title>

        <SmallTextFootnoteDescriptionSpan>
          {label} *
        </SmallTextFootnoteDescriptionSpan>

        <Input
          data-class="modal-edit-reference-input"
          value={value}
          onChange={handleOnChange}
          maxLength={MAX_LENGTH}
        />
      </GapVerticalContainerStyled>
    </Modal>
  );
});
