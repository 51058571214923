import { divFlex } from 'monetary/view/pages/QuotaRequests/components/Quotas/components/Cards/Cards.styled';
import styled from 'styled-components';

export const FiltersContainer = styled.div.attrs({
  'data-class': 'FiltersContainer',
})`
  ${divFlex}
  width: 26%;
  height: 100%;
`;
