import React from 'react';
import { observer } from 'mobx-react-lite';

import { UC as appUC } from 'app-wrapper/controllers';
import { ManualConfirmationComponent } from './ManualConfirmation.component';

const ManualConfirmationContainer = observer(() => (
  <ManualConfirmationComponent
    onClose={appUC.drawer.closeDrawer}
  />
));

export { ManualConfirmationContainer as ManualConfirmation };
