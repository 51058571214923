import React from 'react';

import { useAppSelector } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentBillingComponent } from './ShipmentBilling.component';

const ShipmentBilling = () => {
  const isLoading = useAppSelector(R.selectors.shipmentBilling.getIsLoading);
  const error = useAppSelector(R.selectors.shipmentBilling.getError);
  const data = useAppSelector(R.selectors.shipmentBilling.getDataForTable);
  const totalData = useAppSelector(R.selectors.shipmentBilling.getTotalValues);
  const permissions = useAppSelector(R.selectors.shipment.getShipmentChargesPermissions);
  const defaultActiveKey = useAppSelector(R.selectors.shipmentBilling.getDefaultActiveKey);

  return (
    <ShipmentBillingComponent
      data={data}
      isLoading={isLoading}
      error={error}
      fetchData={UC.shipmentBillingController.loadData}
      onStart={UC.shipmentBillingController.onStart}
      totalData={totalData}
      resetData={UC.shipmentBillingController.resetData}
      permissions={permissions}
      defaultActiveKey={defaultActiveKey}
    />
  );
};

export { ShipmentBilling };
