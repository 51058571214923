import React from 'react';

import { ShipmentDrawerContent } from 'shipment-operations/constants';
import { R } from 'shipment-operations/repository';
import { useAppSelector } from 'app-wrapper/hooks';
import { UC } from 'shipment-operations/controllers';

import { HeaderComponent } from 'shipment-operations/view/components/ActiveCharge';

const HeaderContainer = () => {
  const applyTo = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeApplyTo);
  const measureBy = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeMeasureBy);
  const designation = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeDesignation);
  const currency = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeCurrency);
  const dropdownCharges = useAppSelector(R.selectors.shipmentActiveCharge.getChargesForDropdownAccessorial);
  const container = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeSelectedContainerType);
  const companies = useAppSelector(R.selectors.shipmentActiveCharge.getCompaniesForSelect);

  const selectedChargeTitle = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeSelectedChargeTitle);

  const chargeError = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeErrorCharge);
  const containersTree = useAppSelector(R.selectors.shipmentActiveCharge.preparedTreeData);
  const containersError = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeSelectedContainersError);
  const errors = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeErrors);
  const containerType = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeSelectedContainerType);

  const mode = useAppSelector(R.selectors.shipmentDrawer.getContentType);

  const selectedCustomerCompanyValue = useAppSelector(R.selectors.shipmentActiveCharge.getSelectedCustomerCompany);
  const selectedVendorCompanyValue = useAppSelector(R.selectors.shipmentActiveCharge.getSelectedVendorCompany);

  const isDisableDesignation = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeDisableDesignation);

  return (
    <HeaderComponent
      applyTo={applyTo}
      measureBy={measureBy}
      designation={designation}
      onChangeDropDown={UC.shipmentActiveCharge.setActiveChargeValue}
      currency={currency}
      dropdownCharges={dropdownCharges}
      setCharge={UC.shipmentActiveCharge.setActiveChargeSelectedCharge}
      selectedChargeTitle={selectedChargeTitle}
      chargeError={chargeError}
      containersTree={containersTree}
      selectContainer={UC.shipmentActiveCharge.setActiveChargeContainer}
      containersError={containersError}
      errors={errors}
      mode={mode}
      container={container}
      containerType={containerType}
      modeType={ShipmentDrawerContent.SHIPMENT_ACCESSORIAL_ADD_CHARGE}
      companies={companies}
      customerValue={selectedCustomerCompanyValue?.name}
      vendorValue={selectedVendorCompanyValue?.name}
      isDisableDesignation={isDisableDesignation}
    />
  );
};

export { HeaderContainer as Header };
