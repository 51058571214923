import { v4 as uuidv4 } from 'uuid';

import { apiWorker } from 'app-wrapper/repository/utilsServices';

import {
  IGetRFQDoorAutocompleteResponse,
  ISessionRFQDoorAutocomplete,
  IGetRFQLocationServiceByPlaceIdResponse,
  LocationsServiceResponse,
} from 'monetary/models/contracts';
import {
  FreightDoorsAutocompleteDTM,
  FreightDoorsByPlaceIdAddressDTM,
  FreightDoorsByPlaceIdDTM,
  FreightSelectFieldCoordinatesDTM,
  FreightSelectFieldCountryDTM,
  IFreightDoorsByPlaceIdDTM,
  ISessionFreightDoorsByPlaceId,
} from 'monetary/models/dtm';

import { EPlaceTypes } from 'monetary/constants';
import { IGetRFQDoorAutocompleteBeProps, IGetRFQDoorAutocompleteBeResponse, LocationsServiceDTM } from 'monetary/models/dtm/Quotas';
import { IGetQueryParamsDTM } from 'shipment-operations/models/dtm';

export class LocationService {
  constructor() {
    try {
      const script = window.document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&language=en`;

      window.document.head.appendChild(script);
    } catch (e) {
      console.error('HTML tag "head" not found');
    }
  }

  private autocompleteSessionToken: undefined | google.maps.places.AutocompleteSessionToken;

  private locationServicePlace = '/location-service/api/v1/places';

  private locationService = '/location-service/api/v1/locations';

  private locationServiceAutocomplete = '/location-service/api/v1/places/autocomplete';

  private googleAutocompleteSessionStorage = 'getRFQDoorAutocomplete'

  private googlePlaceIdSessionStorage = 'googlePlaceIdSessionStorage'

  public getLocationService = async (params: IGetQueryParamsDTM) => {
    const {
      query, page = 1, size = 5, markOutsideRegions,
    } = params;

    let result: LocationsServiceDTM[] | null = null;
    let response: LocationsServiceResponse[] | null = null;

    try {
      const rawResponse = await apiWorker.requestGet<LocationsServiceResponse[]>(`${this.locationService}?query=${encodeURIComponent(query)}${markOutsideRegions ? '&markOutsideRegions=true' : ''}&function=PORT,RAIL_TERMINAL&page=${page}&size=${size}`);

      if (rawResponse.status !== 200) {
        throw new Error(' Response not ok');
      }

      response = rawResponse.data;

      if (response) {
        result = response.map((itemLocation) => LocationsServiceDTM.fromPlain({
          code: itemLocation.code,
          country: itemLocation.country
            ? FreightSelectFieldCountryDTM.fromPlain({
              code: itemLocation.country.code,
              name: itemLocation.country.name,
              forbidden: itemLocation.country.forbidden,
            })
            : undefined,
          subdivisionCode: itemLocation.subdivisionCode || undefined,
          subdivisionName: itemLocation.subdivisionName || undefined,
          subdivisionType: itemLocation.subdivisionType || undefined,
          locationCode: itemLocation.locationCode || undefined,
          locationName: itemLocation.locationName || undefined,
          coordinates: itemLocation.coordinates
            ? FreightSelectFieldCoordinatesDTM.fromPlain({
              lat: `${itemLocation?.coordinates?.lat || ''}`,
              lng: `${itemLocation?.coordinates?.lng || ''}`,
            })
            : undefined,
          iataCode: itemLocation.iataCode || undefined,
          timeZoneId: itemLocation.timeZoneId || undefined,
        }));
      }
    } catch (e) {
      throw new Error('RFQ: getLocationService, list getting error');
    }

    result?.forEach((item) => {
      const errors = item.validate();

      if (errors.length) {
        console.error('DTM valid RFQ: error', errors);
      }
    });

    return result;
  }

  public getRFQDoorAutocomplete = async (value: string, isSecondCall?: boolean) => {
    let status: string = '';
    let response: google.maps.places.AutocompletePrediction[]
      | null = null;
    let result: FreightDoorsAutocompleteDTM[] | null = null;

    try {
      const auto = new window.google.maps.places.AutocompleteService();

      if (!this.autocompleteSessionToken) {
        this.autocompleteSessionToken = new window.google.maps.places.AutocompleteSessionToken();
      }

      response = await new Promise((resolve, reject) => {
        auto.getPlacePredictions({
          input: value,
          componentRestrictions: { country: ['us', 'pl'] },
          types: [
            // 'premise',
            // 'street_address',
          ],
          sessionToken: this.autocompleteSessionToken,
        }, (responseGoogle, statusGoogle) => {
          if (statusGoogle === window.google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR) {
            console.error('log: responseGoogle', responseGoogle, statusGoogle);

            this.autocompleteSessionToken = new window.google.maps.places.AutocompleteSessionToken();

            reject(statusGoogle);
          }

          status = statusGoogle;
          resolve(responseGoogle);
        });
      });
    } catch (e) {
      console.error('FETCH RFQ: getRFQDoorAutocomplete', e);

      if (e === window.google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR) {
        if (!isSecondCall) {
          result = await this.getRFQDoorAutocomplete(value, true) as FreightDoorsAutocompleteDTM[] | null;
          return result;
        }
      }

      throw e;
    }

    if (response && status === window.google.maps.places.PlacesServiceStatus.OK) {
      result = response?.map((item) => FreightDoorsAutocompleteDTM.fromPlain({
        description: item.description,
        placeId: item.place_id,
        id: item.id,
      })) || [];
    }

    result?.forEach((item) => {
      const errors = item.validate();

      if (errors.length) {
        console.error('DTM valid RFQ: error', errors);
      }
    });

    // TODO: Next tasks need create a new service
    // const auto2 = new window.google.maps.places.PlacesService(document.createElement('div')).getDetails({
    //   placeId: result?.[0].placeId || '',
    //   fields: ['opening_hours', 'utc_offset_minutes'],
    // }, (place, status2) => {
    //   console.log('log: auto2', place, status2);
    //   if (status2 !== 'OK') return; // something went wrong
    //   const isOpenAtTime = place?.opening_hours?.isOpen(new Date('December 17, 2020 03:24:00'));
    //   if (isOpenAtTime) {
    //     // We know it's open.
    //   }

    //   const isOpenNow = place?.opening_hours?.isOpen();
    //   if (isOpenNow) {
    //     // We know it's open.
    //   }
    // });

    return result;
  }

  public getRFQDoorAutocompleteBe = async (props: IGetRFQDoorAutocompleteBeProps) => {
    const {
      value,
      sessionToken,
      country,
      types,
    } = props;
    let result: FreightDoorsAutocompleteDTM[] | null = null;

    // responseRaw = await apiWorker.requestGetBySchema(`${this.locationServiceAutocomplete}` as '/api/v1/places/autocomplete'
    const responseRaw = await apiWorker.requestGet<IGetRFQDoorAutocompleteBeResponse[]>(`${this.locationServiceAutocomplete}`, {
      params: {
        input: value,
        sessionToken,
        country: country || ['US', 'PL'],
        type: types?.slice(0, 1)?.[0],
      },
    });

    result = responseRaw.data.map((item) => FreightDoorsAutocompleteDTM.fromPlain({
      description: item.description,
      placeId: item.id,
      id: uuidv4(),
    }));

    return result;
  }

  public getSessionRFQDoorAutocomplete = (value: string) => {
    const storageStore = window.sessionStorage.getItem(this.googleAutocompleteSessionStorage);
    const storage: ISessionRFQDoorAutocomplete | null = storageStore ? JSON.parse(storageStore) : null;

    if (storage?.[value]) {
      if (storage[value].maxAge > Date.now()) {
        return storage[value].result;
      }

      throw new Error('Value is expired');
    }

    return null;
  }

  public setSessionRFQDoorAutocomplete = (newValue: string, newResult: IGetRFQDoorAutocompleteResponse[]) => {
    const prevStorageStore = window.sessionStorage.getItem(this.googleAutocompleteSessionStorage);
    const prevStorage: ISessionRFQDoorAutocomplete | null = prevStorageStore ? JSON.parse(prevStorageStore) : null;

    let newStorage: ISessionRFQDoorAutocomplete = {};

    if (prevStorage) {
      newStorage = {
        ...prevStorage,
        [newValue]: {
          result: newResult,
          maxAge: Date.now() + 24 * 60 * 60 * 1000,
        },
      };
    } else {
      newStorage = {
        [newValue]: {
          result: newResult,
          maxAge: Date.now() + 24 * 60 * 60 * 1000,
        },
      };
    }

    window.sessionStorage.setItem(this.googleAutocompleteSessionStorage, JSON.stringify(newStorage));
  }

  public deleteSessionRFQDoorAutocomplete = (value: string) => {
    const prevStorageStore = window.sessionStorage.getItem(this.googleAutocompleteSessionStorage);
    const prevStorage: ISessionRFQDoorAutocomplete | null = prevStorageStore ? JSON.parse(prevStorageStore) : null;

    let newStorage: ISessionRFQDoorAutocomplete = {};

    if (prevStorage) {
      newStorage = {
        ...prevStorage,
      };
    }

    if (prevStorage?.[value]) {
      newStorage = {
        ...prevStorage,
      };

      delete newStorage[value];
    }

    window.sessionStorage.setItem(this.googleAutocompleteSessionStorage, JSON.stringify(newStorage));
  }

  public getRFQDoorByPlaceId = async (placeId: string) => {
    let status: string = '';
    let result: FreightDoorsByPlaceIdDTM | undefined;

    await new Promise((resolve, reject) => {
      new window.google.maps.places.PlacesService(document.createElement('div')).getDetails({
        placeId: placeId || '',
        fields: ['opening_hours', 'utc_offset_minutes', 'address_components', 'formatted_address', 'place_id'],
        // fields: ['ALL'],
      }, (place, statusGoogle) => {
        status = statusGoogle;
        if (status === window.google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR || status !== 'OK') {
          reject(status);
        }

        result = FreightDoorsByPlaceIdDTM.fromPlain({
          addressComponents: place.address_components?.map((item) => FreightDoorsByPlaceIdAddressDTM.fromPlain({
            longName: item.long_name,
            shortName: item.short_name,
            types: item.types,
          })) || [],
          placeId,
          fullAddress: place.formatted_address,
          utcOffset: place?.utc_offset_minutes,
          types: place?.types,
        });

        resolve(result);
      });
    });

    return result;
  }

  public getRFQLocationServiceByPlaceId = async (placeId: string) => {
    const addressComponents: FreightDoorsByPlaceIdAddressDTM[] = [];

    const responseRaw = await apiWorker.requestGet<IGetRFQLocationServiceByPlaceIdResponse>(`${this.locationServicePlace}/${placeId}`);

    const response = responseRaw.data;

    if (response?.address) {
      addressComponents.push(FreightDoorsByPlaceIdAddressDTM.fromPlain({
        longName: response?.address,
        shortName: response?.address,
        types: [EPlaceTypes.street_address],
      }));
    }
    if (response?.city) {
      addressComponents.push(FreightDoorsByPlaceIdAddressDTM.fromPlain({
        longName: response?.city,
        shortName: response?.city,
        types: [EPlaceTypes.locality],
      }));
    }
    if (response?.country) {
      addressComponents.push(FreightDoorsByPlaceIdAddressDTM.fromPlain({
        longName: response?.country?.name,
        shortName: response?.country?.code,
        types: [EPlaceTypes.country],
      }));
    }
    if (response?.postalCode) {
      addressComponents.push(FreightDoorsByPlaceIdAddressDTM.fromPlain({
        longName: response?.postalCode,
        shortName: response?.postalCode,
        types: [EPlaceTypes.postal_code],
      }));
    }

    const result = FreightDoorsByPlaceIdDTM.fromPlain({
      addressComponents,
      placeId,
      fullAddress: response?.address,
      timezoneId: response?.timeZoneId,
    });

    return result;
  }

  public getSessionRFQDoorByPlaceId = (value: string) => {
    const storageStore = window.sessionStorage.getItem(this.googlePlaceIdSessionStorage);
    const storage: ISessionFreightDoorsByPlaceId | undefined = storageStore ? JSON.parse(storageStore) : undefined;

    if (storage?.[value]) {
      if (storage[value].maxAge > Date.now()) {
        return FreightDoorsByPlaceIdDTM.fromPlain(storage[value].result);
      }

      throw new Error('Value is expired');
    }

    return undefined;
  }

  public setSessionRFQDoorByPlaceId = (newValue: string, newResult: IFreightDoorsByPlaceIdDTM) => {
    const prevStorageStore = window.sessionStorage.getItem(this.googlePlaceIdSessionStorage);
    const prevStorage: ISessionFreightDoorsByPlaceId | undefined = prevStorageStore ? JSON.parse(prevStorageStore) : undefined;

    let newStorage: ISessionFreightDoorsByPlaceId | undefined;

    if (prevStorage) {
      newStorage = {
        ...prevStorage,
        [newValue]: {
          result: newResult,
          maxAge: Date.now() + 24 * 60 * 60 * 1000,
        },
      };
    } else {
      newStorage = {
        [newValue]: {
          result: newResult,
          maxAge: Date.now() + 2 * 24 * 60 * 60 * 1000,
        },
      };
    }

    window.sessionStorage.setItem(this.googlePlaceIdSessionStorage, JSON.stringify(newStorage));
  }

  public deleteSessionRFQDoorByPlaceId = (value: string) => {
    const prevStorageStore = window.sessionStorage.getItem(this.googlePlaceIdSessionStorage);
    const prevStorage: ISessionFreightDoorsByPlaceId | undefined = prevStorageStore ? JSON.parse(prevStorageStore) : undefined;

    let newStorage: ISessionFreightDoorsByPlaceId = {};

    if (prevStorage) {
      newStorage = {
        ...prevStorage,
      };
    }

    if (prevStorage?.[value]) {
      newStorage = {
        ...prevStorage,
      };

      delete newStorage[value];
    }

    window.sessionStorage.setItem(this.googlePlaceIdSessionStorage, JSON.stringify(newStorage));
  }
}
