import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CheckOutlined from '@ant-design/icons/CheckOutlined';

import {
  CustomSeparator,
  EmptyNumber,
  Number,
  SecondNumber,
  StepWrapper,
  Title,
  Wrapper,
  NumberDisabled,
} from './StepsHeader.styled';

interface StepsHeaderComponentProps {
  step: number;
  isCustomerOrg: boolean;
}

export const StepsHeaderComponent: FC<StepsHeaderComponentProps> = ({ step, isCustomerOrg }) => {
  const { t } = useTranslation();

  // starting from 2 since soon we will add quote step which will be first
  return (
    <Wrapper>
      <StepWrapper>
        {step >= 2 ? (
          <>
            {step === 2 ? (
              <Number>
                1
              </Number>
            ) : (
              <EmptyNumber>
                <CheckOutlined style={{ color: '#6C79DE' }} />
              </EmptyNumber>
            )}
          </>
        ) : (
          <NumberDisabled>1</NumberDisabled>
        )}

        <Title isStepPassed={step >= 2}>
          {t('Schedules')}
        </Title>
      </StepWrapper>

      <CustomSeparator isStepPassed={step >= 3} />

      <StepWrapper>
        {step >= 3 ? (
          <>
            {step === 3 ? (
              <Number>
                2
              </Number>
            ) : (
              <EmptyNumber>
                <CheckOutlined style={{ color: '#6C79DE' }} />
              </EmptyNumber>
            )}
          </>
        ) : (
          <NumberDisabled>2</NumberDisabled>
        )}

        <Title isStepPassed={step >= 3}>
          {t('Booking Details')}
        </Title>
      </StepWrapper>

      {isCustomerOrg ? (
        <>
          <CustomSeparator isStepPassed={step === 4} />

          <StepWrapper>
            <SecondNumber isStepPassed={step === 4}>3</SecondNumber>
            <Title isStepPassed={step === 4}>
              {t('Review and Confirm')}
            </Title>
          </StepWrapper>
        </>
      ) : null}
    </Wrapper>
  );
};
