import styled, { css } from 'styled-components';
import Button from 'antd/es/button';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { Input } from 'app-wrapper/view/components';

const titleCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
`;

const secondTitleCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
`;

const inputCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  height: 32px;
`;

const blockNameCss = css`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
`;

const itemRowCss = css`
  margin-top: 16px;
`;

const inputHalfCss = css`
  width: calc(50% - 2px);
`;

const inputTopMarginCss = css`
  margin-top: 5px;
`;

export const CBDividerMargins = {
  margin: '0',
  marginTop: '16px',
};

export const ContactsContactPersonBlock = styled.div.attrs({
  'data-class': 'ContactsContactPersonBlock',
})`
  ${divFlex}
  ${itemRowCss}
  flex-direction: column;
  width: 100%;
`;

export const CBContentBodyFormPrimary = styled.div.attrs({
  'data-class': 'CBContentBodyFormPrimary',
})`
  ${divFlex}
  width: 58px;
  height: 22px;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.themesColors.polarGreen1};
  color: ${({ theme }) => theme.themesColors.polarGreen7};
  font-size: 12px;
  font-weight: 400;
  border: 1px solid ${({ theme }) => theme.themesColors.polarGreen3};
  margin-left: 8px;
  border-radius: 3px;
`;

export const ContactsContactPersonBlockNameWrapper = styled.div.attrs({
  'data-class': 'ContactsContactPersonBlockNameWrapper',
})`
  ${divFlex}
  ${blockNameCss}
  width: 100%;
`;

export const ContactsContactPersonBlockName = styled.div.attrs({
  'data-class': 'ContactsContactPersonBlockName',
})`
  ${divFlex}
  ${blockNameCss}
  width: 50%;
`;

export const ContactsContactPersonBlockDeleteButton = styled(Button).attrs({
  'data-class': 'ContactsContactPersonBlockDeleteButton',
})`
  ${divFlex}
  ${titleCss}

  height: 22px;
  align-items: center;
  justify-content: center;
  width: 82px;
  right: 0;
  margin-left: auto;
`;

// First

export const ContactsContactPersonBlockFirst = styled.div.attrs({
  'data-class': 'ContactsContactPersonBlockFirst',
})`
  ${divFlex}
  ${itemRowCss}
  flex-direction: column;
  width: 100%;
`;

export const ContactsContactPersonBlockFirstInputDiv = styled.div.attrs({
  'data-class': 'ContactsContactPersonBlockFirstInputDiv',
})`
  ${divFlex}
  width: 100%;
`;

export const ContactsContactPersonBlockFirstInputWrapper = styled.div.attrs({
  'data-class': 'ContactsContactPersonBlockFirstInputWrapper',
})`
  ${divFlex}
  ${inputHalfCss}
  flex-direction: column;
`;

export const ContactsContactPersonBlockFirstInputWrapperRight = styled.div.attrs({
  'data-class': 'ContactsContactPersonBlockFirstInputWrapperRight',
})`
  ${divFlex}
  ${inputHalfCss}

  flex-direction: column;
  margin-left: 4px;
`;

export const ContactsContactPersonBlockFirstTitle = styled.div.attrs({
  'data-class': 'ContactsContactPersonBlockFirstTitle',
})`
  ${divFlex}
  ${titleCss}
  width: 100%;
`;

export const ContactsContactPersonBlockFirstInput = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
  'data-class'?: string,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': props['data-class'] || 'ContactsContactPersonBlockFirstInput',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}

  width: 100%;

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

// Second

export const ContactsContactPersonBlockSecond = styled.div.attrs({
  'data-class': 'ContactsContactPersonBlockSecond',
})`
  ${divFlex}
  ${itemRowCss}
  flex-direction: column;
  width: 100%;
  margin-top: 13px;
`;

export const ContactsContactPersonBlockSecondTitleDiv = styled.div.attrs({
  'data-class': 'ContactsContactPersonBlockSecondTitleDiv',
})`
  ${divFlex}
  ${titleCss}
  width: 100%;
`;

export const ContactsContactPersonBlockSecondTitle = styled.div.attrs({
  'data-class': 'ContactsContactPersonBlockSecondTitle',
})`
  ${divFlex}
  ${titleCss}
`;

export const ContactsContactPersonBlockSecondTitleSecond = styled.div.attrs({
  'data-class': 'ContactsContactPersonBlockSecondTitleSecond',
})`
  ${divFlex}
  ${titleCss}
  ${secondTitleCss}
  margin-left: 3px;
`;

export const ContactsContactPersonBlockSecondInputDiv = styled.div.attrs({
  'data-class': 'ContactsContactPersonBlockSecondInputDiv',
})`
  ${divFlex}
  width: 100%;
  margin-top: 4px;
`;

export const ContactsContactPersonBlockSecondPhoneWrapper = styled.div.attrs({
  'data-class': 'ContactsContactPersonBlockSecondPhoneWrapper',
})`
  ${divFlex}
  ${inputHalfCss}
  flex-direction: column;
`;

export const ContactsContactPersonBlockSecondPhone = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
  'data-class'?: string,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': props['data-class'] || 'ContactsContactPersonBlockSecondPhone',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}

  width: 100%;

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

export const ContactsContactPersonBlockSecondAPhoneWrapper = styled.div.attrs({
  'data-class': 'ContactsContactPersonBlockSecondAPhoneWrapper',
})`
  ${divFlex}
  ${inputHalfCss}
  flex-direction: column;

  margin-left: 4px;
`;

export const ContactsContactPersonBlockSecondAPhone = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'ContactsContactPersonBlockSecondAPhone',
}))`
  ${divFlex}
  ${inputCss}
  width: 100%;
  margin-top: 4px;

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

// Third

export const ContactsContactPersonBlockFourth = styled.div.attrs({
  'data-class': 'ContactsContactPersonBlockFourth',
})`
  ${divFlex}
  ${itemRowCss}
  flex-direction: column;
  width: 100%;

  button {
    width: 179px;
    ${titleCss}
    font-size: 14px;
  }
  svg {
    color: ${({ theme }) => theme?.themesColors?.neutralBranding7};
  }
`;
