import styled from 'styled-components';

import { BodyTextRegularSpan } from 'app-wrapper/view/guideline/Font';
import { GapHorizontalContainerStyled } from 'app-wrapper/view/guideline';

export const Wrap = styled(GapHorizontalContainerStyled)`
  cursor: pointer;
  width: 100%;
  min-height: 40px;
  max-height: 40px;
  padding: 9px 12px;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  align-items: center;
`;

export const Placeholder = styled(BodyTextRegularSpan)`
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const TextWrap = styled.div`
  width: 100%;
  height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
