import { divFlex } from 'app-wrapper/view/themes/mixin';
import styled from 'styled-components';

export const ContentSectionContainer = styled.div.attrs((props: {
  height?: string
}) => ({
  height: props.height,
  'data-class': 'ContentSectionContainer',
}))`
  ${divFlex}
  width: 100%;
  ${({ height }) => (height && `height: ${height};`)};
`;
