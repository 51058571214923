import AntModal from 'antd/es/modal';
import styled from 'styled-components';

import themesColors from 'app-wrapper/view/themes/themesColors';

export const Modal = styled(AntModal)`
  &.ant-modal {
    width: 416px !important;
  }

  .ant-modal-header {
    padding: 0px;
    border-bottom: 0px;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #202C3C;
  }

  .ant-modal-body {
    padding: 32px 32px 0px 32px;
  }

  .ant-modal-footer {
    padding: 24px 32px;
    border-top: 0;

    button {
      border-radius: 3px;
    }

    button:first-of-type {
      border: 1px solid rgba(222, 225, 233, 1);
      color: #202C3C;
    }

    button:last-of-type {
      border-color: ${themesColors.neutralBranding8} !important;
      background: ${themesColors.neutralBranding8} !important;
    }

    button:last-of-type:disabled {
      border-color: #d9d9d9;
      background: #f5f5f5;
    }
  }
`;
