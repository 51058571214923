import { plainToInstance, Type } from 'class-transformer';
import {
  IsBoolean,
  IsDefined,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';

import i18n from 'app-wrapper/i18n/i18n';
import { DateDtm } from 'app-wrapper/models/dtm';
import { BaseDTM } from 'proto/BaseDTM';

import { EOrganizationMemberRole, EOrganizationType } from 'user-management/constants';
import {
  DocumentDTM,
  IDocumentDTM,
  IShipmentConfirmationDTM,
  ShipmentConfirmationDTM,
} from 'shipment-operations/models/dtm';
import {
  OrganizationReviewDTM,
  IOrganizationReviewDTM,
  TeamDTM,
  ITeamDTM,
} from 'user-management/models/dtm';
import { EShipmentConfirmationTypes, OrganizationPaymentMethod } from 'shipment-operations/constants';

import { AddressDTM, IAddressDTM } from 'app-wrapper/models/dtm/BaseAddress.dtm';
import { TWebsiteUrl, WebsiteUrl } from '../WebsiteUrl';
import { BusinessRegistrationNumberDtm } from '../BusinessRegistrationNumber';
import { EOrganizationStatus } from './OrganizationStatus.dtm';
import { OrganizationMemberDTM, TOrganizationMemberDTM } from '../OrganizationMember.dtm';
import { OrganizationMembershipDtm } from '../OrganizationMembership';

export interface IOrganizationPaymentMethodDTM {
  id?: number;
  type: string;
  creditTerm?: number;
  creditLimit?: number;
}

export class OrganizationPaymentMethodDTM extends BaseDTM<IOrganizationPaymentMethodDTM> {
  @IsOptional()
  @IsNumber()
  id: number;

  @IsString()
  type: string;

  @IsOptional()
  @IsNumber()
  creditTerm: number;

  @IsOptional()
  @IsNumber()
  creditLimit: number;

  static createEmpty = () => plainToInstance(OrganizationPaymentMethodDTM, {
    id: 0,
    type: '',
    creditTerm: 0,
    creditLimit: 0,
  });

  getType = () => this.type.replace(/_/g, ' ').toLowerCase()

  getTypeSelect = () => {
    if (this.type === OrganizationPaymentMethod.PREPAYMENT) {
      return i18n.t('Prepayment');
    }

    if (this.type === OrganizationPaymentMethod.DEFERRED_PAYMENT) {
      return i18n.t('Deferred Payment');
    }

    return '';
  }

  getTypeReq = () => this.type.replace(/ /g, '_').toUpperCase()
}

export interface IOrganizationTradeReferenceDTM {
  companyName: string;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
}

export class OrganizationTradeReferenceDTM extends BaseDTM<IOrganizationTradeReferenceDTM> {
  @IsString()
  companyName: string;

  @IsString()
  firstName: string;

  @IsString()
  lastName: string;

  @IsString()
  email: string;

  @IsString()
  phone: string;

  public getFullName = () => `${this.firstName} ${this.lastName}`.trim();

  static createEmpty = () => plainToInstance(OrganizationTradeReferenceDTM, {
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });
}

export interface IOrganizationScheduleDTM {
  from: string
  to: string
}

export class OrganizationScheduleDTM extends BaseDTM<IOrganizationScheduleDTM> {
  @IsString()
  from: string;

  @IsString()
  to: string;
}

export type TOrganizationPrimaryContactDTM = {
  email: string
  enabled?: boolean
  sub?: string
  phone?: string
  firstName?: string
  lastName?: string
}

export class OrganizationPrimaryContactDTM extends BaseDTM<TOrganizationPrimaryContactDTM> {
  @IsDefined()
  @IsString()
  email: string

  @IsOptional()
  @IsBoolean()
  enabled?: boolean

  @IsOptional()
  @IsString()
  sub?: string

  @IsOptional()
  @IsString()
  phone?: string

  @IsOptional()
  @IsString()
  firstName?: string

  @IsOptional()
  @IsString()
  lastName?: string

  getFullName = () => {
    let fullName = this.firstName || '';

    fullName += fullName ? ` ${this.lastName}` : '';

    return fullName;
  }
}

export type TOrganizationCoverageAreasDTM = {
  type: string
  countryCode?: string
}

export class OrganizationCoverageAreasDTM extends BaseDTM<TOrganizationCoverageAreasDTM> {
  @IsDefined()
  @IsString()
  type: string

  @IsOptional()
  @IsString()
  countryCode?: string
}

export type TOrganizationSchedulesDTM = {
  MONDAY?: IOrganizationScheduleDTM
  TUESDAY?: IOrganizationScheduleDTM
  WEDNESDAY?: IOrganizationScheduleDTM
  THURSDAY?: IOrganizationScheduleDTM
  FRIDAY?: IOrganizationScheduleDTM
  SATURDAY?: IOrganizationScheduleDTM
  SUNDAY?: IOrganizationScheduleDTM
}

export class OrganizationSchedulesDTM extends BaseDTM<TOrganizationSchedulesDTM> {
  @IsOptional()
  @Type(() => OrganizationScheduleDTM)
  MONDAY?: OrganizationScheduleDTM

  @IsOptional()
  @Type(() => OrganizationScheduleDTM)
  TUESDAY?: OrganizationScheduleDTM

  @IsOptional()
  @Type(() => OrganizationScheduleDTM)
  WEDNESDAY?: OrganizationScheduleDTM

  @IsOptional()
  @Type(() => OrganizationScheduleDTM)
  THURSDAY?: OrganizationScheduleDTM

  @IsOptional()
  @Type(() => OrganizationScheduleDTM)
  FRIDAY?: OrganizationScheduleDTM

  @IsOptional()
  @Type(() => OrganizationScheduleDTM)
  SATURDAY?: OrganizationScheduleDTM

  @IsOptional()
  @Type(() => OrganizationScheduleDTM)
  SUNDAY?: OrganizationScheduleDTM
}

export type TOrganizationDTM = {
  id: number
  type?: `${EOrganizationType}`
  industry?: string
  customId?: string
  status?: `${EOrganizationStatus}`
  role?: `${EOrganizationMemberRole}`
  name: string,
  address: IAddressDTM
  phone: string
  phone2?: string
  note?: string;
  createdAt?: DateDtm;
  email?: string
  emailInformation?: string
  webUrl?: TWebsiteUrl
  businessRegistrationNumber: BusinessRegistrationNumberDtm
  memberships?: OrganizationMembershipDtm[]
  taxId?: string
  tradeReference?: IOrganizationTradeReferenceDTM;
  paymentMethod?: IOrganizationPaymentMethodDTM;
  paymentMethodForm?: IOrganizationPaymentMethodDTM;
  review?: IOrganizationReviewDTM
  coverageAreas?: TOrganizationCoverageAreasDTM[]
  primaryContact?: TOrganizationPrimaryContactDTM
  membersContacts?: TOrganizationMemberDTM[]
  membersContactsValueOld?: TOrganizationMemberDTM
  membersContactsValue?: TOrganizationMemberDTM
  accountManager?: TOrganizationMemberDTM
  salesManager?: TOrganizationMemberDTM
  accountTeam?: ITeamDTM
  accountHolderId?: number
  documents: IDocumentDTM[]
  confirmations?: IShipmentConfirmationDTM[]
  timeZoneId?: string
  schedule?: TOrganizationSchedulesDTM;
}

export class OrganizationDTM extends BaseDTM<TOrganizationDTM> {
  @IsDefined()
  @IsNumber()
  id: number

  @IsOptional()
  @IsEnum(EOrganizationType)
  type?: EOrganizationType

  @IsOptional()
  @IsString()
  industry?: string

  @IsOptional()
  @IsString()
  customId?: string

  @IsOptional()
  @IsEnum(EOrganizationStatus)
  status?: EOrganizationStatus

  @IsOptional()
  @IsEnum(EOrganizationMemberRole)
  role?: EOrganizationMemberRole

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationTradeReferenceDTM)
  tradeReference?: OrganizationTradeReferenceDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationPaymentMethodDTM)
  paymentMethod?: OrganizationPaymentMethodDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationPaymentMethodDTM)
  paymentMethodForm?: OrganizationPaymentMethodDTM;

  @IsOptional()
  @Type(() => OrganizationReviewDTM)
  @ValidateNested()
  review?: OrganizationReviewDTM;

  @IsDefined()
  @IsString()
  name: string

  @ValidateNested()
  @Type(() => AddressDTM)
  address: AddressDTM

  @IsString()
  phone: string

  @IsOptional()
  @IsString()
  phone2?: string

  @ValidateNested({ each: true })
  @Type(() => DocumentDTM)
  documents: DocumentDTM[]

  @IsOptional()
  @IsString()
  // TODO: disabled due to out of sync with the backend
  // @IsEmail()
  email?: string

  @IsOptional()
  @IsString()
  // TODO: disabled due to out of sync with the backend
  // @IsEmail()
  emailInformation?: string

  // @IsString()
  // @IsOptional()
  // note?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  createdAt?: DateDtm;

  @IsOptional()
  @ValidateNested()
  @Type(() => WebsiteUrl)
  webUrl: WebsiteUrl

  @IsDefined()
  @ValidateNested()
  @Type(() => BusinessRegistrationNumberDtm)
  businessRegistrationNumber: BusinessRegistrationNumberDtm

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => OrganizationMembershipDtm)
  memberships?: OrganizationMembershipDtm[]

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => OrganizationCoverageAreasDTM)
  coverageAreas?: OrganizationCoverageAreasDTM[]

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationPrimaryContactDTM)
  primaryContact?: OrganizationPrimaryContactDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationMemberDTM)
  membersContactsValueOld?: OrganizationMemberDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationMemberDTM)
  membersContactsValue?: OrganizationMemberDTM

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => OrganizationMemberDTM)
  membersContacts?: OrganizationMemberDTM[]

  @IsOptional()
  @IsNumber()
  accountHolderId?: number

  @IsOptional()
  @IsString()
  taxId: string

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => ShipmentConfirmationDTM)
  confirmations: ShipmentConfirmationDTM[]

  @IsOptional()
  @ValidateNested()
  @Type(() => TeamDTM)
  accountTeam?: TeamDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationMemberDTM)
  accountManager?: OrganizationMemberDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationMemberDTM)
  salesManager?: OrganizationMemberDTM;

  @IsOptional()
  @Type(() => OrganizationSchedulesDTM)
  schedule: OrganizationSchedulesDTM;

  @IsOptional()
  @IsString()
  timeZoneId: string

  getAddressLine = (fullCountryName?: string) => {
    let fullAddress = this.address.address1;

    if (this.address.address2) {
      fullAddress += `, ${this.address.address2}`;
    }

    fullAddress += `, ${this.address.city}`;

    if (this.address.state) {
      fullAddress += `, ${this.address.state}`;
    }

    fullAddress += `, ${this.address.postalCode}, ${fullCountryName || this.address.country}`;

    return fullAddress;
  }

  getAddressCityCountry = () => {
    let fullAddress = this.address.city;

    fullAddress += `, ${this.address.country}`;

    return fullAddress;
  }

  getPrimaryFullName = () => {
    let fullName = this.primaryContact?.firstName || '';

    fullName += ` ${this.primaryContact?.lastName}`;

    return fullName;
  }

  termsAndCondition = () => this.confirmations?.find((confirmation) => confirmation.type === EShipmentConfirmationTypes.TERMS_AND_CONDITIONS);

  isTermsAndConditionAccepted = () => Boolean(this.termsAndCondition());

  mustAccepTermsAndConditions = () => this.status === EOrganizationStatus.ACTIVE && this.role === EOrganizationMemberRole.CUSTOMER && !this.isTermsAndConditionAccepted();
}

export type TOrganizationFieldsErrorsFieldDTM = {
  id?: string // for list of items - to identify one from list
  isBlur?: boolean
  isFocus?: boolean
  message?: string
  isVisited?: boolean
  isTooltipVisible?: boolean
}

export class OrganizationFieldsErrorsFieldDTM extends BaseDTM<TOrganizationFieldsErrorsFieldDTM> {
  @IsString()
  id?: string

  @IsOptional()
  @IsBoolean()
  isBlur?: boolean

  @IsOptional()
  @IsBoolean()
  isFocus?: boolean

  @IsOptional()
  @IsString()
  message?: string

  @IsOptional()
  @IsBoolean()
  isVisited?: boolean

  @IsOptional()
  @IsBoolean()
  isTooltipVisible?: boolean
}

export type TOrganizationFieldsErrorsDTM = {
  id?: TOrganizationFieldsErrorsFieldDTM
  status?: TOrganizationFieldsErrorsFieldDTM
  role?: TOrganizationFieldsErrorsFieldDTM
  name?: TOrganizationFieldsErrorsFieldDTM
  type?: TOrganizationFieldsErrorsFieldDTM
  industry?: TOrganizationFieldsErrorsFieldDTM
  address1?: TOrganizationFieldsErrorsFieldDTM
  address2?: TOrganizationFieldsErrorsFieldDTM
  addressCity?: TOrganizationFieldsErrorsFieldDTM
  addressState?: TOrganizationFieldsErrorsFieldDTM
  addressPostalCode?: TOrganizationFieldsErrorsFieldDTM
  addressCountry?: TOrganizationFieldsErrorsFieldDTM
  phone?: TOrganizationFieldsErrorsFieldDTM
  phone2?: TOrganizationFieldsErrorsFieldDTM
  email?: TOrganizationFieldsErrorsFieldDTM
  primaryName?: TOrganizationFieldsErrorsFieldDTM
  primaryLastName?: TOrganizationFieldsErrorsFieldDTM
  primaryPhone?: TOrganizationFieldsErrorsFieldDTM
  primaryPhone2?: TOrganizationFieldsErrorsFieldDTM
  paymentType?: TOrganizationFieldsErrorsFieldDTM
  paymentCreditTerm?: TOrganizationFieldsErrorsFieldDTM
  paymentCreditLimit?: TOrganizationFieldsErrorsFieldDTM
  primaryEmail?: TOrganizationFieldsErrorsFieldDTM
  emailInformation?: TOrganizationFieldsErrorsFieldDTM
  webUrl?: TOrganizationFieldsErrorsFieldDTM
  businessRegistrationNumber?: TOrganizationFieldsErrorsFieldDTM
  memberships?: TOrganizationFieldsErrorsFieldDTM[]
  usci?: TOrganizationFieldsErrorsFieldDTM
  taxId?: TOrganizationFieldsErrorsFieldDTM
  MembersContactsValue?: TOrganizationFieldsErrorsFieldDTM
  coverageAreas?: TOrganizationFieldsErrorsFieldDTM
}

export class OrganizationFieldsErrorsDTM extends BaseDTM<TOrganizationFieldsErrorsDTM> {
  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  id?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  status?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  role?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  name?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  type?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  industry?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  address1?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  address2?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  address?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  addressCity?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  addressState?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  addressPostalCode?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  addressCountry?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  phone?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  phone2?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  email?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  primaryName?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  primaryLastName?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  primaryPhone?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  primaryPhone2?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  paymentType?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  paymentCreditTerm?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  paymentCreditLimit?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  primaryEmail?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  emailInformation?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  webUrl?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  businessRegistrationNumber?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  memberships?: OrganizationFieldsErrorsFieldDTM[]

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  usci?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  taxId?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  MembersContactsValue?: OrganizationFieldsErrorsFieldDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationFieldsErrorsFieldDTM)
  coverageAreas?: OrganizationFieldsErrorsFieldDTM
}
