import { createSelector } from 'reselect';
import moment from 'moment/moment';

import { RootState } from 'app-wrapper/store';
import { ENotificationCriticality } from 'app-wrapper/constants';
import { CommandCenterComponentTaskDTM, NotificationTaskContentDTM } from 'app-wrapper/models/dtm';
import { ActionLink } from 'app-wrapper/enums';

const localState = (state: RootState) => state.rootOverview;

const getOverview = createSelector(
  localState,
  (state) => state,
);

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getStep = createSelector(
  localState,
  (state) => state.step,
);

const getHasOwnRequestedQuotas = createSelector(
  localState,
  (state) => state.hasOwnRequestedQuotas,
);

const getHasOwnBookedShipments = createSelector(
  localState,
  (state) => state.hasOwnBookedShipments,
);

const getIsUserOnboarded = createSelector(
  localState,
  (state) => state.isUserOnboarded,
);

const getIsUserInvited = createSelector(
  localState,
  (state) => state.isUserInvited,
);

const getTasks = createSelector(
  localState,
  (state) => state.tasks as NotificationTaskContentDTM[],
);

const getCountAllTasks = createSelector(
  localState,
  (state) => state.countAllTasks,
);

const getNotifications = createSelector(
  localState,
  (state) => state.notifications,
);

const getTrackerValue = createSelector(
  localState,
  (state) => state.trackerValue,
);

const getTrackerOptions = createSelector(
  localState,
  (state) => state.trackerOptions,
);

const getShipmentByIdStats = createSelector(
  localState,
  (state) => state.shipmentByIdStats,
);

const getTasksStats = createSelector(
  localState,
  (state) => state.tasksStats,
);

const getDatePriority = (date?: number): ENotificationCriticality => {
  if (!date) return ENotificationCriticality.LOW;

  const dateCreate = moment(date);
  const dateNow = moment(Date.now());

  const dateDiff = dateNow.endOf('day').unix() - dateCreate.endOf('day').unix();

  if (dateDiff === 0) {
    return ENotificationCriticality.HIGH;
  }

  if ((dateDiff * -1) < (4 * 24 * 60 * 60)) {
    return ENotificationCriticality.MEDIUM;
  }

  return ENotificationCriticality.LOW;
};

const getAllDatePriority = (date?: number): ENotificationCriticality => {
  if (!date) return ENotificationCriticality.LOW;

  const dateCreate = moment(date);
  const dateNow = moment(Date.now());

  const dateDiff = dateNow.endOf('day').unix() - dateCreate.endOf('day').unix();

  if (dateDiff >= 0) {
    return ENotificationCriticality.HIGH;
  }

  if ((dateDiff * -1) < (4 * 24 * 60 * 60)) {
    return ENotificationCriticality.MEDIUM;
  }

  return ENotificationCriticality.LOW;
};

const getAllDateWarning = (date?: number) => {
  if (!date) return false;

  const dateCreate = moment(date);
  const dateNow = moment(Date.now());

  const dateDiff = dateNow.endOf('day').unix() - dateCreate.endOf('day').unix();

  return dateDiff > 0;
};

const getTaskModule = (value?: string) => {
  if (value?.indexOf('_AND') !== -1) {
    return value?.replace(/_/g, ' ')?.toLocaleLowerCase();
  }

  return value?.replace(/_/g, ' & ')?.toLocaleLowerCase();
};

const getTasksToRender = createSelector(
  getTasks,
  (tasks) => tasks?.map((item) => (CommandCenterComponentTaskDTM.fromPlain({
    id: `${item.id}`,
    customId: item.customId,
    priority: item.criticality,
    title: item.title,
    description: item.description,
    dueDate: item.dueDate,
    datePriority: getAllDatePriority(item.dueDate?.date?.setMilliseconds(0)),
    dateWarning: getAllDateWarning(item.dueDate?.date?.setMilliseconds(0)),
    shipmentName: `${item?.metadata?.shipmentName || ''}`,
    rateRequestName: `${item.objectReference?.rateRequestId || ''}`,
    rateOrganizationIdName: `${item.objectReference?.organizationId || ''}`,
    shipmentType: `${item.objectReference?.type || ''}`,
    rateRequestType: 'Request',
    rateOrganizationType: 'Organization',
    module: getTaskModule(item?.domain),
    assignee: item.assignee,
    assigneeUsers: item.assigneeUsers,
    actionLink: item?.actions?.find((itemAction) => itemAction.type === ActionLink.LINK)?.link || '',
    shipmentId: item.metadata?.shipmentId ? +item.metadata?.shipmentId : undefined,
    taskType: item.metadata?.taskType,
  }))),
);

const getAllTasksToRender = createSelector(
  getTasks,
  (tasks) => tasks?.map((item) => (CommandCenterComponentTaskDTM.fromPlain({
    id: `${item.id}`,
    customId: item.customId,
    priority: item.criticality,
    title: item.title,
    description: item.description,
    dueDate: item.dueDate,
    datePriority: getAllDatePriority(item.dueDate?.date?.setMilliseconds(0)),
    dateWarning: getAllDateWarning(item.dueDate?.date?.setMilliseconds(0)),
    shipmentName: `${item?.metadata?.shipmentName || ''}`,
    shipmentType: `${item.objectReference?.type || ''}`,
    module: getTaskModule(item?.domain),
    assignee: item.assignee,
    assigneeUsers: item.assigneeUsers,
    actionLink: item?.actions?.find((itemAction) => itemAction.type === ActionLink.LINK)?.link || '',
    taskType: item.metadata?.taskType,
  }))),
);

const getNotificationsToRender = createSelector(
  getNotifications,
  (state): CommandCenterComponentTaskDTM[] => state?.map((item) => CommandCenterComponentTaskDTM.fromPlain({
    id: `${item.id}`,
    customId: item.customId,
    priority: item.criticality,
    title: item.title,
    description: item.description,
    dueDate: item.createdAt,
    datePriority: getDatePriority(item.dueDate?.date?.setMilliseconds(0)),
    dateWarning: false,
    shipmentName: `${item?.metadata?.shipmentName || ''}`,
    shipmentType: `${item.objectReference?.type || ''}`,
    shipmentId: item.objectReference?.shipmentId,
    module: getTaskModule(item?.domain),
    isViewed: !!item.view,
    viewedId: `${item.viewId || ''}`,
  })),
);

const getIsVisibleTermsAndConditionDrawer = createSelector(
  localState,
  (state) => state.isVisibleTermsAndConditionDrawer,
);

const getIsUserTheOnlyMember = createSelector(
  localState,
  (state) => state.isUserTheOnlyMember,
);

const getIsUserPrimary = createSelector(
  localState,
  (state) => state.isUserPrimary,
);

const getTrackerShipmentOptions = createSelector(
  localState,
  (state) => state.trackerShipmentOptions,
);

export const overviewSelectors = {
  getOverview,
  getIsLoading,
  getStep,
  getHasOwnRequestedQuotas,
  getHasOwnBookedShipments,
  getIsUserOnboarded,
  getIsUserInvited,
  getTasks,
  getCountAllTasks,
  getNotifications,
  getTrackerValue,
  getTrackerOptions,
  getShipmentByIdStats,
  getTasksStats,
  getTasksToRender,
  getAllTasksToRender,
  getNotificationsToRender,
  getIsVisibleTermsAndConditionDrawer,
  getIsUserTheOnlyMember,
  getIsUserPrimary,
  getTrackerShipmentOptions,
};
