import { OverviewShipmentStore } from 'app-wrapper/repository/mobxStores/OverviewShipmentStore';
import { AllRequestsStore } from 'monetary/repository/mobxStores/AllRequestsStore';
import { createContext, useContext } from 'react';

import { DrawerStore } from 'app-wrapper/repository/mobxStores/DrawerStore';
import { ISFDrawerStore } from 'app-wrapper/repository/mobxStores/ISFDrawerStore';
import { AdditionalServicesDrawerStore } from 'monetary/repository/mobxStores/AdditionalServicesDrawer';
import {
  EditSupplierScheduleStore,
  ShippingPartiesStore,
  ShipmentSchedulesStore,
  EditLoadingControlsDrawerStore,
  AdditionalServicesStore,
  ShipmentContainersStore,
  CompaniesStore,
  CreatedCompanyStore,
  ConfirmationBookingDetailsStore,
} from 'shipment-operations/repository/mobxStores';
import {
  ShipmentLoadingControlsStore,
} from 'shipment-operations/repository/mobxStores/ShipmentLoadingControlsStore';
import { ShipmentPaymentTermsStore } from 'shipment-operations/repository/mobxStores/ShipmentPaymentTerms';
import { UserEditProfileStore } from 'user-management/repository/mobxStores/UserEditProfile';
import { CustomerSalesStore } from 'user-management/repository/mobxStores/CustomerSales';
import { ContractsStore } from 'app-wrapper/repository/mobxStores/Contracts';
import { ShipmentCostChangesDrawerStore } from 'app-wrapper/repository/mobxStores/ShipmentCostChangesDrawerStore';

export interface MobxStoresInterface {
  overviewDepartingShipmentStore: OverviewShipmentStore,
  overviewArrivingShipmentStore: OverviewShipmentStore,
  overviewPendingConfirmationsShipmentStore: OverviewShipmentStore,
  userEditProfileStore: UserEditProfileStore,
  additionalServicesDrawerStore: AdditionalServicesDrawerStore,
  drawerStore: DrawerStore,
  shipmentServices: AdditionalServicesStore,
  customerSales: CustomerSalesStore,
  contracts: ContractsStore,
  shipmentCostChangesDrawer: ShipmentCostChangesDrawerStore,
  allRequestsStore: AllRequestsStore,
  editLoadingControlsDrawer: EditLoadingControlsDrawerStore,
  editSupplierScheduleStore: EditSupplierScheduleStore,
  shipmentLoadingControlsStore: ShipmentLoadingControlsStore,
  shipmentContainersStore: ShipmentContainersStore,
  shippingPartiesStore: ShippingPartiesStore,
  shipmentSchedulesStore: ShipmentSchedulesStore,
  isfDrawer: ISFDrawerStore,
  shipmentPaymentTermsStore: ShipmentPaymentTermsStore,
  companies: CompaniesStore,
  createdCompany: CreatedCompanyStore,
  confirmationBookingDetailsStore: ConfirmationBookingDetailsStore,
}
// @ts-ignore
export const MobxStoresContext = createContext<MobxStoresInterface>(null);

export const useMobxStore = () => useContext(MobxStoresContext);

export const resetMobxStore = (stores: MobxStoresInterface) => {
  Object.keys(stores).forEach((key) => {
    stores[key as keyof MobxStoresInterface].clear();
  });
};
