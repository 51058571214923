import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { DateDtm } from 'app-wrapper/models/dtm';

import { CountryDTM, ShipmentRouteDTM } from 'shipment-operations/models/dtm';
import { IShipmentTransportationLocationDataState } from 'shipment-operations/models/states';
import { ShipmentRoutesType } from 'shipment-operations/constants';

import { CountriesJson } from './countries';

const countries = Object.entries(CountriesJson).map(([code, name]) => (CountryDTM.fromPlain({
  code,
  name,
})));

const initialState: IShipmentTransportationLocationDataState = {
  isLoading: false,
  countriesList: countries,
};

export const shipmentTransportationLocationDataStore = createSlice({
  name: 'ShipmentTransportationLocationData',
  initialState,
  reducers: {
    setFullData: (state, action: PayloadAction<ShipmentRouteDTM>) => {
      state.id = action.payload.id;
      state.type = action.payload.type;
      state.terminalName = action.payload.terminalName;
      state.passCode = action.payload.passCode;
      state.address1 = action.payload.address1;
      state.address2 = action.payload.address2;
      state.address3 = action.payload.address3;
      state.address4 = action.payload.address4;
      state.city = action.payload.city;
      state.state = action.payload.state;
      state.postcode = action.payload.postcode;
      state.releaseDate = action.payload.releaseDate;
      // TODO: check and change this equal when API will be implemented
      state.country = state.countriesList.find((item) => item.code === action.payload.country);
    },
    setInitialData: (state, { payload }: PayloadAction<ShipmentRouteDTM>) => ({
      ...state,
      initialData: payload,
    }),
    setType: (state, action: PayloadAction<ShipmentRoutesType>) => {
      state.type = action.payload;
    },
    setTerminalName: (state, action: PayloadAction<string>) => {
      state.terminalNameError = undefined;
      state.terminalName = action.payload;
    },
    setTerminalNameError: (state, action: PayloadAction<Error>) => {
      state.terminalNameError = action.payload;
    },
    clearTerminalNameError: (state) => {
      state.terminalNameError = undefined;
    },
    setPassCode: (state, action: PayloadAction<string>) => {
      state.passCodeError = undefined;
      state.passCode = action.payload;
    },
    setPassCodeError: (state, action: PayloadAction<Error>) => {
      state.passCodeError = action.payload;
    },
    clearPassCodeError: (state) => {
      state.passCodeError = undefined;
    },
    setAddress1: (state, action: PayloadAction<string>) => {
      state.address1Error = undefined;
      state.address1 = action.payload;
    },
    setAddress1Error: (state, action: PayloadAction<Error>) => {
      state.address1Error = action.payload;
    },
    clearAddress1Error: (state) => {
      state.address1Error = undefined;
    },
    setAddress2: (state, action: PayloadAction<string>) => {
      state.address2Error = undefined;
      state.address2 = action.payload;
    },
    setAddress2Error: (state, action: PayloadAction<Error>) => {
      state.address2Error = action.payload;
    },
    clearAddress2Error: (state) => {
      state.address2Error = undefined;
    },
    setAddress3: (state, action: PayloadAction<string>) => {
      state.address3Error = undefined;
      state.address3 = action.payload;
    },
    setAddress3Error: (state, action: PayloadAction<Error>) => {
      state.address3Error = action.payload;
    },
    clearAddress3Error: (state) => {
      state.address3Error = undefined;
    },
    setAddress4: (state, action: PayloadAction<string>) => {
      state.address4Error = undefined;
      state.address4 = action.payload;
    },
    setAddress4Error: (state, action: PayloadAction<Error>) => {
      state.address4Error = action.payload;
    },
    clearAddress4Error: (state) => {
      state.address4Error = undefined;
    },
    setCity: (state, action: PayloadAction<string>) => {
      state.cityError = undefined;
      state.city = action.payload;
    },
    setCityError: (state, action: PayloadAction<Error>) => {
      state.cityError = action.payload;
    },
    clearCityError: (state) => {
      state.cityError = undefined;
    },
    setState: (state, action: PayloadAction<string>) => {
      state.stateError = undefined;
      state.state = action.payload;
    },
    setStateError: (state, action: PayloadAction<Error>) => {
      state.stateError = action.payload;
    },
    clearStateError: (state) => {
      state.stateError = undefined;
    },
    setPostcode: (state, action: PayloadAction<string>) => {
      state.postcodeError = undefined;
      state.postcode = action.payload;
    },
    setPostcodeError: (state, action: PayloadAction<Error>) => {
      state.postcodeError = action.payload;
    },
    clearPostcodeError: (state) => {
      state.postcodeError = undefined;
    },
    setCountry: (state, action: PayloadAction<CountryDTM | undefined>) => {
      state.countryError = undefined;
      state.country = action.payload;
    },
    setCountryError: (state, action: PayloadAction<Error>) => {
      state.countryError = action.payload;
    },
    clearCountryError: (state) => {
      state.countryError = undefined;
    },
    setReleaseDate: (state, { payload }: PayloadAction<DateDtm | undefined>) => ({
      ...state,
      releaseDateError: undefined,
      releaseDate: payload,
    }),
    setReleaseDateError: (state, action: PayloadAction<Error>) => {
      state.releaseDateError = action.payload;
    },
    clearReleaseDateError: (state) => {
      state.releaseDateError = undefined;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setReleaseDateCYEmpty: (state, action: PayloadAction<DateDtm | undefined>) => {
      state.releaseDateCYEmpty = action.payload;
    },
    clear: () => (initialState),
  },
});

export const shipmentTransportationLocationDataActions = shipmentTransportationLocationDataStore.actions;
export const shipmentTransportationLocationDataReducer = shipmentTransportationLocationDataStore.reducer;
