import styled from 'styled-components';

import { OverlayWrap as BaseOverlay } from 'monetary/view/components/QuotasSearchBar/components/OriginOverlay/OriginOverlay.styled';

export const OverlayWrap = styled(BaseOverlay)`
  position: relative;
  min-width: 428px;
  max-width: 428px;
  box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05);
`;

export const OverlayBottom = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  height: 200px;
  width: 100%;
`;

export const Overlay = styled.div.attrs({
  'data-class': 'FQFormRequest',
})`
  width: 100%;

  div[data-class="RAPWrap"], div[data-class="CInfPanelTitleWrap"] {
    margin-top: 16px;
  }

  div[data-class="RFQFormHeader"], div[data-class="CInfPanelTitleWrap"], div[data-class="AdditionalServicesServiceTitleWrap"] {
    padding-bottom: 0;
    box-shadow: none;
  }

  div[data-class="CInfCompanyContent"], div[data-class="AdditionalServicesServiceContent"] {
    margin-top: 8px;
  }

  div[data-class="CInfCompanySelectWrapper"] {
    max-width: 100%;
  }

  div[data-class="AdditionalServicesServiceTitleWrap"] {
    margin-top: 0;
  }
`;
