import styled, { css } from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { Input } from 'app-wrapper/view/components';

const inputCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  min-height: 32px;
`;

const inputTopMarginCss = css`
  margin-top: 4px;
`;

export const EditProfileOrganizationTitle = styled.div.attrs({
  'data-class': 'EditProfileOrganizationTitle',
})`
  ${divFlex}
  justify-content: start;
  align-items: center;

  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};

  svg {
    color: ${({ theme }) => theme.themesColors.secondaryDot45};
    margin-left: 4px;
  }
`;

export const EditProfileOrganizationSubTitle = styled.div.attrs({
  'data-class': 'EditProfileOrganizationSubTitle',
})`
  ${divFlex}
  justify-content: start;
  margin-left: 4px;

  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const EditProfileOrganizationHeader = styled.div.attrs({
  'data-class': 'EditProfileOrganizationHeader',
})`
  ${divFlex}
  justify-content: start;

  margin-bottom: 16px;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;

export const EditProfileOrganizationInput = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'EditProfileOrganizationInput',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}
  width: 100%;

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

export const EditProfileOrganizationSpaceInput = styled.div.attrs({
  'data-class': 'EditProfileOrganizationSpaceInput',
})`
  ${divFlex}
  width: 100%;

  margin-bottom: 16px;
`;

export const EditProfileOrganizationSpaceRow = styled.div.attrs({
  'data-class': 'EditProfileOrganizationSpaceRow',
})`
  ${divFlex}
  width: 100%;

  > div:not(:first-child) {
    margin-left: 8px;
  }
`;

export const EditProfileOrganizationSpaceColumn = styled.div.attrs((props: {
  widthProp: string,
}) => ({
  widthProp: props.widthProp,
  'data-class': 'EditProfileOrganizationSpaceColumn',
}))`
  ${divFlex}
  flex-direction: column;

  ${({ widthProp }) => widthProp && `width: ${widthProp}`};
`;

export const EditProfileOrganizationAddress = styled.div.attrs({
  'data-class': 'EditProfileOrganizationAddress',
})`
  ${divFlex}
  flex-direction: column;
  justify-content: center;
`;
