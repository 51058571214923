import { RootState } from 'app-wrapper/store';
import { createSelector } from 'reselect';

const localState = (state: RootState) => state.signUp;

const getLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getRequestError = createSelector(
  localState,
  (state) => state.requestError,
);

const getEmail = createSelector(
  localState,
  (state) => state.email,
);

const getEmailError = createSelector(
  localState,
  (state) => state.emailError,
);

const getIsDisabledEmail = createSelector(
  localState,
  (state) => state.isDisabledEmail,
);

const getPassword = createSelector(
  localState,
  (state) => state.password,
);

const getPasswordError = createSelector(
  localState,
  (state) => state.passwordError,
);

const getFirstName = createSelector(
  localState,
  (state) => state.firstName,
);

const getFirstNameError = createSelector(
  localState,
  (state) => state.firstNameError,
);

const getLastName = createSelector(
  localState,
  (state) => state.lastName,
);

const getLastNameError = createSelector(
  localState,
  (state) => state.lastNameError,
);

export const signUpSelectors = {
  getLoading,
  getRequestError,
  getEmail,
  getEmailError,
  getIsDisabledEmail,
  getPassword,
  getPasswordError,
  getFirstName,
  getFirstNameError,
  getLastName,
  getLastNameError,
};
