import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation } from 'react-router';
import Tabs from 'antd/es/tabs';

import { RouteNames } from 'app-wrapper/constants';
import { ShipmentChargesPermissionsDtm } from 'shipment-operations/models/dtm';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { ShipmentTabs } from 'shipment-operations/view/components/ShipmentTabs';
import { hasAccess } from 'app-wrapper/utils';

interface IShipmentBillingTabsComponentProps {
  permissions: ShipmentChargesPermissionsDtm
}

export const ShipmentBillingTabsComponent: FC<IShipmentBillingTabsComponentProps> = ({
  permissions,
}) => {
  const { t } = useTranslation();
  const { shipmentId = '0' } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onChangeTab = useCallback((tab) => {
    navigate(tab, { replace: true });
  }, []);

  return (
    <ShipmentTabs
      activeKey={pathname}
      onChange={onChangeTab}
    >
      {hasAccess(permissions.shipmentChargesAvailability, PermissionAttributePolicy.WRITE) && <Tabs.TabPane tab={t('Receivables')} key={RouteNames.SHIPMENT_BILLING_RECEIVABLES(shipmentId)} /> }
      <Tabs.TabPane tab={t('Payables')} key={RouteNames.SHIPMENT_BILLING_PAYABLES(shipmentId)} />
    </ShipmentTabs>
  );
};
