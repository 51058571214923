import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';
import {
  PermissionAttributePolicy,
  ShipmentBookingStatusEnum,
  ShipmentStatusEnum,
  ShippingInstructionsStatusEnum,
} from 'shipment-operations/constants';
import { hasAccess, hasReadAccess } from 'app-wrapper/utils';
import { ECarrierSCAC, MaerskCodes } from 'monetary/constants';
import { R as UserR } from 'user-management/repository';

const SUBMITTED_STATUSES = [
  ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED,
  ShipmentBookingStatusEnum.BOOKING_SUBMITTED,
];

const mismatchedStatusesCustomer = [
  ShipmentBookingStatusEnum.BOOKING_CONFIRMED,
  ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED,
  ShipmentBookingStatusEnum.BOOKING_AMENDMENT_CONFIRMED,
];

const mismatchedStatusesPartner = [
  ShipmentBookingStatusEnum.BOOKING_CONFIRMED,
  ShipmentBookingStatusEnum.BOOKING_AMENDMENT_CONFIRMED,
];

const localState = (state: RootState) => state.shipment;

const localStateShippingParties = (state: RootState) => state.shippingParties;

const getShipment = createSelector(
  localState,
  (state) => state.shipment,
);

const getIsDrayageShipment = createSelector(
  getShipment,
  (shipment) => shipment?.isDrayageShipment(),
);

const getShipmentConfirmationFileNamePrint = createSelector(
  getShipment,
  (shipment) => {
    let fileName = 'Skypace. Confirmation for Shipment';

    if (shipment?.shipmentName || shipment?.id) {
      fileName += ` ${shipment?.shipmentName || `#${shipment.id}`}`;
    }

    fileName += `.${shipment?.origin.address || ''}`;
    fileName += ` - ${shipment?.destination.address || ''}`;

    return fileName;
  },
);

const getLoadingState = createSelector(
  localState,
  (state) => state.isLoading,
);

const getContainersBasicType = createSelector(
  localState,
  (state) => state.containersBasicType,
);

const getPaymentTerms = createSelector(
  localState,
  (state) => state.paymentTerms,
);

const getTradeType = createSelector(
  getPaymentTerms,
  (paymentTerms) => paymentTerms?.tradeType,
);

const getOwnContainersState = createSelector(
  localState,
  (state) => state.isOwnContainers,
);

const getDestinationTimeZone = createSelector(
  localState,
  (state) => state.destinationTimeZone,
);

const getExportClearancePermissions = createSelector(
  localState,
  (state) => state.permissions.exportClearance,
);

const getSIPermissions = createSelector(
  localState,
  (state) => state.permissions.shippingInstructions,
);

const getDocumentsPermissions = createSelector(
  localState,
  (state) => state.permissions.documents,
);

const getTransportationDrayagePermissions = createSelector(
  localState,
  (state) => state.permissions.transportationDrayage,
);

const getContainersPermissions = createSelector(
  localState,
  (state) => state.permissions.containers,
);

const getShipmentChargesPermissions = createSelector(
  localState,
  (state) => state.permissions.shipmentCharges,
);

const getShipmentTransportationPermissions = createSelector(
  localState,
  (state) => state.permissions.transportation,
);

const getDashboardsPermissions = createSelector(
  localState,
  (state) => state.permissions.dashboards.visibility,
);

const getCargosPermissions = createSelector(
  localState,
  (state) => state.permissions.cargos,
);

const getOverviewPermissions = createSelector(
  localState,
  (state) => state.permissions.overview,
);

const getIsManualReferenceNumberDrawerVisible = createSelector(
  getShipment,
  getOverviewPermissions,
  (shipment, permissions) => {
    if (!shipment || !permissions) {
      return false;
    }

    const { manualReferenceNumberDrawerAvailability } = permissions;
    const { scac, status } = shipment;

    return [ECarrierSCAC.YMLU, ECarrierSCAC.OOLU].includes(scac as ECarrierSCAC) && hasReadAccess(manualReferenceNumberDrawerAvailability) && status === ShipmentStatusEnum.PENDING_CONFIRMATION;
  },
);

const getTransportationPermissions = createSelector(
  localState,
  (state) => state.permissions.transportation,
);

const getSelectedShippingParty = createSelector(
  localStateShippingParties,
  (state) => state.changedFormState[state.selectedForm],
);

const getIsSignatureNraShippingParties = createSelector(
  getSelectedShippingParty,
  (changeFormState) => changeFormState?.isAgreeNra,
);

const getShipmentCustomerCompanyId = createSelector(
  getShipment,
  (shipment) => shipment?.customer?.id,
);

const getActiveChargePermissions = createSelector(
  localState,
  (state) => state.permissions.shipmentCharges,
);

const getOriginActiveChargePermissions = createSelector(
  getActiveChargePermissions,
  (charge) => charge.activeChargeOrigin,
);

const getDestinationActiveChargePermissions = createSelector(
  getActiveChargePermissions,
  (charge) => charge.activeChargeDestination,
);

const getShipmentChangesPermissions = createSelector(
  getOverviewPermissions,
  (permissions) => permissions.shipmentChanges,
);

const getWorkgroupPermissions = createSelector(
  localState,
  (state) => state.permissions.workgroup,
);

const getLinkContainerPermissions = createSelector(
  localState,
  (state) => state.permissions.transportationDrayage.linkContainer,
);

const getBookingStatus = createSelector(
  getShipment,
  (shipment) => shipment?.bookingStatus,
);

const getShipmentStatus = createSelector(
  getShipment,
  (shipment) => shipment?.status,
);

const getIsBookingStatusWarning = createSelector(
  getShipment,
  getBookingStatus,
  getShipmentChangesPermissions,
  (shipment, bookingStatus, permissions) => {
    if (hasAccess(permissions, PermissionAttributePolicy.READ)) {
      if ((shipment && !shipment?.isRequestTheSameWithShipment) || (bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED)) {
        return true;
      }
    }
    return false;
  },
);

const getIsUnconfirmedChanges = createSelector(
  getShipment,
  getShipmentChangesPermissions,
  (shipment, permissions) => hasAccess(permissions, PermissionAttributePolicy.READ) && (shipment && !shipment?.isRequestTheSameWithShipment),
);

const getIsRequestTheSameWithShipment = createSelector(
  getShipment,
  (shipment) => shipment?.isRequestTheSameWithShipment,
);

const getIsDisableChanges = createSelector(
  getBookingStatus,
  getShipment,
  (status, shipment) => (!!status && SUBMITTED_STATUSES.includes(status)) || shipment?.status === ShipmentStatusEnum.PENDING_CONFIRMATION,
);

const getCarrierNumber = createSelector(
  getShipment,
  (shipment) => shipment?.carrierReferenceNumber,
);

const getOceanBookingId = createSelector(
  getShipment,
  (shipment) => shipment?.oceanBookingId,
);

const getWithThreeColumnsTable = createSelector(
  getBookingStatus,
  getIsRequestTheSameWithShipment,
  (bookingStatus, isRequest) => bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED || !isRequest,
);

const getIsMismatchedStatus = createSelector(
  getBookingStatus,
  getShipmentChangesPermissions,
  (status, permissions) => {
    if (permissions === PermissionAttributePolicy.READ) {
      return (status && mismatchedStatusesCustomer.includes(status));
    }
    if (permissions === PermissionAttributePolicy.WRITE) {
      return (status && mismatchedStatusesPartner.includes(status));
    }
    return false;
  },
);

const getSiStatus = createSelector(
  getShipment,
  (shipment) => shipment?.siStatus,
);

const getSiRequestTheSameWithShipment = createSelector(
  getShipment,
  (shipment) => shipment?.siRequestTheSameWithShipment,
);

const getIsThereAreMissMatches = createSelector(
  getSiStatus,
  getSiRequestTheSameWithShipment,
  (status, isRequest) => status !== ShippingInstructionsStatusEnum.SI_PREPARATION && !isRequest,
);

const getShipmentOrigin = createSelector(
  localState,
  (state) => (state.shipment ? state.shipment.origin : null),
);

const getShipmentDestination = createSelector(
  localState,
  (state) => (state.shipment ? state.shipment.destination : null),
);

const getIsUsShipmentOriginOrDestination = createSelector(
  localState,
  (state) => Boolean(state.shipment?.origin.countryCode === 'US' || state.shipment?.destination.countryCode === 'US'),
);

const getIsMaersk = createSelector(
  localState,
  (state) => {
    const scac = state.shipment?.scac;
    return !!(scac && MaerskCodes.includes(scac));
  },
);

const getShipmentCarrierSCAC = createSelector(
  getShipment,
  (shipment) => shipment?.scac,
);

const getIsOriginPartner = createSelector(
  UserR.selectors.userOrganizationData.getUserOrganizationId,
  getShipment,
  (orgId, shipment) => orgId === shipment?.originPartnerOrgId,
);

const getIsDestinationPartner = createSelector(
  UserR.selectors.userOrganizationData.getUserOrganizationId,
  getShipment,
  (orgId, shipment) => orgId === shipment?.destinationPartnerOrgId,
);

const getIsAccountHolder = createSelector(
  UserR.selectors.userOrganizationData.getUserOrganizationId,
  getShipment,
  (orgId, shipment) => orgId === shipment?.accountHolderOrgId,
);

const getIsOriginPartnerAgent = createSelector(
  UserR.selectors.userOrganizationData.getUserOrganizationId,
  getShipment,
  (orgId, shipment) => (orgId === shipment?.originPartnerAgentOrgId) && (orgId !== shipment?.bookingAgentOrgId),
);

const getIsDestinationPartnerAgent = createSelector(
  UserR.selectors.userOrganizationData.getUserOrganizationId,
  getShipment,
  (orgId, shipment) => (orgId === shipment?.destinationPartnerAgentOrgId) && (orgId !== shipment?.bookingAgentOrgId),
);

const getIsBookingAgent = createSelector(
  UserR.selectors.userOrganizationData.getUserOrganizationId,
  getShipment,
  (orgId, shipment) => orgId === shipment?.bookingAgentOrgId,
);

const getOriginTimeZone = createSelector(
  getShipmentOrigin,
  (origin) => origin?.estimatedDate?.getDateAsMomentWithOffset().format().substring(19),
);

const getContainers = createSelector(
  localState,
  (state) => state.containers,
);

export const shipmentSelectors = {
  getCargosPermissions,
  getContainersBasicType,
  getPaymentTerms,
  getContainersPermissions,
  getDestinationTimeZone,
  getDocumentsPermissions,
  getExportClearancePermissions,
  getShipmentConfirmationFileNamePrint,
  getLoadingState,
  getOverviewPermissions,
  getOwnContainersState,
  getShipment,
  getShipmentChargesPermissions,
  getSIPermissions,
  getTransportationPermissions,
  getIsSignatureNraShippingParties,
  getShipmentCustomerCompanyId,
  getOriginActiveChargePermissions,
  getDestinationActiveChargePermissions,
  getIsOriginPartner,
  getIsDestinationPartner,
  getDashboardsPermissions,
  getShipmentChangesPermissions,
  getBookingStatus,
  getIsBookingStatusWarning,
  getShipmentStatus,
  getIsDisableChanges,
  getCarrierNumber,
  getOceanBookingId,
  getIsRequestTheSameWithShipment,
  getWithThreeColumnsTable,
  getShipmentTransportationPermissions,
  getIsMismatchedStatus,
  getIsUnconfirmedChanges,
  getIsThereAreMissMatches,
  getShipmentOrigin,
  getShipmentDestination,
  getIsUsShipmentOriginOrDestination,
  getIsMaersk,
  getShipmentCarrierSCAC,
  getIsAccountHolder,
  getIsOriginPartnerAgent,
  getIsDestinationPartnerAgent,
  getIsBookingAgent,
  getSiRequestTheSameWithShipment,
  getWorkgroupPermissions,
  getOriginTimeZone,
  getTransportationDrayagePermissions,
  getContainers,
  getIsDrayageShipment,
  getIsManualReferenceNumberDrawerVisible,
  getLinkContainerPermissions,
  getTradeType,
};
