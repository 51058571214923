import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RcFile, UploadProps } from 'antd/es/upload/interface';
import AntdUploadExports from 'antd/es/upload';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import UploadOutlined from '@ant-design/icons/UploadOutlined';

import { showWarning } from 'app-wrapper/utils/showWarning';
import { addPrefix } from 'app-wrapper/utils';

import { CargoDTM, MSDSDocumentDTM } from 'shipment-operations/models/dtm';

import { Upload, Button } from './CargoDocumentUpload.styled';

const MAX_FILE_SIZE = 1024 * 1024 * 10; // 10MB
const { LIST_IGNORE } = AntdUploadExports;

interface ICargoDocumentUploadComponentProps {
  error: boolean
  authToken: string
  cargoId: number
  shipmentId: string;
  msdsDocument: CargoDTM['msdsDocument']
  setCargoMsdsDocument: (msdsDocument: CargoDTM['msdsDocument'], cargoId: number) => void
  touchCargoField: (field: string, cargoId: number) => void
  downloadCargoMsdsDocument: (shipmentId: number, cargoId: number) => void
}

export const CargoDocumentUploadComponent: FC<ICargoDocumentUploadComponentProps> = ({
  error,
  authToken,
  msdsDocument,
  downloadCargoMsdsDocument,
  touchCargoField,
  setCargoMsdsDocument,
  shipmentId,
  cargoId,
}) => {
  const { t } = useTranslation();

  const setMsdsDocument = useCallback((value: CargoDTM['msdsDocument']) => {
    setCargoMsdsDocument(value, cargoId);
  }, [setCargoMsdsDocument, cargoId]);

  const touchField = useCallback((field: string) => {
    touchCargoField(field, cargoId);
  }, [touchCargoField, cargoId]);

  const downloadMsdsDocument = useCallback(() => downloadCargoMsdsDocument(+shipmentId, cargoId), [downloadCargoMsdsDocument, cargoId, shipmentId]);

  const handleBeforeUpload = useCallback((file: RcFile) => {
    const isAcceptableSize = file.size < MAX_FILE_SIZE;
    if (!isAcceptableSize) {
      showWarning({ message: t('File is too large'), duration: 4 });
    }
    return isAcceptableSize || LIST_IGNORE;
  }, []);

  const handleChange: UploadProps['onChange'] = useCallback(({ file }) => {
    if (file.status === 'removed') {
      setMsdsDocument([]);
      return;
    }

    if (file.response) {
      file.url = '/';
    }

    if (file.response && file.status === 'error') {
      file.error = new Error(file.response?.message);
      file.response = file.response.message ? file.response.message : {};
    }

    setMsdsDocument([MSDSDocumentDTM.fromPlain({
      name: file.name,
      url: file.url,
      uid: file.uid,
      response: file.response,
      status: file.status,
    })]);
  }, []);

  const handleRemove: UploadProps['onRemove'] = useCallback(() => {
    setMsdsDocument([]);
  }, []);

  const handlePreview: UploadProps['onPreview'] = useCallback((file) => {
    if (!file.status) {
      return;
    }
    downloadMsdsDocument();
  }, []);

  const handleBlur = useCallback(() => {
    touchField('msdsDocument');
  }, []);

  const postUrl = addPrefix(`/shipment-service/api/v1/shipments/${shipmentId}/documents?type=MSDS`);
  const isErrorOutlineDisplayed = error && !msdsDocument.length;

  return (
    <Upload
      maxCount={1}
      multiple={false}
      showUploadList={{ showRemoveIcon: true, removeIcon: <DeleteOutlined /> }}
      beforeUpload={handleBeforeUpload}
      action={postUrl}
      headers={{ Authorization: authToken }}
      fileList={msdsDocument}
      onPreview={handlePreview}
      onChange={handleChange}
      onRemove={handleRemove}
    >
      <Button
        error={+isErrorOutlineDisplayed}
        icon={<UploadOutlined />}
        type="dashed"
        onBlur={handleBlur}
      >
        {t('Upload document')}
      </Button>
    </Upload>
  );
};
