import { ShipmentSchedulesService } from './ShipmentSchedules';
import { BillOfLadingService } from './BillOfLading';
import { CargoService } from './Cargo';
import { CommodityService } from './Commodity';
import { ContactsService } from './Contacts';
import { CreateInvoiceChargesService } from './CreateInvoiceCharges';
import { HsValidationService } from './HsValidation';
import { ShipmentBillingInvoiceService } from './ShipmentBillingInvoice';
import { ShipmentBillingService } from './ShipmentBilling';
import { ShipmentContainersService } from './ShipmentContainers';
import { ShipmentDeclarationService } from './ShipmentDeclaration';
import { ShipmentDocumentService } from './ShipmentDocument';
import { ShipmentService } from './Shipment';
import { ShippingPartiesService } from './ShippingParties';
import { TemperatureControlService } from './TemperatureControl';
import { InttraService } from './Inttra';
import { CreditNoteService } from './CreditNote';
import { ShipmentTransportationChargesService } from './ShipmentTransportationCharges';
import { ShipmentRoutesService } from './ShipmentRoutes';
import { ShipmentTrackerService } from './ShipmentTracker';
import { ExportClearanceService } from './ExportClearance';
import { PlansService } from './Plans';
import { PaymentTermsService } from './PaymentTerms';
import { ShipmentInstructionDetailsService } from './ShipmentInstructionDetails';
import { ShipmentMissMatchesService } from './ShipmentMissMatches';
import { MakePaymentService } from './MakePayment';
import { MasterBillOfLadingsService } from './MasterBillOfLadings';
import { ShipmentChargesService } from './ShipmentCharges';
import { ShipmentAccessorialService } from './ShipmentAccessorial';
import { ShipmentFreeTime } from './ShipmentFreeTime';
import { ModuleFunctionalityPermissionsService } from './ModuleFunctionalityPermissions';
import { ShipmentBillingDocumentsService } from './ShipmentBillingDocuments';
import { ShipmentActiveChargeService } from './ShipmentActiveCharge';
import { ShipmentChangesService } from './ShipmentChanges';
import { ShipmentWorkgroupService } from './ShipmentWorkgroup';
import { AdditionalServicesService } from './AdditionalServices';
import { ShipmentAdditionalServicesService } from './ShipmentAdditionalServices';
import { AddRatesService } from './AddRates';
import { LoadingControlService } from './LoadingControl';
import { BookingDetailsService } from './BookingDetails';

export const services = {
  billOfLading: new BillOfLadingService(),
  cargo: new CargoService(),
  commodity: new CommodityService(),
  contacts: new ContactsService(),
  createInvoiceCharges: new CreateInvoiceChargesService(),
  exportClearance: new ExportClearanceService(),
  hsValidation: new HsValidationService(),
  shipment: new ShipmentService(),
  shipmentBilling: new ShipmentBillingService(),
  shipmentBillingInvoice: new ShipmentBillingInvoiceService(),
  shipmentContainers: new ShipmentContainersService(),
  shipmentDeclaration: new ShipmentDeclarationService(),
  shipmentDocument: new ShipmentDocumentService(),
  shippingParties: new ShippingPartiesService(),
  shipmentPlans: new PlansService(),
  shipmentMissMatches: new ShipmentMissMatchesService(),
  temperatureControl: new TemperatureControlService(),
  inttra: new InttraService(),
  creditNote: new CreditNoteService(),
  shipmentTransportationCharges: new ShipmentTransportationChargesService(),
  shipmentRoutes: new ShipmentRoutesService(),
  shipmentTracker: new ShipmentTrackerService(),
  paymentTerms: new PaymentTermsService(),
  shipmentInstructionDetails: new ShipmentInstructionDetailsService(),
  makePayment: new MakePaymentService(),
  shipmentCharges: new ShipmentChargesService(),
  masterBillOfLading: new MasterBillOfLadingsService(),
  shipmentAccessorial: new ShipmentAccessorialService(),
  shipmentFreeTime: new ShipmentFreeTime(),
  moduleFunctionalityPermissions: new ModuleFunctionalityPermissionsService(),
  shipmentBillingDocuments: new ShipmentBillingDocumentsService(),
  shipmentActiveCharge: new ShipmentActiveChargeService(),
  shipmentChanges: new ShipmentChangesService(),
  shipmentWorkgroup: new ShipmentWorkgroupService(),
  additionalService: new AdditionalServicesService(),
  shipmentAdditionalServices: new ShipmentAdditionalServicesService(),
  addRates: new AddRatesService(),
  loadingControls: new LoadingControlService(),
  shipmentSchedulesService: new ShipmentSchedulesService(),
  bookingDetails: new BookingDetailsService(),
};
