import React, { FC, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Skeleton from 'antd/es/skeleton';

import { normalizationCost, validateDecimalFraction } from 'app-wrapper/utils';

import { ShipmentPreviewDTM, WaypointDTM } from 'shipment-operations/models/dtm';

import { getCarrierSCACNamesRFQ } from 'monetary/constants';

import {
  Container,
  ContainerWrap,
  Actions,
  Button,
  CarrierInfo,
  CarrierInfoRow,
  CarrierShipIcon,
  Text,
  DividerText,
  TextBold,
  TextGrey,
  DestinationInfo,
  DestinationInfoRow,
  DestinationInfoCol,
  DividerIcon,
  ShipmentTotalInfo,
  ShipmentTotalInfoRow,
  ShipmentTotalItem,
  WeightIcon,
  NavigatorIcon,
  GorizontalMenuIcon,
} from './Footer.styled';

interface FooterComponentProps {
  step: number;
  voyageDuration: string;
  goSecondStep: () => void;
  goFirstStep: () => void;
  setStep: (step: number) => void;
  agreeAndBook: () => void;
  goNextFromSchedule: () => void;
  origin: WaypointDTM | null;
  destination: WaypointDTM | null;
  shipment: ShipmentPreviewDTM | null;
  containersAmount: number;
  totalWeight: number;
  totalVolume: number;
  totalCost: string;
  nameSCAC: string;
  isLoading: boolean;
  isContentUpdating: boolean;
  isCustomerOrg: boolean;
  isAgreeAndBookDisabled: boolean;
}

const formatNumber = (amount: number) => {
  if (Number.isNaN(amount)) {
    return 0;
  }

  const formatted = String(amount.toFixed(3));
  const [unit, divisional] = formatted.split('.');
  const [divisional1, divisional2, divisional3] = divisional;
  const numericDivisional = Number(`${divisional1}${divisional2}${divisional3}`);

  return numericDivisional ? `${Number(unit)}.${divisional}` : unit;
};

export const FooterComponent: FC<FooterComponentProps> = ({
  step,
  goFirstStep,
  goSecondStep,
  voyageDuration,
  shipment,
  origin,
  goNextFromSchedule,
  destination,
  totalWeight,
  totalVolume,
  totalCost,
  nameSCAC,
  setStep,
  isLoading,
  containersAmount,
  isContentUpdating,
  agreeAndBook,
  isCustomerOrg,
  isAgreeAndBookDisabled,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const originName = useMemo(() => (origin ? origin.address : ''), [origin]);
  const originDate = useMemo(() => (origin && origin.estimatedDate ? origin.estimatedDate.getFormatDMMMHHmmWithOffset() : ''), [origin]);
  const destinationName = useMemo(() => (destination ? destination.address : ''), [destination]);
  const destinationDate = useMemo(() => (destination && destination.estimatedDate ? destination.estimatedDate.getFormatDMMMHHmmWithOffset() : ''), [destination]);

  const handleAgreeAndBook = useCallback(() => {
    agreeAndBook();
  }, [agreeAndBook, navigate]);

  const handleGoSecondStep = useCallback(() => {
    goSecondStep();
  }, [goSecondStep, navigate]);

  const nameTitle: string = getCarrierSCACNamesRFQ(nameSCAC);

  return (
    <Container>
      {isLoading ? <Skeleton /> : (
        <ContainerWrap>
          {step > 2 || !!shipment ? (
            <>
              <CarrierInfo>
                <CarrierInfoRow>
                  <TextBold>
                    {nameTitle}
                  </TextBold>

                  <DividerText>
                    /
                  </DividerText>

                  <TextBold>
                    $ {
                    normalizationCost(validateDecimalFraction(`${Number(totalCost || 0)}`, 15, 2, { forcePrecision: true }), {
                      toFixed: 2,
                      thousandthSeparatorComma: true,
                    })
                  }
                  </TextBold>
                </CarrierInfoRow>

                <CarrierInfoRow>
                  <CarrierShipIcon />

                  <TextGrey>
                    {t('CY-CY')}
                    {t(' ')}
                    {t('FCL')}
                    {t(' ')}
                    {voyageDuration}
                  </TextGrey>
                </CarrierInfoRow>
              </CarrierInfo>

              <DestinationInfo>
                <DestinationInfoCol>
                  <Text>{originName}</Text>

                  <DestinationInfoRow>
                    <TextGrey>
                      {t('ETD')}
                    </TextGrey>

                    <Text
                      style={{
                        marginLeft: '4px',
                      }}
                    >
                      {originDate}
                    </Text>
                  </DestinationInfoRow>
                </DestinationInfoCol>

                <DividerIcon />

                <DestinationInfoCol>
                  <Text>{destinationName}</Text>

                  <DestinationInfoRow>
                    <TextGrey>
                      {t('ETA')}
                    </TextGrey>

                    <Text
                      style={{
                        marginLeft: '4px',
                      }}
                    >
                      {destinationDate}
                    </Text>
                  </DestinationInfoRow>
                </DestinationInfoCol>
              </DestinationInfo>

              <ShipmentTotalInfo>
                <Text>
                  {t('Shipment Total')}
                </Text>

                <ShipmentTotalInfoRow>
                  <ShipmentTotalItem>
                    <GorizontalMenuIcon />

                    <TextGrey>
                      × {containersAmount} cont
                    </TextGrey>
                  </ShipmentTotalItem>

                  <ShipmentTotalItem>
                    <WeightIcon />

                    <TextGrey>
                      {formatNumber(totalWeight)} kg
                    </TextGrey>
                  </ShipmentTotalItem>

                  <ShipmentTotalItem>
                    <NavigatorIcon />

                    <TextGrey>
                      {formatNumber(totalVolume)} cbm
                    </TextGrey>
                  </ShipmentTotalItem>
                </ShipmentTotalInfoRow>
              </ShipmentTotalInfo>
            </>
          ) : null}

          <Actions>
            {step === 2 ? (
              <>
                <Button disabled={isLoading || isContentUpdating} onClick={goNextFromSchedule}>
                  {t('Next')}
                </Button>
              </>
            ) : null}

            {step === 3 ? (
              <>
                <Button onClick={() => setStep(2)} type="default">
                  {t('Back')}
                </Button>

                {isCustomerOrg ? (
                  <Button disabled={isContentUpdating} onClick={handleGoSecondStep}>
                    {t('Next')}
                  </Button>
                ) : (
                  <Button disabled={isContentUpdating} onClick={handleGoSecondStep}>
                    {t('Save and Book')}
                  </Button>
                )}
              </>
            ) : null}

            {step === 4 ? (
              <>
                <Button
                  onClick={goFirstStep}
                  type="default"
                >
                  {t('Back')}
                </Button>

                <Button disabled={isContentUpdating || isAgreeAndBookDisabled} onClick={handleAgreeAndBook}>
                  {t('Agree and Book')}
                </Button>
              </>
            ) : null}
          </Actions>
        </ContainerWrap>
      )}
    </Container>
  );
};
