import { action, computed } from 'mobx';

import { ControllerStore, IControllerState } from 'proto/BaseMobxStore';

interface IConfirmationBookingDetailsState {
  bookingNumber?: string;
  originalBookingNumber?: string
  editModalHost: string;
  pending: boolean;
}

const initialState = {
  bookingNumber: undefined,
  originalBookingNumber: undefined,
  isLoading: false,
  editModalHost: '',
  pending: false,
};

export class ConfirmationBookingDetailsStore extends ControllerStore<IControllerState<IConfirmationBookingDetailsState>> {
  @action
  setBookingNumber(bookingNumber?: string) {
    this.state.bookingNumber = bookingNumber;
  }

  @action
  setOriginalBookingNumber(originalBookingNumber?: string) {
    this.state.originalBookingNumber = originalBookingNumber;
  }

  @action
  setEditModalHost(editModalHost: string) {
    this.state.editModalHost = editModalHost;
  }

  @action
  setPending(pending: boolean) {
    this.state.pending = pending;
  }

  @computed
  get getBookingNumber() {
    return this.state.bookingNumber;
  }

  @computed
  get getEditModalHost() {
    return this.state.editModalHost;
  }

  @computed
  get getOriginalBookingNumber() {
    return this.state.originalBookingNumber;
  }

  @computed
  get isPending() {
    return this.state.pending;
  }
}

export const createConfirmationBookingDetailsStore = (_initialState?: IControllerState<IConfirmationBookingDetailsState>) => new ConfirmationBookingDetailsStore(_initialState || initialState);
