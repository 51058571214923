import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import message from 'antd/es/message';
import { CoverageAreaTypeEnum } from 'app-wrapper/enums/CoverageAreaType.enum';
import { isCountry } from 'app-wrapper/repository/store/CountriesList/countries';
import { CountrySelectUseCase } from 'app-wrapper/usecases/CountrySelect.useCase';
import { MultiUseCase } from 'app-wrapper/usecases/MultiSelect.useCase';
import { SelectUseCase } from 'app-wrapper/usecases/Select.useCase';
import { BaseController, controller } from 'proto/BaseController';

import i18n from 'app-wrapper/i18n/i18n';
import { REQUEST_STATUS, RouteNames } from 'app-wrapper/constants';
import { validationEmail, validationPhone } from 'app-wrapper/utils';

import { R as AuthR } from 'authentication/repository';

import { R } from 'user-management/repository';
import {
  OrganizationDTM,
  BusinessRegistrationNumberDtm,
  OrganizationMemberDTM,
  OrganizationPrimaryContactDTM,
  OrganizationMembershipDtm,
  OrganizationFieldsErrorsFieldDTM,
  OrganizationPaymentMethodDTM,
  EOrganizationStatus,
  OrganizationCoverageAreasDTM,
} from 'user-management/models/dtm';
import {
  EAccountDepartmentType,
  EAccountDrawerContent, EAccountDrawerType,
  EMembershipType,
  EOrganizationMemberRole,
  EOrganizationType,
} from 'user-management/constants';
import { WebsiteUrl } from 'user-management/models/dtm/WebsiteUrl';
import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { OrganizationPaymentMethod } from 'shipment-operations/constants';

@controller
export class OrganizationProfileController extends BaseController {
  get stateSelectUseCase() {
    return new SelectUseCase(
      this,
      R.actions.userOrganizationData.setUserOrganizationUpdateAddressState,
      R.actions.userOrganizationData.setUserOrganizationErrorsAddressState,
      this.onValidateOrganizationFields,
    );
  }

  get coverageMultiSelectUseCase() {
    return new MultiUseCase<OrganizationCoverageAreasDTM>(
      this,
      R.actions.userOrganizationData.setUserOrganizationUpdateCoverageAreas,
      R.actions.userOrganizationData.setUserOrganizationErrorsCoverageAreas,
      this.onValidateOrganizationFields,
    );
  }

  get internalAddressStateSelectUseCase() {
    return new SelectUseCase(
      this,
      R.actions.userOrganizationData.setUserCustomerAddInternalAddressState as ActionCreatorWithPayload<string | undefined, string>,
      R.actions.userOrganizationData.setCustomerAddInternalErrorsAddressState,
      this.onValidateCustomerAddInternalFields,
    );
  }

  get countrySelectUseCase() {
    return new CountrySelectUseCase(
      this,
      R.actions.userOrganizationData.setUserOrganizationUpdateAddressCountry as ActionCreatorWithPayload<string | undefined, string>,
      R.actions.userOrganizationData.setUserOrganizationErrorsAddressCountry,
      this.onValidateOrganizationFields,
      R.actions.userOrganizationData.setUserOrganizationUpdateAddressState,
    );
  }

  get internalAddressCountrySelectUseCase() {
    return new CountrySelectUseCase(
      this,
      R.actions.userOrganizationData.setCustomerAddInternalAddressCountry as ActionCreatorWithPayload<string | undefined, string>,
      R.actions.userOrganizationData.setCustomerAddInternalErrorsAddressCountry,
      this.onValidateCustomerAddInternalFields,
      R.actions.userOrganizationData.setUserCustomerAddInternalAddressState as ActionCreatorWithPayload<string | undefined, string>,
    );
  }

  public loadTeamsAndAccountManagers = async () => {
    const currentOrganization = R.selectors.userOrganizationData.getUserOrganization(this.store.getState());

    if (!currentOrganization) {
      return;
    }

    const teams = await R.services.teams.getTeams(currentOrganization.id);
    const members = await R.services.organization.getOrganizationMembersList(currentOrganization.id);

    this.dispatch(R.actions.userOrganizationData.setTeams(teams));
    this.dispatch(R.actions.userOrganizationData.setAccountManagers(members.filter(({ departments }) => departments.find(({ type }) => type === EAccountDepartmentType.ACCOUNT_MANAGEMENT))));
    this.dispatch(R.actions.userOrganizationData.setSalesManagers(members.filter(({ departments }) => departments.find(({ type }) => type === EAccountDepartmentType.SALES))));
  };

  public uploadData = async () => {
    let organization: OrganizationDTM | null;
    let organizationMember: OrganizationMemberDTM | undefined;
    let organizationMembersList: OrganizationMemberDTM[];

    this.dispatch(R.actions.userOrganizationData.setUserSuccessMessage(''));
    this.dispatch(R.actions.userOrganizationData.setUserErrorMessage(''));
    this.dispatch(R.actions.userOrganizationData.resetCustomerInternalOrganization());
    this.dispatch(R.actions.userOrganizationData.emptyUserOrganizationUpdateMembersContactsAll());

    try {
      organization = await R.services.organization.getCurrentOrganization();
    } catch (e) {
      console.error(e);

      return;
    }

    if (!organization) {
      return;
    }

    const userEmail = AuthR.selectors.auth.getEmail(this.store.getState());

    try {
      organizationMember = await R.services.organization.getOrganizationMember(organization.id, userEmail);
    } catch (e) {
      console.error(e);

      return;
    }

    this.dispatch(R.actions.userOrganizationData.setCurrentOrganizationUserData(organizationMember));

    this.dispatch(R.actions.userOrganizationData.setUserOrganization(organization));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdate(organization));

    try {
      organizationMembersList = await R.services.organization.getOrganizationMembersList(organization.id);
    } catch (e) {
      console.error(e);

      return;
    }
    if (!organizationMembersList) {
      return;
    }

    organizationMembersList.forEach((item) => {
      if (item.primary && item.enabled && item.id) {
        this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateMembersContactsValue(item));
      }
    });

    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateMembersContacts(organizationMembersList));
  };

  public onEndCustomerPage = async () => {
    await this.abortAllRequests();
  }

  public clearPartnersPage = () => {
    this.dispatch(R.actions.partners.setPartners([]));
    this.dispatch(R.actions.partners.setPage(0));
    this.dispatch(R.actions.partners.setIsPaginationEnded(false));
  }

  public paginatePartners = async () => {
    this.dispatch(R.actions.partners.setLoading(true));

    const partners = R.selectors.partners.getPartners(this.store.getState());
    const page = R.selectors.partners.getPage(this.store.getState());

    const res = await R.services.organization.getOrganizations(page + 1, undefined, undefined, EOrganizationMemberRole.PARTNER);

    if (!res.length) {
      this.dispatch(R.actions.partners.setIsPaginationEnded(true));
      this.dispatch(R.actions.partners.setLoading(false));

      return;
    }

    this.dispatch(R.actions.partners.setPage(page + 1));
    this.dispatch(R.actions.partners.setLoading(false));
    this.dispatch(R.actions.partners.setPartners([...partners, ...res]));
  }

  public getPartnersPageInfo = async () => {
    this.dispatch(R.actions.partners.setLoading(true));

    const partners = await R.services.organization.getOrganizations(undefined, undefined, undefined, EOrganizationMemberRole.PARTNER);

    this.dispatch(R.actions.partners.setPartners(partners));
    this.dispatch(R.actions.partners.setLoading(false));
  }

  public onStartCustomerPage = async () => {
    let organization: OrganizationDTM | null;
    let organizations: OrganizationDTM[] | null;
    let organizationsActive: OrganizationDTM[] | null;
    let organizationsWaitingApproval: OrganizationDTM[] | null;
    let organizationsReject: OrganizationDTM[] | null;
    let organizationMember: OrganizationMemberDTM | undefined;

    this.dispatch(R.actions.userOrganizationData.setUserSuccessMessage(''));
    this.dispatch(R.actions.userOrganizationData.setUserErrorMessage(''));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsStatus(REQUEST_STATUS.pending));
    this.dispatch(R.actions.userOrganizationData.emptyUserOrganizationUpdateMembersContactsAll());

    try {
      organization = await R.services.organization.getCurrentOrganization();
    } catch (e) {
      console.error('onStartCustomerPage error', e);

      this.dispatch(R.actions.userOrganizationData.setUserOrganizationsStatus(REQUEST_STATUS.rejected));
      this.callErrorNotification(i18n.t('RFQRequestErrors.COMMON_ERROR_TITLE'), { placement: 'bottomLeft' });
      return;
    }

    if (organization) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganization(organization));
    }

    try {
      const getOrg = async (statuses?: string) => new Promise<OrganizationDTM[]>((resolve, reject) => {
        try {
          const res = R.services.organization.getOrganizations(undefined, undefined, statuses, EOrganizationMemberRole.CUSTOMER);

          resolve(res);
        } catch (e) {
          reject(e);
        }
      });

      const [res1, res2, res3, res4] = await Promise.all<Array<OrganizationDTM>>([
        getOrg(),
        getOrg(EOrganizationStatus.ACTIVE),
        getOrg(EOrganizationStatus.WAITING_APPROVAL),
        getOrg(EOrganizationStatus.REJECTED),
      ]);

      organizations = res1;
      organizationsActive = res2;
      organizationsWaitingApproval = res3;
      organizationsReject = res4;
    } catch (e) {
      console.error('onStartCustomerPage error', e);

      this.dispatch(R.actions.userOrganizationData.setUserOrganizationsStatus(REQUEST_STATUS.rejected));

      return;
    }

    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsStatus(REQUEST_STATUS.complete));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsPageIncrement());
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsActivePageIncrement());
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsWaitingApprovalPageIncrement());
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsRejectPageIncrement());

    if (!organizations) {
      return;
    }

    this.dispatch(R.actions.userOrganizationData.setUserOrganizations(organizations));

    if (organizationsActive) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationsActive(organizationsActive));
    }
    if (organizationsWaitingApproval) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationsWaitingApproval(organizationsWaitingApproval));
    }
    if (organizationsReject) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationsReject(organizationsReject));
    }

    if (!organization) {
      return;
    }

    const userEmail = AuthR.selectors.auth.getEmail(this.store.getState());

    try {
      organizationMember = await R.services.organization.getOrganizationMember(organization.id, userEmail);
    } catch (e) {
      console.error(e);

      return;
    }

    this.dispatch(R.actions.userOrganizationData.setCurrentOrganizationUserData(organizationMember));

    const organizationMembersList = await R.services.organization.getOrganizationMembersList(organization.id);

    if (!organizationMembersList) {
      return;
    }

    organizationMembersList.forEach((item) => {
      if (item.primary && item.id) {
        this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateMembersContactsValueOld(item));
        this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateMembersContactsValue(item));
      }
    });

    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateMembersContacts(organizationMembersList));
  };

  public onEndCustomerInternalPage = async () => {
    this.abortAllRequests();
  }

  public onStartCustomerInternalPage = (id: string) => async () => {
    let organization: OrganizationDTM | null;
    let organizations: OrganizationDTM | null;
    let organizationMember: OrganizationMemberDTM | undefined;
    let organizationMembersList: OrganizationMemberDTM[];

    this.dispatch(R.actions.userOrganizationData.setUserSuccessMessage(''));
    this.dispatch(R.actions.userOrganizationData.setUserErrorMessage(''));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsStatus(REQUEST_STATUS.pending));
    this.dispatch(R.actions.userOrganizationData.emptyUserOrganizationUpdateMembersContactsAll());
    this.dispatch(R.actions.userOrganizationData.resetUserOrganizationUpdate());

    try {
      organization = await R.services.organization.getCurrentOrganization();
    } catch (e) {
      console.error('onStartCustomerInternalPage error', e);

      this.dispatch(R.actions.userOrganizationData.setUserOrganizationsStatus(REQUEST_STATUS.rejected));

      return;
    }

    if (organization) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganization(organization));
    }

    if (!id) return;

    try {
      organizations = await R.services.organization.getOrganizationById(id);
    } catch (e) {
      console.error('onStartCustomerPage error', e);

      this.dispatch(R.actions.userOrganizationData.setUserOrganizationsStatus(REQUEST_STATUS.rejected));
      return;
    }

    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsStatus(REQUEST_STATUS.complete));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsPageIncrement());

    if (!organizations) {
      return;
    }

    this.dispatch(R.actions.userOrganizationData.setCustomerInternalOrganization(organizations));

    if (!organization) {
      return;
    }

    const userEmail = AuthR.selectors.auth.getEmail(this.store.getState());

    try {
      organizationMember = await R.services.organization.getOrganizationMember(organization.id, userEmail);
    } catch (e) {
      console.error(e);

      return;
    }

    this.dispatch(R.actions.userOrganizationData.setCurrentOrganizationUserData(organizationMember));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdate(organizations));

    try {
      organizationMembersList = await R.services.organization.getOrganizationMembersList(organizations.id);
    } catch (e) {
      console.error(e);

      return;
    }
    if (!organizationMembersList) {
      return;
    }

    organizationMembersList.forEach((item) => {
      if (item.primary && item.id) {
        this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateMembersContactsValueOld(item));
        this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateMembersContactsValue(item));
      }
    });

    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateMembersContacts(organizationMembersList));
  };

  public nextPageCustomer = async () => {
    const page = this.store.getState().userOrganizationData.organizationsPage;
    let organizations: OrganizationDTM[] | null;
    this.dispatch(R.actions.userOrganizationData.setUserSuccessMessage(''));
    this.dispatch(R.actions.userOrganizationData.setUserErrorMessage(''));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsStatus(REQUEST_STATUS.pending));

    try {
      organizations = await R.services.organization.getOrganizations(page);
    } catch (e) {
      console.error('nextPageCustomer error', e);

      this.dispatch(R.actions.userOrganizationData.setUserOrganizationsStatus(REQUEST_STATUS.rejected));
      return;
    }

    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsStatus(REQUEST_STATUS.complete));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsPageIncrement());

    if (!organizations) {
      return;
    }

    if (!organizations.length) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationsIsEnd(true));
    }

    const organizationsCurrent = this.store.getState().userOrganizationData.organizations;
    const organizationsIds = organizationsCurrent?.map((item) => item.id);
    const organizationsAdd = [
      ...organizationsCurrent || [],
      ...organizations,
    ].filter((item) => !organizationsIds?.includes(item.id));

    this.dispatch(R.actions.userOrganizationData.addsUserOrganizations(organizationsAdd));
  }

  public loadPageCustomerActive = async (page: number) => {
    let organizations: OrganizationDTM[] | null;
    this.dispatch(R.actions.userOrganizationData.setUserSuccessMessage(''));
    this.dispatch(R.actions.userOrganizationData.setUserErrorMessage(''));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsStatus(REQUEST_STATUS.pending));

    try {
      organizations = await R.services.organization.getOrganizations(page, undefined, EOrganizationStatus.ACTIVE);
    } catch (e) {
      console.error('nextPageCustomer error', e);

      this.dispatch(R.actions.userOrganizationData.setUserOrganizationsStatus(REQUEST_STATUS.rejected));
      return;
    }

    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsStatus(REQUEST_STATUS.complete));

    if (!organizations) {
      return;
    }

    if (!organizations.length) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationsActiveIsEnd(true));
    }

    const organizationsCurrent = this.store.getState().userOrganizationData.organizationsActive;
    const organizationsIds = organizationsCurrent?.map((item) => item.id);
    const organizationsAdd = [
      ...organizationsCurrent || [],
      ...organizations,
    ].filter((item) => !organizationsIds?.includes(item.id));

    this.dispatch(R.actions.userOrganizationData.addsUserOrganizationsActive(organizationsAdd));
  }

  public reloadPageCustomerActive = async () => {
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsActiveIsEnd(false));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsActive([]));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsActivePage(0));
    const page = this.store.getState().userOrganizationData.organizationsActivePage || 0;

    await this.loadPageCustomerActive(page);

    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsActivePageIncrement());
  }

  public nextPageCustomerActive = async () => {
    const page = this.store.getState().userOrganizationData.organizationsActivePage || 0;

    this.loadPageCustomerActive(page);

    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsActivePageIncrement());
  }

  public nextPageCustomerWaitingApproval = async () => {
    const page = this.store.getState().userOrganizationData.organizationsWaitingApprovalPage;
    let organizations: OrganizationDTM[] | null;
    this.dispatch(R.actions.userOrganizationData.setUserSuccessMessage(''));
    this.dispatch(R.actions.userOrganizationData.setUserErrorMessage(''));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsStatus(REQUEST_STATUS.pending));

    try {
      organizations = await R.services.organization.getOrganizations(page, undefined, EOrganizationStatus.WAITING_APPROVAL);
    } catch (e) {
      console.error('nextPageCustomer error', e);

      this.dispatch(R.actions.userOrganizationData.setUserOrganizationsStatus(REQUEST_STATUS.rejected));
      return;
    }

    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsStatus(REQUEST_STATUS.complete));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsWaitingApprovalPageIncrement());

    if (!organizations) {
      return;
    }

    if (!organizations.length) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationsWaitingApprovalIsEnd(true));
    }

    const organizationsCurrent = this.store.getState().userOrganizationData.organizationsWaitingApproval;
    const organizationsIds = organizationsCurrent?.map((item) => item.id);
    const organizationsAdd = [
      ...organizationsCurrent || [],
      ...organizations,
    ].filter((item) => !organizationsIds?.includes(item.id));

    this.dispatch(R.actions.userOrganizationData.addsUserOrganizationsWaitingApproval(organizationsAdd));
  }

  public nextPageCustomerReject = async () => {
    const page = this.store.getState().userOrganizationData.organizationsRejectPage;
    let organizations: OrganizationDTM[] | null;
    this.dispatch(R.actions.userOrganizationData.setUserSuccessMessage(''));
    this.dispatch(R.actions.userOrganizationData.setUserErrorMessage(''));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsStatus(REQUEST_STATUS.pending));

    try {
      organizations = await R.services.organization.getOrganizations(page, undefined, EOrganizationStatus.REJECTED);
    } catch (e) {
      console.error('nextPageCustomer error', e);

      this.dispatch(R.actions.userOrganizationData.setUserOrganizationsStatus(REQUEST_STATUS.rejected));
      return;
    }

    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsStatus(REQUEST_STATUS.complete));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsRejectPageIncrement());

    if (!organizations) {
      return;
    }

    if (!organizations.length) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationsRejectIsEnd(true));
    }

    const organizationsCurrent = this.store.getState().userOrganizationData.organizationsReject;
    const organizationsIds = organizationsCurrent?.map((item) => item.id);
    const organizationsAdd = [
      ...organizationsCurrent || [],
      ...organizations,
    ].filter((item) => !organizationsIds?.includes(item.id));

    this.dispatch(R.actions.userOrganizationData.addsUserOrganizationsReject(organizationsAdd));
  }

  public onResetInternalPage = async () => {
    this.dispatch(R.actions.userOrganizationData.clearInternalCustomer());
  }

  public clear = () => {
    this.dispatch(R.actions.userOrganizationData.clear());
  }

  public closeEditProfileOrganizationDrawer = () => {
    const organization = this.store.getState().userOrganizationData?.organization;
    const organizationInternal = this.store.getState().userOrganizationData?.organizationInternal;
    const organizationUpdateOld = this.store.getState().userOrganizationData?.organizationUpdate;
    const organizationUpdate = organizationInternal || organization || undefined;

    this.dispatch(R.actions.accountDrawer.closeDrawer());
    if (!organizationUpdate) {
      this.dispatch(R.actions.userOrganizationData.resetUserOrganizationUpdate());
      return;
    }
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdate(OrganizationDTM.fromPlain({
      ...organizationUpdate,
      membersContacts: [...organizationUpdateOld?.membersContacts || []],
      membersContactsValue: organizationUpdateOld?.membersContactsValue ? { ...organizationUpdateOld?.membersContactsValue } : undefined,
      membersContactsValueOld: organizationUpdateOld?.membersContactsValueOld ? { ...organizationUpdateOld?.membersContactsValueOld } : undefined,
      documents: [],
    })));

    this.dispatch(R.actions.userOrganizationData.setUserSuccessMessage(''));
    this.dispatch(R.actions.userOrganizationData.setUserErrorMessage(''));

    this.dispatch(R.actions.userOrganizationData.resetUserOrganizationErrors());
  }

  public closeCustomerAddInternalDrawer = () => {
    this.dispatch(R.actions.accountDrawer.closeDrawer());

    this.dispatch(R.actions.userOrganizationData.resetCustomerAddInternal());

    this.dispatch(R.actions.userOrganizationData.setUserSuccessMessage(''));
    this.dispatch(R.actions.userOrganizationData.setUserErrorMessage(''));

    this.dispatch(R.actions.userOrganizationData.resetCustomerAddInternalErrors());
  }

  public closeAddressesAndContactsAddAddressDrawer = () => {
    this.dispatch(R.actions.accountDrawer.closeDrawer());

    this.dispatch(R.actions.contactBook.resetUpdate());
  }

  public closeAddressesAndContactsEditAddressDrawer = () => {
    this.dispatch(R.actions.accountDrawer.closeDrawer());

    this.dispatch(R.actions.contactBook.resetUpdate());
  }

  public saveEditProfileOrganization = async () => {
    const organizationCurrent = this.store.getState().userOrganizationData.organization;
    const organization = this.store.getState().userOrganizationData.organizationUpdate;
    const organizationInternal = this.store.getState().userOrganizationData?.organizationInternal;

    if (
      !organizationCurrent
      || organizationInternal?.id
      || (this.onValidateOrganizationFields() || !this.isUpdatedOrganizationFields())) {
      if (organizationInternal) {
        this.saveEditProfileOrganizationInternal();
      }
      return;
    }

    this.dispatch(R.actions.userOrganizationData.setUserSuccessMessage(''));
    this.dispatch(R.actions.userOrganizationData.setUserErrorMessage(''));

    const primaryContact = OrganizationPrimaryContactDTM.fromPlain({
      ...organization.primaryContact,
      firstName: organization.membersContactsValue?.firstName || '',
      lastName: organization.membersContactsValue?.lastName || '',
      email: organization.membersContactsValue?.email || '',
      phone: organization.membersContactsValue?.phone || '',
      // phone2: organization.membersContactsValue?.phone2,
      enabled: organization.membersContactsValue?.enabled,
      sub: organization.membersContactsValue?.sub,
    });

    try {
      await R.services.organization.putCurrentOrganization({
        ...organization,
        primaryContact,
      });
    } catch (e) {
      const errorMessage = e as string || 'Unknown error';

      this.dispatch(R.actions.userOrganizationData.setUserErrorMessage(errorMessage));

      throw e;
    }

    message.success(i18n.t('updateOrganizationProfile'));

    this.dispatch(R.actions.userOrganizationData.setUserOrganization({
      ...organization,
      primaryContact,
    }));

    this.dispatch(R.actions.accountDrawer.closeDrawer());

    this.dispatch(R.actions.userOrganizationData.setUserSuccessMessage(''));
    this.dispatch(R.actions.userOrganizationData.setUserErrorMessage(''));

    this.dispatch(R.actions.userOrganizationData.resetUserOrganizationErrors());
  }

  public saveEditProfileOrganizationInternal = async () => {
    const organization = this.store.getState().userOrganizationData.organizationUpdate;

    this.onCheckVisitedOrganizationFields();

    if (this.onValidateOrganizationFields() || !this.isUpdatedOrganizationInternalFields()) return;

    this.dispatch(R.actions.userOrganizationData.setUserSuccessMessage(''));
    this.dispatch(R.actions.userOrganizationData.setUserErrorMessage(''));

    const primaryContact = OrganizationPrimaryContactDTM.fromPlain({
      ...organization.primaryContact,
      firstName: organization.membersContactsValue?.firstName || '',
      lastName: organization.membersContactsValue?.lastName || '',
      email: organization.membersContactsValue?.email || '',
      phone: organization.membersContactsValue?.phone || '',
      // phone2: organization.membersContactsValue?.phone2,
      enabled: organization.membersContactsValue?.enabled,
      sub: organization.membersContactsValue?.sub,
    });

    try {
      await R.services.organization.putCurrentOrganization({
        ...organization,
        primaryContact,
      });
    } catch (e) {
      const errorMessage = e as string || 'Unknown error';

      this.dispatch(R.actions.userOrganizationData.setUserErrorMessage(errorMessage));

      throw e;
    }

    message.success(i18n.t('updateOrganizationProfile'));

    this.dispatch(R.actions.userOrganizationData.setCustomerInternalOrganization({
      ...organization,
      primaryContact,
    }));

    this.dispatch(R.actions.accountDrawer.closeDrawer());

    this.dispatch(R.actions.userOrganizationData.setUserSuccessMessage(''));
    this.dispatch(R.actions.userOrganizationData.setUserErrorMessage(''));

    this.dispatch(R.actions.userOrganizationData.resetCustomerAddInternalErrors());
  }

  public setTempTeam = (tempTeam: number) => {
    this.dispatch(R.actions.userOrganizationData.setTempTeam(tempTeam));
    this.dispatch(R.actions.userOrganizationData.setTempTeamError(false));
  }

  public setTempAccountManager = (email: string) => {
    this.dispatch(R.actions.userOrganizationData.setTempAccountManager(email));
    this.dispatch(R.actions.userOrganizationData.setTempAccountManagerError(false));
  }

  public setTempSalesManager = (email: string) => {
    this.dispatch(R.actions.userOrganizationData.setTempSalesManager(email));
    this.dispatch(R.actions.userOrganizationData.setTempSalesManagerError(false));
  }

  public saveCustomerAddInternal = async () => {
    let organization = this.store.getState().userOrganizationData.customersAddInternal;
    let newOrganization: OrganizationDTM | null = null;
    const teamToSave = R.selectors.userOrganizationData.getTeamToSave(this.store.getState());
    const accountManagerToSave = R.selectors.userOrganizationData.getAccountManagerToSave(this.store.getState());
    const salesManager = R.selectors.userOrganizationData.getSalesManagerToSave(this.store.getState());

    organization = {
      ...organization,
      accountTeam: teamToSave,
      accountManager: accountManagerToSave,
      salesManager,
    };

    this.onCheckVisitedCustomerAddInternalFields();
    if (this.onValidateCustomerAddInternalFields() || !this.isUpdatedCustomerAddInternalFields()) return;
    this.dispatch(R.actions.userOrganizationData.setUserSuccessMessage(''));
    this.dispatch(R.actions.userOrganizationData.setUserErrorMessage(''));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsAddStatus(REQUEST_STATUS.pending));

    try {
      newOrganization = await R.services.organization.postCurrentOrganization({
        ...organization,
      });
    } catch (e) {
      const errorMessage = e as string || 'Unknown error';
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationsAddStatus(REQUEST_STATUS.rejected));

      this.dispatch(R.actions.userOrganizationData.setUserErrorMessage(errorMessage));

      throw e;
    }

    if (!newOrganization) {
      this.dispatch(R.actions.userOrganizationData.setUserErrorMessage('Organization not added'));
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationsAddStatus(REQUEST_STATUS.rejected));
      this.callErrorNotification(i18n.t('RFQRequestErrors.COMMON_ERROR_TITLE'), { placement: 'bottomLeft' });
      return;
    }

    message.success(i18n.t('addOrganizationProfile'));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationsAddStatus(REQUEST_STATUS.complete));

    this.dispatch(R.actions.accountDrawer.closeDrawer());

    this.dispatch(R.actions.userOrganizationData.setUserSuccessMessage(''));
    this.dispatch(R.actions.userOrganizationData.setUserErrorMessage(''));

    this.dispatch(R.actions.userOrganizationData.resetCustomerAddInternal());
    this.dispatch(R.actions.userOrganizationData.resetCustomerAddInternalErrors());

    this.reloadPageCustomerActive();
  }

  public openCustomerAddInternal = () => {
    this.dispatch(R.actions.accountDrawer.openDrawer(EAccountDrawerContent.USER_CUSTOMER_ADD_INTERNAL));

    this.loadTeamsAndAccountManagers();
  }

  public openEditProfileOrganization = () => {
    this.dispatch(R.actions.accountDrawer.setType(EAccountDrawerType.ORGANIZATION));
    this.dispatch(R.actions.accountDrawer.openDrawer(EAccountDrawerContent.USER_EDIT_PROFILE_ORGANIZATION));
  }

  public openEditCustomerProfileOrganization = () => {
    this.dispatch(R.actions.accountDrawer.setType(EAccountDrawerType.CUSTOMER));
    this.dispatch(R.actions.accountDrawer.openDrawer(EAccountDrawerContent.USER_EDIT_PROFILE_ORGANIZATION));
  }

  public openEditPartnerProfileOrganization = () => {
    this.dispatch(R.actions.accountDrawer.setType(EAccountDrawerType.PARTNER));
    this.dispatch(R.actions.accountDrawer.openDrawer(EAccountDrawerContent.USER_EDIT_PROFILE_ORGANIZATION));
  }

  public onClickCustomerInternal = (id: string) => {
    this.navigate(RouteNames.ACCOUNT_CUSTOMER(id));
  }

  public goToPartnerOrganization = (id: string) => {
    this.navigate(RouteNames.ACCOUNT_PARTNER(id));
  }

  public onChangeUpdateName = (name: string) => {
    const validValue = name.slice(0, 100);
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateName(validValue));

    this.onValidateOrganizationFields();
  }

  public onBlurUpdateName = () => {
    this.onValidateOrganizationFields();
  }

  public onChangeUpdateType = (type: EOrganizationType) => {
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateType(type));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateIndustry(''));

    this.onValidateOrganizationFields();
  }

  public onChangeUpdateIndustry = (value: string) => {
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateIndustry(value));

    this.onValidateOrganizationFields();
  }

  public onChangeUpdateAddress1 = (address1: string) => {
    const validValue = address1.slice(0, 50);
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateAddress1(validValue));

    this.onValidateOrganizationFields();
  }

  public onChangeUpdateAddress2 = (address2: string) => {
    const validValue = address2.slice(0, 50);
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateAddress2(validValue));

    this.onValidateOrganizationFields();
  }

  public onChangeUpdateAddressCity = (city: string) => {
    const validValue = city.slice(0, 50);
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateAddressCity(validValue));

    this.onValidateOrganizationFields();
  }

  public onChangeUpdateAddressState = (state: string) => {
    this.stateSelectUseCase.onChange(state);
  }

  public onClearUpdateAddressState = () => {
    this.stateSelectUseCase.onClear();
  }

  public onBlurUpdateAddressState = () => {
    this.stateSelectUseCase.onBlur();
  }

  public onChangeUpdateCoverageArea = (values: string[]) => {
    const areas = values.map(
      (area) => OrganizationCoverageAreasDTM.fromPlain({
        countryCode: area,
        type: isCountry(area) ? CoverageAreaTypeEnum.COUNTRY : '',
      }),
    );
    this.coverageMultiSelectUseCase.onChange(areas);
  }

  public onClearUpdateCoverageArea = () => {
    this.coverageMultiSelectUseCase.onClear();
  }

  public onBlurUpdateCoverageArea = () => {
    this.coverageMultiSelectUseCase.onBlur();
  }

  public onChangeUpdateAddressPostalCode = (postalCode: string) => {
    const validValue = postalCode.slice(0, 10);
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateAddressPostalCode(validValue));

    this.onValidateOrganizationFields();
  }

  public onBlurUpdateAddressPostalCode = () => {
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsAddressPostalCode({
      isFocus: false,
      isBlur: true,
      isVisited: true,
    }));
    this.onValidateOrganizationFields();
  }

  public onChangeUpdateAddressCountry = (country: string) => {
    this.countrySelectUseCase.onChange(country);
  }

  public onClearUpdateAddressCountry = () => {
    this.countrySelectUseCase.onClear();
  }

  public onChangeUpdatePhone = (phone: string) => {
    const validValue = validationPhone(phone);
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdatePhone(validValue.phone));

    this.onValidateOrganizationFields();
  }

  public onChangeUpdatePhone2 = (phone2: string) => {
    const validValue = validationPhone(phone2);
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdatePhone2(validValue.phone));

    this.onValidateOrganizationFields();
  }

  public onChangeUpdateEmail = (email: string) => {
    const validValue = validationEmail(email);
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateEmail(validValue.email));

    this.onValidateOrganizationFields();
  }

  public onChangeUpdateWebUrl = (webUrl: string) => {
    const validValue = WebsiteUrl.fromPlain({ webUrl }).getValidValue();
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateWebUrl(validValue));

    this.onValidateOrganizationFields();
  }

  public onChangeUpdateBusinessRegistrationNumber = (businessRegistrationNumber: BusinessRegistrationNumberDtm) => {
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateBusinessRegistrationNumber(BusinessRegistrationNumberDtm.fromPlain({
      ...businessRegistrationNumber,
      valueTemp: businessRegistrationNumber.value.replace(/ /g, '').trim().slice(0, 16),
      value: businessRegistrationNumber.value.replace(/ /g, '').trim().slice(0, 16),
    })));

    this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsBusinessRegistrationNumber({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateOrganizationFields();
  }

  public onAddUpdateMembership = () => {
    const allowedTypes = R.selectors.userOrganizationData.getOrganizationAllowedMembershipTypes(this.store.getState());

    if (!allowedTypes.length) {
      return;
    }

    const newMembershipType = allowedTypes[0];

    const newMembership = OrganizationMembershipDtm.createEmptyWithSelectedType(newMembershipType);

    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateMemberships(newMembership));
  }

  public onRemoveUpdateMembership = (id: string) => {
    this.dispatch(R.actions.userOrganizationData.removeUserOrganizationUpdateMemberships(id));
  }

  public onChangeUpdateMembershipType = (id: string, type: EMembershipType) => {
    const organizationUpdate = R.selectors.userOrganizationData.getUserOrganizationUpdate(this.store.getState());
    const membership = organizationUpdate.memberships?.find(((item) => item.id === id));

    if (!membership) {
      return;
    }

    const newMembership = OrganizationMembershipDtm.fromPlain({
      id,
      type,
      value: membership.value,
      isNew: membership.isNew,
    });
    const newValue = newMembership.getSlicedValueByMaxLength();

    if (newValue) {
      newMembership.value = newValue;
    }

    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateMemberships(newMembership));

    this.onValidateOrganizationFields();
  }

  public onChangeUpdateMembershipValue = (id: string, value: string) => {
    const organizationUpdate = R.selectors.userOrganizationData.getUserOrganizationUpdate(this.store.getState());
    const membership = organizationUpdate.memberships?.find(((item) => item.id === id));
    const validValue = value.replace(/[^0-9]/g, '');

    if (!membership) {
      return;
    }

    this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsMembership(OrganizationFieldsErrorsFieldDTM.fromPlain({
      id,
      isFocus: true,
      isBlur: false,
    })));

    const newMembership = OrganizationMembershipDtm.fromPlain({
      id,
      value: validValue,
      type: membership.type,
      isNew: membership.isNew,
    });

    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateMemberships(newMembership));

    this.onValidateOrganizationFields();
  }

  public onChangeUpdateTaxId = (taxId: string) => {
    const validValue = taxId.slice(0, 35);
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateTaxId(validValue));

    this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsTaxId({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateOrganizationFields();
  }

  public onChangeUpdatePrimaryName = (id: string) => {
    const { organizationUpdate } = this.store.getState().userOrganizationData;
    const membersContactsValue = organizationUpdate.membersContacts?.filter((item: OrganizationMemberDTM) => item.id === id)?.[0];

    if (membersContactsValue) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdateMembersContactsValue(membersContactsValue));
    }

    this.onValidateOrganizationFields();
  }

  public onChangeUpdatePrimaryEmail = (primaryEmail: string) => {
    const validValue = validationEmail(primaryEmail);
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdatePrimaryEmail(validValue.email));

    this.onValidateOrganizationFields();
  }

  public onChangeUpdatePrimaryPhone = (phone: string) => {
    const validValue = validationPhone(phone);
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdatePrimaryPhone(validValue.phone));

    this.onValidateOrganizationFields();
  }

  public onChangeUpdatePrimaryPhone2 = (phone2: string) => {
    const validValue = validationPhone(phone2);
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationUpdatePrimaryPhone2(validValue.phone));

    this.onValidateOrganizationFields();
  }

  public onChangeCustomerAddInternalName = (name: string) => {
    const validValue = name.slice(0, 100);
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalName(validValue));

    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsName({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateCustomerAddInternalFields();
  }

  public onChangeCustomerAddInternalType = (type: EOrganizationType) => {
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalType(type));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalIndustry(''));

    this.onValidateCustomerAddInternalFields();
  }

  public onChangeCustomerAddInternalIndustry = (value: string) => {
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalIndustry(value));

    this.onValidateCustomerAddInternalFields();
  }

  public onChangeCustomerAddInternalAddress1 = (address1: string) => {
    const validValue = address1.slice(0, 50);
    this.dispatch(R.actions.userOrganizationData.setUserCustomerAddInternalAddress1(validValue));

    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsAddress1({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateCustomerAddInternalFields();
  }

  public onChangeCustomerAddInternalAddress2 = (address2: string) => {
    const validValue = address2.slice(0, 50);
    this.dispatch(R.actions.userOrganizationData.setUserCustomerAddInternalAddress2(validValue));

    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsAddress2({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateCustomerAddInternalFields();
  }

  public onChangeCustomerAddInternalAddressCity = (city: string) => {
    const validValue = city.slice(0, 50);
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalAddressCity(validValue));

    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsAddressCity({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateCustomerAddInternalFields();
  }

  public onChangeCustomerAddInternalAddressState = (state: string) => {
    this.internalAddressStateSelectUseCase.onChange(state);
  }

  public onClearCustomerAddInternalAddressState = () => {
    this.internalAddressStateSelectUseCase.onClear();
  }

  public onBlurCustomerAddInternalAddressState = () => {
    this.internalAddressStateSelectUseCase.onBlur();
  }

  public onChangeCustomerAddInternalAddressPostalCode = (postalCode: string) => {
    const validValue = postalCode.slice(0, 10);
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalAddressPostalCode(validValue));

    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsAddressPostalCode({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateCustomerAddInternalFields();
  }

  public onChangeCustomerAddInternalAddressCountry = (country: string) => {
    this.internalAddressCountrySelectUseCase.onChange(country);
  }

  public onClearCustomerAddInternalAddressCountry = () => {
    this.internalAddressCountrySelectUseCase.onClear();
  }

  public onChangeCustomerAddInternalEmail = (email: string) => {
    const validValue = validationEmail(email);
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalEmail(validValue.email));

    this.onValidateCustomerAddInternalFields();
  }

  public onChangeCustomerAddInternalBusinessRegistrationNumber = (businessRegistrationNumber: BusinessRegistrationNumberDtm) => {
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalBusinessRegistrationNumber(BusinessRegistrationNumberDtm.fromPlain({
      ...businessRegistrationNumber,
      valueTemp: businessRegistrationNumber.value.replace(/ /g, '').trim().slice(0, 16),
      value: businessRegistrationNumber.value.replace(/ /g, '').trim().slice(0, 16),
    })));

    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsBusinessRegistrationNumber({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateCustomerAddInternalFields();
  }

  public onAddCustomerAddInternalMembership = () => {
    const allowedTypes = R.selectors.userOrganizationData.getCustomersAddInternalAllowedMembershipTypes(this.store.getState());

    if (!allowedTypes.length) {
      return;
    }

    const newMembershipType = allowedTypes[0];

    const newMembership = OrganizationMembershipDtm.createEmptyWithSelectedType(newMembershipType);

    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalMembership(newMembership));
  }

  public onRemoveCustomerAddInternalMembership = (id: string) => {
    this.dispatch(R.actions.userOrganizationData.removeCustomerAddInternalMembership(id));
  }

  public onChangeCustomerAddInternalMembershipType = (id: string, type: EMembershipType) => {
    const organizationUpdate = R.selectors.userOrganizationData.getCustomersAddInternal(this.store.getState());
    const membership = organizationUpdate.memberships?.find(((item) => item.id === id));

    if (!membership) {
      return;
    }

    const newMembership = OrganizationMembershipDtm.fromPlain({
      id,
      type,
      value: membership.value,
      isNew: membership.isNew,
    });
    const newValue = newMembership.getSlicedValueByMaxLength();

    if (newValue) {
      newMembership.value = newValue;
    }

    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalMembership(newMembership));

    this.onValidateCustomerAddInternalFields();
  }

  public onChangeCustomerAddInternalMembershipValue = (id: string, value: string) => {
    const organizationUpdate = R.selectors.userOrganizationData.getCustomersAddInternal(this.store.getState());
    const membership = organizationUpdate.memberships?.find(((item) => item.id === id));
    const validValue = value.replace(/[^0-9]/g, '');

    if (!membership) {
      return;
    }

    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsMembership(OrganizationFieldsErrorsFieldDTM.fromPlain({
      id,
      isFocus: true,
      isBlur: false,
    })));

    const newMembership = OrganizationMembershipDtm.fromPlain({
      id,
      value: validValue,
      type: membership.type,
      isNew: membership.isNew,
    });

    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalMembership(newMembership));

    this.onValidateCustomerAddInternalFields();
  }

  public onChangeCustomerAddInternalTaxId = (taxId: string) => {
    const validValue = taxId.slice(0, 35);
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalTaxId(validValue));

    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsTaxId({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateCustomerAddInternalFields();
  }

  public onChangeCustomerAddInternalPhone = (phone: string) => {
    const validValue = validationPhone(phone);
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalPhone(validValue.phone));

    this.onValidateOrganizationFields();

    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPhone({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateCustomerAddInternalFields();
  }

  public onChangeCustomerAddInternalPhone2 = (phone2: string) => {
    const validValue = validationPhone(phone2);
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalPhone2(validValue.phone));

    this.onValidateOrganizationFields();

    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPhone2({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateCustomerAddInternalFields();
  }

  public onChangeCustomerAddInternalEmailInformation = (emailInformation: string) => {
    const validValue = validationEmail(emailInformation);
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalEmailInformation(validValue.email));

    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsEmailInformation({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateCustomerAddInternalFields();
  }

  public onChangeCustomerAddInternalWebUrl = (webUrl: string) => {
    const validValue = WebsiteUrl.fromPlain({ webUrl }).getValidValue();
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalWebUrl(validValue));

    this.onValidateCustomerAddInternalFields();
  }

  public onChangeCustomerAddInternalPrimaryName = (primaryName: string) => {
    const validValue = primaryName.slice(0, 50);
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalPrimaryFirstName(validValue));

    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPrimaryName({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateCustomerAddInternalFields();
  }

  public onChangeCustomerAddInternalPrimaryLastName = (primaryName: string) => {
    const validValue = primaryName.slice(0, 50);
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalPrimaryLastName(validValue));

    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPrimaryLastName({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateCustomerAddInternalFields();
  }

  public onChangeCustomerAddInternalPrimaryEmail = (primaryEmail: string) => {
    const validValue = validationEmail(primaryEmail);
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalPrimaryEmail(validValue.email));

    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPrimaryEmail({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateCustomerAddInternalFields();
  }

  public onChangeCustomerAddInternalPrimaryPhone = (phone: string) => {
    const validValue = validationPhone(phone);
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalPrimaryPhone(validValue.phone));

    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPrimaryPhone({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateCustomerAddInternalFields();
  }

  public onChangeCustomerAddInternalPrimaryPhone2 = (phone2: string) => {
    const validValue = validationPhone(phone2);
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalPrimaryPhone2(validValue.phone));

    this.onValidateOrganizationFields();

    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPrimaryPhone2({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateCustomerAddInternalFields();
  }

  public onChangeCustomerAddInternalPaymentType = (value: string) => {
    const organizationUpdate = R.selectors.userOrganizationData.getCustomersAddInternal(this.store.getState());
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalPaymentMethod(OrganizationPaymentMethodDTM.fromPlain({
      ...OrganizationPaymentMethodDTM.createEmpty(),
      ...organizationUpdate?.paymentMethod,
      type: value,
    })));

    this.onValidateOrganizationFields();

    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPaymentType({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateCustomerAddInternalFields();
  }

  public onChangeCustomerAddInternalPaymentCreditTerm = (value: number) => {
    const organizationUpdate = R.selectors.userOrganizationData.getCustomersAddInternal(this.store.getState());
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalPaymentMethod(OrganizationPaymentMethodDTM.fromPlain({
      ...OrganizationPaymentMethodDTM.createEmpty(),
      ...organizationUpdate?.paymentMethod,
      creditTerm: value,
    })));

    this.onValidateOrganizationFields();

    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPaymentCreditTerm({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateCustomerAddInternalFields();
  }

  public onChangeCustomerAddInternalPaymentCreditLimit = (value: string) => {
    const validValue = `${value}`.replace(/[(\D)-]/g, '').slice(0, 20);

    const organizationUpdate = R.selectors.userOrganizationData.getCustomersAddInternal(this.store.getState());
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalPaymentMethod(OrganizationPaymentMethodDTM.fromPlain({
      ...OrganizationPaymentMethodDTM.createEmpty(),
      ...organizationUpdate?.paymentMethod,
      creditLimit: Number(Number(validValue).toFixed(2)),
    })));

    this.onValidateOrganizationFields();

    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPaymentCreditLimit({
      isFocus: true,
      isBlur: false,
    }));
    this.onValidateCustomerAddInternalFields();
  }

  public abortAllRequests = () => {
    apiWorker.abortAllRequests();
  }

  private isUpdatedOrganizationFields = () => {
    const { organization, organizationUpdate } = this.store.getState().userOrganizationData;

    let isUpdate = false;

    if (
      organization?.address.address1 !== organizationUpdate.address.address1
      || organization?.address.address2 !== organizationUpdate.address.address2
      || organization?.address.city !== organizationUpdate.address.city
      || organization?.address.state !== organizationUpdate.address.state
      || organization?.address.closestPort !== organizationUpdate.address.closestPort
      || organization?.address.country !== organizationUpdate.address.country
      || organization?.name !== organizationUpdate.name
      || organization?.phone !== organizationUpdate.phone
      || organization?.phone2 !== organizationUpdate.phone2
      || organization?.email !== organizationUpdate.email
      || organization?.webUrl !== organizationUpdate.webUrl
      || organization?.businessRegistrationNumber !== organizationUpdate.businessRegistrationNumber
      || organization?.memberships?.[0]?.value !== organizationUpdate.memberships?.[0]?.value
      || organization?.memberships?.[1]?.value !== organizationUpdate.memberships?.[1]?.value
      || organization?.taxId !== organizationUpdate.taxId
      || organizationUpdate?.membersContactsValue !== organizationUpdate.membersContactsValueOld
    ) {
      isUpdate = true;
    }

    return isUpdate;
  }

  private isUpdatedOrganizationInternalFields = () => {
    const { organizationInternal: organization, organizationUpdate } = this.store.getState().userOrganizationData;

    let isUpdate = false;

    if (
      organization?.address.address1 !== organizationUpdate.address.address1
      || organization?.address.address2 !== organizationUpdate.address.address2
      || organization?.address.city !== organizationUpdate.address.city
      || organization?.address.state !== organizationUpdate.address.state
      || organization?.address.closestPort !== organizationUpdate.address.closestPort
      || organization?.address.country !== organizationUpdate.address.country
      || organization?.name !== organizationUpdate.name
      || organization?.phone !== organizationUpdate.phone
      || organization?.phone2 !== organizationUpdate.phone2
      || organization?.email !== organizationUpdate.email
      || organization?.webUrl !== organizationUpdate.webUrl
      || organization?.businessRegistrationNumber !== organizationUpdate.businessRegistrationNumber
      || organization?.memberships?.[0]?.value !== organizationUpdate.memberships?.[0]?.value
      || organization?.memberships?.[1]?.value !== organizationUpdate.memberships?.[1]?.value
      || organization?.taxId !== organizationUpdate.taxId
      || organizationUpdate?.membersContactsValue !== organizationUpdate.membersContactsValueOld
    ) {
      isUpdate = true;
    }

    return isUpdate;
  }

  private isUpdatedCustomerAddInternalFields = () => {
    const { customersAddInternal } = this.store.getState().userOrganizationData;

    let isUpdate = false;

    if (
      (customersAddInternal?.address?.address1 && customersAddInternal?.address?.address1 !== '')
      || (customersAddInternal?.address?.address2 && customersAddInternal?.address?.address2 !== '')
      || (customersAddInternal?.address?.city && customersAddInternal?.address?.city !== '')
      || (customersAddInternal?.address?.state && customersAddInternal?.address?.state !== '')
      || (customersAddInternal?.address?.closestPort && customersAddInternal?.address?.closestPort !== '')
      || (customersAddInternal?.name && customersAddInternal?.name !== '')
      || (customersAddInternal?.phone && customersAddInternal?.phone !== '')
      || (customersAddInternal?.phone2 && customersAddInternal?.phone2 !== '')
      || (customersAddInternal?.email && customersAddInternal?.email !== '')
      || (customersAddInternal?.businessRegistrationNumber.getValidValue() && customersAddInternal?.businessRegistrationNumber.getValidValue() !== '')
      || (customersAddInternal?.memberships?.[0] && customersAddInternal?.memberships[0].getValidValue() !== '')
      || (customersAddInternal?.taxId && customersAddInternal?.taxId !== '')
      || (customersAddInternal?.phone && customersAddInternal?.phone !== '')
      || (customersAddInternal?.phone2 && customersAddInternal?.phone2 !== '')
      || (customersAddInternal?.primaryContact?.firstName && customersAddInternal?.primaryContact?.firstName !== '')
      || (customersAddInternal?.primaryContact?.lastName && customersAddInternal?.primaryContact?.lastName !== '')
      || (customersAddInternal?.primaryContact?.email && customersAddInternal?.primaryContact?.email !== '')
      || (customersAddInternal?.primaryContact?.phone && customersAddInternal?.primaryContact?.phone !== '')
      || (customersAddInternal?.emailInformation && customersAddInternal?.emailInformation !== '')
      || (customersAddInternal?.webUrl && customersAddInternal?.webUrl.getValidValue() !== '')
    ) {
      isUpdate = true;
    }

    return isUpdate;
  }

  private onCheckVisitedOrganizationFields = () => {
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsName({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsType({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsIndustry({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsAddress1({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsAddress2({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsAddressCity({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsAddressState({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsAddressPostalCode({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsAddressCountry({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsPhone({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsPhone2({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsEmail({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsWebUrl({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsBusinessRegistrationNumber({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsAllMembershipsVisited(true));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsTaxId({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsMembersContactsValue({
      isVisited: true,
    }));
  }

  private onCheckVisitedCustomerAddInternalFields = () => {
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPaymentType({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsType({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsIndustry({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPaymentCreditTerm({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPaymentCreditLimit({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsName({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsAddress1({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsAddress2({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsAddressCity({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsAddressState({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsAddressPostalCode({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsAddressCountry({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsEmail({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsBusinessRegistrationNumber({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsAllMembershipsVisited(true));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsTaxId({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPhone({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPhone2({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsEmailInformation({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsWebUrl({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPrimaryName({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPrimaryLastName({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPrimaryEmail({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPrimaryPhone({
      isVisited: true,
    }));
    this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPrimaryPhone2({
      isVisited: true,
    }));
  }

  private onValidateOrganizationFields = () => {
    const organization = this.store.getState().userOrganizationData.organizationUpdate;
    const errors = this.store.getState().userOrganizationData.organizationFieldsErrors;
    let isError = false;

    if (organization?.name) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsName({
        message: '',
      }));
    } else {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsName({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.type) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsType({
        message: '',
      }));
    } else {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsType({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (errors?.coverageAreas?.isVisited && organization?.coverageAreas && organization.coverageAreas.length === 0) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsCoverageAreas({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    } else {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsCoverageAreas({
        message: '',
      }));
    }

    if (organization?.type === EOrganizationType.BCO && !organization?.industry) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsIndustry({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    } else {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsIndustry({
        message: '',
      }));
    }

    if (organization?.address?.address1) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsAddress1({
        message: '',
      }));
    } else {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsAddress1({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.address?.city) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsAddressCity({
        message: '',
      }));
    } else {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsAddressCity({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.address?.state) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsAddressState({
        message: '',
      }));
    } else if (organization?.address?.country === 'US') {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsAddressState({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    } else if (organization?.address?.country && organization?.address?.country !== 'US') {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsAddressState({
        message: '',
      }));
    } else if (!organization?.address?.country) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsAddressState({
        message: '',
      }));
    }

    if (organization?.address?.postalCode) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsAddressPostalCode({
        message: '',
      }));
    } else {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsAddressPostalCode({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.address?.country) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsAddressCountry({
        message: '',
      }));
    } else {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsAddressCountry({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.phone && !validationPhone(organization?.phone).errorMessage) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsPhone({
        message: '',
      }));
    } else if (organization?.phone && validationPhone(organization?.phone).errorMessage) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsPhone({
        message: validationPhone(organization?.phone).errorMessage,
      }));

      isError = true;
    } else {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsPhone({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.phone2 && !validationPhone(organization?.phone2).errorMessage) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsPhone2({
        message: '',
      }));
    } else if (organization?.phone2 && validationPhone(organization?.phone2).errorMessage) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsPhone2({
        message: validationPhone(organization?.phone2).errorMessage,
      }));

      isError = true;
    }

    if (organization?.email === '') {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsEmail({
        message: '',
      }));
    } else if (organization?.email) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsEmail({
        message: validationEmail(organization?.email).errorMessage,
      }));

      if (validationEmail(organization?.email).errorMessage) {
        isError = true;
      }
    }

    if (organization?.webUrl?.webUrl && !organization?.webUrl?.hasError()) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsWebUrl({
        message: '',
      }));
    } else if (organization?.webUrl?.webUrl && organization?.webUrl?.hasError()) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsWebUrl({
        message: organization?.webUrl.getErrorMessage(),
      }));

      isError = true;
    } else if (!organization?.webUrl?.webUrl) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsWebUrl({
        message: '',
      }));
    }

    if (organization?.businessRegistrationNumber.value) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsBusinessRegistrationNumber({
        message: '',
      }));
    } else if (organization?.businessRegistrationNumber.value === '') {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsBusinessRegistrationNumber({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    // Currently off
    // if (organization?.businessRegistrationNumber.isValid()) {
    //   this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsBusinessRegistrationNumber({
    //     message: '',
    //   }));
    // } else if (organization?.businessRegistrationNumber.value === '') {
    //   this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsBusinessRegistrationNumber({
    //     message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
    //   }));
    //   isError = true;
    // } else {
    //   this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsBusinessRegistrationNumber({
    //     message: i18n.t('basicErrors.NOT_VALID_PATTERN_EIN'),
    //   }));
    //   isError = true;
    // }

    organization?.memberships?.forEach((item) => {
      if (item.value) {
        const errorMessage = item.getErrorMessage();

        this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsMembership({
          id: item.id,
          message: errorMessage,
        }));

        if (errorMessage) {
          isError = true;
        }
      }
    });

    if (organization?.membersContactsValue) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsMembersContactsValue({
        message: '',
      }));
    } else {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsMembersContactsValue({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    return isError;
  }

  private onValidateCustomerAddInternalFields = () => {
    const organization = this.store.getState().userOrganizationData.customersAddInternal;
    const tempTeam = R.selectors.userOrganizationData.getTempTeam(this.store.getState());
    const tempAccountManager = R.selectors.userOrganizationData.getTempAccountManager(this.store.getState());
    const tempSalesManager = R.selectors.userOrganizationData.getTempSalesManager(this.store.getState());
    let isError = false;

    if (!tempTeam) {
      this.dispatch(R.actions.userOrganizationData.setTempTeamError(true));
      isError = true;
    }

    if (!tempSalesManager) {
      this.dispatch(R.actions.userOrganizationData.setTempSalesManagerError(true));
      isError = true;
    }

    if (!tempAccountManager) {
      this.dispatch(R.actions.userOrganizationData.setTempAccountManagerError(true));
      isError = true;
    }

    if (organization?.paymentMethod?.type) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPaymentType({
        message: '',
      }));
    } else {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPaymentType({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.type) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsType({
        message: '',
      }));
    } else {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsType({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.type === EOrganizationType.BCO && !organization?.industry) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsIndustry({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    } else {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsIndustry({
        message: '',
      }));
    }

    if (organization?.paymentMethod?.creditTerm) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPaymentCreditTerm({
        message: '',
      }));
    } else if (organization?.paymentMethod?.getTypeReq() === OrganizationPaymentMethod.DEFERRED_PAYMENT && !organization?.paymentMethod?.creditTerm) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPaymentCreditTerm({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.paymentMethod?.creditLimit) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPaymentCreditLimit({
        message: '',
      }));
    } else if (organization?.paymentMethod?.getTypeReq() === OrganizationPaymentMethod.DEFERRED_PAYMENT && !organization?.paymentMethod?.creditLimit) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPaymentCreditLimit({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.name) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsName({
        message: '',
      }));
    } else {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsName({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.primaryContact?.firstName) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPrimaryName({
        message: '',
      }));
    } else {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPrimaryName({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.primaryContact?.lastName) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPrimaryLastName({
        message: '',
      }));
    } else {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPrimaryLastName({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.address?.address1) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsAddress1({
        message: '',
      }));
    } else {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsAddress1({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.address?.city) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsAddressCity({
        message: '',
      }));
    } else {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsAddressCity({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.address?.state) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsAddressState({
        message: '',
      }));
    } else if (organization?.address?.country === 'US') {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsAddressState({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    } else if (organization?.address?.country && organization?.address?.country !== 'US') {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsAddressState({
        message: '',
      }));
    } else if (!organization?.address?.country) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsAddressState({
        message: '',
      }));
    }

    if (organization?.address?.postalCode) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsAddressPostalCode({
        message: '',
      }));
    } else {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsAddressPostalCode({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.address?.country) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsAddressCountry({
        message: '',
      }));
    } else {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsAddressCountry({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.primaryContact?.email) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPrimaryEmail({
        message: validationEmail(organization?.primaryContact?.email).errorMessage,
      }));

      if (validationEmail(organization?.primaryContact?.email).errorMessage) {
        isError = true;
      }
    } else {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPrimaryEmail({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.businessRegistrationNumber.getValidValue()) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsBusinessRegistrationNumber({
        message: '',
      }));
    } else if (!organization?.businessRegistrationNumber.getValidValue()) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsBusinessRegistrationNumber({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    // Currently off
    // if (organization?.businessRegistrationNumber.getValidValue() && !organization?.businessRegistrationNumber.hasError()) {
    //   this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsBusinessRegistrationNumber({
    //     message: '',
    //   }));
    // } else if (organization?.businessRegistrationNumber.getValidValue() && organization?.businessRegistrationNumber.hasError()) {
    //   this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsBusinessRegistrationNumber({
    //     message: organization?.businessRegistrationNumber.getErrorMessage(),
    //   }));
    // } else {
    //   this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsBusinessRegistrationNumber({
    //     message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
    //   }));
    //   isError = true;
    // }

    if (organization.primaryContact?.phone && !validationPhone(organization?.primaryContact?.phone).errorMessage) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPrimaryPhone({
        message: '',
      }));
    } else if (organization?.primaryContact?.phone && validationPhone(organization?.primaryContact?.phone).errorMessage) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPrimaryPhone({
        message: validationPhone(organization?.primaryContact?.phone).errorMessage,
      }));

      isError = true;
    } else {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPrimaryPhone({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.phone && !validationPhone(organization?.phone).errorMessage) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPhone({
        message: '',
      }));
    } else if (organization?.phone && validationPhone(organization?.phone).errorMessage) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPhone({
        message: validationPhone(organization?.phone).errorMessage,
      }));

      isError = true;
    } else {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPhone({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    if (organization?.phone2 && !validationPhone(organization?.phone2).errorMessage) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPhone2({
        message: '',
      }));
    } else if (organization?.phone2 && validationPhone(organization?.phone2).errorMessage) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsPhone2({
        message: validationPhone(organization?.phone2).errorMessage,
      }));

      isError = true;
    }

    if (organization?.emailInformation === '') {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsEmailInformation({
        message: '',
      }));
    } else if (organization?.emailInformation) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsEmailInformation({
        message: validationEmail(organization?.emailInformation).errorMessage,
      }));

      if (validationEmail(organization?.emailInformation).errorMessage) {
        isError = true;
      }
    }

    if (organization?.webUrl?.webUrl && !organization?.webUrl?.hasError()) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsWebUrl({
        message: '',
      }));
    } else if (organization?.webUrl?.webUrl && organization?.webUrl?.hasError()) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsWebUrl({
        message: organization?.webUrl.getErrorMessage(),
      }));

      isError = true;
    } else if (!organization?.webUrl?.webUrl) {
      this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsWebUrl({
        message: '',
      }));
    }

    if (organization?.businessRegistrationNumber.value) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsBusinessRegistrationNumber({
        message: '',
      }));
    } else if (organization?.businessRegistrationNumber.value === '') {
      this.dispatch(R.actions.userOrganizationData.setUserOrganizationErrorsBusinessRegistrationNumber({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    }

    // Currently off
    // if (organization?.businessRegistrationNumber.isValid()) {
    //   this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsBusinessRegistrationNumber({
    //     message: '',
    //   }));
    // } else if (organization?.businessRegistrationNumber.value === '') {
    //   this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsBusinessRegistrationNumber({
    //     message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
    //   }));
    //   isError = true;
    // } else {
    //   this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsBusinessRegistrationNumber({
    //     message: i18n.t('basicErrors.NOT_VALID_PATTERN_EIN'),
    //   }));
    //   isError = true;
    // }

    organization?.memberships?.forEach((item) => {
      if (item.value) {
        const errorMessage = item.getErrorMessage();

        this.dispatch(R.actions.userOrganizationData.setCustomerAddInternalErrorsMembership({
          id: item.id,
          message: errorMessage,
        }));

        if (errorMessage) {
          isError = true;
        }
      }
    });

    return isError;
  }
}
