import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { REQUEST_STATUS } from 'app-wrapper/constants';

import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';

import { FreightQuoteContentComponent } from './FreightQuoteContent.component';

const FreightQuoteContentContainer: FC = () => {
  const {
    isCollapse,
    isShowModalRequestName, form, quotaRFRRequestOptions,
    isQuotaEmptyError,
    status,
  } = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const quotas = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentQuotas);
  const filters = useSelector(R.selectors.freightQuote.getFreightQuoteFilters);
  const RFQNewQuotaAvailability = useSelector(R.selectors.moduleFunctionalityPermissions.getRFQNewQuotaPermissions);

  const isDisableModalRequestName = !quotas?.allQuotas?.length || !!form?.bookmark?.name;
  const quotasCreateFailedMessage = quotas?.quotasOption?.quotasCreateFailedMessage;

  const isEmptyBg = status === REQUEST_STATUS.complete || status === REQUEST_STATUS.rejected || Boolean(filters?.requestFiltersStatus);

  return (
    <FreightQuoteContentComponent
      isCollapse={isCollapse}
      isEmptyBg={isEmptyBg}
      isCollapseAndEmptyQuota={isCollapse && !quotas?.allQuotas?.length}
      RFQNewQuotaAvailability={RFQNewQuotaAvailability}
      quotasCreateFailedMessage={quotasCreateFailedMessage}
      isShowModalRequestName={isShowModalRequestName}
      isDisableModalRequestName={isDisableModalRequestName}
      isQuotaEmptyError={isQuotaEmptyError}
      quotaId={quotas?.quotasOption?.id}
      quotaRFRRequestOptions={quotaRFRRequestOptions}
      onOpenRequestSavedName={UC.FreightQuote.onOpenRequestSavedName}
      onStartFirstRunSupportAllRequestPage={UC.FreightQuote.onStartFirstRunSupportAllRequestPage}
      onStartFirstRun={UC.FreightQuote.onStartFirstRun}
      abortAllRequests={UC.FreightQuote.abortAllRequests}
      onClickEmptyQuota={UC.FreightRFRQuote.onClickEmptyQuota}
      onClickModifyQuota={UC.FreightRFRQuote.onClickModifyQuota}
      onClickOpenModal={UC.FreightRFRQuote.onChangeIsOpenModalRate(true)}
    />
  );
};

const FreightQuoteContentContainerCached = memo(FreightQuoteContentContainer);

export { FreightQuoteContentContainerCached as FreightQuoteContent };
