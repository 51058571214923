import styled from 'styled-components';
import AntDatePicker from 'antd/es/date-picker';

export const DatePicker = styled(AntDatePicker)`
  width: 100%;

  &.ant-picker-focused {
    box-shadow: none !important;
    border: 1px solid ${({ theme }) => theme?.themesColors?.primaryBranding6} !important;
  }
`;
