import i18n from 'app-wrapper/i18n/i18n';
import { NotificationMessageError } from 'app-wrapper/models/errors/NotificationMessageError';
import { currentSession } from 'app-wrapper/utils';
import {
  signUp,
  signIn,
  fetchAuthSession,
  confirmSignUp,
  updatePassword,
  resetPassword,
  confirmResetPassword,
  updateUserAttributes,
} from 'aws-amplify/auth';

import { IErrorExceptionDTM } from 'app-wrapper/models/contracts';

import {
  ISignInDTM,
  ISignUpDTM,
  UserAuthDataDTM,
} from 'authentication/models/dtm';
import {
  TChangePasswordErrorContract,
  TForgotPasswordErrorContract,
  TGetUserDataErrorContract,
} from 'authentication/models/contracts';
import { ErrorNoCurrentUser } from 'authentication/models/errors';

export class AuthService {
  public signUpAuth = async ({
    firstName, lastName, email, password,
  }: ISignUpDTM) => {
    try {
      const response = await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            given_name: firstName,
            family_name: lastName,
          },
          autoSignIn: true,
        },
      });

      return response;
    } catch (e: unknown) {
      throw new Error(`${(e as IErrorExceptionDTM)?.message || 'Something wrong, please try again'}`);
    }
  };

  public signInAuth = async ({ email, password }: ISignInDTM) => {
    try {
      await signIn({
        username: email,
        password,
        options: {
          authFlowType: 'USER_SRP_AUTH',
        },
      });

      const cognitoUserData = await this.getUserDataFromSession();

      const userData = UserAuthDataDTM.fromPlain(cognitoUserData);

      if (!userData.isValid()) {
        console.error(userData.validate());
      }

      return userData;
    } catch (e: unknown) {
      throw new Error(`${(e as IErrorExceptionDTM)?.message || 'Something wrong, please try again'}`);
    }
  };

  public outAuth = async () => {
    try {
      currentSession.signOut();
    } catch (e) {
      throw new Error('Something wrong, please try again');
    }
  };

  public setLoggedIn = async () => {
    try {
      const x = await fetchAuthSession();

      return x;
    } catch (e) {
      if (e === 'No current user') {
        throw new ErrorNoCurrentUser();
      }

      throw new Error('Something wrong, please try again');
    }
  };

  public onCheckUser = async () => {
    try {
      const cognitoUserData = await this.getUserDataFromSession();

      const userData = UserAuthDataDTM.fromPlain(cognitoUserData);

      if (!userData.isValid()) {
        console.error(userData.validate());
      }

      return userData;
    } catch (e) {
      throw new Error('User not found');
    }
  };

  public confirmSignUp = async (
    username: string, confirmationCode: string,
  ) => {
    try {
      const response = await confirmSignUp({ username, confirmationCode });

      return response;
    } catch (e) {
      throw new Error('User not found');
    }
  };

  public changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      await updatePassword({ oldPassword, newPassword });
    } catch (e) {
      const errorData = e as TChangePasswordErrorContract;
      const errorString = e as string;

      if (['NotAuthorizedException', 'InvalidParameterException'].includes(errorData.name)) {
        throw new NotificationMessageError(i18n.t('Your current password is incorrect'));
      }

      throw new NotificationMessageError(errorData?.message ? i18n.t(errorData.message) : errorString);
    }
  };

  public passwordResetEmailSend = async (email: string) => {
    try {
      await resetPassword({ username: email });
    } catch (e) {
      const error = e as TForgotPasswordErrorContract;

      throw new Error(error.message);
    }
  };

  public passwordResetNewPassword = async (email: string, code: string, newPassword: string) => {
    try {
      await confirmResetPassword({ username: email, newPassword, confirmationCode: code });
    } catch (e) {
      const error = e as TForgotPasswordErrorContract;

      throw new Error(error.message);
    }
  };

  public changeUserData = async (firstName: string, lastName: string) => {
    try {
      await updateUserAttributes({
        userAttributes: {
          given_name: firstName,
          family_name: lastName,
        },
      });
    } catch (e) {
      const error = e as TForgotPasswordErrorContract;

      throw new Error(error.message);
    }
  };

  public getUserData = async () => {
    try {
      const cognitoUserData = await this.getUserDataFromSession();

      const userData = UserAuthDataDTM.fromPlain(cognitoUserData);

      if (!userData.isValid()) {
        console.error(userData.validate());
      }

      return userData;
    } catch (e) {
      const error = e as TGetUserDataErrorContract;

      throw new Error(error.message);
    }
  }

  private async getUserDataFromSession() {
    const session = await fetchAuthSession();
    if (!session.tokens?.idToken) {
      throw new Error('Session not found');
    }

    const cognitoUserData = session?.tokens?.idToken?.payload;
    return {
      email: cognitoUserData?.email as string,
      firstName: cognitoUserData?.given_name as string,
      lastName: cognitoUserData?.family_name as string,
      userId: cognitoUserData?.['cognito:username'] as string,
      isAdmin: (cognitoUserData?.['cognito:groups'] as string[])?.includes('FreightuneAdmin'),
    };
  }
}
