import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 240px;
  border-right: 1px solid #EAEEF8;
  padding: 24px;
  min-height: calc(100vh - 56px);
`;

export const Name = styled.span`
  color: ${({ theme }) => theme?.themesColors?.darkGrayMiddle};
  text-decoration: underline;
  text-decoration-style: dotted;
  font-size: 12px;
  cursor: pointer;
`;

export const MarginTopContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
`;
