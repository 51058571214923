import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';

import { DestinationOverlayComponent } from './DestinationOverlay.component';

export const DestinationOverlay = React.memo(() => {
  const сurrent = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const origin = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentOrigin);
  const destination = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentDestination);
  const errors = useSelector(R.selectors.freightQuote.getFreightQuoteErrors);

  const originErrors = useMemo(() => ({
    isPort: errors?.origin?.isPort,
    dateDoor: errors?.origin?.dateDoor,
    datePort: errors?.origin?.datePort,
    location: errors?.origin?.location,
  }), [errors?.origin]);

  const destinationErrors = useMemo(() => ({
    isPort: errors?.destination?.isPort,
    dateDoor: errors?.destination?.dateDoor,
    datePort: errors?.destination?.datePort,
    location: errors?.destination?.location,
  }), [errors?.destination]);

  return (
    <DestinationOverlayComponent
      origin={origin}
      destination={destination}
      originErrors={originErrors}
      destinationErrors={destinationErrors}
      onChangeDestinationTypeToPort={UC.FreightQuote.onChangeDestinationTypeToPort}
      onChangePortDateDestination={UC.FreightQuote.onChangePortDateDestination}
      onFocusPortDateDestination={UC.FreightQuote.onFocusPortDateDestination}
      onBlurPortDateDestination={UC.FreightQuote.onBlurPortDateDestination}
      isSubmitVisited={сurrent?.isSubmitVisited}
    />
  );
});
