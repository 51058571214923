import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CargoDTM } from 'shipment-operations/models/dtm';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { HsCodeInputComponent, IHsCodeInputComponentProps } from 'shipment-operations/view/components';

interface HSCodeInputComponentProps extends Omit<IHsCodeInputComponentProps, 'setHsCode' | 'touchField'> {
  setHsCode: (shipmentId: number, hsCode: CargoDTM['code'], cargoId: number) => void;
  touchCargoField: (fieldName: string, cargoId: number) => void;
  isTemperatureControlled: boolean
  cargoId: number;
  dropdownMatchSelectWidth?: number;
  cargosAvailability?: PermissionAttributePolicy;
}

export const HSCodeInputComponent: FC<HSCodeInputComponentProps> = ({
  setHsCode,
  touchCargoField,
  isTemperatureControlled,
  cargoId,
  cargosAvailability,
  dropdownMatchSelectWidth,
  ...props
}) => {
  const { t } = useTranslation();

  const setHSCode = useCallback((shipmentId: number, hsCode: CargoDTM['code']) => {
    setHsCode(shipmentId, hsCode, cargoId);
  }, [cargoId, setHsCode]);

  const touchField = useCallback((field: string) => {
    touchCargoField(field, cargoId);
  }, [cargoId, touchCargoField]);

  return (
    <HsCodeInputComponent
      {...props}
      placeholder={t('Enter HS Code or Commodity Name')}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      setHsCode={setHSCode}
      touchField={touchField}
      isTemperatureControlled={isTemperatureControlled}
      cargosAvailability={cargosAvailability}
    />
  );
};
