import styled from 'styled-components';
import { SmallTextFootnoteDescriptionSpan, BodyTextRegular } from 'app-wrapper/view/fonts';
import { ContentSection } from 'app-wrapper/view/guideline';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: flex-start;
`;

export const InputsWrapper = styled.div`
  display: flex;
  width: 50%;
  gap: 8px;
`;

export const InputWrapper = styled(SmallTextFootnoteDescriptionSpan)`
  color: ${({ theme }) => theme?.themesColors?.darkGrayMiddle};
`;

export const InputValue = styled.span`
  ${BodyTextRegular}
`;

export const UploadSection = styled(ContentSection)`
  padding: 23px 16px;
`;
