import message from 'antd/es/message';

import i18n from 'app-wrapper/i18n/i18n';

import { R } from 'monetary/repository';
import { ERateTypes } from 'monetary/constants';
import { RateChangesDtm } from 'monetary/models/dtm';

import { BaseController, controller } from 'proto/BaseController';

@controller
export class RatesController extends BaseController {
  fetchData = async () => {
    const ratesList = R.services.rate.getRates();

    this.dispatch(R.actions.rates.setRatesList(ratesList));
  }

  sendFile = async (rateType: ERateTypes, file?: File) => {
    let rateChangesData: RateChangesDtm | null = null;

    if (!file) {
      console.error('RatesController.sendFile error: no file');

      return;
    }

    this.dispatch(R.actions.rates.setIsLoading(true));
    this.dispatch(R.actions.rates.setFileSend(rateType));

    try {
      if (rateType === ERateTypes.OCEAN_BASE_RATE) {
        rateChangesData = await R.services.rate.uploadOceanRate(file);
      }

      if (rateType === ERateTypes.OCEAN_SURCHARGES) {
        rateChangesData = await R.services.rate.uploadOceanSurchargesRate(file);
      }

      if (rateType === ERateTypes.SERVICE_RATES) {
        rateChangesData = await R.services.rate.uploadServiceRate(file);
      }

      if (rateType === ERateTypes.FEES) {
        rateChangesData = await R.services.rate.uploadFeesRate(file);
      }

      if (rateType === ERateTypes.PENALTIES_AND_FREE_TIME) {
        rateChangesData = await R.services.rate.uploadPenaltiesRate(file);
      }

      if (rateType === ERateTypes.DRAYAGE_BASE_RATE) {
        rateChangesData = await R.services.rate.uploadDrayageRate(file);
      }

      if (rateType === ERateTypes.DRAYAGE_SURCHARGES) {
        rateChangesData = await R.services.rate.uploadDrayageSurcharges(file);
      }
    } catch (e) {
      this.dispatch(R.actions.rates.setIsLoading(false));
      this.dispatch(R.actions.rates.setFileSend(''));

      throw e;
    }

    if (!rateChangesData) {
      return;
    }
    this.callSuccessNotification(`${file.name} uploaded: ${rateChangesData.getDataAsString()}`);

    this.dispatch(R.actions.rates.setIsLoading(false));
    this.dispatch(R.actions.rates.setFileSend(''));
  }

  onClear = () => {
    this.dispatch(R.actions.rates.clear());
  }

  onCloseMessageWarningItem = (rateType: ERateTypes) => {
    // temporarily empty
    console.error('onCloseMessageWarningItem', rateType);
  }

  onRatesClear = async () => {
    this.dispatch(R.actions.rates.setIsLoading(true));

    try {
      await R.services.rate.clearCacheQuotas();
    } catch (e) {
      message.error(i18n.t('Rates cache is not cleared'));
      this.dispatch(R.actions.rates.setIsLoading(false));

      return;
    }

    this.dispatch(R.actions.rates.setIsLoading(false));
    message.success(i18n.t('Rates cache has been cleared'));
  }
}
