import React, { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Alert from 'antd/es/alert';
import Button from 'antd/es/button';

import { TableSkeleton } from 'app-wrapper/view/components';
import { StyledTable, AlertWrapper } from 'shipment-operations/view/pages/ShipmentBilling/ShipmentBilling.styled';
import { DataTypeBillingDTM } from 'shipment-operations/models/dtm';
import { useBilling } from './useBilling';

interface ITotalData {
  totalSum: number
  totalBalance: number
  key: number
}
interface IARInvoiceProps {
  fetchData: (shipmentId?: string, type?: string) => void
  data: DataTypeBillingDTM[]
  totalData: ITotalData[]
  isLoading: boolean
  type: string
  error: boolean
}

const CustomTable: FC<IARInvoiceProps> = ({
  fetchData, data, totalData, isLoading, type, error,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams<'shipmentId'>();
  const {
    columns, columnsFooter,
  } = useBilling(shipmentId, type);

  const handleFetchData = useCallback(() => {
    fetchData(shipmentId, type);
  }, [shipmentId, fetchData, type]);

  if (isLoading) {
    return <TableSkeleton />;
  }

  return (
    <>
      {error ? (
        <AlertWrapper>
          <Alert
            message={t('loadBillingInvoicesErrorMsg')}
            description={t('loadBillingInvoicesDescription')}
            type="error"
            action={(
              <Button size="middle" type="primary" onClick={handleFetchData}>
                {t('reload')}
              </Button>
            )}
          />
        </AlertWrapper>
      ) : (
        <>
          <StyledTable
            columns={columns}
            dataSource={data}
            pagination={false}
          />
          <StyledTable
            columns={columnsFooter}
            showHeader={false}
            dataSource={totalData}
            pagination={false}
          />
        </>
      )}
    </>
  );
};

export { CustomTable };
