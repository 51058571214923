import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadProps } from 'antd/es/upload';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';

import { IShipmentDocumentFileListDTM } from 'shipment-operations/models/dtm';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

import { Button, Upload } from './ShipmentDocumentsUpload.styled';

interface IShipmentDocumentsAdditionalUploadComponentProps {
  idIndex: number
  name?: string
  maxCount?: number
  postUrl: string
  authToken: string
  msdsDocument: UploadFile[]
  disabledBtn: boolean
  hasError?: boolean
  validateMsdsDocument: UploadProps['beforeUpload']
  previewMsdsDocument: (documentId: number | null, documentName: string) => void
  onRemove?: (fileId?: string | null) => void
  onUploadDone?: (value: string, name: string) => void
  onChangeRun: (fileList: IShipmentDocumentFileListDTM[]) => void
  onChangeRemoved: (fileId?: string) => void
  onChangeDone: (file: IShipmentDocumentFileListDTM[]) => void
  isBlurDocumentFile: (index: number) => void
  isFocusDocumentFile: (index: number) => void
  additionalDocumentsPermissions?: PermissionAttributePolicy
}

export const ShipmentDocumentsAdditionalUploadComponent: FC<IShipmentDocumentsAdditionalUploadComponentProps> = ({
  idIndex,
  name,
  maxCount = 1,
  postUrl,
  authToken,
  msdsDocument,
  disabledBtn,
  hasError,
  validateMsdsDocument,
  previewMsdsDocument,
  onRemove,
  onUploadDone,
  onChangeRun,
  onChangeRemoved,
  onChangeDone,
  isBlurDocumentFile,
  isFocusDocumentFile,
  additionalDocumentsPermissions,
}) => {
  const { t } = useTranslation();

  const isDisabled = useMemo(() => additionalDocumentsPermissions !== PermissionAttributePolicy.WRITE, [additionalDocumentsPermissions]);

  const onRemoveHandler: UploadProps['onRemove'] = useCallback(
    (file: IShipmentDocumentFileListDTM) => {
      if (onRemove) {
        onRemove(file?.fileId || '');
      }
    },
    [onRemove],
  );

  const onChangeHandler = useCallback(
    (
      info: UploadChangeParam<IShipmentDocumentFileListDTM>,
    ) => {
      let { fileList } = info;
      isFocusDocumentFile(idIndex);

      onChangeRun(fileList);

      if (info.file.status === 'removed') {
        onChangeRemoved(info.file?.fileId || undefined);

        isBlurDocumentFile(idIndex);
      }

      if (info.file.status === 'done') {
        onChangeDone([...fileList.map((list) => ({
          ...list,
          fileId: info.file?.response?.id || null,
          idIndex,
        }))]);

        if (onUploadDone) onUploadDone(`${info.file?.response?.id || ''}`, info.file?.response?.name);

        isBlurDocumentFile(idIndex);
      } else if (info.file.response && info.file.status === 'error') {
        fileList = fileList.map((file) => {
          if (info.file.uid === file.uid) {
            return {
              ...file,
              error: new Error(info.file.response?.message),
            };
          }

          return file;
        });

        onChangeRun(fileList);

        isBlurDocumentFile(idIndex);
      }
    },
    [idIndex, isBlurDocumentFile, isFocusDocumentFile, onChangeDone, onChangeRemoved, onChangeRun, onUploadDone],
  );

  const handlePreview: UploadProps['onPreview'] = useCallback((file: IShipmentDocumentFileListDTM) => {
    if (!file?.fileId) {
      return;
    }

    previewMsdsDocument(
      file?.fileId ? +file.fileId : null,
      file.name,
    );
  }, []);

  return (
    <>
      <Upload
        name={name}
        maxCount={maxCount}
        multiple={false}
        headers={{ Authorization: authToken }}
        showUploadList={{ showRemoveIcon: true, removeIcon: <DeleteOutlined /> }}
        action={postUrl}
        fileList={msdsDocument}
        beforeUpload={validateMsdsDocument}
        onPreview={handlePreview}
        onChange={onChangeHandler}
        onRemove={onRemoveHandler}
        disabled={isDisabled}
      >
        <Button
          icon={<UploadOutlined />}
          type="dashed"
          disabled={isDisabled || disabledBtn}
          hasError={hasError}
        >
          {t('Upload document')}
        </Button>
      </Upload>
    </>
  );
};
