import { controller, BaseController } from 'proto/BaseController';
import { R as shipmentR } from 'shipment-operations/repository';
import { ManualConfirmationStore } from 'shipment-operations/repository/mobxStores';

@controller
export class ManualConfirmationController extends BaseController<ManualConfirmationStore> {
  async onLoadedPage() {
    super.onLoadedPage();
    const shipmentId = this.currentStore?.getShipmentId;
    if (!shipmentId) {
      return;
    }
    this.currentStore?.setLoading(true);
    const shipment = await shipmentR.services.shipment.getShipmentShortById(+shipmentId);
    this.mobxStore.confirmationBookingDetailsStore.setBookingNumber(shipment.carrierReferenceNumber);
    this.mobxStore.confirmationBookingDetailsStore.setOriginalBookingNumber(shipment.carrierReferenceNumber);
  }
}
