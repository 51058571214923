import { components } from 'api-contracts/dev/organization-service/v1/schema';
import { EShipmentConfirmationTypes } from 'shipment-operations/constants';
import { TPostOrganizationConfirmationsContract } from 'user-management/models/contracts/getOrganizationConfirmations.contract';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment/moment';

import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { DateDtm } from 'app-wrapper/models/dtm';
import { ForbiddenError } from 'app-wrapper/models/errors/httpErrors/ForbiddenError/Forbidden.error';
import { DocumentDTM, IShipmentConfirmationDTM, ShipmentConfirmationDTM } from 'shipment-operations/models/dtm';

import {
  BusinessRegistrationNumberDtm,
  EOrganizationStatus,
  MemberRoleForEdit,
  OrganizationDTM,
  OrganizationMemberDTM,
  OrganizationMembershipDtm,
  OrganizationReviewDTM,
  AccountDepartmentDTM,
  TeamDTM,
  AdminPublicInfoDTM,
  TeamMemberDTM,
} from 'user-management/models/dtm';
import {
  IGetOrganizationTeamContract,
  TGetCurrentOrganizationResponseContract,
  TGetOrganizationMemberContract,
  TGetOrganizationMembersListParamsContract,
  TPostOrganizationMemberRequestContract,
  TPostOrganizationMemberResponseContract,
} from 'user-management/models/contracts';
import {
  EAccountDepartmentType,
  EOrganizationIndustry,
  EOrganizationMemberRole,
  EOrganizationType,
} from 'user-management/constants';

export class OrganizationService {
  urlBase = '/organization-service/api/v1/organizations'

  public getCurrentOrganization = async (removeSignal?: boolean) => {
    let organization: OrganizationDTM | null = null;

    const rawResponse = await apiWorker.requestGetBySchema(`${this.urlBase}/current?onlyActive=false` as '/api/v1/organizations/current', {}, removeSignal);

    const response = rawResponse.data;

    const memberships = response.memberships ? response.memberships.map((item) => OrganizationMembershipDtm.fromPlain({
      id: `${item.id}`,
      type: item.type,
      value: item.value,
      isNew: false,
    })) : [];

    if (memberships.length === 0) {
      memberships.push(OrganizationMembershipDtm.createEmpty());
    }

    const paymentMethod = response.paymentMethod as components['schemas']['DeferredPayment'];

    organization = OrganizationDTM.fromPlain({
      id: response.id as number,
      type: response.type,
      industry: response.industry || undefined,
      status: response.status,
      role: response.role,
      name: response.name,
      // @ts-ignore
      accountTeam: response.accountTeam ? this.formatAccountTeamToDTM(response.accountTeam) : undefined,
      // @ts-ignore
      accountManager: response.accountManager ? this.formatAccountManagerDTM(response.accountManager) : undefined,
      // @ts-ignore
      salesManager: response.salesManager ? this.formatAccountManagerDTM(response.salesManager) : undefined,
      createdAt: response.createdAt ? DateDtm.fromPlain({
        date: response.createdAt,
        offset: 0,
      }) : undefined,
      address: {
        id: null,
        country: response.address.country,
        state: response.address.state,
        city: response.address.city,
        address1: response.address.address1,
        address2: response.address.address2,
        postalCode: response.address.postalCode,
        closestPort: response.address.closestPort,
      },
      tradeReference: response.tradeReference || undefined,
      review: response.review || undefined,
      primaryContact: response?.primaryContact ? {
        email: response.primaryContact.email,
        firstName: response.primaryContact.firstName,
        lastName: response.primaryContact.lastName,
        phone: response.primaryContact.phone,
        enabled: response.primaryContact?.enabled,
        sub: response.primaryContact?.sub,
      } : undefined,
      phone: response.contactInformation.phone,
      phone2: response.contactInformation.phone2,
      email: response.contactInformation.email,
      webUrl: (response.contactInformation.webUrl
        ? ({
          webUrl: response.contactInformation.webUrl || '',
        })
        : undefined),
      businessRegistrationNumber: response?.registrationNumber && BusinessRegistrationNumberDtm.fromPlain({
        type: response.registrationNumber.type,
        value: response.registrationNumber.value,
      }),
      memberships,
      taxId: response.taxId,
      coverageAreas: (response?.coverageAreas as components['schemas']['CountryArea'][])?.map((item) => ({
        type: item.type,
        countryCode: item.countryCode,
      })) || undefined,
      accountHolderId: response.accountHolderId,
      documents: response.documents ? response.documents.map((document) => DocumentDTM.fromPlain({
        ...document,
        createdAt: undefined,
        createdBy: '',
      })) : [],
      paymentMethod: paymentMethod && {
        id: paymentMethod?.id,
        creditLimit: paymentMethod?.creditLimit,
        creditTerm: paymentMethod?.creditTerm,
        type: paymentMethod?.type,
      },
      confirmations: response.confirmations?.map((confirmation) => ShipmentConfirmationDTM.fromPlain({
        ...confirmation,
        type: confirmation.type as EShipmentConfirmationTypes,
      })),
      schedule: response.schedule,
      timeZoneId: response.timeZoneId,
    });

    return organization;
  }

  public getRelatedAdminPublicInfo = async (orgIdRelatedToAdmin: number): Promise<AdminPublicInfoDTM | null> => {
    let adminPublicInfo: AdminPublicInfoDTM | null = null;

    try {
      const responseRaw = await apiWorker.requestGetBySchema('/organization-service/api/v1/admin-organizations/public-info' as '/api/v1/admin-organizations/public-info', {
        params: {
          organizationId: orgIdRelatedToAdmin,
        },
      });

      const { data } = responseRaw;

      adminPublicInfo = AdminPublicInfoDTM.fromPlain({
        id: data.id as number,
        name: data.name,
        type: data.type as EOrganizationType,
        industry: data.industry as EOrganizationIndustry,
        contactEmail: data.contactInformation ? data.contactInformation.email : undefined,
        contactPhone: data.contactInformation ? data.contactInformation.phone : undefined,
        contactPhone2: data.contactInformation ? data.contactInformation.phone2 : undefined,
        address: data.address ? {
          id: null,
          country: data.address.country,
          state: data.address.state,
          city: data.address.city,
          address1: data.address.address1,
          address2: data.address.address2,
          postalCode: data.address.postalCode,
          closestPort: data.address.closestPort,
        } : undefined,
        registrationNumber: data.registrationNumber.value,
        registrationType: data.registrationNumber.type,
        primaryContact: data.primaryContact ? {
          email: data.primaryContact.email,
          firstName: data.primaryContact.firstName,
          lastName: data.primaryContact.lastName,
          phone: data.primaryContact.phone,
          // phone2: data.primaryContact.phone2,
          enabled: data.primaryContact?.enabled,
          sub: data.primaryContact?.sub,
        } : undefined,
      });
    } catch (e) {
      if (e instanceof ForbiddenError) {
        return null;
      }

      throw e;
    }

    return adminPublicInfo;
  }

  public getCustomerRelatedPartnerPublicInfo = async (customerIdRelatedToPartner: number): Promise<AdminPublicInfoDTM | null> => {
    let adminPublicInfo: AdminPublicInfoDTM | null = null;

    try {
      const responseRaw = await apiWorker.requestGetBySchema(`/organization-service/api/v1/organizations/${customerIdRelatedToPartner}/public-info` as '/api/v1/organizations/{organizationId}/public-info');

      const { data } = responseRaw;

      adminPublicInfo = AdminPublicInfoDTM.fromPlain({
        id: data.id as number,
        name: data.name,
        type: data.type as EOrganizationType,
        industry: data.industry as EOrganizationIndustry,
        contactEmail: data.contactInformation ? data.contactInformation.email : undefined,
        contactPhone: data.contactInformation ? data.contactInformation.phone : undefined,
        contactPhone2: data.contactInformation ? data.contactInformation.phone2 : undefined,
        address: data.address ? {
          id: null,
          country: data.address.country,
          state: data.address.state,
          city: data.address.city,
          address1: data.address.address1,
          address2: data.address.address2,
          postalCode: data.address.postalCode,
          closestPort: data.address.closestPort,
        } : undefined,
        registrationNumber: data.registrationNumber.value,
        registrationType: data.registrationNumber.type,
        primaryContact: data.primaryContact ? {
          email: data.primaryContact.email,
          firstName: data.primaryContact.firstName,
          lastName: data.primaryContact.lastName,
          phone: data.primaryContact.phone,
          // phone2: data.primaryContact.phone2,
          enabled: data.primaryContact?.enabled,
          sub: data.primaryContact?.sub,
        } : undefined,
      });
    } catch (e) {
      if (e instanceof ForbiddenError) {
        return null;
      }

      throw e;
    }

    return adminPublicInfo;
  }

  public postOrganizationReview = async (organizationId: number | string, review: OrganizationReviewDTM): Promise<OrganizationDTM | null> => {
    let organization: OrganizationDTM | null = null;

    const rawResponse = await apiWorker.requestPost<TGetCurrentOrganizationResponseContract>(`${this.urlBase}/${organizationId}/review`, review);
    const response = rawResponse.data;

    const memberships = response.memberships ? response.memberships.map((item) => OrganizationMembershipDtm.fromPlain({
      id: `${item.id}`,
      type: item.type,
      value: item.value,
      isNew: false,
    })) : [];

    if (memberships.length === 0) {
      memberships.push(OrganizationMembershipDtm.createEmpty());
    }

    organization = OrganizationDTM.fromPlain({
      id: response.id,
      type: response.type,
      industry: response.industry || undefined,
      status: response.status as EOrganizationStatus,
      role: response.role,
      name: response.name,
      note: response.note,
      createdAt: response.createdAt ? DateDtm.fromPlain({
        date: response.createdAt,
        offset: 0,
      }) : undefined,
      address: {
        id: null,
        country: response.address.country,
        state: response.address.state,
        city: response.address.city,
        address1: response.address.address1,
        address2: response.address.address2,
        postalCode: response.address.postalCode,
        closestPort: response.address.closestPort,
      },
      review: response.review || undefined,
      primaryContact: response?.primaryContact ? {
        email: response.primaryContact.email,
        firstName: response.primaryContact.firstName,
        lastName: response.primaryContact.lastName,
        phone: response.primaryContact.phone,
        // phone2: response.primaryContact.phone2,
        enabled: response.primaryContact?.enabled,
        sub: response.primaryContact?.sub,
      } : undefined,
      phone: response.contactInformation.phone,
      phone2: response.contactInformation.phone2,
      email: response.contactInformation.email,
      webUrl: (response.contactInformation.webUrl
        ? ({
          webUrl: response.contactInformation.webUrl || '',
        })
        : undefined),
      businessRegistrationNumber: response?.registrationNumber && BusinessRegistrationNumberDtm.fromPlain({
        type: response.registrationNumber.type,
        value: response.registrationNumber.value,
      }),
      memberships,
      taxId: response.taxId,
      coverageAreas: response?.coverageAreas?.map((item) => ({
        type: item.type,
        countryCode: item.countryCode,
      })) || undefined,
      accountHolderId: response.accountHolderId,
      documents: response.documents ? response.documents.map((document) => DocumentDTM.fromPlain({
        ...document,
        createdAt: document.createdAt ? DateDtm.fromPlain({
          date: document.createdAt,
          offset: moment.parseZone(document.createdAt).utcOffset(),
        }) : undefined,
      })) : [],
    });

    return organization;
  };

  public getOrganizationById = async (companyId: number | string, removeSignal?: boolean) => {
    let organization: OrganizationDTM | null = null;

    const rawResponse = await apiWorker.requestGetBySchema(`${this.urlBase}/${companyId}` as '/api/v1/organizations/{organizationId}', {}, removeSignal);

    const response = rawResponse.data;

    const memberships = response.memberships ? response.memberships.map((item) => OrganizationMembershipDtm.fromPlain({
      id: `${item.id}`,
      type: item.type,
      value: item.value,
      isNew: false,
    })) : [];

    if (memberships.length === 0) {
      memberships.push(OrganizationMembershipDtm.createEmpty());
    }

    const paymentMethod = response.paymentMethod as components['schemas']['DeferredPayment'];

    organization = OrganizationDTM.fromPlain({
      id: response.id as number,
      type: response.type,
      industry: response.industry || undefined,
      status: response.status as EOrganizationStatus,
      role: response.role,
      name: response.name,
      // @ts-ignore
      accountTeam: response.accountTeam ? this.formatAccountTeamToDTM(response.accountTeam) : undefined,
      // @ts-ignore
      accountManager: response.accountManager ? this.formatAccountManagerDTM(response.accountManager) : undefined,
      // @ts-ignore
      salesManager: response.salesManager ? this.formatAccountManagerDTM(response.salesManager) : undefined,
      createdAt: response.createdAt ? DateDtm.fromPlain({
        date: response.createdAt,
        offset: 0,
      }) : undefined,
      address: {
        id: null,
        country: response.address.country,
        state: response.address.state,
        city: response.address.city,
        address1: response.address.address1,
        address2: response.address.address2,
        postalCode: response.address.postalCode,
        closestPort: response.address.closestPort,
      },
      tradeReference: response.tradeReference || undefined,
      review: response.review || undefined,
      primaryContact: response?.primaryContact ? {
        email: response.primaryContact.email,
        firstName: response.primaryContact.firstName,
        lastName: response.primaryContact.lastName,
        phone: response.primaryContact.phone,
        enabled: response.primaryContact?.enabled,
        sub: response.primaryContact?.sub,
      } : undefined,
      phone: response.contactInformation.phone,
      phone2: response.contactInformation.phone2,
      email: response.contactInformation.email,
      webUrl: (response.contactInformation.webUrl
        ? ({
          webUrl: response.contactInformation.webUrl || '',
        })
        : undefined),
      businessRegistrationNumber: response?.registrationNumber && BusinessRegistrationNumberDtm.fromPlain({
        type: response.registrationNumber.type,
        value: response.registrationNumber.value,
      }),
      memberships,
      taxId: response.taxId,
      coverageAreas: (response?.coverageAreas as components['schemas']['CountryArea'][])?.map((item) => ({
        type: item.type,
        countryCode: item.countryCode,
      })) || undefined,
      accountHolderId: response.accountHolderId,
      documents: response.documents ? response.documents.map((document) => DocumentDTM.fromPlain({
        ...document,
        createdBy: document.createdBy as string,
        createdAt: document.createdAt ? DateDtm.fromPlain({
          date: document.createdAt,
          offset: moment.parseZone(document.createdAt).utcOffset(),
        }) : undefined,
      })) : [],
      paymentMethod: paymentMethod && {
        id: paymentMethod?.id,
        creditLimit: paymentMethod?.creditLimit,
        creditTerm: paymentMethod?.creditTerm,
        type: paymentMethod?.type,
      },
      confirmations: response.confirmations?.map((confirmation) => ShipmentConfirmationDTM.fromPlain({
        ...confirmation,
        type: confirmation.type as EShipmentConfirmationTypes,
      })),
      schedule: response.schedule,
      timeZoneId: response.timeZoneId,
    });

    return organization;
  }

  public putCurrentOrganization = async (organization: OrganizationDTM) => {
    if (!organization.id) return;

    const { memberships = [] } = organization;
    const membershipsToSend = memberships.filter(({ type, value }) => type && value);

    // TODO: add contract here
    const organizationReq = {
      id: organization.id,
      type: organization.type,
      industry: organization.industry || null,
      status: organization.status,
      role: organization.role,
      name: organization.name,
      accountManager: organization.accountManager ? {
        user: {
          sub: organization.accountManager.sub,
          enabled: organization.accountManager.enabled,
          email: organization.accountManager.email,
          phone: organization.accountManager.phone,
          firstName: organization.accountManager.firstName,
          lastName: organization.accountManager.lastName,
        },
        role: organization.accountManager.role,
        onboarded: organization.accountManager.onboarded,
      } : undefined,
      salesManager: organization.salesManager ? {
        user: {
          sub: organization.salesManager.sub,
          enabled: organization.salesManager.enabled,
          email: organization.salesManager.email,
          phone: organization.salesManager.phone,
          firstName: organization.salesManager.firstName,
          lastName: organization.salesManager.lastName,
        },
        role: organization.salesManager.role,
        onboarded: organization.salesManager.onboarded,
      } : undefined,
      accountTeam: organization.accountTeam,
      tradeReference: organization?.tradeReference ? organization?.tradeReference : null,
      address: {
        country: organization.address.country,
        state: organization.address.state || undefined,
        city: organization.address.city,
        address1: organization.address.address1,
        address2: organization.address.address2 || undefined,
        postalCode: organization.address.postalCode,
        closestPort: organization.address.closestPort,
      },
      primaryContact: organization?.primaryContact ? {
        email: organization.primaryContact.email,
        firstName: organization.primaryContact.firstName,
        lastName: organization.primaryContact.lastName,
        phone: organization.primaryContact?.phone || null,
        enabled: organization.primaryContact?.enabled,
        sub: organization.primaryContact?.sub,
      } : undefined,
      contactInformation: {
        phone: organization.phone,
        phone2: organization.phone2 || undefined,
        email: organization.email || undefined,
        webUrl: organization?.webUrl?.getValidValue() || undefined,
      },
      registrationNumber: {
        type: organization.businessRegistrationNumber.type,
        value: organization.businessRegistrationNumber.getValidValue(),
      },
      memberships: membershipsToSend.length ? membershipsToSend.map((item) => ({ type: item.type, value: item.value })) : [],
      taxId: this.getEmptyValue(organization.taxId),
      coverageAreas: organization?.coverageAreas?.map((item) => ({
        type: item.type,
        countryCode: item.countryCode,
      })) || null,
      accountHolderId: organization.accountHolderId,
      paymentMethod: organization.paymentMethod && {
        id: organization.paymentMethod?.id,
        creditLimit: organization.paymentMethod?.creditLimit,
        creditTerm: organization.paymentMethod?.creditTerm,
        type: organization.paymentMethod?.type,
      },
      schedule: organization.schedule,
      timeZoneId: organization.timeZoneId,
    };

    // fix put params
    // @ts-ignore
    await apiWorker.requestPutBySchema(`${this.urlBase}/${organization.id}` as '/api/v1/organizations/{organizationId}', organizationReq);
  }

  public postCurrentOrganization = async (organization: OrganizationDTM) => {
    let organizationResp: OrganizationDTM | null = null;

    const membershipsToSend = organization?.memberships?.filter(({ type, value }) => type && value) || [];
    const requestMemberships = membershipsToSend[0]?.value ? membershipsToSend.map((item) => (
      item.isNew
        ? { type: item.type, value: item.value }
        : { id: item.id, type: item.type, value: item.value }
    )) : [];

    const organizationReq = {
      type: organization.type,
      industry: organization.industry || undefined,
      status: 'ACTIVE' as const,
      role: EOrganizationMemberRole.CUSTOMER as const,
      name: organization.name,
      accountManager: organization.accountManager ? {
        user: {
          sub: organization.accountManager.sub,
          enabled: organization.accountManager.enabled,
          email: organization.accountManager.email,
          phone: organization.accountManager.phone,
          firstName: organization.accountManager.firstName,
          lastName: organization.accountManager.lastName,
        },
        role: organization.accountManager.role,
        onboarded: organization.accountManager.onboarded,
      } : undefined,
      salesManager: organization.salesManager ? {
        user: {
          sub: organization.salesManager.sub,
          enabled: organization.salesManager.enabled,
          email: organization.salesManager.email,
          phone: organization.salesManager.phone,
          firstName: organization.salesManager.firstName,
          lastName: organization.salesManager.lastName,
        },
        role: organization.salesManager.role,
        onboarded: organization.salesManager.onboarded,
      } : undefined,
      accountTeam: organization.accountTeam,
      address: {
        country: organization.address.country,
        state: organization.address.state,
        city: organization.address.city,
        address1: organization.address.address1,
        address2: organization.address.address2,
        postalCode: organization.address.postalCode,
        closestPort: organization.address.closestPort || undefined,
      },
      ...(organization?.tradeReference ? {
        tradeReference: organization?.tradeReference,
      } : {}),
      contactInformation: {
        phone: organization.phone,
        phone2: organization.phone2 || undefined,
        email: organization.email || undefined,
        webUrl: organization?.webUrl?.getValidValue() || undefined,
      },
      primaryContact: organization.primaryContact?.email ? {
        email: organization.primaryContact?.email,
        phone: organization.primaryContact?.phone,
        firstName: organization.primaryContact?.firstName,
        lastName: organization.primaryContact?.lastName,
      } : null,
      registrationNumber: {
        type: organization.businessRegistrationNumber.type,
        value: organization.businessRegistrationNumber.getValidValue(),
      },
      memberships: requestMemberships,
      taxId: organization.taxId,
      coverageAreas: organization?.coverageAreas?.map((item) => ({
        type: item.type,
        countryCode: item.countryCode,
      })),
      accountHolderId: organization.accountHolderId,
      paymentMethod: organization.paymentMethod && {
        id: organization.paymentMethod?.id || undefined,
        creditLimit: organization.paymentMethod?.creditLimit || undefined,
        creditTerm: organization.paymentMethod?.creditTerm || undefined,
        type: organization.paymentMethod?.getTypeReq(),
      },
      schedule: organization.schedule,
      timeZoneId: organization.timeZoneId,
    };

    // @ts-ignore
    const rawResponse = await apiWorker.requestPostBySchema(`${this.urlBase}` as '/api/v1/organizations', organizationReq);

    const response = rawResponse.data;

    const memberships = response.memberships ? response.memberships.map((item) => OrganizationMembershipDtm.fromPlain({
      id: `${item.id}`,
      type: item.type,
      value: item.value,
      isNew: false,
    })) : [];

    if (memberships.length === 0) {
      memberships.push(OrganizationMembershipDtm.createEmpty());
    }

    organizationResp = OrganizationDTM.fromPlain({
      id: response.id as number,
      type: response.type,
      status: response.status as EOrganizationStatus,
      role: response.role,
      name: response.name,
      // @ts-ignore
      accountTeam: response.accountTeam ? this.formatAccountTeamToDTM(response.accountTeam) : undefined,
      // @ts-ignore
      accountManager: response.accountManager ? this.formatAccountManagerDTM(response.accountManager) : undefined,
      // @ts-ignore
      salesManager: response.salesManager ? this.formatAccountManagerDTM(response.salesManager) : undefined,
      note: '',
      createdAt: response.createdAt ? DateDtm.fromPlain({
        date: response.createdAt,
        offset: 0,
      }) : undefined,
      address: {
        id: null,
        country: response.address.country,
        state: response.address.state,
        city: response.address.city,
        address1: response.address.address1,
        address2: response.address.address2,
        postalCode: response.address.postalCode,
        closestPort: response.address.closestPort,
      },
      review: response.review || undefined,
      primaryContact: response?.primaryContact ? {
        email: response.primaryContact.email,
        firstName: response.primaryContact.firstName,
        lastName: response.primaryContact.lastName,
        phone: response.primaryContact.phone,
        enabled: response.primaryContact?.enabled,
        sub: response.primaryContact?.sub,
      } : undefined,
      phone: response.contactInformation.phone,
      phone2: response.contactInformation.phone2,
      email: response.contactInformation.email,
      webUrl: (response.contactInformation.webUrl
        ? ({
          webUrl: response.contactInformation.webUrl || '',
        })
        : undefined),
      businessRegistrationNumber: response?.registrationNumber && BusinessRegistrationNumberDtm.fromPlain({
        type: response.registrationNumber.type,
        value: response.registrationNumber.value,
      }),
      memberships,
      taxId: response.taxId,
      coverageAreas: (response?.coverageAreas as components['schemas']['CountryArea'][])?.map((item) => ({
        type: item.type,
        countryCode: item.countryCode,
      })) || undefined,
      accountHolderId: response.accountHolderId,
      documents: response.documents ? response.documents.map((document) => DocumentDTM.fromPlain({
        ...document,
        createdBy: document.createdBy as string,
        createdAt: document.createdAt ? DateDtm.fromPlain({
          date: document.createdAt,
          offset: moment.parseZone(document.createdAt).utcOffset(),
        }) : undefined,
      })) : [],
      schedule: response.schedule,
      timeZoneId: response.timeZoneId,
    });

    return organizationResp;
  }

  public putOrganizationMember = async (organizationId: number, memberEmail: string, member: OrganizationMemberDTM) => {
    let organizationMember: OrganizationMemberDTM | null = null;

    const memberToPut = {
      email: member.email,
      phone: member.phone,
      role: member.role,
      onboarded: member.onboarded,
      teams: member.teams.map(({ id, team, role }) => ({
        id,
        role,
        team: team ? {
          id: team.id,
        } : undefined,
      })),
      jobTitle: member.jobTitle,
      departmentIds: member.departments.map(({ id }) => id),
    };

    const rawResponse = await apiWorker.requestPutBySchema(
      `${this.urlBase}/${organizationId}/members/${memberEmail}` as '/api/v1/organizations/{organizationId}/members/{memberEmail}',
      // fix put params
      // @ts-ignore
      memberToPut,
    );

    const response = rawResponse.data;

    organizationMember = OrganizationMemberDTM.fromPlain({
      sub: response.user.sub,
      enabled: response.user.enabled,
      email: response.user.email,
      phone: response.user.phone,
      firstName: response.user.firstName,
      lastName: response.user.lastName,
      onboarded: response.onboarded,
      jobTitle: response.jobTitle || '',
      role: response.role as EOrganizationMemberRole,
      teams: response.teams ? response.teams.map((item) => TeamMemberDTM.fromPlain({
        ...item,
        team: item.team ? TeamDTM.fromPlain({
          id: item.team.id,
          name: item.team.name,
          status: item.team.status,
          membersCount: item.team.membersCount || 0,
          customersCount: item.team.customersCount || 0,
          customers: [],
          members: [],
        }) : undefined,
        member: item.member ? OrganizationMemberDTM.fromPlain({
          sub: item.member.user.sub,
          enabled: item.member.user.enabled,
          email: item.member.user.email,
          phone: item.member.user.phone,
          firstName: item.member.user.firstName,
          lastName: item.member.user.lastName,
          onboarded: item.member.onboarded,
          jobTitle: item.member.jobTitle || '',
          role: item.member.role as EOrganizationMemberRole,
          teams: [],
          departments: item.member.departments ? item.member.departments.map((department) => AccountDepartmentDTM.fromPlain({
            id: department.id,
            customId: '',
            type: department.type as EAccountDepartmentType,
            email: department.email,
            phone: department.phone,
            createdBy: '',
            createdAt: department.createdAt,
            members: [],
          })) : [],
        }) : undefined,
      })) : [],
      departments: response.departments ? response.departments.map((department) => AccountDepartmentDTM.fromPlain({
        id: department.id,
        customId: '',
        type: department.type as EAccountDepartmentType,
        email: department.email,
        phone: department.phone,
        createdBy: '',
        createdAt: department.createdAt,
        members: [],
      })) : [],
    });

    return organizationMember;
  }

  public getOrganizationMember = async (organizationId: number, memberEmail: string) => {
    let organizationMember: OrganizationMemberDTM | null = null;

    const rawResponse = await apiWorker.requestGetBySchema(
      `${this.urlBase}/${organizationId}/members/${memberEmail}` as '/api/v1/organizations/{organizationId}/members/{memberEmail}',
    );

    const response = rawResponse.data;

    organizationMember = OrganizationMemberDTM.fromPlain({
      sub: response.user.sub,
      enabled: response.user.enabled,
      email: response.user.email,
      phone: response.user.phone,
      firstName: response.user.firstName,
      lastName: response.user.lastName,
      onboarded: response.onboarded,
      id: response.user.sub,
      role: response.role as EOrganizationMemberRole,
      status: undefined,
      primary: response.primary,
      jobTitle: response.jobTitle || '',
      teams: response.teams ? response.teams.map((item) => TeamMemberDTM.fromPlain({
        ...item,
        team: item.team ? TeamDTM.fromPlain({
          id: item.team.id,
          name: item.team.name,
          status: item.team.status,
          membersCount: item.team.membersCount || 0,
          customersCount: item.team.customersCount || 0,
          customers: [],
          members: [],
        }) : undefined,
        member: item.member ? OrganizationMemberDTM.fromPlain({
          sub: item.member.user.sub,
          enabled: item.member.user.enabled,
          email: item.member.user.email,
          phone: item.member.user.phone,
          firstName: item.member.user.firstName,
          lastName: item.member.user.lastName,
          onboarded: item.member.onboarded,
          jobTitle: item.member.jobTitle || '',
          role: item.member.role as EOrganizationMemberRole,
          teams: [],
          departments: item.member.departments ? item.member.departments.map((department) => AccountDepartmentDTM.fromPlain({
            id: department.id,
            customId: '',
            type: department.type as EAccountDepartmentType,
            email: department.email,
            phone: department.phone,
            createdBy: '',
            createdAt: department.createdAt,
            members: [],
          })) : [],
        }) : undefined,
      })) : [],
      departments: response.departments ? response.departments.map((department) => AccountDepartmentDTM.fromPlain({
        id: department.id,
        customId: '',
        type: department.type as EAccountDepartmentType,
        email: department.email || '',
        phone: department.phone || '',
        createdBy: '',
        createdAt: department.createdAt,
        members: [],
      })) : [],
    });

    return organizationMember;
  }

  public inviteOrganizationMember = async (organizationId: number, memberEmail: string, role: EOrganizationMemberRole, departmentIds: string[], teamIds: string[], jobTitle?: string) => {
    let organizationMember: OrganizationMemberDTM | null = null;

    const body: TPostOrganizationMemberRequestContract = {
      email: memberEmail,
      role,
      departmentIds,
      teams: [],
      jobTitle: jobTitle || '',
    };

    const rawResponse = await apiWorker.requestPost<TPostOrganizationMemberResponseContract>(
      `${this.urlBase}/${organizationId}/members`,
      body,
    );

    const response = rawResponse.data;

    organizationMember = OrganizationMemberDTM.fromPlain({
      sub: response.user.sub,
      enabled: response.user.enabled,
      email: response.user.email,
      phone: response.user.phone,
      firstName: response.user.firstName,
      lastName: response.user.lastName,
      role: response.role as EOrganizationMemberRole,
      teams: [],
      jobTitle: response.jobTitle || '',
      departments: response.departments ? response.departments.map((department) => AccountDepartmentDTM.fromPlain({
        id: department.id,
        customId: '',
        type: department.type,
        email: department.email,
        phone: department.phone,
        createdBy: '',
        createdAt: department.createdAt,
        members: [],
      })) : [],
    });

    return organizationMember;
  }

  public getOrganizationMembersList = async (organizationId: number, params?: TGetOrganizationMembersListParamsContract) => {
    const organizationMembersList: OrganizationMemberDTM[] = [];

    try {
      const rawResponse = await apiWorker.requestGetBySchema(
        `${this.urlBase}/${organizationId}/members` as '/api/v1/organizations/{organizationId}/members',
        {
          params: {
            ...params,
            departments: params?.departments?.join(','),
          },
        },
      );

      rawResponse.data.forEach((item) => {
        const organizationMember = OrganizationMemberDTM.fromPlain({
          sub: item.user.sub,
          onboarded: item.onboarded,
          enabled: item.user.enabled,
          primary: item.primary,
          email: item.user.email,
          phone: item.user.phone,
          firstName: item.user.firstName,
          lastName: item.user.lastName,
          role: item.role as EOrganizationMemberRole,
          id: uuidv4(),
          jobTitle: item.jobTitle || '',
          teams: item.teams ? item.teams.map((team) => TeamMemberDTM.fromPlain({
            ...team,
            team: team.team ? TeamDTM.fromPlain({
              id: team.team.id,
              name: team.team.name,
              status: team.team.status,
              membersCount: team.team.membersCount || 0,
              customersCount: team.team.customersCount || 0,
              customers: [],
              members: [],
            }) : undefined,
            member: team.member ? OrganizationMemberDTM.fromPlain({
              sub: team.member.user.sub,
              enabled: team.member.user.enabled,
              email: team.member.user.email,
              phone: team.member.user.phone,
              firstName: team.member.user.firstName,
              lastName: team.member.user.lastName,
              onboarded: team.member.onboarded,
              jobTitle: team.member.jobTitle || '',
              role: team.member.role as EOrganizationMemberRole,
              teams: [],
              departments: team.member.departments ? team.member.departments.map((department) => AccountDepartmentDTM.fromPlain({
                id: department.id,
                customId: '',
                type: department.type as EAccountDepartmentType,
                email: department.email,
                phone: department.phone,
                createdBy: '',
                createdAt: department.createdAt,
                members: [],
              })) : [],
            }) : undefined,
          })) : [],
          departments: item.departments ? item.departments.map((department) => AccountDepartmentDTM.fromPlain({
            id: department.id,
            customId: '',
            type: department.type as EAccountDepartmentType,
            email: department.email || '',
            phone: department.phone || '',
            createdBy: '',
            createdAt: department.createdAt,
            members: [],
          })) : [],
        });

        organizationMembersList.push(organizationMember);
      });
    } catch (e) {
      throw new Error('Organization members list getting error');
    }

    return organizationMembersList;
  }

  public getOrganizations = async (page?: number, size?: number, statuses?: string, role?: EOrganizationMemberRole, teamId?: number) => {
    const organizations: OrganizationDTM[] = [];
    const currentPage = page || 0;
    const sizePage = size || 15;

    const rawResponse = await apiWorker.requestGetBySchema(`${this.urlBase}` as '/api/v1/organizations', {
      params: {
        statuses: statuses || 'ACTIVE,WAITING_APPROVAL,REJECTED',
        page: currentPage,
        size: sizePage,
        ...(role ? { role } : {}),
        ...(typeof teamId === 'number' ? { teamId } : {}),
      },
    });

    const response = rawResponse.data;

    response.content?.forEach((item) => {
      const memberships = item.memberships ? item.memberships.map((membership) => OrganizationMembershipDtm.fromPlain({
        id: `${membership.id}`,
        type: membership.type,
        value: membership.value,
        isNew: false,
      })) : [];

      if (memberships.length === 0) {
        memberships.push(OrganizationMembershipDtm.createEmpty());
      }

      const organization = OrganizationDTM.fromPlain({
        id: item.id as number,
        type: item.type,
        customId: uuidv4(),
        status: item.status as EOrganizationStatus,
        role: item.role as EOrganizationMemberRole,
        name: item.name,
        // @ts-ignore
        accountTeam: item.accountTeam ? this.formatAccountTeamToDTM(item.accountTeam) : undefined,
        // @ts-ignore
        accountManager: item.accountManager ? this.formatAccountManagerDTM(item.accountManager) : undefined,
        // @ts-ignore
        salesManager: item.salesManager ? this.formatAccountManagerDTM(item.salesManager) : undefined,
        note: '',
        createdAt: item.createdAt ? DateDtm.fromPlain({
          date: item.createdAt,
          offset: 0,
        }) : undefined,
        address: {
          id: null,
          country: item.address.country,
          state: item.address.state,
          city: item.address.city,
          address1: item.address.address1,
          address2: item.address.address2,
          postalCode: item.address.postalCode,
          closestPort: item.address.closestPort,
        },
        tradeReference: item.tradeReference || undefined,
        review: item.review || undefined,
        phone: item.contactInformation.phone,
        phone2: item.contactInformation.phone2,
        email: item.contactInformation.email,
        webUrl: (item.contactInformation.webUrl
          ? ({
            webUrl: item.contactInformation.webUrl || '',
          })
          : undefined),
        businessRegistrationNumber: item.registrationNumber && BusinessRegistrationNumberDtm.fromPlain({
          type: item.registrationNumber.type,
          value: item.registrationNumber.value,
        }),
        memberships,
        taxId: item.taxId,
        coverageAreas: (item?.coverageAreas as components['schemas']['CountryArea'][])?.map((itemCoverageAreas) => ({
          type: itemCoverageAreas.type,
          countryCode: itemCoverageAreas.countryCode,
        })) || undefined,
        primaryContact: item?.primaryContact ? {
          email: item.primaryContact.email,
          firstName: item.primaryContact.firstName,
          lastName: item.primaryContact.lastName,
          phone: item.primaryContact.phone,
          enabled: item.primaryContact?.enabled,
          sub: item.primaryContact?.sub,
        } : undefined,
        accountHolderId: item.accountHolderId,
        documents: [],
        schedule: item.schedule,
        timeZoneId: item.timeZoneId,
      });

      organizations.push(organization);
    });

    return organizations;
  }

  public formatAccountTeamToDTM = (rawData: IGetOrganizationTeamContract): TeamDTM => TeamDTM.fromPlain({
    id: rawData?.id,
    status: rawData.status,
    name: rawData.name,
    membersCount: rawData.membersCount,
    customersCount: rawData.customersCount,
    customers: [],
    members: [],
  });

  public formatAccountManagerDTM = (rawData: TGetOrganizationMemberContract): OrganizationMemberDTM => OrganizationMemberDTM.fromPlain({
    sub: rawData.user.sub,
    onboarded: rawData.onboarded,
    enabled: rawData.user.enabled,
    email: rawData.user.email,
    phone: rawData.user.phone,
    firstName: rawData.user.firstName,
    lastName: rawData.user.lastName,
    role: rawData.role as EOrganizationMemberRole,
    id: uuidv4(),
    teams: [],
    departments: rawData.departments ? rawData.departments.map((department) => AccountDepartmentDTM.fromPlain({
      id: department.id,
      customId: '',
      type: department.type,
      email: department.email || '',
      phone: department.phone || '',
      createdBy: '',
      createdAt: department.createdAt,
      members: [],
    })) : [],
  })

  public putMemberRole = async (organizationId: number, email: string, body: MemberRoleForEdit) => {
    const rawResponse = await apiWorker.requestPut<TGetOrganizationMemberContract>(
      `${this.urlBase}/${organizationId}/members/${email}`,
      body,
    );

    const response = rawResponse.data;

    const organizationMember = OrganizationMemberDTM.fromPlain({
      sub: response.user.sub,
      enabled: response.user.enabled,
      email: response.user.email,
      phone: response.user.phone,
      firstName: response.user.firstName,
      lastName: response.user.lastName,
      onboarded: response.onboarded,
      role: response.role as EOrganizationMemberRole,
      teams: [],
      departments: response.departments ? response.departments.map((department) => AccountDepartmentDTM.fromPlain({
        id: department.id,
        customId: '',
        type: department.type,
        email: department.email,
        phone: department.phone,
        createdBy: '',
        createdAt: department.createdAt,
        members: [],
      })) : [],
    });

    return organizationMember;
  }

  public deleteMember = async (organizationId: number, email: string) => {
    try {
      await apiWorker.requestDelete(`${this.urlBase}/${organizationId}/members/${email}`);
    } catch {
      throw new Error('Delete member getting error');
    }
  }

  private getEmptyValue = (value?: string | null) => (
    value || (value === '' ? value : null)
  );

  public postConfirmations = async (organizationId: number, confirmation: IShipmentConfirmationDTM) => {
    let result: ShipmentConfirmationDTM | null = null;

    const response = await apiWorker.requestPost<TPostOrganizationConfirmationsContract>(`${this.urlBase}/${organizationId}/confirmations`, {
      id: confirmation.id,
      type: confirmation.type,
      createdBy: confirmation.createdBy,
      createdAt: confirmation.createdAt,
    });

    if (response) {
      result = response.data ? ShipmentConfirmationDTM.fromPlain({
        id: response.data.id,
        type: response.data.type as EShipmentConfirmationTypes,
        createdAt: response.data.createdAt,
        createdBy: response.data.createdBy,
      }) : null;
    }

    return result;
  }
}
