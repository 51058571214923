import React, {
  useState,
  memo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { clearShipmentId } from 'app-wrapper/data/shipments/shipmentSlice';
import { hideBookingModal, isOpenModal } from 'app-wrapper/data/modals/modalsSlice';
import { PageHeader, Button } from 'app-wrapper/view/components';
import {
  ModalContent,
  ModalDialog,
  ModalFooter,
  Overlay,
} from 'app-wrapper/view/components/Modal/Modal.styled';

import { ShippingParties } from 'shipment-operations/view/pages/Booking/ShippingParties';
import { Cargo } from 'shipment-operations/view/pages/Booking/Cargo';
import { Containers } from 'shipment-operations/view/pages/Booking/Containers';
import { Drayage } from 'shipment-operations/view/pages/Booking/Drayage';
import { Documents } from 'shipment-operations/view/pages/Booking/Documents';
import { Billing } from 'shipment-operations/view/pages/Booking/Billing';

import {
  Content,
  Step,
  Steps,
  ButtonGroup,
  MainContent,
  CloseIcon,
  ModalHeader,
  FormsContainer,
} from './styled';

// TODO: separate logic from view

export const BookingComponent = memo(() => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const showModal = useSelector(isOpenModal);
  const dispatch = useDispatch();

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return <ShippingParties />;
      case 1:
        return <Cargo />;
      case 2:
        return <Containers />;
      case 3:
        return <Drayage />;
      case 4:
        return <Documents />;
      case 5:
        return <Billing />;
      default:
        return null;
    }
  };

  if (!showModal) {
    return null;
  }

  return (
    <Overlay>
      <ModalDialog>
        <ModalHeader>
          <PageHeader title="New Booking" />
          <CloseIcon onClick={() => {
            dispatch(hideBookingModal());
            dispatch(clearShipmentId());
          }}
          />
        </ModalHeader>
        <ModalContent>
          <Content>
            <MainContent>
              <Steps current={activeStep}>
                <Step title={t('Shipping Parties')} onClick={() => setActiveStep(0)} />
                <Step title={t('Cargo')} onClick={() => setActiveStep(1)} />
                <Step title={t('Containers')} onClick={() => setActiveStep(2)} />
                <Step title={t('Drayage')} onClick={() => setActiveStep(3)} />
                <Step title={t('Documents')} onClick={() => setActiveStep(4)} />
                <Step title={t('Billing')} onClick={() => setActiveStep(5)} />
              </Steps>
              <FormsContainer>{getStepContent()}</FormsContainer>
            </MainContent>
          </Content>
        </ModalContent>
        <ModalFooter>
          <ButtonGroup>
            <Button
              size="large"
              type="default"
            >
              {t('Save Draft')}
            </Button>
            <Button htmlType="submit" form={`step_form_${activeStep}`} size="large">
              {t('Next')}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalDialog>
    </Overlay>
  );
});
