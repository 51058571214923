import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { ShippingPartiesReferencesComponent } from './ShippingPartiesReferences.component';

const ShippingPartiesReferencesContainer: FC = () => {
  const { references } = useSelector(R.selectors.shippingParties.getSelectedShippingParty);
  const role = useSelector(R.selectors.shippingParties.getSelectedFormType);
  const permissions = useSelector(R.selectors.shippingParties.getShippingPartyPermissionsByRole(role));

  return (
    <ShippingPartiesReferencesComponent
      references={references}
      setReference={UC.shippingParties.setReference}
      addReference={UC.shippingParties.addReference}
      removeReference={UC.shippingParties.removeReference}
      disabled={!permissions.isAbleToEditAdditionalInformation}
    />
  );
};

export {
  ShippingPartiesReferencesContainer as ShippingPartiesReferences,
};
