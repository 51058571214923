import { AuthSession } from '@aws-amplify/core';
import { fetchAuthSession, signOut, AuthUser } from '@aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';

class CurrentSession {
  private session: AuthSession | undefined

  private user: AuthUser | undefined

  private userId: string | undefined

  private loadSession = async () => {
    try {
      const user = await getCurrentUser();

      if (!this.user) {
        this.user = user;
        this.userId = this.user?.username;
      }

      if (!this.session) {
        this.session = await fetchAuthSession();
      }

      if (this.userId && this.userId !== user.username) {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
    } catch (e) {
      console.error(e);
    }
  }

  getIdToken = async () => {
    try {
      await this.loadSession();

      if (this.session) {
        return this.session.tokens?.idToken?.toString();
      }
    } catch (e) {
      console.error(e);
    }

    return '';
  }

  async refreshSession() {
    // no refresh token in amplify v6
    // const result = await new Promise<CognitoUserSession>((resolve, reject) => {
    //   if (this.session && this.user) {
    //     this.user.refreshSession(this.session.getRefreshToken(), (error, newSession) => {
    //       if (error) {
    //         reject(error);
    //       }
    //       resolve(newSession);
    //     });
    //   }
    // });
    // this.session = result;
    // return result;
  }

  signOut() {
    signOut();
    this.session = undefined;
    this.user = undefined;
  }
}

export const currentSession = new CurrentSession();
