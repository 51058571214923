import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';

import { observer } from 'mobx-react-lite';
import { useMobxStore } from 'app-wrapper/mobxStores';
import { AllRequestedQuotesPageComponent } from './AllRequestedQuotesPage.component';

const AllRequestedQuotesPageContainer: FC = observer(() => {
  const { allRequestsStore } = useMobxStore();
  const getFreightQuoteCurrent = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const quotas = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentQuotas);

  const quotaName = `${getFreightQuoteCurrent?.form?.bookmark?.name || getFreightQuoteCurrent.quotaId || quotas?.quotasOption?.id || ''}`;

  const isDisableModalRequestName = !quotas?.allQuotas?.length || !!getFreightQuoteCurrent?.form?.bookmark?.name;

  return (
    <AllRequestedQuotesPageComponent
      allQuotesState={quotas}
      allQuotesData={allRequestsStore.state.items}
      hasMore={quotas?.quotasOption?.hasMore}
      allRequestBumpersPageStatus={getFreightQuoteCurrent?.allRequestBumpersPageStatus}
      quotaName={quotaName}
      quotaStatus={quotas?.quotasOption?.fullCompleteStatus || undefined}
      isFavorite={getFreightQuoteCurrent?.filtersRequestAllQuotas?.isFavorite}
      isFavoriteDisabled={getFreightQuoteCurrent?.filtersRequestAllQuotas?.isDisabled}
      savedRequestName={getFreightQuoteCurrent?.savedRequestName}
      isShowModalRequestName={getFreightQuoteCurrent?.isShowModalRequestName}
      isLoadingRequestName={getFreightQuoteCurrent?.isLoadingRequestName}
      isDisableModalRequestName={isDisableModalRequestName}
      isShowModalRequestBtn={!!quotas?.allQuotas?.length}
      onChangeSavedName={UC.FreightQuote.onChangeRequestSavedName}
      onOpenRequestSavedName={UC.FreightQuote.onOpenRequestSavedName}
      onSaveRequestSavedName={UC.FreightQuote.onSaveRequestSavedName}
      onCloseRequestSavedName={UC.FreightQuote.onCloseRequestSavedName}
      onRunAllQuotasPage={UC.FreightQuote.onRunAllQuotasPage}
      onRunAllQuotasBumpersPage={UC.FreightQuote.onRunAllQuotasBumpersPage}
      onScrollNextInfinityPage={UC.FreightQuote.onScrollAllQuotasPageNextInfinityPage}
      onChangeAllRequestShowSaved={UC.FreightQuote.onChangeAllRequestShowSaved}
      onClickEmptyQuotesButton={UC.FreightQuote.onClickGetNewQuote}
    />
  );
});

export { AllRequestedQuotesPageContainer as AllRequestedQuotesPage };
