import { createSlice } from 'app-wrapper/createSlice';
import { RECEIVABLES } from 'shipment-operations/constants';

import { IShipmentBillingState } from 'shipment-operations/models/states';

const initialState: IShipmentBillingState = {
  data: [],
  error: false,
  isLoading: false,
  defaultActiveKey: RECEIVABLES,
};

export const ShipmentBillingSlice = createSlice({
  name: 'shipmentBilling',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => ({
      ...state,
      isLoading: payload,
    }),
    setDefaultActiveKey: (state, { payload }) => ({
      ...state,
      defaultActiveKey: payload,
    }),
    setShipmentBilling: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: false,
      data: payload,
    }),
    setError: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
    clear: () => ({
      ...initialState,
    }),
  },
});

export const shipmentBillingActions = ShipmentBillingSlice.actions;
export const shipmentBillingReducer = ShipmentBillingSlice.reducer;
