import React, { FC } from 'react';
import { ButtonProps } from 'antd/es/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import { MinusSvg } from 'app-wrapper/view/icons';

import {
  ButtonMinusName,
  ButtonMinusWrapper,
} from './ButtonMinus.styled';

interface IButtonMinus extends ButtonProps {
  isDisabled?: boolean
  onClick?: () => void
  name?: string
  sizeType?: SizeType
}

export const ButtonMinus: FC<IButtonMinus> = (props) => {
  const {
    isDisabled,
    onClick,
    name,
    sizeType,
  } = props;

  return (
    <ButtonMinusWrapper
      onClick={onClick}
      disabled={isDisabled}
      size={sizeType || 'middle'}
      type="dashed"
      data-testid="addMediumBtn"
    >
      <MinusSvg
        style={
          {
            color: isDisabled ? '#D1D7E2' : undefined,
          }
        }
        height={9}
        width={9}
      />

      {name
        ? (
          <ButtonMinusName className="addCommodity__title">
            {name}
          </ButtonMinusName>
        )
        : ''}
    </ButtonMinusWrapper>
  );
};
