import { createSelector } from 'reselect';
import filter from 'lodash/fp/filter';
import uniqueId from 'lodash/fp/uniqueId';
import intersection from 'lodash/intersection';
import includes from 'lodash/includes';
import { v4 as uuidv4 } from 'uuid';

import { prepareDataForTree } from 'app-wrapper/utils/prepareDataForTree';
import { RootState } from 'app-wrapper/store';
import { getLocationToOneString } from 'app-wrapper/utils';
import i18n from 'app-wrapper/i18n/i18n';

import {
  ChargeCodeLoadTypeEnum,
  ContainerAllTypesNamesLongConst, isContainerAllTypes,
} from 'shipment-operations/constants';

import { AdditionalServiceDTM, IQuotaServiceFiltersParamsDTM } from 'monetary/models/dtm/Quotas';
import {
  chargeCodeCode,
  chargeCodeDesignation, chargeCodeMeasureBy, chargeCodeOccurrence, chargeCodePriceBy, chargeCodeSubjectTo, chargeCodeSubType, chargeCodeType, EFreightIncotermsByExport, EFreightIncotermsByImport, EFreightIncotermsTrade,
} from 'monetary/constants';
import {
  AccessorialsFilterReturnDTM,
  DetailAccessorialsContainerDTM,
  DetailAccessorialsDTM,
  FreightQuotaContentDTM,
  FreightQuotaContentSchedulesChargesDTM,
  DetailBreakdownDTM,
  IDetailBreakdownStepsContainersTypesByIdDTM,
  IDetailBreakdownStepsContainersTypesDTM,
  DetailRoutingContainerStepDTM,
  DetailRoutingDTM,
  IFreightQuotaContentSchedulesChargesDTM,
  DetailBreakdownContainerDTM,
  DetailBreakdownStepsDTM,
  DetailAccessorialsTransportationDTM,
  DetailBreakdownTransportationDTM,
  DetailBreakdownServicesDTM,
  EFreightPaymentTerms,
  DetailRoutingStepsDTM,
  IFreightQuotaContentSchedulesDTM,
} from 'monetary/models/dtm';
import { accessorialsFilterPerUnit } from 'monetary/utils';

import { FreightQuotePrintSelectors } from './FreightQuotePrint.selectors';

const selectSelf = (state: RootState) => state;

const getFreightQuote = (state: RootState) => (
  state.FreightQuota
);

const getFreightQuoteCurrent = createSelector(
  selectSelf,
  (data) => data.FreightQuota.currentState,
);

const getFreightQuoteFilters = createSelector(
  selectSelf,
  (data) => data.FreightQuota.filters,
);

const getFreightQuoteFiltersRequestStatus = createSelector(
  getFreightQuoteFilters,
  (filters) => filters.requestFiltersStatus,
);

const getFreightQuoteQuotaLoadingSteps = createSelector(
  selectSelf,
  (data) => data.FreightQuota.quotaLoadingSteps,
);

const getFreightQuoteCurrentForm = createSelector(
  selectSelf,
  (data) => data.FreightQuota.currentState.form,
);

const getFreightQuoteCurrentFormContainers = (state: RootState) => (
  state.FreightQuota.currentState.form?.containers
);

const getFreightQuoteCurrentFormContainersValues = (state: RootState) => (
  state.FreightQuota.currentState.form?.containers?.values
);

const getFreightQuoteErrors = (state: RootState) => (
  state.FreightQuota.errors
);

const getFreightQuoteCurrentFiltersPrice = createSelector(
  getFreightQuoteFilters,
  (filters) => filters?.valuesFilters?.filterPrice,
);

const getFreightQuoteCurrentFiltersParams = createSelector(
  getFreightQuoteFilters,
  (filters) => ({
    minTotalCost: filters?.valuesFilters?.filterPrice?.filterMin,
    maxTotalCost: filters?.valuesFilters?.filterPrice?.filterMax,
    minTotalTransitTime: filters?.valuesFilters?.filterTransitTime?.filterMin,
    maxTotalTransitTime: filters?.valuesFilters?.filterTransitTime?.filterMax,
    carriers: filters?.valuesFilters?.filterCarrier?.group
      ?.filter((itemCarrier) => itemCarrier.checked)
      ?.map((itemCarrier) => itemCarrier.value),
    isFavorite: filters?.valuesFilters?.filterIsFavorite,
    includeRelatedPorts: filters?.isIncludeRelatedPortRequest,
  } as IQuotaServiceFiltersParamsDTM),
);

const getIsHaveFreightQuoteCurrentFiltersParams = createSelector(
  getFreightQuoteFilters,
  (filters) => (
    filters?.valuesFilters?.filterPrice?.filterMin
  || filters?.valuesFilters?.filterPrice?.filterMax
  || filters?.valuesFilters?.filterTransitTime?.filterMin
  || filters?.valuesFilters?.filterTransitTime?.filterMax
  || filters?.valuesFilters?.filterCarrier?.group
    ?.find((itemCarrier) => itemCarrier.checked)?.checked
  || filters?.valuesFilters?.filterIsFavorite
  || filters?.isIncludeRelatedPortRequest),
);

const getFreightQuoteCurrentOrigin = (state: RootState) => state.FreightQuota.currentState.form?.origin;

const getFreightQuoteCurrentDestination = (state: RootState) => (
  state.FreightQuota.currentState.form?.destination
);

const getFreightQuoteCurrentLocationPortStatus = (state: RootState) => (
  state.FreightQuota.currentState.locationPortStatus
);

const getOriginDoorAddress = createSelector(
  getFreightQuoteCurrent,
  (data) => data?.doorsAutocomplete?.origin?.options?.map((item) => ({
    label: item.description,
    value: item.value,
    key: item.key,
  })) || [],
);

const getSelectedDoorOriginAddress = createSelector(
  getOriginDoorAddress,
  getFreightQuoteCurrentOrigin,
  (options, origin) => {
    if (!origin?.location) {
      return undefined;
    }

    const option = options.find(({ value }) => value === origin.location?.code);

    return option?.label;
  },
);

const getDestinationDoorAddress = createSelector(
  getFreightQuoteCurrent,
  (data) => data?.doorsAutocomplete?.destination?.options?.map((item) => ({
    label: item.description,
    value: item.value,
    key: item.key,
  })) || [],
);

const getSelectedDoorDestinationAddress = createSelector(
  getDestinationDoorAddress,
  getFreightQuoteCurrentDestination,
  (options, origin) => {
    if (!origin?.location) {
      return undefined;
    }

    const option = options.find(({ value }) => value === origin.location?.code);

    return option?.label;
  },
);

const getQuantitySummaryContainers = createSelector(
  getFreightQuoteCurrent,
  (data) => data.form?.containers?.values?.reduce((acc, current) => ((current?.quantity ? Number(current.quantity) : 0) + acc), 0),
);

const getFreightQuoteCurrentFormServiceValues = createSelector(
  getFreightQuoteCurrent,
  (data) => data.form?.services?.values,
);

const getFreightQuoteCurrentFormServiceValuesChecked = createSelector(
  getFreightQuoteCurrent,
  (data) => data.form?.services?.valuesChecked,
);

const getRateServiceActive = createSelector(
  getFreightQuoteCurrentFormServiceValues,
  (data) => filter((item) => item.status !== 'INACTIVE', data).map((item) => ({
    ...item,
    key: `${uniqueId('parent_')}`,
  })) || [],
);

const groupedFilter = (item: AdditionalServiceDTM) => (item.mode === 'ROAD' && ['DRAYAGE', 'ALL'].includes(item.loadType) && item.occurrence === 'ADDITIONAL');

const getRateServiceFilter = createSelector(
  getFreightQuoteCurrentForm,
  getRateServiceActive,
  (data, dataActive) => filter((item) => !!item.phases && (
    (['OCEAN', 'ALL'].includes(item.mode) && ['FCL', 'ALL'].includes(item.loadType) && item.occurrence === 'ADDITIONAL')
    || (groupedFilter(item) && item.designation === 'ORIGIN' && !data?.origin?.datePort?.earliestDate)
    || (groupedFilter(item) && item.designation === 'DESTINATION' && !data?.destination?.datePort?.earliestDate)
  ), dataActive || []) || [],
);

const getFreightQuoteCurrentContainers = (state: RootState) => (
  state.FreightQuota.currentState.form?.containers
);

const getCountContainersTotal = createSelector(
  getFreightQuoteCurrentContainers,
  (containers) => containers?.values?.reduce((prev, current) => (Number(current.quantity) || 0) + prev, 0).toFixed(0),
);

const getCountContainersWeightTotal = createSelector(
  getFreightQuoteCurrentContainers,
  (containers) => containers?.values?.reduce((prev, current) => (Number(current.weight) * Number(current.quantity) || 0) + prev, 0).toFixed(0),
);

const getCountContainersVolumeTotal = createSelector(
  getFreightQuoteCurrentContainers,
  (containers) => containers?.values?.reduce((prev, current) => (Number(current.volume) * Number(current.quantity) || 0) + prev, 0).toFixed(0),
);

// Details

const getFreightQuoteCurrentQuotas = createSelector(
  selectSelf,
  (data) => data.FreightQuota.quotas,
);

const getFreightQuoteCurrentQuotasIndexSchedule = (index: number) => createSelector(
  getFreightQuoteCurrentQuotas,
  (quotas) => quotas?.indexSchedules?.[index]?.idIndex,
);

const getFreightQuoteCurrentAllQuotas = createSelector(
  getFreightQuoteCurrentQuotas,
  (quotas) => quotas?.allQuotas,
);

const getFreightQuoteCurrentAllQuotasByIndex = (index?: number) => createSelector(
  getFreightQuoteCurrentQuotas,
  (quotas) => (typeof index === 'number' ? (
    quotas?.allQuotas?.[index]
  ) : undefined),
);

const getFreightQuoteCurrentAllQuotasAppliedByIndex = (index: number) => createSelector(
  getFreightQuoteCurrentAllQuotasByIndex(index),
  (allQuotas) => {
    const schedules: IFreightQuotaContentSchedulesDTM[] = [];
    allQuotas?.routes?.forEach((item) => {
      item?.legs?.forEach((itemLegs) => {
        allQuotas
          ?.schedules?.forEach((itemSchedule) => {
            const findTransportations = itemSchedule?.transportations
              ?.filter((itemTransportation) => itemTransportation.transportLeg === itemLegs.id);

            const findTransportation = findTransportations?.[0];
            const pickupFromTime = findTransportation?.schedule?.type === ChargeCodeLoadTypeEnum.DRAYAGE ? findTransportation
              ?.schedule?.pickupFromTime || '' : '';

            schedules.push({
              ...itemSchedule,
              pickupFromTime,
            });
          });
      });
    });

    const newData = FreightQuotaContentDTM.fromPlain({
      ...allQuotas,
      schedules: [...allQuotas?.schedules?.map((item) => ({
        ...item,
        pickupFromTime: schedules?.filter((itemFind) => item.id === itemFind.id)?.[0]?.pickupFromTime,
        transportations: [
          ...item.transportations || [],
        ],
        charges: [...item?.charges?.filter((charge) => charge.applied) || []],
      })) || []],
    });

    return newData;
  },
);

const getFreightQuoteCurrentAllQuotasNotAppliedByIndex = (index: number) => createSelector(
  getFreightQuoteCurrentAllQuotasByIndex(index),
  (allQuotas) => FreightQuotaContentDTM.fromPlain({
    ...allQuotas,
    schedules: [...allQuotas?.schedules?.map((item) => ({
      ...item,
      charges: [...item?.charges?.filter((charge) => !charge.applied) || []],
    })) || []],
  }),
);

const getFreightQuoteCurrentAllQuotasDetailsContainersTypeByIdTypes = (index: number) => createSelector(
  getFreightQuoteCurrentAllQuotasAppliedByIndex(index),
  (allQuotas) => {
    const containersTypes: IDetailBreakdownStepsContainersTypesDTM = {};
    let containersTypesById: IDetailBreakdownStepsContainersTypesByIdDTM = {};

    allQuotas?.containers?.forEach((itemContainer) => {
      if (containersTypes[itemContainer?.type || 0]) {
        containersTypes[itemContainer?.type || ''].push(itemContainer?.id || 0);
      } else {
        containersTypes[itemContainer?.type || ''] = [itemContainer?.id || 0];
      }
      containersTypesById = {
        ...containersTypesById,
        [itemContainer?.id || 0]: itemContainer.type,
      };
    });

    return {
      containersTypes,
      containersTypesById,
    };
  },
);

const getFreightQuoteCurrentFileNamePrint = () => createSelector(
  getFreightQuoteCurrent,
  (currentState) => currentState.fileNamePrint,
);

const getFreightQuoteCurrentAllQuotasByIndexFileNamePrint = (index?: number) => createSelector(
  getFreightQuoteCurrentAllQuotasByIndex(index),
  (allQuotas) => {
    let fileName = 'Skypace. Quote #';
    const quota = FreightQuotaContentDTM.fromPlain({
      ...allQuotas,
    });

    if (quota?.id) {
      fileName += `${quota.id}`;
    }

    fileName += `.${quota?.getOriginLocationUNLOCK()}`;
    fileName += ` - ${quota?.getDestinationLocationUNLOCK()}`;

    return fileName;
  },
);

const getPaymentTerms = (name: string, incoterm?: string, incotermTrade?: string) => {
  switch (name) {
    case 'origin':
      if (incotermTrade === EFreightIncotermsTrade.EXPORT) {
        if (incoterm === EFreightIncotermsByExport.DAP
          || incoterm === EFreightIncotermsByExport.DPU
          || incoterm === EFreightIncotermsByExport.CIF
          || incoterm === EFreightIncotermsByExport.CFR) {
          return EFreightPaymentTerms.PREPAID;
        }
      }

      if (incotermTrade === EFreightIncotermsTrade.IMPORT) {
        if (incoterm === EFreightIncotermsByImport.EXW) {
          return EFreightPaymentTerms.COLLECT;
        }
        if (incoterm === EFreightIncotermsByImport.FCA || incoterm === EFreightIncotermsByImport.FAS || incoterm === EFreightIncotermsByImport.FOB) {
          return EFreightPaymentTerms.PREPAID;
        }
      }
      return EFreightPaymentTerms.DEFAULT;

    case 'freight':
      if (incotermTrade === EFreightIncotermsTrade.EXPORT) {
        if (incoterm === EFreightIncotermsByExport.DAP
          || incoterm === EFreightIncotermsByExport.DPU
          || incoterm === EFreightIncotermsByExport.CIF
          || incoterm === EFreightIncotermsByExport.CFR) {
          return EFreightPaymentTerms.PREPAID;
        }
      }

      if (incotermTrade === EFreightIncotermsTrade.IMPORT) {
        if (incoterm === EFreightIncotermsByImport.EXW
          || incoterm === EFreightIncotermsByImport.FCA
          || incoterm === EFreightIncotermsByImport.FAS
          || incoterm === EFreightIncotermsByImport.FOB) {
          return EFreightPaymentTerms.COLLECT;
        }
      }

      return EFreightPaymentTerms.DEFAULT;

    case 'destination':
      if (incotermTrade === EFreightIncotermsTrade.EXPORT) {
        if (incoterm === EFreightIncotermsByExport.DAP || incoterm === EFreightIncotermsByExport.DPU) {
          return EFreightPaymentTerms.PREPAID;
        }
        if (incoterm === EFreightIncotermsByExport.CIF || incoterm === EFreightIncotermsByExport.CFR) {
          return EFreightPaymentTerms.COLLECT;
        }
      }

      if (incotermTrade === EFreightIncotermsTrade.IMPORT) {
        if (incoterm === EFreightIncotermsByImport.EXW
          || incoterm === EFreightIncotermsByImport.FCA
          || incoterm === EFreightIncotermsByImport.FAS
          || incoterm === EFreightIncotermsByImport.FOB) {
          return EFreightPaymentTerms.COLLECT;
        }
      }

      return EFreightPaymentTerms.DEFAULT;

    default:
      return EFreightPaymentTerms.DEFAULT;
  }
};

const getPaymentTermsSelected = createSelector(
  getFreightQuoteCurrent,
  (current) => {
    const { incotermsTrade, incoterms: incoterm } = current;

    return {
      hasOriginCollect: incotermsTrade === EFreightIncotermsTrade.EXPORT ? getPaymentTerms('origin', incoterm, incotermsTrade) === EFreightPaymentTerms.PREPAID : getPaymentTerms('origin', incoterm, incotermsTrade) === EFreightPaymentTerms.COLLECT,
      hasFreightCollect: incotermsTrade === EFreightIncotermsTrade.EXPORT ? getPaymentTerms('freight', incoterm, incotermsTrade) === EFreightPaymentTerms.PREPAID : getPaymentTerms('freight', incoterm, incotermsTrade) === EFreightPaymentTerms.COLLECT,
      hasDestinationCollect: incotermsTrade === EFreightIncotermsTrade.EXPORT ? getPaymentTerms('destination', incoterm, incotermsTrade) === EFreightPaymentTerms.PREPAID : getPaymentTerms('destination', incoterm, incotermsTrade) === EFreightPaymentTerms.COLLECT,
    };
  },
);

const getFreightQuoteCurrentAllQuotasDetailsRoutingByIndex = (index: number) => createSelector(
  getFreightQuoteCurrentAllQuotasAppliedByIndex(index),
  getFreightQuoteCurrentQuotasIndexSchedule(index),
  getFreightQuoteCurrentAllQuotasDetailsContainersTypeByIdTypes(index),
  (allQuotas, indexSchedule = 0, { containersTypes, containersTypesById }) => {
    const freight: DetailRoutingDTM[] = [];
    const origin: DetailRoutingDTM[] = [];
    const destination: DetailRoutingDTM[] = [];
    let originBreakdown: undefined | DetailBreakdownDTM;
    let destinationBreakdown: undefined | DetailBreakdownDTM;

    const getTerminal = (type: string, propsGetTerminal?: {
      name?: string
      address?: string
      postalCode?: string
    }) => {
      const {
        name,
        address,
        postalCode,
      } = propsGetTerminal || {};

      if (type === 'DOOR') {
        if (address) {
          return `${address}${postalCode ? `, ${postalCode}` : ''}`;
        }
        return `${postalCode || ''}`;
      }

      return name;
    };

    allQuotas?.routes?.forEach((item) => {
      item?.legs?.forEach((itemLegs) => {
        const transportLegType = allQuotas
          ?.schedules?.[indexSchedule]
          ?.transportations
          ?.filter((itemTransportations) => itemTransportations.transportLeg === itemLegs.id);

        if (!transportLegType?.length) {
          return;
        }

        const transportLegTypeFirst = transportLegType?.[0]?.transport?.type || '';
        const { country, name: city, city: cityDoor } = itemLegs.arrivalLocation;
        const {
          country: countryFrom, name: cityFrom, city: cityFromDoor,
        } = itemLegs.departureLocation;

        const findTransportations = allQuotas
          ?.schedules?.[indexSchedule]
          ?.transportations
          ?.filter((itemTransportation) => itemTransportation.transportLeg === itemLegs.id);
        let isFindOrigin = false;
        let isFindDestination = false;

        const findService: IFreightQuotaContentSchedulesChargesDTM[] = [];
        const findFees = allQuotas
          ?.schedules?.[indexSchedule]
          ?.charges
          ?.filter((itemSurcharges) => {
            isFindOrigin = itemSurcharges?.designation === chargeCodeDesignation.ORIGIN ? true : isFindOrigin;
            isFindDestination = itemSurcharges?.designation === chargeCodeDesignation.DESTINATION ? true : isFindDestination;

            if (itemSurcharges.subjectTo === chargeCodeSubjectTo.INCLUDED) {
              return false;
            }

            if (itemSurcharges?.chargeCode?.occurrence === chargeCodeOccurrence.ADDITIONAL) {
              findService.push(itemSurcharges);
              return false;
            }

            return (itemSurcharges?.priceBy === chargeCodePriceBy.BOL || itemSurcharges?.chargeCode?.type === chargeCodeType.FEE) && (Number(itemSurcharges.totalCost) > 0 || Number(itemSurcharges?.totalCost) < 0);
          });

        const findTransportation = findTransportations?.[0];
        const objPush: DetailRoutingDTM = DetailRoutingDTM.fromPlain({
          type: findTransportation?.schedule?.type,
          legId: itemLegs.id,
          duration: findTransportation
            ?.schedule?.transitDuration || 0,
          paymentTerms: getPaymentTerms(itemLegs.phase, allQuotas.incoterm, allQuotas.tradeType),
          to: getLocationToOneString({ country: country?.code || '', city: city || cityDoor, firstCityName: true }),
          from: getLocationToOneString({ country: countryFrom?.code || '', city: cityFrom || cityFromDoor, firstCityName: true }),
          pickupFromTime: findTransportation?.schedule?.pickupFromTime || '',
          pickupToTime: findTransportation?.schedule?.pickupToTime || '',
          fullPickupTime: findTransportation?.schedule?.fullPickupTime || '',
          deliveryTime: findTransportation?.schedule?.deliveryTime || '',
          fullReturnFromTime: findTransportation?.schedule?.fullReturnFromTime || '',
          fullReturnToTime: findTransportation?.schedule?.fullReturnToTime || '',
          etd: findTransportation?.schedule?.departureTime || '',
          eta: findTransportation
            ?.schedule?.arrivalTime || '',
          transportType: transportLegTypeFirst,
          VesselName: findTransportation?.transport?.name,
          VoyageNumber: findTransportation?.voyageCode || undefined,
          arrivalTerminal: getTerminal(itemLegs?.arrivalLocation?.type || '', {
            name: findTransportation?.arrivalTerminal || '',
            address: itemLegs.arrivalLocation.address,
            postalCode: itemLegs.arrivalLocation.postalCode,
          }),
          departureTerminal: getTerminal(itemLegs?.departureLocation?.type || '', {
            name: findTransportation?.departureTerminal || '',
            address: itemLegs.departureLocation.address,
            postalCode: itemLegs.departureLocation.postalCode,
          }),
          transportations: findTransportations || undefined,
        });

        const errorsobjPush = item.validate();

        if (errorsobjPush.length) {
          console.error('DTM valid RFQ selector: getFreightQuoteCurrentAllQuotasDetailsRoutingByIndex', errorsobjPush);
        }

        const getFindSteps = (name: string,
          dataStep: FreightQuotaContentSchedulesChargesDTM[]) => {
          let findSummary = 0;
          const findData: DetailRoutingContainerStepDTM[] = dataStep
            .filter((itemFees) => itemFees?.designation?.toLocaleLowerCase() === name)
            .map((itemFees) => {
              const descriptionText = (itemFees?.priceBy === chargeCodePriceBy.BOL
                && itemFees?.measureBy === chargeCodeMeasureBy.FLAT)
                ? i18n.t('perBL')
                : undefined;

              const nameFees = itemFees?.chargeCode?.code === chargeCodeCode.MSC && itemFees?.chargeCode?.originalDescription
                ? itemFees?.chargeCode?.originalDescription
                : itemFees?.chargeCode?.description || '';
              findSummary += itemFees?.totalCost || 0;
              return DetailRoutingContainerStepDTM.fromPlain({
                name: nameFees,
                summary: itemFees.totalCost,
                cost: itemFees.costPerUnit,
                totalUnits: 1,
                numberOfUnits: itemFees?.numberOfUnits,
                measureBy: itemFees?.measureBy,
                descriptionText,
                isUnits: !!descriptionText,
              });
            });

          return { findData, findSummary };
        };

        const getFindServiceSteps = (name: string,
          dataStep: IFreightQuotaContentSchedulesChargesDTM[]) => {
          let findSummary = 0;
          const stackNames: string[] = [];
          const newDataService: {
            [key: string]: DetailRoutingContainerStepDTM
          } = {};

          const containersServicesArray: DetailBreakdownContainerDTM[] = [];
          const checkSomeType = new Set();

          dataStep
            .filter((itemFees) => itemFees?.designation?.toLocaleLowerCase() === name)
            .forEach((itemFees) => {
              if (checkSomeType
                .has(containersTypesById[itemFees.containerId || ''])
              ) {
                containersServicesArray.forEach((itemService) => {
                  if (itemFees.containerId && itemService.containerId === itemFees.containerId) {
                    const nameFees = itemFees?.chargeCode?.code === chargeCodeCode.MSC && itemFees?.chargeCode?.originalDescription
                      ? itemFees?.chargeCode?.originalDescription
                      : itemFees?.chargeCode?.description || '';

                    const totalUnitsName = containersTypesById?.[itemFees?.containerId || ''] || '';
                    findSummary += itemFees?.totalCost || 0;

                    const descriptionText = (itemFees?.priceBy === chargeCodePriceBy.BOL
                      && itemFees.measureBy === chargeCodeMeasureBy.FLAT)
                      ? i18n.t('perBL')
                      : undefined;

                    if (itemFees?.priceBy === chargeCodePriceBy.CONTAINER) {
                      itemService.steps?.push(DetailRoutingContainerStepDTM.fromPlain({
                        name: nameFees,
                        summary: Number(((itemFees?.totalCost || 0) * containersTypes[totalUnitsName]?.length)?.toFixed(2)),
                        cost: itemFees.costPerUnit || 0,
                        numberOfUnits: itemFees.numberOfUnits,
                        measureBy: itemFees?.measureBy,
                        totalUnits: Number(containersTypes?.[totalUnitsName]?.length) || 0,
                        isUnits: itemFees?.priceBy === chargeCodePriceBy.CONTAINER || !!descriptionText,
                        descriptionText,
                      }));
                    }
                  }
                });

                return;
              }
              checkSomeType.add(containersTypesById[itemFees.containerId || ''] || null);

              const descriptionText = (itemFees?.priceBy === chargeCodePriceBy.BOL
                && itemFees.measureBy === chargeCodeMeasureBy.FLAT)
                ? i18n.t('perBL')
                : undefined;

              const totalUnitsName = containersTypesById?.[itemFees?.containerId || ''] || '';
              findSummary += itemFees?.totalCost || 0;

              if (itemFees?.priceBy === chargeCodePriceBy.CONTAINER) {
                const nameFees = itemFees?.chargeCode?.code === chargeCodeCode.MSC && itemFees?.chargeCode?.originalDescription
                  ? itemFees?.chargeCode?.originalDescription
                  : itemFees?.chargeCode?.description || '';

                if (newDataService[itemFees?.chargeCode?.description || '']) {
                  const containerNameType = containersTypesById[itemFees.containerId || ''] || '';
                  const containerName = (isContainerAllTypes(containerNameType) && ContainerAllTypesNamesLongConst[containerNameType]) || '';

                  containersServicesArray.push(DetailBreakdownContainerDTM.fromPlain({
                    name: containerName,
                    containerId: itemFees?.containerId,
                    steps: [{
                      name: nameFees,
                      summary: Number(((itemFees?.totalCost || 0) * containersTypes[totalUnitsName]?.length)?.toFixed(2)),
                      cost: itemFees.costPerUnit || 0,
                      numberOfUnits: itemFees.numberOfUnits,
                      measureBy: itemFees?.measureBy,
                      totalUnits: Number(containersTypes?.[totalUnitsName]?.length) || 0,
                      isUnits: itemFees?.priceBy === chargeCodePriceBy.CONTAINER || !!descriptionText,
                      descriptionText,
                    }],
                    summary: Number(((itemFees?.totalCost || 0) * containersTypes[totalUnitsName]?.length)?.toFixed(2)),
                    cost: Number(itemFees?.totalCost?.toFixed(2)),
                    totalUnits: 0,
                  }));
                } else if (itemFees?.chargeCode?.code !== chargeCodeCode.MSC) {
                  const containerNameType = containersTypesById[itemFees.containerId || ''] || '';
                  const containerName = (isContainerAllTypes(containerNameType) && ContainerAllTypesNamesLongConst[containerNameType]) || '';

                  containersServicesArray.push(DetailBreakdownContainerDTM.fromPlain({
                    name: containerName,
                    containerId: itemFees?.containerId,
                    steps: [{
                      name: nameFees,
                      summary: Number(((itemFees?.totalCost || 0) * containersTypes[totalUnitsName]?.length)?.toFixed(2)),
                      cost: itemFees.costPerUnit || 0,
                      numberOfUnits: itemFees.numberOfUnits,
                      measureBy: itemFees?.measureBy,
                      totalUnits: Number(containersTypes?.[totalUnitsName]?.length) || 0,
                      isUnits: itemFees?.priceBy === chargeCodePriceBy.CONTAINER || !!descriptionText,
                      descriptionText,
                      totalCost: itemFees?.totalCost || 0,
                    }],
                    summary: Number(((itemFees?.totalCost || 0) * containersTypes[totalUnitsName]?.length)?.toFixed(2)),
                    cost: Number(itemFees?.totalCost?.toFixed(2)),
                    totalUnits: 0,
                  }));

                  if (itemFees?.chargeCode?.description) {
                    stackNames.push(itemFees.chargeCode.description);
                  }
                }
              } else if (newDataService[itemFees?.chargeCode?.description || '']) {
                newDataService[itemFees?.chargeCode?.description || ''] = DetailRoutingContainerStepDTM.fromPlain({
                  name: itemFees?.chargeCode?.description || '',
                  summary: (newDataService[itemFees?.chargeCode?.description || ''].summary || 0) + (itemFees?.totalCost || 0),
                  cost: itemFees.costPerUnit || 0,
                  numberOfUnits: itemFees.numberOfUnits,
                  measureBy: itemFees?.measureBy,
                  totalUnits: newDataService[itemFees?.chargeCode?.description || '']?.totalUnits + 1,
                  isUnits: itemFees?.priceBy === chargeCodePriceBy.CONTAINER || !!descriptionText,
                  descriptionText,
                });
              } else {
                newDataService[itemFees?.chargeCode?.description || ''] = DetailRoutingContainerStepDTM.fromPlain({
                  name: itemFees?.chargeCode?.description || '',
                  summary: itemFees.totalCost || 0,
                  cost: itemFees.costPerUnit || 0,
                  numberOfUnits: itemFees.numberOfUnits,
                  measureBy: itemFees?.measureBy,
                  totalUnits: 1,
                  isUnits: itemFees?.priceBy === chargeCodePriceBy.CONTAINER || !!descriptionText,
                  descriptionText,
                });

                if (itemFees?.chargeCode?.description) {
                  stackNames.push(itemFees.chargeCode.description);
                }
              }
            });

          const findData: DetailRoutingContainerStepDTM[] = Object.values(newDataService).map((itemValue) => DetailRoutingContainerStepDTM.fromPlain({
            name: itemValue?.name || '',
            summary: itemValue.summary,
            cost: itemValue.cost,
            totalUnits: itemValue.totalUnits,
            numberOfUnits: itemValue.numberOfUnits,
            measureBy: itemValue?.measureBy,
            isUnits: itemValue.isUnits,
            descriptionText: itemValue.descriptionText,
          }));

          const containersServices: DetailBreakdownServicesDTM = DetailBreakdownServicesDTM.fromPlain({
            summary: findSummary,
            containers: containersServicesArray,
            other: {
              summary: 0,
              steps: findData,
            },
          });

          return { findData: containersServices, findSummary };
        };

        if (itemLegs.phase.toLocaleLowerCase() === 'freight') {
          const { findData: findFeesSteps, findSummary: findFeesSummary } = getFindSteps('freight', findFees || []);
          const { findData: findServiceSteps, findSummary: findServiceSummary } = getFindServiceSteps('freight', findService);

          freight.push({
            ...objPush,
            fees: DetailRoutingStepsDTM.fromPlain({
              summary: findFeesSummary,
              steps: findFeesSteps,
            }),
            service: DetailBreakdownServicesDTM.fromPlain({
              summary: findServiceSummary,
              containers: findServiceSteps.containers,
              other: findServiceSteps.other,
            }),
          });
        }

        const objFind = (name: string) => {
          const { findData: findFeesSteps, findSummary: findFeesSummary } = getFindSteps(name, findFees || []);
          const { findData: findServiceSteps, findSummary: findServiceSummary } = getFindServiceSteps(name, findService);

          return DetailBreakdownDTM.fromPlain({
            fees: DetailBreakdownStepsDTM.fromPlain({
              summary: findFeesSummary,
              steps: findFeesSteps,
            }),
            service: DetailBreakdownServicesDTM.fromPlain({
              summary: findServiceSummary,
              containers: findServiceSteps.containers,
              other: findServiceSteps.other,
            }),
            fieldName: '',
            paymentTerms: getPaymentTerms(name, allQuotas.incoterm, allQuotas.tradeType),
            summary: findFeesSummary,
          });
        };

        if (isFindOrigin) {
          originBreakdown = objFind('origin');
        }
        if (itemLegs.phase.toLocaleLowerCase() === 'origin'
          && !origin?.filter((itemOrigin) => itemOrigin.legId === itemLegs.id).length) {
          origin.push({ ...objPush });
        }
        if (isFindDestination) {
          destinationBreakdown = objFind('destination');
        }
        if (itemLegs.phase.toLocaleLowerCase() === 'destination'
          && !destination?.filter((itemOrigin) => itemOrigin.legId === itemLegs.id).length) {
          destination.push({ ...objPush });
        }
      });
    });
    return {
      freight,
      origin,
      destination,
      originBreakdown,
      destinationBreakdown,
    };
  },
);

const getFreightQuoteCurrentAllQuotasDetailsBreakdownByIndex = (index: number) => createSelector(
  getFreightQuoteCurrentAllQuotasAppliedByIndex(index),
  getFreightQuoteCurrentQuotasIndexSchedule(index),
  getFreightQuoteCurrentAllQuotasDetailsContainersTypeByIdTypes(index),
  getFreightQuoteCurrentAllQuotasDetailsRoutingByIndex(index),
  (allQuotas, indexSchedule = 0, { containersTypes, containersTypesById }, {
    originBreakdown, destinationBreakdown, freight: freightData,
    origin: originData,
    destination: destinationData,
  }) => {
    let resultFreight: null | DetailBreakdownDTM = null;
    let resultOrigin: null | DetailBreakdownDTM = null;
    let resultDestination: null | DetailBreakdownDTM = null;

    const freight: DetailBreakdownDTM = DetailBreakdownDTM.fromPlain({
      fieldName: i18n.t('Freight'),
      summary: allQuotas?.cost?.freightTotalCost || 0,
      transportation: DetailBreakdownTransportationDTM.fromPlain({ summary: 0, containers: [] }),
      paymentTerms: getPaymentTerms('freight', allQuotas?.incoterm, allQuotas?.tradeType),
    });
    const origin: DetailBreakdownDTM = DetailBreakdownDTM.fromPlain({
      fieldName: i18n.t('Origin'),
      summary: allQuotas?.cost?.originTotalCost || 0,
      transportation: DetailBreakdownTransportationDTM.fromPlain({ summary: 0, containers: [] }),
      paymentTerms: getPaymentTerms('origin', allQuotas?.incoterm, allQuotas?.tradeType),
    });
    const destination: DetailBreakdownDTM = DetailBreakdownDTM.fromPlain({
      fieldName: i18n.t('Destination'),
      summary: allQuotas?.cost?.destinationTotalCost || 0,
      transportation: DetailBreakdownTransportationDTM.fromPlain({ summary: 0, containers: [] }),
      paymentTerms: getPaymentTerms('destination', allQuotas?.incoterm, allQuotas?.tradeType),
    });

    let transportationsId: number[] = [];
    freightData?.forEach((itemTransport) => {
      transportationsId = itemTransport?.transportations
        ?.reduce((prev: number[], item) => { prev.push(item.id); return prev; }, []) || [];
    });
    originData?.forEach((itemTransport) => {
      const transportationsIdAdd = itemTransport?.transportations
        ?.reduce((prev: number[], item) => { prev.push(item.id); return prev; }, []) || [];
      transportationsIdAdd.forEach((item) => {
        if (!transportationsId.includes(item)) {
          transportationsId.push(item);
        }
      });
    });
    destinationData?.forEach((itemTransport) => {
      const transportationsIdAdd = itemTransport?.transportations
        ?.reduce((prev: number[], item) => { prev.push(item.id); return prev; }, []) || [];
      transportationsIdAdd.forEach((item) => {
        if (!transportationsId.includes(item)) {
          transportationsId.push(item);
        }
      });
    });

    const getResultBreakdown = (objData: DetailBreakdownDTM, name: string) => {
      const checkSomeType = new Set();
      const filterChargesAll: IFreightQuotaContentSchedulesChargesDTM[] = [];
      const filterCharges = allQuotas?.schedules?.[indexSchedule]?.charges
        ?.filter((itemCharge) => {
          const someUnitType = intersection(transportationsId,
            itemCharge.transportationIds);

          if (someUnitType.length) {
            filterChargesAll.push(itemCharge);
          }
          if (checkSomeType
            .has(containersTypesById[itemCharge.containerId || ''])
          ) {
            return false;
          }
          checkSomeType.add(containersTypesById[itemCharge.containerId || ''] || null);
          return someUnitType.length;
        }); // find same values
      let transportationSummary = 0;
      const includedBaseRateContainer: number[] = [];
      const includedBaseRateId: string[] = [];

      filterCharges?.forEach((itemCharge) => {
        const surcharges = allQuotas?.schedules?.[indexSchedule]?.charges;
        const infoSteps: string[] = [];
        const includedBaseRate: string[] = [];

        const stepsSurcharges = surcharges
          ?.sort((aCharge) => (includes(chargeCodeCode, aCharge?.chargeCode?.code) ? 0 : 1))
          .reduce((prev: DetailRoutingContainerStepDTM[], itemSurcharge) => {
            if (itemSurcharge.subjectTo === chargeCodeSubjectTo.INCLUDED) {
              if (itemSurcharge?.chargeCode?.code !== chargeCodeCode.MSC && itemSurcharge?.chargeCode?.description
                && !infoSteps.includes(itemSurcharge.chargeCode.description.toLowerCase())) {
                infoSteps.push(itemSurcharge.chargeCode.description.toLowerCase());
              } else if (itemSurcharge?.chargeCode?.code === chargeCodeCode.MSC) {
                if (itemSurcharge?.chargeCode?.originalDescription
                  && !infoSteps.includes(itemSurcharge?.chargeCode?.originalDescription.toLowerCase())) {
                  infoSteps.push(itemSurcharge?.chargeCode?.originalDescription.toLowerCase());
                } else if (!itemSurcharge?.chargeCode?.originalDescription && itemSurcharge?.chargeCode.description && !infoSteps.includes(itemSurcharge?.chargeCode.description.toLowerCase())) {
                  infoSteps.push(itemSurcharge?.chargeCode?.description.toLowerCase());
                }
              }

              if ((Number(itemSurcharge.totalCost) === 0 && itemSurcharge?.designation === name.toUpperCase())
              ) {
                const nameCharge = itemSurcharge?.chargeCode?.code === chargeCodeCode.MSC && itemSurcharge?.chargeCode?.originalDescription
                  ? itemSurcharge?.chargeCode?.originalDescription
                  : itemSurcharge?.chargeCode?.description || '';

                if ((includedBaseRate.includes(nameCharge)
                  || includedBaseRateId.includes(itemSurcharge.chargeCode?.customId || ''))
                  || (!containersTypesById[itemCharge.containerId || ''])) return prev;

                includedBaseRateId.push(itemSurcharge.chargeCode?.customId || uuidv4());
                includedBaseRate.push(nameCharge);
                if (itemCharge.containerId) {
                  includedBaseRateContainer.push(itemCharge.containerId);
                }

                if (name.toUpperCase() === chargeCodeDesignation.FREIGHT
                  && nameCharge) {
                  if (!infoSteps.includes(nameCharge.toLowerCase())) {
                    infoSteps.push(nameCharge.toLowerCase());
                  }
                } else {
                  prev.push(DetailRoutingContainerStepDTM.fromPlain({
                    name: nameCharge,
                    cost: 0,
                    totalUnits: 0,
                    numberOfUnits: itemSurcharge?.numberOfUnits,
                    measureBy: itemSurcharge?.measureBy,
                    summary: 0,
                    code: itemSurcharge?.chargeCode?.code,
                    isIncludedBaseRate: true,
                  }));
                }
              }

              return prev;
            }

            if (itemSurcharge?.designation === name.toUpperCase()
              && itemSurcharge.containerId === itemCharge.containerId
            ) {
              if (itemSurcharge.priceBy !== chargeCodePriceBy.BOL
                && itemSurcharge?.chargeCode?.type !== chargeCodeType.FEE
                && itemSurcharge.subjectTo !== chargeCodeSubjectTo.INCLUDED
                && (Number(itemSurcharge.totalCost) > 0 || Number(itemSurcharge?.totalCost) < 0)
              ) {
                const totalUnitsName = containersTypesById?.[itemSurcharge?.containerId || ''] || '';

                if (itemSurcharge?.chargeCode?.occurrence === chargeCodeOccurrence.ADDITIONAL) return prev;

                const nameCharge = itemSurcharge?.chargeCode?.code === chargeCodeCode.MSC && itemSurcharge?.chargeCode?.originalDescription
                  ? itemSurcharge?.chargeCode?.originalDescription
                  : itemSurcharge?.chargeCode?.description || '';

                prev.push(DetailRoutingContainerStepDTM.fromPlain({
                  name: nameCharge,
                  cost: Number(itemSurcharge.costPerUnit),
                  totalCost: itemSurcharge?.totalCost,
                  numberOfUnits: itemSurcharge?.numberOfUnits,
                  measureBy: itemSurcharge?.measureBy,
                  totalUnits: Number(containersTypes?.[totalUnitsName]?.length) || 0,
                  summary: Number(((itemSurcharge?.totalCost || 1) * containersTypes[totalUnitsName]?.length)
                    .toFixed(2)),
                  code: itemSurcharge?.chargeCode?.code,
                }));

                transportationSummary += Number((itemSurcharge?.totalCost || 1).toFixed(2)) || 0;
              }
            }

            return prev;
          }, [])
          .sort((item) => (item.isIncludedBaseRate ? 1 : -1))
          // sort for Chrome
          .sort((aCharge) => (!includes(chargeCodeCode, aCharge?.code) ? -1 : 0))
          .sort((aCharge) => (aCharge?.code === chargeCodeCode.FRT ? -1 : 0))
          .sort((aCharge) => (aCharge?.code === chargeCodeCode.DRAY ? -1 : 0));

        const filterChargesAllTotalUnits = filterChargesAll
          .filter((itemChargeType) => itemChargeType.containerId === itemCharge.containerId
            && itemChargeType.subjectTo !== chargeCodeSubjectTo.INCLUDED
            && (Number(itemChargeType?.totalCost) > 0 || Number(itemChargeType?.totalCost) < 0)
            && itemChargeType.applied
            && itemChargeType?.priceBy === chargeCodePriceBy.CONTAINER
            && (itemChargeType?.chargeCode?.type === chargeCodeType.CHARGE
              || itemChargeType?.chargeCode?.type === chargeCodeType.SURCHARGE)
            && itemChargeType?.designation === name.toUpperCase())
          .length || 0;
        transportationSummary += Number(name.toUpperCase() === chargeCodeDesignation.FREIGHT
          ? (((itemCharge?.costPerUnit || 1) * filterChargesAllTotalUnits).toFixed(2))
          : 0);

        const freightSteps = [...stepsSurcharges?.map((itemSurcharge) => {
          if (includes(chargeCodeCode, itemSurcharge.code)) {
            return {
              ...itemSurcharge,
              infoSteps: (itemSurcharge?.code !== chargeCodeCode.MSC && itemSurcharge?.code !== chargeCodeCode.DRAY) ? infoSteps : undefined,
            };
          }
          return itemSurcharge;
        }) || []];

        if (freightSteps.length) {
          const containerNameType = containersTypesById[itemCharge.containerId || ''] || '';
          const containerName = (isContainerAllTypes(containerNameType) && ContainerAllTypesNamesLongConst[containerNameType]) || '';

          objData?.transportation?.containers?.push(DetailBreakdownContainerDTM.fromPlain({
            name: containerName,
            steps: freightSteps,
            summary: Number(transportationSummary.toFixed(2)),
            cost: Number(itemCharge?.totalCost?.toFixed(2)),
            totalUnits: allQuotas?.schedules?.[indexSchedule]?.charges?.length || 0,
          }));
        }
      });
      return { objData, summary: transportationSummary };
    };

    const { objData: freightGetData, summary: freightTransportSummary } = getResultBreakdown(freight, 'Freight');

    const { objData: originGetData } = getResultBreakdown(origin, 'Origin');

    const { objData: destinationGetData } = getResultBreakdown(destination, 'Destination');

    resultFreight = {
      ...freightGetData,
      transportation: DetailBreakdownTransportationDTM.fromPlain({
        ...freightGetData.transportation,
        summary: freightTransportSummary,
      }),
      fees: freightData?.[0]?.fees ? DetailBreakdownStepsDTM.fromPlain(freightData?.[0]?.fees) : undefined,
      service: freightData?.[0]?.service ? DetailBreakdownTransportationDTM.fromPlain(freightData?.[0]?.service) : undefined,
    };

    resultOrigin = {
      ...originGetData,
      fees: originBreakdown?.fees,
      service: originBreakdown?.service,
    };

    resultDestination = {
      ...destinationGetData,
      fees: destinationBreakdown?.fees,
      service: destinationBreakdown?.service,
    };

    return {
      resultFreight,
      resultOrigin,
      resultDestination,
    };
  },
);

const getFreightQuoteCurrentAllQuotasDetailsAccessorialsByIndex = (index: number) => createSelector(
  getFreightQuoteCurrentAllQuotasNotAppliedByIndex(index),
  getFreightQuoteCurrentQuotasIndexSchedule(index),
  getFreightQuoteCurrentAllQuotasDetailsContainersTypeByIdTypes(index),
  (allQuotas, indexSchedule = 0, { containersTypesById }): AccessorialsFilterReturnDTM => {
    const freight: DetailAccessorialsDTM = DetailAccessorialsDTM.fromPlain({ fieldName: i18n.t('Freight'), summary: 0, transportation: DetailAccessorialsTransportationDTM.fromPlain({ summary: 0, containers: [] }) });
    const origin: DetailAccessorialsDTM = DetailAccessorialsDTM.fromPlain({ fieldName: i18n.t('Origin'), summary: 0, transportation: DetailAccessorialsTransportationDTM.fromPlain({ summary: 0, containers: [] }) });
    const destination: DetailAccessorialsDTM = DetailAccessorialsDTM.fromPlain({ fieldName: i18n.t('Destination'), summary: 0, transportation: DetailAccessorialsTransportationDTM.fromPlain({ summary: 0, containers: [] }) });

    const getResultAccessorials = (objData: DetailAccessorialsDTM, name: string) => {
      const filterCharges = allQuotas?.schedules?.[indexSchedule]?.charges;

      const freeTime: DetailAccessorialsContainerDTM[] = [];
      const accessorials: DetailAccessorialsContainerDTM[] = [];
      const accessorialsGroup: DetailAccessorialsTransportationDTM[] = [];
      const penalties: DetailAccessorialsContainerDTM[] = [];

      const setContainerItem = (
        container: DetailAccessorialsContainerDTM[],
        itemCharge: FreightQuotaContentSchedulesChargesDTM,
        dontGroup?: boolean,
      ) => {
        const subSteps: string[] = [];
        const perUnit = accessorialsFilterPerUnit(itemCharge?.priceBy || '', itemCharge?.measureBy || '');

        if (container
          .some((itemContainer) => {
            if (dontGroup) {
              return itemContainer.name === itemCharge?.chargeCode?.description
                && itemContainer.applianceRangeMinValue === itemCharge.applianceRange?.minValue;
            }

            return itemContainer.name === itemCharge?.chargeCode?.description;
          })) {
          return;
        }

        if (itemCharge.applianceRange?.minValue) {
          subSteps.push(`${i18n.t('ChargedFrom')} ${itemCharge.applianceRange?.minValue || ''} ${`${i18n.t(`measureBy.${itemCharge?.measureBy}`)}`}`);
        }

        container.push(DetailAccessorialsContainerDTM.fromPlain({
          name: itemCharge?.chargeCode?.description || '',
          steps: subSteps,
          summary: Number(
            Number(itemCharge.costPerUnit)
              .toFixed(2),
          ),
          cost: Number(
            Number(itemCharge.costPerUnit)
              .toFixed(2),
          ),
          totalUnits: 1,
          // not exists in dtm
          // numberOfUnits: itemCharge.numberOfUnits,
          perUnit,
          applianceRangeMinValue: itemCharge.applianceRange?.minValue || 0,
        }));
      };

      const setAccessorialsItem = (
        container: DetailAccessorialsContainerDTM[],
        itemCharge: FreightQuotaContentSchedulesChargesDTM,
      ) => {
        const subSteps: string[] = [];
        const containerTypeName = containersTypesById[itemCharge?.containerId || ''];
        const perUnit = accessorialsFilterPerUnit(itemCharge?.priceBy || '', itemCharge?.measureBy || '', isContainerAllTypes(containerTypeName) ? undefined : '');

        if (container
          .some((itemContainer) => {
            if (itemCharge?.priceBy
              && itemCharge.priceBy === chargeCodePriceBy.CONTAINER
              && (itemContainer.name === itemCharge?.chargeCode?.description
                || (itemCharge.chargeCode?.code === chargeCodeCode.MSC
                  && itemContainer.name.toLowerCase() === itemCharge?.chargeCode?.originalDescription?.toLowerCase()))
            ) {
              return itemContainer?.containerType === containersTypesById?.[itemCharge?.containerId || ''];
            }

            return (itemContainer.name === itemCharge?.chargeCode?.description
              || (itemCharge.chargeCode?.code === chargeCodeCode.MSC
                && itemContainer.name.toLowerCase() === itemCharge?.chargeCode?.originalDescription));
          })) {
          return;
        }

        if (itemCharge.applianceRange?.minValue) {
          subSteps.push(`${i18n.t('ChargedFrom')} ${itemCharge.applianceRange?.minValue || ''} ${`${i18n.t(`measureBy.${itemCharge?.measureBy}`)}`}`);
        }

        const nameCharge = itemCharge?.chargeCode?.code === chargeCodeCode.MSC && itemCharge?.chargeCode?.originalDescription
          ? itemCharge?.chargeCode?.originalDescription.toLowerCase()
          : itemCharge?.chargeCode?.description || '';
        container.push(DetailAccessorialsContainerDTM.fromPlain({
          name: nameCharge,
          steps: subSteps,
          summary: Number(
            Number(itemCharge.costPerUnit)
              .toFixed(2),
          ),
          cost: Number(
            Number(itemCharge.costPerUnit)
              .toFixed(2),
          ),
          totalUnits: 1,
          // not exists in dtm
          // numberOfUnits: itemCharge?.numberOfUnits,
          perUnit,
          containerTypeName,
          applianceRangeMinValue: itemCharge.applianceRange?.minValue || 0,
          containerType: containersTypesById?.[itemCharge?.containerId || ''],
        }));
      };

      filterCharges?.forEach((itemCharge) => {
        if (itemCharge?.designation !== name.toUpperCase()) {
          return;
        }

        if (itemCharge?.chargeCode?.subType === chargeCodeSubType.ACCESSORIAL) {
          setAccessorialsItem(accessorials, itemCharge);
        }

        if (itemCharge?.chargeCode?.subType === chargeCodeSubType.EXCEPTION) {
          setContainerItem(penalties, itemCharge);
        }

        if (itemCharge?.chargeCode?.subType === chargeCodeSubType.FREE_TIME) {
          setContainerItem(freeTime, itemCharge, true);
        }
      });

      accessorials.forEach((itemCharge) => {
        if (isContainerAllTypes(itemCharge?.containerTypeName)) {
          const findSomeTypeIndex = accessorialsGroup?.findIndex((item) => item.containerType === itemCharge?.containerTypeName);
          if (accessorialsGroup[findSomeTypeIndex]) {
            accessorialsGroup[findSomeTypeIndex].containers.push(itemCharge);
          } else {
            accessorialsGroup.push(DetailAccessorialsTransportationDTM.fromPlain({
              name: ContainerAllTypesNamesLongConst[itemCharge?.containerTypeName] || '',
              containers: [itemCharge],
              summary: 0,
              containerType: itemCharge.containerType,
            }));
          }
        }
      });

      return {
        objData: DetailAccessorialsDTM.fromPlain(objData),
        freeTime: freeTime.sort((a, b) => {
          if (a?.applianceRangeMinValue && b?.applianceRangeMinValue) {
            if (a.applianceRangeMinValue > b.applianceRangeMinValue) {
              return 1;
            }

            if (a.applianceRangeMinValue < b.applianceRangeMinValue) {
              return -1;
            }
          }

          return 0;
        }),
        accessorials,
        accessorialsGroup,
        penalties,
      };
    };

    const {
      objData: freightGetData,
      freeTime: freightFreeTime,
      accessorials: freightAccessorials,
      accessorialsGroup: freightAccessorialsGroup,
      penalties: freightPenalties,
    } = getResultAccessorials(freight, 'Freight');
    const {
      objData: originGetData,
      freeTime: originFreeTime,
      accessorials: originAccessorials,
      accessorialsGroup: originAccessorialsGroup,
      penalties: originPenalties,
    } = getResultAccessorials(origin, 'Origin');
    const {
      objData: destinationGetData,
      freeTime: destinationFreeTime,
      accessorials: destinationAccessorials,
      accessorialsGroup: destinationAccessorialsGroup,
      penalties: destinationPenalties,
    } = getResultAccessorials(destination, 'Destination');

    return AccessorialsFilterReturnDTM.fromPlain({
      accessorialsOrigin: DetailAccessorialsDTM.fromPlain({
        ...originGetData,
        freeTime: originFreeTime,
        accessorials: originAccessorials,
        accessorialsGroup: originAccessorialsGroup,
        penalties: originPenalties,
      }),
      accessorialsFreight: DetailAccessorialsDTM.fromPlain({
        ...freightGetData,
        freeTime: freightFreeTime,
        accessorials: freightAccessorials,
        accessorialsGroup: freightAccessorialsGroup,
        penalties: freightPenalties,
      }),
      accessorialsDestination: DetailAccessorialsDTM.fromPlain({
        ...freightGetData,
        ...destinationGetData,
        freeTime: destinationFreeTime,
        accessorials: destinationAccessorials,
        accessorialsGroup: destinationAccessorialsGroup,
        penalties: destinationPenalties,
      }),
    });
  },
);

const getTreeDataAdditionalServices = createSelector(
  getRateServiceActive,
  getRateServiceFilter,
  (servicesRateActive, servicesRate) => {
    if (servicesRateActive && servicesRateActive.length) {
      return prepareDataForTree(
        servicesRate || [],
      );
    }

    return [];
  },
);

const getTreeDataAdditionalServicesDefaultExpandedKeys = createSelector(
  getRateServiceActive,
  getRateServiceFilter,
  getFreightQuoteCurrentFormServiceValuesChecked,
  (servicesRateActive, servicesRate, defaultCheckedKeys) => {
    if (servicesRateActive && servicesRateActive.length) {
      const filteredChargeCodes = servicesRate?.reduce((acc: string[], cur: AdditionalServiceDTM) => {
        acc.push(cur.code);
        return acc;
      }, []) || [];

      return filteredChargeCodes.filter((item) => defaultCheckedKeys?.includes(item));
    }

    return [];
  },
);

const getTreeDataAdditionalServicesDefaultKeys = createSelector(
  getRateServiceActive,
  getRateServiceFilter,
  (servicesRateActive, servicesRate) => {
    if (servicesRateActive && servicesRateActive.length) {
      return servicesRate.map((itemService) => itemService?.key || '');
    }

    return [];
  },
);

const getFreightQuoteCurrentAllQuotasTotalCost = (index: number) => createSelector(
  getFreightQuoteCurrentAllQuotasAppliedByIndex(index),
  (allQuotas) => {
    const {
      tradeType: incotermTrade,
      incoterm: incotermAll,
      cost,
    } = allQuotas;

    if (!incotermAll) return '';

    const incoterm = incotermAll as string;

    switch (incotermTrade) {
      case EFreightIncotermsTrade.EXPORT:
        if (incoterm === EFreightIncotermsByExport.DAP || incoterm === EFreightIncotermsByExport.DPU) {
          return `${(cost?.originTotalCost || 0) + (cost?.freightTotalCost || 0) + (cost?.destinationTotalCost || 0)}`;
        }
        if (incoterm === EFreightIncotermsByExport.CIF || incoterm === EFreightIncotermsByExport.CFR) {
          return `${(cost?.originTotalCost || 0) + (cost?.freightTotalCost || 0)}`;
        }

        return '';

      case EFreightIncotermsTrade.IMPORT:
        if (incoterm === EFreightIncotermsByImport.EXW) {
          return `${(cost?.originTotalCost || 0) + (cost?.freightTotalCost || 0) + (cost?.destinationTotalCost || 0)}`;
        }
        if (incoterm === EFreightIncotermsByImport.FCA || incoterm === EFreightIncotermsByImport.FAS || incoterm === EFreightIncotermsByImport.FOB) {
          return `${(cost?.freightTotalCost || 0) + (cost?.destinationTotalCost || 0)}`;
        }

        return '';

      default:
        return '';
    }
  },
);

const getFreightQuoteCurrentAllQuotasIsPaymentTermsOrigin = (index: number) => createSelector(
  getFreightQuoteCurrentAllQuotasAppliedByIndex(index),
  (allQuotas) => {
    const {
      tradeType: incotermTrade,
      incoterm: incotermAll,
    } = allQuotas;

    if (!incotermAll) return false;

    const incoterm = incotermAll as string;

    return incotermTrade === EFreightIncotermsTrade.IMPORT
      && (incoterm === EFreightIncotermsByImport.FCA
        || incoterm === EFreightIncotermsByImport.FAS
        || incoterm === EFreightIncotermsByImport.FOB);
  },
);

const getFreightQuoteCurrentAllQuotasIsPaymentTermsDestination = (index: number) => createSelector(
  getFreightQuoteCurrentAllQuotasAppliedByIndex(index),
  (allQuotas) => {
    const {
      tradeType: incotermTrade,
      incoterm: incotermAll,
    } = allQuotas;

    if (!incotermAll) return false;

    const incoterm = incotermAll as string;

    return incotermTrade === EFreightIncotermsTrade.EXPORT
      && (incoterm === EFreightIncotermsByExport.CIF
        || incoterm === EFreightIncotermsByExport.CFR);
  },
);

const getSelfService = createSelector(
  getFreightQuote,
  (data) => data.isSelfServiceRequest,
);

const getOriginPromiseReject = createSelector(
  selectSelf,
  (data) => data.FreightQuota.originAddressPromiseReject,
);

const getDestinationPromiseReject = createSelector(
  selectSelf,
  (data) => data.FreightQuota.destinationAddressPromiseReject,
);

const getAddAdditionalServicesDrawer = createSelector(
  selectSelf,
  (data) => data.FreightQuota.addAdditionalServicesDrawer,
);

const getAddAdditionalServicesDrawerIsDisabledAdd = createSelector(
  selectSelf,
  (data) => {
    let isDisabledAdd = true;

    if (
      data.FreightQuota?.currentState?.form?.origin?.location?.code
      && data.FreightQuota?.currentState?.form?.destination?.location?.code
      && data.FreightQuota?.currentState?.form?.origin?.datePort?.earliestDate
      && data.FreightQuota?.currentState?.form?.origin?.datePort?.latestDate) {
      isDisabledAdd = false;
    }

    if (
      !data.FreightQuota?.currentState?.form?.origin?.isPort
      && data.FreightQuota?.currentState?.form?.origin?.location?.code
      && data.FreightQuota?.currentState?.form?.destination?.location?.code
      && data.FreightQuota?.currentState?.form?.origin?.datePort?.earliestDate) {
      isDisabledAdd = false;
    }

    return isDisabledAdd;
  },
);

const getAdditionalServicesRatesCharges = createSelector(
  getFreightQuote,
  (state) => state.additionalServicesRatesCharges,
);

export const FreightQuoteSelectors = {
  FreightQuotePrintSelectors,
  getCountContainersTotal,
  getCountContainersVolumeTotal,
  getCountContainersWeightTotal,
  getDestinationDoorAddress,
  getFreightQuote,
  getFreightQuoteCurrent,
  getFreightQuoteFilters,
  getFreightQuoteFiltersRequestStatus,
  getFreightQuoteQuotaLoadingSteps,
  getFreightQuoteCurrentAllQuotas,
  getFreightQuoteCurrentAllQuotasAppliedByIndex,
  getFreightQuoteCurrentAllQuotasByIndex,
  getFreightQuoteCurrentAllQuotasDetailsAccessorialsByIndex,
  getFreightQuoteCurrentAllQuotasDetailsBreakdownByIndex,
  getFreightQuoteCurrentAllQuotasDetailsContainersTypeByIdTypes,
  getFreightQuoteCurrentFileNamePrint,
  getFreightQuoteCurrentAllQuotasByIndexFileNamePrint,
  getPaymentTermsSelected,
  getFreightQuoteCurrentAllQuotasDetailsRoutingByIndex,
  getFreightQuoteCurrentAllQuotasIsPaymentTermsDestination,
  getFreightQuoteCurrentAllQuotasIsPaymentTermsOrigin,
  getFreightQuoteCurrentAllQuotasNotAppliedByIndex,
  getFreightQuoteCurrentAllQuotasTotalCost,
  getFreightQuoteCurrentDestination,
  getFreightQuoteCurrentFiltersPrice,
  getFreightQuoteCurrentFiltersParams,
  getIsHaveFreightQuoteCurrentFiltersParams,
  getFreightQuoteCurrentFormContainers,
  getFreightQuoteCurrentFormContainersValues,
  getFreightQuoteCurrentLocationPortStatus,
  getFreightQuoteCurrentOrigin,
  getFreightQuoteCurrentQuotas,
  getFreightQuoteCurrentQuotasIndexSchedule,
  getFreightQuoteErrors,
  getOriginDoorAddress,
  getQuantitySummaryContainers,
  getRateServiceActive,
  getRateServiceFilter,
  getSelfService,
  getTreeDataAdditionalServices,
  getTreeDataAdditionalServicesDefaultExpandedKeys,
  getTreeDataAdditionalServicesDefaultKeys,
  getOriginPromiseReject,
  getDestinationPromiseReject,
  getAddAdditionalServicesDrawer,
  getAddAdditionalServicesDrawerIsDisabledAdd,
  getAdditionalServicesRatesCharges,
  getSelectedDoorOriginAddress,
  getSelectedDoorDestinationAddress,
};
