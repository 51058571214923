import message from 'antd/es/message';
import i18n from 'i18next';
import moment from 'moment/moment';
import isNull from 'lodash/fp/isNull';

import { BaseController, controller } from 'proto/BaseController';
import { R } from 'shipment-operations/repository';
import { ContainerDocumentDTM, ShipmentChangesDtm, TransportPlanDTM } from 'shipment-operations/models/dtm';
import {
  CUTOFF,
  DOCUMENTATION,
  ORIGIN_CONTAINER_TERMINAL,
  ORIGIN_CONTAINER_YARD,
  PermissionAttributePolicy,
  PORT, ShipmentBookingStatusEnum,
  TERMINAL_DATE,
  TERMINAL_NAME,
  VGM,
} from 'shipment-operations/constants';
import { IErrorExceptionDTM } from 'app-wrapper/models/contracts';
import { ConflictError } from 'app-wrapper/models/errors/httpErrors/ConflictError';
import { GatewayTimeoutError } from 'app-wrapper/models/errors/httpErrors/GatewayTimeoutError';
import { NetworkErrorProto } from 'app-wrapper/models/errors';
import { UC } from 'shipment-operations/controllers/index';
import { DrawersUseCase } from 'app-wrapper/usecases/Drawers.useCase';

@controller
export class ShipmentChangesController extends BaseController {
  loadData = async (shipmentId: string) => {
    if (!shipmentId) {
      return;
    }
    let response: ShipmentChangesDtm[] | null;

    this.dispatch(R.actions.shipmentChanges.setIsLoadingData(true));
    try {
      response = await R.services.shipmentChanges.getShipmentChanges(shipmentId);
    } catch (e) {
      this.dispatch(R.actions.shipmentChanges.setIsErrorLoadingData(true));
      return;
    }

    let cargoContainers: {
      id?: number;
      mismatches: ShipmentChangesDtm[] | null;
    } = { id: 0, mismatches: [] };
    let locations: {
      id?: number;
      mismatches: ShipmentChangesDtm[] | null;
    } = { id: 0, mismatches: [] };
    let withMismatches: boolean = false;
    try {
      cargoContainers = await R.services.shipmentChanges.getCargoContainersMismatches(shipmentId);
      if (cargoContainers.mismatches && cargoContainers.mismatches.length > 0) {
        withMismatches = true;
      }
      this.dispatch(R.actions.shipmentChanges.setMismatchId(cargoContainers.id));
    } catch {
      this.dispatch(R.actions.shipmentChanges.setIsErrorLoadingData(true));
      return;
    }
    response = [...response, ...cargoContainers.mismatches || []];

    try {
      locations = await R.services.shipmentChanges.getLocations(shipmentId);
      if (locations.mismatches && locations.mismatches.length > 0) {
        withMismatches = true;
      }
      this.dispatch(R.actions.shipmentChanges.setLocationMismatchId(locations.id));
    } catch {
      this.dispatch(R.actions.shipmentChanges.setIsErrorLoadingData(true));
      return;
    }
    this.dispatch(R.actions.shipmentChanges.setWithMismatches(withMismatches));

    // if we have saved shipment id, we need to load short, because we are out of shipment page
    const savedShipmentId = R.selectors.overview.getShipmentId(this.store.getState());
    if (savedShipmentId) {
      const short = await R.services.shipment.getShipmentShortById(+shipmentId);
      this.dispatch(R.actions.shipmentChanges.setShipment(short));
    }

    const locationsMismatches = locations.mismatches?.find((item) => (item.relatesToType === ORIGIN_CONTAINER_YARD) || (item.relatesToType === ORIGIN_CONTAINER_TERMINAL));
    if (locationsMismatches) {
      this.dispatch(R.actions.shipmentChanges.setWithLocationSection(true));
    }

    let portCutoffMismatch = locations.mismatches?.find((item) => item.relatesToType === CUTOFF && item.field === PORT && item.mismatch)?.mismatchesCurrent;
    if (!portCutoffMismatch && !isNull(portCutoffMismatch)) {
      portCutoffMismatch = response?.find((item) => item.relatesToType === CUTOFF && item.field === PORT && !item.mismatch)?.current;
    }
    let vgmMismatch = locations.mismatches?.find((item) => item.relatesToType === CUTOFF && item.field === VGM && item.mismatch)?.mismatchesCurrent;
    if (!vgmMismatch && !isNull(vgmMismatch)) {
      vgmMismatch = response?.find((item) => item.relatesToType === CUTOFF && item.field === VGM && !item.mismatch)?.current;
    }
    let documentationMismatch = locations.mismatches?.find((item) => item.relatesToType === CUTOFF && item.field === DOCUMENTATION && item.mismatch)?.mismatchesCurrent;
    if (!documentationMismatch && !isNull(documentationMismatch)) {
      documentationMismatch = response?.find((item) => item.relatesToType === CUTOFF && item.field === DOCUMENTATION && !item.mismatch)?.current;
    }
    const changedCutoffs = [
      {
        type: PORT,
        value: withMismatches ? (portCutoffMismatch || '') : '',
      },
      {
        type: VGM,
        value: withMismatches ? (vgmMismatch || '') : '',
      },
      {
        type: DOCUMENTATION,
        value: withMismatches ? (documentationMismatch || '') : '',
      },
    ];
    this.dispatch(R.actions.shipmentChanges.setChangedCutOffs(changedCutoffs));

    let pickUpTerminalMismatch = locations.mismatches?.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === TERMINAL_NAME && item.mismatch)?.mismatchesCurrent;
    if (!pickUpTerminalMismatch && !isNull(pickUpTerminalMismatch)) {
      pickUpTerminalMismatch = response.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === TERMINAL_NAME && !item.mismatch)?.current;
    }
    let pickUpReleaseMismatch = locations.mismatches?.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === TERMINAL_DATE && item.mismatch)?.mismatchesCurrent;
    if (!pickUpReleaseMismatch && !isNull(pickUpReleaseMismatch)) {
      pickUpReleaseMismatch = response.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === TERMINAL_DATE && !item.mismatch)?.current;
    }
    let pickUpPassCodeMismatch = locations.mismatches?.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'PASSCODE' && item.mismatch)?.mismatchesCurrent;
    if (!pickUpPassCodeMismatch && !isNull(pickUpPassCodeMismatch)) {
      pickUpPassCodeMismatch = response.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'PASSCODE' && !item.mismatch)?.current;
    }
    let pickUpAddress1Mismatch = locations.mismatches?.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_1' && item.mismatch)?.mismatchesCurrent;
    if (!pickUpAddress1Mismatch && !isNull(pickUpAddress1Mismatch)) {
      pickUpAddress1Mismatch = response.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_1' && !item.mismatch)?.current;
    }
    let pickUpAddress2Mismatch = locations.mismatches?.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_2' && item.mismatch)?.mismatchesCurrent;
    if (!pickUpAddress2Mismatch && !isNull(pickUpAddress2Mismatch)) {
      pickUpAddress2Mismatch = response.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_2' && !item.mismatch)?.current;
    }
    let pickUpAddress3Mismatch = locations.mismatches?.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_3' && item.mismatch)?.mismatchesCurrent;
    if (!pickUpAddress3Mismatch && !isNull(pickUpAddress3Mismatch)) {
      pickUpAddress3Mismatch = response.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_3' && !item.mismatch)?.current;
    }
    let pickUpAddress4Mismatch = locations.mismatches?.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_4' && item.mismatch)?.mismatchesCurrent;
    if (!pickUpAddress4Mismatch && !isNull(pickUpAddress4Mismatch)) {
      pickUpAddress4Mismatch = response.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_4' && !item.mismatch)?.current;
    }
    let pickUpCityMismatch = locations.mismatches?.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'CITY' && item.mismatch)?.mismatchesCurrent;
    if (!pickUpCityMismatch && !isNull(pickUpCityMismatch)) {
      pickUpCityMismatch = response.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'CITY' && !item.mismatch)?.current;
    }
    let pickUpStateMismatch = locations.mismatches?.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'STATE' && item.mismatch)?.mismatchesCurrent;
    if (!pickUpStateMismatch && !isNull(pickUpStateMismatch)) {
      pickUpStateMismatch = response.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'STATE' && !item.mismatch)?.current;
    }
    let pickUpZipcodeMismatch = locations.mismatches?.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ZIPCODE' && item.mismatch)?.mismatchesCurrent;
    if (!pickUpZipcodeMismatch && !isNull(pickUpZipcodeMismatch)) {
      pickUpZipcodeMismatch = response.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ZIPCODE' && !item.mismatch)?.current;
    }
    let pickUpCountryMismatch = locations.mismatches?.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'COUNTRY' && item.mismatch)?.mismatchesCurrent;
    if (!pickUpCountryMismatch && !isNull(pickUpCountryMismatch)) {
      pickUpCountryMismatch = response.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'COUNTRY' && !item.mismatch)?.current;
    }

    let dropOffTerminalMismatch = locations.mismatches?.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === TERMINAL_NAME && item.mismatch)?.mismatchesCurrent;
    if (!dropOffTerminalMismatch && !isNull(dropOffTerminalMismatch)) {
      dropOffTerminalMismatch = response.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === TERMINAL_NAME && !item.mismatch)?.current;
    }
    let dropOffReleaseMismatch = locations.mismatches?.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === TERMINAL_DATE && item.mismatch)?.mismatchesCurrent;
    if (!dropOffReleaseMismatch && !isNull(dropOffReleaseMismatch)) {
      dropOffReleaseMismatch = response.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === TERMINAL_DATE && !item.mismatch)?.current;
    }
    let dropOffPassCodeMismatch = locations.mismatches?.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'PASSCODE' && item.mismatch)?.mismatchesCurrent;
    if (!dropOffPassCodeMismatch && !isNull(dropOffPassCodeMismatch)) {
      dropOffPassCodeMismatch = response.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'PASSCODE' && !item.mismatch)?.current;
    }
    let dropOffAddress1Mismatch = locations.mismatches?.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_1' && item.mismatch)?.mismatchesCurrent;
    if (!dropOffAddress1Mismatch && !isNull(dropOffAddress1Mismatch)) {
      dropOffAddress1Mismatch = response.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_1' && !item.mismatch)?.current;
    }
    let dropOffAddress2Mismatch = locations.mismatches?.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_2' && item.mismatch)?.mismatchesCurrent;
    if (!dropOffAddress2Mismatch && !isNull(dropOffAddress2Mismatch)) {
      dropOffAddress2Mismatch = response.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_2' && !item.mismatch)?.current;
    }
    let dropOffAddress3Mismatch = locations.mismatches?.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_3' && item.mismatch)?.mismatchesCurrent;
    if (!dropOffAddress3Mismatch && !isNull(dropOffAddress3Mismatch)) {
      dropOffAddress3Mismatch = response.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_3' && !item.mismatch)?.current;
    }
    let dropOffAddress4Mismatch = locations.mismatches?.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_4' && item.mismatch)?.mismatchesCurrent;
    if (!dropOffAddress4Mismatch && !isNull(dropOffAddress4Mismatch)) {
      dropOffAddress4Mismatch = response.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_4' && !item.mismatch)?.current;
    }
    let dropOffCityMismatch = locations.mismatches?.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'CITY' && item.mismatch)?.mismatchesCurrent;
    if (!dropOffCityMismatch && !isNull(dropOffCityMismatch)) {
      dropOffCityMismatch = response.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'CITY' && !item.mismatch)?.current;
    }
    let dropOffStateMismatch = locations.mismatches?.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'STATE' && item.mismatch)?.mismatchesCurrent;
    if (!dropOffStateMismatch && !isNull(dropOffStateMismatch)) {
      dropOffStateMismatch = response.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'STATE' && !item.mismatch)?.current;
    }
    let dropOffZipcodeMismatch = locations.mismatches?.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ZIPCODE' && item.mismatch)?.mismatchesCurrent;
    if (!dropOffZipcodeMismatch && !isNull(dropOffZipcodeMismatch)) {
      dropOffZipcodeMismatch = response.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ZIPCODE' && !item.mismatch)?.current;
    }
    let dropOffCountryMismatch = locations.mismatches?.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'COUNTRY' && item.mismatch)?.mismatchesCurrent;
    if (!dropOffCountryMismatch && !isNull(dropOffCountryMismatch)) {
      dropOffCountryMismatch = response.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'COUNTRY' && !item.mismatch)?.current;
    }

    const changedLocations = [
      {
        type: 'ORIGIN_CONTAINER_YARD',
        address1: withMismatches ? (pickUpAddress1Mismatch || '') : '',
        city: withMismatches ? (pickUpCityMismatch || '') : '',
        country: withMismatches ? (pickUpCountryMismatch || '') : '',
        terminalName: withMismatches ? (pickUpTerminalMismatch || '') : '',
        time: withMismatches ? (pickUpReleaseMismatch || '') : '',
        passCode: withMismatches ? (pickUpPassCodeMismatch || '') : '',
        address2: withMismatches ? (pickUpAddress2Mismatch || '') : '',
        address3: withMismatches ? (pickUpAddress3Mismatch || '') : '',
        address4: withMismatches ? (pickUpAddress4Mismatch || '') : '',
        state: withMismatches ? (pickUpStateMismatch || '') : '',
        postcode: withMismatches ? (pickUpZipcodeMismatch || '') : '',
      },
      {
        type: 'ORIGIN_CONTAINER_TERMINAL',
        address1: withMismatches ? (dropOffAddress1Mismatch || '') : '',
        city: withMismatches ? (dropOffCityMismatch || '') : '',
        country: withMismatches ? (dropOffCountryMismatch || '') : '',
        terminalName: withMismatches ? (dropOffTerminalMismatch || '') : '',
        time: withMismatches ? (dropOffReleaseMismatch || '') : '',
        passCode: withMismatches ? (dropOffPassCodeMismatch || '') : '',
        address2: withMismatches ? (dropOffAddress2Mismatch || '') : '',
        address3: withMismatches ? (dropOffAddress3Mismatch || '') : '',
        address4: withMismatches ? (dropOffAddress4Mismatch || '') : '',
        state: withMismatches ? (dropOffStateMismatch || '') : '',
        postcode: withMismatches ? (dropOffZipcodeMismatch || '') : '',
      },
    ];

    this.dispatch(R.actions.shipmentChanges.setChangedLocations(changedLocations));

    response = [...response, ...locations.mismatches || []];
    this.dispatch(R.actions.shipmentChanges.setData(response));
  }

  loadBlMismatches = async (shipmentId: string) => {
    if (!shipmentId) {
      return;
    }
    let response: ShipmentChangesDtm[] | null = [];

    this.dispatch(R.actions.shipmentChanges.setIsLoadingData(true));
    try {
      response = await R.services.shipmentChanges.getShipmentChangesBL(shipmentId);
    } finally {
      this.dispatch(R.actions.shipmentChanges.setIsErrorLoadingData(true));
    }

    this.dispatch(R.actions.shipmentChanges.setData(response));
    this.dispatch(R.actions.shipmentChanges.setWithMismatches(false));
  }

  syncIntraNumber = () => {
    const shipment = R.selectors.shipment.getShipment(this.store.getState());
    this.dispatch(R.actions.shipmentChanges.setInttraNumber(shipment?.oceanBookingId));
    this.dispatch(R.actions.shipmentChanges.setCarrierNumber(shipment?.carrierReferenceNumber));
    const savedShipmentId = R.selectors.overview.getShipmentId(this.store.getState());
    if (savedShipmentId) {
      const savedShipment = R.selectors.shipmentChanges.getShipmentShort(this.store.getState());
      this.dispatch(R.actions.shipmentChanges.setInttraNumber(savedShipment?.oceanBookingId));
      this.dispatch(R.actions.shipmentChanges.setCarrierNumber(savedShipment?.carrierReferenceNumber));
    }
  }

  onChangeInttraNumber = (value: string) => {
    this.dispatch(R.actions.shipmentChanges.setInttraNumber(value));
  }

  onChangeCarrierNumber = (value: string) => {
    this.dispatch(R.actions.shipmentChanges.setCarrierNumber(value));
  }

  setDocument = (document: ContainerDocumentDTM | null) => {
    this.dispatch(R.actions.shipmentChanges.setDocument(document));
  }

  downloadDocument = (shipmentId: string) => {
    const document = R.selectors.shipmentChanges.getDocument(this.store.getState());

    if (!document) {
      return;
    }

    try {
      R.services.shipmentDocument.getShipmentDocument(+shipmentId, document.response.id, document.response.name);
    } catch (e) {
      console.error('PREVIEW SHIPMENT DOCUMENT: CONTROLLER ERROR');
    }
  }

  updatedChangedData = (array: any, newItem: any) => {
    const newArray = [...array];
    const index = newArray.findIndex((item) => item.type === newItem.type);
    if (index === -1) {
      newArray.push(newItem);
    } else {
      newArray[index] = newItem;
    }
    return newArray;
  }

  onChangeCutOff = (row: any) => {
    const cutOffsErrors = R.selectors.shipmentChanges.getCutoffsErrors(this.store.getState());
    if (cutOffsErrors.length > 0) {
      const filteredErrors = cutOffsErrors.filter((item) => item !== row.fieldName);
      this.dispatch(R.actions.shipmentChanges.setCutOffsErrors(filteredErrors));
    }
    const changedCutoffs = R.selectors.shipmentChanges.getChangedCutOffs(this.store.getState());
    const value = moment(row.carrierConfirmed).format();
    const type = row.key;
    const newCutoffs = this.updatedChangedData(changedCutoffs, { type, value });
    this.dispatch(R.actions.shipmentChanges.setChangedCutOffs(newCutoffs));
    if (type === PORT) {
      this.dispatch(R.actions.shipmentTrackerRoutes.setShowAddChargeButton(false));
      this.dispatch(R.actions.shipmentTrackerRoutes.setValidationLoaded(false));
    }
  }

  updateFieldByType = (shipmentLocations: any, targetType: string, fieldName: string, newValue: string) => shipmentLocations.map((location: any) => {
    if (location.type === targetType) {
      return { ...location, [fieldName]: newValue };
    }
    return location;
  })

  onChangeLocation = (row: any) => {
    const locationsErrors = R.selectors.shipmentChanges.getLocationsError(this.store.getState());
    if (locationsErrors.length > 0) {
      const filteredErrors = locationsErrors.filter((item) => item.type !== row.type || item.value !== row.fieldName);
      this.dispatch(R.actions.shipmentChanges.setLocationsErrors(filteredErrors));
    }
    const changedLocations = R.selectors.shipmentChanges.getChangedLocations(this.store.getState());
    let value = row.carrierConfirmed;
    if (row.fieldName === 'time') {
      value = moment(row.carrierConfirmed).format();
    }
    const newLocations = this.updateFieldByType(changedLocations, row.type, row.fieldName, value);
    this.dispatch(R.actions.shipmentChanges.setChangedLocations(newLocations));
  }

  submitAmendments = async (shipmentId: string) => {
    this.dispatch(R.actions.shipmentChanges.setActionLoading(true));
    try {
      await R.services.shipmentChanges.confirmData(shipmentId);
      this.dispatch(R.actions.shipmentChanges.setActionLoading(false));
    } catch (e) {
      this.dispatch(R.actions.shipmentChanges.setActionLoading(false));
      throw e;
    }
    setTimeout(() => {
      this.dispatch(R.actions.shipmentChanges.setChangedConfirm(true));
      message.success(i18n.t('Your amendments have been successfully submitted'));
      this.dispatch(R.actions.shipmentChanges.setActionLoading(false));
      this.dispatch(R.actions.shipmentChanges.setShouldRefresh(true));
    }, 500);
  }

  onConfirmData = async (shipmentId: string) => {
    this.dispatch(R.actions.shipmentChanges.setActionLoading(true));
    const permissions = R.selectors.shipment.getShipmentChangesPermissions(this.store.getState());
    const isRequestTheSameWithShipment = R.selectors.shipment.getIsRequestTheSameWithShipment(this.store.getState());
    if ((permissions === PermissionAttributePolicy.READ) && !isRequestTheSameWithShipment) {
      try {
        await R.services.shipmentChanges.confirmData(shipmentId);
      } catch (e) {
        this.dispatch(R.actions.shipmentChanges.setActionLoading(false));
        this.callErrorNotification((e as IErrorExceptionDTM).message || '');
        return;
      }
      setTimeout(() => {
        this.dispatch(R.actions.shipmentChanges.setChangedConfirm(true));
        new DrawersUseCase(this).closeDrawer();
        message.success(i18n.t('Your amendments have been successfully submitted'));
        this.dispatch(R.actions.shipmentChanges.setActionLoading(false));
        this.dispatch(R.actions.shipmentChanges.setShouldRefresh(true));
      }, 5000);
      return;
    }

    const transportPlan = R.selectors.shipmentChanges.getTransportPlan(this.store.getState());
    const current = transportPlan?.current as TransportPlanDTM;
    const mismatchedTransportPlan = R.selectors.shipmentChanges.getMismatchedTransportPlan(this.store.getState());

    const changedCutOffs = R.selectors.shipmentChanges.getChangedCutOffs(this.store.getState());
    const cutOffsErrors = changedCutOffs.filter((item) => !item.value).map((item) => item.type);
    if (cutOffsErrors.length > 0) {
      this.dispatch(R.actions.shipmentChanges.setCutOffsErrors(cutOffsErrors));
      this.dispatch(R.actions.shipmentChanges.setActionLoading(false));
    }
    const departureTime = current.transportations[0].schedule.departureTime.substring(19);
    const cutoffsWithTimezone = changedCutOffs.map((item) => ({
      ...item,
      value: item.value.substring(0, 19) + departureTime,
    }));

    const inttraNumber = R.selectors.shipmentChanges.getInttraNumber(this.store.getState());
    const carrierNumber = R.selectors.shipmentChanges.getCarrierNumber(this.store.getState());

    const changedLocations = R.selectors.shipmentChanges.getChangedLocations(this.store.getState());
    const locationsErrors: { type: string, value: string }[] = [];
    changedLocations.forEach((elem) => {
      const requiredFields = ['address1', 'city', 'country', 'terminalName', 'time', 'state'];
      requiredFields.forEach((fieldName) => {
        if (!elem[fieldName as keyof typeof elem] && (fieldName !== 'state' || (fieldName === 'state' && elem.country === 'US'))) {
          locationsErrors.push({
            type: elem.type,
            value: fieldName,
          });
        }
      });
    });
    if (locationsErrors.length > 0) {
      this.dispatch(R.actions.shipmentChanges.setLocationsErrors(locationsErrors));
      this.dispatch(R.actions.shipmentChanges.setActionLoading(false));
    }

    if (cutOffsErrors.length > 0 || locationsErrors.length > 0) {
      this.dispatch(R.actions.shipmentChanges.setActionLoading(false));
      return;
    }

    const earliestCYEmptyRelease = changedLocations[0].time;
    const earliestCTOFullDropDate = changedLocations[1].time;
    const portCutoff = cutoffsWithTimezone.find((item) => item.type === PORT)?.value;
    if (portCutoff && (earliestCYEmptyRelease > portCutoff)) {
      this.dispatch(R.actions.shipmentChanges.setActionLoading(false));
      this.callErrorNotification(i18n.t('Earliest CY Empty Release date should be before origin cutoff port date'));
      return;
    }

    if (portCutoff && ((earliestCTOFullDropDate < earliestCYEmptyRelease) || (earliestCTOFullDropDate > portCutoff))) {
      this.dispatch(R.actions.shipmentChanges.setActionLoading(false));
      this.callErrorNotification(i18n.t('Earliest CTO Full Drop Date Should be after Earliest CY Empty Release Date and before origin cutoff port date'));
      return;
    }

    if (!inttraNumber || !carrierNumber) {
      this.dispatch(R.actions.shipmentChanges.setActionLoading(false));
      return;
    }

    const locationsWithTimezone = changedLocations.map((item) => ({
      ...item,
      time: item.time.substring(0, 19) + departureTime,
    }));

    const document = R.selectors.shipmentChanges.getDocument(this.store.getState());
    const body = {
      inttraReferenceNumber: inttraNumber,
      cutOffs: cutoffsWithTimezone,
      shipmentLocations: locationsWithTimezone,
      carrierReferenceNumber: carrierNumber,
      transportationPlan: mismatchedTransportPlan?.mismatchesCurrent || null,
      confirmationDocument: document?.response,
    };

    try {
      await R.services.shipmentChanges.sendManualConfirmation(shipmentId, body);
    } catch (e) {
      message.error(i18n.t('Something went wrong'));
      this.dispatch(R.actions.shipmentChanges.setActionLoading(false));
    }
    message.success(i18n.t('Your shipment have been successfully updated'));
    this.dispatch(R.actions.shipmentChanges.setActionLoading(false));
    new DrawersUseCase(this).closeDrawer();
    this.dispatch(R.actions.shipmentChanges.setShouldRefresh(true));
    this.dispatch(R.actions.shipmentChanges.setShouldUpdateShipment(true));
    this.dispatch(R.actions.shipmentChanges.setWithMismatches(false));
  }

  onAccept = async (shipmentId: string, isCustomer: boolean) => {
    const mismatchId = R.selectors.shipmentChanges.getMismatchId(this.store.getState());
    const locationMismatchId = R.selectors.shipmentChanges.getLocationMismatchId(this.store.getState());
    if (!mismatchId && !locationMismatchId) {
      return;
    }
    this.dispatch(R.actions.shipmentChanges.setAcceptLoading(true));
    if (locationMismatchId) {
      try {
        await R.services.shipmentChanges.acceptLocationMismatches(shipmentId, locationMismatchId);
      } catch (e) {
        this.dispatch(R.actions.shipmentChanges.setAcceptLoading(false));
        message.error(i18n.t('Something went wrong'));
        return;
      }
    }

    if (mismatchId) {
      try {
        await R.services.shipmentChanges.acceptMismatches(shipmentId, mismatchId);
      } catch (e) {
        this.dispatch(R.actions.shipmentChanges.setAcceptLoading(false));
        message.error(i18n.t('Something went wrong'));
        return;
      }
    }

    new DrawersUseCase(this).closeDrawer();
    this.dispatch(R.actions.shipmentChanges.setAcceptLoading(false));
    if (isCustomer) {
      message.success(i18n.t('Shipment amendments has been acknowledged.'));
    }
    this.dispatch(R.actions.shipmentChanges.setShouldRefresh(true));
  }

  onSearchRef = async (shipmentId: string) => {
    this.dispatch(R.actions.shipmentChanges.setIsSearchLoading(true));
    const refNumber = R.selectors.shipmentChanges.getInttraNumber(this.store.getState());
    try {
      const res = await R.services.shipmentChanges.searchReference(shipmentId, refNumber);
      if (res.bookingStatus !== ShipmentBookingStatusEnum.BOOKING_CANCEL) {
        this.dispatch(R.actions.shipmentChanges.setCarrierNumber(res.carrierReferenceNumber));
        this.dispatch(R.actions.shipmentChanges.setSearchError(false));
        this.dispatch(R.actions.shipmentChanges.setIsSearchSuccess(true));
      } else {
        UC.overview.loadData(shipmentId);
        this.dispatch(R.actions.shipmentChanges.setSearchError(true));
        this.dispatch(R.actions.shipmentChanges.setIsSearchSuccess(false));
        this.dispatch(R.actions.shipmentChanges.setSearchErrorMessage(res.cancelReasonDto?.message));
        this.dispatch(R.actions.shipmentChanges.setIsSearchLoading(false));
      }
    } catch (e) {
      this.dispatch(R.actions.shipmentChanges.setIsSearchSuccess(true));
      if (e instanceof ConflictError) {
        this.callErrorNotification(i18n.t('No bookings found matching Inttra Reference you provided.'));
      }
      if (e instanceof NetworkErrorProto) {
        if (e.status === 400) {
          this.callErrorNotification(i18n.t('No bookings found matching Inttra Reference you provided.'));
        }
      }
      if (e instanceof GatewayTimeoutError) {
        this.callErrorNotification(i18n.t('We have difficulties in finding your booking. Please try to search again.'));
      }
      this.dispatch(R.actions.shipmentChanges.setIsSearchLoading(false));
    }
  }

  public onReset = () => {
    this.dispatch(R.actions.shipmentChanges.reset());
    this.dispatch(R.actions.shipmentTrackerRoutes.clearEditPlan());
  }
}
