import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { SideBarComponent } from './SideBar.component';

const SideBarContainer = observer(() => {
  const { confirmationBookingDetailsStore } = useMobxStore();

  return (
    <SideBarComponent
      validBookingDetails={confirmationBookingDetailsStore.getOriginalBookingNumber}
    />
  );
});

export { SideBarContainer as SideBar };
