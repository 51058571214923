import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import FilterFilled from '@ant-design/icons/FilterFilled';

import { QUOTAS_STATUS } from 'app-wrapper/constants';
import { EmptyStateForPages, InfiniteScroll } from 'app-wrapper/view/components';

import {
  IFreightQuotaContentDTM, IFreightQuotasStateDTM,
} from 'monetary/models/dtm';
import { RFQLoaderContainer } from 'monetary/view/components';

import { TermsAndConditionsDrawerContainer } from 'app-wrapper/view/pages/Overview/components/StepsManager/components';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { SkeletonRFQ } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import {
  CardsContainer,
  RFQContentWrapperInfiniteScroll,
} from 'monetary/view/pages/QuotaRequests/components/Quotas/components/Cards/Cards.styled';
import { RFQNotFoundRecommended } from 'monetary/view/components/RFQResponse/RFQNotFoundRecommended';
import { CardContainer } from 'monetary/view/pages/QuotaRequests/components/Quotas/components/Card';

export interface IRFQContentComponentProps {
  NoneContentRecommended?: React.ReactNode
  isFooter?: boolean
  requestFiltersStatus?: string
  isAllRequestPage?: boolean
  quotaRequestServiceByIdData?: IFreightQuotaContentDTM[]
  quotaRequestServiceById?: IFreightQuotasStateDTM
  fullCompleteStatus?: string
  hideBook?: boolean
  hasNextPage?: boolean
  onRunRFQContentComponent: () => void
  onScrollNextInfinityPage: () => void
  isHaveFiltersParams: boolean
}

export const CardsComponent = memo((props: IRFQContentComponentProps) => {
  const {
    NoneContentRecommended,
    quotaRequestServiceByIdData,
    quotaRequestServiceById,
    fullCompleteStatus,
    isFooter,
    requestFiltersStatus,
    isAllRequestPage,
    hideBook,
    hasNextPage,
    onRunRFQContentComponent,
    onScrollNextInfinityPage,
    isHaveFiltersParams,
  } = props;
  const { t } = useTranslation();
  const [isRunScroll, setIsRunScroll] = useState(false);

  useEffect(() => {
    setIsRunScroll(false);
    onRunRFQContentComponent();
  }, []);

  const nextScrollOnHandler = useCallback(
    () => {
      if (!isRunScroll) setIsRunScroll(true);
      onScrollNextInfinityPage();
    },
    [isRunScroll, onScrollNextInfinityPage],
  );

  const scrollOnOpenDetails = useCallback(
    (top) => {
      document.getElementsByClassName('infinite-scroll-component')[0].scroll({
        top,
        behavior: 'smooth',
      });
    },
    [],
  );

  const isNoneContent = (
    (!quotaRequestServiceByIdData?.length
      && (
        fullCompleteStatus === QUOTAS_STATUS.complete
        || fullCompleteStatus === QUOTAS_STATUS.failed
      )
      && (
        NoneContentRecommended || <RFQNotFoundRecommended />
      )));

  const isEmptyFilters = isHaveFiltersParams
    && fullCompleteStatus !== QUOTAS_STATUS.pending
    && !quotaRequestServiceByIdData?.length;

  const isLoading = (requestFiltersStatus === QUOTAS_STATUS.pending)
    || quotaRequestServiceById?.quotasOption?.nextRequestStatus === QUOTAS_STATUS.pending;

  return (
    <CardsContainer>
      <TermsAndConditionsDrawerContainer />
      {!isEmptyFilters ? (
        <RFQContentWrapperInfiniteScroll>{(isNoneContent
              || (!!NoneContentRecommended
                && fullCompleteStatus === QUOTAS_STATUS.pending
                && (
                  <RFQLoaderContainer
                    nameLogo="Searching Quotes"
                  />
                )))
            || (
              <InfiniteScroll
                dataLength={Number(quotaRequestServiceByIdData?.length || 0)}
                next={nextScrollOnHandler}
                hasMore={!!hasNextPage}
                loader={
                  isLoading
                  && <SkeletonRFQ isLoading rowCount={1} />
                }
                scrollableTarget="scrollableDiv"
                height={isFooter ? '76vh' : '83vh'}
                style={{ gap: '18px', display: 'flex', flexDirection: 'column' }}
              >
                <SkeletonRFQ isLoading={isLoading && quotaRequestServiceByIdData?.length === 0}>
                  {quotaRequestServiceByIdData?.map((item, index) => (
                    <CardContainer
                      isAllRequestPage={isAllRequestPage}
                      idIndex={index}
                      key={`quotaRequestServiceById_${item.id}_${index + 0}`}
                      onScroll={scrollOnOpenDetails}
                      hideBook={hideBook}
                    />
                  ))}
                </SkeletonRFQ>
              </InfiniteScroll>
            )}
        </RFQContentWrapperInfiniteScroll>
      )
        : (
          <EmptyStateForPages
            icon={(
              <FilterFilled
                style={{
                  color: themesColors.neutralBranding65,
                  width: '25px',
                  height: '23px',
                }}
              />
            )}
            headerText={t('No quotes found')}
            contentText={t('Filters maybe to restrictive.')}
            buttonText={t('Clear filters')}
          />
        )}
    </CardsContainer>
  );
});
