import styled, { css } from 'styled-components';

import { divFlex, responsive } from 'app-wrapper/view/themes/mixin';
import { Select } from 'app-wrapper/view/components';

const inputCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  min-height: 32px;
`;

export const RFRTabs = styled.div.attrs({
  'data-class': 'RFRTabs',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;
`;

export const RFRTabsSelect = styled(Select).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
  isLeft: boolean,
  isRight: boolean,
  cssMarginLeft: string,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  isLeft: props.isLeft,
  isRight: props.isRight,
  cssMarginLeft: props.cssMarginLeft,
  'data-class': 'RFRTabsSelect',
}))`
  ${divFlex}
  ${inputCss}


  .ant-select-item-option-content {
    font-size: 12px;
    color: ${({ theme }) => theme.themesColors.neutralBranding10};
  }

  border-width: 0px;
  .ant-select-item-option-content {
    font-family: "Roboto";
  }

  .ant-select-selector {
    min-width: ${({ theme }) => theme?.inputConst?.defaultHeight};
    ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
    border-radius: 3px !important;
    ${({ isLeft }) => isLeft && `
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    `};
    ${({ isRight }) => isRight && `
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-left: none !important;
    `};
  }
  ${responsive.forFullMedia`
    .ant-select-selector {
      min-width: ${({ theme }: { theme: { inputConst: { hdHeight: string } } }) => theme?.inputConst?.hdHeight};
    }
  `}
  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

export const RFRTabsFreightMode = styled.div.attrs({
  'data-class': 'RFRTabsFreightMode',
})`
  ${divFlex}
  width: 100%;
`;

export const RFRIncotermsWrap = styled.div.attrs({
  'data-class': 'RFRIncotermsWrap',
})`
  ${divFlex}
  width: 50%;

  margin-left: 4px;
`;
