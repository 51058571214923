import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC as appUC } from 'app-wrapper/controllers';
import { UC } from 'shipment-operations/controllers';

import { EditMBLNumberModalComponent } from './EditMBLNumberModal.component';

const EditMBLNumberModalContainer = React.memo(() => {
  const { t } = useTranslation();
  const isPending = useSelector(R.selectors.overview.getIsModalPending);
  const mblNumber = useSelector(R.selectors.overview.getMBLNumberToEdit);

  return (
    <EditMBLNumberModalComponent
      isPending={isPending}
      onClose={appUC.drawer.closeDrawer}
      onOk={UC.overview.saveMBLNumber}
      mblNumber={mblNumber}
      setMBLNumber={UC.overview.setMBLNumberToEdit}
      title={t('Edit MBL Number')}
      label={t('MBL Number')}
    />
  );
});

export { EditMBLNumberModalContainer as EditMBLNumberModal };
