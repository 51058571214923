import {
  IsDefined, IsString, IsOptional, IsNumber, ValidateNested, IsBoolean, IsEnum, IsEmail,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

import { EShippingPartyTypes } from 'shipment-operations/constants';

import { IAddressDTM, AddressDTM } from './Address.dtm';
import {
  ICompanyDTM,
  CompanyDTM,
  CompanyContactDTM,
} from './Company.dtm';
import { IContactDTM, ContactDTM } from './Contact.dtm';

export interface IShippingPartyReference {
  id?: string
  value: string
}

export class ShippingPartyReference extends BaseDTM<IShippingPartyReference> {
  @IsOptional()
  @IsString()
  id?: string;

  @IsDefined()
  @IsString()
  value: string;
}

export interface IShippingPartyContactDTM {
  id: number | null
  fullName: string
  email: string
  phone: string
  phone2?: string
  primary?: boolean
}

export class ShippingPartyContactDTM extends BaseDTM<IShippingPartyContactDTM> {
  @IsNumber()
  @IsOptional()
  id: number | null;

  @IsDefined()
  @IsString()
  fullName: string;

  @IsDefined()
  @IsEmail()
  email: string;

  @IsDefined()
  @IsString()
  phone: string;

  @IsOptional()
  @IsString()
  phone2?: string;

  @IsOptional()
  @IsBoolean()
  primary?: boolean;

  static areContactsEqual = (contact: ShippingPartyContactDTM, companyContact: CompanyContactDTM): boolean => contact.fullName === companyContact.fullName
    && (contact.email || '') === (companyContact.email || '')
    && (contact.phone || '') === (companyContact.phone || '')
    && (contact.phone2 || '') === (companyContact.phone2 || '');
}

export interface IShippingPartyDTM {
  id?: number

  role: EShippingPartyTypes

  company?: ICompanyDTM;
  address?: IAddressDTM;
  contact?: IShippingPartyContactDTM;

  addressList: IAddressDTM[]
  contactList: IContactDTM[]

  references: IShippingPartyReference[]

  description?: string

  isHasCompanyBook?: boolean
  isSameAsMyOrganisation?: boolean
  hasSystemUpdatePolicy?: boolean
  isFreightForwarderOrgType?: boolean
  isAgreeNra?: boolean
  isAgreeNraError?: boolean
  isAgreeNraShow?: boolean
}

export class ShippingPartyDTM extends BaseDTM<IShippingPartyDTM> {
  @IsOptional()
  @IsNumber()
  id?: number;

  @IsDefined()
  @IsEnum(EShippingPartyTypes)
  role: EShippingPartyTypes;

  @IsOptional()
  @ValidateNested()
  @Type(() => CompanyDTM)
  company?: CompanyDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => AddressDTM)
  address?: AddressDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ShippingPartyContactDTM)
  contact?: ShippingPartyContactDTM;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => AddressDTM)
  addressList: AddressDTM[]

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => ContactDTM)
  contactList: ContactDTM[]

  @IsDefined()
  @ValidateNested()
  @Type(() => ShippingPartyReference)
  references: ShippingPartyReference[]

  @IsOptional()
  @IsString()
  description?: string;

  @IsOptional()
  @IsBoolean()
  isHasCompanyBook?: boolean;

  @IsOptional()
  @IsBoolean()
  isSameAsMyOrganisation?: boolean;

  @IsOptional()
  @IsBoolean()
  hasSystemUpdatePolicy?: boolean;

  @IsOptional()
  @IsBoolean()
  isFreightForwarderOrgType?: boolean;

  @IsOptional()
  @IsBoolean()
  isAgreeNra?: boolean;

  @IsOptional()
  @IsBoolean()
  isAgreeNraError?: boolean;

  @IsOptional()
  @IsBoolean()
  isAgreeNraShow?: boolean;
}
