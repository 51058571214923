import React, {
  FC,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import Typography from 'antd/es/typography';

import { DateDtm } from 'app-wrapper/models/dtm';
import {
  Row,
  Col,
  RadioGroup,
} from 'app-wrapper/view/components';
import { RadioButton } from 'app-wrapper/view/components/ant/Radio';
import { FreightFromLocationStateDatePortDTM, FreightFromLocationStateDTM } from 'monetary/models/dtm';
import { IDefaultFieldErrors } from 'monetary/models/errors';
import { AddressBlockDestinationContainer } from 'monetary/view/components/FreightQuoteFormRequest/AddressBlock/AddressBlockDestination.container';
import {
  RowAddressBoth,
  AddressBlockRadioGroup,
  AddressBlockDatePicker,
} from 'monetary/view/components/FreightQuoteFormRequest/AddressBlock/AddressBlock.styled';
import {
  Overlay,
  OverlayWrap,
  OverlayBottom,
} from 'monetary/view/components/QuotasSearchBar/components/OriginOverlay/OriginOverlay.styled';

interface IOriginOverlayComponentProps {
  onChangeDestinationTypeToPort: (isPort: boolean) => void
  destination?: FreightFromLocationStateDTM
  origin?: FreightFromLocationStateDTM
  onChangePortDateDestination: (props: FreightFromLocationStateDatePortDTM) => void
  originErrors: {
    isPort?: IDefaultFieldErrors
    location?: IDefaultFieldErrors
    datePort?: {
      error?: IDefaultFieldErrors
    }
    dateDoor?: {
      error?: IDefaultFieldErrors
    }
  }
  destinationErrors: {
    isPort?: IDefaultFieldErrors
    location?: IDefaultFieldErrors
    datePort?: {
      error?: IDefaultFieldErrors
    }
    dateDoor?: {
      error?: IDefaultFieldErrors
    }
  }
  onFocusPortDateDestination: () => void
  onBlurPortDateDestination: () => void
  isSubmitVisited?: boolean
}

export const DestinationOverlayComponent: FC<IOriginOverlayComponentProps> = ({
  onChangeDestinationTypeToPort,
  destination,
  origin,
  onBlurPortDateDestination,
  originErrors,
  destinationErrors,
  onFocusPortDateDestination,
  onChangePortDateDestination,
  isSubmitVisited,
}) => {
  const { t } = useTranslation();

  const disabledDateDeparture = useCallback((current: moment.Moment): boolean => {
    const minDate = origin?.datePort?.earliestDate ? moment(origin?.datePort?.earliestDate) : moment().add(4, 'days');

    if (destination?.isPort && destination?.datePort?.earliestDate) {
      const minDateSecond = origin?.datePort?.earliestDate
        ? moment(origin?.datePort?.earliestDate)
        : moment(destination?.datePort?.earliestDate).subtract(1, 'day');
      const maxDate = moment(destination?.datePort?.earliestDate).add(34, 'days');

      return current && (current < minDateSecond.endOf('day') || (current > maxDate.endOf('day')));
    }

    if (!destination?.isPort) {
      const maxDays = origin?.datePort?.earliestDate ? moment(origin?.datePort?.earliestDate).add(0, 'd') : DateDtm.addWorkingsDaysDisabled(moment(), 3);
      return current && (current < maxDays.endOf('day'));
    }

    return current && current < minDate.endOf('day');
  }, [origin?.datePort?.earliestDate, destination?.datePort?.earliestDate]);

  const departureOnCalendarChange = useCallback((dates, dateStrings) => {
    if (destination?.isPort) {
      onChangePortDateDestination(FreightFromLocationStateDatePortDTM.fromPlain({
        earliestDate: dateStrings || '',
        latestDate: dateStrings || '',
      }));
    } else {
      onChangePortDateDestination(FreightFromLocationStateDatePortDTM.fromPlain({
        earliestDate: dateStrings || '',
      }));
    }
  }, [onChangePortDateDestination, destination?.isPort]);

  const isErrorDateDestination = useMemo(
    () => !!destinationErrors.datePort?.error?.message
      && ((originErrors.datePort?.error?.isVisited
          && destinationErrors.datePort?.error?.isVisited)
        || isSubmitVisited),
    [originErrors.datePort?.error, destinationErrors.datePort?.error, isSubmitVisited],
  );

  const departureTypeToPortOnChangeHandler = useCallback((e) => {
    onChangeDestinationTypeToPort(e.target.value === '1');
  }, []);

  return (
    <OverlayWrap>
      <Overlay>
        <RowAddressBoth className="FreightQuoteFormRequest__Row__col_both_col">
          <Col span={24} className="FreightQuoteFormRequest__Row__col_left">
            <Row justify="space-between" className="FreightQuoteFormRequest__Row__col_both_col__title">
              <div>
                <Typography.Title level={5}>{t('Destination')}</Typography.Title>
              </div>

              <AddressBlockRadioGroup>
                <RadioGroup
                  size="small"
                  onChange={departureTypeToPortOnChangeHandler}
                  value={`${destination?.isPort ? '1' : '0'}`}
                >
                  <RadioButton value="0">{t('Door')}</RadioButton>
                  <RadioButton value="1">{t('Port')}</RadioButton>
                </RadioGroup>

              </AddressBlockRadioGroup>
            </Row>

            <Row className="FreightQuoteFormRequest__Row__col FreightQuoteFormRequest__Row__col_first">
              <Col span={24}>
                <AddressBlockDestinationContainer />
              </Col>
            </Row>

            <Row className="contentFullWidth FreightQuoteFormRequest__Row__col FreightQuoteFormRequest__Row__col_second">
              <Col span={24}>
                <AddressBlockDatePicker
                  className="AddressBlockDatePickerDestination"
                  isError={isErrorDateDestination}
                  value={destination?.datePort?.earliestDate ? moment(destination?.datePort?.earliestDate) : null}
                  placeholder={t('Delivery date')}
                  disabledDate={disabledDateDeparture}
                  onChange={departureOnCalendarChange}
                  onFocus={onFocusPortDateDestination}
                  onBlur={onBlurPortDateDestination}
                />
              </Col>
            </Row>
          </Col>
        </RowAddressBoth>
      </Overlay>

      <OverlayBottom />
    </OverlayWrap>
  );
};
