import message from 'antd/es/message';
import { UploadFile } from 'antd/lib/upload/interface';
import { components } from 'api-contracts/dev/task-service/v1/schema';
import {
  EChangeRequestType,
  EChangeRequestTypeText,
  ENotificationCriticality,
  ENotificationDomainByChangeRequestType,
  ENotificationStatus,
} from 'app-wrapper/constants';
import {
  TaskAssigneeTypeEnum,
  TaskObjectReferenceType,
  TaskTargetAudienceEnum,
  TaskTypeEnum,
} from 'app-wrapper/enums';
import i18n from 'app-wrapper/i18n/i18n';
import {
  DateDtm,
  NotificationTaskContentAttachmentDTM,
  NotificationTaskContentDTM,
} from 'app-wrapper/models/dtm';
import { R as appWrapperR } from 'app-wrapper/repository';
import { CommandCenterUseCase } from 'app-wrapper/usecases/CommandCenter';
import { DrawersUseCase } from 'app-wrapper/usecases/Drawers.useCase';
import { InputMobxUseCase } from 'app-wrapper/usecases/InputMobx.useCase';
import { instanceToPlain } from 'app-wrapper/utils';
import { requiredValidation } from 'app-wrapper/validations';
import { maxLength2048Validation } from 'app-wrapper/validations/maxLength.validation';
import moment from 'moment';
import { BaseController, controller } from 'proto/BaseController';
import { InputStore } from 'proto/BaseMobxStore/InputStore';
import { R as shipmentR, R } from 'shipment-operations/repository';
import { ChangeRequestStore } from 'shipment-operations/repository/mobxStores';
import { PermissionsService } from 'shipment-operations/view/drawers/ChangeRequestDrawer/permissions';
import { EAccountDepartmentType, EShipmentOrganizationRole } from 'user-management/constants';

@controller
export class ChangeRequestDrawerController extends BaseController<ChangeRequestStore> {
  async onLoadedPage() {
    super.onLoadedPage();
    this.currentStore?.setLoading(true);

    const organization = await this.repositories.organizationRepository.get();
    if (!organization) {
      return;
    }

    if (this.currentStore?.state.changeRequestId) {
      const task = await appWrapperR.services.CommandCenter.getTask(this.currentStore?.state.changeRequestId);
      this.currentStore?.setChangeRequest(task);
      this.currentStore?.setType(task.payload?.changeRequest?.type as EChangeRequestType);
    }

    const shipmentId = String(this.currentStore?.state.changeRequest?.objectReference?.shipmentId || this.shipmentId());

    const roles = await R.services.moduleFunctionalityPermissions.getCurrentShipmentRoles(shipmentId) || [] as EShipmentOrganizationRole[];

    this.currentStore?.setPermissions(new PermissionsService(roles).getFunctionalityPermissions());

    this.currentStore?.setLoadedFiles(this.currentStore?.state.changeRequest?.attachments || []);

    this.currentStore?.setEditMode(!this.currentStore?.state.changeRequest);

    this.currentStore?.setForm({
      text: new InputStore(''),
      files: [],
    });

    this.currentStore?.setLoading(false);
  }

  text() {
    return new InputMobxUseCase(
      this,
      this.currentStore?.state.form.text as InputStore<string>,
      this.textValidation,
      { maxLength: 2048 },
    );
  }

  textValidation(useCase: InputMobxUseCase<InputStore<string>>) {
    if (!requiredValidation.validate(useCase.storeMobx.state.value)) {
      useCase.setError(requiredValidation.error());
      return;
    }

    if (maxLength2048Validation.validate(useCase.storeMobx.state.value)) {
      useCase.setError(maxLength2048Validation.error());
      return;
    }

    useCase.setError(null);
  }

  addFile(file: UploadFile) {
    this.currentStore?.addFile(file);
  }

  async downloadFile(file: NotificationTaskContentAttachmentDTM) {
    await appWrapperR.services.CommandCenter.downloadAttachment(Number(this.currentStore?.state.changeRequest?.id), file);
  }

  async deleteLoadedFile(deletedItem: NotificationTaskContentAttachmentDTM) {
    await appWrapperR.services.CommandCenter.deleteAttachment(Number(this.currentStore?.state.changeRequest?.id), Number(deletedItem.id));
    this.currentStore?.deleteLoadedFile(deletedItem);
    message.success(i18n.t('Successfully deleted'));
  }

  removeFile(deletedItem: UploadFile) {
    this.currentStore?.deleteItem(deletedItem);
  }

  closeDrawer() {
    new DrawersUseCase(this).closeDrawer();
  }

  validate() {
    this.text().validate();
  }

  async submitForm() {
    this.validate();

    if (this.currentStore?.hasError) {
      return;
    }

    this.currentStore?.setSubmitting(true);

    let newTask: NotificationTaskContentDTM;

    const organization = await this.repositories.organizationRepository.get();

    const shipmentId = Number(this.shipmentId() || this.currentStore?.state.changeRequest?.objectReference?.shipmentId);

    const shortShipment = await shipmentR.services.shipment.getShipmentShortById(shipmentId);

    if (!shortShipment || !shortShipment.bookingAgentOrgId || !this.currentStore?.state.type) {
      return;
    }

    const members = await R.services.shipmentWorkgroup.getShipmentWorkgroup(String(shipmentId));

    const member = members.find(
      (_member) => _member.role === this.getMemberRole(),
    );

    try {
      const createdAt = DateDtm.fromPlain(moment());
      newTask = this.currentStore?.state.changeRequest
        ? await appWrapperR.services.CommandCenter.putTask({
          ...instanceToPlain(this.currentStore?.state.changeRequest),
          payload: {
            changeRequest: {
              type: this.currentStore?.state.type,
              description: this.currentStore?.state.form.text?.value as string,
            },
          },
          dueDate: this.currentStore?.state.changeRequest?.dueDate,
          createdAt: this.currentStore?.state.changeRequest?.createdAt,
        })
        : await appWrapperR.services.CommandCenter.postTask({
          type: TaskTypeEnum.CHANGE_REQUEST,
          title: EChangeRequestTypeText[this.currentStore?.state.type],
          criticality: ENotificationCriticality.MEDIUM,
          status: ENotificationStatus.TODO,
          domain: ENotificationDomainByChangeRequestType[this.currentStore?.state.type],
          createdAt: createdAt?.getBackendFormatWithOffset(),
          dueDate: createdAt?.addDays(1)?.getBackendFormatWithOffset(),
          payload: {
            changeRequest: {
              // @ts-ignore
              type: this.currentStore?.state.type,
              // @ts-ignore
              description: this.currentStore?.state.form.text?.value,
            },
          },

          assignee: {
            type: member ? TaskAssigneeTypeEnum.USER : TaskAssigneeTypeEnum.ORGANIZATION,
            organization: {
              id: member?.organization.id || shortShipment.originPartnerOrgId,
            },
            user: member ? {
              email: member?.email,
            } : undefined,
          } as components['schemas']['UserAssignee'],
          organizationId: organization?.id as number,
          objectReference: {
            type: TaskObjectReferenceType.SHIPMENT,
            shipmentId: Number(shipmentId),
          } as components['schemas']['ShipmentReference'],
          targetAudience: {
            type: TaskTargetAudienceEnum.ORGANIZATIONS_GROUP,
            organizationIds: [
              organization?.id,
              shortShipment.originPartnerOrgId,
              shortShipment.destinationPartnerOrgId,
            ],
          } as components['schemas']['OrganizationsGroupTargetAudience'],

          metadata: {
            shipmentId: String(shipmentId),
            shipmentName: shortShipment.shipmentName,
            taskType: TaskTypeEnum.CHANGE_REQUEST,
            changeRequestType: this.currentStore?.state.type,
          },
        });

      this.currentStore?.setChangeRequest(newTask);

      const results = await Promise.all(
        this.currentStore?.state.form.files.map((file) => {
          const formData = new FormData();
          // @ts-ignore
          formData.append('file', file.value);
          return appWrapperR.services.CommandCenter.postAttachment(Number(newTask.id), formData);
        }) as Promise<NotificationTaskContentAttachmentDTM>[],
      );

      this.currentStore?.addLoadedFiles(results);
      this.currentStore?.removeFiles();
    } finally {
      this.currentStore?.setSubmitting(false);
    }

    this.currentStore?.setEditMode(false);

    message.success(i18n.t('Saved successfully'));

    if (this.isNewChangeRequest()) {
      this.closeDrawer();
    }
  }

  async deleteChangeRequest() {
    this.currentStore?.setSubmitting(true);

    await appWrapperR.services.CommandCenter.putTask({
      ...(this.currentStore?.state.changeRequest ? instanceToPlain(this.currentStore.state.changeRequest) : {}),
      status: ENotificationStatus.REJECTED,
      dueDate: this.currentStore?.state.changeRequest?.dueDate,
      createdAt: this.currentStore?.state.changeRequest?.createdAt,
    });

    this.currentStore?.setSubmitting(false);

    message.success(i18n.t('Successfully rejected'));

    this.closeDrawer();

    new CommandCenterUseCase(this).onRunTasksUpdateFilters(this.shipmentId());
  }

  async completeChangeRequest() {
    this.currentStore?.setSubmitting(true);

    await appWrapperR.services.CommandCenter.putTask({
      ...(this.currentStore?.state.changeRequest ? instanceToPlain(this.currentStore.state.changeRequest) : {}),
      status: ENotificationStatus.DONE,
      dueDate: this.currentStore?.state.changeRequest?.dueDate,
      createdAt: this.currentStore?.state.changeRequest?.createdAt,
    });

    this.currentStore?.setSubmitting(false);

    message.success(i18n.t('Successfully completed'));

    this.closeDrawer();

    new CommandCenterUseCase(this).onRunTasksUpdateFilters(this.shipmentId());
  }

  editForm() {
    this.currentStore?.setEditMode(true);
    this.currentStore?.setForm({
      text: new InputStore(this.currentStore?.state.changeRequest?.payload?.changeRequest?.description),
      files: [],
    });
  }

  cancelEditMode() {
    this.currentStore?.setEditMode(false);
    this.currentStore?.setForm({
      text: new InputStore(this.currentStore?.state.changeRequest?.payload?.changeRequest?.description),
      files: [],
    });
  }

  private shipmentId() {
    return (this.params as { shipmentId: string }).shipmentId;
  }

  private isNewChangeRequest() {
    return !this.currentStore?.state.changeRequestId;
  }

  private getMemberRole() {
    if (!this.currentStore?.state.type) {
      return EAccountDepartmentType.DRAYAGE_EXPORT;
    }
    if ([EChangeRequestType.UPDATE_HOUSE_CONSIGNEE_INFORMATION,
      EChangeRequestType.UPDATE_HOUSE_NOTIFY_INFORMATION,
      EChangeRequestType.UPDATE_HOUSE_SHIPPER_INFORMATION,
    ].includes(this.currentStore.state.type)) {
      return EAccountDepartmentType.DOCUMENTATION_OPS_EXPORT;
    }

    if (this.currentStore.state.type === EChangeRequestType.UPDATE_SCHEDULE_INFORMATION) {
      return EAccountDepartmentType.BOOKING_OPS;
    }

    return [EChangeRequestType.UPDATE_RECEIVER_INFORMATION, EChangeRequestType.UPDATE_DRAYAGE_INFORMATION_IMPORT].includes(this.currentStore.state.type)
      ? EAccountDepartmentType.DRAYAGE_IMPORT
      : EAccountDepartmentType.DRAYAGE_EXPORT;
  }
}
