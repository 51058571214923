import { Input } from 'app-wrapper/view/components';
import React, { ChangeEvent, FC } from 'react';

import { ShippingPartyReference } from 'shipment-operations/models/dtm';

import {
  ShippingPartiesReference,
  ShippingPartiesReferenceButton,
  ShippingPartiesReferencesContainer,
} from './ShippingPartiesReferences.styled';

interface IShippingPartiesReferencesComponentProps {
  references: ShippingPartyReference[]
  setReference: (ref: ShippingPartyReference) => void
  addReference: () => void
  removeReference: (ref: ShippingPartyReference) => void
  disabled: boolean
}

export const ShippingPartiesReferencesComponent: FC<IShippingPartiesReferencesComponentProps> = ({
  references,
  setReference,
  addReference,
  removeReference,
  disabled,
}) => {
  const createReferenceChangeHandler = (ref: ShippingPartyReference) => (event: ChangeEvent<HTMLInputElement>) => {
    const newRef = ShippingPartyReference.fromPlain({
      id: ref.id,
      value: event.currentTarget.value,
    });

    setReference(newRef);
  };

  const createActionClickHandler = (i: number, ref: ShippingPartyReference) => () => {
    if (i === 0) {
      addReference();
      return;
    }

    removeReference(ref);
  };

  return (
    <ShippingPartiesReferencesContainer>
      {references.map((ref, i) => (
        <ShippingPartiesReference key={ref.id}>
          <Input
            value={ref.value}
            onChange={createReferenceChangeHandler(ref)}
            disabled={disabled}
          />
          <ShippingPartiesReferenceButton
            type="dashed"
            disabled={disabled}
            onClick={createActionClickHandler(i, ref)}
          >
            {i === 0 ? '+' : '-'}
          </ShippingPartiesReferenceButton>
        </ShippingPartiesReference>
      ))}
    </ShippingPartiesReferencesContainer>
  );
};
