import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';
import { ETransportationType } from 'monetary/constants';
import { ChargeCodeLoadTypeEnum, DESTINATION, ORIGIN } from 'shipment-operations/constants';
import { useIsExportOrImport } from 'app-wrapper/hooks';
import { ChargeDTM } from 'shipment-operations/models/dtm';
import { ShipmentTransportationAccessorials } from './ShipmentTransportationAccessorials.component';

const ShipmentTransportationAccessorialsContainer = () => {
  const isLoading = useSelector(R.selectors.shipmentAccessorial.getIsLoading);
  const isError = useSelector(R.selectors.shipmentAccessorial.getError);
  const accessorialData = useSelector(R.selectors.shipmentAccessorial.getAccessorial);
  const penaltiesData = useSelector(R.selectors.shipmentAccessorial.getPenalties);

  const successCreation = useSelector(R.selectors.shipmentActiveCharge.getActiveChargeSuccessCreation);
  const isLoadingDelete = useSelector(R.selectors.shipmentActiveCharge.getIsLoadingDelete);
  const successDelete = useSelector(R.selectors.shipmentActiveCharge.getIsDeleteSuccess);
  const permissions = useSelector(R.selectors.shipment.getShipmentChargesPermissions);

  const { isExport, isImport, isOceanBooking } = useIsExportOrImport();

  let filteredAccessorial: ChargeDTM[] = [];
  let filteredPenalties: ChargeDTM[] = [];

  if (isExport) {
    filteredAccessorial = accessorialData.filter((item) => item.designation === ORIGIN && item.chargeCode.loadType === ChargeCodeLoadTypeEnum.DRAYAGE);
    filteredPenalties = penaltiesData.filter((item) => item.designation === ORIGIN && item.chargeCode.loadType === ChargeCodeLoadTypeEnum.DRAYAGE);
  }
  if (isImport) {
    filteredAccessorial = accessorialData.filter((item) => item.designation === DESTINATION && item.chargeCode.loadType === ChargeCodeLoadTypeEnum.DRAYAGE);
    filteredPenalties = penaltiesData.filter((item) => item.designation === DESTINATION && item.chargeCode.loadType === ChargeCodeLoadTypeEnum.DRAYAGE);
  }
  if (isOceanBooking) {
    filteredAccessorial = accessorialData.filter((item) => item.chargeCode.mode !== ETransportationType.ROAD && item.chargeCode.loadType !== ChargeCodeLoadTypeEnum.DRAYAGE);
    filteredPenalties = penaltiesData.filter((item) => item.chargeCode.mode !== ETransportationType.ROAD && item.chargeCode.loadType !== ChargeCodeLoadTypeEnum.DRAYAGE);
  }

  return (
    <ShipmentTransportationAccessorials
      fetchData={UC.shipmentAccessorial.loadData}
      isLoading={isLoading}
      accessorialData={filteredAccessorial}
      penaltiesData={filteredPenalties}
      successCreation={successCreation}
      closeDrawer={UC.shipmentActiveCharge.closeDrawer}
      openEditCharge={UC.shipmentActiveCharge.openEditCharge}
      setDeletedCharge={UC.shipmentActiveCharge.setDeletedCharge}
      onDelete={UC.shipmentActiveCharge.deleteCharge}
      isLoadingDelete={isLoadingDelete}
      permissions={permissions}
      isError={isError}
      successDelete={successDelete}
    />
  );
};

export { ShipmentTransportationAccessorialsContainer as ShipmentTransportationAccessorials };
