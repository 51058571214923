import { getLocationToOneString } from 'app-wrapper/utils';
import {
  DetailBreakdownContainerDTM, DetailBreakdownServicesDTM,
  DetailRoutingContainerStepDTM,
  DetailRoutingDTM, DetailRoutingStepsDTM, FreightQuotaContentSchedulesChargesDTM,
  IDetailBreakdownStepsContainersTypesByIdDTM,
  IDetailBreakdownStepsContainersTypesDTM, IFreightQuotaContentSchedulesChargesDTM,
} from 'monetary/models/dtm';
import { createSelector } from 'reselect';

import i18n from 'app-wrapper/i18n/i18n';
import { RootState } from 'app-wrapper/store';
import {
  AddressDTM,
  BookingWizardContainersErrorsDTM,
  CargoDTM,
  CompanyAddressDTM,
  CompanyContactDTM,
  ContainerDTM,
  ShippingPartyContactDTM,
} from 'shipment-operations/models/dtm';
import { ValidationErrorType } from 'app-wrapper/types';
import {
  chargeCodeCode,
  chargeCodeDesignation,
  chargeCodeMeasureBy,
  chargeCodeOccurrence,
  chargeCodePriceBy,
  chargeCodeSubjectTo,
  chargeCodeType,
  EFreightIncotermsTrade,
} from 'monetary/constants';
import { EOrganizationMemberRole, EOrganizationType, EShipmentOrganizationRole } from 'user-management/constants';
import {
  GEN,
  ShipmentStatusEnum,
  CONTAINER_MAX_COST_VALUE,
  isContainerAllTypes,
  ContainerAllTypesNamesLongConst,
} from 'shipment-operations/constants';
import { getPaymentTerms } from 'shipment-operations/repository/store/RollShipmentWizard/RollShipmentWizard.selectors';

export enum EPickupDeliveryTabs {
  CARGO_SUPPLIER = 'CARGO_SUPPLIER',
  CARGO_RECEIVER = 'CARGO_RECEIVER',
}

const localState = (state: RootState) => state.bookingWizard;

const localStateShipment = (state: RootState) => state.shipment;

const getIsSecondStep = createSelector(
  localState,
  (state) => state.step === 2,
);

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getIsContentUpdating = createSelector(
  localState,
  (state) => state.isContentUpdating,
);

const getCurrentStep = createSelector(
  localState,
  (state) => state.step,
);

const getShipmentId = createSelector(
  localState,
  (state) => state.shipmentId,
);

const getShipmentNRAFileNamePrint = createSelector(
  localState,
  localStateShipment,
  (state, stateShipment) => {
    let fileName = i18n.t('Skypace. NRA for ');
    const name = state.shipmentId || stateShipment?.shipment?.shipmentName;

    if (name) {
      fileName += name;
    }

    return fileName;
  },
);

const getShipmentTermsOfServiceFileNamePrint = createSelector(
  localState,
  localStateShipment,
  (state, stateShipment) => {
    let fileName = i18n.t('T/C for ');
    const name = stateShipment?.shipment?.shipmentName;

    if (name) {
      fileName += name;
    }

    return fileName;
  },
);

const getNewBookPartiesId = createSelector(
  localState,
  (state) => state.newBookPartiesId,
);

const getNewBookPartiesConsigneeId = createSelector(
  localState,
  (state) => state.newBookPartiesConsigneeId,
);

const getTotalCost = createSelector(
  localState,
  (state) => state.totalCost,
);

const getShipment = createSelector(
  localState,
  (state) => state.shipmentData,
);

const getIsShipmentWaitingForApproval = createSelector(
  getShipment,
  (shipment) => shipment?.status === ShipmentStatusEnum.AWAITING_APPROVAL,
);

const getShipmentOrigin = createSelector(
  localState,
  (state) => (state.shipmentData ? state.shipmentData.origin : null),
);

const getShipmentDestination = createSelector(
  localState,
  (state) => (state.shipmentData ? state.shipmentData.destination : null),
);

const getIsUsShipmentOriginOrDestination = createSelector(
  localState,
  (state) => Boolean(state.shipmentData?.origin.countryCode === 'US' || state.shipmentData?.destination.countryCode === 'US'),
);

const getVoyageDuration = createSelector(
  localState,
  (state) => state.durationDays,
);

const getCargos = createSelector(
  localState,
  (state) => state.cargos,
);

const getShouldHaveAnyHazmats = createSelector(
  localState,
  (state) => state.shouldHaveHazmats,
);

const getCargoById = (cargoId: number) => createSelector(
  localState,
  ({ cargos }) => {
    const cargo = cargos.find(({ id }) => id === cargoId) as CargoDTM;

    return cargo;
  },
);

const getHasTemperatureControl = createSelector(
  localState,
  (state) => state.hasTemperatureControl,
);

const getIsFirstCargo = (cargoId: number) => createSelector(
  localState,
  (state) => {
    const { cargos } = state;
    const cargoIndex = cargos.findIndex(({ id }) => cargoId === id);

    return cargoIndex === 0;
  },
);

const getIsHazmatToggledByCargoId = (cargoId: number) => createSelector(
  localState,
  (state) => {
    const { toggledHazmatCargoIds } = state;

    return !!toggledHazmatCargoIds.find((id) => id === cargoId);
  },
);

const getCargosTotalWeightAndVolume = createSelector(
  localState,
  (state) => {
    const { cargos } = state;
    const weight = cargos.reduce((start, next) => start + (next.weight && !Number.isNaN(next.weight) ? +next.weight : 0), 0);
    const volume = cargos.reduce((start, next) => start + (next.volume && !Number.isNaN(next.volume) ? +next.volume : 0), 0);

    return {
      weight,
      volume,
    };
  },
);

const hasErrorsAtAnyCargo = createSelector(
  localState,
  (state) => {
    const { cargos } = state;
    let hasErrors = false;

    cargos.forEach(({ errors }) => {
      if (errors.hasErrors()) {
        hasErrors = true;
      }
    });

    return hasErrors;
  },
);

const hasErrorsAtAnyContainers = createSelector(
  localState,
  (state) => {
    const { containersErrors } = state;
    let hasErrors = false;

    containersErrors.forEach((errors) => {
      if (errors.hasErrors()) {
        hasErrors = true;
      }
    });

    return hasErrors;
  },
);

const getHSCodeBEValidationErrorMessage = createSelector(
  localState,
  (state) => {
    const { cargos } = state;

    const cargoWithHSCodeError = cargos.find(({ errors }) => {
      const { code } = errors;

      return code && code.type === ValidationErrorType.ALERT;
    });

    if (!cargoWithHSCodeError || !cargoWithHSCodeError.errors || !cargoWithHSCodeError.errors.code || !cargoWithHSCodeError.errors.code.message) {
      return '';
    }

    return cargoWithHSCodeError.errors.code.message;
  },
);

const getIsCargoForcedToBeHazmat = createSelector(
  localState,
  (state) => {
    const shouldHaveAnyHazmats = state.shouldHaveHazmats;
    const hasSingleCargo = state.cargos.length === 1;

    return shouldHaveAnyHazmats && hasSingleCargo;
  },
);

const getIsHazmatErrorVisible = createSelector(
  localState,
  (state) => state.isHazmatErrorVisible,
);

const getIsHaveAccountLimit = createSelector(
  localState,
  (state) => state.isHaveAccountLimit,
);

const getIsShowAccountLimit = createSelector(
  localState,
  (state) => state.isShowAccountLimit,
);

const getCreatedCargosIds = createSelector(
  localState,
  (state) => state.createdCargosIds,
);

const getWasInformationSavedOnce = createSelector(
  localState,
  (state) => state.wasInformationSavedOnce,
);

const getDefaultCargo = createSelector(
  localState,
  (state) => state.defaultCargo,
);

const getContainersAmount = createSelector(
  localState,
  (state) => state.containersAmount,
);

const getCargosToDeleteIds = createSelector(
  localState,
  (state) => state.cargosToDeleteIds,
);

const getEmptyReleaseDate = createSelector(
  localState,
  (state) => {
    if (!state.emptyReleaseDate) {
      return undefined;
    }

    return state.emptyReleaseDate.getDateAsMomentWithOffset();
  },
);

const getContainers = createSelector(
  localState,
  (state) => state.containers,
);

const getContainerById = (id: string) => createSelector(
  localState,
  (state) => state.containers.find((_container) => id === _container.id) as ContainerDTM,
);

const getContainerErrorsById = (id: string) => createSelector(
  localState,
  (state) => {
    const containerErrors = state.containersErrors.find(({ containerId }) => containerId === id);

    return containerErrors as BookingWizardContainersErrorsDTM;
  },
);

const getContainersErrors = createSelector(
  localState,
  (state) => state.containersErrors,
);

const getHasSOC = createSelector(
  localState,
  (state) => state.hasSOC,
);

const getNameSCAC = createSelector(
  getShipment,
  (shipment) => shipment?.carrier || '',
);

const getEmptyReleaseDateError = createSelector(
  localState,
  (state) => state.emptyReleaseDateError,
);

const getCurrentOrganization = createSelector(
  localState,
  (state) => state.currentOrganization,
);

const getShouldShowShipperSection = createSelector(
  localState,
  getCurrentOrganization,
  (state, currentOrganization) => {
    const { incotermsTrade } = state;

    if (!currentOrganization || currentOrganization.role !== EOrganizationMemberRole.CUSTOMER) {
      return false;
    }

    const { type } = currentOrganization;

    return type === EOrganizationType.FF || incotermsTrade === EFreightIncotermsTrade.IMPORT;
  },
);

const getShouldShowConsigneeSection = createSelector(
  localState,
  getCurrentOrganization,
  (state, currentOrganization) => {
    const { incotermsTrade } = state;

    if (!currentOrganization || currentOrganization.role !== EOrganizationMemberRole.CUSTOMER) {
      return false;
    }

    const { type } = currentOrganization;

    return Boolean(type === EOrganizationType.FF && incotermsTrade === EFreightIncotermsTrade.IMPORT);
  },
);

const getShouldShowConsigneeNRASection = createSelector(
  localState,
  (state) => {
    const { incotermsTrade } = state;

    return Boolean(incotermsTrade === EFreightIncotermsTrade.IMPORT);
  },
);

const getCompaniesList = createSelector(
  localState,
  (state) => state.companiesList,
);

const getSelectedCompany = createSelector(
  localState,
  (state) => state.selectedCompany,
);

const getSelectedConsigneeCompany = createSelector(
  localState,
  (state) => state.selectedConsigneeCompany,
);

const getConsigneeCompanyError = createSelector(
  localState,
  (state) => state.consigneeCompanyError,
);

const getShipmentReference = createSelector(
  localState,
  (state) => state.shipmentReference,
);

const getShipmentReferenceData = createSelector(
  localState,
  (state) => state.shipmentReferenceData,
);

const getCompanyError = createSelector(
  localState,
  (state) => state.companyError,
);

const getShipmentReferenceError = createSelector(
  localState,
  (state) => state.shipmentReferenceError,
);

const getShipmentPartyReference = createSelector(
  localState,
  (state) => state.shipmentPartyReference,
);

const getShipmentPartyReferenceError = createSelector(
  localState,
  (state) => state.shipmentPartyReferenceError,
);

const getShipmentConsigneeReference = createSelector(
  localState,
  (state) => state.shipmentConsigneeReference,
);

const getShipmentConsigneeReferenceError = createSelector(
  localState,
  (state) => state.shipmentConsigneeReferenceError,
);

const getIsCurrentOrganizationCustomer = createSelector(
  getCurrentOrganization,
  (currentOrg) => !!currentOrg && currentOrg.role === EOrganizationMemberRole.CUSTOMER,
);

const getIsNRAChecked = createSelector(
  localState,
  (state) => state.isNRAChecked,
);

const getIsTermsAndConditionsChecked = createSelector(
  localState,
  (state) => state.isTermsAndConditionsChecked,
);

const getIsAgreeAndBookDisabled = createSelector(
  getIsCurrentOrganizationCustomer,
  getIsNRAChecked,
  getIsTermsAndConditionsChecked,
  getIsUsShipmentOriginOrDestination,
  (isCustomerOrg,
    isNRAChecked,
    isTermsAndConditionsChecked,
    isUsShipmentOriginOrDestination) => !isCustomerOrg || (isUsShipmentOriginOrDestination && !isNRAChecked) || !isTermsAndConditionsChecked,
);

const getContactBookDefault = (state: RootState) => (
  state.bookingWizard.wizardContactBook.defaultState
);

const getContactBookUpdate = (state: RootState) => (
  state.bookingWizard.wizardContactBook.updateState
);

const getContactBookUpdateCompanyListByIds = (id: string) => (state: RootState) => (
  state.bookingWizard.wizardContactBook.updateState.companyList.filter((item) => id === item.customId)?.[0]
);

const getContactBookUpdateErrorsCompanyListStateById = (id: string) => (state: RootState) => (
  state.bookingWizard.wizardContactBook.updateState.errors.companyListState.filter((item) => id === item.customId)?.[0]
);

const getContactBookUpdateCompanyListAddressesByIds = (ids: string[]) => (state: RootState) => (
  state.bookingWizard.wizardContactBook.updateState.companyListAddresses.filter((item) => ids.includes(item?.customId || '')).map((item) => CompanyAddressDTM.fromPlain(item))
);

const getContactBookUpdateCompanyListContactPersonsByIds = (ids: string[]) => (state: RootState) => (
  state.bookingWizard.wizardContactBook.updateState.companyListContactPersons.filter((item) => ids.includes(item?.customId || '')).map((item) => CompanyContactDTM.fromPlain(item))
);

const getContactBookUpdateErrorsAddressesById = (id: string) => (state: RootState) => (
  state.bookingWizard.wizardContactBook.updateState.errors.addresses.filter((item) => id === item.customId)?.[0]
);

const getContactBookUpdateErrorsContactPersonsById = (id: string) => (state: RootState) => (
  state.bookingWizard.wizardContactBook.updateState.errors.contactPersons.filter((item) => id === item.customId)?.[0]
);

const getActiveQuoteCharges = createSelector(
  localState,
  (state) => state.activeQuoteCharges,
);

const getIsWithGensetCharges = createSelector(
  getActiveQuoteCharges,
  (charges) => !!charges?.some((item) => item?.chargeCode?.code === GEN),
);

const getSumOfGenset = createSelector(
  getActiveQuoteCharges,
  (charges) => charges?.reduce((acc, item) => {
    if (item?.chargeCode?.code === GEN) {
      return acc + (item.totalCost || 0);
    }

    return acc;
  }, 0),
);

const getCargoSupplier = createSelector(
  localState,
  (state) => state.cargoSupplier,
);

const getCargoReceiver = createSelector(
  localState,
  (state) => state.cargoReceiver,
);

const getCargoSupplierCompany = createSelector(
  getCargoSupplier,
  (party) => party?.company,
);

const getCargoSupplierAddress = createSelector(
  getCargoSupplier,
  (party) => party?.address,
);

const getCargoSupplierContact = createSelector(
  getCargoSupplier,
  (party) => party?.contact,
);

const getCargoReceiverCompany = createSelector(
  getCargoReceiver,
  (party) => party?.company,
);

const getCargoReceiverAddress = createSelector(
  getCargoReceiver,
  (party) => party?.address,
);

const getCargoReceiverContact = createSelector(
  getCargoReceiver,
  (party) => party?.contact,
);

const getIsCargoSupplierRequiredErrorVisible = createSelector(
  localState,
  (state) => state.isCargoSupplierRequiredErrorVisible,
);

const getIsCargoReceiverRequiredErrorVisible = createSelector(
  localState,
  (state) => state.isCargoReceiverRequiredErrorVisible,
);

const getShipmentRoles = createSelector(
  localState,
  (state) => state.shipmentRoles,
);

const getIsCustomer = createSelector(
  getShipmentRoles,
  (roles) => roles.includes(EShipmentOrganizationRole.CUSTOMER),
);

const getIsOriginPartner = createSelector(
  getShipmentRoles,
  (roles) => roles.includes(EShipmentOrganizationRole.ORIGIN_PARTNER),
);

const getIsDestinationPartner = createSelector(
  getShipmentRoles,
  (roles) => roles.includes(EShipmentOrganizationRole.DESTINATION_PARTNER),
);

const getIsCreateCompanyAddressBlocked = createSelector(
  localState,
  (state) => state.isCreateCompanyAddressBlocked,
);

const getSupplierStepActiveTab = createSelector(
  localState,
  (state) => state.suppliersStepActiveTab,
);

const getIsLoadingMethodsInformationMissing = createSelector(
  getIsOriginPartner,
  getIsShipmentWaitingForApproval,
  (isOriginPartner, isShipmentWaitingForApproval) => isOriginPartner && isShipmentWaitingForApproval,
);

const getContainersTotalValue = createSelector(
  getContainers,
  (containers) => containers.length * CONTAINER_MAX_COST_VALUE,
);

const getIsContainersValueWarningVisible = createSelector(
  getIsOriginPartner,
  getIsShipmentWaitingForApproval,
  getContainersTotalValue,
  getCargos,
  (isOriginPartner, isShipmentWaitingForApproval, totalAllowedValue, cargos) => {
    const actualCost = cargos.reduce((prev, current) => (Number(current.value) || 0) + prev, 0);

    return isOriginPartner && isShipmentWaitingForApproval && actualCost >= totalAllowedValue;
  },
);

const getSupplierLoadingControls = createSelector(
  localState,
  (state) => state.supplierLoadingControls,
);

const getReceiverLoadingControls = createSelector(
  localState,
  (state) => state.receiverLoadingControls,
);

const getSupplierPhoneErrorMessage = createSelector(
  localState,
  (state) => state.supplierPhoneErrorMessage,
);

const getSupplierAdditionalErrorMessage = createSelector(
  localState,
  (state) => state.supplierAdditionalPhoneErrorMessage,
);

const getReceiverPhoneErrorMessage = createSelector(
  localState,
  (state) => state.receiverPhoneErrorMessage,
);

const getReceiverAdditionalErrorMessage = createSelector(
  localState,
  (state) => state.receiverAdditionalPhoneErrorMessage,
);

const getIsThereAnyPhoneError = createSelector(
  getSupplierPhoneErrorMessage,
  getSupplierAdditionalErrorMessage,
  getReceiverPhoneErrorMessage,
  getReceiverAdditionalErrorMessage,
  (supplierPhoneErrorMessage, supplierAdditionalPhoneErrorMessage, receiverPhoneErrorMessage, receiverAdditionalPhoneErrorMessage) => !!supplierPhoneErrorMessage?.length || !!supplierAdditionalPhoneErrorMessage?.length || !!receiverPhoneErrorMessage?.length || !!receiverAdditionalPhoneErrorMessage?.length,
);

const getChosenCompany = createSelector(
  localState,
  (state) => {
    const {
      suppliersStepActiveTab,
      cargoSupplier,
      cargoReceiver,
      companiesList,
    } = state;
    const shippingParty = suppliersStepActiveTab === EPickupDeliveryTabs.CARGO_SUPPLIER ? cargoSupplier : cargoReceiver;

    if (!shippingParty?.company?.id) {
      return null;
    }

    const targetCompany = companiesList.find((_company) => _company.id === shippingParty?.company?.id);

    return targetCompany;
  },
);

const getContactsForChosenCompany = createSelector(
  getChosenCompany,
  (company) => company?.contacts || [],
);

const getChosenContactId = createSelector(
  localState,
  (state) => state.chosenContactId,
);

const getSaveAddressToContactBookErrorMessage = createSelector(
  localState,
  getChosenCompany,
  getCargoSupplierAddress,
  getCargoReceiverAddress,
  (state, company, supplierAddress, receiverAddress): string | undefined => {
    const { suppliersStepActiveTab } = state;
    const address = suppliersStepActiveTab === EPickupDeliveryTabs.CARGO_SUPPLIER ? supplierAddress : receiverAddress;

    if (!company || !address || !company.addresses.length) {
      return i18n.t('Seems like company has no relation to your organization');
    }

    const { addresses } = company;

    const similarAddresses = addresses.filter((companyAddress) => AddressDTM.areAddressesEqual(address, companyAddress));

    return !similarAddresses.length ? undefined : i18n.t('Already in your Contact Book');
  },
);

const getSaveContactToContactBookErrorMessage = createSelector(
  localState,
  getChosenCompany,
  getCargoSupplierContact,
  getCargoReceiverContact,
  (state, company, supplierContact, receiverContact): string | undefined => {
    const { suppliersStepActiveTab } = state;
    const contact = suppliersStepActiveTab === EPickupDeliveryTabs.CARGO_SUPPLIER ? supplierContact : receiverContact;

    if (!company || !contact || !company.contacts.length) {
      return i18n.t('Seems like company has no relation to your organization');
    }

    const { contacts } = company;

    const similarContacts = contacts.filter((companyContact) => ShippingPartyContactDTM.areContactsEqual(contact, companyContact));

    return !similarContacts.length ? undefined : i18n.t('Already in your Contact Book');
  },
);

const getIsSavingAddress = createSelector(
  localState,
  (state) => state.isSavingAddress,
);

const getCurrentSchedule = createSelector(
  localState,
  (state) => state.currentSchedule,
);

const getCurrentScheduleSchema = createSelector(
  localState,
  (state) => {
    const {
      currentQuota: quota,
      currentSchedule,
    } = state;
    const scheduleId = currentSchedule?.id || 0;

    const containersTypes: IDetailBreakdownStepsContainersTypesDTM = {};
    let containersTypesById: IDetailBreakdownStepsContainersTypesByIdDTM = {};

    quota?.containers?.forEach((itemContainer) => {
      if (containersTypes[itemContainer?.type || 0]) {
        containersTypes[itemContainer?.type || ''].push(itemContainer?.id || 0);
      } else {
        containersTypes[itemContainer?.type || ''] = [itemContainer?.id || 0];
      }
      containersTypesById = {
        ...containersTypesById,
        [itemContainer?.id || 0]: itemContainer.type,
      };
    });
    const indexSchedule = quota?.schedules?.findIndex(({ id }) => id === scheduleId) || 0;
    const allQuotas = quota;
    const freight: DetailRoutingDTM[] = [];
    const origin: DetailRoutingDTM[] = [];
    const destination: DetailRoutingDTM[] = [];

    const getTerminal = (type: string, propsGetTerminal?: {
      name?: string
      address?: string
      postalCode?: string
    }) => {
      const {
        name,
        address,
        postalCode,
      } = propsGetTerminal || {};

      if (type === 'DOOR') {
        if (address) {
          return `${address}${postalCode ? `, ${postalCode}` : ''}`;
        }
        return `${postalCode || ''}`;
      }

      return name;
    };

    allQuotas?.routes?.forEach((item) => {
      item?.legs?.forEach((itemLegs) => {
        const transportLegType = allQuotas
          ?.schedules?.[indexSchedule]
          ?.transportations
          ?.filter((itemTransportations) => itemTransportations.transportLeg === itemLegs.id);

        if (!transportLegType?.length) {
          return;
        }

        const transportLegTypeFirst = transportLegType?.[0]?.transport?.type || '';
        const { country, name: city, city: cityDoor } = itemLegs.arrivalLocation;
        const {
          country: countryFrom, name: cityFrom, city: cityFromDoor,
        } = itemLegs.departureLocation;

        const findTransportations = allQuotas
          ?.schedules?.[indexSchedule]
          ?.transportations
          ?.filter((itemTransportation) => itemTransportation.transportLeg === itemLegs.id);
        let isFindOrigin = false;
        let isFindDestination = false;

        const findService: IFreightQuotaContentSchedulesChargesDTM[] = [];
        const findFees = allQuotas
          ?.schedules?.[indexSchedule]
          ?.charges
          ?.filter((itemSurcharges) => {
            isFindOrigin = itemSurcharges?.designation === chargeCodeDesignation.ORIGIN ? true : isFindOrigin;
            isFindDestination = itemSurcharges?.designation === chargeCodeDesignation.DESTINATION ? true : isFindDestination;

            if (itemSurcharges.subjectTo === chargeCodeSubjectTo.INCLUDED) {
              return false;
            }

            if (itemSurcharges?.chargeCode?.occurrence === chargeCodeOccurrence.ADDITIONAL) {
              findService.push(itemSurcharges);
              return false;
            }

            return itemSurcharges?.priceBy === chargeCodePriceBy.BOL || itemSurcharges?.chargeCode?.type === chargeCodeType.FEE;
          });

        const findTransportation = findTransportations?.[0];
        const objPush: DetailRoutingDTM = DetailRoutingDTM.fromPlain({
          duration: findTransportation
            ?.schedule?.transitDuration || 0,
          paymentTerms: getPaymentTerms(itemLegs.phase, allQuotas.incoterm, allQuotas.tradeType),
          to: getLocationToOneString({ country: country?.code || '', city: city || cityDoor, firstCityName: true }),
          from: getLocationToOneString({ country: countryFrom?.code || '', city: cityFrom || cityFromDoor, firstCityName: true }),
          etd: findTransportation
            ?.schedule?.departureTime || '',
          eta: findTransportation
            ?.schedule?.arrivalTime || '',
          pickupFromTime: findTransportation?.schedule?.pickupFromTime || '',
          pickupToTime: findTransportation?.schedule?.pickupToTime || '',
          fullPickupTime: findTransportation?.schedule?.fullPickupTime || '',
          deliveryTime: findTransportation?.schedule?.deliveryTime || '',
          fullReturnFromTime: findTransportation?.schedule?.fullReturnFromTime || '',
          fullReturnToTime: findTransportation?.schedule?.fullReturnToTime || '',
          transportType: transportLegTypeFirst,
          VesselName: findTransportation?.transport?.name,
          VoyageNumber: findTransportation?.voyageCode || undefined,
          arrivalTerminal: getTerminal(itemLegs?.arrivalLocation?.type || '', {
            name: findTransportation?.arrivalTerminal || '',
            address: itemLegs.arrivalLocation.address,
            postalCode: itemLegs.arrivalLocation.postalCode,
          }),
          departureTerminal: getTerminal(itemLegs?.departureLocation?.type || '', {
            name: findTransportation?.departureTerminal || '',
            address: itemLegs.departureLocation.address,
            postalCode: itemLegs.departureLocation.postalCode,
          }),
          transportations: findTransportations || undefined,
        });

        const errorsobjPush = item.validate();

        if (errorsobjPush.length) {
          console.error('DTM valid RFQ selector: getFreightQuoteCurrentAllQuotasDetailsRoutingByIndex', errorsobjPush);
        }

        const getFindSteps = (name: string,
          dataStep: FreightQuotaContentSchedulesChargesDTM[]) => {
          let findSummary = 0;
          const findData: DetailRoutingContainerStepDTM[] = dataStep
            .filter((itemFees) => itemFees?.designation?.toLocaleLowerCase() === name)
            .map((itemFees) => {
              const descriptionText = (itemFees?.priceBy === chargeCodePriceBy.BOL
                && itemFees?.measureBy === chargeCodeMeasureBy.FLAT)
                ? i18n.t('perBL')
                : undefined;

              const nameFees = itemFees?.chargeCode?.code === chargeCodeCode.MSC && itemFees?.chargeCode?.originalDescription
                ? itemFees?.chargeCode?.originalDescription?.toLowerCase()
                : itemFees?.chargeCode?.description || '';
              findSummary += itemFees?.totalCost || 0;
              return DetailRoutingContainerStepDTM.fromPlain({
                name: nameFees,
                summary: itemFees.totalCost,
                cost: itemFees.costPerUnit,
                totalUnits: 1,
                descriptionText,
                isUnits: !!descriptionText,
              });
            });

          return { findData, findSummary };
        };

        const getFindServiceSteps = (name: string,
          dataStep: IFreightQuotaContentSchedulesChargesDTM[]) => {
          let findSummary = 0;
          const stackNames: string[] = [];
          const newDataService: {
            [key: string]: DetailRoutingContainerStepDTM
          } = {};

          const containersServicesArray: DetailBreakdownContainerDTM[] = [];
          const checkSomeType = new Set();

          dataStep
            .filter((itemFees) => itemFees?.designation?.toLocaleLowerCase() === name)
            .forEach((itemFees) => {
              if (checkSomeType
                .has(containersTypesById[itemFees.containerId || ''])
              ) {
                return;
              }
              checkSomeType.add(containersTypesById[itemFees.containerId || ''] || null);

              const descriptionText = (itemFees?.priceBy === chargeCodePriceBy.BOL
                && itemFees.measureBy === chargeCodeMeasureBy.FLAT)
                ? i18n.t('perBL')
                : undefined;

              const totalUnitsName = containersTypesById?.[itemFees?.containerId || ''] || '';
              findSummary += itemFees?.totalCost || 0;

              if (itemFees?.priceBy === chargeCodePriceBy.CONTAINER) {
                const nameFees = itemFees?.chargeCode?.code === chargeCodeCode.MSC && itemFees?.chargeCode?.originalDescription
                  ? itemFees?.chargeCode?.originalDescription
                  : itemFees?.chargeCode?.description || '';

                if (newDataService[itemFees?.chargeCode?.description || '']) {
                  const containerNameType = containersTypesById[itemFees.containerId || ''] || '';
                  const containerName = (isContainerAllTypes(containerNameType) && ContainerAllTypesNamesLongConst[containerNameType]) || '';

                  containersServicesArray.push(DetailBreakdownContainerDTM.fromPlain({
                    name: containerName,
                    steps: [{
                      name: nameFees,
                      summary: Number(((itemFees?.totalCost || 0) * containersTypes[totalUnitsName]?.length)?.toFixed(2)),
                      cost: itemFees.costPerUnit || 0,
                      totalUnits: Number(containersTypes?.[totalUnitsName]?.length) || 0,
                      isUnits: itemFees?.priceBy === chargeCodePriceBy.CONTAINER || !!descriptionText,
                      descriptionText,
                    }],
                    summary: Number(((itemFees?.totalCost || 0) * containersTypes[totalUnitsName]?.length)?.toFixed(2)),
                    cost: Number(itemFees?.totalCost?.toFixed(2)),
                    totalUnits: 0,
                  }));
                } else if (itemFees?.chargeCode?.code !== chargeCodeCode.MSC) {
                  const containerNameType = containersTypesById[itemFees.containerId || ''] || '';
                  const containerName = (isContainerAllTypes(containerNameType) && ContainerAllTypesNamesLongConst[containerNameType]) || '';

                  containersServicesArray.push(DetailBreakdownContainerDTM.fromPlain({
                    name: containerName,
                    steps: [{
                      name: nameFees,
                      summary: Number(((itemFees?.totalCost || 0) * containersTypes[totalUnitsName]?.length)?.toFixed(2)),
                      cost: itemFees.costPerUnit || 0,
                      totalUnits: Number(containersTypes?.[totalUnitsName]?.length) || 0,
                      isUnits: itemFees?.priceBy === chargeCodePriceBy.CONTAINER || !!descriptionText,
                      descriptionText,
                    }],
                    summary: Number(((itemFees?.totalCost || 0) * containersTypes[totalUnitsName]?.length)?.toFixed(2)),
                    cost: Number(itemFees?.totalCost?.toFixed(2)),
                    totalUnits: 0,
                  }));

                  if (itemFees?.chargeCode?.description) {
                    stackNames.push(itemFees.chargeCode.description);
                  }
                }
              } else if (newDataService[itemFees?.chargeCode?.description || '']) {
                newDataService[itemFees?.chargeCode?.description || ''] = DetailRoutingContainerStepDTM.fromPlain({
                  name: itemFees?.chargeCode?.description || '',
                  summary: (newDataService[itemFees?.chargeCode?.description || ''].summary || 0) + (itemFees?.totalCost || 0),
                  cost: itemFees.costPerUnit || 0,
                  totalUnits: newDataService[itemFees?.chargeCode?.description || '']?.totalUnits + 1,
                  isUnits: itemFees?.priceBy === chargeCodePriceBy.CONTAINER || !!descriptionText,
                  descriptionText,
                });
              } else {
                newDataService[itemFees?.chargeCode?.description || ''] = DetailRoutingContainerStepDTM.fromPlain({
                  name: itemFees?.chargeCode?.description || '',
                  summary: itemFees.totalCost || 0,
                  cost: itemFees.costPerUnit || 0,
                  totalUnits: 1,
                  isUnits: itemFees?.priceBy === chargeCodePriceBy.CONTAINER || !!descriptionText,
                  descriptionText,
                });

                if (itemFees?.chargeCode?.description) {
                  stackNames.push(itemFees.chargeCode.description);
                }
              }
            });

          const findData: DetailRoutingContainerStepDTM[] = Object.values(newDataService).map((itemValue) => DetailRoutingContainerStepDTM.fromPlain({
            name: itemValue?.name || '',
            summary: itemValue.summary,
            cost: itemValue.cost,
            totalUnits: itemValue.totalUnits,
            isUnits: itemValue.isUnits,
            descriptionText: itemValue.descriptionText,
          }));

          const containersServices: DetailBreakdownServicesDTM = DetailBreakdownServicesDTM.fromPlain({
            summary: findSummary,
            containers: containersServicesArray,
            other: {
              summary: 0,
              steps: findData,
            },
          });

          return { findData: containersServices, findSummary };
        };

        if (itemLegs.phase.toLocaleLowerCase() === 'freight') {
          const { findData: findFeesSteps, findSummary: findFeesSummary } = getFindSteps('freight', findFees || []);
          const { findData: findServiceSteps, findSummary: findServiceSummary } = getFindServiceSteps('freight', findService);

          freight.push({
            ...objPush,
            fees: DetailRoutingStepsDTM.fromPlain({
              summary: findFeesSummary,
              steps: findFeesSteps,
            }),
            service: DetailBreakdownServicesDTM.fromPlain({
              summary: findServiceSummary,
              containers: findServiceSteps.containers,
              other: findServiceSteps.other,
            }),
          });
        }

        if (itemLegs.phase.toLocaleLowerCase() === 'origin') {
          origin.push({ ...objPush });
        }
        if (itemLegs.phase.toLocaleLowerCase() === 'destination') {
          destination.push({ ...objPush });
        }
      });
    });

    return {
      freight,
      origin,
      destination,
    };
  },
);

const getExportSchedule = createSelector(
  localState,
  (state) => state.exportSchedule,
);

const getImportSchedule = createSelector(
  localState,
  (state) => state.importSchedule,
);

const getIsScheduleRequiredErrorVisible = createSelector(
  localState,
  (state) => state.isScheduleStepRequiredErrorVisible,
);

const getIsCargoReadyRequiredErrorVisible = createSelector(
  getIsScheduleRequiredErrorVisible,
  getExportSchedule,
  (isVisible, schedule) => isVisible && !!schedule.id,
);

const getIsCargoDeliveryRequiredErrorVisible = createSelector(
  getIsScheduleRequiredErrorVisible,
  getImportSchedule,
  (isVisible, schedule) => isVisible && !!schedule.id,
);

const getCurrentQuotaRequestId = createSelector(
  localState,
  (state) => state.currentQuotaRequestId,
);

const getInitialScheduleId = createSelector(
  localState,
  (state) => state.initialScheduleId,
);

const getInitialQuotaId = createSelector(
  localState,
  (state) => state.initialQuotaId,
);

const getInitialQuotaRequestId = createSelector(
  localState,
  (state) => state.initialQuotaRequestId,
);

const getCurrentQuota = createSelector(
  localState,
  (state) => state.currentQuota,
);

const getShouldChangeQuotaDueToContainer = createSelector(
  localState,
  (state) => state.shouldChangeQuotaDueToContainer,
);

const getNotSavedContainers = createSelector(
  localState,
  (state) => state.notSavedContainers,
);

const getSchedules = createSelector(
  localState,
  (state) => state.schedules,
);

const getChosenSchedule = createSelector(
  localState,
  (state) => state.chosenScheduleId,
);

const getScheduleSchemaById = (scheduleId: number) => createSelector(
  localState,
  (state) => {
    const { currentQuota: quota } = state;

    const containersTypes: IDetailBreakdownStepsContainersTypesDTM = {};
    let containersTypesById: IDetailBreakdownStepsContainersTypesByIdDTM = {};

    quota?.containers?.forEach((itemContainer) => {
      if (containersTypes[itemContainer?.type || 0]) {
        containersTypes[itemContainer?.type || ''].push(itemContainer?.id || 0);
      } else {
        containersTypes[itemContainer?.type || ''] = [itemContainer?.id || 0];
      }
      containersTypesById = {
        ...containersTypesById,
        [itemContainer?.id || 0]: itemContainer.type,
      };
    });
    const indexSchedule = quota?.schedules?.findIndex(({ id }) => id === scheduleId) || 0;
    const allQuotas = quota;
    const freight: DetailRoutingDTM[] = [];
    const origin: DetailRoutingDTM[] = [];
    const destination: DetailRoutingDTM[] = [];

    const getTerminal = (type: string, propsGetTerminal?: {
      name?: string
      address?: string
      postalCode?: string
    }) => {
      const {
        name,
        address,
        postalCode,
      } = propsGetTerminal || {};

      if (type === 'DOOR') {
        if (address) {
          return `${address}${postalCode ? `, ${postalCode}` : ''}`;
        }
        return `${postalCode || ''}`;
      }

      return name;
    };

    allQuotas?.routes?.forEach((item) => {
      item?.legs?.forEach((itemLegs) => {
        const transportLegType = allQuotas
          ?.schedules?.[indexSchedule]
          ?.transportations
          ?.filter((itemTransportations) => itemTransportations.transportLeg === itemLegs.id);

        if (!transportLegType?.length) {
          return;
        }

        const transportLegTypeFirst = transportLegType?.[0]?.transport?.type || '';
        const { country, name: city, city: cityDoor } = itemLegs.arrivalLocation;
        const {
          country: countryFrom, name: cityFrom, city: cityFromDoor,
        } = itemLegs.departureLocation;

        const findTransportations = allQuotas
          ?.schedules?.[indexSchedule]
          ?.transportations
          ?.filter((itemTransportation) => itemTransportation.transportLeg === itemLegs.id);
        let isFindOrigin = false;
        let isFindDestination = false;

        const findService: IFreightQuotaContentSchedulesChargesDTM[] = [];
        const findFees = allQuotas
          ?.schedules?.[indexSchedule]
          ?.charges
          ?.filter((itemSurcharges) => {
            isFindOrigin = itemSurcharges?.designation === chargeCodeDesignation.ORIGIN ? true : isFindOrigin;
            isFindDestination = itemSurcharges?.designation === chargeCodeDesignation.DESTINATION ? true : isFindDestination;

            if (itemSurcharges.subjectTo === chargeCodeSubjectTo.INCLUDED) {
              return false;
            }

            if (itemSurcharges?.chargeCode?.occurrence === chargeCodeOccurrence.ADDITIONAL) {
              findService.push(itemSurcharges);
              return false;
            }

            return itemSurcharges?.priceBy === chargeCodePriceBy.BOL || itemSurcharges?.chargeCode?.type === chargeCodeType.FEE;
          });

        const findTransportation = findTransportations?.[0];
        const objPush: DetailRoutingDTM = DetailRoutingDTM.fromPlain({
          duration: findTransportation
            ?.schedule?.transitDuration || 0,
          paymentTerms: getPaymentTerms(itemLegs.phase, allQuotas.incoterm, allQuotas.tradeType),
          to: getLocationToOneString({ country: country?.code || '', city: city || cityDoor, firstCityName: true }),
          from: getLocationToOneString({ country: countryFrom?.code || '', city: cityFrom || cityFromDoor, firstCityName: true }),
          etd: findTransportation
            ?.schedule?.departureTime || '',
          eta: findTransportation
            ?.schedule?.arrivalTime || '',
          pickupFromTime: findTransportation?.schedule?.pickupFromTime || '',
          pickupToTime: findTransportation?.schedule?.pickupToTime || '',
          fullPickupTime: findTransportation?.schedule?.fullPickupTime || '',
          deliveryTime: findTransportation?.schedule?.deliveryTime || '',
          fullReturnFromTime: findTransportation?.schedule?.fullReturnFromTime || '',
          fullReturnToTime: findTransportation?.schedule?.fullReturnToTime || '',
          transportType: transportLegTypeFirst,
          VesselName: findTransportation?.transport?.name,
          VoyageNumber: findTransportation?.voyageCode || undefined,
          arrivalTerminal: getTerminal(itemLegs?.arrivalLocation?.type || '', {
            name: findTransportation?.arrivalTerminal || '',
            address: itemLegs.arrivalLocation.address,
            postalCode: itemLegs.arrivalLocation.postalCode,
          }),
          departureTerminal: getTerminal(itemLegs?.departureLocation?.type || '', {
            name: findTransportation?.departureTerminal || '',
            address: itemLegs.departureLocation.address,
            postalCode: itemLegs.departureLocation.postalCode,
          }),
          transportations: findTransportations || undefined,
        });

        const errorsobjPush = item.validate();

        if (errorsobjPush.length) {
          console.error('DTM valid RFQ selector: getFreightQuoteCurrentAllQuotasDetailsRoutingByIndex', errorsobjPush);
        }

        const getFindSteps = (name: string,
          dataStep: FreightQuotaContentSchedulesChargesDTM[]) => {
          let findSummary = 0;
          const findData: DetailRoutingContainerStepDTM[] = dataStep
            .filter((itemFees) => itemFees?.designation?.toLocaleLowerCase() === name)
            .map((itemFees) => {
              const descriptionText = (itemFees?.priceBy === chargeCodePriceBy.BOL
                && itemFees?.measureBy === chargeCodeMeasureBy.FLAT)
                ? i18n.t('perBL')
                : undefined;

              const nameFees = itemFees?.chargeCode?.code === chargeCodeCode.MSC && itemFees?.chargeCode?.originalDescription
                ? itemFees?.chargeCode?.originalDescription?.toLowerCase()
                : itemFees?.chargeCode?.description || '';
              findSummary += itemFees?.totalCost || 0;
              return DetailRoutingContainerStepDTM.fromPlain({
                name: nameFees,
                summary: itemFees.totalCost,
                cost: itemFees.costPerUnit,
                totalUnits: 1,
                descriptionText,
                isUnits: !!descriptionText,
              });
            });

          return { findData, findSummary };
        };

        const getFindServiceSteps = (name: string,
          dataStep: IFreightQuotaContentSchedulesChargesDTM[]) => {
          let findSummary = 0;
          const stackNames: string[] = [];
          const newDataService: {
            [key: string]: DetailRoutingContainerStepDTM
          } = {};

          const containersServicesArray: DetailBreakdownContainerDTM[] = [];
          const checkSomeType = new Set();

          dataStep
            .filter((itemFees) => itemFees?.designation?.toLocaleLowerCase() === name)
            .forEach((itemFees) => {
              if (checkSomeType
                .has(containersTypesById[itemFees.containerId || ''])
              ) {
                return;
              }
              checkSomeType.add(containersTypesById[itemFees.containerId || ''] || null);

              const descriptionText = (itemFees?.priceBy === chargeCodePriceBy.BOL
                && itemFees.measureBy === chargeCodeMeasureBy.FLAT)
                ? i18n.t('perBL')
                : undefined;

              const totalUnitsName = containersTypesById?.[itemFees?.containerId || ''] || '';
              findSummary += itemFees?.totalCost || 0;

              if (itemFees?.priceBy === chargeCodePriceBy.CONTAINER) {
                const nameFees = itemFees?.chargeCode?.code === chargeCodeCode.MSC && itemFees?.chargeCode?.originalDescription
                  ? itemFees?.chargeCode?.originalDescription
                  : itemFees?.chargeCode?.description || '';

                if (newDataService[itemFees?.chargeCode?.description || '']) {
                  const containerNameType = containersTypesById[itemFees.containerId || ''] || '';
                  const containerName = (isContainerAllTypes(containerNameType) && ContainerAllTypesNamesLongConst[containerNameType]) || '';

                  containersServicesArray.push(DetailBreakdownContainerDTM.fromPlain({
                    name: containerName,
                    steps: [{
                      name: nameFees,
                      summary: Number(((itemFees?.totalCost || 0) * containersTypes[totalUnitsName]?.length)?.toFixed(2)),
                      cost: itemFees.costPerUnit || 0,
                      totalUnits: Number(containersTypes?.[totalUnitsName]?.length) || 0,
                      isUnits: itemFees?.priceBy === chargeCodePriceBy.CONTAINER || !!descriptionText,
                      descriptionText,
                    }],
                    summary: Number(((itemFees?.totalCost || 0) * containersTypes[totalUnitsName]?.length)?.toFixed(2)),
                    cost: Number(itemFees?.totalCost?.toFixed(2)),
                    totalUnits: 0,
                  }));
                } else if (itemFees?.chargeCode?.code !== chargeCodeCode.MSC) {
                  const containerNameType = containersTypesById[itemFees.containerId || ''] || '';
                  const containerName = (isContainerAllTypes(containerNameType) && ContainerAllTypesNamesLongConst[containerNameType]) || '';

                  containersServicesArray.push(DetailBreakdownContainerDTM.fromPlain({
                    name: containerName,
                    steps: [{
                      name: nameFees,
                      summary: Number(((itemFees?.totalCost || 0) * containersTypes[totalUnitsName]?.length)?.toFixed(2)),
                      cost: itemFees.costPerUnit || 0,
                      totalUnits: Number(containersTypes?.[totalUnitsName]?.length) || 0,
                      isUnits: itemFees?.priceBy === chargeCodePriceBy.CONTAINER || !!descriptionText,
                      descriptionText,
                    }],
                    summary: Number(((itemFees?.totalCost || 0) * containersTypes[totalUnitsName]?.length)?.toFixed(2)),
                    cost: Number(itemFees?.totalCost?.toFixed(2)),
                    totalUnits: 0,
                  }));

                  if (itemFees?.chargeCode?.description) {
                    stackNames.push(itemFees.chargeCode.description);
                  }
                }
              } else if (newDataService[itemFees?.chargeCode?.description || '']) {
                newDataService[itemFees?.chargeCode?.description || ''] = DetailRoutingContainerStepDTM.fromPlain({
                  name: itemFees?.chargeCode?.description || '',
                  summary: (newDataService[itemFees?.chargeCode?.description || ''].summary || 0) + (itemFees?.totalCost || 0),
                  cost: itemFees.costPerUnit || 0,
                  totalUnits: newDataService[itemFees?.chargeCode?.description || '']?.totalUnits + 1,
                  isUnits: itemFees?.priceBy === chargeCodePriceBy.CONTAINER || !!descriptionText,
                  descriptionText,
                });
              } else {
                newDataService[itemFees?.chargeCode?.description || ''] = DetailRoutingContainerStepDTM.fromPlain({
                  name: itemFees?.chargeCode?.description || '',
                  summary: itemFees.totalCost || 0,
                  cost: itemFees.costPerUnit || 0,
                  totalUnits: 1,
                  isUnits: itemFees?.priceBy === chargeCodePriceBy.CONTAINER || !!descriptionText,
                  descriptionText,
                });

                if (itemFees?.chargeCode?.description) {
                  stackNames.push(itemFees.chargeCode.description);
                }
              }
            });

          const findData: DetailRoutingContainerStepDTM[] = Object.values(newDataService).map((itemValue) => DetailRoutingContainerStepDTM.fromPlain({
            name: itemValue?.name || '',
            summary: itemValue.summary,
            cost: itemValue.cost,
            totalUnits: itemValue.totalUnits,
            isUnits: itemValue.isUnits,
            descriptionText: itemValue.descriptionText,
          }));

          const containersServices: DetailBreakdownServicesDTM = DetailBreakdownServicesDTM.fromPlain({
            summary: findSummary,
            containers: containersServicesArray,
            other: {
              summary: 0,
              steps: findData,
            },
          });

          return { findData: containersServices, findSummary };
        };

        if (itemLegs.phase.toLocaleLowerCase() === 'freight') {
          const { findData: findFeesSteps, findSummary: findFeesSummary } = getFindSteps('freight', findFees || []);
          const { findData: findServiceSteps, findSummary: findServiceSummary } = getFindServiceSteps('freight', findService);

          freight.push({
            ...objPush,
            fees: DetailRoutingStepsDTM.fromPlain({
              summary: findFeesSummary,
              steps: findFeesSteps,
            }),
            service: DetailBreakdownServicesDTM.fromPlain({
              summary: findServiceSummary,
              containers: findServiceSteps.containers,
              other: findServiceSteps.other,
            }),
          });
        }

        if (itemLegs.phase.toLocaleLowerCase() === 'origin') {
          origin.push({ ...objPush });
        }
        if (itemLegs.phase.toLocaleLowerCase() === 'destination') {
          destination.push({ ...objPush });
        }
      });
    });

    return {
      freight,
      origin,
      destination,
    };
  },
);

export const bookingWizardSelectors = {
  getDefaultCargo,
  getIsContentUpdating,
  getIsSecondStep,
  getContainersAmount,
  getCurrentStep,
  getSupplierLoadingControls,
  getReceiverLoadingControls,
  getShipmentId,
  getShipmentNRAFileNamePrint,
  getShipmentTermsOfServiceFileNamePrint,
  getVoyageDuration,
  getShipmentOrigin,
  getShipmentDestination,
  getCreatedCargosIds,
  getCargos,
  getShouldHaveAnyHazmats,
  getCargoById,
  getHasTemperatureControl,
  getIsFirstCargo,
  getIsHazmatToggledByCargoId,
  getCargosTotalWeightAndVolume,
  hasErrorsAtAnyCargo,
  hasErrorsAtAnyContainers,
  getIsCargoForcedToBeHazmat,
  getIsHazmatErrorVisible,
  getIsHaveAccountLimit,
  getIsShowAccountLimit,
  getHSCodeBEValidationErrorMessage,
  getIsLoading,
  getWasInformationSavedOnce,
  getNewBookPartiesId,
  getNewBookPartiesConsigneeId,
  getTotalCost,
  getCargosToDeleteIds,
  getEmptyReleaseDate,
  getContainers,
  getContainerById,
  getContainerErrorsById,
  getHasSOC,
  getSchedules,
  getNameSCAC,
  getEmptyReleaseDateError,
  getCurrentOrganization,
  getShouldShowShipperSection,
  getCompaniesList,
  getSelectedCompany,
  getShipmentReference,
  getShipmentReferenceData,
  getCompanyError,
  getShipmentReferenceError,
  getShouldShowConsigneeSection,
  getShouldShowConsigneeNRASection,
  getIsCurrentOrganizationCustomer,
  getIsNRAChecked,
  getIsTermsAndConditionsChecked,
  getIsAgreeAndBookDisabled,
  getContactBookDefault,
  getContactBookUpdate,
  getContactBookUpdateCompanyListByIds,
  getContactBookUpdateErrorsCompanyListStateById,
  getContactBookUpdateCompanyListAddressesByIds,
  getContactBookUpdateCompanyListContactPersonsByIds,
  getContactBookUpdateErrorsAddressesById,
  getContactBookUpdateErrorsContactPersonsById,
  getIsUsShipmentOriginOrDestination,
  getShipment,
  getShipmentPartyReference,
  getShipmentPartyReferenceError,
  getIsWithGensetCharges,
  getSumOfGenset,
  getShipmentConsigneeReference,
  getShipmentConsigneeReferenceError,
  getSelectedConsigneeCompany,
  getConsigneeCompanyError,
  getCargoSupplier,
  getCargoReceiver,
  getCargoSupplierCompany,
  getCargoSupplierAddress,
  getCargoSupplierContact,
  getCargoReceiverCompany,
  getCargoReceiverAddress,
  getCargoReceiverContact,
  getIsCargoSupplierRequiredErrorVisible,
  getIsCargoReceiverRequiredErrorVisible,
  getContainersErrors,
  getShipmentRoles,
  getIsOriginPartner,
  getIsCreateCompanyAddressBlocked,
  getSupplierStepActiveTab,
  getIsShipmentWaitingForApproval,
  getIsLoadingMethodsInformationMissing,
  getContainersTotalValue,
  getIsContainersValueWarningVisible,
  getSupplierAdditionalErrorMessage,
  getSupplierPhoneErrorMessage,
  getReceiverPhoneErrorMessage,
  getReceiverAdditionalErrorMessage,
  getIsThereAnyPhoneError,
  getContactsForChosenCompany,
  getChosenContactId,
  getSaveAddressToContactBookErrorMessage,
  getIsSavingAddress,
  getIsDestinationPartner,
  getIsCustomer,
  getSaveContactToContactBookErrorMessage,
  getCurrentSchedule,
  getCurrentScheduleSchema,
  getExportSchedule,
  getImportSchedule,
  getIsCargoReadyRequiredErrorVisible,
  getIsCargoDeliveryRequiredErrorVisible,
  getCurrentQuotaRequestId,
  getInitialScheduleId,
  getInitialQuotaId,
  getInitialQuotaRequestId,
  getCurrentQuota,
  getShouldChangeQuotaDueToContainer,
  getNotSavedContainers,
  getChosenSchedule,
  getScheduleSchemaById,
};
