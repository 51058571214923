import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CustomerServiceOutlined from '@ant-design/icons/CustomerServiceOutlined';
import DollarCircleOutlined from '@ant-design/icons/DollarCircleOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import EyeOutlined from '@ant-design/icons/EyeOutlined';

import { QUOTAS_STATUS } from 'app-wrapper/constants';

import { RFQLoaderContainer } from 'monetary/view/components';

import {
  LoaderContainer,
  RFQResponseTextStatus,
} from 'monetary/view/pages/QuotaRequests/components/Quotas/components/Preview/Preview.styled';
import { GapHorizontalContainerStyled } from 'app-wrapper/view/guideline';
import { InfoComponent } from './Info';

interface INoneContentProps {
  isLoading?: boolean;
  isLoadingQuotas?: boolean;
}

export const Preview: FC<INoneContentProps> = (props) => {
  const {
    isLoading,
    isLoadingQuotas,
  } = props;
  const { t } = useTranslation();

  const contentNotFailed = useMemo(() => (
    <>
      {isLoading ? (
        <LoaderContainer>
          <RFQLoaderContainer
            nameLogo="Searching Quotes"
          />
        </LoaderContainer>
      )
        : (
          <GapHorizontalContainerStyled width="100%">
            <InfoComponent
              icon={<CustomerServiceOutlined />}
              headerText={t('24/7 Support')}
              footerText={t('24/7 customer service, real human interaction, and personalised assistance.')}
            />
            <InfoComponent
              icon={<DollarCircleOutlined />}
              headerText={t('Best Prices')}
              footerText={t('Unbeatable value with instant quote and flexible payment terms.')}
            />
            <InfoComponent
              icon={(<ClockCircleOutlined />)}
              headerText={t('Time Saving')}
              footerText={t('Get Quote, Book and pay in a seconds, not hours.')}
            />
            <InfoComponent
              icon={(<EyeOutlined />)}
              headerText={t('Full Visibility & Tracking')}
              footerText={t('Mange your supply chain and see all shipping-related information using a dashboard.')}
            />
          </GapHorizontalContainerStyled>
        )}
    </>
  ), [isLoading, t]);

  return isLoadingQuotas
    ? <RFQResponseTextStatus>{QUOTAS_STATUS.failed}</RFQResponseTextStatus>
    : contentNotFailed;
};
