import React, {
  useEffect, FC,
} from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';
import { useParams } from 'react-router';

import { RECEIVABLES } from 'shipment-operations/constants';
import { DataTypeBillingDTM, ShipmentChargesPermissionsDtm } from 'shipment-operations/models/dtm';
import {
  Wrapper, SubPageHeader, SubPageHeaderContainer, SubPageHeaderTitle,
} from 'app-wrapper/view/guideline';

import { CustomTable } from './CustomTable';
import { ShipmentBillingTabs } from './ShipmentBillingTabs';

interface ITotalData {
  totalSum: number
  totalBalance: number
  key: number
}

interface IShipmentBillingComponentProps {
  data: DataTypeBillingDTM[]
  isLoading: boolean
  error: boolean
  fetchData: (shipmentId?: string, type?: string) => void
  totalData: ITotalData[]
  resetData: () => void
  onStart: (shipmentId?: string) => void
  permissions: ShipmentChargesPermissionsDtm
  defaultActiveKey?: string
}

const ShipmentBillingComponent: FC<IShipmentBillingComponentProps> = ({
  data, isLoading, error, fetchData, totalData, resetData, permissions,
  onStart,
  defaultActiveKey,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams<'shipmentId'>();

  useEffect(() => {
    onStart(shipmentId);

    return resetData;
  }, []);

  if (!permissions.shipmentChargesAvailability) {
    return (
      <Wrapper>
        <Skeleton />
      </Wrapper>
    );
  }

  return (
    <Wrapper data-class="ShipmentBillingComponentWrapper">
      <SubPageHeader>
        <SubPageHeaderContainer>
          <SubPageHeaderTitle>{t('shipmentBillingTitle')}</SubPageHeaderTitle>
        </SubPageHeaderContainer>
      </SubPageHeader>
      <ShipmentBillingTabs />

      <CustomTable
        data={data}
        fetchData={fetchData}
        totalData={totalData}
        isLoading={isLoading}
        type={defaultActiveKey || RECEIVABLES}
        error={error}
      />

    </Wrapper>
  );
};

export { ShipmentBillingComponent };
