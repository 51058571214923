import React from 'react';

export const BoxIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.03627 0L14.7366 3.94826V11.8216L8.03627 15.7698L1.33594 11.8216V3.94826L8.03627 0ZM2.66927 11.0596V5.80141L7.36963 8.48733V13.8294L2.66927 11.0596ZM8.70296 13.8294L13.4033 11.0596V5.80139L8.70296 8.48728V13.8294ZM8.03627 1.5476L13.0203 4.48454L8.03625 7.33259L3.05218 4.48455L8.03627 1.5476Z"
      fill="#202C3C"
    />
  </svg>
);
