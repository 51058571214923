import React, { FC } from 'react';
import { ButtonProps } from 'antd/es/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import { PlusSvg } from 'app-wrapper/view/icons';

import {
  ButtonPlusName,
  ButtonPlusWrapper,
} from './ButtonPlus.styled';

interface IButtonPlus extends ButtonProps {
  isDisabled?: boolean
  onClick?: () => void
  name?: string
  sizeType?: SizeType
}

export const ButtonPlus: FC<IButtonPlus> = (props) => {
  const {
    isDisabled,
    onClick,
    name,
    sizeType,
  } = props;

  return (
    <ButtonPlusWrapper
      onClick={onClick}
      disabled={isDisabled}
      size={sizeType || 'middle'}
      type="dashed"
      data-testid="addMediumBtn"
    >
      <PlusSvg
        style={
          {
            color: isDisabled ? '#D1D7E2' : undefined,
          }
        }
        height={9}
        width={9}
      />

      {name
        ? (
          <ButtonPlusName className="addCommodity__title">
            {name}
          </ButtonPlusName>
        )
        : ''}
    </ButtonPlusWrapper>
  );
};
