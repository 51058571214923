import styled from 'styled-components';
import AntDatePicker from 'antd/es/date-picker';

export const RangePicker = styled(AntDatePicker.RangePicker)`
  width: 100%;
  height: 32px;
  min-height: 32px;

  &.ant-picker-focused {
    box-shadow: none !important;
    border: 1px solid ${({ theme }) => theme?.themesColors?.primaryBranding6} !important;
  }
`;
