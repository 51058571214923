import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { useIsExportOrImport } from 'app-wrapper/hooks';
import { ETransportationType } from 'monetary/constants';
import { ChargeCodeLoadTypeEnum, DESTINATION, ORIGIN } from 'shipment-operations/constants';
import { ChargeDTM } from 'shipment-operations/models/dtm';
import { ShipmentFreeTime } from './ShipmentFreeTime.component';

function cleanObject(obj: { [key: string]: ChargeDTM[] }) {
  return Object.entries(obj)
    .filter(([, value]) => !(Array.isArray(value) && value.length === 0))
    .reduce((acc: { [key: string]: ChargeDTM[] }, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
}

function filterObjectByCondition(obj: { [key: string]: ChargeDTM[] } = {}, condition: (item: ChargeDTM) => boolean): { [key: string]: ChargeDTM[] } {
  const filteredObj: { [key: string]: ChargeDTM[] } = {};

  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      filteredObj[key] = obj[key].filter(condition);
    }
  });

  return cleanObject(filteredObj);
}

const ShipmentFreeTimeContainer = () => {
  const isLoading = useSelector(R.selectors.shipmentFreeTime.getIsLoading);
  const isError = useSelector(R.selectors.shipmentFreeTime.getError);
  const originData = useSelector(R.selectors.shipmentFreeTime.getGroupedOrigin);
  const freightData = useSelector(R.selectors.shipmentFreeTime.getGroupedFreight);
  const destinationData = useSelector(R.selectors.shipmentFreeTime.getGroupedDestination);
  const permissions = useSelector(R.selectors.shipment.getShipmentChargesPermissions);

  const { isExport, isImport, isOceanBooking } = useIsExportOrImport();
  let filteredDataOrigin: { [key: string]: ChargeDTM[] } = {};
  let filteredDataFreight: { [key: string]: ChargeDTM[] } = {};
  let filteredDataDestination: { [key: string]: ChargeDTM[] } = {};

  if (isExport) {
    const condition = (item: ChargeDTM) => item.chargeCode.loadType === ChargeCodeLoadTypeEnum.DRAYAGE && item.designation === ORIGIN;
    filteredDataOrigin = filterObjectByCondition(originData, condition);
    filteredDataFreight = filterObjectByCondition(freightData, condition);
    filteredDataDestination = filterObjectByCondition(destinationData, condition);
  }
  if (isImport) {
    const condition = (item: ChargeDTM) => item.chargeCode.loadType === ChargeCodeLoadTypeEnum.DRAYAGE && item.designation === DESTINATION;
    filteredDataOrigin = filterObjectByCondition(originData, condition);
    filteredDataFreight = filterObjectByCondition(freightData, condition);
    filteredDataDestination = filterObjectByCondition(destinationData, condition);
  }
  if (isOceanBooking) {
    filteredDataOrigin = filterObjectByCondition(originData, (item) => item.chargeCode.mode !== ETransportationType.ROAD && item.chargeCode.loadType !== ChargeCodeLoadTypeEnum.DRAYAGE);
    filteredDataFreight = filterObjectByCondition(freightData, (item) => item.chargeCode.mode !== ETransportationType.ROAD && item.chargeCode.loadType !== ChargeCodeLoadTypeEnum.DRAYAGE);
    filteredDataDestination = filterObjectByCondition(destinationData, (item) => item.chargeCode.mode !== ETransportationType.ROAD && item.chargeCode.loadType !== ChargeCodeLoadTypeEnum.DRAYAGE);
  }

  return (
    <ShipmentFreeTime
      fetchData={UC.shipmentFreeTime.loadData}
      isLoading={isLoading}
      originData={filteredDataOrigin}
      freightData={filteredDataFreight}
      destinationData={filteredDataDestination}
      permissions={permissions}
      isError={isError}
    />
  );
};

export { ShipmentFreeTimeContainer as ShipmentFreeTime };
