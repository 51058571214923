import { SyntheticEvent } from 'react';

import { BaseController, controller } from 'proto/BaseController';
import { DrawersUseCase } from 'app-wrapper/usecases/Drawers.useCase';
import { LoadingControlDTM } from 'shipment-operations/models/dtm';
import { EDrayageLoadingSide, ETasksType } from 'shipment-operations/constants';
import { ERateTypes } from 'monetary/constants';
import { CommandCenterComponentTaskDTM } from 'app-wrapper/models/dtm';
import { EChangeRequestType } from 'app-wrapper/constants';

@controller
export class DrawerController extends BaseController {
  public closeDrawer() {
    new DrawersUseCase(this).closeDrawer();
  }

  public closeDrawerById(id: string) {
    new DrawersUseCase(this).closeDrawerById(id);
  }

  public openRailBillingDrawer(shipmentId: string) {
    new DrawersUseCase(this).openRailBillingDrawer(shipmentId);
  }

  public openVGMTaskDrawer(shipmentId: string) {
    new DrawersUseCase(this).openVGMTaskDrawer(shipmentId);
  }

  public openEditMBLNumberModal() {
    new DrawersUseCase(this).openEditMBLNumberModal();
  }

  public openEditCarrierReferenceNumberModal() {
    new DrawersUseCase(this).openEditCarrierReferenceNumberModal();
  }

  public openEditMBLNumberModalFromOceanBooking() {
    new DrawersUseCase(this).openEditMBLNumberModalFromOceanBooking();
  }

  public openEditCarrierReferenceNumberModalFromOceanBooking() {
    new DrawersUseCase(this).openEditCarrierReferenceNumberModalFromOceanBooking();
  }

  public openISFTaskDrawer(taskId: string) {
    new DrawersUseCase(this).openISFTaskDrawer(taskId);
  }

  public openDrayageBaseRateDownloadModal() {
    new DrawersUseCase(this).openDrayageBaseRateDownloadModal(ERateTypes.DRAYAGE_BASE_RATE);
  }

  public openDrayageSurchargesRateDownloadModal() {
    new DrawersUseCase(this).openDrayageBaseRateDownloadModal(ERateTypes.DRAYAGE_SURCHARGES);
  }

  public openOverviewManualReferenceNumberModal() {
    new DrawersUseCase(this).openOverviewManualReferenceNumberModal();
  }

  public openEditSupplierScheduleDrawer(drayageSide: EDrayageLoadingSide, scheduleId?: number) {
    new DrawersUseCase(this).openEditSupplierScheduleDrawer(drayageSide, scheduleId);
  }

  public openBookingDrayageSelectContactDrawer() {
    new DrawersUseCase(this).openBookingDrayageSelectContactDrawer();
  }

  public openDrayageLoadingControlsDrawer(loadingControls: LoadingControlDTM) {
    new DrawersUseCase(this).openDrayageLoadingControlsDrawer(loadingControls);
  }

  public openShipmentCostChangesDrawer(taskId: string) {
    new DrawersUseCase(this).openShipmentCostChangesDrawer(taskId);
  }

  public openSalesRepresentativeDrawer() {
    new DrawersUseCase(this).openSalesRepresentativeDrawer();
  }

  public openCreateContractDrawer() {
    new DrawersUseCase(this).openCreateContractDrawer();
  }

  public openEditContractDrawer() {
    new DrawersUseCase(this).openEditContractDrawer();
  }

  public openMaerskChanges() {
    new DrawersUseCase(this).openMaerskChanges();
  }

  public openBookingDrawer() {
    new DrawersUseCase(this).openBookingDrawer();
  }

  public openBookingDrayageDrawer(shipmentId?: string) {
    new DrawersUseCase(this).openBookingDrayageDrawer(shipmentId);
  }

  public openBookingConfirmation() {
    new DrawersUseCase(this).openBookingConfirmation();
  }

  public openShipmentMismatches() {
    new DrawersUseCase(this).openShipmentMismatches();
  }

  public openRollShipmentDrawer() {
    new DrawersUseCase(this).openRollShipmentDrawer();
  }

  public openChangeBookingScheduleDrawer() {
    new DrawersUseCase(this).openChangeBookingScheduleDrawer();
  }

  public openChangeBookingQuotaDrawer() {
    new DrawersUseCase(this).openChangeBookingQuotaDrawer();
  }

  public openShipmentServicesDocumentUpload() {
    new DrawersUseCase(this).openShipmentServiceDocumentUpload();
  }

  public openShipmentChanges(withValidate = false) {
    new DrawersUseCase(this).openShipmentChanges(withValidate);
  }

  public openBLMismatches() {
    new DrawersUseCase(this).openBLMismatches();
  }

  public openAddRates(typeDrawer: string) {
    new DrawersUseCase(this).openAddRates(typeDrawer);
  }

  public openChargesCostChanges() {
    new DrawersUseCase(this).openChargesCostChangesDrawer();
  }

  public openLinkContainer(planId: number) {
    new DrawersUseCase(this).openLinkContainer(planId);
  }

  public openTaskDrawer(task: CommandCenterComponentTaskDTM, e: SyntheticEvent) {
    if (task.taskType === ETasksType.CHANGE_REQUEST && task.shipmentId) {
      e.preventDefault();
      new DrawersUseCase(this).openChangeRequestDrawer(Number(task.id), EChangeRequestType.UPDATE_CONTAINERS_INFORMATION);
    } else if (task.taskType === ETasksType.VGM_TASK && task.shipmentId) {
      e.preventDefault();
      this.openVGMTaskDrawer(String(task.shipmentId));
    } else if (task.taskType === ETasksType.SUBMIT_RAIL_BILLING && task.shipmentId) {
      e.preventDefault();
      this.openRailBillingDrawer(String(task.shipmentId));
    } else if (task.taskType === ETasksType.CREATE_BOOKING_REQUEST_TASK) {
      e.preventDefault();
      this.openMaerskChanges();
    } else if (task.taskType === ETasksType.VALIDATE_BOOKING_INFORMATION) {
      e.preventDefault();
      this.openShipmentMismatches();
    } else if (task.taskType === ETasksType.VALIDATE_SCHEDULE_UPDATES) {
      e.preventDefault();
      this.openShipmentMismatches();
    } else if (task.taskType === ETasksType.VALIDATE_SHIPMENT_CHANGES) {
      e.preventDefault();
      this.openShipmentChanges(true);
    } else if (task.taskType === ETasksType.SUBMIT_BOOKING_AMEND_REQUEST) {
      e.preventDefault();
      this.openShipmentChanges();
    } else if (task.taskType === ETasksType.CONFIRM_SHIPMENT_REQUEST) {
      e.preventDefault();
      this.openBookingConfirmation();
    } else if (task.taskType === ETasksType.CONFIRM_SHIPMENT_COST_CHANGE_TASK) {
      e.preventDefault();
      this.openShipmentCostChangesDrawer(String(task.id));
    } else if (task.taskType === ETasksType.SEND_ISF_TASK) {
      e.preventDefault();
      this.openISFTaskDrawer(String(task.id));
    } else if (task.taskType === ETasksType.COMPLETE_BOOKING_TASK && task.shipmentId) {
      e.preventDefault();
      new DrawersUseCase(this).openBookingDrayageDrawerFromTask(String(task.shipmentId));
    }
  }

  public openChangeRequestDrawer() {
    new DrawersUseCase(this).openChangeRequestDrawer(null, EChangeRequestType.UPDATE_CONTAINERS_INFORMATION);
  }

  public openChangeRequestCargoDrawer() {
    new DrawersUseCase(this).openChangeRequestDrawer(null, EChangeRequestType.UPDATE_CARGO_INFORMATION);
  }

  public openChangeRequestCargoSupplierDrawer() {
    new DrawersUseCase(this).openChangeRequestDrawer(null, EChangeRequestType.UPDATE_SUPPLIER_INFORMATION);
  }

  public openChangeRequestCargoReceiverDrawer() {
    new DrawersUseCase(this).openChangeRequestDrawer(null, EChangeRequestType.UPDATE_RECEIVER_INFORMATION);
  }

  public openChangeRequestShipperDrawer() {
    new DrawersUseCase(this).openChangeRequestDrawer(null, EChangeRequestType.UPDATE_HOUSE_SHIPPER_INFORMATION);
  }

  public openChangeRequestConsigneeDrawer() {
    new DrawersUseCase(this).openChangeRequestDrawer(null, EChangeRequestType.UPDATE_HOUSE_CONSIGNEE_INFORMATION);
  }

  public openChangeRequestNotifyDrawer() {
    new DrawersUseCase(this).openChangeRequestDrawer(null, EChangeRequestType.UPDATE_HOUSE_NOTIFY_INFORMATION);
  }

  public openChangeRequestExportPickupDrawer() {
    new DrawersUseCase(this).openChangeRequestDrawer(null, EChangeRequestType.UPDATE_DRAYAGE_INFORMATION_EXPORT);
  }

  public openChangeRequestImportPickupDrawer() {
    new DrawersUseCase(this).openChangeRequestDrawer(null, EChangeRequestType.UPDATE_DRAYAGE_INFORMATION_IMPORT);
  }

  public openChangeRequestScheduleInfo() {
    new DrawersUseCase(this).openChangeRequestDrawer(null, EChangeRequestType.UPDATE_SCHEDULE_INFORMATION);
  }

  public openManualConfirmationDrawer(id: string) {
    new DrawersUseCase(this).openManualConfirmation(id);
  }
}
