import React, {
  ChangeEvent, FC, useCallback, useEffect,
} from 'react';
import Alert from 'antd/es/alert';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Spinner from 'antd/es/spin';
import isEmpty from 'lodash/fp/isEmpty';

import { ShipmentDrawerWrapper } from 'shipment-operations/view/pages/Shipment/components/ShipmentDrawer/ShipmentDrawer.styled';
import { Button } from 'app-wrapper/view/components';
import {
  FooterWrapper,
  SpinnerWrapper,
  SubTitle,
  TableWrapper,
  Wrapper,
  Underline,
  CarrierWrapper,
  InputsWrapper,
  InputTitle,
  StyledInput, UploadWrapper, ValidateWrapper,
} from 'shipment-operations/view/drawers/ShipmentChanges/ShipmentChanges.styled';
import {
  CARGO, CHANGES, CONTAINER, CUTOFF, LOCATIONS,
} from 'shipment-operations/constants';
import { IChangesTable, ITransportPlanChanges } from 'shipment-operations/models/dtm';
import {
  CustomTable, EditableTable, TransportPlan, Uploader,
} from 'shipment-operations/view/drawers/ShipmentChanges/components';
import { Validate } from 'shipment-operations/view/components';

interface ShipmentMismatchesProps {
  isCustomer: boolean;
  loadData: (shipmentId: string) => void;
  loading: boolean;
  cutOffTable: IChangesTable[];
  onAccept: (shipmentId: string, isCustomer: boolean) => void;
  actionLoading: boolean;
  cargoTable: IChangesTable[];
  containersTable: IChangesTable[];
  locationsTable: IChangesTable[];
  onChangeCutOff: (row: IChangesTable) => void;
  onChangeLocation: (row: IChangesTable) => void;
  onConfirm: (shipmentId: string) => void;
  syncIntraNumber: () => void;
  isAcceptLoading: boolean;
  closeDrawer: () => void;
  transportPlanCurrent?: ITransportPlanChanges[];
  transportPlanMismatchCurrent?: ITransportPlanChanges[];
  transportPlanMismatchPrevious?: ITransportPlanChanges[];
  isTransportPlanMismatch?: boolean;
  savedShipmentId: string;
  onChangeCarrierNumber: (value: string) => void;
  onChangeInttraNumber: (value: string) => void;
  carrierNumber?: string;
  inttraNumber?: string;
  reset: () => void;
  showValidate: boolean;
  isDisableSubmit: boolean;
  withLocationSection: boolean;
}

const ShipmentMismatchesComponent: FC<ShipmentMismatchesProps> = ({
  isCustomer, loading, loadData, cutOffTable, actionLoading, onAccept, cargoTable, containersTable,
  onChangeCutOff, locationsTable, onChangeLocation, onConfirm, transportPlanMismatchPrevious, reset, showValidate,
  syncIntraNumber, isAcceptLoading, transportPlanCurrent, transportPlanMismatchCurrent, isDisableSubmit, withLocationSection,
  isTransportPlanMismatch, savedShipmentId, closeDrawer, inttraNumber, carrierNumber, onChangeInttraNumber, onChangeCarrierNumber,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams();

  const inttraChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChangeInttraNumber(event.target.value);
    },
    [inttraNumber],
  );
  const carrierChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChangeCarrierNumber(event.target.value);
    },
    [],
  );

  const handleAccept = useCallback(() => {
    if (shipmentId || savedShipmentId) {
      onAccept(shipmentId || savedShipmentId, isCustomer);
    }
  }, [shipmentId]);

  const handleConfirm = useCallback(() => {
    if (shipmentId || savedShipmentId) {
      onConfirm(shipmentId || savedShipmentId);
    }
  }, [shipmentId]);

  useEffect(() => {
    syncIntraNumber();
    if (shipmentId || savedShipmentId) {
      loadData(shipmentId || savedShipmentId);
    }
  }, [shipmentId, savedShipmentId]);

  useEffect(() => {
    if (!loading) {
      syncIntraNumber();
    }
  }, [savedShipmentId, loading]);

  useEffect(() => () => reset(), []);

  return (
    <ShipmentDrawerWrapper
      title={t('Review Shipment Amendments')}
      visible
      width="1137px"
      closable
      maskClosable={false}
      onClose={closeDrawer}
    >
      <Wrapper>
        {loading ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (
          <>
            <Alert
              style={{ whiteSpace: 'pre-line' }}
              showIcon
              type="warning"
              message={t('reviewMismatches')}
              description={isCustomer ? t('reviewMismatchesDescription') : t('reviewMismatchesDescriptionPartner')}
            />
            {!isCustomer && (
              <>
                <CarrierWrapper>
                  <SubTitle>{t('carrier')}</SubTitle>
                  <InputsWrapper>
                    <InputTitle>{t('inttraReferenceNumber')}</InputTitle>
                    <StyledInput
                      style={{ marginRight: '15px', borderColor: inttraNumber ? '' : 'red' }}
                      value={inttraNumber}
                      onChange={inttraChangeHandler}
                    />
                    <InputTitle>{t('carrierReferenceNumber')}</InputTitle>
                    <StyledInput
                      style={{ borderColor: carrierNumber ? '' : 'red' }}
                      value={carrierNumber}
                      onChange={carrierChangeHandler}
                    />
                  </InputsWrapper>
                </CarrierWrapper>
                <UploadWrapper>
                  <SubTitle>{t('bookingConfirmationDocument')}</SubTitle>
                  <Uploader />
                </UploadWrapper>
              </>
            )}
            {isCustomer && (
              <>
                {isTransportPlanMismatch && (
                  <TableWrapper>
                    <SubTitle>{t('transportPlan')}</SubTitle>
                    <Underline />
                    <TransportPlan
                      requestedTitle={isCustomer ? t('oldValue') : t('currentValue')}
                      mismatchedTitle={t('newValue')}
                      requestedData={transportPlanMismatchPrevious}
                      mismatchedData={transportPlanMismatchCurrent}
                      withMismatch
                    />
                  </TableWrapper>
                )}
                {!isEmpty(cutOffTable) && (
                  <TableWrapper>
                    <SubTitle>{t('Cutoffs')}</SubTitle>
                    <CustomTable
                      data={cutOffTable}
                      type={CUTOFF}
                      withThreeColumns={false}
                      withMismatches={false}
                      isRequestTheSameWithShipment={false}
                      isCustomer={isCustomer}
                      mismatchesTable
                    />
                  </TableWrapper>
                )}
              </>
            )}
            {!isCustomer && (
              <>
                {!isEmpty(cargoTable) && (
                  <TableWrapper>
                    <SubTitle>{t('cargo')}</SubTitle>
                    <CustomTable
                      data={cargoTable}
                      type={CARGO}
                      withThreeColumns={false}
                      withMismatches={false}
                      isRequestTheSameWithShipment={false}
                      isCustomer={isCustomer}
                      mismatchesTable
                    />
                  </TableWrapper>
                )}
                {!isEmpty(containersTable) && (
                  <TableWrapper>
                    <SubTitle>{t('Containers')}</SubTitle>
                    <CustomTable
                      data={containersTable}
                      type={CONTAINER}
                      withThreeColumns={false}
                      withMismatches={false}
                      isRequestTheSameWithShipment={false}
                      isCustomer={isCustomer}
                      mismatchesTable
                    />
                  </TableWrapper>
                )}
                {isTransportPlanMismatch && (
                  <TableWrapper>
                    <SubTitle>{t('transportPlan')}</SubTitle>
                    <Underline />
                    <TransportPlan
                      requestedTitle={t('currentValue')}
                      mismatchedTitle={t('newValue')}
                      requestedData={transportPlanCurrent}
                      mismatchedData={transportPlanMismatchCurrent}
                      withMismatch
                    />
                  </TableWrapper>
                )}
                {showValidate && !isCustomer && (
                  <ValidateWrapper>
                    <Validate typeDrawer={CHANGES} />
                  </ValidateWrapper>
                )}
                <TableWrapper>
                  <SubTitle>{t('Cutoffs')}</SubTitle>
                  <EditableTable
                    data={cutOffTable}
                    type={CUTOFF}
                    withThreeColumns={false}
                    withMismatches={false}
                    isRequestTheSameWithShipment={false}
                    onChange={onChangeCutOff}
                    isCustomer={isCustomer}
                    mismatchesTable
                  />
                </TableWrapper>
                {withLocationSection && (
                  <TableWrapper>
                    <SubTitle>{t('Location')}</SubTitle>
                    <EditableTable
                      data={locationsTable}
                      type={LOCATIONS}
                      withThreeColumns={false}
                      withMismatches={false}
                      isRequestTheSameWithShipment={false}
                      onChange={onChangeLocation}
                      isCustomer={isCustomer}
                      mismatchesTable
                    />
                  </TableWrapper>
                )}
              </>
            )}
          </>
        )}
      </Wrapper>
      <FooterWrapper>
        <Button loading={isAcceptLoading} onClick={handleAccept} style={{ marginLeft: '15px' }} type="default">
          {isCustomer ? t('acknowledge') : 'Skip'}
        </Button>
        {!isCustomer && (
          <Button
            loading={actionLoading}
            onClick={handleConfirm}
            style={{ marginLeft: '15px' }}
            disabled={isDisableSubmit}
          >
            {t('Approve')}
          </Button>
        )}
      </FooterWrapper>
    </ShipmentDrawerWrapper>
  );
};

export { ShipmentMismatchesComponent };
