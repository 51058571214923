import React from 'react';
import { useTranslation } from 'react-i18next';

import { SubPageContent } from 'app-wrapper/view/guideline';

import { Wrapper, SmallDivider } from '../styled';
import { GreenCheck, EditSection, StyledObject } from '../CommonStyled';

const ScheduleComponent = () => {
  const { t } = useTranslation();

  return (
    <SubPageContent>
      <GreenCheck title={t('Schedule')} isValid />
      <Wrapper>
        <div style={{ width: '60%' }}>
          <EditSection onClick={() => {}} title={t('Routing')} />
        </div>
        <div style={{ width: '40%' }}>
          <EditSection onClick={() => {}} title="Cutoffs">
            <StyledObject customKey={t('Port')} customValue="01 Dec, 09-15" />
            <SmallDivider />
            <StyledObject customKey={t('Documentation')} customValue="13 Dec, 11-15" />
            <SmallDivider />
            <StyledObject customKey={t('VGM')} customValue="13 Dec, 11-15" />
          </EditSection>
        </div>
      </Wrapper>
    </SubPageContent>
  );
};

export { ScheduleComponent };
