import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';

import { OriginOverlayComponent } from './OriginOverlay.component';

export const OriginOverlay = React.memo(() => {
  const current = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const origin = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentOrigin);
  const errors = useSelector(R.selectors.freightQuote.getFreightQuoteErrors);

  const originErrors = useMemo(() => ({
    isPort: errors?.origin?.isPort,
    dateDoor: errors?.origin?.dateDoor,
    datePort: errors?.origin?.datePort,
    location: errors?.origin?.location,
  }), [errors?.origin]);

  const destinationErrors = useMemo(() => ({
    isPort: errors?.destination?.isPort,
    dateDoor: errors?.destination?.dateDoor,
    datePort: errors?.destination?.datePort,
    location: errors?.destination?.location,
  }), [errors?.destination]);

  return (
    <OriginOverlayComponent
      origin={origin}
      originErrors={originErrors}
      destinationErrors={destinationErrors}
      onChangeOriginTypeToPort={UC.FreightQuote.onChangeOriginTypeToPort}
      onChangePortDateOrigin={UC.FreightQuote.onChangePortDateOrigin}
      onFocusPortDateOrigin={UC.FreightQuote.onFocusPortDateOrigin}
      onBlurPortDateOrigin={UC.FreightQuote.onBlurPortDateOrigin}
      isSubmitVisited={current?.isSubmitVisited}
    />
  );
});
