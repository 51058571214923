import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'monetary/controllers';
import { R } from 'monetary/repository';
import { R as userManagementR } from 'user-management/repository';
import { FreightQuotaContentDTM, IFreightQuotaContentDTM } from 'monetary/models/dtm';
import { EPermissionAttributePolicy } from 'monetary/constants';

import { CardComponent } from './Card.component';

export interface ICardProps {
  data?: IFreightQuotaContentDTM
  isAllRequestPage?: boolean
  idIndex: number
  hideBook?: boolean
  onScroll: (top: number) => void
}

export const CardContainer = memo(({
  idIndex, hideBook,
}: ICardProps) => {
  const getFreightQuoteCurrent = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const allQuotasByIndex: FreightQuotaContentDTM = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentAllQuotasAppliedByIndex(idIndex));
  const allQuotasByIndexFileNamePrint = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentAllQuotasByIndexFileNamePrint(idIndex));
  const isOrganizationInDraft = useSelector(userManagementR.selectors.userOrganizationData.getIsOrganizationInDraft);
  const isOrganizationInWaitingForApproval = useSelector(userManagementR.selectors.userOrganizationData.getIsOrganizationInWaitingForApproval);
  const mustOrganizationAcceptTermsAndCondition = useSelector(userManagementR.selectors.userOrganizationData.getMustOrganizationAcceptTermsAndCondition);

  const getFreightQuoteCurrentAllQuotasTotalCost = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentAllQuotasTotalCost(idIndex));

  const getRFQNewQuotaPermissions = useSelector(R.selectors.moduleFunctionalityPermissions.getRFQNewQuotaPermissions);
  const quotas = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentQuotas);

  const indexSchedule = quotas?.indexSchedules?.[idIndex]?.idIndex || 0;

  const isShowContractName = getRFQNewQuotaPermissions?.quotaItemContractName === EPermissionAttributePolicy.WRITE;

  return (
    <CardComponent
      data={{
        ...allQuotasByIndex,
        schedules: [
          ...allQuotasByIndex?.schedules?.map((item) => ({
            ...item,
            transportations: [
              ...item.transportations || [],
            ],
          })) || [],
        ],
      }}
      isShowContractName={isShowContractName}
      fileNamePrint={allQuotasByIndexFileNamePrint}
      onPrint={UC.FreightQuote.onSetPrintedId}
      onClearCustomerInfo={UC.FreightQuote.onClearCustomerInfo}
      onBook={UC.FreightQuote.onBook}
      shipmentId={getFreightQuoteCurrent.booking?.shipmentId}
      bookingStatus={getFreightQuoteCurrent.booking?.status}
      indexSchedule={indexSchedule}
      totalCost={getFreightQuoteCurrentAllQuotasTotalCost}
      isOrganizationInDraft={isOrganizationInDraft}
      hideBook={hideBook}
      isOrganizationInWaitingForApproval={isOrganizationInWaitingForApproval}
      mustOrganizationAcceptTermsAndCondition={mustOrganizationAcceptTermsAndCondition}
    />
  );
});
