import message from 'antd/es/message';
import i18n from 'i18next';

import { R as AuthR } from 'authentication/repository';
import { UserAuthDataDTM } from 'authentication/models/dtm';
import { BaseController, controller } from 'proto/BaseController';
import { validationPhone } from 'app-wrapper/utils';
import { R as userManagementR, R } from 'user-management/repository';
import { OrganizationMemberDTM } from 'user-management/models/dtm';

@controller
export class UserEditProfileController extends BaseController {
  public onFormOpen = async () => {
    const currentOrg = R.selectors.userOrganizationData.getUserOrganization(this.store.getState());
    const user = AuthR.selectors.auth.getUser(this.store.getState());
    const userPhone = R.selectors.userOrganizationData.getOrganizationUserPhone(this.store.getState());
    this.mobxStore.userEditProfileStore.setLoading(true);

    let organizationMember: OrganizationMemberDTM | null = null;

    if (currentOrg) {
      const departments = await R.services.accountDepartment.getOrganizationDepartments({
        organizationId: String(currentOrg.id),
      });
      organizationMember = await userManagementR.services.organization.getOrganizationMember(currentOrg.id, user.email);

      this.mobxStore.userEditProfileStore.setIsUserPrimary(organizationMember?.primary);
      this.mobxStore.userEditProfileStore.setAvailableDepartments(departments);
      this.mobxStore.userEditProfileStore.setDepartments(organizationMember ? organizationMember?.departments.map(({ id }) => String(id)) : []);
    }

    this.mobxStore.userEditProfileStore.clearFirstNameError();
    this.mobxStore.userEditProfileStore.clearLastNameError();
    this.mobxStore.userEditProfileStore.setFirstName(organizationMember?.firstName || user.firstName);
    this.mobxStore.userEditProfileStore.setLastName(organizationMember?.lastName || user.lastName);
    this.mobxStore.userEditProfileStore.setPhone(userPhone || '');
    this.mobxStore.userEditProfileStore.setLoading(false);
  };

  public setDepartments = (departments: string[]) => {
    this.mobxStore.userEditProfileStore.setDepartments(departments);
  };

  public changeUserProfileData = async () => {
    let organizationMember: OrganizationMemberDTM | undefined;
    let user: UserAuthDataDTM | undefined;

    const {
      firstName = '',
      lastName = '',
      phone = '',
      jobTitle,
    } = this.mobxStore.userEditProfileStore.state;

    this.validateFirstName();
    this.validateLastName();

    if (phone) {
      this.validatePhone();
    }

    const {
      firstNameError,
      lastNameError,
      phoneError,
      departments,
      isUserPrimary,
      availableDepartments,
    } = this.mobxStore.userEditProfileStore.state;

    const departmentsToSave = departments.length ? availableDepartments.filter(({ id }) => departments.includes(String(id))) : [];

    if (phoneError || lastNameError || firstNameError) {
      return;
    }

    this.mobxStore.userEditProfileStore.setLoading(true);

    try {
      await AuthR.services.auth.changeUserData(firstName, lastName);
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);

        this.mobxStore.userEditProfileStore.setLoading(false);
        return;
      }

      this.mobxStore.userEditProfileStore.setLoading(false);

      return;
    }

    const userEmail = AuthR.selectors.auth.getEmail(this.store.getState());

    const organization = R.selectors.userOrganizationData.getUserOrganization(this.store.getState());

    if (organization) {
      try {
        organizationMember = await R.services.organization.getOrganizationMember(organization.id, userEmail);
      } catch (e) {
        console.error(e);
      }
    }

    if (organizationMember) {
      if (organization?.id) {
        try {
          organizationMember = await R.services.organization.putOrganizationMember(organization?.id, userEmail, OrganizationMemberDTM.fromPlain({
            ...organizationMember,
            phone,
            jobTitle,
            departments: isUserPrimary ? departmentsToSave : organizationMember.departments,
          }));
        } catch (e) {
          if (e instanceof Error) {
            message.error(e.message);
            this.mobxStore.userEditProfileStore.setLoading(false);
          }

          throw e;
        }
      }

      this.dispatch(R.actions.userOrganizationData.setCurrentOrganizationUserData(organizationMember));
    }

    try {
      user = await AuthR.services.auth.getUserData();
    } catch (e) {
      console.error(e);

      return;
    }

    if (!user) {
      return;
    }

    this.dispatch(AuthR.actions.auth.setUser(user));

    this.mobxStore.userEditProfileStore.setLoading(false);

    this.mobxStore.userEditProfileStore.clear();
    this.dispatch(R.actions.accountDrawer.closeDrawer());

    message.success(i18n.t('User Profile changed successfully'));
  };

  public onBlurPhone = () => {
    this.validatePhone();
  }

  public setPhone = (phone: string) => {
    this.mobxStore.userEditProfileStore.setPhone(phone);
  }

  public setJobTitle = (jobTitle: string) => {
    this.mobxStore.userEditProfileStore.setJobTitle(jobTitle);
  }

  public setFirstName = (value: string) => {
    this.mobxStore.userEditProfileStore.setFirstName(value);
  }

  public onBlurFirstName = () => {
    this.validateFirstName();
  }

  public setLastName = (value: string) => {
    this.mobxStore.userEditProfileStore.setLastName(value);
  }

  public onBlurLastName = () => {
    this.validateLastName();
  }

  public closeUserEditProfileDrawer = () => {
    this.mobxStore.userEditProfileStore.clear();
    this.dispatch(R.actions.accountDrawer.closeDrawer());
  }

  private validatePhone = () => {
    const { phone } = this.mobxStore.userEditProfileStore.state;

    this.mobxStore.userEditProfileStore.clearPhoneError();

    if (phone) {
      const { errorMessage } = validationPhone(phone);

      if (errorMessage) {
        this.mobxStore.userEditProfileStore.setPhoneError(new Error(errorMessage));
      }
    }
  }

  private validateFirstName = () => {
    const { firstName } = this.mobxStore.userEditProfileStore.state;

    this.mobxStore.userEditProfileStore.clearFirstNameError();

    if (!firstName) {
      this.mobxStore.userEditProfileStore.setFirstNameError(new Error(i18n.t('basicErrors.REQUIRED_MESSAGE')));
    }
  }

  private validateLastName = () => {
    const { lastName } = this.mobxStore.userEditProfileStore.state;

    this.mobxStore.userEditProfileStore.clearLastNameError();

    if (!lastName) {
      this.mobxStore.userEditProfileStore.setLastNameError(new Error(i18n.t('basicErrors.REQUIRED_MESSAGE')));
    }
  }
}
