import styled from 'styled-components';

import { SmallTextFootnoteDescriptionSpan } from 'app-wrapper/view/guideline/Font';
import { OverlayWrap as BaseOverlay } from 'monetary/view/components/QuotasSearchBar/components/OriginOverlay/OriginOverlay.styled';

export const OverlayWrap = styled(BaseOverlay)`
  position: relative;
  min-width: 728px;
  max-width: 728px;
  box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05);
`;

export const OverlayBottom = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  height: 150px;
  width: 100%;
`;

export const Overlay = styled.div.attrs({
  'data-class': 'FQFormRequest',
})`
  width: 100%;

  input::placeholder {
    color: ${({ theme }) => theme.themesColors.secondaryDot45};
  }

  div.FreightQuoteFormRequest__Row__col_both__title {
    margin-top: 0;
    margin-bottom: 8px;
    box-shadow: none;
  }

  div.switchSoc > div {
    margin-left: 0 !important;
  }

  .FreightQuoteFormRequest__Row__Commodity__content__col_add__tooltip {
    min-width: 32px !important;
  }

  .ant-alert {
    margin-top: 8px;
    align-items: flex-start;

    & > :first-child {
      margin-top: 4px;
    }
  }

  .ant-alert-message {
    ${SmallTextFootnoteDescriptionSpan}
  }

  .FreightQuoteFormRequest__Row__Element__Type_required {
    color: #73819b;
    margin-left: 3px;
  }

  .text_placeholder {
    color: #73819b !important;
    font-size: 12px;
  }

  .FreightQuoteFormRequest__Row {

    &__Commodity, &__Containers {
      .ant-col:not(:first-child) {
        margin-left: auto;
      }

      .ant-select {
        width: 100%;
      }

      &__parent_content {
        &_item {
          margin-top: 8px;
        }
        &_item_first {
          margin-top: 0;
        }
      }

      &__content {
        &__col {
          &_add {
            position: relative;

            &_btn {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0 !important;
              min-height: 32px;

              background: none;
              color: ${({ theme }) => theme.themesColors.neutralBranding9};
              position: absolute;
              bottom: 0;
              border: 1px dashed ${({ theme }) => theme.themesColors.neutralBranding6};
              cursor: pointer;

              &:focus {
                box-shadow: none;
              }

              &:disabled {
                background-color: white;
                border: 1px dashed ${({ theme }) => theme.themesColors.neutralBranding6};
              }
            }
          }
        }

        &_title {
          margin-top: 0px;

          margin-bottom: 8px;
          font-size: 12px;

          &_icon {
            cursor: pointer;
            margin-left: 5px;

            svg {
              color: ${({ theme }) => theme.themesColors.secondaryDot45};
              width: 11px;
              height: 11px;
            }
          }
        }
      }
    }

    &__Containers {
      margin-top: 0px;

      .FreightQuoteFormRequest__Row__col_both__title {
        margin-top: 15px;
        padding-bottom: 9px;
        margin-bottom: 0;
      }
    }
  }

  .Switch {
    &__positive {
      background-color: ${({ theme }) => theme.themesColors.secondaryDot45};
    }
  }
`;
