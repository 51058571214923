import React, {
  FC,
  useCallback,
  ChangeEvent, useEffect, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LockOutlined from '@ant-design/icons/LockOutlined';
import IdcardOutlined from '@ant-design/icons/IdcardOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import MailOutlined from '@ant-design/icons/MailOutlined';

import { RouteNames } from 'app-wrapper/constants';
import { SkypaceDarkLogo } from 'app-wrapper/view/icons';
import { AlertBlock } from 'app-wrapper/view/components';

import { TooltipError } from 'user-management/view/components';

import { FieldErrorDTM } from 'app-wrapper/models/dtm';
import { PASSWORD_MIN_LENGTH } from 'authentication/consts/PasswordMinLength';
import {
  BottomMessage,
  LogoWrapper,
  SignUpButton,
  SignUpEmailInput,
  SignUpForm,
  SignUpMessage,
  SignUpTitle,
  SignUpWrapper,
  SignUpLinkWrapper,
  SignUpPasswordInput,
  AlertWrap,
} from './SignUp.styled';

interface ISignUpComponentProps {
  loading: boolean
  alertText?: string
  firstNameValue?: string
  firstNameError?: FieldErrorDTM
  lastNameValue?: string
  lastNameError?: FieldErrorDTM
  emailValue?: string
  emailError?: FieldErrorDTM
  isDisabledEmail: boolean
  passwordValue?: string
  passwordError?: FieldErrorDTM
  onFirstNameFieldChange: (firstName: string) => void
  onFirstNameBlur: () => void
  onLastNameFieldChange: (lastName: string) => void
  onLastNameBlur: () => void
  onEmailFieldChange: (email: string) => void
  onEmailBlur: () => void
  onPasswordFieldChange: (password: string) => void
  onPasswordBlur: () => void
  onSignUp: () => void
  onClear: () => void
}

export const SignUpComponent: FC<ISignUpComponentProps> = ({
  loading,
  alertText,
  firstNameValue,
  firstNameError,
  lastNameValue,
  lastNameError,
  emailValue,
  emailError,
  isDisabledEmail,
  passwordValue,
  passwordError,
  onFirstNameFieldChange,
  onFirstNameBlur,
  onLastNameFieldChange,
  onLastNameBlur,
  onEmailFieldChange,
  onEmailBlur,
  onPasswordFieldChange,
  onPasswordBlur,
  onSignUp,
  onClear,
}) => {
  const { t } = useTranslation();

  useEffect(() => onClear, []);

  const firstNameChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onFirstNameFieldChange(event.currentTarget.value);
    },
    [],
  );

  const firstNameBlurHandler = useCallback(
    () => {
      onFirstNameBlur();
    },
    [],
  );

  const lastNameChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onLastNameFieldChange(event.currentTarget.value);
    },
    [],
  );

  const lastNameBlurHandler = useCallback(
    () => {
      onLastNameBlur();
    },
    [],
  );

  const emailChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onEmailFieldChange(event.currentTarget.value);
    },
    [],
  );

  const emailBlurHandler = useCallback(
    () => {
      onEmailBlur();
    },
    [],
  );

  const passwordChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onPasswordFieldChange(event.currentTarget.value);
    },
    [],
  );

  const passwordBlurHandler = useCallback(
    () => {
      onPasswordBlur();
    },
    [],
  );

  const passwordResetEmailSendHandler = useCallback(
    () => {
      onSignUp();
    },
    [],
  );

  const isVisibleEmailError = useMemo(() => {
    const msg = emailError?.message !== t('basicerrors?.REQUIRED_MESSAGE');

    return !!(emailError?.message && msg);
  }, [emailError]);

  const isVisiblePasswordError = useMemo(() => {
    const msg = passwordError?.message !== t('basicerrors?.REQUIRED_MESSAGE');

    return !!(passwordError?.message && msg);
  }, [passwordError]);

  const disabledSubmit = !firstNameValue || !lastNameValue || !emailValue || !passwordValue || isVisibleEmailError || isVisiblePasswordError;

  return (
    <SignUpWrapper>
      <SignUpForm>
        <LogoWrapper>
          <SkypaceDarkLogo />
        </LogoWrapper>
        <SignUpTitle>
          {t('Empowering Global Trade Logistics')}
        </SignUpTitle>

        {alertText && (<AlertWrap><AlertBlock type="warning" message={alertText} /></AlertWrap>)}

        <SignUpEmailInput
          name="firstName"
          maxLength={50}
          placeholder={t('First Name')}
          prefix={<UserOutlined />}
          value={firstNameValue}
          error={firstNameError?.hasError}
          size="large"
          onChange={firstNameChangeHandler}
          onBlur={firstNameBlurHandler}
        />
        <SignUpEmailInput
          name="lastName"
          maxLength={50}
          placeholder={t('Last Name')}
          prefix={<IdcardOutlined />}
          value={lastNameValue}
          error={lastNameError?.hasError}
          size="large"
          onChange={lastNameChangeHandler}
          onBlur={lastNameBlurHandler}
        />
        <TooltipError
          message={emailError?.message || ''}
          visible={emailError?.isTooltipMessageVisible()}
        >
          <SignUpEmailInput
            name="email"
            maxLength={50}
            placeholder={t('Email')}
            prefix={<MailOutlined />}
            value={emailValue}
            error={emailError?.hasError}
            disabled={isDisabledEmail}
            size="large"
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
          />
        </TooltipError>
        <TooltipError
          message={passwordError?.message || ''}
          visible={passwordError?.isTooltipMessageVisible()}
        >
          <SignUpPasswordInput
            name="password"
            maxLength={50}
            placeholder={t('Create Password')}
            prefix={<LockOutlined />}
            value={passwordValue}
            error={passwordError?.hasError}
            size="large"
            onChange={passwordChangeHandler}
            onBlur={passwordBlurHandler}
          />
        </TooltipError>
        <SignUpMessage>
          {t('Password at least {{count}} characters long', { count: PASSWORD_MIN_LENGTH })}
        </SignUpMessage>

        <SignUpButton
          htmlType="submit"
          data-class="SignUp"
          size="large"
          onClick={passwordResetEmailSendHandler}
          loading={loading}
          disabled={disabledSubmit}
        >
          {t('Sign Up')}
        </SignUpButton>
        <SignUpLinkWrapper>
          {t('Do you have an account already? ')}
          <Link to={RouteNames.SIGN_IN()}>
            {t('Sign In')}
          </Link>
        </SignUpLinkWrapper>
      </SignUpForm>
      <BottomMessage />
    </SignUpWrapper>
  );
};
