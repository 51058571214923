import React from 'react';
import { useTranslation } from 'react-i18next';

import { SubPageContent } from 'app-wrapper/view/guideline';

import { Wrapper, SmallDivider } from '../styled';
import { GreenCheck, EditSection, StyledObject } from '../CommonStyled';

const LocationComponent = () => {
  const { t } = useTranslation();

  return (
    <SubPageContent>
      <GreenCheck title={t('Location')} isValid />
      <Wrapper>
        <EditSection onClick={() => {}} title={t('Empty pick-up')}>
          <StyledObject customKey={t('Terminal Name')} customValue="Yesen Terminal LLC. MTY" />
          <SmallDivider />
          <StyledObject customKey={t('Pass Through Code')} customValue="7846359555" />
          <SmallDivider />
          <StyledObject customKey={t('Address')} customValue="701 New Dock Street Berth 212-215, Islands, California, USA (US)" />
          <SmallDivider />
          <StyledObject customKey={t('Earliest CTO Full Drop Date')} customValue="30 Dec, 09:10" />
        </EditSection>
        <EditSection onClick={() => {}} title={t('Full drop-off')}>
          <StyledObject customKey={t('Terminal Name')} customValue="Yesen Terminal LLC. MTY" />
          <SmallDivider />
          <StyledObject customKey={t('Pass Through Code')} customValue="7846359555" />
          <SmallDivider />
          <StyledObject customKey={t('Address')} customValue="701 New Dock Street Berth 212-215, Islands, California, USA (US)" />
          <SmallDivider />
          <StyledObject customKey={t('Earliest CTO Full Drop Date')} customValue="30 Dec, 09:10" />
        </EditSection>
      </Wrapper>
    </SubPageContent>
  );
};

export { LocationComponent };
