import { instanceToPlain } from 'app-wrapper/utils';
import moment from 'moment';
import message from 'antd/es/message';
import i18n from 'app-wrapper/i18n/i18n';

import {
  CommandCenterGetCommandCenterGetNotificationsDTM, CommandCenterGetTasksDTM, DateDtm, NotificationTaskDTM,
} from 'app-wrapper/models/dtm';
import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { R as appR } from 'app-wrapper/repository';
import { loadUntil } from 'app-wrapper/utils/loadUntil';
import { BaseController, controller } from 'proto/BaseController';
import { LoadPlansUseCase } from 'shipment-operations/usecases';

import { R as userManagementR } from 'user-management/repository';
import { EOrganizationMemberRole } from 'user-management/constants';
import { UC as appUC } from 'app-wrapper/controllers';
import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';
import {
  EShippingPartyTypes,
  ContainerReeferTypes,
  ContainerUsualTypes,
  EShipmentConfirmationTypes,
  ShipmentStatusEnum,
  ShipmentDrawerContent,
  EShipmentCancellationType,
} from 'shipment-operations/constants';
import { BadInttraRequestError } from 'shipment-operations/models/errors';
import {
  BookingMissMatchesDTM,
  CargoDTM,
  ChargeDTM,
  CompanyDTM,
  ContainerDTM,
  ContainerSumDTM,
  ContainerSumWithTypeDTM,
  OverviewReferencesDTM,
  OverviewShippingPartiesDTM,
  ShipmentConfirmationDTM,
  ShipmentEventDTM,
  ShipmentPreviewDTM,
  ShipmentTrackerDTM,
  ShippingPartyDTM,
  ShippingPartyOverviewDTM,
  TransportationOverviewDTM,
  TransportPlanMissMatchesDTM,
  RollWarningInfoDTM,
  ShipmentAllStatsDTM,
  CarrierOverviewDTM,
  ReferencesOverviewDTM,
} from 'shipment-operations/models/dtm';
import { putShipmentDocumentsAdditionalDetailsResponse } from 'shipment-operations/models/contracts';
import { OverviewDashboardUseCase } from 'app-wrapper/usecases/OverviewDashboard.useCase';
import { ENotificationCriticality } from 'app-wrapper/constants';

@controller
export class OverviewController extends BaseController {
  public loadData = async (shipmentId: string) => {
    this.dispatch(R.actions.overview.setIsLoading(true));
    this.dispatch(appR.actions.overview.setShipmentByIdStats(ShipmentAllStatsDTM.createEmpty()));

    const waitPromises: Promise<unknown>[] = [
      this.loadCharges(shipmentId),
      this.loadStats(shipmentId),
      UC.shipment.loadShipmentStats(shipmentId),
      R.services.shipmentMissMatches.getTransportPlanMissMatches(shipmentId),
      R.services.shipmentMissMatches.getBookingMissMatches(shipmentId),
      R.services.cargo.getCargos(+shipmentId),
      R.services.shipmentContainers.getContainersList(shipmentId),
      this.loadContainers(shipmentId),
      R.services.shippingParties.getList(shipmentId),
      R.services.shipment.getConfirmations(shipmentId),
      R.services.inttra.getBookingOverview(shipmentId),
    ];

    const [
      , , , transportPlanRes, bookingMissMatchesRes, cargosRes, containersRes, , partiesRes,
      confirmationsRes, bookingOverviewRes,
    ] = await Promise.all(waitPromises);

    new OverviewDashboardUseCase(this).resetStatisticsTasksAndNotification();

    const transportPlanMissMatches: TransportPlanMissMatchesDTM | null = transportPlanRes as TransportPlanMissMatchesDTM | null;

    if (transportPlanMissMatches) {
      this.dispatch(R.actions.shipmentMissMatches.setTransportMissMatches(transportPlanMissMatches));
    }

    const bookingMissMatches: BookingMissMatchesDTM | null = bookingMissMatchesRes as BookingMissMatchesDTM | null;

    if (bookingMissMatches) {
      this.dispatch(R.actions.shipmentMissMatches.setBookingMissMatches(bookingMissMatches));
    }
    if (transportPlanMissMatches?.missMatches.length || bookingMissMatches?.containerMissMatches.length || bookingMissMatches?.cargoMissMatches.length) {
      this.dispatch(R.actions.shipmentChanges.setWithMismatches(true));
    } else {
      this.dispatch(R.actions.shipmentChanges.setWithMismatches(false));
    }

    const cargos: CargoDTM[] = cargosRes as CargoDTM[];

    this.dispatch(R.actions.overview.setCargos(cargos));

    const containers: ContainerDTM[] = containersRes as ContainerDTM[];

    this.dispatch(R.actions.overview.setContainersSumWithType(this.formatContainersToContainersSumWithTypes(containers)));

    const shippingPartyList: ShippingPartyDTM[] = partiesRes as ShippingPartyDTM[];

    if (!shippingPartyList) {
      this.dispatch(R.actions.overview.setIsLoading(false));
      return;
    }

    const shipperRaw = shippingPartyList.find(({ role }) => role === EShippingPartyTypes.HOUSE_SHIPPER);
    const consigneeRaw = shippingPartyList.find(({ role }) => role === EShippingPartyTypes.HOUSE_CONSIGNEE);
    const notifyPartyRaw = shippingPartyList.find(({ role }) => role === EShippingPartyTypes.HOUSE_NOTIFY_PARTY);
    const accountHolderRaw = shippingPartyList.find(({ role }) => role === EShippingPartyTypes.ACCOUNT_HOLDER);
    const forwarderAgentRaw = shippingPartyList.find(({ role }) => role === EShippingPartyTypes.FORWARDER_AGENT);
    const deviveryAgentRaw = shippingPartyList.find(({ role }) => role === EShippingPartyTypes.DELIVERY_AGENT);
    const oceanCarrierRaw = shippingPartyList.find(({ role }) => role === EShippingPartyTypes.OCEAN_CARRIER);

    const shipper = await this.getFullShippingParty(shipperRaw);
    const consignee = await this.getFullShippingParty(consigneeRaw);
    const notifyParty = await this.getFullShippingParty(notifyPartyRaw);
    const accountHolder = await this.getFullShippingParty(accountHolderRaw);
    const forwarderAgent = await this.getFullShippingParty(forwarderAgentRaw);
    const deliveryAgent = await this.getFullShippingParty(deviveryAgentRaw);
    const oceanCarrier = await this.getFullShippingParty(oceanCarrierRaw);

    const overviewShippingParties = OverviewShippingPartiesDTM.fromPlain({
      shipper: shipper ? instanceToPlain(shipper) : {
        references: [],
      },
      consignee: consignee ? instanceToPlain(consignee) : {
        references: [],
      },
      notifyParty: notifyParty ? instanceToPlain(notifyParty) : {
        references: [],
      },
      accountHolder: accountHolder ? instanceToPlain(accountHolder) : {
        references: [],
      },
      forwarderAgent: forwarderAgent
        ? instanceToPlain(forwarderAgent)
        : {
          references: [],
        },
      deliveryAgent: deliveryAgent ? instanceToPlain(deliveryAgent) : {
        references: [],
      },
      oceanCarrier: oceanCarrier ? instanceToPlain(oceanCarrier) : {
        references: [],
      },
    });

    this.dispatch(R.actions.overview.setShippingParties(overviewShippingParties));

    let shipment: ShipmentPreviewDTM | null = null;

    try {
      shipment = await R.services.shipment.getShipmentShortById(+shipmentId);
    } catch (e) {
      console.error('OverviewController.loadData: error');
    }

    if (shipment) {
      const { rollPlanId } = shipment;

      if (rollPlanId) {
        await this.getRollShipmentWarningInformation(String(rollPlanId));
      }

      this.dispatch(R.actions.rollShipmentWizard.setShipment(shipment));
      this.dispatch(R.actions.shipment.setShipment(shipment));
    }

    if (!shipment) {
      this.dispatch(R.actions.overview.setIsLoading(false));

      return;
    }

    const confirmations: ShipmentConfirmationDTM[] = confirmationsRes as ShipmentConfirmationDTM[];
    let isTermsAndConditionsConfirmed = false;
    let isNRAConfirmed = false;

    if (confirmations.length) {
      isTermsAndConditionsConfirmed = !!confirmations.find(({ type }) => type === EShipmentConfirmationTypes.TERMS_AND_CONDITIONS);
      isNRAConfirmed = !!confirmations.find(({ type }) => type === EShipmentConfirmationTypes.NEGOTIATED_RATE_ARRANGEMENT);
    }

    const organization = userManagementR.selectors.userOrganizationData.getUserOrganization(this.store.getState());

    if (shipment && organization) {
      const {
        organizationId,
        customerOrgId,
        confirmedAt,
        status,
      } = shipment;

      this.dispatch(R.actions.overview.setIsWaitingAcceptFromCustomer(
        organization.role === EOrganizationMemberRole.CUSTOMER && organizationId !== customerOrgId && Boolean(confirmedAt) && status !== ShipmentStatusEnum.CANCELLED && (!isTermsAndConditionsConfirmed || (!isNRAConfirmed && shipment.isUsShipmentOriginOrDestination())),
      ));
    }

    this.dispatch(R.actions.overview.setIsTermsAndConditionsConfirmed(isTermsAndConditionsConfirmed));
    this.dispatch(R.actions.overview.setIsNRAConfirmed(isNRAConfirmed));

    const bookingOverview: TransportationOverviewDTM | null = bookingOverviewRes as TransportationOverviewDTM | null;

    new OverviewDashboardUseCase(this).onLoadStatisticsTasks(shipmentId);
    new OverviewDashboardUseCase(this).onLoadStatisticsNotifications(shipmentId);
    await this.loadDashboard(shipmentId);

    if (!bookingOverview) {
      const { carrierReferenceNumber, oceanBookingId } = shipment;

      const overviewReferences = OverviewReferencesDTM.fromPlain({
        inttra: oceanBookingId || '',
        mbl: carrierReferenceNumber,
        contract: '',
        booking: carrierReferenceNumber,
      });

      this.dispatch(R.actions.overview.setReferences(overviewReferences));
      this.dispatch(R.actions.overview.setIsLoading(false));

      return;
    }

    const overviewReferences = OverviewReferencesDTM.fromPlain({
      inttra: bookingOverview.inttraReferenceNumber,
      mbl: bookingOverview.mblNumber || shipment.carrierReferenceNumber,
      contract: bookingOverview.contractNumber,
      booking: bookingOverview.carrierReference,
    });

    this.dispatch(R.actions.overview.setReferences(overviewReferences));
    this.dispatch(R.actions.overview.setIsLoading(false));
    this.dispatch(R.actions.shipmentChanges.setShouldRefresh(false));
    this.dispatch(R.actions.shipmentChanges.setShouldUpdateShipment(false));
  };

  public loadDashboard = async (shipmentId: string) => {
    let tasks: NotificationTaskDTM | null = null;
    let notifications: NotificationTaskDTM | null = null;
    const { email } = this.store.getState().auth;
    const assigneeUserEmails = email ? [email] : undefined;
    const { organization } = this.store.getState().userOrganizationData;
    const assigneeAudienceOrganizationIds = organization?.id ? [organization?.id] : undefined;

    try {
      tasks = await appR.services.CommandCenter.getTasks(CommandCenterGetTasksDTM.fromPlain({
        page: '0',
        sort: 'dueDate,desc',
        query: 'TODO',
        size: '3',
        criticality: [ENotificationCriticality.HIGH],
        shipmentId,
        assigneeUserEmails,
      }));
    } catch (e) {
      console.error('OverviewController: init load tasks');
    }

    if (tasks) {
      this.dispatch(appR.actions.overview.setTasks(tasks.content));
      this.dispatch(appR.actions.overview.setCountAllTasks(tasks.totalElements));
    }

    try {
      notifications = await appR.services.CommandCenter.getNotifications(CommandCenterGetCommandCenterGetNotificationsDTM.fromPlain({
        page: '0',
        sort: 'createdAt,desc',
        size: '3',
        shipmentId,
        assigneeAudienceOrganizationIds,
      }));
    } catch (e) {
      console.error('OverviewController: init load notifications');
    }

    if (notifications) {
      this.dispatch(appR.actions.overview.setNotifications(notifications.content.splice(0, 5)));
    }
  }

  public getRollShipmentWarningInformation = async (planId: string) => {
    let plans;

    try {
      plans = await R.services.shipmentPlans.getShipmentPlans(String(planId));
    } catch (e) {
      console.error('OverviewController Error: getPlans');
    }

    const shipment = R.selectors.shipment.getShipment(this.store.getState());

    if (plans && shipment) {
      const { origin, destination } = shipment;
      const [plan] = plans;
      const { transportations } = plan;
      const firstTransportation = transportations[0];
      const lastTransportation = transportations[transportations.length - 1];

      this.dispatch(R.actions.overview.setRollWarningInfo(RollWarningInfoDTM.fromPlain({
        shouldShowWarning: true,
        prevLocation: origin.address,
        nextLocation: destination.address,
        prevETD: origin.estimatedDate ? origin.estimatedDate.getFormatDMMMHHmmWithOffset() : '',
        prevETA: destination.estimatedDate ? destination.estimatedDate.getFormatDMMMHHmmWithOffset() : '',
        nextETD: firstTransportation && firstTransportation.schedule
          ? DateDtm.fromPlain({
            date: firstTransportation.schedule.departureTime,
            offset: moment.parseZone(firstTransportation.schedule.departureTime).utcOffset(),
          }).getFormatDMMMHHmmWithOffset()
          : '',
        nextETA: lastTransportation && lastTransportation.schedule
          ? DateDtm.fromPlain({
            date: lastTransportation.schedule.arrivalTime,
            offset: moment.parseZone(lastTransportation.schedule.arrivalTime).utcOffset(),
          }).getFormatDMMMHHmmWithOffset()
          : '',
      })));
    }
  };

  public loadCharges = async (shipmentId: string) => {
    let response: ChargeDTM[] | null;

    this.dispatch(R.actions.overview.setChargesLoading(true));
    try {
      response = await loadUntil(
        async () => R.services.shipmentCharges.getCharges(shipmentId),
        (conditionData: ChargeDTM[]) => conditionData.length !== 0,
      );
    } catch (e) {
      this.dispatch(R.actions.overview.setChargesError(true));
      return;
    }

    this.dispatch(R.actions.overview.setCharges(response));
  }

  public loadStats = async (shipmentId: string) => {
    let response: any;

    this.dispatch(R.actions.overview.setStatsLoading(true));
    try {
      response = await R.services.shipment.getShipmentStatsCustomer(shipmentId, 'PAYABLES');
    } catch (e) {
      this.dispatch(R.actions.overview.setStatsError(true));
      return;
    }
    this.dispatch(R.actions.overview.setStats(response));
  }

  public onChangeShipmentReference = (value: string) => {
    const validValue = value
      .replace(/[^\w]/g, '')
      .slice(0, 16);

    this.dispatch(R.actions.overview.setShipmentReference(validValue));

    this.onValidateShipmentReference();
  }

  public onValidateShipmentReference = () => {
    const reference = R.selectors.overview.getShipmentReference(this.store.getState());
    let isError = false;

    if (reference) {
      this.dispatch(R.actions.overview.setShipmentReferenceIsError(false));
    } else {
      this.dispatch(R.actions.overview.setShipmentReferenceIsError(true));
      isError = true;
    }

    return isError;
  }

  public onChangeIsOpenModalShipmentReference = (value: boolean) => {
    this.dispatch(R.actions.overview.setModalShipmentReference(value));

    const shipment = R.selectors.shipment.getShipment(this.store.getState());

    this.dispatch(R.actions.overview.setShipmentReference(shipment?.reference || ''));
    this.dispatch(R.actions.overview.setShipmentReferenceIsError(false));
  };

  public saveShipmentReference = async () => {
    if (this.onValidateShipmentReference()) {
      return;
    }

    const reference = R.selectors.overview.getShipmentReference(this.store.getState());
    const shipment = R.selectors.shipment.getShipment(this.store.getState());
    let additionalDetails: putShipmentDocumentsAdditionalDetailsResponse | null;

    if (!shipment?.id) {
      return;
    }

    try {
      additionalDetails = await R.services.shipmentDocument.getAdditionalDetails(+shipment?.id);

      if (additionalDetails) {
        await R.services.shipmentDocument.putAdditionalDetails(+shipment?.id, {
          ...additionalDetails,
          references: additionalDetails.references.map((referenceItem) => ({
            value: reference || referenceItem.value,
            id: +referenceItem.id,
          })),
        });
      } else if (!additionalDetails && reference) {
        await R.services.shipmentDocument.postAdditionalDetails(+shipment?.id, {
          id: null,
          references: [{
            value: reference,
            id: null,
          }],
        });
      } else {
        throw new Error('Shipment Reference updated failed');
      }
    } finally {
      message.success(i18n.t('Shipment Reference is updated'));

      this.dispatch(R.actions.overview.setModalShipmentReference(false));

      this.dispatch(R.actions.rollShipmentWizard.setShipment(ShipmentPreviewDTM.fromPlain({
        ...shipment,
        reference: reference || shipment.reference,
      })));
      this.dispatch(R.actions.shipment.setShipment(ShipmentPreviewDTM.fromPlain({
        ...shipment,
        reference: reference || shipment.reference,
      })));
    }
  };

  public setIsNRAConfirmed = (value: boolean) => {
    this.dispatch(R.actions.overview.setIsNRAConfirmed(value));
  };

  public setIsTermsAndConditionsConfirmed = (value: boolean) => {
    this.dispatch(R.actions.overview.setIsTermsAndConditionsConfirmed(value));
  };

  public acceptCustomerBookingAgreements = async (shipmentId: string) => {
    const shipment = R.selectors.shipment.getShipment(this.store.getState());

    if (shipment?.isUsShipmentOriginOrDestination()) {
      try {
        await R.services.shipment.postConfirmations(shipmentId, ShipmentConfirmationDTM.fromPlain({
          type: EShipmentConfirmationTypes.NEGOTIATED_RATE_ARRANGEMENT,
        }));
      } catch (e) {
        console.error('Overview CONTROLLER: confirmNRA');

        return;
      }
    }

    try {
      await R.services.shipment.postConfirmations(shipmentId, ShipmentConfirmationDTM.fromPlain({
        type: EShipmentConfirmationTypes.TERMS_AND_CONDITIONS,
      }));
    } catch (e) {
      console.error('Overview CONTROLLER: confirmTermsAndConditions');

      return;
    }

    this.dispatch(R.actions.overview.setIsWaitingAcceptFromCustomer(false));
    this.dispatch(R.actions.overview.setIsCustomerBookingDrawerOpened(false));
    this.navigate(0);
  };

  public resubmitBooking = async (shipmentId: string) => {
    await R.services.shipment.resubmitShipmentBooking(shipmentId);
  };

  public openTermsAgreementDrawer = () => {
    this.dispatch(R.actions.overview.setIsCustomerBookingDrawerOpened(true));
  }

  public closeTermsAgreementDrawer = () => {
    this.dispatch(R.actions.overview.setIsCustomerBookingDrawerOpened(false));
  }

  loadContainers = async (shipmentId?: string) => {
    if (!shipmentId) {
      return;
    }

    let response: ShipmentTrackerDTM[];
    this.dispatch(R.actions.shipmentTracker.setError(false));
    this.dispatch(R.actions.shipmentTracker.setLoading(true));
    this.dispatch(R.actions.shipmentTrackerRoutes.setLoading(true));
    this.dispatch(R.actions.rollShipmentWizard.setIsLoading(true));

    try {
      response = await R.services.shipmentTracker.getContainers(shipmentId);
    } catch {
      this.dispatch(R.actions.shipmentTracker.setError(true));
      return;
    }
    this.dispatch(R.actions.shipmentTracker.setContainers(response));
    this.dispatch(R.actions.shipmentTrackerRoutes.setContainers(response));

    const events = response
      .reduce((start: ShipmentEventDTM[], current) => [...start, ...current.container.events], [])
      .filter(({ actual }) => !!actual);

    this.dispatch(R.actions.rollShipmentWizard.setEvents(events));
    this.dispatch(R.actions.rollShipmentWizard.setIsLoading(false));

    if (response.length) {
      const planIds = response.map((plan) => plan.planId);
      await new LoadPlansUseCase(this).loadPlans(planIds, true);
    } else {
      this.dispatch(R.actions.shipmentTrackerRoutes.setSchedules([]));
    }
  }

  public setCorrectionsText = (text: string) => {
    this.dispatch(R.actions.shipmentMissMatches.setCorrectionsText(text));
  }

  public acceptBookingMissMatches = async (shipmentId: string) => {
    const bookingMissMatchId = R.selectors.shipmentMissMatches.getBookingId(this.store.getState());

    try {
      await R.services.shipmentMissMatches.acceptBookingPlanMissMatches(String(shipmentId), String(bookingMissMatchId));
    } catch (e) {
      console.error('OverviewController: error at acceptBookingMissMatches');
    }

    this.dispatch(R.actions.shipmentMissMatches.clearBookingMissMatches());
  };

  public acceptTransportPlanMissMatches = async (shipmentId: string) => {
    const transportPlanMissMatchId = R.selectors.shipmentMissMatches.getTransportPlanId(this.store.getState());

    try {
      await R.services.shipmentMissMatches.acceptTransportPlanMissMatches(String(shipmentId), String(transportPlanMissMatchId));
    } catch (e) {
      console.error('OverviewController: error at acceptTransportPlanMissMatches');
    }

    this.dispatch(R.actions.shipmentMissMatches.clearTransportMissMatches());
  }

  public acceptBookingMissMatchCorrections = async (shipmentId: string, corrections: string) => {
    try {
      await R.services.inttra.submitBooking(shipmentId, corrections);
    } catch (error) {
      if (error instanceof BadInttraRequestError) {
        this.dispatch(R.actions.overview.setBookingRequestSuccessful(false));
        this.dispatch(R.actions.overview.setServerError(error));
      }

      return;
    }

    this.dispatch(R.actions.shipmentMissMatches.clearBookingMissMatches());
    this.dispatch(R.actions.overview.setBookingRequestSuccessful(true));
    this.dispatch(R.actions.overview.setServerError(undefined));
  }

  public submitBooking = async (shipmentId: string) => {
    try {
      await R.services.inttra.submitBooking(shipmentId);
    } catch (error) {
      if (error instanceof BadInttraRequestError) {
        this.dispatch(R.actions.overview.setBookingRequestSuccessful(false));
        this.dispatch(R.actions.overview.setServerError(error));
      }
      return;
    }
    this.dispatch(R.actions.overview.setBookingRequestSuccessful(true));
    this.dispatch(R.actions.overview.setServerError(undefined));
  };

  public setMBLNumberToEdit = (mblNumber: string) => {
    this.dispatch(R.actions.overview.setMBLNumberToEdit(mblNumber));
  }

  public setCarrierReferenceNumberToEdit = (carrierReferenceNumber: string) => {
    this.dispatch(R.actions.overview.setCarrierReferenceNumberToEdit(carrierReferenceNumber));
  }

  public saveMBLNumber = async (shipmentId: string) => {
    this.dispatch(R.actions.overview.setIsModalPending(true));

    const mblNumber = R.selectors.overview.getMBLNumberToEdit(this.store.getState());

    const bookingDetails = await R.services.bookingDetails.getBookingDetails(shipmentId);

    if (bookingDetails) {
      bookingDetails.mblReferenceNumber = mblNumber;

      await R.services.bookingDetails.editBookingDetails(shipmentId, bookingDetails);
      await this.updateBookingOverviewInformation(shipmentId);
    }

    this.dispatch(R.actions.overview.setIsModalPending(false));
    appUC.drawer.closeDrawer();
  }

  public saveCarrierReferenceNumber = async (shipmentId: string) => {
    this.dispatch(R.actions.overview.setIsModalPending(true));

    const carrierReference = R.selectors.overview.getCarrierReferenceNumberToEdit(this.store.getState());

    const bookingDetails = await R.services.bookingDetails.getBookingDetails(shipmentId);

    if (bookingDetails) {
      bookingDetails.carrierReferenceNumber = carrierReference;

      await R.services.bookingDetails.editBookingDetails(shipmentId, bookingDetails);
      await this.updateBookingOverviewInformation(shipmentId);
    }

    this.dispatch(R.actions.overview.setIsModalPending(false));
    appUC.drawer.closeDrawer();
  }

  public updateBookingOverviewInformation = async (shipmentId: string) => {
    const overview = await R.services.inttra.getBookingOverview(shipmentId);

    if (overview) {
      const overviewReferences = OverviewReferencesDTM.fromPlain({
        inttra: overview.inttraReferenceNumber,
        mbl: overview.mblNumber,
        contract: overview.contractNumber,
        booking: overview.carrierReference,
      });

      this.dispatch(R.actions.overview.setReferences(overviewReferences));
      this.dispatch(R.actions.transportationOverview.setBookingOverviewData({
        carrierName: overview.carrierName,
        carrierReference: overview.carrierReference,
        termsAndConditions: overview.termsAndConditions,
        comments: overview.comments,
        contactName: overview.contactName,
        phoneList: overview.phoneList,
        emailList: overview.emailList,
        mblNumber: overview.mblNumber,
        inttraReferenceNumber: overview.inttraReferenceNumber,
        contractNumber: overview.contractNumber,
        agentsReference: overview.agentsReference,
      } as CarrierOverviewDTM & ReferencesOverviewDTM));
    }
  }

  public cancelShipment = async (shipmentId: string) => {
    const cancellationType = R.selectors.overview.getShipmentCancellationType(this.store.getState());
    const cancellationMessage = R.selectors.overview.getShipmentCancellationMessage(this.store.getState());

    if (!cancellationType) {
      return;
    }

    this.dispatch(R.actions.overview.setIsCancelShipmentPending(true));

    try {
      await R.services.inttra.cancelBooking(shipmentId, cancellationType, cancellationMessage);
    } catch (error) {
      if (error instanceof BadInttraRequestError) {
        this.dispatch(R.actions.overview.setServerError(error));
      }

      this.closeCancelShipmentModal();

      return;
    }

    this.dispatch(R.actions.overview.setServerError(undefined));
    this.dispatch(R.actions.shipmentMissMatches.clearTransportMissMatches());

    let shipment: ShipmentPreviewDTM | null;

    try {
      shipment = await R.services.shipment.getShipmentShortById(+shipmentId);
    } catch (e) {
      this.closeCancelShipmentModal();

      console.error(e);
      return;
    }

    if (!shipment) {
      this.closeCancelShipmentModal();

      return;
    }

    this.closeCancelShipmentModal();
    this.dispatch(R.actions.overview.setIsWaitingAcceptFromCustomer(false));
    this.dispatch(R.actions.shipment.setShipment(shipment));
  };

  private formatContainersToContainersSumWithTypes = (containers: ContainerDTM[]) => [
    ContainerSumWithTypeDTM.fromPlain({
      type: ContainerUsualTypes['22G0'],
      ...this.reduceContainersPropertiesToContainerSum(containers.filter(({ type }) => type === ContainerUsualTypes['22G0'])),
    }),
    ContainerSumWithTypeDTM.fromPlain({
      type: ContainerUsualTypes['42G0'],
      ...this.reduceContainersPropertiesToContainerSum(containers.filter(({ type }) => type === ContainerUsualTypes['42G0'])),
    }),
    ContainerSumWithTypeDTM.fromPlain({
      type: ContainerUsualTypes['45G0'],
      ...this.reduceContainersPropertiesToContainerSum(containers.filter(({ type }) => type === ContainerUsualTypes['45G0'])),
    }),
    ContainerSumWithTypeDTM.fromPlain({
      type: ContainerUsualTypes.L5G0,
      ...this.reduceContainersPropertiesToContainerSum(containers.filter(({ type }) => type === ContainerUsualTypes.L5G0)),
    }),
    ContainerSumWithTypeDTM.fromPlain({
      type: ContainerReeferTypes['22R0'],
      ...this.reduceContainersPropertiesToContainerSum(containers.filter(({ type }) => type === ContainerReeferTypes['22R0'])),
    }),
    ContainerSumWithTypeDTM.fromPlain({
      type: ContainerReeferTypes['25R1'],
      ...this.reduceContainersPropertiesToContainerSum(containers.filter(({ type }) => type === ContainerReeferTypes['25R1'])),
    }),
    ContainerSumWithTypeDTM.fromPlain({
      type: ContainerReeferTypes['42R0'],
      ...this.reduceContainersPropertiesToContainerSum(containers.filter(({ type }) => type === ContainerReeferTypes['42R0'])),
    }),
    ContainerSumWithTypeDTM.fromPlain({
      type: ContainerReeferTypes['45R1'],
      ...this.reduceContainersPropertiesToContainerSum(containers.filter(({ type }) => type === ContainerReeferTypes['45R1'])),
    }),
    ContainerSumWithTypeDTM.fromPlain({
      type: ContainerReeferTypes.L5R1,
      ...this.reduceContainersPropertiesToContainerSum(containers.filter(({ type }) => type === ContainerReeferTypes.L5R1)),
    }),
  ].filter(({ containersNumber }) => containersNumber);

  private reduceContainersPropertiesToContainerSum = (containers: ContainerDTM[]): ContainerSumDTM => {
    const cargos = containers.reduce((prev, next) => [...prev, ...next.cargoItems], [] as ContainerDTM['cargoItems']);

    return cargos.reduce((prev, next) => (ContainerSumDTM.fromPlain({
      volume: prev.volume + (Number(next.volume) || 0),
      weight: prev.weight + (Number(next.weight) || 0),
      packagesNumber: prev.packagesNumber + (Number(next.packagesNumber) || 0),
      value: prev.value + (Number(next.value) || 0),
      containersNumber: prev.containersNumber,
    })), ContainerSumDTM.fromPlain({
      volume: 0,
      weight: 0,
      packagesNumber: 0,
      value: 0,
      containersNumber: containers.length,
    }));
  }

  public closeAlert = () => {
    this.dispatch(R.actions.overview.resetAlert());
  }

  public reset = () => {
    apiWorker.abortAllRequests();
    this.dispatch(R.actions.overview.reset());
    this.dispatch(R.actions.shipmentTrackerRoutes.clear());
  };

  public openDrawer = (type: ShipmentDrawerContent) => {
    this.dispatch(R.actions.shipmentDrawer.openDrawer(type));
  }

  public closeDrawer = () => {
    this.dispatch(R.actions.shipmentDrawer.closeDrawer());
  }

  public openCancelShipmentModal = () => {
    this.dispatch(R.actions.overview.setIsCancelShipmentModalOpened(true));
  }

  public setShipmentId = (shipmentId: string) => {
    this.dispatch(R.actions.overview.setShipmentId(shipmentId));
  }

  public closeCancelShipmentModal = () => {
    this.dispatch(R.actions.overview.setIsCancelShipmentModalOpened(false));
    this.dispatch(R.actions.overview.clearCancelShipmentModalData());
  }

  public setShipmentCancellationType = (type?: EShipmentCancellationType) => {
    this.dispatch(R.actions.overview.setShipmentCancellationType(type));
  }

  public setShipmentCancellationMessage = (cancellationMessage: string) => {
    this.dispatch(R.actions.overview.setShipmentCancellationMessage(cancellationMessage));
  }

  public setManualReferenceNumber = (value: string) => {
    this.dispatch(R.actions.overview.setManualReferenceNumber(value));
  }

  public confirmManualReferenceNumber = async (shipmentId: string) => {
    this.dispatch(R.actions.overview.setIsManualReferenceNumberDrawerPending(true));
    const manualReferenceNumber = R.selectors.overview.getManualReferenceNumber(this.store.getState());

    await R.services.inttra.manualConfirmBookingWithOnlyCarrierReferenceNumber(shipmentId, manualReferenceNumber);

    const shipment = await R.services.shipment.getShipmentShortById(+shipmentId);

    this.dispatch(R.actions.shipment.setShipment(shipment));
    this.dispatch(R.actions.overview.setIsManualReferenceNumberDrawerPending(false));

    appUC.drawer.closeDrawer();
  }

  private getFullShippingParty = async (shippingParty?: ShippingPartyDTM) => {
    if (!shippingParty || !shippingParty.company) {
      return null;
    }

    const company: CompanyDTM | null = shippingParty.company || null;
    const address = shippingParty.address || null;
    const contact = shippingParty.contact || null;

    const shippingPartyOverview = ShippingPartyOverviewDTM.fromPlain({
      companyName: company?.name,
      addressLine: [
        address?.address1,
        address?.address2,
        address?.city,
        address?.state,
        address?.postalCode,
        address?.country,
      ].filter((v) => !!v).join(', '),
      address: address ? {
        ...instanceToPlain(address),
        id: 0,
      } : undefined,
      contactPerson: contact?.fullName,
      email: contact?.email,
      phone: contact?.phone,
      additionalPhone: contact?.phone2,
      taxId: company?.taxId,
      references: shippingParty.references,
      scac: company?.scac,
    });

    if (!shippingPartyOverview.isValid()) {
      console.error(shippingPartyOverview.validate());
    }

    return shippingPartyOverview;
  };
}
