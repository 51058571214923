import React, { FC } from 'react';

import { RFQItemDetailRoutingComponent } from 'monetary/view/components/RFQResponse/RFQItemDetailRouting';
import { DetailRoutingDTM } from 'monetary/models/dtm';

interface IRoutingDetailsComponentProps {
  origin: DetailRoutingDTM[]
  freight: DetailRoutingDTM[]
  destination: DetailRoutingDTM[]
}

export const RoutingDetailsComponent: FC<IRoutingDetailsComponentProps> = ({
  origin,
  freight,
  destination,
}) => (
  <RFQItemDetailRoutingComponent
    origin={origin}
    freight={freight}
    destination={destination}
  />
);
