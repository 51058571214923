import React, { FC, useCallback } from 'react';

import { InputWithError } from 'app-wrapper/view/components/InputWithError/InputWithError.component';
import { formatPhone } from 'app-wrapper/utils';
import { MAX_LENGTH_20 } from 'app-wrapper/constants';

interface IAdditionalPhoneInputComponentProps {
  additionalPhone?: string
  setAdditionalPhone: (phone: string) => void;
  errorMessage?: string;
  disabled: boolean
}

export const AdditionalPhoneInputComponent: FC<IAdditionalPhoneInputComponentProps> = ({
  additionalPhone,
  setAdditionalPhone,
  errorMessage,
  disabled,
}) => {
  const handleChange = useCallback((e) => {
    setAdditionalPhone(formatPhone(e.target.value));
  }, [setAdditionalPhone]);

  return (
    <InputWithError
      errorMessage={errorMessage}
      error={Boolean(additionalPhone?.length && !!errorMessage)}
      value={additionalPhone}
      onChange={handleChange}
      maxLength={MAX_LENGTH_20}
      disabled={disabled}
    />
  );
};
