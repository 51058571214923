import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { ShipmentBillingTabsComponent } from './ShipmentBillingTabs.component';

const ShipmentBillingTabsContainer: FC = memo(() => {
  const permissions = useSelector(R.selectors.shipment.getShipmentChargesPermissions);

  return (
    <ShipmentBillingTabsComponent
      permissions={permissions}
    />
  );
});

export {
  ShipmentBillingTabsContainer as ShipmentBillingTabs,
};
