import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';
import Divider from 'antd/es/divider';

import { hasReadAccess, hasAccess } from 'app-wrapper/utils';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';

import {
  ReferenceContainer,
  ReferenceLabel,
  ReferencesContainer,
  ReferencesLayout,
  Title,
} from './ShipmentOverviewReferences.styled';

interface IShipmentOverviewReferencesProps {
  isLoading: boolean
  shipmentReference?: string
  inttraReference?: string
  mblReference?: string
  contractReference?: string
  bookingReference?: string
  mblAndCarrierReferencesAvailability?: PermissionAttributePolicy
  inttraReferenceAvailability?: PermissionAttributePolicy
  contractAvailability?: PermissionAttributePolicy
  onChangeIsOpenModalShipmentReference: (value: boolean) => void
  openEditMBLNumberModal: () => void;
  openEditCarrierReferenceModal: () => void;
}

export const ShipmentOverviewReferencesComponent: FC<IShipmentOverviewReferencesProps> = ({
  isLoading,
  shipmentReference,
  inttraReference,
  mblReference,
  contractReference,
  bookingReference,
  mblAndCarrierReferencesAvailability,
  inttraReferenceAvailability,
  contractAvailability,
  onChangeIsOpenModalShipmentReference,
  openEditMBLNumberModal,
  openEditCarrierReferenceModal,
}) => {
  const { t } = useTranslation();

  const onChangeIsOpenModalShipmentReferenceHandler = useCallback(
    () => {
      onChangeIsOpenModalShipmentReference(true);
    },
    [onChangeIsOpenModalShipmentReference],
  );

  const onEditIsOpenModalShipmentReferenceHandler = useCallback(
    () => '',
    [],
  );

  const isAbleToViewInttraReference = useMemo(() => hasReadAccess(inttraReferenceAvailability), [inttraReferenceAvailability]);
  const isAbleToViewContractReference = useMemo(() => hasReadAccess(contractAvailability), [contractAvailability]);
  const isAbleToEditMBLAndCarrier = useMemo(() => hasAccess(mblAndCarrierReferencesAvailability, PermissionAttributePolicy.WRITE), [mblAndCarrierReferencesAvailability]);

  if (isLoading) {
    return (
      <SkeletonSection height="156px" />
    );
  }

  return (
    <ReferencesLayout>
      <Title>
        {t('References')}
      </Title>
      <Divider dashed style={{ margin: 0, marginBottom: '8px' }} />
      <ReferencesContainer>
        <ReferenceContainer data-class="ShipmentOverview_ReferenceContainer_ShipmentReference">
          <ReferenceLabel>{t('Shipment Reference')}</ReferenceLabel>
          <Typography.Text
            editable={shipmentReference ? {
              editing: false,
              onChange: onEditIsOpenModalShipmentReferenceHandler,
              onCancel: onEditIsOpenModalShipmentReferenceHandler,
              onStart: onChangeIsOpenModalShipmentReferenceHandler,
            } : {
              tooltip: t('Add'),
              onChange: onEditIsOpenModalShipmentReferenceHandler,
              onCancel: onEditIsOpenModalShipmentReferenceHandler,
              onStart: onChangeIsOpenModalShipmentReferenceHandler,
            }}
            copyable={!!shipmentReference}
          >{shipmentReference || ''}
          </Typography.Text>
        </ReferenceContainer>

        {isAbleToViewInttraReference ? (
          <ReferenceContainer>
            <ReferenceLabel>{t('Intrra Reference Number')}</ReferenceLabel>
            <Typography.Text copyable={!!inttraReference}>{inttraReference || '-'}</Typography.Text>
          </ReferenceContainer>
        ) : null}

        <ReferenceContainer data-testid="mbl-number-reference-container">
          <ReferenceLabel>{t('MBL Number')}</ReferenceLabel>
          <Typography.Text
            copyable={!!mblReference}
            editable={isAbleToEditMBLAndCarrier ? {
              tooltip: t('Edit'),
              onStart: openEditMBLNumberModal,
            } : false}
          >
            {mblReference || '-'}
          </Typography.Text>
        </ReferenceContainer>

        {isAbleToViewContractReference ? (
          <ReferenceContainer>
            <ReferenceLabel>{t('Contract Number')}</ReferenceLabel>
            <Typography.Text copyable={!!contractReference}>{contractReference || '-'}</Typography.Text>
          </ReferenceContainer>
        ) : null}

        <ReferenceContainer data-testid="booking-number-reference-container">
          <ReferenceLabel>{t('Booking Number')}</ReferenceLabel>
          <Typography.Text
            copyable={!!bookingReference}
            editable={isAbleToEditMBLAndCarrier ? {
              tooltip: t('Edit'),
              onStart: openEditCarrierReferenceModal,
            } : false}
          >
            {bookingReference || '-'}
          </Typography.Text>
        </ReferenceContainer>
      </ReferencesContainer>
    </ReferencesLayout>
  );
};
