import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RcFile, UploadProps } from 'antd/es/upload/interface';
import AntdUploadExports from 'antd/es/upload';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import UploadOutlined from '@ant-design/icons/UploadOutlined';

import { showWarning } from 'app-wrapper/utils/showWarning';
import { addPrefix } from 'app-wrapper/utils';
import { ContainerDocumentDTM } from 'shipment-operations/models/dtm';

import { Upload, Button } from './UploadDocument.styled';

const MAX_FILE_SIZE = 1024 * 1024 * 10; // 10MB
const { LIST_IGNORE } = AntdUploadExports;

interface DocumentUploadComponentProps {
  authToken: string
  shipmentId: string;
  containerDocument: ContainerDocumentDTM | null
  setContainerDocument: (containerId: string, document: ContainerDocumentDTM | null) => void
  downloadDocument: (shipmentId: string, containerId: string) => void
  documentType: string
  containerId: string;
  error: boolean;
  disabled?: boolean;
}

// TODO Refactoring request by @AliaksandrKatovich FRTUN-2166
export const DocumentUploadComponent: FC<DocumentUploadComponentProps> = ({
  authToken,
  containerDocument,
  setContainerDocument,
  downloadDocument,
  shipmentId,
  documentType,
  containerId,
  error,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const setDocument = useCallback((value: ContainerDocumentDTM[]) => {
    setContainerDocument(containerId, value[0] || null);
  }, [setContainerDocument, containerId]);

  const downloadContainerDocument = useCallback(() => downloadDocument(shipmentId, containerId), [downloadDocument, shipmentId, containerId]);

  const handleBeforeUpload = useCallback((file: RcFile) => {
    const isAcceptableSize = file.size < MAX_FILE_SIZE;
    if (!isAcceptableSize) {
      showWarning({ message: t('File is too large'), duration: 4 });
    }
    return isAcceptableSize || LIST_IGNORE;
  }, []);

  const handleChange: UploadProps['onChange'] = useCallback(({ file }) => {
    if (file.status === 'removed') {
      setDocument([]);
      return;
    }

    if (file.response) {
      file.url = '/';
    }

    if (file.response && file.status === 'error') {
      file.error = new Error(file.response?.message);
      file.response = file.response.message ? file.response.message : {};
    }

    setDocument([ContainerDocumentDTM.fromPlain({
      name: file.name,
      url: file.url,
      uid: file.uid,
      response: file.response,
      status: file.status,
    })]);
  }, [setDocument]);

  const handleRemove: UploadProps['onRemove'] = useCallback(() => {
    setDocument([]);
  }, [setDocument]);

  const handlePreview: UploadProps['onPreview'] = useCallback((file) => {
    if (!file.status) {
      return;
    }

    downloadContainerDocument();
  }, [downloadContainerDocument]);

  const postUrl = addPrefix(`/shipment-service/api/v1/shipments/${shipmentId}/documents?type=${documentType}`);

  return (
    <Upload
      maxCount={1}
      multiple={false}
      showUploadList={{ showRemoveIcon: true, removeIcon: <DeleteOutlined /> }}
      beforeUpload={handleBeforeUpload}
      action={postUrl}
      headers={{ Authorization: authToken }}
      fileList={containerDocument ? [containerDocument] : []}
      onPreview={handlePreview}
      onChange={handleChange}
      onRemove={handleRemove}
      data-testid={`seaworthy-document-upload-${containerId}`}
      disabled={disabled}
    >
      <Button
        disabled={disabled}
        icon={<UploadOutlined />}
        type="dashed"
        hasError={error}
        data-testid={`seaworthy-document-upload-button-${containerId}`}
      >
        {t('Upload document')}
      </Button>
    </Upload>
  );
};
