import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useIsExportOrImport } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { HeaderComponent } from 'shipment-operations/view/components/ActiveCharge';
import { ShipmentDrawerContent } from 'shipment-operations/constants';

const HeaderContainer = () => {
  const { isExportOrImport } = useIsExportOrImport();
  const applyTo = useSelector(R.selectors.shipmentActiveCharge.getActiveChargeApplyTo);
  const measureBy = useSelector(R.selectors.shipmentActiveCharge.getActiveChargeMeasureBy);
  const designation = useSelector(R.selectors.shipmentActiveCharge.getActiveChargeDesignation);
  const currency = useSelector(R.selectors.shipmentActiveCharge.getActiveChargeCurrency);
  const dropdownCharges = useSelector(R.selectors.shipmentActiveCharge.getChargesForDropdown);
  const dropdownChargesDrayage = useSelector(R.selectors.shipmentActiveCharge.getChargesForDrayage);
  const container = useSelector(R.selectors.shipmentActiveCharge.getActiveChargeSavedContainer);
  const companies = useSelector(R.selectors.shipmentActiveCharge.getCompaniesForSelect);
  const selectedChargeTitle = useSelector(R.selectors.shipmentActiveCharge.getActiveChargeSelectedChargeTitle);
  const chargeError = useSelector(R.selectors.shipmentActiveCharge.getActiveChargeErrorCharge);
  const containersTree = useSelector(R.selectors.shipmentActiveCharge.preparedTreeData);
  const containersError = useSelector(R.selectors.shipmentActiveCharge.getActiveChargeSelectedContainersError);
  const errors = useSelector(R.selectors.shipmentActiveCharge.getActiveChargeErrors);
  const containerType = useSelector(R.selectors.shipmentActiveCharge.getActiveChargeSelectedContainerType);
  const isExport = useSelector(R.selectors.shipmentActiveCharge.getIsExportTradeType);
  const selectedContainers = useSelector(R.selectors.shipmentActiveCharge.getActiveChargeSelectedContainers);
  const vendorDrayage = useSelector(R.selectors.shipmentActiveCharge.getSelectedContainersVendor);

  const mode = useSelector(R.selectors.shipmentDrawer.getContentType);

  const selectedCustomerCompany = useSelector(R.selectors.shipmentActiveCharge.getSelectedCustomerCompany);
  const selectedVendorCompany = useSelector(R.selectors.shipmentActiveCharge.getSelectedVendorCompany);

  const isDisableDesignation = useSelector(R.selectors.shipmentActiveCharge.getActiveChargeDisableDesignation);

  const isOriginPartner = useSelector(R.selectors.shipment.getIsOriginPartner);
  const isDestinationPartner = useSelector(R.selectors.shipment.getIsDestinationPartner);
  const charges = useSelector(R.selectors.shipmentTransportationCharges.getCharges);
  const chargeId = useSelector(R.selectors.shipmentActiveCharge.getActiveChargeId);

  const vendorCompanyValue = useMemo(() => charges?.find((charge) => charge.id === chargeId)?.creditor?.name, [charges, chargeId]);

  return (
    <HeaderComponent
      applyTo={applyTo}
      measureBy={measureBy}
      designation={designation}
      onChangeDropDown={UC.shipmentActiveCharge.setActiveChargeValue}
      currency={currency}
      dropdownCharges={isExportOrImport ? dropdownChargesDrayage : dropdownCharges}
      setCharge={UC.shipmentActiveCharge.setActiveChargeSelectedCharge}
      selectedChargeTitle={selectedChargeTitle}
      chargeError={chargeError}
      containersTree={containersTree}
      selectContainer={UC.shipmentActiveCharge.setActiveChargeContainer}
      containersError={containersError}
      errors={errors}
      mode={mode}
      container={container}
      containerType={containerType}
      modeType={ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_ADD_CHARGE}
      companies={companies}
      customerValue={selectedCustomerCompany?.name}
      vendorValue={(vendorCompanyValue || selectedVendorCompany?.name)}
      vendorDrayage={vendorDrayage}
      isDisableDesignation={isDisableDesignation}
      isExport={isExport}
      isOriginPartner={isOriginPartner}
      isDestinationPartner={isDestinationPartner}
      selectedContainers={selectedContainers}
    />
  );
};

export { HeaderContainer as Header };
