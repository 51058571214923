import { IFreightQuotaState } from 'monetary/models/states';
import { FreightFromStateDTM } from 'monetary/models/dtm';
import { freightLoadType } from 'monetary/constants';

export const FreightQuoteInitialState: IFreightQuotaState = {
  defaultState: {
    loadType: freightLoadType.FCL,
    form: FreightFromStateDTM.createEmpty(),
  },
  currentState: {
    isUpdate: false,
    form: FreightFromStateDTM.createEmpty(),
  },
  originAddressPromiseReject: undefined,
  destinationAddressPromiseReject: undefined,
  isSelfServiceRequest: false,
  currentOrgRelatedAdmin: null,
  accountHolderPricingDepartment: null,
  additionalServicesRatesCharges: [],
  quotaLoadingSteps: {
    isLoadingCompleteStep1: false,
    isLoadingCompleteStep2: false,
    isLoadingCompleteStep3: false,
    isLoadingCompleteStep4: false,
  },
  filters: {},
  quotas: {},
};
