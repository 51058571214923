import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';

import { REQUEST_STATUS } from 'app-wrapper/constants';

import { AllRequestedSecondStepComponent } from './AllRequestedSecondStep.component';

const AllRequestedSecondStepContainer: FC = () => {
  const {
    isCollapse, isQuotaEmptyError,
    quotaRFRRequestOptions,
    status,
  } = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const quotas = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentQuotas);
  const filters = useSelector(R.selectors.freightQuote.getFreightQuoteFilters);

  const quotasCreateFailedMessage = quotas?.quotasOption?.quotasCreateFailedMessage;
  const isEmptyBg = status === REQUEST_STATUS.complete || status === REQUEST_STATUS.rejected || Boolean(filters?.requestFiltersStatus);

  return (
    <AllRequestedSecondStepComponent
      isCollapse={isCollapse}
      isEmptyBg={isEmptyBg}
      isCollapseAndEmptyQuota={isCollapse && !quotas?.allQuotas?.length}
      quotasCreateFailedMessage={quotasCreateFailedMessage}
      quotaId={quotas?.quotasOption?.id}
      isQuotaEmptyError={isQuotaEmptyError}
      quotaRFRRequestOptions={quotaRFRRequestOptions}
      onSubmitReuseRequest={UC.FreightQuote.onSubmitReuseRequest}
      onClickOpenModal={UC.FreightRFRQuote.onChangeIsOpenModalRate(true)}
      onClickEmptyQuota={UC.FreightRFRQuote.onClickEmptyQuota}
      onClickModifyQuota={UC.FreightRFRQuote.onClickModifyQuota}
    />
  );
};

const AllRequestedSecondStepContainerCached = memo(AllRequestedSecondStepContainer);

export { AllRequestedSecondStepContainerCached as AllRequestedSecondStep };
