import {
  IsString,
  IsOptional,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

interface ICustomerInfoDTM {
  name?: string
  address?: string
  city?: string
  country?: string
  postalCode?: string
  state?: string
  email?: string
  fullName?: string
  phone?: string
}

export class CustomerInfoDTM extends BaseDTM<ICustomerInfoDTM> {
  @IsOptional()
  @IsString()
  name?: string;

  @IsOptional()
  @IsString()
  address?: string;

  @IsOptional()
  @IsString()
  city?: string;

  @IsOptional()
  @IsString()
  country?: string;

  @IsOptional()
  @IsString()
  postalCode?: string;

  @IsOptional()
  @IsString()
  state?: string;

  @IsOptional()
  @IsString()
  email?: string;

  @IsOptional()
  @IsString()
  fullName?: string;

  @IsOptional()
  @IsString()
  phone?: string;

  cityAndState() {
    return `${this.city}${this.state ? `, ${this.state}` : ''}`;
  }

  getPostalCode() {
    return this.postalCode === 'N/A' ? '' : this.postalCode;
  }

  cityStatePostalCodeCountry() {
    return [
      this.city,
      [this.state, this.getPostalCode()].filter((s) => s).join(' '),
      this.country,
    ].filter((s) => s).join(', ');
  }
}
