import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import { Button } from 'app-wrapper/view/components';
import { UserManagementFormFooterActionPanel, UserManagementFormFooterRemoveButton } from './UserManagementFormFooter.styled';

interface IUserManagementFormFooterProps {
  isLoading?: boolean
  hasRemove?: boolean
  removeDiscard?: boolean
  disabledDiscard?: boolean
  disabledUpdate?: boolean
  saveName?: string
  discardName?: string
  removeName?: string
  sizeType?: SizeType
  removeChanges?: () => void
  discardChanges: () => void
  updateForm: () => void
}

export const UserManagementFormFooter: FC<IUserManagementFormFooterProps> = ({
  isLoading,
  hasRemove,
  removeDiscard,
  disabledDiscard,
  disabledUpdate,
  saveName,
  discardName,
  removeName,
  sizeType = 'middle',
  removeChanges,
  discardChanges,
  updateForm,
}) => {
  const { t } = useTranslation();

  const saveButton = saveName || t('SaveChanges');
  const discardButton = discardName || t('Discard Changes');
  const removeButton = removeName || t('Remove');

  return (
    <UserManagementFormFooterActionPanel>
      {hasRemove && (
        <UserManagementFormFooterRemoveButton
          size={sizeType}
          type="default"
          onClick={removeChanges}
          disabled={removeDiscard || isLoading}
        >
          {removeButton}
        </UserManagementFormFooterRemoveButton>
      )}
      <Button
        size={sizeType}
        type="default"
        onClick={discardChanges}
        disabled={disabledDiscard || isLoading}
      >
        {discardButton}
      </Button>
      <Button
        size={sizeType}
        onClick={updateForm}
        disabled={disabledUpdate}
        loading={isLoading}
      >
        {saveButton}
      </Button>
    </UserManagementFormFooterActionPanel>
  );
};
