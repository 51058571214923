import styled from 'styled-components';

export const CardContainerStyled = styled.div.attrs({
  'data-class': 'CardContainerStyled',
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 142px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.themesColors.lightGrayBackground};
  border-radius: 4px;
  padding: 16px 24px;
`;

export const CardHeader = styled.div.attrs({
  'data-class': 'CardHeader',
})`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 46px;
`;

export const CardHeaderContainerIcon = styled.div.attrs({
  'data-class': 'CardHeaderContainerIcon',
})`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.green71};
  align-content: center;
  justify-content: center;
`;

export const CardDecoration = styled.div.attrs({
  'data-class': 'CardDecoration',
})`
  position: absolute;
  top: 71px;
  left: -10px;
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 17px;
  background-color: ${({ theme }) => theme.colors.green71};
`;
