import React, {
  FC, lazy, Suspense, useCallback, useEffect, useState,
} from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { matchPath, useLocation, useNavigate } from 'react-router';
import notification from 'antd/es/notification';
import SyncOutlined from '@ant-design/icons/SyncOutlined';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import {
  Auth, PasswordResetEmailSend, PasswordResetNewPassword, SignIn, SignUp, SignUpSuccess,
} from 'authentication/view/pages';

import {
  AllRequestedQuotesPage,
  FreightQuotePage,
  Invoices,
  Payments,
  RatesUpload,
  RFRByIdPage,
  RFRListPage,
  QuotaRequests,
} from 'monetary/view/pages';

import {
  Shipment,
  ShipmentActivityLog,
  ShipmentBilling,
  ShipmentBillOfLading,
  ShipmentCargo,
  ShipmentContainers,
  ShipmentContainerData,
  ShipmentDisputes,
  ShipmentDocuments,
  ShipmentDocumentsAll,
  ShipmentDocumentsHazmat,
  ShipmentDocumentsAdditional,
  ShipmentDocumentsSeaworthyCertificates,
  ShipmentList,
  ShipmentOverview,
  ShipmentShippingParties,
  ShipmentTransportation,
  ShipmentBillingInvoice,
  ShipmentCharges,
  ShipmentTransportationOverview,
  ShipmentTransportationTracker,
  ShipmentTransportationCharges,
  CreditNote,
  PaymentView,
  ShipmentTransportationAccessorials,
  ShipmentFreeTime,
  ShipmentLocations,
  ShipmentPeople,
  ShipmentAdditionalServices,
  ShipmentAdditionalService,
  TransportationOceanBookingOverview,
  TransportationOceanBooking,
  TransportationExportInlandPickup,
  TransportationImportInlandDelivery,
  ShipmentBillingPayables,
} from 'shipment-operations/view/pages';
import {
  ShipmentAlertsPage,
  ShipmentAlertsPageCompletedPage,
  ShipmentNotificationPage,
  ShipmentRequestRejectTasksPage,
  ShipmentRequestTasksCompletedPage,
  ShipmentRequestTasksPage,
  ShipmentTasksPage,
  ShipmentTasksPageCompleted,
} from 'shipment-operations/view/pages/Shipment/components';

import { HomeComponent } from 'app-wrapper/view/pages/Home';
import { RouteNames } from 'app-wrapper/constants';
import {
  OverviewPage,
  OrganizationRegistration,
  CommandCenterPage,
  CommandCenterTasksPage,
  CommandCenterNotificationPage,
  CommandCenterTasksCompletedPage,
  CommandCenterTasksAlertsPage,
  CommandCenterTasksAlertsCompletedPagePage,
  Contracts,
  CommandCenterTasksRequestsPage,
  CommandCenterTasksRequestsCompletedPage,
  CommandCenterTasksRequestsRejectedPage,
} from 'app-wrapper/view/pages';
import { Button } from 'app-wrapper/view/components';
import { UC as CommandCenterUC } from 'app-wrapper/controllers';

import {
  Account,
  NewOrganizationData,
  AddressesAndContactsPage,
  AddressesAndContactsSecondPage,
  ContactBookPage,
  Customer,
  Partner,
  CustomerDocuments,
  CustomersInternalPage,
  CustomersListPage,
  PartnersListPage,
  NewOrganizationReviewAndConfirm,
  NewOrganizationWizardWrapper,
  NewOrganizationSuccessPage,
  OrganizationProfile,
  NewOrganizationDocuments,
  UserManagement,
  UserProfile,
  OrganizationDocuments,
  ContactBookByIdPage,
  ContactBookByIdContactPersonPage,
  CustomerNotes,
  CustomerAccountingPage,
  AccountDepartmentsPage,
  AccountDepartmentsByIdPage,
  TeamsPage,
  TeamPage,
  CustomerTeamView,
  CustomerInternalTeam,
  UserManagementProfile,
  PartnerOrganizationContainer,
  CustomerSales,
} from 'user-management/view';
import { UsersPage } from 'user-management/view/pages/Users';

import { PAYABLES, RECEIVABLES } from 'shipment-operations/constants';
import { MainContainer } from 'shipment-operations/view/pages/ShipmentCharges/components';

import { UC as userManagementUC } from 'user-management/controllers';
import { UC as monetaryUC } from 'monetary/controllers';
import { UC as authenticationUC } from 'authentication/controllers';
import {
  createShipmentTransportationExportInlandPickupOverviewStore,
  createShipmentTransportationImportInlandDeliveryOverviewStore,
} from 'shipment-operations/repository/mobxStores';
import {
  ShipmentTransportationExportInlandPickupOverviewController,
} from 'shipment-operations/controllers/ShipmentTransportationExportInlandPickupOverview/ShipmentTransportationOverview.controller';
import { ShipmentTransportationImportInlandDeliveryOverviewController } from 'shipment-operations/controllers/ShipmentTransportationImportInlandDeliveryOverview/ShipmentTransportationImportInlandDeliveryOverview.controller';
import {
  OverviewContainer,
} from 'shipment-operations/view/pages/TransportationExportInlandPickup/components/Overview/Overview.container';
import { OverviewContainer as TransportationImportInlandOverview } from 'shipment-operations/view/pages/TransportationImportInlandDelivery/components/Overview/Overview.container';
import { ImportInlandDeliveryCharges } from 'shipment-operations/view/pages/TransportationImportInlandDelivery/components/Charges';
import { ExportInlandPickupCharges } from 'shipment-operations/view/pages/TransportationExportInlandPickup/components/Charges';
import { FreightQuoteController } from 'monetary/controllers/FreightQuoteController';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { GUIDELINE_FEATURE_FLAG } from 'app-wrapper/constants/featureFlags';
import { useIsPosthogLoaded } from 'app-wrapper/hooks';
import {
  AccountDefaultNavigate,
  NewCompanyWizardDefaultNavigate, Page,
  ShipmentDefaultNavigate,
  ShipmentDocumentsDefaultNavigate,
  ShipmentTransportationDefaultNavigate,
  ShipmentTransportationOceanBookingDefaultNavigate,
  ShipmentImportDefaultNavigate,
  ShipmentExportDefaultNavigate,
  PageComponent,
} from './components';
import { Spinner, Container } from './Routes.styles';
import getTheme from '../themes';

interface IRoutesComponentProps {
  isLoading: boolean;
  isLoggedIn: boolean;
  checkAuth: () => void;
  saveLastPath: (lastPath: string) => void;
  onChangePath: (currentPath: string) => void;
  showNotificationNewAppVersion: (callback: () => void) => void;
  onRunLoadStatistics: () => void;
  clearIntervalLoadStatistics: () => void;
}

const PageWithSubMenuComponent = lazy(() => import('guideline/view/pages/PageWithSubMenu/PageWithSubMenu.component')
  .then((module) => ({ default: module.PageWithSubMenuComponent })));

const PageWithSubMenuControlsComponent = lazy(() => import('guideline/view/pages/PageWithSubMenuElements')
  .then((module) => ({ default: module.PageWithSubMenuElementsComponent })));

const PageWithSubMenuMarkupComponent = lazy(() => import('guideline/view/pages/PageWithSubMenuMarkup')
  .then((module) => ({ default: module.PageWithSubMenuMarkupComponent })));

const PageWithSubMenuSectionsComponent = lazy(() => import('guideline/view/pages/PageWithSubMenuSections')
  .then((module) => ({ default: module.PageWithSubMenuSectionsComponent })));

const PageWithSubMenuIconsComponent = lazy(() => import('guideline/view/pages/PageWithSubMenuIcons')
  .then((module) => ({ default: module.PageWithSubMenuIconsComponent })));

const PageWithSubMenuFontsComponent = lazy(() => import('guideline/view/pages/PageWithSubMenuFonts')
  .then((module) => ({ default: module.PageWithSubMenuFontsComponent })));

export const RoutesComponent: FC<IRoutesComponentProps> = ({
  isLoggedIn,
  isLoading,
  checkAuth,
  saveLastPath,
  onChangePath,
  showNotificationNewAppVersion,
  onRunLoadStatistics,
  clearIntervalLoadStatistics,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [timerId, setTimerId] = useState<number>();
  const handleClickRefreshButton = useCallback(() => {
    navigate(0);
  }, []);
  const param = matchPath(RouteNames.SHIPMENT_BILLING(), location.pathname);
  const shipmentId = param?.params?.shipmentId || '0';

  const handleNotificationNewAppVersion = useCallback(() => showNotificationNewAppVersion(
    () => notification.warning({
      message: t('Critical Updates Available'),
      description: (
        <ThemeProvider theme={getTheme()}>
          <div style={{
            gap: '10px',
            display: 'flex',
            alignContent: 'space-between',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
          >
            <div>
              <span>
                {t('Please refresh the page to get the latest version. Without refreshing the page, the application will not work correctly.')}
              </span>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
            >
              <Button
                size="small"
                onClick={handleClickRefreshButton}
              >
                {t('Refresh')}
              </Button>
            </div>
          </div>
        </ThemeProvider>
      ),
      icon: <SyncOutlined style={{ color: '#f3be00' }} />,
      placement: 'bottomRight',
      duration: 0,
    }),
  ), []);

  useEffect(() => {
    checkAuth();
    handleNotificationNewAppVersion();
    setTimerId(
      window.setInterval(
        handleNotificationNewAppVersion,
        // @ts-ignore only for e2e test
        window.Cypress ? 50 * 1000 : 60 * 1000 * 10,
      ),
    );

    return () => {
      clearInterval(timerId);
      clearIntervalLoadStatistics();
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      onRunLoadStatistics();
    } else {
      saveLastPath(location.pathname);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    onChangePath(location.pathname);
  }, [location.pathname]);

  const isGuidelineEnabled = useFeatureFlagEnabled(GUIDELINE_FEATURE_FLAG);
  const isPosthogLoaded = useIsPosthogLoaded();

  if (isLoading || !isPosthogLoaded) {
    return (
      <Container>
        <Spinner size="large" />
      </Container>
    );
  }

  return isLoggedIn ? (
    <Routes>
      <Route
        path={RouteNames.HOME()}
        element={<HomeComponent />}
      >
        <Route
          index
          element={<OverviewPage />}
        />
        <Route
          path={RouteNames.OVERVIEW()}
          element={<OverviewPage />}
        />
        <Route
          path={RouteNames.ACCOUNT()}
          element={<Account />}
        >
          <Route
            index
            element={<UserProfile />}
          />
          <Route
            path={RouteNames.USER_PROFILE()}
            element={<UserProfile />}
          />
          <Route
            path={RouteNames.ORGANIZATION_PROFILE()}
            element={<OrganizationProfile />}
          />
          <Route
            path={RouteNames.USER_MANAGEMENT()}
            element={<UserManagement />}
          />
          <Route
            path={RouteNames.USER_MANAGEMENT_PROFILE()}
            element={<UserManagementProfile />}
          />
          <Route
            path={RouteNames.ACCOUNT_DOCUMENTS()}
            element={<OrganizationDocuments />}
          />
          <Route
            path={RouteNames.ADDRESSES_CONTACTS()}
            element={<AddressesAndContactsPage />}
          />
          <Route
            path={RouteNames.ACCOUNT_DEPARTMENTS()}
            element={<AccountDepartmentsPage />}
          />
          <Route
            path={RouteNames.ACCOUNT_DEPARTMENTS_BY_ID()}
            element={<AccountDepartmentsByIdPage />}
          />
          <Route
            path={RouteNames.ADDRESSES_CONTACTS_SECOND()}
            element={<AddressesAndContactsSecondPage />}
          />
          <Route
            path={RouteNames.ACCOUNT_CUSTOMERS()}
            element={<CustomersListPage />}
          />
          <Route
            path={RouteNames.ACCOUNT_TEAMS()}
            element={Page(userManagementUC.teams, <TeamsPage />)}
          />
          <Route
            path={RouteNames.ACCOUNT_MY_TEAM()}
            element={<CustomerTeamView />}
          />
          <Route
            path={RouteNames.ACCOUNT_TEAM()}
            element={<TeamPage />}
          />
          <Route
            path={RouteNames.ACCOUNT_PARTNERS()}
            element={<PartnersListPage />}
          />
          <Route
            path={RouteNames.ACCOUNT_USERS()}
            element={<UsersPage />}
          />

          <Route
            path={RouteNames.ACCOUNT_PARTNER()}
            element={<Partner />}
          >
            <Route
              index
              element={<AccountDefaultNavigate partner />}
            />

            <Route
              path={RouteNames.ACCOUNT_PARTNER_GENERAL_INFO()}
              element={<PartnerOrganizationContainer />}
            />
            <Route
              path={RouteNames.ACCOUNT_PARTNER_DOCUMENTS()}
              element={<CustomerDocuments />}
            />
            <Route
              path={RouteNames.ACCOUNT_PARTNER_NOTES()}
              element={<CustomerNotes />}
            />
            <Route
              path={RouteNames.ACCOUNT_PARTNER_ACCOUNTING()}
              element={<CustomerAccountingPage />}
            />
          </Route>

          <Route
            path={RouteNames.ACCOUNT_CUSTOMER()}
            element={<Customer />}
          >
            <Route
              index
              element={<AccountDefaultNavigate />}
            />
            <Route
              path={RouteNames.ACCOUNT_CUSTOMER_GENERAL_INFO()}
              element={<CustomersInternalPage />}
            />
            <Route
              path={RouteNames.ACCOUNT_CUSTOMER_DOCUMENTS()}
              element={<CustomerDocuments />}
            />
            <Route
              path={RouteNames.ACCOUNT_CUSTOMER_NOTES()}
              element={<CustomerNotes />}
            />
            <Route
              path={RouteNames.ACCOUNT_CUSTOMER_ACCOUNTING()}
              element={<CustomerAccountingPage />}
            />
            <Route
              path={RouteNames.ACCOUNT_CUSTOMER_TEAM()}
              element={<CustomerInternalTeam />}
            />
            <Route
              path={RouteNames.ACCOUNT_CUSTOMER_SALES()}
              element={<CustomerSales />}
            />
          </Route>
        </Route>
        <Route
          path={RouteNames.CONTACT_BOOK()}
          element={<ContactBookPage />}
        />
        <Route
          path={RouteNames.CONTACT_BOOK_BY_ID()}
          element={<ContactBookByIdPage />}
        />
        <Route
          path={RouteNames.CONTACT_BOOK_BY_ID_CONTACT_BY_ID()}
          element={<ContactBookByIdContactPersonPage />}
        />
        <Route
          path={RouteNames.RATES_UPLOAD()}
          element={<RatesUpload />}
        />
        <Route
          path={RouteNames.RATES_FREIGHT_LIST()}
          element={<RFRListPage />}
        />
        <Route
          path={RouteNames.RATES_FREIGHT_BY_ID()}
          element={<RFRByIdPage />}
        />
        <Route
          path={RouteNames.CONTRACTS()}
          element={<Contracts />}
        />
        <Route
          path={RouteNames.COMMAND_CENTER()}
          element={<CommandCenterPage />}
        />
        <Route
          path={RouteNames.COMMAND_CENTER_TASKS()}
          element={<CommandCenterTasksPage />}
        />
        <Route
          path={RouteNames.COMMAND_CENTER_TASKS_COMPLETED()}
          element={Page(CommandCenterUC.CommandCenter, <CommandCenterTasksCompletedPage />)}
        />
        <Route
          path={RouteNames.COMMAND_CENTER_TASKS_REQUESTS()}
          element={Page(CommandCenterUC.CommandCenter, <CommandCenterTasksRequestsPage />)}
        />
        <Route
          path={RouteNames.COMMAND_CENTER_TASKS_REQUESTS_COMPLETED()}
          element={Page(CommandCenterUC.CommandCenter, <CommandCenterTasksRequestsCompletedPage />)}
        />
        <Route
          path={RouteNames.COMMAND_CENTER_TASKS_REQUESTS_REJECTED()}
          element={Page(CommandCenterUC.CommandCenter, <CommandCenterTasksRequestsRejectedPage />)}
        />
        <Route
          path={RouteNames.COMMAND_CENTER_TASKS_ALERTS()}
          element={Page(CommandCenterUC.CommandCenter, <CommandCenterTasksAlertsPage />)}
        />
        <Route
          path={RouteNames.COMMAND_CENTER_TASKS_ALERTS_COMPLETED()}
          element={Page(CommandCenterUC.CommandCenter, <CommandCenterTasksAlertsCompletedPagePage />)}
        />
        <Route
          path={RouteNames.COMMAND_CENTER_NOTIFICATION()}
          element={<CommandCenterNotificationPage />}
        />
        <Route
          path={RouteNames.ALL_REQUESTED_QUOTES()}
          element={<PageComponent ControllerClass={FreightQuoteController} component={<AllRequestedQuotesPage />} />}
        />
        {/* Currently is hide */}
        {/* <Route
          path={RouteNames.CUSTOM_QUOTE()}
          element={<RCQPage />}
        />
        <Route
          path={RouteNames.CUSTOM_QUOTE_REQUEST()}
          element={<RCQByIdPage />}
        /> */}

        <Route
          path={RouteNames.QUOTA_REQUESTS()}
          element={<QuotaRequests />}
        />

        <Route
          path={RouteNames.FREIGHT_QUOTE()}
          element={Page(monetaryUC.FreightQuote, <FreightQuotePage />)}
        />
        <Route
          path={RouteNames.REGISTER_ORGANISATION()}
          element={<OrganizationRegistration />}
        />

        <Route
          path={RouteNames.SHIPMENTS_ACTIVE()}
          element={<ShipmentList />}
        />

        <Route
          path={RouteNames.SHIPMENTS_COMPLETED()}
          element={<ShipmentList />}
        />

        <Route
          path={RouteNames.SHIPMENTS_CANCELLED()}
          element={<ShipmentList />}
        />

        <Route
          path={RouteNames.SHIPMENTS_WAITING_APPROVAL()}
          element={<ShipmentList />}
        />

        <Route
          path={RouteNames.SHIPMENTS()}
          element={<Navigate to={RouteNames.SHIPMENTS_ACTIVE()} replace />}
        />

        <Route
          path={RouteNames.SHIPMENT()}
          element={<Shipment />}
        >
          <Route
            index
            element={<ShipmentDefaultNavigate />}
          />
          <Route
            path={RouteNames.SHIPMENT_OVERVIEW()}
            element={<ShipmentOverview />}
          />
          <Route
            path={RouteNames.SHIPMENT_PEOPLE()}
            element={<ShipmentPeople />}
          />
          <Route
            path={RouteNames.SHIPMENT_TRACKER()}
            element={<ShipmentTransportationTracker isCommon />}
          />
          <Route
            path={RouteNames.SHIPMENT_ADDITIONAL_SERVICES()}
            element={<ShipmentAdditionalServices />}
          />
          <Route
            path={RouteNames.SHIPMENT_ADDITIONAL_SERVICE()}
            element={<ShipmentAdditionalService />}
          />
          <Route
            path={RouteNames.SHIPMENT_CENTER_TASKS()}
            element={<ShipmentTasksPage />}
          />
          <Route
            path={RouteNames.SHIPMENT_CENTER_TASKS_COMPLETED()}
            element={<ShipmentTasksPageCompleted />}
          />
          <Route
            path={RouteNames.SHIPMENT_CENTER_ALERTS()}
            element={<ShipmentAlertsPage />}
          />
          <Route
            path={RouteNames.SHIPMENT_CENTER_ALERTS_COMPLETED()}
            element={<ShipmentAlertsPageCompletedPage />}
          />
          <Route
            path={RouteNames.SHIPMENT_CENTER_REQUEST()}
            element={Page(CommandCenterUC.CommandCenter, <ShipmentRequestTasksPage />)}
          />
          <Route
            path={RouteNames.SHIPMENT_CENTER_REQUEST_COMPLETED()}
            element={Page(CommandCenterUC.CommandCenter, <ShipmentRequestTasksCompletedPage />)}
          />
          <Route
            path={RouteNames.SHIPMENT_CENTER_REQUEST_REJECTED()}
            element={Page(CommandCenterUC.CommandCenter, <ShipmentRequestRejectTasksPage />)}
          />
          <Route
            path={RouteNames.SHIPMENT_CENTER_NOTIFICATION()}
            element={<ShipmentNotificationPage />}
          />
          <Route
            path={RouteNames.SHIPMENT_SHIPPING_PARTIES()}
            element={<ShipmentShippingParties />}
          />
          <Route
            path={RouteNames.SHIPMENT_CARGO()}
            element={<ShipmentCargo />}
          />
          <Route
            path={RouteNames.SHIPMENT_LOCATIONS()}
            element={<ShipmentLocations />}
          />
          <Route
            path={RouteNames.SHIPMENT_CONTAINERS()}
            element={<ShipmentContainers />}
          >
            <Route
              index
              element={<ShipmentContainerData />}
            />
            <Route
              path={RouteNames.SHIPMENT_CONTAINER()}
              element={<ShipmentContainerData />}
            />
          </Route>
          <Route
            path={RouteNames.SHIPMENT_DOCUMENTS()}
            element={<ShipmentDocuments />}
          >
            <Route
              index
              element={<ShipmentDocumentsDefaultNavigate />}
            />

            <Route
              path={RouteNames.SHIPMENT_DOCUMENTS_ALL()}
              element={<ShipmentDocumentsAll />}
            />

            <Route
              path={RouteNames.SHIPMENT_DOCUMENTS_HAZMAT()}
              element={<ShipmentDocumentsHazmat />}
            />

            <Route
              path={RouteNames.SHIPMENT_DOCUMENTS_SEAWORTHY_CERTIFICATE()}
              element={<ShipmentDocumentsSeaworthyCertificates />}
            />

            <Route
              path={RouteNames.SHIPMENT_DOCUMENTS_ADDITIONAL()}
              element={<ShipmentDocumentsAdditional />}
            />
          </Route>
          <Route
            path={RouteNames.SHIPMENT_TRANSPORTATION_OCEAN_BOOKING()}
            element={<TransportationOceanBooking />}
          >
            <Route
              index
              element={<ShipmentTransportationOceanBookingDefaultNavigate />}
            />

            <Route
              path={RouteNames.SHIPMENT_TRANSPORTATION_OCEAN_BOOKING_OVERVIEW()}
              element={<TransportationOceanBookingOverview />}
            />
            <Route
              path={RouteNames.SHIPMENT_TRANSPORTATION_OCEAN_BOOKING_TRACKER()}
              element={<ShipmentTransportationTracker />}
            />
            <Route
              path={RouteNames.SHIPMENT_TRANSPORTATION_OCEAN_BOOKING_CHARGES()}
              element={<ShipmentTransportationCharges />}
            />
            <Route
              path={RouteNames.SHIPMENT_TRANSPORTATION_OCEAN_BOOKING_ACCESSORIALS()}
              element={<ShipmentTransportationAccessorials />}
            />
            <Route
              path={RouteNames.SHIPMENT_TRANSPORTATION_OCEAN_BOOKING_FREE_TIME()}
              element={<ShipmentFreeTime />}
            />
          </Route>
          <Route
            path={RouteNames.SHIPMENT_TRANSPORTATION_IMPORT()}
            element={<TransportationImportInlandDelivery />}
          >
            <Route
              index
              element={<ShipmentImportDefaultNavigate />}
            />
            <Route
              path={RouteNames.SHIPMENT_TRANSPORTATION_IMPORT_OVERVIEW()}
              element={(
                <PageComponent
                  ControllerClass={ShipmentTransportationImportInlandDeliveryOverviewController}
                  StoreFactory={createShipmentTransportationImportInlandDeliveryOverviewStore}
                  component={<TransportationImportInlandOverview />}
                />
              )}
            />
            <Route
              path={RouteNames.SHIPMENT_TRANSPORTATION_IMPORT_TRACKER()}
              element={<ShipmentTransportationTracker />}
            />
            <Route
              path={RouteNames.SHIPMENT_TRANSPORTATION_IMPORT_CHARGES()}
              element={<ImportInlandDeliveryCharges />}
            />
            <Route
              path={RouteNames.SHIPMENT_TRANSPORTATION_IMPORT_ACCESSORIALS()}
              element={<ShipmentTransportationAccessorials />}
            />
            <Route
              path={RouteNames.SHIPMENT_TRANSPORTATION_IMPORT_FREE_TIME()}
              element={<ShipmentFreeTime />}
            />
          </Route>
          <Route
            path={RouteNames.SHIPMENT_TRANSPORTATION_EXPORT()}
            element={<TransportationExportInlandPickup />}
          >
            <Route
              index
              element={<ShipmentExportDefaultNavigate />}
            />
            <Route
              path={RouteNames.SHIPMENT_TRANSPORTATION_EXPORT_OVERVIEW()}
              element={(
                <PageComponent
                  ControllerClass={ShipmentTransportationExportInlandPickupOverviewController}
                  StoreFactory={createShipmentTransportationExportInlandPickupOverviewStore}
                  component={<OverviewContainer />}
                />
              )}
            />
            <Route
              path={RouteNames.SHIPMENT_TRANSPORTATION_EXPORT_TRACKER()}
              element={<ShipmentTransportationTracker />}
            />
            <Route
              path={RouteNames.SHIPMENT_TRANSPORTATION_EXPORT_CHARGES()}
              element={<ExportInlandPickupCharges />}
            />
            <Route
              path={RouteNames.SHIPMENT_TRANSPORTATION_EXPORT_ACCESSORIALS()}
              element={<ShipmentTransportationAccessorials />}
            />
            <Route
              path={RouteNames.SHIPMENT_TRANSPORTATION_EXPORT_FREE_TIME()}
              element={<ShipmentFreeTime />}
            />
          </Route>
          <Route
            path={RouteNames.SHIPMENT_TRANSPORTATION()}
            element={<ShipmentTransportation />}
          >
            <Route
              index
              element={<ShipmentTransportationDefaultNavigate />}
            />
            <Route
              path={RouteNames.SHIPMENT_TRANSPORTATION_OVERVIEW()}
              element={<ShipmentTransportationOverview />}
            />
            <Route
              path={RouteNames.SHIPMENT_TRANSPORTATION_TRACKER()}
              element={<ShipmentTransportationTracker />}
            />
            <Route
              path={RouteNames.SHIPMENT_TRANSPORTATION_CHARGES()}
              element={<ShipmentTransportationCharges />}
            />
          </Route>

          <Route
            path={RouteNames.SHIPMENT_BILL_OF_LADING()}
            element={<ShipmentBillOfLading />}
          />
          <Route
            element={<ShipmentCharges />}
          >
            <Route
              path={RouteNames.SHIPMENT_CHARGES()}
              element={<MainContainer />}
            />
            <Route
              path={RouteNames.SHIPMENT_CHARGES_ACCESSORIALS()}
              element={<ShipmentTransportationAccessorials />}
            />
            <Route
              path={RouteNames.SHIPMENT_CHARGES_FREE_TIME()}
              element={<ShipmentFreeTime />}
            />
          </Route>
          <Route
            path={RouteNames.SHIPMENT_BILLING()}
            element={<Navigate to={RouteNames.SHIPMENT_BILLING_RECEIVABLES(shipmentId)} replace />}
          />
          <Route
            path={RouteNames.SHIPMENT_BILLING_RECEIVABLES()}
            element={<ShipmentBilling />}
          />

          <Route
            path={RouteNames.SHIPMENT_BILLING_PAYABLES()}
            element={<ShipmentBillingPayables />}
          />
          <Route
            path={RouteNames.SHIPMENT_BILLING_INVOICE()}
            element={<ShipmentBillingInvoice />}
          />
          <Route
            path={RouteNames.SHIPMENT_CREDIT_NOTE()}
            element={<CreditNote />}
          />
          <Route
            path={RouteNames.SHIPMENT_DISPUTES()}
            element={<ShipmentDisputes />}
          />
          <Route
            path={RouteNames.SHIPMENT_ACTIVITY_LOG()}
            element={<ShipmentActivityLog />}
          />
        </Route>

        <Route
          path={RouteNames.FINANCES_INVOICES()}
          element={<Invoices type={RECEIVABLES} />}
        />

        <Route
          path={RouteNames.FINANCES_AP_INVOICES()}
          element={<Invoices type={PAYABLES} />}
        />

        <Route
          path={RouteNames.FINANCES_PAYMENTS()}
          element={<Payments type={RECEIVABLES} />}
        />

        <Route
          path={RouteNames.FINANCES_AP_PAYMENTS()}
          element={<Payments type={PAYABLES} />}
        />

        <Route
          path={RouteNames.FINANCES_PAYMENT_VIEW()}
          element={<PaymentView />}
        />

        <Route
          path={RouteNames.NEW_ORGANIZATION_WIZARD()}
          element={<NewOrganizationWizardWrapper />}
        >
          <Route
            index
            element={<NewCompanyWizardDefaultNavigate />}
          />

          <Route
            path={RouteNames.ADD_COMPANY_DETAILS_WIZARD_ITEM()}
            element={<NewOrganizationData />}
          />
          <Route
            path={RouteNames.SUBMIT_PROOF_DOCUMENTS_WIZARD_ITEM()}
            element={<NewOrganizationDocuments />}
          />
          <Route
            path={RouteNames.NEW_ORGANIZATION_REVIEW_AND_CONFIRM_WIZARD_ITEM()}
            element={<NewOrganizationReviewAndConfirm />}
          />
        </Route>

        <Route
          path={RouteNames.NEW_ORGANIZATION_SUCCESS()}
          element={<NewOrganizationSuccessPage />}
        />
      </Route>

      {isGuidelineEnabled
        ? (
          <Route
            path={RouteNames.GUIDELINE()}
            element={(
              <Suspense fallback={<span>loading</span>}>
                <PageWithSubMenuComponent />
              </Suspense>
            )}
          >
            <Route
              index
              element={<PageWithSubMenuControlsComponent />}
            />

            <Route
              path={RouteNames.GUIDELINE_ELEMENTS()}
              element={<PageWithSubMenuControlsComponent />}
            />

            <Route
              path={RouteNames.GUIDELINE_ICONS()}
              element={<PageWithSubMenuIconsComponent />}
            />

            <Route
              path={RouteNames.GUIDELINE_FONTS()}
              element={<PageWithSubMenuFontsComponent />}
            />

            <Route
              path={RouteNames.GUIDELINE_MARKUP()}
              element={<PageWithSubMenuMarkupComponent />}
            />

            <Route
              path={RouteNames.GUIDELINE_SECTIONS()}
              element={<PageWithSubMenuSectionsComponent />}
            />
          </Route>
        )
        : null}

      <Route
        path="*"
        element={<Navigate to={RouteNames.HOME()} replace />}
      />
    </Routes>
  ) : (
    <Routes>
      <Route
        path={RouteNames.HOME()}
        element={<Auth />}
      >
        <Route
          index
          element={<Navigate to={RouteNames.SIGN_IN()} replace />}
        />
        <Route
          path={RouteNames.SIGN_IN()}
          element={<SignIn />}
        />
        <Route
          path={RouteNames.SIGN_UP()}
          element={<Navigate to={RouteNames['SIGN-UP']()} replace />}
        />
        <Route
          path={RouteNames['SIGN-UP']()}
          element={Page(authenticationUC.signUp, <SignUp />)}
        />
        <Route
          path={RouteNames.FORGOT_PASSWORD_EMAIL_SEND()}
          element={<PasswordResetEmailSend />}
        />
        <Route
          path={RouteNames.FORGOT_PASSWORD_NEW_PASSWORD()}
          element={<PasswordResetNewPassword />}
        />
        <Route
          path={RouteNames.SIGN_UP_SUCCESS()}
          element={<SignUpSuccess />}
        />
      </Route>

      <Route
        path="*"
        element={<Navigate to={RouteNames.HOME()} replace />}
      />
    </Routes>
  );
};
