import styled, { css } from 'styled-components';
import { emptyScrollbar } from 'app-wrapper/view/themes/components/emptyScrollbar';
import { FilterMinMaxElement, FilterCheckboxElement, Button } from 'app-wrapper/view/components';
import { CustomScrollbar } from 'app-wrapper/view/styles/mixins';

export const textColorFirst = css`
  color: ${({ theme }) => theme.colors.characterBrandingTitle85};
`;

export const divFlex = css`
  display: flex;
  position: relative;
  ${textColorFirst}
`;

export const RFQContentDiv = styled.div.attrs({
  'data-class': 'RFQContentDiv',
})`
  ${divFlex}
  width: 100%;
  height: 100%;
`;

export const RFQContentWrapper = styled.div.attrs({
  'data-class': 'RFQContentWrapper',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-left: ${({ theme }) => theme.freightQuotePage.padding.leftPaddingContent};
  padding-right: ${({ theme }) => theme.freightQuotePage.padding.rightPaddingContent};

  div[data-class="EmptyStateForPagesIconSecondWrap"] .anticon-filter svg {
    width: 25px;
    height: 22px;
  }
`;

export const RFQContentWrapperHeader = styled.div.attrs({
  'data-class': 'RFQContentWrapperHeader',
})`
  ${divFlex}
  width: 100%;
  height: ${({ theme }) => theme.freightQuotePage.height.filterHeader};
`;

export const RFQContentWrapperHeaderFound = styled.div.attrs({
  'data-class': 'RFQContentWrapperHeaderFound',
})`
  ${divFlex}
  color: ${({ theme }) => theme.themesColors.middleGraySecondary};
  align-items: center;
  font-size: 12px;
  padding-top: 5px;

  div[data-class="RFQContentWrapperHeaderFiltersIsVisibleIsIncludeRelatedPortRequest"] {
    button {
      margin-left: 16px;
    }
    span[data-class="TypeSwitchControllerTextInactive"] {
      color: ${({ theme }) => theme.themesColors.neutralBranding10};
    }
  }
`;

export const RFQContentWrapperHeaderFilters = styled.div.attrs({
  'data-class': 'RFQContentWrapperHeaderFilters',
})`
  ${divFlex}
  right: 0;
  margin-left: auto;
  color: ${({ theme }) => theme.themesColors.middleGraySecondary};
  align-items: center;
  font-size: 12px;
  padding-top: 5px;
  > div {
    margin-left: 34px;
  }
`;

export const RFQContentWrapperHeaderSchedule = styled.div.attrs({
  'data-class': 'RFQContentWrapperHeaderSchedule',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 100%;
  margin-left: 32px;
`;

export const RFQContentWrapperHeaderScheduleButton = styled(Button).attrs({
  'data-class': 'RFQContentWrapperHeaderScheduleButton',
})`
`;

export const RFQContentWrapperHeaderFiltersItem = styled(FilterMinMaxElement).attrs({
  'data-class': 'RFQContentWrapperHeaderFiltersItem',
})`
`;

export const RFQContentWrapperHeaderFiltersCheckboxItem = styled(FilterCheckboxElement).attrs({
  'data-class': 'RFQContentWrapperHeaderFiltersCheckboxItem',
})`
`;

export const RFQContentWrapperInfiniteScroll = styled.div.attrs({
  'data-class': 'RFQContentWrapperInfiniteScroll',
})`
  ${divFlex}
  ${emptyScrollbar}
  width: 100%;
  overflow-y: auto;
  flex-direction: column;
  > div {
    width: 100%;
  }
  .infinite-scroll-component {
    ${CustomScrollbar}
  }
`;
