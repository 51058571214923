import awsConfig from './awsConfig';

const amplifyConfig = {
  Auth: {
    Cognito: {
      userPoolId: awsConfig.cognito.USER_POOL_ID as string,
      userPoolClientId: awsConfig.cognito.APP_CLIENT_ID as string,
      signUpVerificationMethod: 'link' as 'link' | 'code' | undefined,
      loginWith: {
        oauth: {
          domain: '',
          scopes: ['email', 'openid', 'phone', 'profile', 'aws.cognito.signin.user.admin'],
          redirectSignIn: ['http://localhost:3000/'],
          redirectSignOut: ['http://localhost:3000/'],
          responseType: 'code' as const,
        },
      },
    },
  },
};

export default amplifyConfig;
