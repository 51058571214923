import React from 'react';
import { useSelector } from 'react-redux';

import { R as monetaryR } from 'monetary/repository';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { SchedulesStepComponent } from './SchedulesStep.component';

export const SchedulesStep = React.memo(() => {
  const origin = useSelector(monetaryR.selectors.freightQuote.getFreightQuoteCurrentOrigin);
  const isPending = useSelector(R.selectors.bookingWizard.getIsLoading);
  const schedules = useSelector(R.selectors.bookingWizard.getSchedules);
  const scheduleId = useSelector(R.selectors.bookingWizard.getChosenSchedule);

  return (
    <SchedulesStepComponent
      origin={origin}
      isPending={isPending}
      scheduleId={scheduleId}
      schedules={schedules}
      setSchedule={UC.bookingWizard.chooseSchedule}
    />
  );
});
