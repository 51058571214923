import styled from 'styled-components';
import AntSpinner from 'antd/es/spin';

export const SpinnerWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Spinner = styled(AntSpinner)``;
